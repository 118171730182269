define("client/pods/components/documents/document-print/footer-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bh/y/YBI",
    "block": "{\"symbols\":[\"group\",\"@model\"],\"statements\":[[4,\"if\",[[24,[\"model\",\"_data\",\"notes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"documents-print__notes has-text-left\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--bold documents-print__label\"],[8],[0,\"\\n      \"],[1,[28,\"tt\",[\"notes\"],null],false],[0,\":\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[24,[\"model\",\"_data\",\"notes\"]],false],[0,\"\\n\"],[4,\"each\",[[23,2,[\"_data\",\"groups\"]]],null,{\"statements\":[[0,\"        \"],[1,[23,1,[\"_data\",\"notes\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/document-print/footer-notes/template.hbs"
    }
  });

  _exports.default = _default;
});