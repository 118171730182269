define("client/pods/components/guests/campaign-main/campaign-commissions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TaabQwRv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"layouts/section-row\",null,null,{\"statements\":[[4,\"if\",[[24,[\"campaign\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"channels/shop/campaigns/campaigns-editor/commissions-editor\",null,[[\"tab\",\"isEditing\",\"isReadonly\",\"model\",\"dirty\",\"summaryDataAdapterName\",\"adjustmentListAdapterName\",\"isGuest\",\"errorMsg\"],[[24,[\"tab\"]],false,true,[24,[\"campaign\"]],[24,[\"dirty\"]],\"guests/shop/campaigns/commissions/all\",\"guests/shop/campaigns/commissions/adjustment\",true,[24,[\"errorMsg\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/guests/campaign-main/campaign-commissions/template.hbs"
    }
  });

  _exports.default = _default;
});