define("client/pods/components/extensions/apps/tw/hct/export-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HO2LLw1F",
    "block": "{\"symbols\":[\"onCloseDialogAction\"],\"statements\":[[4,\"if\",[[28,\"pod/get\",[\"features.extensions.tw_hct_export\"],null]],null,{\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"label\"],[\"export HCT data\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"disabled\",\"isRunning\",\"onSubmit\"],[\"export HCT data\",[24,[\"disabled\"]],[24,[\"exportTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"exportTask\"]],\"hct\"],null]]]],false],[0,\"\\n\\n\\t\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"disabled\",\"isRunning\",\"onSubmit\"],[\"export all data\",[24,[\"disabled\"]],[24,[\"exportTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"exportTask\"]],\"all\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/hct/export-btn/template.hbs"
    }
  });

  _exports.default = _default;
});