define("client/pods/components/channels/shop/campaigns/items/table-view/detail-limit-row/component", ["exports", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    big: Ember.inject.service(),
    settings: Ember.inject.service(),
    tagName: '',
    adapterName: 'channels/shop/campaigns/details/limits',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['products/lists/limit-types', 'channels/shop/campaigns/lists/limit-types', 'channels/shop/campaigns/lists/inventory-sync-toggle']);
    },

    didInsertElement() {
      this._super(...arguments);

      this.setupTask.perform();
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      const model = this?.model || {};

      if (RA.isNotNilOrEmpty(model)) {
        const product = yield this.fetchProductTask.perform({
          model
        });
        model.set('product', product);
      }

      const tempCalculatedAvailablePreOrderQtyOnlyBeforeCart = model?.calculatedAvailablePreOrderQtyOnlyBeforeCart || 0;
      this.set('tempCalculatedAvailablePreOrderQtyOnlyBeforeCart', tempCalculatedAvailablePreOrderQtyOnlyBeforeCart);
    }),
    isSingleOrBulkEditing: Ember.computed('isSingleEditing', 'tableViewOptions.isBulkEditing', function () {
      return this.isSingleEditing || this.tableViewOptions.isBulkEditing;
    }),
    isReadonly: Ember.computed('isSingleOrBulkEditing', function () {
      return !this.isSingleOrBulkEditing;
    }),
    updateBulkEditingTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        model,
        onCloseDialogAction
      } = _ref;
      return function* () {
        if (_this.tableViewOptions?.isBulkEditing) {
          if (RA.isNilOrEmpty(model)) {
            model = _this?.model || {};
          }

          const salesLimitedQty = model.getData('salesLimitedQty');
          const salesPreOrderLimitedQty = model.getData('salesPreOrderLimitedQty');
          const hasSalesLimitedQty = model.getData('hasSalesLimitedQty');
          const salesPreOrderLimitedQtyDesc = model.getData('salesPreOrderLimitedQtyDesc');
          const hasSalesPreOrderLimitedQty = model.getData('hasSalesPreOrderLimitedQty');
          const allowPreOrder = model.getData('allowPreOrder');
          const overrideUseInventoryAsLimitedQty = model.getData('overrideUseInventoryAsLimitedQty');

          if (hasSalesLimitedQty && RA.isNilOrEmpty(salesLimitedQty) && overrideUseInventoryAsLimitedQty) {
            model.setData('hasSalesLimitedQty', false);
          }

          if (allowPreOrder && hasSalesPreOrderLimitedQty && RA.isNilOrEmpty(salesPreOrderLimitedQty && !salesPreOrderLimitedQtyDesc)) {
            model.setData('hasSalesPreOrderLimitedQty', false);
          }

          yield _this.updateTask.perform({
            model
          });
          const updatedModel = _this?.model || {};
          const tempCalculatedAvailablePreOrderQtyOnlyBeforeCart = updatedModel?.calculatedAvailablePreOrderQtyOnlyBeforeCart || 0;

          _this.set('tempCalculatedAvailablePreOrderQtyOnlyBeforeCart', tempCalculatedAvailablePreOrderQtyOnlyBeforeCart);
        }

        if (onCloseDialogAction) {
          onCloseDialogAction();
        }
      }();
    }),
    updateTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        model
      } = _ref2;
      return function* () {
        if (RA.isNilOrEmpty(model)) {
          model = _this2?.model || {};
        }

        let product = model.product;
        const updated = yield _this2.crud.queueUpdateRecordTask.perform({
          adapterName: 'channels/shop/campaigns/details/limits',
          appendPath: model.getData('_key'),
          model,
          component: _this2
        });
        product = yield _this2.fetchProductTask.perform({
          model
        });

        if (updated && !_this2.tableViewOptions?.isBulkEditing) {
          _this2.set('model', updated);

          _this2.set('original', false);

          _this2.set('isSingleEditing', false);
        } else if (updated && _this2.tableViewOptions?.isBulkEditing) {
          const adapterName = _this2.adapterName;

          const dirty = _this2.crud.setupDirty({
            adapterName,
            model: updated,
            attrs: {
              product
            }
          });

          _this2.set('model', dirty);
        }

        if (updated) {
          _this2.set('errors', false);
        }
      }();
    }),
    fetchProductTask: (0, _emberConcurrency.task)(function (_ref3) {
      var _this3 = this;

      let {
        model
      } = _ref3;
      return function* () {
        if (RA.isNilOrEmpty(model)) {
          model = _this3?.model || {};
        }

        const itemKey = model.getData('itemKey') || '';
        const isGuest = R.pathOr(false, ['tableViewOptions', 'isGuest'])(_this3);

        if (!_this3.get('product.inventory') && itemKey && !isGuest) {
          const product = yield _this3.crud.findTask.perform({
            adapterName: 'products',
            appendPath: itemKey
          });
          return product;
        } else {
          const product = model.get('product');
          return product;
        }
      }();
    }),

    cancel() {
      const original = this.original;
      const dirty = this.model;
      this.crud.cancelEditing({
        adapterName: this.adapterName,
        dirty
      });
      this.set('model', original);
      this.set('original', false);
      this.set('isSingleEditing', false);
      this.set('errors', false);
      const model = this?.model || {};
      const tempCalculatedAvailablePreOrderQtyOnlyBeforeCart = model?.calculatedAvailablePreOrderQtyOnlyBeforeCart || 0;
      this.set('tempCalculatedAvailablePreOrderQtyOnlyBeforeCart', tempCalculatedAvailablePreOrderQtyOnlyBeforeCart);
    },

    actions: {
      edit() {
        const adapterName = this.adapterName;
        const model = this.model;
        const dirty = this.crud.setupDirty({
          adapterName,
          model,
          attrs: {
            product: model.product
          }
        });
        this.set('original', model); // dirty.set('product', model.get('product'))

        this.set('model', dirty);
        this.set('isSingleEditing', true);
        this.set('errors', false);
      },

      cancel() {
        this.cancel();
      },

      setDefaultAllowPreOrder(model) {
        const overSalesLimitedQtyStatus = model.getData('overSalesLimitedQtyStatus');
        const allowPreOrder = model.getData('allowPreOrder');

        if (RA.isNilOrEmpty(allowPreOrder)) {
          model.setData('allowPreOrder', false);

          if (R.equals(overSalesLimitedQtyStatus, 'preOrder')) {
            model.setData('allowPreOrder', true);
          }
        }
      }

    },

    cancelEditingLimitQty(onCloseDialogAction) {
      this.cancel();

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    },

    calculateSalesLimitedQty(operator) {
      const model = this?.model || {};

      if (RA.isNotNilOrEmpty(model)) {
        let newSalesLimitedQtyBig = this.big.newBig(model?._data?.salesLimitedQty || 0);
        let newCalculatedAvailableQtyBeforeCartBig = this.big.newBig(model?._data?.calculatedAvailableQtyBeforeCart || 0);
        const salesQty = model?._data?.salesQty || 0;

        if (R.equals(operator, 'add')) {
          newSalesLimitedQtyBig = newSalesLimitedQtyBig.plus(1);
          newCalculatedAvailableQtyBeforeCartBig = newSalesLimitedQtyBig.minus(salesQty);
        } else if (R.equals(operator, 'subtract')) {
          newSalesLimitedQtyBig = newSalesLimitedQtyBig.minus(1);
          newCalculatedAvailableQtyBeforeCartBig = newSalesLimitedQtyBig.minus(salesQty);
        } else if (R.equals(operator, 'direct')) {
          newCalculatedAvailableQtyBeforeCartBig = newSalesLimitedQtyBig.minus(salesQty);
        } else if (R.equals(operator, 'addAvailableQty')) {
          newCalculatedAvailableQtyBeforeCartBig = newCalculatedAvailableQtyBeforeCartBig.plus(1);
          newSalesLimitedQtyBig = newCalculatedAvailableQtyBeforeCartBig.plus(salesQty);
        } else if (R.equals(operator, 'subtractAvailableQty')) {
          newCalculatedAvailableQtyBeforeCartBig = newCalculatedAvailableQtyBeforeCartBig.minus(1);
          newSalesLimitedQtyBig = newCalculatedAvailableQtyBeforeCartBig.plus(salesQty);
        } else if (R.equals(operator, 'directAvailableQty')) {
          newSalesLimitedQtyBig = newCalculatedAvailableQtyBeforeCartBig.plus(salesQty);
        }

        if (newSalesLimitedQtyBig.lte(0)) {
          newSalesLimitedQtyBig = this.big.newBig(0);
        }

        if (newCalculatedAvailableQtyBeforeCartBig.lte(0)) {
          newCalculatedAvailableQtyBeforeCartBig = this.big.newBig(0);
        }

        const roundingInventoryQty = this.settings.getProp('roundingInventoryQty');
        model.setData('salesLimitedQty', newSalesLimitedQtyBig.toFixed(roundingInventoryQty));
        model.setData('calculatedAvailableQtyBeforeCart', newCalculatedAvailableQtyBeforeCartBig.toFixed(roundingInventoryQty));
        let limitedQtyDeficitBig = this.big.newBig(0);

        if (newCalculatedAvailableQtyBeforeCartBig.lte(0)) {
          limitedQtyDeficitBig = this.big.newBig(newSalesLimitedQtyBig).minus(salesQty);
        }

        let newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = this.big.newBig(this?.tempCalculatedAvailablePreOrderQtyOnlyBeforeCart || 0);
        const newSalesPreOrderLimitedQtyBig = this.big.newBig(model?._data?.salesPreOrderLimitedQty || 0);
        newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newSalesPreOrderLimitedQtyBig.plus(limitedQtyDeficitBig);
        this.set('tempCalculatedAvailablePreOrderQtyOnlyBeforeCart', newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.toFixed(roundingInventoryQty));
      }
    },

    calculateSalesPreOrderLimitedQty(operator) {
      const model = this?.model || {};

      if (RA.isNotNilOrEmpty(model)) {
        let newSalesPreOrderLimitedQtyBig = this.big.newBig(model?._data?.salesPreOrderLimitedQty || 0);
        let newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = this.big.newBig(this?.tempCalculatedAvailablePreOrderQtyOnlyBeforeCart || 0);
        const salesLimitedQty = model?._data?.salesLimitedQty || 0;
        const salesQty = model?._data?.salesQty || 0;
        const calculatedAvailableQtyBeforeCartBig = this.big.newBig(model?._data?.calculatedAvailableQtyBeforeCart || 0);
        let limitedQtyDeficitBig = this.big.newBig(0);

        if (calculatedAvailableQtyBeforeCartBig.lte(0)) {
          limitedQtyDeficitBig = this.big.newBig(salesLimitedQty).minus(salesQty);
        }

        if (R.equals(operator, 'add')) {
          newSalesPreOrderLimitedQtyBig = newSalesPreOrderLimitedQtyBig.plus(1);
          newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newSalesPreOrderLimitedQtyBig.plus(limitedQtyDeficitBig);
        } else if (R.equals(operator, 'subtract')) {
          newSalesPreOrderLimitedQtyBig = newSalesPreOrderLimitedQtyBig.minus(1);
          newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newSalesPreOrderLimitedQtyBig.plus(limitedQtyDeficitBig);
        } else if (R.equals(operator, 'direct')) {
          newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newSalesPreOrderLimitedQtyBig.plus(limitedQtyDeficitBig);
        } else if (R.equals(operator, 'addAvailablePreOrderQty')) {
          newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.plus(1);
          newSalesPreOrderLimitedQtyBig = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.minus(limitedQtyDeficitBig);
        } else if (R.equals(operator, 'subtractAvailablePreOrderQty')) {
          newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.minus(1);
          newSalesPreOrderLimitedQtyBig = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.minus(limitedQtyDeficitBig);
        } else if (R.equals(operator, 'directAvailablePreOrderQty')) {
          newSalesPreOrderLimitedQtyBig = newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.minus(limitedQtyDeficitBig);
        }

        if (newSalesPreOrderLimitedQtyBig.lte(0)) {
          newSalesPreOrderLimitedQtyBig = this.big.newBig(0);
        }

        const roundingInventoryQty = this.settings.getProp('roundingInventoryQty');
        model.setData('salesPreOrderLimitedQty', newSalesPreOrderLimitedQtyBig.toFixed(roundingInventoryQty));
        this.set('tempCalculatedAvailablePreOrderQtyOnlyBeforeCart', newTempCalculatedAvailablePreOrderQtyOnlyBeforeCartBig.toFixed(roundingInventoryQty));
      }
    },

    toggleAllowPreOrderTask: (0, _emberConcurrency.task)(function (_ref4) {
      var _this4 = this;

      let {
        model
      } = _ref4;
      return function* () {
        if (RA.isNilOrEmpty(model)) {
          model = _this4?.model || {};
        }

        model.setData('salesPreOrderLimitedQty', '');
        model.setData('hasSalesPreOrderLimitedQty', false);
        yield _this4.updateBulkEditingTask.perform({
          model
        });
      }();
    }),
    toggleHasPreOrderLimitedQtyTask: (0, _emberConcurrency.task)(function (_ref5) {
      var _this5 = this;

      let {
        model
      } = _ref5;
      return function* () {
        if (RA.isNilOrEmpty(model)) {
          model = _this5?.model || {};
        }

        model.setData('salesPreOrderLimitedQty', '');
        yield _this5.updateBulkEditingTask.perform({
          model
        });
      }();
    }),
    toggleHasSalesLimitedQtyTask: (0, _emberConcurrency.task)(function (_ref6) {
      var _this6 = this;

      let {
        model
      } = _ref6;
      return function* () {
        if (RA.isNilOrEmpty(model)) {
          model = _this6?.model || {};
        }

        const hasSalesLimitedQty = R.pathOr(false, ['_data', 'hasSalesLimitedQty'])(model);
        model.setData('salesLimitedQty', '');
        model.setData('salesPreOrderLimitedQty', '');

        if (!hasSalesLimitedQty) {
          model.setData('allowPreOrder', false);
          model.setData('hasSalesPreOrderLimitedQty', false);
        }

        yield _this6.updateBulkEditingTask.perform({
          model
        });
      }();
    }),
    toggleUseInventoryAsLimitedQtyTask: (0, _emberConcurrency.task)(function (_ref7) {
      var _this7 = this;

      let {
        model
      } = _ref7;
      return function* () {
        if (RA.isNilOrEmpty(model)) {
          model = _this7?.model || {};
        }

        const hasSalesLimitedQty = R.pathOr(false, ['_data', 'hasSalesLimitedQty'])(model);

        if (!hasSalesLimitedQty) {
          model.setData('allowPreOrder', false);
          model.setData('hasSalesPreOrderLimitedQty', false);
        }

        yield _this7.updateBulkEditingTask.perform({
          model
        });
      }();
    }),
    isLimitedQtyDisabled: Ember.computed('tableViewOptions.campaignModel._data.useInventoryAsLimitedQty', 'settings.settingsModel._data.beta.useInventoryAsLimitedQty', 'model._data.{useInventoryAsLimitedQty,overrideUseInventoryAsLimitedQty}', function () {
      const betaUseInventoryAsLimitedQty = R.pathOr(false, ['settings', 'settingsModel', '_data', 'beta', 'useInventoryAsLimitedQty'])(this);

      if (betaUseInventoryAsLimitedQty) {
        const campaignUseInventoryAsLimitedQty = R.pathOr(false, ['tableViewOptions', 'campaignModel', '_data', 'useInventoryAsLimitedQty'])(this);

        if (campaignUseInventoryAsLimitedQty) {
          const overrideUseInventoryAsLimitedQty = R.pathOr(false, ['model', '_data', 'overrideUseInventoryAsLimitedQty'])(this);

          if (!overrideUseInventoryAsLimitedQty) {
            return true;
          }
        } else {
          const useInventoryAsLimitedQty = R.pathOr(false, ['model', '_data', 'useInventoryAsLimitedQty'])(this);

          if (useInventoryAsLimitedQty) {
            return true;
          }
        }
      }

      return false;
    }),
    isUseInventoryAsLimitedQtyDisabled: Ember.computed('tableViewOptions.campaignModel._data.useInventoryAsLimitedQty', 'settings.settingsModel._data.beta.useInventoryAsLimitedQty', 'model._data.hasSalesLimitedQty', function () {
      const betaUseInventoryAsLimitedQty = R.pathOr(false, ['settings', 'settingsModel', '_data', 'beta', 'useInventoryAsLimitedQty'])(this);

      if (betaUseInventoryAsLimitedQty) {
        const campaignUseInventoryAsLimitedQty = R.pathOr(false, ['tableViewOptions', 'campaignModel', '_data', 'useInventoryAsLimitedQty'])(this);

        if (!campaignUseInventoryAsLimitedQty) {
          const hasSalesLimitedQty = R.pathOr(false, ['model', '_data', 'hasSalesLimitedQty'])(this);

          if (!hasSalesLimitedQty) {
            return true;
          }
        }
      }

      return false;
    }),
    isSalesLimitedQtySelectorAllowed: Ember.computed('tableViewOptions.{adjustTotalQty,campaignModel._data.useInventoryAsLimitedQty}', 'settings.settingsModel._data.beta.useInventoryAsLimitedQty', 'model._data.{useInventoryAsLimitedQty,overrideUseInventoryAsLimitedQty}', function () {
      let isAllowed = false;
      const isAdjustTotalQty = R.pipe(R.pathOr('', ['tableViewOptions', 'limitAdjustType']), R.equals('adjustTotalQty'))(this);

      if (isAdjustTotalQty) {
        isAllowed = true;
      }

      const betaUseInventoryAsLimitedQty = R.pathOr(false, ['settings', 'settingsModel', '_data', 'beta', 'useInventoryAsLimitedQty'])(this);

      if (betaUseInventoryAsLimitedQty) {
        const campaignUseInventoryAsLimitedQty = R.pathOr(false, ['tableViewOptions', 'campaignModel', '_data', 'useInventoryAsLimitedQty'])(this);

        if (campaignUseInventoryAsLimitedQty) {
          const overrideUseInventoryAsLimitedQty = R.pathOr(false, ['model', '_data', 'overrideUseInventoryAsLimitedQty'])(this);

          if (!overrideUseInventoryAsLimitedQty) {
            isAllowed = false;
          }
        } else {
          const useInventoryAsLimitedQty = R.pathOr(false, ['model', '_data', 'useInventoryAsLimitedQty'])(this);

          if (useInventoryAsLimitedQty) {
            isAllowed = false;
          }
        }
      }

      return isAllowed;
    })
  }, (_applyDecoratedDescriptor(_obj, "cancelEditingLimitQty", [_dec], Object.getOwnPropertyDescriptor(_obj, "cancelEditingLimitQty"), _obj), _applyDecoratedDescriptor(_obj, "calculateSalesLimitedQty", [_dec2], Object.getOwnPropertyDescriptor(_obj, "calculateSalesLimitedQty"), _obj), _applyDecoratedDescriptor(_obj, "calculateSalesPreOrderLimitedQty", [_dec3], Object.getOwnPropertyDescriptor(_obj, "calculateSalesPreOrderLimitedQty"), _obj)), _obj)));

  _exports.default = _default;
});