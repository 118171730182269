define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/convenience-store-zones/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GeN4kA4r",
    "block": "{\"symbols\":[\"@model\",\"@isReadonly\"],\"statements\":[[7,\"div\",false],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"setup\"]],[23,1,[]]],null]]],[3,\"did-update\",[[28,\"fn\",[[23,0,[\"setup\"]],[23,1,[]]],null]]],[8],[0,\"\\n  \"],[5,\"forms/form-row\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"elements/element-btn\",[],[[\"@disabled\",\"@icon\",\"@label\",\"@onSubmit\",\"@translate\"],[[23,2,[]],[28,\"if\",[[23,1,[\"_data\",\"isMainSelected\"]],\"fas fa-check\"],null],\"main island\",[28,\"fn\",[[23,0,[\"onToggleSelectRegionsGroup\"]],false],null],true]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"forms/form-row\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"elements/element-btn\",[],[[\"@disabled\",\"@icon\",\"@label\",\"@onSubmit\",\"@translate\"],[[23,2,[]],[28,\"if\",[[23,1,[\"_data\",\"isOutlyingSelected\"]],\"fas fa-check\"],null],\"outlying islands\",[28,\"fn\",[[23,0,[\"onToggleSelectRegionsGroup\"]],true],null],true]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/convenience-store-zones/template.hbs"
    }
  });

  _exports.default = _default;
});