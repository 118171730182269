define("client/pods/components/extensions/apps/tw/t-cat/credentials/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1kJMODeW",
    "block": "{\"symbols\":[\"@errors\",\"@isReadonly\",\"@model\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"t-cat id\",[23,1,[]],\"twTCat\",\"tCatId\"]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@translatedPlaceholder\",\"@value\"],[[23,2,[]],[28,\"tt\",[\"required\"],null],[23,3,[\"_data\",\"tCatId\"]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"t-cat token\",[23,1,[]],\"twTCat\",\"tCatToken\"]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@translatedPlaceholder\",\"@value\"],[[23,2,[]],[28,\"tt\",[\"required\"],null],[23,3,[\"_data\",\"tCatToken\"]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/t-cat/credentials/template.hbs"
    }
  });

  _exports.default = _default;
});