define("client/pods/components/extensions/apps/tw/invoices/print-btn/component", ["exports", "client/pods/components/extensions/apps/tw/invoices/mixins/invoices"], function (_exports, _invoices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_invoices.default, {
    tagName: '',
    showInvoiceEditor: false // @TODO: remove to own app
    // wasCancelled: Ember.observer('model._data.status', function () {
    //   if (this.get('model._data.status') === 'cancelled') {
    //     const invoiceMasterKey = this.get('invoiceMasterKey')
    //     this.get('onFetchInvoices')(invoiceMasterKey)
    //   }
    // })

  });

  _exports.default = _default;
});