define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/bulk-discount-conflicts/bulk-discount-conflicts-item/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "server", _descriptor2, this);
    }

    get currentBulkDiscountName() {
      const model = this.args.model;
      const bulkDiscounts = model.getData('bulkDiscounts') || [];
      const bulkDiscount = this.args.bulkDiscount || {};
      const isCurrent = bulkDiscount.getData('isCurrent') || false;
      const bulkDiscountName = bulkDiscount.getData('bulkDiscountName') || '';

      if (!isCurrent && bulkDiscountName) {
        return bulkDiscountName;
      }

      let bulkDiscountId = '';

      if (!isCurrent && !bulkDiscountName) {
        bulkDiscountId = bulkDiscount.getData('bulkDiscountId') || '';
      }

      if (isCurrent) {
        const conflictsList = this.args.conflict.getData('conflictsList') || [];
        bulkDiscountId = R.pipe(R.find(R.pathEq(['_data', 'isCurrent'], true)), R.pathOr('', ['_data', 'bulkDiscountId']))(conflictsList);
      }

      return R.pipe(R.find(R.pathEq(['_data', 'bulkDiscountId'], bulkDiscountId)), R.pathOr('', ['_data', 'name']))(bulkDiscounts);
    }

    get isSelected() {
      const bulkDiscount = this.args.bulkDiscount || {};
      const isCurrent = R.pathOr(false, ['_data', 'isCurrent'])(bulkDiscount);

      if (isCurrent) {
        return true;
      }

      return false;
    }

    *chooseBulkDiscountTask(bulkDiscountId) {
      const conflictModel = this.args.conflict;
      conflictModel.setData('bulkDiscountId', bulkDiscountId);
      this.args.updateCampaignDetailBulkDiscountTask.perform({
        model: conflictModel
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "chooseBulkDiscountTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "chooseBulkDiscountTask"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent;
});