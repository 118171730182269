define("client/pods/components/importer/import-btn/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ImporterDataImportComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class ImporterDataImportComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dataManager", _descriptor, this);

      _initializerDefineProperty(this, "adapterName", _descriptor2, this);

      _initializerDefineProperty(this, "batchString", _descriptor3, this);

      _initializerDefineProperty(this, "showImportModal", _descriptor4, this);

      _initializerDefineProperty(this, "rowsData", _descriptor5, this);

      _initializerDefineProperty(this, "response", _descriptor6, this);

      this.adapterName = this.args.adapterName;
      this.columns = this.args.columns;
      this.response = false;
    }

    toggleImportModal() {
      this.showImportModal = !this.showImportModal;
    }

    next(onCloseDialogAction) {
      this.response = false;
      const batchDataString = this.batchString || ''; // let batchData = {}
      // try {
      //   // data is json
      //   batchData = JSON.parse(batchDataString)
      // } catch (err) {
      // convert lines to json

      const byRows = R.pipe(R.trim, R.split(/\n/g), R.reject(RA.isNilOrEmpty), R.map(R.trim), R.map(R.split(/\t/g)))(batchDataString);

      const possibleColumns = _nventor.default.safeHeadOr([])(byRows);

      if (RA.isNilOrEmpty(possibleColumns)) {
        return {};
      }

      this.rowsData = byRows; // }

      onCloseDialogAction();
      this.showImportModal = true;
    }

    cancel() {
      this.batchString = '';
    }

    *importTask() {
      const rowsData = this.rowsData;
      const columnsString = this.columns || '';
      const columns = R.split(' ')(columnsString);
      const batchData = R.map(row => {
        return R.zipObj(columns)(row);
      })(rowsData);
      const self = this;
      return this.dataManager.batchImport({
        adapterName: this.adapterName,
        batchData
      }).then(response => {
        this.response = response;
        this.batchString = '';
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adapterName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "batchString", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showImportModal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rowsData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "response", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleImportModal", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleImportModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "next", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "importTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "importTask"), _class.prototype)), _class));
  _exports.default = ImporterDataImportComponent;
});