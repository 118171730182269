define("client/pods/components/layouts/panels/panel-footer-spacer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X0ewp0K6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"panel-grid__footer-spacer \",[22,\"classNames\"]]]],[8],[0,\"\\n\\t\"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"panel-grid__footer\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/panels/panel-footer-spacer/template.hbs"
    }
  });

  _exports.default = _default;
});