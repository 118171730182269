define("client/pods/components/channels/shop/campaigns/price-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mlv0Zu4U",
    "block": "{\"symbols\":[\"range\",\"index\",\"@isReadonly\",\"@model\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table u-full-width\"],[8],[0,\"\\n\\t\"],[7,\"thead\",true],[8],[0,\"\\n\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"tt\",[\"min price\"],null],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"tt\",[\"max price\"],null],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"th\",true],[8],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\\n\\t\"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[\"_data\",\"shopPriceFilters\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"td\",true],[10,\"class\",\"u-v-align-middle-important\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@value\"],[true,[23,1,[\"_data\",\"min\"]]]]],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@onFocusOut\",\"@value\"],[[23,3,[]],[28,\"fn\",[[23,0,[\"newBlankRange\"]],[23,1,[]]],null],[23,1,[\"_data\",\"max\"]]]]],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\n\\t\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"unless\",[[23,3,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[5,\"elements/element-pushbtn\",[],[[\"@disabled\",\"@classNames\",\"@tabindex\",\"@tab\",\"@onSubmit\"],[[23,3,[]],\"u-close-btn\",-1,[23,0,[\"tab\"]],[28,\"fn\",[[23,0,[\"removeRange\"]],[23,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/price-filter/template.hbs"
    }
  });

  _exports.default = _default;
});