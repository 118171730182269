define("client/pods/websites/service", ["exports", "ember-concurrency-decorators", "client/config/environment", "ramda-adjunct"], function (_exports, _emberConcurrencyDecorators, _environment, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WebsitesService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = class WebsitesService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "server", _descriptor3, this);

      _initializerDefineProperty(this, "constants", _descriptor4, this);

      _initializerDefineProperty(this, 'all', _descriptor5, this);
    }

    getPreviewLink() {
      let {
        model,
        website,
        dataTabContainer,
        isPreview = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (dataTabContainer === 'campaigns') {
        return `${_environment.default.APP.shopUrl}/${model.getData('url')}`;
      }

      let url;

      if (_environment.default.environment === 'development') {
        url = _environment.default.APP.bigordrHost;
      } else {
        url = 'https://';

        if (website.getData('domainVerified')) {
          // if domain verified, use domain
          url += `${website.getData('domain')}`;
        } else {
          // if domain not verified, use subdomain
          url += `${website.getData('subdomain')}.`;
          url += _environment.default.APP.websiteHost;
        }
      }

      if (model.getData('url')) {
        url += `/${model.getData('url')}`;
      }

      if (isPreview) {
        url += '?preview=true';
      }

      return url;
    }

    *searchInputTask(args) {
      let {
        query = '',
        resultsProperty = '',
        adapterName = 'websites',
        filtersAdapterName = 'websites/filters',
        component,
        resultsToggle = false,
        global = true,
        filters
      } = args;

      if (RA.isNilOrEmpty(filters)) {
        filters = this.search.setupFilters({
          adapterName: filtersAdapterName,
          defaultFiltersData: {
            query
          }
        });
      }

      const results = yield this.crud.searchInputTask.perform({
        adapterName,
        filters,
        query,
        resultsProperty,
        component,
        resultsToggle
      });

      if (global && resultsProperty) {
        this[resultsProperty] = results;
      }

      return results;
    }
    /**
     * fetches the page collections. the collection will sync update when
     * new pages are added.
     * @param {Object} website model of the website
     * @returns
     */


    *fetchPagesCollectionTask(website) {
      const collectionModel = yield this.crud.findTask.perform({
        adapterName: 'websites/pages/collection',
        appendPath: website.getData('_key')
      });
      return collectionModel;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, 'all', [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getPreviewLink", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "getPreviewLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchInputTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchInputTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchPagesCollectionTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchPagesCollectionTask"), _class.prototype)), _class));
  _exports.default = WebsitesService;
});