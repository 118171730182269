define("client/pods/components/transporters/shopee-print-btn/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TransportersShopeePrintBtnComponent = (_dec = Ember.inject.service, (_class = class TransportersShopeePrintBtnComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dispatch", _descriptor, this);

      _defineProperty(this, "provider", 'shopee');
    }

    *getWaybillTask() {
      this.args.resetBatchStatus();
      const shopeeData = this.args.shopeeData;
      const documents = R.pipe(R.pathOr([], ['args', 'results']), R.map(R.pipe(R.pathOr({}, ['model']), model => {
        model.set('_data.transporterExtension', this.provider);
        model.set('_data.shopeeData.transporter.selected', shopeeData);
        return model;
      })))(this); // @TODO: should only submit waybills. Create a different btn to download PDFs

      const response = yield this.dispatch.generateWaybillTask.perform({
        transporterExtension: this.provider,
        component: this,
        documents,
        isNewWaybill: true
      });
      this.args.setBatchStatus(response);
      this.args.setDocumentsBatchStatusAlwaysVisible(true);
      this.args.onCloseDialogAction();
      return response;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dispatch", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getWaybillTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getWaybillTask"), _class.prototype)), _class));
  _exports.default = TransportersShopeePrintBtnComponent;
});