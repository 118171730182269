define("client/pods/components/websites/panels-display/component", ["exports", "client/mixins/crud", "client/utils/nventor", "client/config/environment", "ember-concurrency", "ramda-extension"], function (_exports, _crud, _nventor, _environment, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'websites',

    init() {
      this.crud.addLists(this, ['statuses', 'toggles/auto', 'toggles/onOff', 'toggles/onOff', 'websites/lists/subPanels']);

      this._super(...arguments);

      this.set('config', _environment.default);
      const tab = this.tab;
      const model = tab.get('model');
      this.set('model', model);
      this.set('copyErrors', []);
      this.tabsManager.setHeader(this.tabParent, model.getData('name')); // this.set('config', config)
    },

    afterLoadLists() {
      this._super(...arguments);

      const subPanels = R_.dotPath('crud.lists.websites-lists-subPanels')(this) || [];
      const initialPanel = this.tabsManager.getLoadInstructions(this.tab) || subPanels.get('firstObject');
      this.tabsManager.displaySubPanel(this, initialPanel);
    },

    duplicateRecordTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        adapterName,
        model
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        adapterName = adapterName || _this.adapterName;
        model = model || _this.dirty;
        return yield _this.createRecordTask.perform({
          adapterName,
          model,
          errorsProp: 'copyErrors'
        });
      }();
    }).drop(),
    actions: {
      editPanel() {
        const tab = this.tab;
        const model = this.model;
        const activeSubTab = this.tabsManager.getActiveSubTab(tab);
        const subPanels = R_.dotPath('crud.lists.websites-lists-subPanels')(this) || [];
        const subTabOptions = subPanels.findBy('_data.component', activeSubTab.get?.('component'));
        const tabOptions = {
          loadInstructions: {
            component: subTabOptions.get?.('_data.editingComponent')
          }
        };
        this.onEdit(model, {
          tabOptions
        });
      },

      prepareDuplicateRecord() {
        const model = this.model;
        const adapterName = this.adapterName;
        const adapter = this.dataManager.getAdapter(adapterName);
        const data = adapter.serialize(model);
        const newModel = this.setupNewRecord({
          adapterName,
          data
        });
        let name = model.getData('name');
        let shopUrl = model.getData('shopUrl');
        name = `${name}-copy-` + _nventor.default.random.digits(3);
        shopUrl = `${shopUrl}-copy-` + _nventor.default.random.digits(3);
        newModel.setData('status', this.get('constants.status.inactive'));
        newModel.setData('name', name);
        newModel.setData('password', '');
        newModel.setData('shopUrl', shopUrl);
        newModel.setData('_key', '');
        newModel.setData('_id', '');
        newModel.setData('_rev', '');
        newModel.setData('modified', '');
        newModel.setData('created', '');
        newModel.setData('copyFromKey', model.getData('_key'));
        this.set('dirty', newModel);
      }

    }
  });

  _exports.default = _default;
});