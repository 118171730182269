define("client/pods/products/choices/details/model", ["exports", "client/pods/base/model", "client/mixins/products-children", "client/mixins/uploads"], function (_exports, _model, _productsChildren, _uploads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_productsChildren.default, _uploads.default, {
    init() {
      this._super(...arguments);

      this.set('childAdapters', {
        choices: 'products/choices',
        'choices-details': 'products/choices/details',
        'sets-details': 'products/sets/details',
        variants: 'products/variants',
        'variants-options': 'products/variants/options',
        'variants-children': 'products/variants/children'
      });
    },

    defaults() {
      return {};
    }

  });

  _exports.default = _default;
});