define("client/mixins/users-list-by", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_crud.default, _date.default, {
    listByTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        filters,
        adapterName,
        appendPath,
        match,
        status,
        query = '',
        byLatest,
        resultsProperty,
        resultsToggle
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let onHideDropdown = arguments.length > 1 ? arguments[1] : undefined;
      return function* () {
        filters.setData('page', 1);

        if (match) {
          filters.setData('match', match);
        }

        if (status) {
          if (!Ember.isArray(status)) {
            status = [status];
          }

          filters.setData('status', status);
        }

        if (byLatest) {
          filters.setData('sort', [{
            by: 'timestampZ_latest',
            direction: 'DESC'
          }, {
            by: 'timestampZ_update',
            direction: 'DESC'
          }, {
            by: 'timestampZ',
            direction: 'DESC'
          }]);
        }

        const searchSettings = {
          adapterName,
          appendPath,
          resultsProperty,
          resultsToggle,
          query,
          filters
        };
        const results = yield _this.searchRecordsTask.perform(searchSettings);

        if (onHideDropdown) {
          onHideDropdown();
        }

        return results;
      }();
    })
  });

  _exports.default = _default;
});