define("client/helpers/froala-arg", ["exports", "ember-froala-editor/helpers/froala-arg"], function (_exports, _froalaArg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _froalaArg.default;
    }
  });
  Object.defineProperty(_exports, "froalaArg", {
    enumerable: true,
    get: function () {
      return _froalaArg.froalaArg;
    }
  });
});