define("client/pods/recurring/service", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RecurringService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('search'), _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class RecurringService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "searchService", _descriptor2, this);

      _initializerDefineProperty(this, "recurringSchedulesFilters", _descriptor3, this);

      this.recurringSchedulesFilters = this.searchService.setupFilters({
        adapterName: 'recurring/schedules/filters',
        defaultFiltersData: {}
      });
    }

    addSchedule(_ref) {
      let {
        model,
        schedule = {},
        tab
      } = _ref;
      const recurringSchedules = model?._data?.recurringSchedules || [];
      const newRecurringSchedule = this.crud.setupNewRecord({
        adapterName: 'recurring/schedules',
        data: schedule,
        tab
      });
      recurringSchedules.pushObject(newRecurringSchedule);
      model.setData('recurringSchedules', recurringSchedules);
    }

    removeSchedule(_ref2) {
      let {
        model,
        schedule = {}
      } = _ref2;
      const recurringSchedules = model?._data?.recurringSchedules || [];
      recurringSchedules.removeObject(schedule);
      model.setData('recurringSchedules', recurringSchedules);
    }

    setScheduleFromPreset(_ref3, preset) {
      let {
        schedule
      } = _ref3;
      let data = preset.serialize();
      data = R.omit(['_id', '_key', '_rev'])(data);
      R.mapObjIndexed((value, key) => {
        schedule.setData(key, value);
      })(data);
    }

    resetSchedule(_ref4) {
      let {
        schedule
      } = _ref4;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "searchService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "recurringSchedulesFilters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addSchedule", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addSchedule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeSchedule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "removeSchedule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setScheduleFromPreset", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setScheduleFromPreset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetSchedule", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "resetSchedule"), _class.prototype)), _class));
  _exports.default = RecurringService;
});