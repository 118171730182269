define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/template-editor/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsCampaignsItemEditorTemplateEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class ChannelsShopCampaignsCampaignsItemEditorTemplateEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      this.crud.addLists(this, ['users', 'toggles/auto', 'toggles/onOff', 'channels/shop/campaigns/deliveryDateSettings', 'channels/shop/campaigns/deliveryDateSettingsItems', 'channels/shop/campaigns/overSalesLimitedQtyStatuses', 'channels/shop/campaigns/lists/itemEditorSubPanels', 'products/lists/tags', 'products/lists/display-tags', 'products/lists/product-display-position', 'extensions/apps/tw/invoicingAuto', 'shipping/feeType', 'commissions/methods', 'statuses', 'products/lists/pageImportBtnSubPanels', 'products/lists/sync']);

      if (this.args?.isAutomations) {
        this.crud.addLists(this, ['channels/shop/campaigns/automations/statuses']);
      } else {
        this.crud.addLists(this, ['channels/shop/campaigns/statuses']);
      }
    }

    newTemplate(model) {
      const templateModel = model.addNewTemplate();
      this.args.selectTemplate(templateModel);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "newTemplate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "newTemplate"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsItemEditorTemplateEditorComponent;
});