define("client/pods/admin/payments/card/checkout-logs/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdminPaymentsCardCheckoutLogsModel extends _model.default {
    get status() {
      const hasError = R.pipe(R.pathOr('', ['_data', 'error']), RA.isNotNilOrEmpty)(this);

      if (hasError) {
        return 'unsuccessful';
      }

      return 'successful';
    }

    get isSuccessful() {
      return this.status === 'successful';
    }

    get totalStepsIndex() {
      return R.pipe(R.pathOr([], ['_data', 'steps']), R.length, R.dec)(this);
    }

    get allQty() {
      return R.pipe(R.pathOr([], ['_data', 'cartItems']), R.pluck('qty'), R.sum)(this);
    }

    get prettyErrorStack() {
      return R.pipe(R.pathOr([], ['_data', 'steps']), R.last, R.pathOr('', ['error', 'stack']), R.split('\n'), R.join('<br>'))(this);
    }

  }

  _exports.default = AdminPaymentsCardCheckoutLogsModel;
});