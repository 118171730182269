define("client/pods/components/qr-code-generator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7y46XZup",
    "block": "{\"symbols\":[\"@data\",\"@isLink\"],\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[7,\"a\",true],[11,\"href\",[28,\"if\",[[23,2,[]],[23,1,[]]],null]],[10,\"border\",\"0\"],[10,\"rel\",\"nofollow\"],[10,\"target\",\"_blank\"],[8],[0,\"\\n\"],[0,\"  \"],[7,\"figure\",true],[10,\"class\",\"image is-128x128\"],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[23,0,[\"qrCodeSrc\"]]],[8],[9],[0,\"\\n    \"],[7,\"figcaption\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/qr-code-generator/template.hbs"
    }
  });

  _exports.default = _default;
});