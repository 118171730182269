define("client/pods/components/documents/pos/barcode-scanner/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentsPosBarcodeScannerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class DocumentsPosBarcodeScannerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "constants", _descriptor2, this);

      _initializerDefineProperty(this, "barcode", _descriptor3, this);

      this.crud.addLists(this, ['discounts/types', 'documents/lists/pos/macros', 'paymentMethods']);
    }

    get isRunning() {
      if (this.onInputTask.isRunning) {
        return true;
      }

      if (this.args.isRunning) {
        return true;
      }

      return false;
    }

    getMacro(code) {
      const macros = R.path(['crud', 'lists', 'documents-lists-pos-macros'])(this);
      const hasNonMacros = RA.isNilOrEmpty(macros);

      if (hasNonMacros) {
        return;
      }

      return R.find(R.pathEq(['_data', 'macro'], code))(macros);
    }

    clearBarcode() {
      this.barcode = '';
    }

    *addProduct(barcode) {
      const campaignDetails = this?.args?.campaignDetails || [];
      const products = this?.args?.products || [];
      const campaignDetailsAndProducts = R.concat(campaignDetails, products);
      const product = R.pipe(R.filter(R.pipe(R.path(['_data', 'barcode']), RA.isNotNilOrEmpty)), R.find(R.pathEq(['_data', 'barcode'], barcode)))(campaignDetailsAndProducts);
      const onAddItemToCart = this?.args?.onAddItemToCart;

      if (!product || !onAddItemToCart) {
        return;
      }

      return onAddItemToCart.perform(product);
    }

    applyDiscount() {
      var _this = this;

      let {
        type,
        amount
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const model = _this?.args?.model;

        if (!model) {
          return;
        }

        const discountTypes = R.path(['crud', 'lists', 'discounts-types'])(_this);

        if (!discountTypes) {
          return;
        }

        const discountType = R.find(R.pathEq(['_data', 'value'], type))(discountTypes);

        _this.args.model.toggleDiscountType(discountType);

        _this.args.model.set('_data.discountType', type);

        _this.args.model.set('_data.discountRate', amount);

        _this.args.model.sumTotals();
      }();
    }

    *updatePaymentMethod(macro) {
      const model = this?.args?.model;

      if (!model) {
        return;
      }

      const paymentMethod = macro.paymentMethod;
      const hasPaymentMethod = this.constants.paymentMethods[paymentMethod];

      if (!hasPaymentMethod) {
        return;
      }

      const paymentMethodModel = R.pipe(R.pathOr([], ['crud', 'lists', 'paymentMethods']), R.find(R.pathEq(['_data', 'value'], paymentMethod)))(this);
      this.args.model.loadPaymentMethod({
        paymentMethod: paymentMethodModel,
        paymentMethodKey: paymentMethod
      });
    }

    *addMacro(macro) {
      const macroData = macro?._data || {};

      switch (macroData.action) {
        case 'discount':
          return this.applyDiscount.perform(macroData);

        case 'paymentMethod':
          return this.updatePaymentMethod.perform(macroData);

        default:
          console.log('MISSING MACRO: ', {
            macro
          });
          break;
      }
    }

    fetchCampaignDetailsAndProducts() {
      let {
        barcode = '',
        posOptions
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        // campaign details search
        const campaignDetailsFilters = posOptions?.campaignDetailsFilters;
        campaignDetailsFilters.set('_data.query', barcode);
        campaignDetailsFilters.set('_data.isPos', true);
        yield posOptions.fetchProductsByShopTask.perform({
          filters: campaignDetailsFilters
        }); // @TODO: ENABLE PRODUCTS

        return;
        const shouldEmptyProductsResults = RA.isNilOrEmpty(barcode); // products search

        const productFilters = posOptions?.productFilters;

        if (shouldEmptyProductsResults) {
          productFilters?.results?.clear();
        } else {
          productFilters.set('_data.query', barcode);
          yield posOptions.fetchProductsTask.perform({
            filters: productFilters
          });
        }
      }();
    }

    *onInputTask(barcode) {
      const timedBarcode = barcode.toString().trim();
      this.clearBarcode();
      const macro = this.getMacro(timedBarcode);

      if (macro) {
        return yield this.addMacro.perform(macro);
      }

      const posOptions = this.args?.posOptions;
      const hasPosOptions = RA.isNotNilOrEmpty(posOptions);

      if (hasPosOptions) {
        yield this.fetchCampaignDetailsAndProducts.perform({
          barcode,
          posOptions
        });
      }

      const isBarcode = barcode.match(/^\d+$/);

      if (isBarcode && barcode) {
        yield this.addProduct.perform(timedBarcode);
        yield this.fetchCampaignDetailsAndProducts.perform({
          posOptions
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "barcode", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "clearBarcode", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "clearBarcode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addProduct", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "addProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applyDiscount", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "applyDiscount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePaymentMethod", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updatePaymentMethod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addMacro", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "addMacro"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchCampaignDetailsAndProducts", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCampaignDetailsAndProducts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInputTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "onInputTask"), _class.prototype)), _class));
  _exports.default = DocumentsPosBarcodeScannerComponent;
});