define("client/pods/components/contacts/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zelqp6i6",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"contacts/contacts-editor\",null,[[\"tab\",\"model\",\"errors\",\"saveTask\",\"isNew\",\"onCancel\"],[[24,[\"tab\"]],[24,[\"model\"]],[24,[\"errors\"]],[24,[\"createRecordTask\"]],true,[24,[\"onCancel\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/panels-new/template.hbs"
    }
  });

  _exports.default = _default;
});