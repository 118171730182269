define("client/pods/flows/steps/actions/generate-promo-code/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        defaults: {
          actionType: 'generatePromoCode'
        }
      };
    },

    populate(data, attrs) {
      data.defaults = this._transformDefaults(data);

      this._super(data, attrs);
    },

    _transformDefaults(data) {
      let defaultsData = R.pathOr({}, ['defaults'])(data);

      if (RA.isNilOrEmpty(defaultsData)) {
        defaultsData = {
          dateEndZ: '',
          dateStartZ: '',
          discountAmount: 0,
          discountRate: 0,
          discountRateOnSale: 0,
          status: 'active',
          type: 'rate',
          usageLimit: 1
        };
      }

      return this.dataManager.setAsRecord({
        adapterName: 'flows/steps/actions/promo-codes-default',
        data: defaultsData
      });
    }

  });

  _exports.default = _default;
});