define("client/pods/components/users/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVOykp8W",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"users/users-editor\",null,[[\"model\",\"isNew\",\"errors\",\"onSubmitTask\",\"onCancel\"],[[24,[\"model\"]],true,[24,[\"errors\"]],[24,[\"createRecordTask\"]],[24,[\"onDash\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/users/panels-new/template.hbs"
    }
  });

  _exports.default = _default;
});