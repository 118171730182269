define("client/pods/dispatch/lists/export-options/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'exportAndUpdateStatus',
        label: 'export data and update status'
      }, {
        value: 'exportOnly',
        label: 'export'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});