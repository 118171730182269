define("client/pods/workflows/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/workflows/model", "ramda-adjunct"], function (_exports, _adapter, _adaptersNew, _model, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/workflows',
    modelObj: _model.default,
    idParam: '_id',

    serialize(model) {
      const data = this._super(model);

      if (_ramdaAdjunct.default.isNotNilOrEmpty(data.tags)) {
        data.tags = [data.tags];
      }

      return data;
    }

  });

  _exports.default = _default;
});