define("client/pods/components/grapesjs/links-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q6++dUQp",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@modalDialogIsVisible\",\"@website\"],\"statements\":[[5,\"modals/modal-dialog\",[],[[\"@title\",\"@modalDialogIsVisible\",\"@onToggle\",\"@closeOnOverlayClick\"],[\"add link\",[23,2,[]],[28,\"fn\",[[28,\"mut\",[[23,2,[]]],null]],null],false]],{\"statements\":[[0,\"\\n  \"],[5,\"websites/links-editor\",[],[[\"@model\",\"@website\"],[[23,0,[\"tempModel\"]],[23,3,[]]]]],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/footer-btns\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"layouts/btns-group\",[],[[\"@classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\n        \"],[5,\"elements/btn-cancel\",[],[[\"@onSubmit\"],[[28,\"fn\",[[23,0,[\"cancel\"]],[23,1,[]]],null]]]],[0,\"\\n\\n        \"],[5,\"elements/btn-save\",[],[[\"@onSubmit\"],[[28,\"fn\",[[23,0,[\"save\"]],[23,1,[]]],null]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/grapesjs/links-modal/template.hbs"
    }
  });

  _exports.default = _default;
});