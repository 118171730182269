define("client/pods/contacts/groups/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    defaults() {
      return {
        name: '',
        description: '',
        relationships: ['customer'],
        criterias: []
      };
    },

    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let attrs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // may be overridden
      data = this._transformCriterias(data);

      this._super(...arguments);
    },

    _transformCriterias(rawData) {
      rawData.criterias = R.pipe(R.propOr([], 'criterias'), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'contacts/groups/criterias',
          data
        });
      }))(rawData);
      return rawData;
    },

    newCriteria() {
      const criteria = this.dataManager.newRecord({
        adapterName: 'contacts/groups/criterias',
        data: {}
      });
      const criterias = this.getData('criterias') || [];
      criterias.pushObject(criteria);
      this.setData('criterias', criterias);
    },

    removeCriteria(criteria) {
      const hasSelected = criteria?._data?.by;
      const message = this.intl.t('are you sure you want to remove item', {
        item: this.intl.t('match_contacts_groups_criteria')
      });
      let confirmed = true;

      if (hasSelected) {
        confirmed = window.confirm(message);
      }

      if (confirmed) {
        const criterias = this.getData('criterias') || [];
        criterias.removeObject(criteria);
        this.setData('criterias', criterias);
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "newCriteria", [_dec], Object.getOwnPropertyDescriptor(_obj, "newCriteria"), _obj), _applyDecoratedDescriptor(_obj, "removeCriteria", [_dec2], Object.getOwnPropertyDescriptor(_obj, "removeCriteria"), _obj)), _obj)));

  _exports.default = _default;
});