define("client/pods/payment-methods/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/payment-methods/model", "ramda", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/payment-methods',
    modelObj: _model.default,
    idParam: '_id',

    async afterFindAll(responseData) {
      // Filter out line pay if it's not enabled
      const settingsModel = R.path(['settings', 'settingsModel'])(this) || {};

      if (settingsModel.linePayEnabled !== true) {
        responseData = R.reject(method => method.value === 'linePay')(responseData);
      }

      return R.map(docData => _nventor.default.createModel(_model.default, docData))(responseData);
    }

  });

  _exports.default = _default;
});