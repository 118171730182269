define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/bulk-discount-conflicts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Eh5nEXi",
    "block": "{\"symbols\":[\"bulkDiscount\",\"@model\",\"@conflict\",\"@onCloseDialogAction\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"u-margin-bottom-10\"],[8],[0,\"\\n  \"],[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"u-container u-border title is-6\"],[11,\"colspan\",[29,[[23,0,[\"totalConflicts\"]]]]],[8],[1,[23,3,[\"_data\",\"item\"]],false],[9],[0,\"\\n    \"],[7,\"td\",true],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[\"_data\",\"conflictsList\"]]],null,{\"statements\":[[0,\"      \"],[5,\"channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/bulk-discount-conflicts/bulk-discount-conflicts-item\",[],[[\"@model\",\"@conflict\",\"@bulkDiscount\",\"@onCloseDialogAction\",\"@updateCampaignDetailBulkDiscountTask\"],[[23,2,[]],[23,3,[]],[23,1,[]],[23,4,[]],[23,0,[\"updateCampaignDetailBulkDiscountTask\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"u-container\"],[8],[0,\"\\n      \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\",\"@hasPlaceholder\",\"@hasSavedLabel\",\"@wasSaved\"],[[23,0,[\"updateCampaignDetailBulkDiscountTask\",\"isRunning\"]],true,true,[23,0,[\"wasSaved\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/bulk-discount-conflicts/template.hbs"
    }
  });

  _exports.default = _default;
});