define("client/pods/components/extensions/apps/tw/shopee/info-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QW1kBMtH",
    "block": "{\"symbols\":[\"@providerData\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[1,[28,\"tt\",[\"shopee\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[5,\"elements/tag\",[],[[\"@label\"],[[23,1,[\"logs\",\"status\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--smallest\"],[8],[0,\"\\n    \"],[1,[28,\"dateTime\",[[23,1,[\"logs\",\"timestampZ\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"logs\",\"responseData\",\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[28,\"tt\",[\"error message\"],null],false],[0,\":\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[5,\"elements/element-label\",[],[[\"@classNames\",\"@translate\",\"@icon\",\"@label\"],[\"has-text-danger\",false,\"fas fa-exclamation-circle\",[23,1,[\"logs\",\"responseData\",\"message\"]]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/shopee/info-summary/template.hbs"
    }
  });

  _exports.default = _default;
});