define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsConflictsDialogComponent = (_dec = Ember._action, (_class = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsConflictsDialogComponent extends _component.default {
    get modalDialogIsVisible() {
      const model = this.args.model;

      if (model) {
        const bulkDiscountConflictDetails = model.getData('bulkDiscountConflictDetails') || [];

        if (_ramdaAdjunct.default.isNotNilOrEmpty(bulkDiscountConflictDetails)) {
          return true;
        }
      }

      return false;
    }

    onCloseBulkDiscountConflictDialog(onCloseDialogAction) {
      const model = this.args.model;
      const bulkDiscountConflictDetails = model.getData('bulkDiscountConflictDetails') || [];

      if (_ramdaAdjunct.default.isNotNilOrEmpty(bulkDiscountConflictDetails)) {
        model.setData('bulkDiscountConflictDetails', []);
      }

      if (this.args.onDone) {
        this.args.onDone();
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onCloseBulkDiscountConflictDialog", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseBulkDiscountConflictDialog"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorBulkDiscountsConflictsDialogComponent;
});