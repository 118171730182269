define("client/pods/components/extensions/apps/tw/e-invoices/print-manager/print-btn/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    printer: Ember.inject.service(),
    eInvoicesService: Ember.inject.service('extensions/apps/tw/e-invoices'),
    tagName: '',
    isReadonly: false,
    adapterName: 'extensions/apps/tw/e-invoices/to-issue',
    filtersAdapterName: 'extensions/apps/tw/e-invoices/filters',
    onlinePrinters: null,
    printerModels: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['extensions/apps/tw/e-invoices/carrier-types']);

      if (this.document) {
        let telephone = this.get('document._data.billingTelephone');

        if (!telephone) {
          telephone = this.get('document._data.telephone');
        }

        const customData = Ember.Object.create({
          taxNumber: this.get('document._data.taxNumber'),
          companyName: this.get('document._data.companyName'),
          email: this.get('document._data.email'),
          telephone,
          address: this.get('document._data.address'),
          twEInvoiceCarrierType: this.get('document._data.twEInvoiceCarrierType'),
          twEInvoiceCarrierId: this.get('document._data.twEInvoiceCarrierId'),
          twEInvoicePrinterId: null
        });
        this.set('custom', customData);
        const filters = this.search.setupFilters({
          adapterName: this.filtersAdapterName,
          defaultFiltersData: {}
        });
        this.set('filters', filters);
      }
    },

    isAddressRequired: Ember.computed('custom.taxNumber', function () {
      if (this.get('custom.taxNumber')) {
        return true;
      }

      return false;
    }),
    printBtnLabel: Ember.computed('hasPrintBtnLabel', function () {
      if (!this.hasPrintBtnLabel) {
        return '';
      }

      return 'tw_print_eInvoices';
    }),
    fetchToIssueEInvoicesTask: (0, _emberConcurrency.task)(function* () {
      this.printer.fetchOnlinePrintersTask.perform();
      yield this.eInvoicesService.fetchToIssueEInvoicesTask.perform({
        component: this,
        appendPath: this.document._data._key,
        filters: this.filters
      });
    }).drop()
  });

  _exports.default = _default;
});