define("client/pods/contacts/persons/model", ["exports", "client/pods/base/model", "client/mixins/contacts-is-parent", "client/mixins/contacts-details"], function (_exports, _model, _contactsIsParent, _contactsDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_contactsIsParent.default, _contactsDetails.default, {
    componentPath: 'contacts/persons/editor-person',
    component: Ember.computed('componentPath', function () {
      return this.componentPath;
    }),
    defaultForDocs: Ember.computed('_data.{isDefault,isDefaultFor,isDefaultForDocs.[]}', function () {
      if (this.get('_data.isDefault')) {
        if (this.get('_data.isDefaultFor') === 'all') {
          return ['all'];
        }

        return this.get('_data.isDefaultForDocs') || [];
      }

      return [];
    })
  });

  _exports.default = _default;
});