define("client/pods/components/documents/btn-refund/component", ["exports", "@glimmer/component", "client/pods/components/documents/docConfig", "ember-concurrency-decorators", "ramda-adjunct", "ramda-extension", "ramda"], function (_exports, _component, _docConfig, _emberConcurrencyDecorators, RA, R_, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentsBtnRefundComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('date'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._action, (_class = class DocumentsBtnRefundComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "constants", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "dateService", _descriptor3, this);

      _initializerDefineProperty(this, "documents", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "search", _descriptor6, this);

      _initializerDefineProperty(this, "server", _descriptor7, this);

      _initializerDefineProperty(this, "hasReturns", _descriptor8, this);

      _initializerDefineProperty(this, "hasNoRemainingQtys", _descriptor9, this);

      _initializerDefineProperty(this, "dirty", _descriptor10, this);

      _initializerDefineProperty(this, "returnsByDocNo", _descriptor11, this);

      _initializerDefineProperty(this, "quickLinkFromDocType", _descriptor12, this);

      _initializerDefineProperty(this, "quickLinkFromDocName", _descriptor13, this);

      _initializerDefineProperty(this, "quickLinkToDocType", _descriptor14, this);

      _initializerDefineProperty(this, "quickLinkToDocName", _descriptor15, this);

      _initializerDefineProperty(this, "showCancelInvoiceModal", _descriptor16, this);

      _defineProperty(this, "filtersAdapterName", 'documents/filters');

      _defineProperty(this, "title", `${this.intl.t('new')}: ${this.intl.t('sales-returns')}`);

      this.setupTask.perform();
    }

    get quickLinkToAdapterName() {
      return `documents/${this.quickLinkToDocType}/${this.quickLinkToDocName}`;
    }

    *setupTask() {
      const allowReturns = R_.dotPath('args.model.allowReturns')(this);
      this.quickLinkFromDocType = R_.dotPathOr('sales', 'args.quickLinkFromDocType')(this);
      this.quickLinkFromDocName = R_.dotPathOr('invoices', 'args.quickLinkFromDocName')(this);
      this.quickLinkToDocType = R_.dotPathOr('sales', 'args.quickLinkToDocType')(this);
      this.quickLinkToDocName = R_.dotPathOr('returns', 'args.quickLinkToDocName')(this);

      const config = _docConfig.default.getFor({
        docType: this.quickLinkToDocType,
        docName: this.quickLinkToDocName
      });

      const tab = Ember.Object.create({
        config
      });
      this.tab = tab;
      this.adapterName = R_.dotPath('tab.config.adapterName')(this);
      const model = this.args.model;
      const master = model.getData('_key');
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      filters.setData('master', master);
      this.filters = filters;

      if (allowReturns) {
        yield this.fetchReturnsByDetailKeyTask.perform();
      }
    }

    *fetchReturnsByDetailKeyTask() {
      const docType = this.quickLinkFromDocType;
      const docName = this.quickLinkFromDocName;
      const toDocType = this.quickLinkToDocType;
      const toDocName = this.quickLinkToDocName;
      const model = this.args.model;
      const returnsByDetailKey = yield this.documents.hasReturns.perform(model, {
        docType,
        docName,
        toDocType,
        toDocName
      });
      this.hasReturns = RA.isNotNilOrEmpty(returnsByDetailKey);
      this.returnsByDocNo = R.pipe(R.values, R.pluck('docData'), R.flatten, R.groupBy(R.prop('docNo')), R.map(groupData => {
        const first = R.head(groupData);
        first.details = R.pipe(R.pluck('details'), R.flatten)(groupData);
        return first;
      }), R.values)(returnsByDetailKey);
      this.hasNoRemainingQtys = R.pipe(R.pathOr([], ['_data', 'details']), R.reduce((acc, detail) => {
        if (acc === false) {
          return false;
        }

        const originalQty = parseInt(detail.getData('qty')) || 0;
        const detailKey = detail.getData('_key');
        const allReturnedQty = parseInt(R.path([detailKey, 'qty'])(returnsByDetailKey)) || 0;
        return allReturnedQty >= originalQty;
      }, true))(model);
      return returnsByDetailKey;
    }

    *setupDirtyTask() {
      const model = this.args.model;
      const hasAutoCancelInvoice = R.pathOr(false, ['lastSuccessfulEInvoice', '_data', 'number'], model);
      const dirty = this.crud.setupDirty({
        adapterName: model.adapterName,
        model
      });
      dirty.set('hasAutoCancelInvoice', hasAutoCancelInvoice);
      dirty.set('isAutoCancelInvoice', !!hasAutoCancelInvoice);

      this._setupCreateRefundWorkflow(dirty);

      this.dirty = dirty;
    }

    _setupCreateRefundWorkflow(dirty) {
      let createRefundWorkflow = false;

      if (dirty.isSalesInvoices || dirty.isSalesReturns) {
        const inclTotal = dirty?._data?.inclTotal;

        if (dirty?._data?.paymentMethodKey === 'cash' && dirty?._data?.dispatchProcess === 'dispatched') {
          createRefundWorkflow = true;
          dirty.setData('createRefundWorkflowAmount', inclTotal);
        }

        if (dirty?._data?.paymentMethodKey === 'cashBeforeDelivery' && dirty?._data?.dispatchProcess !== 'awaitingPayment') {
          createRefundWorkflow = true;
          dirty.setData('createRefundWorkflowAmount', inclTotal);
        }
      }

      dirty.setData('createRefundWorkflow', createRefundWorkflow);
      return dirty;
    }

    *setupDirtyReturnsTask(adapterName) {
      const model = this.args.model;
      const hasAutoCancelInvoice = R.pathOr(false, ['lastSuccessfulEInvoice', '_data', 'number'], model);
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model
      });

      this._setupCreateRefundWorkflow(dirty);

      dirty.set('hasAutoCancelInvoice', hasAutoCancelInvoice);
      dirty.set('isAutoCancelInvoice', !!hasAutoCancelInvoice);
      const returnsByDetailKey = yield this.fetchReturnsByDetailKeyTask.perform();
      const contactKey = dirty.getData('contactKey');

      if (contactKey) {
        const contact = yield this.crud.findTask.perform({
          adapterName: 'contacts',
          appendPath: contactKey
        });
        this.selectedContact = contact;
        dirty.set('selectedContact', contact);
      }

      dirty.setData('docNo', '');
      dirty.setData('docType', this.quickLinkToDocType);
      dirty.setData('docName', this.quickLinkToDocName);
      dirty.setData('fromDocContactRewardsData', model.getData('contactRewardsData') || []); // link details

      const details = dirty.getData('details');
      const newDetails = R.map(detail => {
        const originalQty = parseInt(detail.getData('qty')) || 0;
        const detailKey = detail.getData('detailKey');
        const allReturnedQty = parseInt(R.path([detailKey, 'qty'])(returnsByDetailKey)) || 0;
        const maxQty = originalQty - allReturnedQty;
        detail.setData('maxQty', maxQty);
        detail.setData('docType', this.quickLinkToDocType);
        detail.setData('docName', this.quickLinkToDocName);
        detail.setData('allReturnedQty', allReturnedQty);
        const docRef = detail.getData('doc.ref') || model.getData('ref');
        detail.setData('fromRef', docRef);
        detail.setData('originalQty', originalQty);
        detail.setData('qty', 0); // always use original price

        detail.setData('originalPrice', detail.getData('price'));
        detail.setData('price', detail.getData('price'));
        detail.setData('exclPrice', detail.getData('discExclPrice'));
        detail.setData('inclPrice', detail.getData('discInclPrice'));
        detail.setData('fromDocType', model.getData('docType'));
        detail.setData('fromDocName', model.getData('docName'));
        detail.setData('fromDocNo', model.getData('docNo'));
        detail.setData('fromDocKey', model.getData('_key'));
        detail.setData('fromMaster', model.getData('_key'));
        detail.setData('fromDetail', detail.getData('_key'));
        detail.setData('fromDetailKey', detailKey); // @NOTE: ensure has paymentCreditCardTransActionCode

        if (dirty.get('isAllowPartialCreditCardRefund')) {
          detail.setData('paymentCreditCardStatus', this.constants.paymentCreditCardStatus.refundRequested);

          if (dirty.getData('paymentCreditCardTransactionCode') && !detail.getData('paymentCreditCardTransactionCode')) {
            detail.setData('paymentCreditCardTransactionCode', dirty.getData('paymentCreditCardTransactionCode'));
            detail.setData('paymentCreditCardTransactionTimestampZ', dirty.getData('paymentCreditCardTransactionTimestampZ'));
          }
        }

        return detail;
      })(details);
      dirty.setData('details', newDetails);
      dirty.sumTotals();
      this.dirty = dirty;
    }

    createReturnsRecordTask(_ref) {
      var _this = this;

      let {
        appendPath = 'final',
        onAfter
      } = _ref;
      return function* () {
        const adapterName = _this.adapterName;
        const dirty = _this.dirty;
        dirty.setData('dispatchStatus', '');
        dirty.setData('dispatchProcess', '');
        dirty.setData('dispatchDateZ', '');
        dirty.setData('waybill', '');
        dirty.setData('created', '');
        dirty.setData('modified', '');
        dirty.setData('dateZ', '');
        dirty.setData('timestampZ', '');
        dirty.setData('timestampZ_latest', '');
        const details = R.pathOr([], ['_data', 'details'])(dirty);
        const nonZeroDetails = R.pipe(R.map(detail => {
          if (detail?._data?.isSetChild) {
            // if (!detail?._data?.isSetWithChoicesChild) {
            const needle = [R.pathEq(['_data', 'index'], detail?._data?.index), R.pathEq(['_data', 'itemKey'], detail?._data.isSetChildOf)]; // } else if (detail?._data?.isSetWithChoicesChild) {
            //   needle = [
            //     R.propEq('childIndex', detail?._data?.childIndex),
            //     R.propEq('itemKey', detail?._data.isSetChildOf)
            //   ]
            // }

            const parent = R.find(R.allPass(needle))(details);
            const parentQty = parent?._data?.qty || 0;

            if (parentQty) {
              const childQty = detail?._data?.childQty || 1;
              const qty = childQty * parentQty;
              detail.setData('qty', qty);
            }
          }

          return detail;
        }), R.filter(detail => {
          // dont filter out child items
          const qty = parseInt(R.path(['_data', 'qty'])(detail)) || 0;
          return R.gt(qty)(0);
        }))(details);
        dirty.setData('details', nonZeroDetails);
        const isAutoCancelInvoice = dirty.get('isAutoCancelInvoice');
        return yield _this.crud.createRecordTask.perform({
          adapterName,
          appendPath,
          model: dirty,
          rawResponse: true,
          onAfter,
          onAfterOptions: {
            isAutoCancelInvoice,
            wasSaved: true
          }
        });
      }();
    }

    onAfter(onCloseDialogAction, saved) {
      let savedOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }

      this.fetchReturnsByDetailKeyTask.perform(); // @Derek
      // @NOTE: this prevents onAfter from being called, which will not ask e-invoices to be cancelled
      // think of a better way to do this
      // if (this.args.onRefresh) {
      //   const model = this.args?.model
      //   this.args?.onRefresh?.(model)
      // }

      if (this.args.onAfterReturns) {
        this.args.onAfterReturns(saved, savedOptions);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "documents", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hasReturns", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hasNoRemainingQtys", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "returnsByDocNo", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "quickLinkFromDocType", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "quickLinkFromDocName", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "quickLinkToDocType", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "quickLinkToDocName", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "showCancelInvoiceModal", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchReturnsByDetailKeyTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchReturnsByDetailKeyTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupDirtyTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirtyTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupDirtyReturnsTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirtyReturnsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createReturnsRecordTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "createReturnsRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAfter", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onAfter"), _class.prototype)), _class));
  _exports.default = DocumentsBtnRefundComponent;
});