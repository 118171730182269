define("client/pods/form-container/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FormContainerModel extends _model.default {}

  _exports.default = FormContainerModel;
});