define("client/pods/components/modals/modal-overlay/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    tagName: '',
    modalClassNames: '',
    closeOnOverlayClick: true,

    close() {
      if (this.closeOnOverlayClick) {
        Ember.set(this, 'toggle', false);

        if (this.onClose) {
          if (R.is(Function, this.onClose)) {
            this.onClose();
          } else {
            // @TODO: fix all instances where modal-container-new is used and need to just pass in one function
            // this just supresses errors, some places boolean is passed in
            console.log('onClose is not a function');
          }
        }

        if (this.onToggle) {
          // this is a temp fix
          if (R.is(Function, this.onToggle)) {
            this.onToggle();
          } else {
            // @TODO: fix all instances where modal-container-new is used and need to just pass in one function
            // this just supresses errors, some places boolean is passed in
            console.log('onToggle is not a function');
          }
        }
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "close", [_dec], Object.getOwnPropertyDescriptor(_obj, "close"), _obj)), _obj)));

  _exports.default = _default;
});