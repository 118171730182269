define("client/pods/uploads/collection/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/uploads/collection/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/uploads/collection',
    modelObj: _model.default,
    idParam: null,
    // [resource, resourceKey]
    idPrefix: 'uploads',

    init() {
      this.set('idParam', ['resource', 'resourceKey']);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});