define("client/pods/components/extensions/apps/tw/shopee/products/component", ["exports", "@glimmer/component", "client/utils/nventor", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _nventor, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwShopeeProductsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('search'), _dec3 = Ember.inject.service('date'), _dec4 = Ember.inject.service('server'), _dec5 = Ember.inject.service('tabsManager'), _dec6 = Ember.inject.service('extensions/apps/tw/shopee/products'), _dec7 = Ember.inject.service('extensions/apps/tw/e-invoices'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class ExtensionsAppsTwShopeeProductsComponent extends _component.default {
    // @service('extensions/apps/tw/e-invoices') eInvoicesService
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "searchService", _descriptor2, this);

      _initializerDefineProperty(this, "dateService", _descriptor3, this);

      _initializerDefineProperty(this, "serverService", _descriptor4, this);

      _initializerDefineProperty(this, "tabsManagerService", _descriptor5, this);

      _initializerDefineProperty(this, "shopeeProductsService", _descriptor6, this);

      _initializerDefineProperty(this, "eInvoicesService", _descriptor7, this);

      _initializerDefineProperty(this, "tab", _descriptor8, this);

      _initializerDefineProperty(this, "results", _descriptor9, this);

      _initializerDefineProperty(this, "shopeeProductsFilters", _descriptor10, this);

      _initializerDefineProperty(this, "batchStatus", _descriptor11, this);

      _initializerDefineProperty(this, "batchStatusAlwaysVisible", _descriptor12, this);

      _defineProperty(this, "extensionsAppsTwShopeeListsProductsSyncSubPanels", 'extensions-apps-tw-shopee-lists-products-sync-sub-panels');

      this.crud.addLists(this, ['statuses', 'extensions/apps/tw/shopee/sync/status', 'extensions/apps/tw/shopee/lists/products/sync-sub-panels']);
      this.shopeeProductsFilters = this.searchService.setupFilters({
        adapterName: 'extensions/apps/tw/shopee/products/filters',
        defaultFiltersData: {
          campaignKey: this.args?.model?._data?._key
        }
      });
    }

    get importOrdersSubPanels() {
      const importOrdersSubPanels = this.crud.lists[this.extensionsAppsTwShopeeListsProductsSyncSubPanels] || [];
      return importOrdersSubPanels.map(subPanelModel => {
        if (subPanelModel._data.value === 'products') {
          subPanelModel.set('_data.badge', this.productsBadge);
        }

        return subPanelModel;
      });
    }

    get selectedProducts() {
      return R.pipe(R.propOr([], 'results'), R.filter(R.pathEq(['isSelected'], true)))(this);
    }

    get downloadBadge() {
      const allowDownload = R.pipe(R.propOr([], 'selectedProducts'), R.filter(R.propEq('allowDownload', true)), R.length)(this);

      if (allowDownload >= 1) {
        return allowDownload;
      }

      return '';
    }

    afterLoadLists() {
      this.tab = this.tabsManagerService.getActiveSubTab(this.args.parentTab);

      const initialPanel = _nventor.default.safeHeadOr({}, this.crud.lists[this.extensionsAppsTwShopeeListsProductsSyncSubPanels]);

      this.tabsManagerService.displaySubPanel(this, initialPanel);
    }

    resetBatchStatus() {
      this.batchStatus = null;
    }

    *onClickShopeeSyncTask() {
      this.afterLoadLists();
      yield this.reloadSearchRecordsTask.perform();
    }

    syncProducts(_ref) {
      var _this = this;

      let {
        batch = [],
        options = {},
        appendPath = ''
      } = _ref;
      return function* () {
        debugger;
        appendPath = `${appendPath}/batch`;
        let params = new URLSearchParams();

        for (let key in options) {
          const hasValue = RA.isNotNilOrEmpty(options[key]);

          if (hasValue) {
            params.append(key, options[key]);
          }
        }

        const queryParams = params.toString();

        if (queryParams) {
          appendPath += `?${queryParams}`;
        }

        const selectedProducts = R.filter(R.propEq('isSelected', true))(batch);
        const hasSelectedProducts = RA.isNotNilOrEmpty(selectedProducts);
        const toSyncProducts = hasSelectedProducts ? selectedProducts : batch;
        const isUpload = appendPath.includes('upload');
        const results = yield _this.shopeeProductsService.syncProducts.perform(toSyncProducts, appendPath);
        _this.batchStatus = results;

        if (!isUpload) {
          _this.results = results;
        }
      }();
    }

    reloadSearchRecordsTask() {
      var _this2 = this;

      let {
        filters
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        filters = filters || _this2.shopeeProductsFilters;
        _this2.results = yield _this2.shopeeProductsService.fetchShopeeProducts.perform({
          filters
        });
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "searchService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "serverService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tabsManagerService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "shopeeProductsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "eInvoicesService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tab", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "results", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "shopeeProductsFilters", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "batchStatus", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "batchStatusAlwaysVisible", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "afterLoadLists", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "afterLoadLists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetBatchStatus", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "resetBatchStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickShopeeSyncTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onClickShopeeSyncTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "syncProducts", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "syncProducts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadSearchRecordsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "reloadSearchRecordsTask"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwShopeeProductsComponent;
});