define("client/pods/payments/virtual-accounts/lists/statuses/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        label: 'atm_approved',
        value: 'approved'
      }, {
        label: 'atm_pending',
        value: 'pending'
      }, {
        label: 'atm_expired',
        value: 'expired'
      }, {
        label: 'atm_attentionRequired',
        value: 'attentionRequired'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});