define("client/pods/components/extensions/apps/tw/e-invoices/tab-container/component", ["exports", "client/mixins/date", "client/mixins/big", "client/pods/components/extensions/apps/tw/e-invoices/mixins/invoices", "client/mixins/crud", "client/mixins/e-invoices-list-by"], function (_exports, _date, _big, _invoices, _crud, _eInvoicesListBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, _eInvoicesListBy.default, _invoices.default, _big.default, _date.default, (_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    settingsService: Ember.inject.service('settings'),
    model: null,
    errors: null,
    customDateZ: '',
    adapterName: 'extensions/apps/tw/e-invoices',
    filtersAdapterName: 'extensions/apps/tw/e-invoices/filters',
    hasManagementBtns: true,
    selectedProvider: 'bigOrdr',

    init() {
      this.crud.addLists(this, ['periods/months', 'periods/years', 'periods/date-options', 'extensions/apps/tw/e-invoices/lists/providers']);

      this._super(...arguments);

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      const pastDays = this.dateService.getPastDays(14);
      this.set('pastDays', pastDays);
      const pastMonths = this.dateService.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      const defaultEInvoiceProvider = this.settingsService.getDefaultEInvoiceProvider();
      this.set('selectedProvider', defaultEInvoiceProvider);
      this.set('hasDate', false);
    },

    afterSearchInputTask() {
      const subTabOptions = {
        component: 'extensions/apps/tw/e-invoices/panels-display'
      };

      if (!this.hasDate) {
        this.set('hasStatement', false);
      }

      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    displaySummaryPanel(_ref) {
      let {
        dateStart,
        dateEnd
      } = _ref;
      const period = this.dateService.getPeriod(dateEnd);
      const currentInvoiceMonth = this.dateService.getEvenInvoiceMonth(period.mm);
      const currentInvoicePeriod = this.dateService.getPeriod(`${period.yyyy}-${currentInvoiceMonth}-01`);
      currentInvoicePeriod.dateStart = dateStart;
      currentInvoicePeriod.dateEnd = dateEnd;
      const subTabOptions = {
        component: 'extensions/apps/tw/e-invoices/panels-display',
        period: currentInvoicePeriod
      };
      this.set('hasStatement', true);
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    actions: {
      /**
       * display dispatched list for given date
       * @param  {string} date (YYYY-MM-DD)
       */
      displayByMonth(period) {
        // cleanse query before displaying by month
        const filters = this.filters;
        filters.setData('query', '');
        const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm)); // this.get('displaySummaryPanelTask').perform({ dateEnd, dateStart })

        this.set('hasDate', true);
        this.displaySummaryPanel({
          dateEnd,
          dateStart
        });
        this.set('mm', period.mm);
        this.set('yyyy', period.yyyy);
      },

      displayByCustomMonth(mm, yyyy) {
        // cleanse query before displaying by month
        const filters = this.filters;
        filters.setData('query', '');
        const dateStart = this.formatDateForUrl(`${yyyy}-${mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(yyyy, mm)); // this.get('displaySummaryPanelTask').perform({ dateEnd, dateStart })

        this.set('hasDate', true);
        this.displaySummaryPanel({
          dateEnd,
          dateStart
        });
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "afterSearchInputTask", [_dec], Object.getOwnPropertyDescriptor(_obj, "afterSearchInputTask"), _obj)), _obj)));

  _exports.default = _default;
});