define("client/pods/components/elements/element-btn/component", ["exports", "client/mixins/has-dropdown", "client/pods/components/elements/mixins/has-date-picker"], function (_exports, _hasDropdown, _hasDatePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_hasDropdown.default, _hasDatePicker.default, {
    tagName: 'button',
    attributeBindings: ['disabled', 'tabindex', 'style'],
    tabindex: 0,
    translate: true,
    classNames: ['button', 'element-btn', 'u-tappable'],
    btnClassNames: null,
    classNameBindings: ['disabled', 'toggleIsOn:on:off', 'isRunning:is-loading', 'isOn:is-on', 'isActivated:is-primary'],
    isRunning: false,
    datePickerClassName: null,
    iconClassName: null,
    toggleIsOn: false,
    isToggler: false,
    hasModalDialog: false,
    modalDialogIsVisible: false,
    toggleOnIconClassName: 'fas fa-caret-up',
    toggleOffIconClassName: 'fas fa-caret-down',
    bubbles: false,
    hasToggleIcon: true,
    isPrintBtn: false,
    hasBadge: true,
    hasIconSpacers: true,
    _tooltipTranslate: Ember.computed('tooltipTranslate', function () {
      const tooltipTranslate = this.get('tooltipTranslate');

      if (tooltipTranslate === false) {
        return false;
      }

      if (tooltipTranslate === true) {
        return true;
      }

      return this.translate;
    }),
    hasBadgeComputed: Ember.computed('hasBadge', function () {
      if (this.hasBadge === null || this.hasBadge === undefined) {
        return true;
      }

      return Boolean(this.hasBadge);
    }),
    submit: function (e) {
      if (this.isPrintBtn) {
        this.printDialog();
      } else {
        if (this.disabled !== true) {
          if (this.hasModalDialog) {
            this.showModalDialog();
          }

          this._submit(e);
        }
      }
    },

    _submit(e) {
      if (this.onPreSubmit) {
        this.onPreSubmit(e, this);
      }

      var isToggler = this.isToggler;

      if (isToggler) {
        this.toggleProperty('toggleIsOn');
      }

      if (this.isDatePicker) {
        this.showDatePicker();
      }

      if (this.onSubmit) {
        if (this.requiresConfirmation) {
          const msg = this.confirmMsgTranslate ? this.intl.t(this.confirmMsg) : this.confirmMsg || this.intl.t('are you sure you want to delete');
          const confirmed = window.confirm(msg);

          if (confirmed) {
            return this.onSubmit(e, this);
          }
        } else {
          this.onSubmit(e, this);
        }
      }
    },

    click: function (e) {
      if (this.bubbles !== true) {
        e.stopPropagation();
      }

      this.submit(e);
    },
    keyUp: function (e) {
      if (this.bubbles !== true) {
        e.stopPropagation();
      }

      if (e.which === 13) {
        this.submit(e);
      }
    },

    showModalDialog() {
      this.set('modalDialogIsVisible', true);
    },

    hideModalDialog() {
      this.set('modalDialogIsVisible', false);
    },

    printDialog() {
      window.print();
    },

    actions: {
      submit() {
        if (this.isPrintBtn) {
          this.printDialog();
        } else {
          this.submit(...arguments);
        }
      }

    }
  });

  _exports.default = _default;
});