define("client/pods/components/dispatch/import-btn/component", ["exports", "client/mixins/crud", "client/mixins/date", "client/mixins/transporter"], function (_exports, _crud, _date, _transporter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, _transporter.default, {
    crud: Ember.inject.service(),
    excel: Ember.inject.service(),
    tagName: 'span',
    showImportModal: false,
    showImportPaidModal: false,
    isDispatchImported: false,
    isPaidImported: false,
    templateFilename: '',
    sheets: null
  });

  _exports.default = _default;
});