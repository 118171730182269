define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/bulk-discount-conflicts/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "server", _descriptor2, this);

      _initializerDefineProperty(this, "wasSaved", _descriptor3, this);
    }

    get totalConflicts() {
      const conflictsList = this.args.conflict.getData('conflictsList') || [];
      return R.length(conflictsList);
    }

    updateCampaignDetailBulkDiscountTask(_ref) {
      var _this = this;

      let {
        model
      } = _ref;
      return function* () {
        // model is campaignDetailModel
        Ember.set(_this, 'wasSaved', false);
        const bulkDiscountId = model.getData('bulkDiscountId') || '';
        const campaignModel = _this.args?.model || {};

        if (_ramdaAdjunct.default.isNotNilOrEmpty(campaignModel)) {
          const key = campaignModel.getData('_key');
          const campaignDetailMaster = model.getData('master') || '';

          if (!campaignDetailMaster) {
            model.setData('master', key);
          }
        }

        try {
          const response = yield _this.crud.replaceRecordTask.perform({
            adapterName: 'channels/shop/campaigns/details',
            appendPath: '/bulk-discount-conflict/skip',
            model,
            component: _this
          }); // need to return false/empty after done

          Ember.set(_this, 'wasSaved', true);
          const conflictsList = response.getData('conflictsList') || [];
          R.forEach(conflict => {
            const conflictBulkDiscountId = conflict.getData('bulkDiscountId') || '';

            if (conflictBulkDiscountId === bulkDiscountId) {
              conflict.setData('isCurrent', true);
            } else {
              conflict.setData('isCurrent', false);
            }
          })(conflictsList);
          const detailModel = _this.args?.detailModel || {};

          if (_ramdaAdjunct.default.isNotNilOrEmpty(detailModel)) {
            Ember.set('detailModel', 'isShowBulkDiscountConflictDialogInDetailRow', false);
            Ember.set('detailModel', 'isShowBulkDiscountConflictDialogInDetailEditor', false);
          }
        } catch (e) {
          Ember.set(_this, 'wasSaved', false);
        }
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "wasSaved", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateCampaignDetailBulkDiscountTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateCampaignDetailBulkDiscountTask"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountConflictsComponent;
});