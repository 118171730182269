define("client/pods/components/updates/update-notify-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K7THDdHH",
    "block": "{\"symbols\":[\"onCloseDialogAction\"],\"statements\":[[0,\"\\n\"],[4,\"elements/element-btn-with-modal-dialog\",null,[[\"label\",\"translate\"],[\"notify update\",false]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"elements/element-input\",null,[[\"disabled\",\"translatedPlaceholder\",\"value\"],[true,\"version\",[24,[\"version\"]]]]],false],[0,\"\\n\\n    \"],[1,[28,\"elements/element-btn\",null,[[\"translate\",\"label\",\"onSubmit\"],[false,\"notify update\",[28,\"action\",[[23,0,[]],\"notifyUpdate\",[23,1,[]]],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/updates/update-notify-btn/template.hbs"
    }
  });

  _exports.default = _default;
});