define("client/pods/components/emails/panels-scheduled/component", ["exports", "client/mixins/crud", "ember-concurrency", "client/pods/components/emails/mixins/emails-list-by", "client/mixins/doc-status"], function (_exports, _crud, _emberConcurrency, _emailsListBy, _docStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DocsListByMixin from '../../../../mixins/doc-list-by'
  var _default = Ember.Component.extend(_crud.default, _docStatus.default, _emailsListBy.default, {
    crud: Ember.inject.service(),
    filtersAdapterName: 'emails/filters',

    init() {
      this.crud.addLists(this, ['emails/lists/menu/notifications']);

      this._super(...arguments);

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          sort: [{
            by: 'paymentDueDateZ',
            direction: 'ASC'
          }]
        }
      });
      this.set('filters', filters);
      this.fetchScheduledTask.perform();
    },

    fetchScheduledTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.filters;
      const results = yield this.listByTask.perform({
        adapterName: 'documents/sales/invoices',
        appendPath: 'emails/payments',
        filters
      });
      this.set('results', results);
    })
  });

  _exports.default = _default;
});