define("client/pods/components/settings/settings-editor/beta-editor/component", ["exports", "ramda-extension", "ember-concurrency"], function (_exports, R_, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    users: Ember.inject.service(),
    crud: Ember.inject.service(),
    contacts: Ember.inject.service(),
    transporterService: Ember.inject.service('transporter'),
    applicationService: Ember.inject.service('application'),

    init() {
      this._super(...arguments);

      if (!this.get('model._data.beta')) {
        this.set('model._data.beta', {});
      }

      if (this.model._data?.beta?.mockWaybillsTransporterKey) {
        this.fetchTransporterTask.perform(this.model._data.beta.mockWaybillsTransporterKey);
      }
    },

    isAdmin: Ember.computed('users.{isAdmin}', function () {
      return this.users.getProp('isAdmin');
    }),
    transportersListNew: Ember.computed('externalTransportersList', 'externalTransportersList.[]', 'listsIsLoaded', 'crud.lists.contacts-transporters.[]', 'externalTransportersList.[]', function () {
      if (this.externalTransportersList) {
        return this.externalTransportersList || [];
      }

      return R_.dotPath('crud.lists.contacts-transporters')(this) || [];
    }),
    searchTransporterInputTask: (0, _emberConcurrency.task)(function* () {
      const options = {
        relationship: 'supplier',
        query: this.model.transporterLabel,
        resultsProperty: 'externalTransportersList',
        component: this,
        match: ['name', 'code', 'taxNumber']
      };
      const transporters = yield this.contacts.searchInputTask.perform(options);
      console.log(transporters);
      this.set('externalTransportersList', transporters);
    }).drop(),
    fetchTransporterTask: (0, _emberConcurrency.task)(function* (transporterKey) {
      this.transporterModel = yield this.transporterService.fetchTransporterTask.perform({
        transporterKey
      });
      this.model.set('transporterLabel', this.transporterModel?._data?.name);
    }).drop(),

    unsetMockWaybillsTransporterKey(model) {
      model.set('_data.beta.mockWaybillsTransporterKey', '');
    },

    setMockWaybillsTransporterKey(model, transporterModel) {
      const transporterKey = transporterModel?._data?._key;
      model.set('_data.beta.mockWaybillsTransporterKey', transporterKey);
    }

  }, (_applyDecoratedDescriptor(_obj, "unsetMockWaybillsTransporterKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "unsetMockWaybillsTransporterKey"), _obj), _applyDecoratedDescriptor(_obj, "setMockWaybillsTransporterKey", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setMockWaybillsTransporterKey"), _obj)), _obj)));

  _exports.default = _default;
});