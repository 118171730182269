define("client/pods/components/files-manager/finder/image/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ember-concurrency", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, _emberConcurrency, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const DESKTOP = 'desktop';
  const TABLET = 'tablet';
  const MOBILE = 'mobile';
  let FilesManagerFinderImageComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('uploads'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class FilesManagerFinderImageComponent extends _component.default {
    /** @type {UploadsService} */
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "uploadsService", _descriptor3, this);

      _initializerDefineProperty(this, "mobile", _descriptor4, this);

      _initializerDefineProperty(this, "tablet", _descriptor5, this);

      _initializerDefineProperty(this, "desktop", _descriptor6, this);

      if (this.args.model) {
        this.setupModel();
      }
    }

    get hasSelected() {
      const isMobileSelected = this.isSelected(this.mobile);
      const isTabletSelected = this.isSelected(this.tablet);
      const isDesktopSelected = this.isSelected(this.desktop);
      return isMobileSelected || isTabletSelected || isDesktopSelected;
    }

    get hasAllSelected() {
      const isMobileSelected = this.isSelected(this.mobile);
      const isTabletSelected = this.isSelected(this.tablet);
      const isDesktopSelected = this.isSelected(this.desktop);
      return isMobileSelected && isTabletSelected && isDesktopSelected;
    }

    get isBackgroundType() {
      return R.pipe(R.pathOr(false, ['args', 'previousImage', 'imgSet', 'imgType']), R.equals('background-img'))(this);
    }

    isSelected(item) {
      return R.pipe(R.propOr(false, 'isSelected'), R.equals(true))(item);
    }
    /**
     * Toggle all devices isSelected to a specific boolean
     * @param {boolean} isSelected
     */


    toggleIsSelectedOnAll() {
      let isSelected = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const items = [this.desktop, this.tablet, this.mobile];
      R.pipe(R.forEach(item => {
        item.set('isSelected', isSelected);
      }))(items);
    }

    getSelectedName() {
      const isMobileSelected = this.isSelected(this.mobile);
      const isTabletSelected = this.isSelected(this.tablet);
      const isDesktopSelected = this.isSelected(this.desktop);

      if (isDesktopSelected) {
        return DESKTOP;
      }

      if (isTabletSelected) {
        return TABLET;
      }

      if (isMobileSelected) {
        return MOBILE;
      }
    }

    resetAllImages(dirty) {
      this.mobile = this.crud.setupDirty(dirty);
      this.tablet = this.crud.setupDirty(dirty);
      this.desktop = this.crud.setupDirty(dirty);
      this.toggleIsSelectedOnAll(true);
    }

    setCurrentSelected(model) {
      const imgSet = {
        mobile: this.mobile,
        tablet: this.tablet,
        desktop: this.desktop,
        desktopUrl: this.desktop.url,
        desktopUuid: this.desktop.getData('uuid'),
        tabletUrl: this.tablet.url,
        tabletUuid: this.tablet.getData('uuid'),
        mobileUrl: this.mobile.url,
        mobileUuid: this.mobile.getData('uuid')
      };
      model.set('imgSet', imgSet);
    }

    setupModel(model) {
      model = model || this.args.model;

      if (!model) {
        this.mobile = null;
        this.tablet = null;
        this.desktop = null;
        return;
      }

      const dirty = {
        adapterName: 'uploads',
        model
      };

      if (this.hasSelected && !this.hasAllSelected) {
        const selectedName = this.getSelectedName();
        this[selectedName] = this.crud.setupDirty(dirty);
        this[selectedName].set('isSelected', true);
      } else {
        this.resetAllImages(dirty);
      }

      if (model.isGif || this.isBackgroundType) {
        this.resetAllImages(dirty);
      }

      this.setCurrentSelected(model);
    }

    onSelectImg(model) {
      if (RA.isNotNilOrEmpty(model)) {
        if (model.isGif || this.isBackgroundType) {
          return;
        }

        this.toggleIsSelectedOnAll(false);
        model.set('isSelected', true);
      }
    }

    *fetchPreviousImagesTask() {
      const previousImage = this?.args?.previousImage;

      if (!previousImage) {
        return null;
      }

      const uuids = [R.pathOr('', ['imgSet', 'desktopUuid'])(previousImage), R.pathOr('', ['imgSet', 'tabletUuid'])(previousImage), R.pathOr('', ['imgSet', 'mobileUuid'])(previousImage)];
      const hasAllUuids = R.pipe(R.map(RA.isNilOrEmpty), R.all(R.equals(false)))(uuids);

      if (!hasAllUuids) {
        return null;
      }

      const devices = [DESKTOP, TABLET, MOBILE];
      const results = yield (0, _emberConcurrency.all)(R.map(uuid => this.uploadsService.fetchByUuid.perform(uuid))(uuids));
      R.pipe(R.map(result => {
        if (RA.isNilOrEmpty(result)) {
          return this.crud.setupNewRecord({
            adapterName: 'uploads',
            data: {}
          });
        }

        return result;
      }), R.flatten, R.forEachObjIndexed((uploadsModel, index) => {
        const device = devices[index];
        this[device] = this.crud.setupDirty({
          adapterName: 'uploads',
          model: uploadsModel
        });
      }))(results);
      this.toggleIsSelectedOnAll();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uploadsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "mobile", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tablet", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "desktop", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setCurrentSelected", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setCurrentSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupModel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setupModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectImg", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectImg"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchPreviousImagesTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchPreviousImagesTask"), _class.prototype)), _class));
  _exports.default = FilesManagerFinderImageComponent;
});