define("client/pods/components/modals/modal-container-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K3X72UUT",
    "block": "{\"symbols\":[\"@isSidebar\",\"@modelContainerClassNames\",\"@isDialog\",\"@modalContentClassNames\",\"@isRunning\",\"&default\",\"@isOverlayTransparent\",\"@onToggle\",\"@onToggleModalDialog\",\"@hasCustomBlock\"],\"statements\":[[4,\"if\",[[23,0,[\"isShowOverlay\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"id\",[29,[\"overlay-\",[23,0,[\"modalId\"]]]]],[12,\"class\",[29,[\"u-tappable tab-grid__modal-overlay modal-overlay \",[28,\"if\",[[23,7,[]],\"modal-overlay--clear\"],null]]]],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"modalsService\",\"closeOverlay\"]],[28,\"hash\",null,[[\"component\",\"isShowOverlay\",\"onToggleModalDialog\",\"onToggle\"],[[23,0,[]],[23,0,[\"isShowOverlay\"]],[23,9,[]],[23,8,[]]]]]],null]]],[8],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,10,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"modal-container\"],[8],[0,\"\\n    \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,5,[]]]],{\"statements\":[[0,\"\\n      \"],[14,6],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"id\",[29,[[23,0,[\"modalId\"]]]]],[12,\"class\",[29,[\"modal-container buttons are-small \",[28,\"if\",[[23,3,[]],\"modal-container--dialog\"],null],\" \",[23,2,[]],\" \",[28,\"if\",[[23,1,[]],\"modal-sidebar slide-in-right\"],null]]]],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"setupModal\"]]],null]]],[8],[0,\"\\n\"],[0,\"        \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-container__content \",[23,4,[]]]]],[8],[0,\"\\n          \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,5,[]]]],{\"statements\":[[0,\"\\n            \"],[14,6],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n\"],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/modals/modal-container-new/template.hbs"
    }
  });

  _exports.default = _default;
});