define("client/pods/components/payments/virtual-accounts/history/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // import * as R_ from 'ramda-extension'
  let PaymentsCardHistoryComponent = (_dec = Ember.inject.service('payments'), _dec2 = Ember._tracked, (_class = class PaymentsCardHistoryComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "paymentsService", _descriptor, this);

      _initializerDefineProperty(this, "doc", _descriptor2, this);

      this.setupTask.perform();
    }

    *setupTask() {
      const docKey = this.args.docKey;

      if (docKey) {
        const response = yield this.paymentsService.fetchHistory.perform(docKey);
        this.doc = response;
      } else {
        this.doc = this.args.model;
      }
    }

    get hasTransactions() {
      const payByLinkRequestsUnsuccessful = this.doc?._data?.payByLinkRequestsUnsuccessful || [];
      const allRefundsTransactions = this.doc?._data?.allRefundsTransactions || [];
      const inclTotal = this.doc?._data?.inclTotal;

      if (!RA.isNilOrEmpty(allRefundsTransactions) || inclTotal || !RA.isNilOrEmpty(payByLinkRequestsUnsuccessful)) {
        return true;
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "paymentsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "doc", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype)), _class));
  _exports.default = PaymentsCardHistoryComponent;
});