define("client/pods/emails/service", ["exports", "ramda", "client/utils/nventor"], function (_exports, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const self = {
    // @DEPRECATED
    // use applicationService
    _splitBy: R.curry((char, data) => {
      return R.pipe(R.split(char), R.map(R.trim), R.reject(_nventor.default.isNilOrEmpty))(data);
    }),

    // @DEPRECATED
    // use applicationService
    splitToArray() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      if (data == null) {
        return '';
      }

      return R.pipe(R.trim, R.replace(/,/g, ' '), R.replace(/\n/g, ' '), self._splitBy(' '))(data);
    },

    // @DEPRECATED
    // use applicationService
    joinAsString() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return R.pipe(_nventor.default.convertToArray, R.join('\n'))(data);
    }

  };

  var _default = Ember.Service.extend(self);

  _exports.default = _default;
});