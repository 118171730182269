define("client/pods/components/grapesjs/schedules-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Cp2yzO/",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@modalDialogIsVisible\",\"@onToggle\",\"@schedulesTatgetIsArray\",\"@tab\"],\"statements\":[[5,\"modals/modal-dialog\",[],[[\"@closeOnOverlayClick\",\"@modalDialogIsVisible\",\"@onToggle\",\"@title\"],[false,[23,2,[]],[23,3,[]],\"schedules\"]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"      \"],[5,\"forms/form-input-row\",[],[[\"@label\"],[\"apply schedules to all pages\"]],{\"statements\":[[0,\"\\n        \"],[5,\"elements/element-toggler\",[],[[\"@onToggle\",\"@toggleOffValue\",\"@toggleOnValue\",\"@toggleValue\"],[[28,\"fn\",[[23,0,[\"setSchedulesModelArray\"]]],null],false,true,[23,0,[\"applySchedulesToAll\"]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[5,\"grapesjs/schedules-modal/schedules-dialog\",[],[[\"@applySchedulesToAll\",\"@checkOverlapDate\",\"@onCloseDialogAction\",\"@schedulesModelArray\",\"@tab\"],[[23,0,[\"applySchedulesToAll\"]],[23,0,[\"checkOverlapDate\"]],[23,1,[]],[28,\"mut\",[[23,0,[\"schedulesModelArray\"]]],null],[23,5,[]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/footer-btns\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"layouts/btns-group\",[],[[\"@classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\n        \"],[5,\"elements/btn-cancel\",[],[[\"@onSubmit\"],[[23,1,[]]]]],[0,\"\\n\\n        \"],[5,\"elements/btn-save\",[],[[\"@disabled\",\"@icon\",\"@label\",\"@onSubmit\"],[[23,0,[\"saveDisabled\"]],false,\"done\",[28,\"perform\",[[23,0,[\"onSave\"]],[23,1,[]]],null]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/grapesjs/schedules-modal/template.hbs"
    }
  });

  _exports.default = _default;
});