define("client/pods/components/elements/color-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u8Isy0E4",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"elements/element-input\",null,[[\"readonly\",\"inputPvtClassNames\",\"value\"],[[24,[\"readonly\"]],\"color-picker__input\",[24,[\"value\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"elements/element-btn\",null,[[\"onSubmit\",\"classNames\",\"value\"],[[28,\"action\",[[23,0,[]],\"toggleColorPicker\"],null],[24,[\"colorPickerClassNames\"]],\"\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showColorPicker\"]]],null,{\"statements\":[[0,\"  \"],[5,\"color-picker\",[],[[\"@color\",\"@colorKey\",\"@model\",\"@toggleColorPicker\"],[[22,\"value\"],[22,\"colorKey\"],[22,\"model\"],[28,\"action\",[[23,0,[]],\"toggleColorPicker\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/color-picker/template.hbs"
    }
  });

  _exports.default = _default;
});