define("client/pods/components/payments/linepay/panels-display/panels-activity--delete/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hideExcl: true,
    actions: {
      toggleView() {
        this.toggleProperty('showDetails');
      }

    }
  });

  _exports.default = _default;
});