define("client/pods/products/lists/sync/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProductsListsSyncModel extends _model.default {}

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: ProductsListsSyncModel,
    idParam: false,

    findAll() {
      const data = [{
        value: 'item',
        label: 'name',
        translate: true
      }, {
        value: 'templates',
        label: 'templates',
        translate: true
      }, {
        value: 'productTags',
        label: 'tags',
        translate: true
      }, {
        value: 'productDisplayTags',
        label: 'display tags',
        translate: true
      }, {
        value: 'description',
        label: 'description',
        translate: true
      }, {
        value: 'additionalDescription',
        label: 'additional description',
        translate: true
      }];

      if (this.settings.getProp('beta.allowRecurringSchedules') && this.settings.getProp('features.allowRecurringSchedules')) {
        data.pushObject({
          value: 'recurringSchedules',
          label: 'recurring schedules',
          translate: true
        });
      }

      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});