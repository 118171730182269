define("client/pods/components/facebook/facebook-login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LIgQcksE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-input-row\",null,null,{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"classNames\",\"icon\",\"hasExchange\",\"disabled\",\"isRunning\",\"onSubmit\"],[[28,\"if\",[[24,[\"hasPagesList\"]],\"re-login\",\"login\"],null],\"facebook-login-btn\",\"fab fa-facebook-square\",[24,[\"hasExchange\"]],[24,[\"isReadonly\"]],[24,[\"loginTask\",\"isRunning\"]],[28,\"perform\",[[24,[\"loginTask\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/facebook/facebook-login/template.hbs"
    }
  });

  _exports.default = _default;
});