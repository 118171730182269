define("client/pods/components/calendar/calendar-day/component", ["exports", "ramda", "client/mixins/date"], function (_exports, R, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    tagName: '',
    isToday: Ember.computed('day.dateZ', 'todayZ', 'dateZ', function () {
      if (this.todayZ === this.get('day.dateZ')) {
        return true;
      }

      return false;
    }),
    schedule: Ember.computed('data.[]', 'day.dateZ', 'dateZ', function () {
      const data = this.data || [];
      const currentZ = this.get('day.dateZ');
      const schedule = R.filter(item => this.getStartOfDayZ(item._data.dateStartZ) <= currentZ && this.getStartOfDayZ(item._data.dateEndZ) >= currentZ)(data);
      return schedule;
    })
  });

  _exports.default = _default;
});