define("client/pods/components/elements/color-picker/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: [''],
    showColorPicker: false,

    didInsertElement() {
      const self = this;

      if (this.value) {
        self.setColorPreview(this.value);
      }
    },

    didUpdate() {
      this.setColorPreview(this.value);
    },

    setColorPreview(hexColor) {
      const isColor = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hexColor);

      if (isColor) {
        (0, _jquery.default)(this.element).children('.color-picker__btn').css('background-color', hexColor);
      }
    },

    willDestroyElement() {
      if (this?.isDestroyed === false) {
        if (this.picker) {
          this.picker.destroy();
        }
      }
    },

    colorPickerClassNames: Ember.computed('readonly', function () {
      let classNames = 'color-picker__btn';

      if (this.readonly) {
        classNames = `${classNames} is-readonly`;
      }

      return classNames;
    }),
    actions: {
      toggleColorPicker() {
        if (!this.readonly) {
          const onSelect = this.onSelect;

          if (onSelect) {
            onSelect(this.model);
          }

          this.set('showColorPicker', !this.showColorPicker);
        }
      },

      setColor(value) {
        if (!this.readonly) {
          this.set('value', value);
          this.setColorPreview(value);
          this.picker.set(value);
        }
      }

    }
  });

  _exports.default = _default;
});