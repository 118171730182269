define("client/pods/components/extensions/apps/tw/hct/export-btn/component", ["exports", "ramda", "ember-concurrency", "client/mixins/date"], function (_exports, R, _emberConcurrency, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    excel: Ember.inject.service(),
    tagName: '',
    transporterExtension: 'hct',
    exportTask: (0, _emberConcurrency.task)(function* (filter) {
      const today = this.formatDate(this.getToday(), 'YYYYMMDD');
      const specs = this.settings.getProp('tw_hct_export_instructions');
      const columns = R.pluck('column', specs);
      const props = R.pluck('prop', specs);
      const results = this.exportData(filter);
      const data = R.pipe(R.map(doc => {
        return R.map(prop => {
          if (prop === '_today_') {
            return today;
          }

          return R.propOr('', prop, doc);
        }, props);
      }), R.values, R.concat([columns]))(results);
      yield this.excel.exportFile({
        worksheets: [{
          sheetName: '1',
          data
        }],
        title: `新竹物流匯出範例-${today}`
      });
    }).drop(),

    exportData(filter) {
      let results = this.dispatchedResults;

      if (filter === 'hct') {
        results = R.filter(R.pathEq(['_data', 'transporterExtension'], 'hct'))(results);
      }

      return R.map(R.prop('_data'))(results);
    }

  });

  _exports.default = _default;
});