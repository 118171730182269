define("client/pods/options/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OptionContainerMixin = Ember.Mixin.create({
    isEditable: Ember.computed('_data._key', function () {
      const key = this.getData('_key');

      if (key != null && key !== '') {
        return true;
      }

      return false;
    }),

    /**
     * should determine if option should be translated
     */
    translate: Ember.computed('_data._key', function () {
      // @TODO: rename to 'isTranslate'
      const key = this.getData('_key');

      if (key != null && key !== '') {
        return false;
      }

      return true;
    })
  });

  var _default = _model.default.extend(OptionContainerMixin);

  _exports.default = _default;
});