define("client/pods/components/emails/status-tags/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    sorted: Ember.computed('events.[]', function () {
      const events = this.events || [];
      const sorted = R.pipe(R.groupBy(event => {
        return event.event;
      }), R.map(group => {
        return {
          count: R.length(group),
          last: R.last(group) || {},
          all: group
        };
      }), R.values, R.reject(R.isNil), R.sortBy(R.path(['last', 'timestampZ'])))(events);
      return sorted;
    }),
    hasEvents: Ember.computed('sorted.[]', function () {
      const sorted = this.sorted || [];

      if (sorted.length > 0) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});