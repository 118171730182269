define("client/pods/grapesjs/plugins/slider-native/components/SliderFrame", ["exports", "client/pods/grapesjs/plugins/slider-native/constants", "client/pods/grapesjs/plugins/slider-native/utils"], function (_exports, _constants, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(dc) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const defaultType = dc.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const {
      frameName,
      frameId,
      slidesSelector
    } = _constants.default;
    dc.addType(frameName, {
      model: defaultModel.extend({
        defaults: { ...defaultModel.prototype.defaults,
          name: 'Slider Frame',
          droppable: slidesSelector,
          selectable: false,
          style: {
            // width: '880px',
            margin: '0 auto',
            position: 'relative',
            overflow: 'hidden',
            'white-space': 'nowrap'
          },
          ...config.frameProps
        },

        init() {
          const cls = config.classFrame;
          this.get('classes').pluck('name').indexOf(cls) < 0 && this.addClass(cls);
        }

      }, {
        isComponent(el) {
          if ((0, _utils.elHasClass)(el, config.classFrame)) return {
            type: frameName
          };
        }

      }),
      view: defaultView
    });
  };

  _exports.default = _default;
});