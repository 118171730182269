define("client/pods/components/grapesjs/schedules-modal/schedules-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RnnziD8J",
    "block": "{\"symbols\":[\"scheduleModel\",\"index\",\"schedule\",\"@checkOverlapDate\",\"@applySchedulesToAll\",\"@isReadonly\",\"@schedulesModelArray\"],\"statements\":[[4,\"each\",[[23,7,[]]],null,{\"statements\":[[0,\"  \"],[5,\"forms/form-section\",[],[[\"@title\",\"@translate\"],[[28,\"if\",[[23,5,[]],\"\",[28,\"concat\",[[28,\"tt\",[\"page\"],null],\": \",[28,\"sum\",[[23,2,[]],1],null]],null]],null],false]],{\"statements\":[[0,\"\\n    \"],[7,\"table\",true],[10,\"class\",\"table-form table-form--with-date-picker table-form--no-last-border\"],[8],[0,\"\\n      \"],[7,\"thead\",true],[8],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"start date\"],null],false],[9],[0,\"\\n          \"],[7,\"th\",true],[10,\"colspan\",\"2\"],[8],[1,[28,\"tt\",[\"start time\"],null],false],[9],[0,\"\\n          \"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"end date\"],null],false],[9],[0,\"\\n          \"],[7,\"th\",true],[10,\"colspan\",\"2\"],[8],[1,[28,\"tt\",[\"end time\"],null],false],[9],[0,\"\\n          \"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"page status\"],null],false],[9],[0,\"\\n          \"],[7,\"th\",true],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"_data\",\"schedules\"]]],null,{\"statements\":[[0,\"          \"],[5,\"grapesjs/schedules-modal/schedules-dialog/schedules-row\",[],[[\"@checkOverlapDate\",\"@model\",\"@schedule\"],[[23,4,[]],[23,1,[]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\n        \"],[5,\"elements/element-btn\",[],[[\"@icon\",\"@label\",\"@disabled\",\"@onSubmit\"],[\"fas fa-plus\",\"add schedule\",[23,6,[]],[28,\"fn\",[[23,0,[\"onSubmitAddSchedule\"]],[23,1,[]]],null]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/grapesjs/schedules-modal/schedules-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});