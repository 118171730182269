define("client/pods/components/grapesjs/schedules-modal/component", ["exports", "ramda", "@glimmer/component", "ember-concurrency-decorators", "ramda-adjunct", "client/utils/nventor"], function (_exports, R, _component, _emberConcurrencyDecorators, _ramdaAdjunct, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GrapesjsSchedulesModalComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class GrapesjsSchedulesModalComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "schedulesModelArray", _descriptor2, this);

      _initializerDefineProperty(this, "applySchedulesToAll", _descriptor3, this);

      _initializerDefineProperty(this, "saveDisabled", _descriptor4, this);

      _initializerDefineProperty(this, "schedulesTargetContainerClass", _descriptor5, this);

      _initializerDefineProperty(this, "schedulesTatgetClass", _descriptor6, this);

      this.crud.addLists(this, ['statuses']);
      this.schedulesTargetContainerClass = this.args.schedulesTargetContainerClass || null;
      this.schedulesTatgetClass = this.args.schedulesTatgetClass || null;
      const schedulesTatgetIsArray = this.args.schedulesTatgetIsArray || false;
      this.applySchedulesToAll = !schedulesTatgetIsArray; // If is not array treat as apply to all
      // If is array default to false

      this.setSchedulesModelArray();
    }
    /**
     * Set schedules array from stored one
     * If only one object still treats as an array
     */


    setSchedulesModelArray() {
      let _applytoall = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      const selected = this.args.editor.getSelected();
      const selectedElement = selected.getEl(); // The outer container of the target

      let frame;

      if (this.schedulesTargetContainerClass) {
        const containerClass = `.${this.schedulesTargetContainerClass}`;
        frame = selectedElement.querySelector(containerClass);
      } // The target classname, will select all inside the target container


      let targets = [];

      if (frame && this.schedulesTatgetClass) {
        const targetClass = `.${this.schedulesTatgetClass}`;
        targets = frame.querySelectorAll(targetClass);
      }

      this.schedulesModelArray = R.map(child => {
        let childSchedules = child.getAttribute('data-gjs-model-schedule') || '{}'; // should be string

        childSchedules = _nventor.default.safeParseJSON(childSchedules);
        let applytoall = false;

        if (_applytoall === null) {
          let childApplytoall = child.getAttribute('data-gjs-model-applytoall');
          childApplytoall = _nventor.default.safeParseJSON(childApplytoall);
          applytoall = childApplytoall === 'true' || childApplytoall === true;
        } else {
          applytoall = _applytoall;
        }

        const elementData = R.pipe( // Get only the relevant data
        R.propOr({}, 'dataset'), R.toPairs, R.filter(str => R.test(/Model/)(str)), // dynamically rename keys
        R.map(R.over(R.lensPath([0]), function () {
          let key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [''];
          return R.pipe(R.split('Model'), R.last, R.toLower)(key);
        })), R.fromPairs)(child);
        const schedulesModel = this.crud.setupNewRecord({
          adapterName: 'grapesjs/schedules',
          data: elementData
        });
        const schedules = schedulesModel.getData('schedules');

        if (_ramdaAdjunct.default.isNilOrEmpty(schedules)) {
          R.forEach(schedule => schedulesModel.addSchedule({ ...schedule,
            scheduleId: _nventor.default.random.alphaNum(18)
          }))(childSchedules);
        }

        this.applySchedulesToAll = applytoall;
        return schedulesModel;
      })(targets);

      if (this.applySchedulesToAll) {
        this.schedulesModelArray = this.schedulesModelArray.splice(0, 1);
      }
    }

    *onSave(onCloseDialogAction) {
      const gjsSelectedElement = this.args.editor.getSelected();
      const applySchedulesToAll = this.applySchedulesToAll;
      const schedulesModelArray = this.schedulesModelArray;
      const allSchedules = R.pipe(R.map(schedule => R.pipe(R.pathOr([], ['_data', 'schedules']))(schedule)), R.reject(_ramdaAdjunct.default.isNilOrEmpty))(schedulesModelArray); // Clear GrapesJs component

      R.reject(R.isNil)(gjsSelectedElement.components());
      const schedulesData = allSchedules.map(_scheduleData => {
        let schedules = [];

        if (applySchedulesToAll) {
          schedules = allSchedules[0];
        } else {
          schedules = _scheduleData;
        }

        schedules = R.pipe(R.map(schedule => {
          if (schedule?.serialize) {
            schedule.serialize();
          }

          return R.pathOr([], ['_data'])(schedule);
        }), R.reject(_ramdaAdjunct.default.isNilOrEmpty))(schedules);
        const gjsModelAttributes = {
          'data-gjs-model-schedule': JSON.stringify(schedules),
          'data-gjs-model-applytoall': applySchedulesToAll
        };
        const _data = {};
        _data.schedulesAttributes = gjsModelAttributes;
        return _data;
      });
      gjsSelectedElement.updateScheddules(schedulesData);
      onCloseDialogAction();
    }

    checkOverlapDate() {
      let model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let schedules = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      const overlapDate = model.checkOverlapDate({
        model
      });

      if (overlapDate.hasOverlap) {
        this.saveDisabled = true;
        const overlapIds = overlapDate.ids || [];
        R.forEach(schedule => {
          const scheduleId = schedule.getData('scheduleId');

          if (R.includes(scheduleId, overlapIds)) {
            Ember.set(schedule, 'hasOverlap', true);
          } else {
            Ember.set(schedule, 'hasOverlap', false);
          }
        })(schedules);
      } else {
        this.saveDisabled = false;
        R.forEach(schedule => {
          Ember.set(schedule, 'hasOverlap', false);
        })(schedules);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "schedulesModelArray", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "applySchedulesToAll", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveDisabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "schedulesTargetContainerClass", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "schedulesTatgetClass", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setSchedulesModelArray", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setSchedulesModelArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSave", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkOverlapDate", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "checkOverlapDate"), _class.prototype)), _class));
  _exports.default = GrapesjsSchedulesModalComponent;
});