define("client/pods/components/documents/sales/consignments-returns/print-btns/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "keEqqKsZ",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"onCloseDialogAction\",\"onCloseDialogAction\",\"onPrintDialogAction\"],\"statements\":[[0,\"\\n\"],[4,\"elements/print-btn\",null,[[\"hasPrintBtn\"],[false]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"documents/document-print\",null,[[\"model\",\"selectedContact\",\"onCloseDialogAction\",\"onPrintDialogAction\"],[[24,[\"model\"]],[24,[\"selectedContact\"]],[23,3,[]],[23,4,[]]]]],false],[0,\"\\n\"]],\"parameters\":[3,4]},null],[0,\"\\n\"],[4,\"lists/list-btn\",null,[[\"icon\",\"isRunning\",\"tooltipLabel\"],[\"fas fa-envelope\",[24,[\"sendEmailTask\",\"isRunning\"]],\"email\"]],{\"statements\":[[0,\"\\n\"],[4,\"elements/element-btn-with-modal-dialog\",null,[[\"icon\",\"label\",\"onSubmit\",\"onClose\"],[\"fas fa-envelope\",\"email document\",[28,\"action\",[[23,0,[]],[24,[\"onSetupSendEmail\"]],[24,[\"email\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"onClearSendEmail\"]]],null]]],{\"statements\":[[0,\"    \\n    \"],[1,[28,\"emails/email-editor\",null,[[\"tab\",\"model\",\"sendEmailTask\",\"onSubmit\",\"onCancel\"],[[24,[\"tab\"]],[24,[\"email\"]],[24,[\"sendEmailTask\"]],[28,\"action\",[[23,0,[]],[24,[\"onSendEmail\"]],[24,[\"email\"]],[23,2,[]]],null],[28,\"action\",[[23,0,[]],[24,[\"onCancelSendEmail\"]],[23,2,[]]],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/sales/consignments-returns/print-btns/template.hbs"
    }
  });

  _exports.default = _default;
});