define("client/pods/components/menus/nav-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['nav-menu'],
    translate: true,
    menu: null,
    selected: null,
    itemClassNames: null,
    actions: {
      selected: function (item) {
        // must always use action set on item model
        this.set('selected', item);

        if (this.onSelect) {
          return this.onSelect(item);
        } // return this[item._data.action](item)

      }
    }
  });

  _exports.default = _default;
});