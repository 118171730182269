define("client/pods/components/channels/shop/rewards/panels-dash/rewards-dialog/rewards-recipients/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _component, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopRewardsPanelsDashRewardsDialogRewardsRecipientsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = class ChannelsShopRewardsPanelsDashRewardsDialogRewardsRecipientsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "constants", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "contact", _descriptor3, this);
    }

    getBirthday(contact) {
      const birthdays = [];
      const contactBirthday = R.propOr('', 'birthday')(contact);
      const memberBirthday = R.pathOr('', ['member', 'birthday'])(contact);
      const createdDateZ = R.propOr('', 'createdDateZ')(contact);

      if (RA.isNotNilOrEmpty(contactBirthday)) {
        birthdays.push({
          date: contactBirthday,
          source: 'contact'
        });
      }

      if (RA.isNotNilOrEmpty(memberBirthday)) {
        birthdays.push({
          date: memberBirthday,
          source: 'member'
        });
      }

      if (RA.isNilOrEmpty(birthdays)) {
        birthdays.push({
          date: createdDateZ,
          source: 'created date'
        });
      }

      return birthdays;
    }

    getEarnedPointsForBirthday(contact) {
      return R.pipe(R.propOr([], 'contactRewardsData'), R.map(contactReward => {
        return R.pipe(R.pathOr([], ['level', 'rewardsEvents']), R.find(R.propEq('type', this.constants.rewardEvents.birthday)), R.propOr(0, 'earnAmount'))(contactReward);
      }), _nventor.default.safeHeadOr(0))(contact);
    }

    get birthdays() {
      const contact = this.args.contact || {};
      return this.getBirthday(contact);
    }

    get birthdayPoints() {
      const contact = this.args.contact || {};
      return this.getEarnedPointsForBirthday(contact);
    }

    get emails() {
      const contact = this.args.contact || {};
      return R.pipe(R.propOr([], 'details'), R.filter(R.propEq('type', 'email')))(contact);
    }

    get memberEmail() {
      const contact = this.args.contact || {};
      return R.pathOr('', ['member', 'email'])(contact);
    }

    get contactRewardsData() {
      const contact = this.args.contact || {};
      let contactRewardsData = contact.contactRewardsData || {};

      if (RA.isArray(contactRewardsData)) {
        return _nventor.default.safeHeadOr({}, contactRewardsData);
      }

      return contactRewardsData;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contact", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChannelsShopRewardsPanelsDashRewardsDialogRewardsRecipientsComponent;
});