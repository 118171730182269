define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/convenience-store-zones/component", ["exports", "ramda", "ramda-adjunct", "@glimmer/component"], function (_exports, R, RA, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopShippingZonesShippingZonesEditorDetailsEditorConvenienceStoreZones = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ChannelsShopShippingZonesShippingZonesEditorDetailsEditorConvenienceStoreZones extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "isMainSelected", _descriptor2, this);

      _initializerDefineProperty(this, "isOutlyingSelected", _descriptor3, this);

      const regions = this.crud.lists['settings-countries-regions'];

      const selectedRegions = isOutlyingValue => R.pipe(R.map(region => {
        const districts = R.pipe(R.pathOr([], ['_data', 'districts']), R.filter(R.pathEq(['_data', 'is_outlying_island'], isOutlyingValue.toString())))(region);

        if (RA.isNotNilOrEmpty(districts)) {
          return R.path(['_data', 'key'])(region);
        }

        return false;
      }), R.reject(R.equals(false)))(regions);

      const selectedDistricts = isOutlyingValue => R.pipe(R.map(region => {
        const districts = R.pipe(R.pathOr([], ['_data', 'districts']), R.filter(R.pathEq(['_data', 'is_outlying_island'], isOutlyingValue.toString())), R.map(R.path(['_data', 'postcode'])))(region);
        const totalDistricts = R.length(region._data.districts);

        if (totalDistricts !== R.length(districts)) {
          return districts;
        }

        return false;
      }), R.reject(R.equals(false)), R.flatten)(regions);

      this.mainRegions = selectedRegions(false) || [];
      this.outlyingRegions = selectedRegions(true) || [];
      this.mainDistricts = selectedDistricts(false) || [];
      this.outlyingDistricts = selectedDistricts(true) || [];
    }

    setup(model) {
      this.isMainSelected = model?._data?.isMainSelected || false;
      this.isOutlyingSelected = model?._data?.isOutlyingSelected || false;
    }

    onToggleSelectRegionsGroup(toggleOutlying) {
      const model = this.args.model;
      const countries = model.getData('countries') || [];
      const country = R.find(R.pathEq(['_data', 'code'], 'TW'))(countries); // only TW is allowed

      if (toggleOutlying) {
        this.isOutlyingSelected = !this.isOutlyingSelected;
        model.setData('isOutlyingSelected', this.isOutlyingSelected);
      } else {
        this.isMainSelected = !this.isMainSelected;
        model.setData('isMainSelected', this.isMainSelected);
      }

      let mainRegions = [];
      let outlyingRegions = [];
      let mainDistricts = [];
      let outlyingDistricts = [];

      if (this.isOutlyingSelected) {
        outlyingRegions = this.outlyingRegions;
        outlyingDistricts = this.outlyingDistricts;
      }

      if (this.isMainSelected) {
        mainRegions = this.mainRegions;
        mainDistricts = this.mainDistricts;
      }

      country._data.regions = R.uniq(R.concat(mainRegions, outlyingRegions));
      country._data.districts = [];

      if (!(this.isOutlyingSelected && this.isMainSelected)) {
        country._data.districts = R.concat(mainDistricts, outlyingDistricts);
      }

      model.setData('countries', []);
      Ember.run.scheduleOnce('afterRender', () => {
        model.setData('countries', [country]);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isMainSelected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isOutlyingSelected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleSelectRegionsGroup", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSelectRegionsGroup"), _class.prototype)), _class));
  _exports.default = ChannelsShopShippingZonesShippingZonesEditorDetailsEditorConvenienceStoreZones;
});