define("client/pods/components/reports/dashboard/card-revenue-for-month/component", ["exports", "ramda", "client/mixins/date", "client/mixins/big"], function (_exports, R, _date, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_big.default, _date.default, {
    tagName: '',
    chartLegend: 'revenue',
    metric: 'net',
    chartType: null,
    chartHeight: null,
    chartWidth: null,
    translate: false,
    isTaxExclusive: true,
    averageByItemQty: false,
    isTableView: true,
    isReloadingChart: false,

    init() {
      this._super(...arguments);

      this.set('metricOptions', ['invoices', 'returns', 'net']);
    },

    autoChartHeight: Ember.computed('chartDatasets.[]', function () {
      const rows = parseInt(this.chartDatasets.length) || 1;
      const height = Math.ceil(rows * 30);

      if (height < 200) {
        return 200;
      }

      return height;
    }),
    chartData: Ember.computed('chartLegend', 'chartLabels.[]', 'chartDatasets.[]', 'isTaxExclusive', function () {
      return {
        labels: this.chartLabels,
        datasets: [{
          label: this.intl.t(this.chartLegend),
          data: this.chartDatasets
        }]
      };
    }),
    sorted: Ember.computed('data.[]', 'metric', function () {
      const metric = this.metric;
      const data = this.get(`data.${metric}`);

      if (Ember.isEmpty(data)) {
        return [];
      }

      const sort = R.sortWith([R.ascend(R.prop('segmentValue'))]);
      return sort(data);
    }),
    chartLabels: Ember.computed('sorted.[]', function () {
      const sorted = this.sorted;

      if (Ember.isEmpty(sorted)) {
        return [];
      }

      const formatDateFor = R.pipe(R.pluck('segmentValue'), R.map(date => {
        if (date) {
          return this.formatDate(date, 'DD');
        }

        return '';
      }));
      return formatDateFor(sorted);
    }),
    chartDatasets: Ember.computed('sorted.[]', 'isTaxExclusive', function () {
      const sorted = this.sorted;

      if (Ember.isEmpty(sorted)) {
        return [];
      }

      const valueParam = this.isTaxExclusive ? 'exclAmt' : 'inclAmt';
      return R.pluck(valueParam, sorted);
    }),

    getExcl(param, type) {
      return this.get(`${param}.${type}`);
    },

    getIncl(param, type) {
      return this.get(`${param}.${type}`);
    },

    getValue(param, exclParam, inclParam) {
      return this.isTaxExclusive ? this.getExcl(param, exclParam) : this.getIncl(param, inclParam);
    },

    // total: computed('chartDatasets.[]', 'isTaxExclusive', function () {
    //   const chartDatasets = this.get('chartDatasets') || []
    //   const rounding = 0
    //   const total = this.sumArray(chartDatasets)
    //   return this.autoRound(rounding, total)
    // })
    // total: computed('report.revenue.net.{exclTotal,inclTotal}', 'isTaxExclusive', function () {
    //   return this.getValue('report.revenue.net', 'exclTotal', 'inclTotal')
    // })
    actions: {
      toggleView() {
        this.toggleProperty('isTableView');
      }

    }
  });

  _exports.default = _default;
});