define("client/pods/documents/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 10,
        query: '',
        master: '',
        match: ['all'],
        documentStatuses: [],
        productStatuses: [],
        dispatchStatus: [],
        dispatchProcesses: [],
        multipleDispatchProcessesCriterias: [],
        date: '',
        dateZ: '',
        dispatchDateZ: '',
        timestampZ: '',
        docNo: '',
        contact: '',
        promoCode: '',
        code: '',
        ref: '',
        item: '',
        inclTotal: '',
        transporterLabel: '',
        transporterAccount: '',
        waybill: '',
        packages: '',
        paymentMethods: [],
        paymentMethodsCustomLabel: [],
        paymentLastFiveDigits: '',
        sourceKey: '',
        statuses: [this.get('constants.status.active')],
        contactTags: [],
        sort: [{
          by: 'timestampZ',
          direction: 'DESC'
        }]
      };
    },

    tagsCount: Ember.computed('_data.tags.[]', function () {
      return this.getPropLength('_data.tags');
    }),
    productTags: Ember.computed('_data.productTags.[]', function () {
      return this.getPropLength('_data.productTags');
    }),
    contactTags: Ember.computed('_data.contactTags.[]', function () {
      return this.getPropLength('_data.contactTags');
    }),
    paymentMethodsCount: Ember.computed('_data.paymentMethods.[]', function () {
      return this.getPropLength('_data.paymentMethods');
    }),
    paymentMethodsCustomLabelCount: Ember.computed('_data.paymentMethodsCustomLabel.[]', function () {
      return this.getPropLength('_data.paymentMethodsCustomLabel');
    }),
    paymentTermsCount: Ember.computed('_data.paymentTerms.[]', function () {
      return this.getPropLength('_data.paymentTerms');
    })
  });

  _exports.default = _default;
});