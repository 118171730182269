define("client/pods/admin/payments/card/statements/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        dateStart: '',
        dateEnd: '',
        sort: [{
          by: 'timestampZ',
          direction: 'DESC'
        }]
      };
    }

  });

  _exports.default = _default;
});