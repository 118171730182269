define("client/pods/extensions/apps/tw/convenience-stores/lists/brands/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtensionsAppsTwConvenienceStoresListsBrandsAdapter extends _model.default {}

  var _default = _adapter.default.extend(_adaptersNew.default, {
    constants: Ember.inject.service(),
    modelObj: ExtensionsAppsTwConvenienceStoresListsBrandsAdapter,
    idParam: false,

    findAll() {
      const data = [{
        label: 'sevenEleven',
        value: this.constants.convenienceStoresBrands.sevenEleven
      }, {
        label: 'familyMart',
        value: this.constants.convenienceStoresBrands.familyMart
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});