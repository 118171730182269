define("client/pods/components/extensions/apps/tw/shopee/products/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H5FtoSB5",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"helpers/tab-link\",[],[[\"@hasLink\",\"@tabOptions\"],[[23,1,[\"_data\",\"itemKey\"]],[28,\"hash\",null,[[\"component\",\"title\",\"loadInstructions\"],[\"products/tab-container\",\"products\",[28,\"hash\",null,[[\"find\"],[[28,\"hash\",null,[[\"id\"],[[23,1,[\"_data\",\"itemKey\"]]]]]]]]]]]]],{\"statements\":[[0,\"\\n\\n  \"],[1,[23,1,[\"_data\",\"item\"]],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/shopee/products/link/template.hbs"
    }
  });

  _exports.default = _default;
});