define("client/pods/components/elements/element-select/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['select'],
    translate: false,
    _options: Ember.computed('options.[]', function () {
      const options = this.options || [];
      return R.map(option => {
        if (R.is(Object, option)) {
          return option;
        }

        return Ember.Object.create({
          value: option,
          label: option
        });
      })(options);
    }),
    actions: {
      select(e) {
        const selectedOption = R.pipe(R.pathOr([], ['target', 'selectedOptions']), R.head)(e);
        let selected = '';

        if (selectedOption) {
          selected = R.propOr('', 'value', selectedOption);
        }

        return this.onSelect(selected);
      }

    }
  });

  _exports.default = _default;
});