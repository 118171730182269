define("client/pods/components/elements/info-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RmNfTzey",
    "block": "{\"symbols\":[\"@index\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-info-card\"],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"u-info-card__index\"],[8],[0,\"\\n      \"],[1,[28,\"number\",[[23,1,[]]],null],false],[0,\".\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"u-info-card__content\"],[8],[0,\"\\n    \"],[14,2],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/info-card/template.hbs"
    }
  });

  _exports.default = _default;
});