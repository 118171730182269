define("client/pods/components/notifications/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NotificationsComponent extends _component.default {// notificationsService: service('notifications'),
  }

  _exports.default = NotificationsComponent;
});