define("client/pods/components/elements/element-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x3BtGCvD",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"select\",true],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"select\"],null]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"_options\"]]],null,{\"statements\":[[0,\"    \"],[6,[23,1,[]],[[12,\"value\",[23,1,[\"value\"]]],[12,\"selected\",[28,\"if\",[[28,\"eq\",[[23,1,[\"value\"]],[24,[\"selected\"]]],null],\"selected\"],null]]],[[],[]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"tt\",[[23,1,[\"label\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-select/template.hbs"
    }
  });

  _exports.default = _default;
});