define("client/mixins/contacts-departments", ["exports", "ramda", "client/mixins/contacts-details", "client/mixins/contacts-persons", "client/utils/nventor"], function (_exports, R, _contactsDetails, _contactsPersons, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_contactsDetails.default, _contactsPersons.default, {
    init() {
      this._super(...arguments);

      this.setData('departments', []);
    },

    /**
    * transform array of data into models
    */
    transformDepartments(rawData) {
      const self = this;

      if (R.is(Array, rawData.departments)) {
        let transformed = rawData.departments.map(function (data) {
          self.transformDetails(data);
          self.transformPersons(data);
          return self._createDepartmentModel(data);
        });
        rawData.departments = transformed;
      }
    },

    /**
    * create department model
    */
    _createDepartmentModel(data) {
      return this.dataManager.setAsRecord({
        adapterName: 'contacts/departments',
        data
      });
    },

    addDepartment(data) {
      const department = this._createDepartmentModel(data);

      const departments = this.getOrSetData('departments', []);

      const departmentId = _nventor.default.random.alphaNum(8);

      department.setData('departmentId', departmentId);
      departments.addObject(department);
      return department;
    },

    removeDepartment(department) {
      const departments = this.getData('departments');
      departments.removeObject(department);
    } // hasChildren: computed('_data.{details.[],persons.[]}', function () {
    //   const departments = this.get('departments') || []
    //   const persons = departments.getData('persons') || []
    //   const details = departments.getData('details') || []
    //   console.log('==============')
    //   console.log(persons)
    //   console.log(details)
    //   if (persons.length > 0) {
    //     return true
    //   }
    //   if (details.length > 0) {
    //     return true
    //   }
    //   return false
    // })


  });

  _exports.default = _default;
});