define("client/pods/components/extensions/apps/tw/e-invoices/print-manager/invoice-management/component", ["exports", "client/mixins/date", "ember-concurrency", "ramda-adjunct", "ramda"], function (_exports, _date, _emberConcurrency, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    printer: Ember.inject.service(),
    tagName: '',

    init() {
      this._super(...arguments);

      let telephone = this.get('invoice._data.billingTelephone');

      if (!telephone) {
        telephone = this.get('invoice._data.telephone');
      }

      const customData = Ember.Object.create({
        email: this.get('invoice._data.email'),
        telephone
      });
      this.set('custom', customData);
    },

    allowCancel: Ember.computed('invoice._data.{cancellableDateZ,status}', 'modalDialogIsVisible', function () {
      const cancellableDateZ = this.get('invoice._data.cancellableDateZ');
      const status = this.get('invoice._data.status');

      if (status !== 'successful') {
        return false;
      } // @NOTE: this is for backwards compatablility
      // can be removed after sep 2021


      const currentPeriod = this.dateService.getPeriod(cancellableDateZ);
      const period = this.dateService.getPeriod(cancellableDateZ);
      let cancellableDate = this.dateService.getMoment(cancellableDateZ);
      const now = this.getMoment();

      if (RA.isOdd(period.mm) && currentPeriod.mm === period.mm) {
        cancellableDate = cancellableDate.date(15);
      }

      if (now.isBefore(cancellableDate)) {
        return true;
      }

      return false;
    }),
    allowQueryStatus: Ember.computed('invoice._data.timestampZ', function () {
      if (this?.invoice?.isSuccessfulOrCancelled) {
        return false;
      }

      const timestampZ = R.pathOr('', ['invoice', '_data', 'timestampZ'])(this);

      if (!timestampZ) {
        return false;
      }

      const fifteenMinutesAfter = this.dateService.getMoment(timestampZ).add(60, 'minutes');
      const allowToQuery = this.dateService.getMoment().isAfter(fifteenMinutesAfter);

      if (!allowToQuery) {
        return false;
      }

      const invoice = this.invoice;
      const provider = invoice?._data?.provider;

      if (provider === 'systemlead' && invoice?._data?.number) {
        return true;
      }

      return true;
    }),
    queryStatusTask: (0, _emberConcurrency.task)(function* () {
      const key = this.invoice?._data?._key;
      yield this.crud.ajaxCallTask.perform('PATCH', {
        adapterName: 'extensions/apps/tw/e-invoices',
        data: {
          _key: key
        }
      });
    })
  });

  _exports.default = _default;
});