define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/zone-tables/districts-column/districts-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k57VMljF",
    "block": "{\"symbols\":[\"@district\",\"@country\",\"@isReadonly\",\"@selectedDistrictsForRegion\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[4,\"if\",[[23,0,[\"isSelected\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"u-padding-top-5 u-padding-bottom-5\"],[8],[0,\"\\n        - [\"],[1,[23,1,[\"_data\",\"postcode\"]],false],[0,\"] \"],[1,[23,1,[\"_data\",\"label\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"u-padding-top-5 u-padding-bottom-5\"],[8],[0,\"\\n      - [\"],[1,[23,1,[\"_data\",\"postcode\"]],false],[0,\"] \"],[1,[23,1,[\"_data\",\"label\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"u-padding-top-5 u-padding-bottom-5\"],[8],[0,\"\\n    \"],[5,\"elements/element-checkbox\",[[12,\"class\",\"u-display-block\"]],[[\"@value\",\"@onToggleProperty\",\"@onAfterToggle\",\"@disabled\"],[[23,0,[\"isSelected\"]],[28,\"fn\",[[28,\"mut\",[[23,0,[\"isSelected\"]]],null]],null],[28,\"fn\",[[23,0,[\"onToggleSetSelectedDistrict\"]],[23,2,[]],[23,1,[]]],null],[23,3,[]]]],{\"statements\":[[0,\"\\n      [\"],[1,[23,1,[\"_data\",\"postcode\"]],false],[0,\"] \"],[1,[23,1,[\"_data\",\"label\"]],false],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/zone-tables/districts-column/districts-row/template.hbs"
    }
  });

  _exports.default = _default;
});