define("client/pods/components/extensions/apps/tw/seven-eleven/print-item-meta/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension"], function (_exports, _component, _emberConcurrencyDecorators, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwSevenElevenPrintItemMetaComponent = (_dec = Ember.inject.service('dispatch'), _dec2 = Ember.inject.service('constants'), _dec3 = Ember.inject.service('transporter'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed('this.args.model._data.convenienceStoreData.storeId'), _dec7 = Ember.computed('sync'), _dec8 = Ember.computed('sync'), _dec9 = Ember.computed('sync'), _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ExtensionsAppsTwSevenElevenPrintItemMetaComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dispatchService", _descriptor, this);

      _initializerDefineProperty(this, "constantsService", _descriptor2, this);

      _initializerDefineProperty(this, "transporterService", _descriptor3, this);

      _initializerDefineProperty(this, "convenienceStoreWindow", _descriptor4, this);

      _initializerDefineProperty(this, "sync", _descriptor5, this);
    }

    get oldStore() {
      return R.pipe(R.pathOr([], ['args', 'model', '_data', 'convenienceStoreData', 'history']), R.last)(this);
    }

    get allowUpdateConvenienceStore() {
      const allowUpdateConvenienceStore = R.pathEq(['args', 'allowUpdateConvenienceStore'], true)(this);

      if (allowUpdateConvenienceStore) {
        return allowUpdateConvenienceStore;
      }

      const provider = R.pathOr('', ['args', 'tab', 'value'])(this);
      return R.includes(this.constantsService.transporterExtensions.sevenEleven)(provider);
    }

    get storeId() {
      return R_.dotPath('args.model._data.convenienceStoreData.storeId')(this);
    }

    get storeName() {
      return R_.dotPath('args.model._data.convenienceStoreData.storeName')(this);
    }

    get storeAddress() {
      return R_.dotPath('args.model._data.convenienceStoreData.storeAddress')(this);
    }

    closeUpdateConvenienceStoreWindow(storeId, rev) {
      this.updateSync();

      try {
        if (this.convenienceStoreWindow && this.convenienceStoreWindow.close) {
          this.convenienceStoreWindow.close();
        }
      } catch (error) {// DO NOTHING ON STORE WINDOWS REFERENCE ERROR
      }
    }

    *updateConvenienceStoreTask(model) {
      const transporterKey = this.args?.transporterKey || this.args?.model?._data?.transporterKey || '';

      try {
        const url = yield this.dispatchService.updateConvenienceStoreUrl.perform({
          model,
          transporterKey
        });
        this.convenienceStoreWindow = window.open(url, '_blank');
      } catch (error) {// DO NOTHING ON STORE WINDOWS REFERENCE ERROR
      }
    }

    updateSync() {
      this.sync = Math.random();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dispatchService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constantsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "transporterService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "convenienceStoreWindow", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sync", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "oldStore", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "oldStore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "storeId", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "storeId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "storeName", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "storeName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "storeAddress", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "storeAddress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeUpdateConvenienceStoreWindow", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "closeUpdateConvenienceStoreWindow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateConvenienceStoreTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateConvenienceStoreTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSync", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "updateSync"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwSevenElevenPrintItemMetaComponent;
});