define("client/pods/components/menus/tab-menu-new/tab-item/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MenusTabMenuNewTabItemComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class MenusTabMenuNewTabItemComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "classNames", this.args?.classNames || 'tab-item');

      _defineProperty(this, "translate", true);

      _initializerDefineProperty(this, "menuLabelKey", _descriptor, this);
    }

    get item() {
      return this.args?.item || null;
    }

    get label() {
      return this.item.get?.(this.menuLabelKey);
    }

    get itemComponent() {
      return this.item?._data?.component || this.item?.component || '';
    }

    get itemValue() {
      return this.item?._data?.value || this.item?.value || '';
    }

    get itemLabel() {
      return this.item?._data?.label || this.item?.label || '';
    }

    get currentActive() {
      if (this.args?.isCurrentActive) {
        const currentActive = this.args.isCurrentActive;

        if (currentActive?.label) {
          return currentActive.label === this.itemLabel;
        }

        if (currentActive?.value) {
          return currentActive.value === this.itemValue;
        }

        return currentActive.component === this.itemComponent;
      }

      return false;
    }

    get subTab() {
      const subTabs = this.args?.subTabs || [];
      const subTab = R.find(R.propEq('component', this.itemComponent))(subTabs);
      return subTab;
    }

    onClick() {
      if (this.args?.onSelect) {
        this.args.onSelect(this.item);
      }
    }
    /**
     * Set TabMenuNew and PanelGrid CSS
     * @param {HTMLElement} element
     * @void
     */


    setCss(element) {
      // @TODO: find a better solution? ideally tab container should hanlde all this
      // this function is used for:
      // go to shop -> campaigns -> settings -> scrolling without this function will not make panel-grid__header-title sticky
      // it will also set a top for panel-grid__header-with-guide-menu
      const onSetCss = this.args.onSetCss;

      if (typeof onSetCss !== 'function') {
        return;
      }

      const index = this.args.menuIndex;

      if (index === 0) {
        onSetCss(element);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "menuLabelKey", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args?.menuLabelKey || '_data.label';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCss", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setCss"), _class.prototype)), _class));
  _exports.default = MenusTabMenuNewTabItemComponent;
});