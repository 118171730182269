define("client/pods/tabs-manager/model", ["exports", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TabsManagerModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, (_class = class TabsManagerModel extends Ember.Object {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "subTabs", _descriptor, this);

      _initializerDefineProperty(this, "value", _descriptor2, this);

      _initializerDefineProperty(this, "dirty", _descriptor3, this);

      _initializerDefineProperty(this, "errorsTracker", _descriptor4, this);

      _initializerDefineProperty(this, "allDirtyApiErrors", _descriptor5, this);

      _initializerDefineProperty(this, "loadInstructions", _descriptor6, this);

      this.resetTabs();
    }

    get errorsTrackerAll() {
      return this._getErrorsTrackerFromItselfAndChildren(this);
    }

    get listName() {
      return R.pipe(R.propOr('', 'adapterName'), R.replace(/[/]/g, '-'))(this);
    }

    get loadInstructionsIsDisplay() {
      return {
        loadInstructions: {
          component: this.loadInstructions.isDisplay,
          editingComponent: this.loadInstructions.component,
          value: this.loadInstructions.value
        }
      };
    }

    _getErrorsTrackerFromItselfAndChildren(tab) {
      const errorsTracker = tab.errorsTracker || [];
      const subTabs = tab?.subTabs || [];

      const _tracker = R.pipe(R.map(subTab => {
        const subTabs = R.propOr([], 'subTabs')(subTab);

        if (subTabs.length > 0) {
          return this._getErrorsTrackerFromItselfAndChildren(subTab);
        }

        return subTab?.errorsTracker || [];
      }), R.flatten, R.concat(errorsTracker), R.reject(_nventor.default.isNilOrEmpty), R.uniq)(subTabs);

      errorsTracker.forEach(tracker => {
        tracker.childTabIds = R.pluck('id')(subTabs) || [];
      });
      return _tracker;
    }
    /**
     * Use to clear all subTabs
     * @void
     */


    resetTabs() {
      const tabs = [];
      tabs._history = [];
      tabs._closed = [];
      this.subTabs = tabs;
    }
    /**
     * subTabs {Array}
     * @returns {Array}
     */


    get subTabsArray() {
      return this.subTabs;
    }
    /**
     * To refresh guide menu so as to render properly
     * @param {String} menuId the menuId used to register guide menu
     * @void
     */


    refreshMenu(menuId) {
      const guideMenuModel = this.get(menuId);

      if (RA.isNotNilOrEmpty(guideMenuModel)) {
        guideMenuModel.menu = guideMenuModel.menu || [];
      }
    }
    /**
     * Set the target 'isActive' to true
     * @param {Object} menuItemModel FormContainerModel || FormContainerSubModel
     * @void
     */


    setMenuItemAsActive(menuItemModel) {
      Ember.set(menuItemModel, 'showActive', true);
      const menuId = menuItemModel.menuId;
      this.refreshMenu(menuId);
    }
    /**
     * Set the target 'isActive' to false
     * @param {Object} menuItemModel FormContainerModel || FormContainerSubModel
     * @void
     */


    setMenuItemAsInactive(menuItemModel) {
      Ember.set(menuItemModel, 'showActive', false);
      const menuId = menuItemModel.menuId;
      this.refreshMenu(menuId);
    }

    setErrorTrackerOnModel(errorTracker) {
      const errorsTracker = this.errorsTracker || [];

      if (errorsTracker.length === 0) {
        this.errorsTracker = [errorTracker];
      } else {
        errorsTracker.addObject(errorTracker);
      }

      errorsTracker.addObject(errorTracker);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "subTabs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorsTracker", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "allDirtyApiErrors", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loadInstructions", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setErrorTrackerOnModel", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setErrorTrackerOnModel"), _class.prototype)), _class));
  _exports.default = TabsManagerModel;
});