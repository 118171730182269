define("client/pods/components/documents/pos/view/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "03dWMZ+s",
    "block": "{\"symbols\":[\"product\",\"@tab\",\"@details\",\"@onAddItemToCart\",\"@onRemoveItemFromCart\",\"@isPosOnFullscreenMode\",\"@isRunning\",\"@posOptionsDetails\",\"@paginationControlsFilters\",\"@paginationControlsList\",\"@onPaginationTask\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"pos-products\"],[8],[0,\"\\n  \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,7,[]]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,8,[]]],null,{\"statements\":[[0,\"      \"],[5,\"documents/pos/product/card\",[],[[\"@tab\",\"@details\",\"@product\",\"@onAddItemToCart\",\"@onRemoveItemFromCart\",\"@isPosOnFullscreenMode\"],[[23,2,[]],[23,3,[]],[23,1,[]],[23,4,[]],[23,5,[]],[23,6,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"pos-products-pagination-footer\"],[8],[0,\"\\n\"],[0,\"  \"],[5,\"helpers/pagination-controls\",[],[[\"@filters\",\"@list\",\"@onPaginationTask\",\"@showPageNumbers\"],[[23,9,[]],[23,10,[]],[23,11,[]],true]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/pos/view/details/template.hbs"
    }
  });

  _exports.default = _default;
});