define("client/pods/components/channels/shop/rewards/rewards-editor/member-levels-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _component, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopRewardsRewardsEditorMemberLevelsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ChannelsShopRewardsRewardsEditorMemberLevelsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "constants", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "earningTypeList", _descriptor3, this);
    }

    get hasRewardForSpecificProductTags() {
      const settings = this.args?.model?.settings || {};

      if (RA.isNotNilOrEmpty(settings)) {
        const betaHasRewardForSpecificProductTags = settings.getProp('beta.hasRewardForSpecificProductTags') || false;
        const featureHasRewardForSpecificProductTags = settings.getProp('features.hasRewardForSpecificProductTags') || false;
        return betaHasRewardForSpecificProductTags && featureHasRewardForSpecificProductTags;
      }

      return false;
    }
    /**
     * Append new member level Model to reward Model
     * @param {Model} reward - reward Model
     * @returns {Void}
     */


    addMemberLevelAction(reward) {
      reward.addMemberLevel();
    }

    onSubmitAddRewardEventOrder(memberLevel) {
      const hasNoOrderRewardEvent = R.pipe(R.pathOr([], ['_data', 'rewardsEvents']), R.find(R.pathEq(['_data', 'type'], this.constants.rewardEvents.order)), RA.isNilOrEmpty)(memberLevel);

      if (hasNoOrderRewardEvent) {
        memberLevel.addRewardEvent({
          type: this.constants.rewardEvents.order,
          earnType: this.constants.rewardEarningTypes.rate
        });
      }
    }

    onSubmitAddRewardEventBirthday(memberLevel) {
      const hasNoBirthdayRewardEvent = R.pipe(R.pathOr([], ['_data', 'rewardsEvents']), R.find(R.pathEq(['_data', 'type'], this.constants.rewardEvents.birthday)), RA.isNilOrEmpty)(memberLevel);

      if (hasNoBirthdayRewardEvent) {
        memberLevel.addRewardEvent({
          type: this.constants.rewardEvents.birthday,
          earnType: this.constants.rewardEarningTypes.amount
        });
      }
    }

    onSubmitAddRewardEventSignup(memberLevel) {
      const hasNoSignupRewardEvent = R.pipe(R.pathOr([], ['_data', 'rewardsEvents']), R.find(R.pathEq(['_data', 'type'], this.constants.rewardEvents.signup)), RA.isNilOrEmpty)(memberLevel);

      if (hasNoSignupRewardEvent) {
        memberLevel.addRewardEvent({
          type: this.constants.rewardEvents.signup,
          earnType: this.constants.rewardEarningTypes.amount
        });
      }
    }
    /**
     * Remove member level Model from reward Model
     * @param {Model} reward - reward Model
     * @param {Model} memberLevel - member level Model
     * @returns {Void}
     */


    onSubmitRemoveMemberLevel(reward, memberLevel) {
      const memberLevelList = reward.get('_data.memberLevels');
      memberLevelList.removeObject(memberLevel);
    }
    /**
     * Remove reward event Model from member level Model
     * @param {Model} memberLevel - member level Model
     * @param {Model} rewardEvent - reward event Model
     * @returns {Void}
     */


    onSubmitRemoveRewardEvent(memberLevel, rewardEvent) {
      const rewardEventList = memberLevel.get('_data.rewardsEvents');
      rewardEventList.removeObject(rewardEvent);
    }

    onSelectRewardEventType(selected) {
      const selectedEvent = selected.getData('value');

      if (selectedEvent !== this.constants.rewardEvents.order) {
        const amountEarningType = R.find(R.pathEq(['_data', 'value'], this.constants.rewardEarningTypes.amount))(this.crud.lists['channels-shop-rewards-earnings']);
        this.earningTypeList = [amountEarningType];
      } else {
        this.earningTypeList = this.crud.lists['channels-shop-rewards-earnings'];
      }
    }

    onSubmitAddRewardEventReferral(memberLevel) {
      const hasNoReferralRewardEvent = R.pipe(R.pathOr([], ['_data', 'rewardsEvents']), R.find(R.pathEq(['_data', 'type'], this.constants.rewardEvents.referral)), RA.isNilOrEmpty)(memberLevel);

      if (hasNoReferralRewardEvent) {
        memberLevel.addRewardEvent({
          type: this.constants.rewardEvents.referral,
          earnType: this.constants.rewardEarningTypes.amount
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "earningTypeList", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R_.dotPathOr([], 'crud.lists.channels-shop-rewards-earnings')(this);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addMemberLevelAction", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addMemberLevelAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitAddRewardEventOrder", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddRewardEventOrder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitAddRewardEventBirthday", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddRewardEventBirthday"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitAddRewardEventSignup", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddRewardEventSignup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitRemoveMemberLevel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveMemberLevel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitRemoveRewardEvent", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveRewardEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectRewardEventType", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectRewardEventType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitAddRewardEventReferral", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddRewardEventReferral"), _class.prototype)), _class));
  _exports.default = ChannelsShopRewardsRewardsEditorMemberLevelsEditorComponent;
});