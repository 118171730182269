define("client/pods/components/documents/status-tag/component", ["exports", "client/mixins/doc-status", "client/mixins/date"], function (_exports, _docStatus, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, _docStatus.default, {
    crud: Ember.inject.service(),
    tagName: '',
    showDropdown: false,
    autoFetchLists: true,
    readonly: false,
    autoPosition: true,

    init() {
      this._super(...arguments);

      if (!this.dispatchProcesses && this.autoFetchLists) {
        this.crud.addLists(this, ['dispatch/lists/processes']);
      }
    },

    isDropdownVisible: Ember.computed('showDropdown', 'isBtn', function () {
      // const list = this.get('list') || []
      if (!this.isBtn) {
        return false;
      }

      if (this.readonly === false && this.showDropdown) {
        // if (this.get('readonly') === false && this.get('showDropdown') && list.length > 0) {
        return true;
      }

      return false;
    }),
    translatedDispatchProcess: Ember.computed('model.{synced,_data.dispatchProcessLabel,_data.dispatchProcess,_data.dispatchDateZ}', 'crud.lists.dispatch-lists-processes.[]', 'dispatchProcesses.[]', function () {
      // translatedDispatchProcess: computed('model._data.dispatchProcess', 'crud.lists.dispatch-lists-processes.[]', 'dispatchProcesses.[]', function () {
      // const model = this.get('model')
      const dispatchProcessLabel = this.get('model._data.dispatchProcessLabel');
      const dispatchProcessLabelTranslate = this.get('model._data.dispatchProcessLabelTranslate');

      if (dispatchProcessLabelTranslate) {
        return this.intl.t(dispatchProcessLabel);
      }

      return dispatchProcessLabel;
    }),
    label: Ember.computed('model.{synced,_data.status,_data.dispatchProcess,_data.dispatchDateZ}', 'translatedDispatchProcess', function () {
      // @TODO: move to model?
      const model = this.model;
      const status = model.getData('status');
      const intl = this.intl;
      let label = intl.t(status);

      if (this.get('model._data.isMerged')) {
        return intl.t('merged_document');
      }

      if (!this.get('model.hasDispatchProcess')) {
        return label;
      }

      const dispatchProcess = model.getData('dispatchProcess');
      const dispatchDateZ = model.getData('dispatchDateZ');

      if (status === this.get('constants.documentsStatus.final')) {
        label = this.translatedDispatchProcess;

        if (dispatchProcess === this.get('constants.dispatchProcess.dispatched')) {
          const date = this.formatDate(dispatchDateZ);
          label = `${label}: ${date}`;
        }
      }

      if (!label) {
        label = intl.t(this.get('constants.dispatchProcess.unprepared'));
      }

      return label;
    }),
    actions: {
      hideDropdown() {
        this.set('showDropdown', false);
      },

      showDropdown() {
        this.set('showDropdown', true);
      },

      submit() {
        const onSubmit = this.onSubmit;

        if (onSubmit) {
          onSubmit(...arguments);
        }
      }

    }
  });

  _exports.default = _default;
});