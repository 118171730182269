define("client/pods/uploads/collection/model", ["exports", "ramda", "ramda-adjunct", "client/pods/base/model", "client/mixins/uploads"], function (_exports, R, RA, _model, _uploads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_uploads.default, {
    isUploadsCollection: true,

    // dont populate uploads
    populate(data, attrs) {
      data.uploads = R.pipe(R.propOr([], 'uploads'), RA.mapIndexed((data, index) => {
        if (RA.isNilOrEmpty(data.index)) {
          data.index = index;
        }

        return this.dataManager.setAsRecord({
          adapterName: 'uploads',
          data,
          attrs
        });
      }))(data);

      this._super(...arguments);
    },

    imagesList: Ember.computed('_data.uploads.[]', 'synced', function () {
      const uploadedModels = this.get('_data.uploads') || [];
      return R.filter(R.pathEq(['_data', 'isImage'], true))(uploadedModels);
    }),
    imagesUuiList: Ember.computed('imagesList.[]', 'synced', function () {
      const imagesList = this.imagesList || [];
      return R.pipe(R.pluck('_data'), R.pluck('uuid'))(imagesList);
    }),
    defaultFile: Ember.computed('_data.uploads.[]', 'synced', function () {
      const uploadedModels = this.get('_data.uploads') || [];
      const defaultFile = R.find(R.pathEq(['_data', 'isDefault'], true))(uploadedModels);
      return defaultFile;
    }),
    sortedFiles: Ember.computed('_data.uploads.[]', 'synced', function () {
      let uploadedModels = this.get('_data.uploads') || [];

      if (RA.isNilOrEmpty(uploadedModels)) {
        return [];
      }

      uploadedModels = R.sortWith([R.descend(R.path(['_data', 'isDefault']))])(uploadedModels);

      if (uploadedModels.length <= 1) {
        return uploadedModels;
      }

      const defaultFile = this.defaultFile;

      if (RA.isNilOrEmpty(defaultFile)) {
        return uploadedModels;
      }

      return uploadedModels;
    }),

    getAsUrls(list) {
      let transforms = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      const cdnBase = this.uploadcare.get('cdnBase');
      return R.map(uuid => {
        return `${cdnBase}/${uuid}${transforms}/`;
      })(list);
    },

    defaultImageUuid: Ember.computed('synced', function () {
      const uploadedModels = this.get('_data.uploads') || [];
      const hasOnlyOneImage = uploadedModels.length === 1;

      if (hasOnlyOneImage) {
        return R.view(R.lensPath([0, '_data', 'uuid']))(uploadedModels);
      }

      return R.pipe(R.filter(R.pathEq(['_data', 'isDefault'], true)), R.view(R.lensPath([0, '_data', 'uuid'])))(uploadedModels);
    }),
    onlyIsDefaultUploads: Ember.computed('synced', function () {
      const found = R.pipe(R.pathOr([], ['_data', 'uploads']), R.filter(R.pathEq(['_data', 'isDefault'], true)))(this);
      return found;
    })
  });

  _exports.default = _default;
});