define("client/pods/components/websites/websites-editor/website-wizard/quick-templates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j8Yxxs2g",
    "block": "{\"symbols\":[\"@tab\",\"@model\",\"@onCloseDialogAction\",\"@errors\"],\"statements\":[[4,\"forms/form-section\",null,[[\"title\",\"errors\",\"context\",\"documentationResource\",\"contentClassNames\"],[\"pick a color theme\",[23,4,[]],\"colorTheme\",\"wizard\",\"flex-container-row\"]],{\"statements\":[[0,\"\\n  \"],[5,\"color-themes\",[],[[\"@model\",\"@autoSelect\"],[[23,2,[]],true]]],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-section\",null,[[\"title\",\"errors\",\"context\",\"documentationResource\"],[\"website template\",[23,4,[]],\"websiteTemplate\",\"wizard\"]],{\"statements\":[[0,\"  \"],[5,\"websites/websites-editor/templates\",[],[[\"@tab\",\"@model\",\"@onCloseDialogAction\",\"@templateOptions\"],[[23,1,[]],[23,2,[]],[23,3,[]],[28,\"hash\",null,[[\"perTemplate\"],[[28,\"hash\",null,[[\"onClick\",\"hideButtonsTemplate\"],[[23,0,[\"onClick\"]],true]]]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/websites-editor/website-wizard/quick-templates/template.hbs"
    }
  });

  _exports.default = _default;
});