define("client/pods/protected/companies/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X/IBo7Hs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-section\",null,[[\"title\"],[\"select company\"]],{\"statements\":[[4,\"forms/form-row\",null,null,{\"statements\":[[0,\"    \"],[5,\"app-companies\",[],[[\"@hasFooter\"],[true]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/protected/companies/template.hbs"
    }
  });

  _exports.default = _default;
});