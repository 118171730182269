define("client/pods/components/menus/nav-tree/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yNd2gQQy",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[24,[\"menu\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[23,1,[\"_data\",\"groups\"]],[23,1,[\"_data\",\"sub\"]],[23,1,[\"_data\",\"isFavorites\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"menus/nav-tree/nav-nested\",null,[[\"item\",\"pinItem\",\"labelKey\",\"isExpanded\",\"onExpand\",\"onPin\",\"onPinTask\",\"isPinnedMenu\",\"onUpdateMenuIndexTask\",\"onSelect\"],[[23,1,[]],[23,1,[]],\"label\",[23,1,[\"_data\",\"isExpanded\"]],[28,\"action\",[[23,0,[]],\"toggleSubMenu\"],null],[24,[\"onPin\"]],[24,[\"onPinTask\"]],[24,[\"isPinnedMenu\"]],[24,[\"onUpdateMenuIndexTask\"]],[24,[\"onSelect\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[5,\"settings/is-beta-allowed\",[],[[\"@betaPath\"],[[23,1,[\"_data\",\"beta\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"menus/nav-tree/nav-item\",null,[[\"item\",\"pinItem\",\"classNames\",\"labelKey\",\"onPin\",\"onPinTask\",\"onUpdateMenuIndexTask\",\"isPinnedMenu\",\"onSelect\"],[[23,1,[]],[23,1,[]],\"nav-tree__item-link\",\"label\",[24,[\"onPin\"]],[24,[\"onPinTask\"]],[24,[\"onUpdateMenuIndexTask\"]],[24,[\"isPinnedMenu\"]],[24,[\"onSelect\"]]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/nav-tree/template.hbs"
    }
  });

  _exports.default = _default;
});