define("client/pods/components/channels/shop/items/item-card/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    tagName: 'div',
    classNames: ['store-item__card', 'card']
  });

  _exports.default = _default;
});