define("client/pods/components/admin/members/tab-container/component", ["exports", "ember-concurrency", "ramda", "ramda-extension", "client/config/environment"], function (_exports, _emberConcurrency, R, R_, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    admin: Ember.inject.service(),
    adapterName: 'admin',
    resultsToggle: false,
    query: '',
    shopVersion: '',
    reloadShopApiVersions: '',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['admin/members/lists/plans']);
      this.set('created', []);
      this.resetNewCompany();
      this.set('shopVersion', _environment.default.apiVersion);
      this.set('reloadShopApiVersions', '');
    },

    resetNewCompany() {
      this.set('newCompany', {
        company: '',
        shopUrlPrefix: '',
        name: '',
        email: '',
        password: '',
        plan: 'free'
      });
    },

    resetPods(onCloseDialogAction) {
      this.set('selectedPodKey', '');
      this.set('selectedPodModel', '');

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    },

    selectPod(selectedPodKey) {
      const activePods = this.activePods || [];
      const selected = R.find(R.pathEq(['_data', 'podKey'], selectedPodKey))(activePods);
      this.set('selectedPodModel', selected);
    },

    getAllActivePods: (0, _emberConcurrency.task)(function* () {
      const data = this.newCompany;
      const results = yield this.server.call('GET', 'api/protected/admin/pods', data);
      const activePods = R.pipe(R_.dotPathOr([], 'data'), R.map(data => {
        if (!data.relatedPods) {
          data.relatedPods = [];
        }

        return Ember.Object.create({
          _data: data
        });
      }))(results);
      this.set('activePods', activePods);
    }).drop(),
    allPodsExcludingSelected: Ember.computed('selectedPodKey', function () {
      const selectedPodKey = this.get('selectedPodKey');
      const activePods = this.get('activePods') || [];

      if (selectedPodKey) {
        return R.reject(R.pathEq(['_data', 'podKey'], selectedPodKey))(activePods);
      }

      return activePods;
    }),
    // async didReceiveAttrs () {
    //   this._super(...arguments)
    //   const adminModel = await this.admin.get('connectAsAdminTask').perform()
    //   this.set('adminModel', adminModel)
    // },
    getConnectedUsersTask: (0, _emberConcurrency.task)(function* () {
      const data = this.newCompany;
      const results = yield this.server.call('GET', 'api/protected/admin/connections', data);
      this.set('connectionsCount', R_.dotPath('data.count')(results));
      this.set('connections', R_.dotPath('data.connections')(results));
    }).drop(),
    saveNewCompanyTask: (0, _emberConcurrency.task)(function* () {
      const data = this.newCompany;
      const results = yield this.server.call('POST', 'api/protected/admin/members', data);
      const podKey = R.path(['data', 'podKey'])(results);
      this.set('newCompany.podKey', podKey);
      this.created.pushObject(this.newCompany);
      this.resetNewCompany();
    }).drop(),
    updateRelationshipsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      const selectedPodModel = this.get('selectedPodModel');
      const data = selectedPodModel.get('_data');
      const selectedPodKey = data.podKey;
      const results = yield this.server.call('PATCH', `api/protected/admin/pods/related/${selectedPodKey}`, data);
      this.resetPods(onCloseDialogAction); // const podKey = R.path(['data', 'podKey'])(results)
      // this.set('newCompany.podKey', podKey)
      // this.created.pushObject(this.newCompany)
      // this.resetNewCompany()
    }).drop(),
    getShopVersionTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      const selectedPodModel = this.get('selectedPodModel');
      const shopVersion = this.shopVersion;
      const results = yield this.server.call('GET', `api/protected/admin/shop/version`, {});
      this.set('shopVersion', results?.data?.latestShopApiVersion || shopVersion);
      this.set('reloadShopApiVersions', results?.data?.reloadShopApiVersions);
    }).drop(),
    updateShopVersionTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      const shopVersion = this.shopVersion;
      const results = yield this.server.call('PATCH', `api/protected/admin/shop/version/${shopVersion}`, {
        reloadShopApiVersions: this.reloadShopApiVersions || ''
      });
      alert(`
      Shop version updated: ${shopVersion}: ${results.data}.
      reloadShopApiVersions: ${this.reloadShopApiVersions}
    `);
      this.resetPods(onCloseDialogAction);
    }).drop()
  }, (_applyDecoratedDescriptor(_obj, "resetPods", [_dec], Object.getOwnPropertyDescriptor(_obj, "resetPods"), _obj), _applyDecoratedDescriptor(_obj, "selectPod", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectPod"), _obj)), _obj)));

  _exports.default = _default;
});