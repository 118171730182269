define("client/pods/components/settings/settings-editor/emails-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      newBlankPaymentReminderEmail() {
        const model = this.model;
        const newBlankPaymentReminderEmail = model.newBlankPaymentReminderEmail();
        model.getData('paymentReminderEmails').pushObject(newBlankPaymentReminderEmail);
      },

      removePaymentReminderEmail(paymentReminderEmail) {
        const msg = 'are you sure you want to remove';

        if (window.confirm(msg)) {
          const model = this.model;
          model.getData('paymentReminderEmails').removeObject(paymentReminderEmail);
        }
      } // addPaymentReminderEmail (paymentReminder, onCloseDialogAction) {
      //   const model = this.get('model')
      //   const paymentReminderEmails = model.getOrSetData('paymentReminderEmails', [])
      //   paymentReminderEmails.pushObject(paymentReminder)
      //   onCloseDialogAction()
      //   this.set('newBlankPaymentReminderEmail', null)
      // },
      // cancelAddPaymentReminderEmail (onCloseDialogAction) {
      //   onCloseDialogAction()
      //   this.set('newBlankPaymentReminderEmail', null)
      // }


    }
  });

  _exports.default = _default;
});