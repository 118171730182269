define("client/pods/admin/payments/card/server-logs/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdminPaymentsCardServerLogsModel extends _model.default {
    get start() {
      return R.pathOr([''], ['_data', 'init'])(this);
    }

    get restart() {
      return R.pathOr([''], ['_data', 'sigint'])(this);
    }

    get uncaughtException() {
      return R.pathOr([''], ['_data', 'uncaughtException'])(this);
    }

    get unhandledRejection() {
      return R.pathOr([''], ['_data', 'unhandledRejection'])(this);
    }

    get isStart() {
      return R.pipe(R.pathOr([''], ['_data', 'type']), R.equals('init'))(this);
    }

    get isRestart() {
      return R.pipe(R.pathOr([''], ['_data', 'type']), R.equals('sigint'))(this);
    }

    get isUncaughtException() {
      return R.pipe(R.pathOr([''], ['_data', 'type']), R.equals('uncaughtException'))(this);
    }

    get isUnhandledRejection() {
      return R.pipe(R.pathOr([''], ['_data', 'type']), R.equals('unhandledRejection'))(this);
    }

  }

  _exports.default = AdminPaymentsCardServerLogsModel;
});