define("client/pods/components/transporters/hct-editor/component", ["exports", "ramda", "client/mixins/date", "client/utils/nventor", "client/mixins/search-contacts", "ember-concurrency", "client/mixins/transporter-accounts", "client/mixins/hct-fetch", "ramda-adjunct"], function (_exports, R, _date, _nventor, _searchContacts, _emberConcurrency, _transporterAccounts, _hctFetch, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_date.default, _hctFetch.default, _searchContacts.default, _transporterAccounts.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    settingsService: Ember.inject.service('settings'),
    // @TODO: figure out a way to handle hct extension. since it will be an external extension, how will model
    // @TODO: know that that data is incomplete?
    // @TODO: move transporter into a modal dialog if isHCT
    // @TODO: then ater processing hct data (on server) then respond with isComplete.
    tagName: 'div',
    isReadonly: false,
    isPickerOnly: false,
    // true: only has transport selector. false: will have additional form info
    isModal: false,
    requestTimer: '',
    // used to keep track of latest timer for when making hct calls
    autoFetchTransporter: true,
    showAdvancedAddress: false,

    async init() {
      this.crud.addLists(this, ['extensions/apps/tw/hct/batches', 'extensions/apps/tw/t-cat/lists/thermosphere']);
      this.autoFetchTransporter = R.propOr(true, 'autoFetchTransporter')(this);

      this._super(...arguments);

      const model = this.model;
      const transporterKey = model.getData('transporterKey');

      if (!this.transporter && transporterKey && this.autoFetchTransporter) {
        const transporter = await this.findTask.perform({
          adapterName: 'contacts',
          appendPath: transporterKey
        });
        this.set('transporter', transporter);
      }

      if (this?.model?._data?.hasAdvancedAddress) {
        this.set('showAdvancedAddress', true);
      }
    },

    afterLoadLists() {
      this._super(...arguments);

      const model = this.model;

      if (model.getData('transporterExtension') === 'hct') {
        if (!model.getData('transporterAccountNumber')) {
          this.setDefaultHCTAccount(model);
        }

        if (this.fetchHCTRegionDataOnInit && model.getData('transporterExtension') === 'hct' && !model.getData('transporterRegion')) {
          this.fetchHCTRegionDataTask.perform(model);
        }
      }
    },

    /**
     * disable if component isReadonly or if has already been despatched
     * @return {[type]} [description]
     */
    isDisabled: Ember.computed('isReadonly', 'model._data.transporterIsDispatched', 'disabled', function () {
      if (!R.isNil(this.disabled)) {
        return this.disabled;
      }

      const isReadonly = this.isReadonly;
      const model = this.model;
      const isDispatched = model.getData('transporterIsDispatched');

      if (isReadonly || isDispatched) {
        return true;
      }

      return false;
    }),
    disallowChangeSenderData: Ember.computed('isReadonly', 'model._data.hasCustomSenderAddress', 'disabled', function () {
      return this.isReadonly;
    }),

    setDefaultHCTAccount(model) {
      model.setData('transporterBatchNumber', '1');
      model.setData('transporterBatchLabel', '1');
      const hasTransporterPackageTemperature = R.pipe(R.path(['_data', 'transporterPackageTemperature']), RA.isNotNilOrEmpty)(model);

      if (!hasTransporterPackageTemperature) {
        const transporterAccounts = this.crud.lists['extensions-apps-tw-t-cat-lists-thermosphere'];
        const transporterAccountValue = R.pathOr('', [0, '_data', 'value'])(transporterAccounts);
        model.setData('transporterPackageTemperature', transporterAccountValue);
      }

      const hasTransporterSenderName = R.pipe(R.path(['_data', 'transporterSenderName']), RA.isNotNilOrEmpty)(model);

      if (!hasTransporterSenderName) {
        const transporterSenderName = this?.transporter?._data?.senderName || this.settingsService?.settingsModel?._data?.company;
        model.set('_data.transporterSenderName', transporterSenderName);
      }

      const hasTransporterSenderTelephone = R.pipe(R.path(['_data', 'transporterSenderTelephone']), RA.isNotNilOrEmpty)(model);

      if (!hasTransporterSenderTelephone) {
        const transporterSenderTelephone = this?.transporter?._data?.senderTelephone || this.settingsService?.settingsModel?._data?.companyTel;
        model.set('_data.transporterSenderTelephone', transporterSenderTelephone);
      }

      const hasTransporterSenderAddress = R.pipe(R.path(['_data', 'transporterSenderAddress']), RA.isNotNilOrEmpty)(model);

      if (!hasTransporterSenderAddress) {
        const transporterSenderAddress = this?.transporter?._data?.senderAddress || this.settingsService?.settingsModel?._data?.address;
        model.set('_data.transporterSenderAddress', transporterSenderAddress);
      }

      if (!model.getData('transporterAccountNumber')) {
        model.setData('transporterAccountNumber', this.defaultTransporterAccountNumber);

        if (!model.getData('packages')) {
          model.setData('packages', '1');
        }

        this.setHCTWeightRate(this.defaultTransporterAccount);
      }
    },

    unsetDefaultHCTAccount(model) {
      model.setData('transporterBatchNumber', '');
      model.setData('transporterBatchLabel', '');
      model.setData('transporterAccountNumber', '');
      model.setData('transporterAccount', '');
      model.setData('packages', '');
      model.setData('transporterWeightRate', '');
      model.setData('transporterExtension', '');
      model.setData('transporterPackageTemperature', '');
    },

    /**
     * update hct region data
     */
    // updateHCTRegion(model) {
    //
    //   const self = this;
    //
    //   const hasPreviousTimer = self.get('requestTimer');
    //   if (hasPreviousTimer) {
    //
    //     clearTimeout(hasPreviousTimer);
    //   }
    //
    //   return new Ember.RSVP.Promise(function (resolve, reject) {
    //
    //     if (model.getData('isHCT') && model.getData('address') && !isPickerOnly) {
    //
    //       //do not fetch hct region data immediately on key press changes,
    //       //allow a slight delay before fetching, this will minimise http calls
    //       const requestTimer = setTimeout(function () {
    //
    //         //must wait for first request to finish before requesting again
    //         const hasPreviousRequest = self.get('request');
    //         if (hasPreviousRequest) {
    //           hasPreviousRequest.then(function () {
    //
    //             return resolve(self._fetchHCTRegionData(model));
    //           });
    //         } else {
    //
    //           return resolve(self._fetchHCTRegionData(model));
    //         }
    //       }, 600);
    //
    //     } else {
    //
    //       model.setData('transporterRegion', '');
    //       model.setData('transporterRegionIsOuter', '');
    //     }
    //
    //     self.set('requestTimer', requestTimer);
    //   });
    // },
    unloadHCT(model) {
      model.setData('transporterRegion', '');
      model.setData('transporterRegionIsOuter', '');
      this.unsetDefaultHCTAccount(model);
    },

    setHCTWeightRate(account) {
      const hasAccount = RA.isNotNilOrEmpty(account);

      if (hasAccount) {
        const weightRate = account.getData('rate');
        const model = this.model;
        model.setData('transporterWeightRate', weightRate);
        model.setData('transporterAccount', account.getData('name'));
      }
    },

    fetchAndSubmitTask: (0, _emberConcurrency.task)(function* () {
      try {
        const model = this.model;
        const transporterRegion = model.getData('transporterRegion');

        if (transporterRegion !== '' && transporterRegion != null && transporterRegion !== false) {
          model.set('showHCTModal', false);
          return this._submit(model);
        }

        const fetchedTransporterRegion = yield this.fetchHCTRegionDataTask.perform(model);
        const errors = this.isHCTIncomplete(model);

        if (errors) {
          this.set('errors', errors);
          return false;
        }

        if (fetchedTransporterRegion) {
          return this._submit(model);
        }
      } catch (err) {
        this.set('errors', err.data);

        _nventor.default.throw('fetchAndSubmitTask failed', err);
      }
    }).drop(),

    _submit(model) {
      model.set('showHCTModal', false);
      return this.onSubmitTask.perform({
        appendPath: 'final'
      });
    },

    toggleShowAdvancedAddress(value) {
      const showAdvancedAddress = this.showAdvancedAddress;

      if (RA.isNotNilOrEmpty(value) && R.is(Boolean, value)) {
        this.set('showAdvancedAddress', value);
      } else {
        this.set('showAdvancedAddress', R.not(showAdvancedAddress));
      }
    },

    loadCustomSenderDetails(model) {
      if (!this.hadCustomSenderDetails || !model) {
        return;
      }

      this.setTransporterSender(this.customSenderDetails);
    },

    setTransporterSender() {
      let transporterPersonData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const docSalesModel = this.model;

      if (!docSalesModel) {
        return;
      }

      const transporterData = R.pipe(R.toPairs, R.filter(R.pipe(R.head, R.test(/^transporter/))), R.fromPairs)(transporterPersonData); // Set transporter data to docSales

      for (const transporterKey in transporterData) {
        const value = transporterData[transporterKey];
        const hasValue = RA.isNotNilOrEmpty(value);

        if (hasValue) {
          docSalesModel.setData(transporterKey, value);
        }
      }
    },

    updateSenderDetails(sender) {
      const selectedSenderName = sender?.name;
      this.set('selectedSenderName', selectedSenderName);
      this.setTransporterSender(sender);
    },

    refreshModel(model) {
      this.set('transporter', model);
    },

    actions: {
      loadTransporterContactPerson(person) {
        const model = this.model;
        const name = person.getData('name');
        model.setData('person', name);
      },

      /**
       * action to handle when address has changed with input change
       */
      hctAddressChanged() {
        const model = this.model;
        model.setData('transporterRegion', '');
        model.setData('transporterRegionIsOuter', '');

        if (this.fetchHCTRegionDataOnInit && model.getData('transporterExtension') === 'hct') {
          this.fetchHCTRegionDataTask.perform(model);
        }
      },

      hctSetWeightRate(account) {
        this.setHCTWeightRate(account);
      },

      loadTransporterAddress(prop, detail) {
        this.onLoadContactDetail(prop, detail);
        this.send('hctAddressChanged');
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "toggleShowAdvancedAddress", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleShowAdvancedAddress"), _obj), _applyDecoratedDescriptor(_obj, "loadCustomSenderDetails", [_dec2], Object.getOwnPropertyDescriptor(_obj, "loadCustomSenderDetails"), _obj), _applyDecoratedDescriptor(_obj, "setTransporterSender", [_dec3], Object.getOwnPropertyDescriptor(_obj, "setTransporterSender"), _obj), _applyDecoratedDescriptor(_obj, "updateSenderDetails", [_dec4], Object.getOwnPropertyDescriptor(_obj, "updateSenderDetails"), _obj), _applyDecoratedDescriptor(_obj, "refreshModel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "refreshModel"), _obj)), _obj)));

  _exports.default = _default;
});