define("client/pods/components/documents/search-filters-advanced/component", ["exports", "client/mixins/doc-list-by", "client/mixins/crud"], function (_exports, _docListBy, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _docListBy.default, {
    crud: Ember.inject.service(),
    tagName: '',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['documents/lists/filters/search', 'documents/lists/filters/statuses', 'documents/lists/filters/dispatchStatus', 'documents/lists/filters/sort', 'filters/count', 'filters/sortDirection']);

      if (!this.onListByTask) {
        this.set('onListByTask', this.listByTask);
      }
    },

    actions: {
      addAdvancedFilter() {},

      search() {}

    }
  });

  _exports.default = _default;
});