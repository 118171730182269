define("client/pods/components/documents/activity-list/component", ["exports", "client/mixins/dispatch", "ramda-extension"], function (_exports, _dispatch, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_dispatch.default, {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    showDetails: false,
    hasTabLinks: true,
    hasOrderDate: Ember.computed('showOrderDate', 'tab.{docType,docName}', function () {
      if (this.showOrderDate && this.get('tab.docType') === 'sales' && this.get('tab.docName') === 'invoices') {
        return true;
      }

      return false;
    }),
    dispatchProcessesSteps: Ember.computed('crud.lists.dispatch-lists-processes.[]', 'dispatchProcessesList.[]', function () {
      if (this.dispatchProcessesList) {
        return this.dispatchProcessesList;
      }

      return R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
    }),
    actions: {
      toggleView() {
        this.toggleProperty('showDetails');
      }

    }
  });

  _exports.default = _default;
});