define("client/pods/components/menus/menu-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['ul'],
    translate: true,
    menu: null,
    actions: {
      selected: function (item, tab) {
        // must always use action set on item model
        this.set('action', item.getData('action'));
        this.sendAction('action', item, tab);
      }
    }
  });

  _exports.default = _default;
});