define("client/pods/components/products/files/files-gallery-uploader/component", ["exports", "client/pods/components/products/files/mixins/products-files"], function (_exports, _productsFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_productsFiles.default, {
    fetchUploads: true,
    fetchProduct: true,
    allowSetDefault: true,
    allowUploads: false,
    showDefaultImagePreview: true
  });

  _exports.default = _default;
});