define("client/pods/components/products/panels-new/component", ["exports", "client/mixins/crud", "client/utils/nventor"], function (_exports, _crud, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'products',
    isEditingVariants: false,
    init: function () {
      this._super(...arguments);

      this.setup();
      this.tabsManager.setHeader(this.tabParent, this.intl.t('new'));
    },

    setup() {
      if (!this.model) {
        const name = this.name || '';

        const resourceKeyTemp = _nventor.default.random.alphaNum(8);

        const data = {
          name,
          resourceKeyTemp
        }; // setup new products model

        const model = this.crud.setupNewRecord?.({
          adapterName: this.adapterName,
          data
        });
        this.set('model', model);
        this.set('newModels', [model]);
      }
    }

  });

  _exports.default = _default;
});