define("client/pods/components/messages/tab-container/messages-window/message-bubble/file-type/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tagName: 'div',
    isShowPreview: false,

    didInsertElement() {
      this._super(...arguments);

      const messageBubble = this.messageBubble;

      if (this.isNotText(messageBubble)) {
        this.set('messageType', messageBubble.type);
        this.set('messageUrl', messageBubble.payload.url);
        const messageName = R.pipe(R.pathOr('', ['payload', 'filePath']), R.split('/'), R.takeLast(1), R.join(''))(messageBubble);
        this.set('messageName', messageName);
      }
    },

    isNotText: R.is(Object),
    actions: {
      showPreview() {
        this.set('isShowPreview', true);
      }

    }
  });

  _exports.default = _default;
});