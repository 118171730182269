define("client/pods/components/channels/shop/campaigns/panels-schedule/day-schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AalxkyOk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-calendar__item u-truncate\"],[8],[0,\"\\n  \"],[5,\"helpers/tab-link\",[],[[\"@isHoverLink\",\"@hasLink\",\"@tooltipContent\",\"@tabOptions\"],[false,[24,[\"model\",\"_data\",\"_key\"]],[24,[\"model\",\"_data\",\"name\"]],[28,\"hash\",null,[[\"component\",\"title\",\"loadInstructions\"],[\"channels/shop/campaigns/tab-container\",\"campaigns\",[28,\"hash\",null,[[\"find\"],[[28,\"hash\",null,[[\"id\"],[[24,[\"model\",\"_data\",\"_key\"]]]]]]]]]]]]],{\"statements\":[[0,\"\\n\\n    \"],[1,[28,\"channels/shop/shop-status\",null,[[\"model\",\"badgeOnly\"],[[24,[\"model\"]],true]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"_data\",\"salesPersons\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"_data\",\"commissionsLatest\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\",true],[10,\"class\",\"icon\"],[8],[0,\"\\n            \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[\"fa-solid fa-sack-dollar\"]]],false],[0,\"\\n          \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[24,[\"model\",\"_data\",\"name\"]],false],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-schedule/day-schedule/template.hbs"
    }
  });

  _exports.default = _default;
});