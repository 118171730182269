define("client/pods/components/products/inventory/inventory-manager/component", ["exports", "ember-concurrency", "client/mixins/crud"], function (_exports, _emberConcurrency, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    allowAdjustment: true,
    filtersAdapterName: 'products/inventory/filters',

    didInsertElement() {
      this._super(...arguments);

      this.fetchChildrenTask.perform();
    },

    title: '',
    _title: Ember.computed('title', 'product.{hasVariantsOrChoices,_data.isSet,_data.name}', function () {
      const hasVariantsOrChoices = this.get('product.hasVariantsOrChoices');

      if (hasVariantsOrChoices) {
        return '';
      }

      const isSet = this.get('product._data.isSet');

      if (isSet) {
        return this.get('product._data.name');
      }

      return this.title;
    }),
    fetchChildrenTask: (0, _emberConcurrency.task)(function* () {
      const master = this.get('product._data._key');

      if (master) {
        const adapterName = 'products';
        const appendPath = '/children';
        const data = {
          master
        };
        const children = yield this.dataManager.findAll({
          adapterName,
          appendPath,
          data
        });
        this.set('children', children);
      }
    }),
    fetchAdjustmentsTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      const product = this.product;
      const searchSettings = {
        adapterName: 'products/inventory/adjustments',
        appendPath: product.getData('_key'),
        resultsProperty: '',
        resultsToggle: '',
        filters
      };
      const adjustmentsData = yield this.searchRecordsTask.perform(searchSettings);
      this.set('adjustmentsData', adjustmentsData);
      return adjustmentsData;
    })
  });

  _exports.default = _default;
});