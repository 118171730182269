define("client/pods/components/app-companies/component", ["exports", "@glimmer/component", "ramda", "ember-concurrency-decorators"], function (_exports, _component, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AppCompaniesComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, (_class = class AppCompaniesComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "auth", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "dataManager", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor6, this);

      _initializerDefineProperty(this, "settings", _descriptor7, this);

      _initializerDefineProperty(this, "pods", _descriptor8, this);

      _initializerDefineProperty(this, "selectedPod", _descriptor9, this);

      _initializerDefineProperty(this, "defaultPod", _descriptor10, this);

      const pods = this.auth.getUserProp('pods') || [];
      const defaultPod = this.auth.getUserProp('pod') || [];
      this.defaultPod = defaultPod;
      this.selectedPod = defaultPod;
      this.pods = R.map(pod => {
        return Ember.Object.create(pod);
      })(pods);
    }

    *loginToCompanyTask() {
      const podKey = this.selectedPod;
      let confirmed = true;

      if (this.args.confirm) {
        const pods = this.pods || [];
        const podData = R.find(R.propEq('podKey', podKey))(pods);
        const msg = this.intl.t('log into new company?', {
          company: podData.company
        });
        confirmed = window.confirm(msg);
      }

      if (!confirmed) {
        this.selectedPod = this.defaultPod;
      }

      if (confirmed) {
        let loginData;
        const tokenData = this.auth.getTokenData();

        if (tokenData.pod === podKey) {
          // same podKey, dont need to re-login
          // no need to re-login with server
          const token = this.auth.getToken();
          loginData = yield this.auth.loginToCompanyTask.perform(podKey, {
            token,
            tokenData
          });
        } else {
          loginData = yield this.auth.loginToCompanyTask.perform(podKey);
        }

        if (this.args.onChange) {
          // clear local storage
          this.dataManager.resetCache();
          this.tabsManager.resetTabs(); // reload pod settings

          yield this.settings.setupTask.perform();
          this.args.onChange(loginData);
          this.router.transitionTo('protected.company', loginData.username, loginData.userId, loginData.company, {
            queryParams: {
              reload: true
            }
          });
        } else {
          // return this.router.transitionTo('protected.company', loginData.username, loginData.userId, loginData.company)
          return this.router.transitionTo('protected.company', loginData.username, loginData.userId, loginData.company, {
            queryParams: {
              reload: true
            }
          });
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "pods", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedPod", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "defaultPod", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loginToCompanyTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loginToCompanyTask"), _class.prototype)), _class));
  _exports.default = AppCompaniesComponent;
});