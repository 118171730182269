define("client/pods/components/websites/websites-editor/page-import-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RnJZPTfl",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@tab\",\"@onInsertTemplate\",\"@onReplaceTemplate\",\"@disabled\",\"@label\"],\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"label\",\"icon\",\"disabled\",\"closeOnOverlayClick\"],[[23,6,[]],\"fas fa-file-import\",[23,5,[]],false]],{\"statements\":[[0,\"\\n  \"],[5,\"websites/websites-editor/templates\",[],[[\"@tab\",\"@onCloseDialogAction\",\"@onInsertTemplate\",\"@onReplaceTemplate\"],[[23,2,[]],[23,1,[]],[23,3,[]],[23,4,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/websites-editor/page-import-btn/template.hbs"
    }
  });

  _exports.default = _default;
});