define("client/pods/constants/service", ["exports", "client/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      this.setProperties(_constants.default);
    }

  });

  _exports.default = _default;
});