define("client/pods/components/products/over-sales-limited-qty-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G/rzYp2U",
    "block": "{\"symbols\":[\"@overSalesLimitedQtyStatus\",\"@model\",\"@isOverSalesLimitedQty\"],\"statements\":[[4,\"if\",[[28,\"or\",[[23,3,[]],[23,2,[\"_data\",\"isOverSalesLimitedQty\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"span\",true],[11,\"class\",[29,[\"tag \",[23,0,[\"overSalesLimitedQtyStatusClass\"]]]]],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"tt\",[[23,1,[]]],null],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/over-sales-limited-qty-tag/template.hbs"
    }
  });

  _exports.default = _default;
});