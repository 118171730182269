define("client/pods/components/websites/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RAnG7v11",
    "block": "{\"symbols\":[],\"statements\":[[5,\"websites/websites-editor\",[],[[\"@tab\",\"@model\",\"@errors\",\"@isEditing\",\"@isNew\",\"@saveTask\",\"@onAfter\",\"@onCancel\"],[[22,\"tab\"],[22,\"model\"],[22,\"errors\"],true,true,[22,\"createRecordTask\"],[28,\"action\",[[23,0,[]],\"onAfter\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-new/template.hbs"
    }
  });

  _exports.default = _default;
});