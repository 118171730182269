define("client/pods/components/contacts/details/editor-details/editor-address/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: '',
    autofocus: true,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['contacts/lists/subTypesAddress']);
    },

    contactsListsSubTypesAddress: Ember.computed('crud.lists.contacts-lists-subTypesAddress', function () {
      const list = this.crud.lists['contacts-lists-subTypesAddress'];
      return list;
    })
  });

  _exports.default = _default;
});