define("client/pods/components/extensions/apps/tw/e-invoices/tracks-manager/numbers/row/component", ["exports", "@glimmer/component", "ramda", "ramda-extension", "client/utils/nventor"], function (_exports, _component, R, R_, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwEInvoicesTracksManagerNumbersRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class ExtensionsAppsTwEInvoicesTracksManagerNumbersRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dateService", _descriptor2, this);

      _initializerDefineProperty(this, "isReadonly", _descriptor3, this);

      _initializerDefineProperty(this, "dirty", _descriptor4, this);

      _initializerDefineProperty(this, "calculatedRemainingCount", _descriptor5, this);

      const isNew = R.pathOr(false, ['args', 'isNew'])(this);

      if (isNew) {
        this.setDefaultsForNew();
      }
    }

    calculateRemainingCount() {
      if (this.isCurrentBook) {
        const start = R_.dotPath('modelProxy._data.availableStartNumber')(this) || 0;
        const end = R_.dotPath('modelProxy._data.endNumber')(this) || 0;
        this.calculatedRemainingCount = end - start + 1;
      } else {
        const start = R_.dotPath('modelProxy._data.startNumber')(this) || 0;
        const end = R_.dotPath('modelProxy._data.endNumber')(this) || 0;
        this.calculatedRemainingCount = end - start + 1;
      }
    }

    get isCurrentBook() {
      const trackNumberIndex = R.path(['args', 'numbersSetData', '_data', 'index'])(this);
      const currentNumberBatchIndex = R.pathOr(0, ['args', 'currentNumberModel', 'batchIndex'])(this);

      if (trackNumberIndex == null) {
        return false;
      }

      const matchingTrackNumberIndex = trackNumberIndex === currentNumberBatchIndex;

      if (!matchingTrackNumberIndex) {
        return false;
      }

      const index = R.pathOr('', ['args', 'model', '_data', 'index'])(this);
      const prefix = R.pathOr('', ['args', 'model', '_data', 'prefix'])(this);
      const matchingPrefix = R.pathEq(['args', 'currentNumberModel', 'prefix'], prefix)(this);
      const matchingIndex = R.pathEq(['args', 'currentNumberModel', 'index'], index)(this);
      return matchingIndex && matchingPrefix;
    }

    get isCurrentBookPassed() {
      const trackNumberIndex = R.path(['args', 'numbersSetData', '_data', 'index'])(this);
      const currentNumberBatchIndex = R.pathOr(0, ['args', 'currentNumberModel', 'batchIndex'])(this);
      const allocatedNumbersIndex = R.path(['args', 'model', '_data', 'index'])(this);
      const currentNumberIndex = R.pathOr(0, ['args', 'currentNumberModel', 'index'])(this);

      if (trackNumberIndex == null) {
        return true;
      }

      if (allocatedNumbersIndex == null) {
        return true;
      }

      if (currentNumberBatchIndex > trackNumberIndex) {
        return true;
      }

      if (trackNumberIndex === currentNumberBatchIndex && currentNumberIndex > allocatedNumbersIndex) {
        return true;
      }

      return false;
    }

    get currentNumber() {
      return R.pathOr(0, ['args', 'currentNumberModel', 'currentNumber'])(this);
    }

    get remainingCount() {
      const isCurrentBook = this.isCurrentBook;
      let startBookNumber = R_.dotPath('args.model._data.startNumber')(this);
      const endBookNumber = R_.dotPath('args.model._data.endNumber')(this);
      const index = R_.dotPath('model._data.index')(this);

      if (isCurrentBook.index > index) {
        return 0;
      }

      if (isCurrentBook && this.currentNumber === endBookNumber) {
        return 0;
      }

      if (!this.currentNumber) {
        return endBookNumber - startBookNumber + 1;
      }

      startBookNumber = this.currentNumber || startBookNumber;
      return endBookNumber - startBookNumber;
    }

    get hasCurrentNumberPassedStartBook() {
      if (this.isCurrentBookPassed) {
        return true;
      }

      if (this.isCurrentBook) {
        const startBookRange = R.pipe(_nventor.default.safeHead, R.propOr(0, 'startNumber'))(this.startBookRange);
        return this.currentNumber >= startBookRange;
      }

      return false;
    }

    get hasCurrentNumberPassedEndBook() {
      if (this.isCurrentBookPassed) {
        return true;
      }

      if (this.isCurrentBook) {
        const endBookRange = R.pipe(_nventor.default.safeLast, R.propOr(0, 'endNumber'))(this.endBookRange);
        return this.currentNumber >= endBookRange;
      }

      return false;
    }

    get startBookRange() {
      // @NOTE: there is a bug here. when there are two prefixes.
      // allocate all number for first prefix,
      // then allocate number for second prefix. the startRange is wrong for second prefix.
      const allocatedNumbers = R.pathOr([], ['args', 'numbersSetData', '_data', 'allocatedNumbers'])(this);
      const index = R.pathOr(R.length(allocatedNumbers), ['args', 'model', 'index'])(this);
      const bookRange = R.pathOr([], ['args', 'numbersSetData', 'bookRange'])(this);
      const hasAllocatedNumber = R.pipe(R.isEmpty, R.not)(allocatedNumbers);

      if (bookRange && hasAllocatedNumber) {
        const previousAllocatedEndNumber = R.ifElse(() => R.equals(index, 0), R.always(null), R.pipe(R.view(R.lensIndex(R.dec(index))), R.pathOr(null, ['endNumber'])))(allocatedNumbers);
        const allocatedStartNumber = R.pipe(R.view(R.lensIndex(index)), R.pathOr(null, ['startNumber']))(allocatedNumbers);
        const allocatedEndNumber = R.pipe(R.view(R.lensIndex(index)), R.pathOr(null, ['endNumber']))(allocatedNumbers);
        const isLastAllocatedNumber = R.pipe(R.view(R.lensIndex(R.inc(index))), R.pathOr(null, ['endNumber']), R.isNil)(allocatedNumbers);

        if (isLastAllocatedNumber && (this.args.isNew || allocatedNumbers.length > 1)) {
          if (previousAllocatedEndNumber) {
            return R.pipe(R.reject(R.pipe(R.prop('startNumber'), R.lte(R.__, previousAllocatedEndNumber))))(bookRange);
          }
        } else if (allocatedEndNumber && allocatedStartNumber) {
          let range = R.pipe(R.reject(R.pipe(R.prop('endNumber'), R.gt(R.__, allocatedEndNumber))))(bookRange);

          if (previousAllocatedEndNumber) {
            range = R.reject(R.pipe(R.prop('endNumber'), R.lte(R.__, previousAllocatedEndNumber)))(range);
          }

          return range;
        }
      }

      return bookRange;
    }

    get endBookRange() {
      const startNumber = R.pathOr(null, ['dirty', 'startNumber'])(this) || this.args.model.startNumber;
      const index = R.pathOr(0, ['args', 'model', 'index'])(this);
      const prefix = R.pathOr(0, ['args', 'model', 'prefix'])(this);
      const currentNumber = R.pathOr(0, ['args', 'currentNumberModel', 'currentNumber'])(this);
      const currentNumberIndex = R.pathOr(0, ['args', 'currentNumberModel', 'index'])(this);
      const currentNumberPrefix = R.pathOr(0, ['args', 'currentNumberModel', 'prefix'])(this);
      let cutOffNumber = startNumber;

      if (currentNumberPrefix === prefix) {
        cutOffNumber = currentNumber;
      }

      const bookRange = R.pathOr([], ['args', 'numbersSetData', 'bookRange'])(this);
      const endRange = R.filter(range => range.endNumber >= cutOffNumber)(bookRange);
      return endRange;
    }

    get modelProxy() {
      return this.dirty || this.args.model;
    }

    get disableEditBtn() {
      if (!this.isReadonly) {
        return true;
      }

      return this.hasCurrentNumberPassedStartBook && this.hasCurrentNumberPassedEndBook;
    }

    get isDisableToToggle() {
      const period = this.dateService.getPeriod();
      const model = this.modelProxy;
      const mm = model.getData('mm');
      const yyyy = model.getData('yyyy');

      if (mm > period.mm && yyyy > period.yyyy) {
        return true;
      }

      if (this.hasCurrentNumberPassedEndBook) {
        return true;
      }

      return this.isReadonly;
    }

    toggleReadonly() {
      this.isReadonly = !this.isReadonly;
    }

    setDefaultsForNew() {
      const allocatedNumbers = R.pathOr([], ['args', 'numbersSetData', '_data', 'allocatedNumbers'])(this);
      const index = R.length(allocatedNumbers);
      this.args.model.set('_data.index', index);
      const bookRange = R.pathOr([], ['args', 'numbersSetData', 'bookRange'])(this);
      let nextBookRange;

      if (index === 0) {
        nextBookRange = _nventor.default.safeHead(bookRange);
        this.args.model.set('_data.startNumber', nextBookRange.startNumber);
        this.args.model.set('_data.endNumber', nextBookRange.endNumber);
      } else {
        const lastAllocatedNumber = _nventor.default.safeLast(allocatedNumbers);

        const currentBookIndex = R.findIndex(R.propEq('endNumber', lastAllocatedNumber.endNumber))(bookRange);
        nextBookRange = R.view(R.lensIndex(R.inc(currentBookIndex)))(bookRange);
        this.args.model.set('_data.startNumber', nextBookRange.startNumber);
        this.args.model.set('_data.endNumber', nextBookRange.endNumber);
      }

      this.args.model.set('_data.isAllowed', false);
    }

    onEdit() {
      const onEdit = this.args.onEdit;
      this.toggleReadonly();

      if (onEdit) {
        onEdit(...arguments);
      }

      this.dirty = this.crud.setupDirty({
        adapterName: 'extensions/apps/tw/eInvoices/tracks/numbers/allocated',
        model: this.args.model
      });
    }

    onEditBlank() {
      this.dirty = this.crud.setupDirty({
        adapterName: 'extensions/apps/tw/eInvoices/tracks/numbers/allocated',
        model: this.args.model
      });
    }

    updateEndRangeWhenOverLimit(book) {
      const endNumber = R.pathOr(0, ['modelProxy', '_data', 'endNumber'])(this);

      if (book.startNumber > endNumber) {
        this.modelProxy.set('_data.endNumber', book.endNumber);
      }
    }

    onCancel() {
      const onCancel = this.args.onCancel;
      this.toggleReadonly();
      this.dirty = null;

      if (onCancel) {
        onCancel(...arguments);
      }
    }

    onCancelBlanks(onCloseDialogAction) {
      this.dirty = null;
      onCloseDialogAction();
    } // @action
    // onSaveUpdate () {
    //   const saveTask = this.args.saveTask
    //   if (saveTask) {
    //     saveTask(this.modelProxy)
    //   }
    // }


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isReadonly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R.pathOr(true, ['args', 'isReadonly'])(this);
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "calculatedRemainingCount", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "calculateRemainingCount", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "calculateRemainingCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDefaultsForNew", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultsForNew"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEdit", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEditBlank", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onEditBlank"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateEndRangeWhenOverLimit", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "updateEndRangeWhenOverLimit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancel", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancelBlanks", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onCancelBlanks"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwEInvoicesTracksManagerNumbersRowComponent;
});