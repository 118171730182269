define("client/pods/components/settings/defaults/3rd-party-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    settings: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['facebook/pixels', 'google/tracking']);
      const useDefaultsShopDotCom = this.get('model._data.thirdParty.shopDotCom.useDefaultsFromSettings');
      this.set('useDefaultsShopDotCom', useDefaultsShopDotCom);
    },

    isSocialLinksReadonly: Ember.computed('isReadonly', 'model._data.useDefaultsSocialLinks', function () {
      if (this.isReadonly) {
        return true;
      }

      return this.get('model._data.useDefaultsSocialLinks');
    }),
    isFacebookReadonly: Ember.computed('isReadonly', 'model._data.useDefaultsFacebook', function () {
      if (this.isReadonly) {
        return true;
      }

      return this.get('model._data.useDefaultsFacebook');
    }),
    isShopDotComReadonly: Ember.computed('isReadonly', 'useDefaultsShopDotCom', function () {
      if (this.isReadonly) {
        return true;
      }

      return this.useDefaultsShopDotCom;
    }),
    shopDotCom: Ember.computed('useDefaultsShopDotCom', function () {
      const shopDotComData = this.get('model._data.thirdParty.shopDotCom');
      const settingsShopDotComData = this.settings?.settingsModel?._data?.thirdParty?.shopDotCom;

      if (this.useDefaultsShopDotCom) {
        return settingsShopDotComData;
      }

      return shopDotComData;
    }),

    toggleUseDefaultsShopDotCom() {
      this.set('model._data.thirdParty.shopDotCom.useDefaultsFromSettings', this.useDefaultsShopDotCom);
    }

  }, (_applyDecoratedDescriptor(_obj, "toggleUseDefaultsShopDotCom", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleUseDefaultsShopDotCom"), _obj)), _obj)));

  _exports.default = _default;
});