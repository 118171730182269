define("client/pods/components/grapesjs/re-index-modal/component", ["exports", "ramda", "ramda-adjunct", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, R, _ramdaAdjunct, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GrapesjsReIndexModalComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class GrapesjsReIndexModalComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "indexModelArray", _descriptor2, this);

      _initializerDefineProperty(this, "frontendErrors", _descriptor3, this);

      _initializerDefineProperty(this, "saveDisabled", _descriptor4, this);

      _defineProperty(this, "slidesTypeName", 'lory-slides');

      _defineProperty(this, "slideTypeName", 'lory-slide');

      this.setIndexModelArray();
    }
    /**
     * Get Index from current elements, and store the Re-Indexed value
     */


    setIndexModelArray() {
      const selected = this.args.editor.getSelected();
      const targets = selected.findType(this.slideTypeName);
      let index = 0;
      R.forEach(child => {
        index++;
        const children = child.getEl().innerHTML;
        this.indexModelArray.push({
          slide: R.clone(child),
          children,
          index
        });
      })(targets);
    }

    *frontendValidation(indexModelArray) {
      // currently the @model does not contains dirty, thus cannot show validation
      this.frontendErrors = [];
      const errors = this.frontendErrors;
      let i = 0;
      indexModelArray.forEach(model => {
        const index = model.index;

        if (_ramdaAdjunct.default.isNilOrEmpty(index)) {
          errors.push({
            type: 'isRequired',
            message: 'is required',
            path: [`grapejs_index[${i}]`],
            context: {
              key: `grapejs_index[${i}]`
            },
            isError: true
          });
        }

        i++;
      });

      if (errors.length === 0) {
        const uniqueLength = R.pipe(R.pluck('index'), R.map(userInputIndex => Number(userInputIndex)), R.uniq, R.length)(indexModelArray);

        if (uniqueLength !== indexModelArray.length) {
          // there may not be no such error yet
          errors.push({
            type: 'notUnique',
            message: 'should be unique values',
            path: ['grapejs'],
            context: {
              key: 'grapejs'
            },
            isError: true
          });
        }
      }

      if (errors.length > 0) {
        return false;
      }

      return true;
    }

    *onSave(onCloseDialogAction) {
      const indexModelArray = this.indexModelArray;
      const isValid = yield this.frontendValidation.perform(indexModelArray);

      if (!isValid) {
        return;
      }

      const gjsSelectedElement = this.args.editor.getSelected();
      const slides = gjsSelectedElement.findType(this.slidesTypeName)[0];
      const newChildren = R.pipe(R.sortWith([R.ascend(R.prop('index'))]), R.pluck('slide'))(indexModelArray);
      slides.empty();
      slides.append(newChildren);
      onCloseDialogAction();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "indexModelArray", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "frontendErrors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveDisabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setIndexModelArray", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setIndexModelArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "frontendValidation", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "frontendValidation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSave", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSave"), _class.prototype)), _class));
  _exports.default = GrapesjsReIndexModalComponent;
});