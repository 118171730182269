define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/order-value/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    autofocus: false,
    isBetweenAutomation: Ember.computed('automationModel._data.criteria', function () {
      const automationModel = this.get('automationModel');
      const criteria = automationModel.getData('criteria');

      if (criteria === 'isBetween') {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});