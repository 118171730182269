define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-automations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "srQ6je/c",
    "block": "{\"symbols\":[\"@headerBottom\",\"@model\",\"@onEditAutomationItem\",\"@onSetHeaderBottom\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@tabContentOnly\",\"@allowDuplicating\",\"@copyErrors\",\"@dirty\",\"@duplicateRecordTask\",\"@errorMsg\",\"@onAssignSalesPerson\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-editor/automations-editor\",[],[[\"@headerBottom\",\"@isEditing\",\"@isReadonly\",\"@model\",\"@onEditAutomationItem\",\"@onSetHeaderBottom\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@tabContentOnly\",\"@automationsCollection\",\"@fetchAutomationsCollectionTask\",\"@productsList\"],[[23,1,[]],false,false,[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,0,[\"automationsCollection\"]],[23,0,[\"fetchAutomationsCollectionTask\"]],[23,0,[\"productsList\"]]]]],[0,\"\\n\\n\"],[5,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",[],[[\"@allowDuplicating\",\"@copyErrors\",\"@dirty\",\"@duplicateRecordTask\",\"@errorMsg\",\"@hasEditBtn\",\"@model\",\"@onAssignSalesPerson\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@isAutomations\",\"@automationsCollection\",\"@productsList\"],[[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]],false,[23,2,[]],[23,14,[]],[23,15,[]],[23,16,[]],[23,17,[]],[23,18,[]],[23,19,[]],[23,5,[]],[23,6,[]],[23,7,[]],true,[23,0,[\"automationsCollection\"]],[23,0,[\"productsList\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-automations/template.hbs"
    }
  });

  _exports.default = _default;
});