define("client/pods/components/files-manager/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FilesManagerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('uploads'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, (_class = class FilesManagerComponent extends _component.default {
    // individual model in uploadsCollection._data.uploads
    // collection model that holds _data.uploads
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "server", _descriptor5, this);

      _initializerDefineProperty(this, "uploadsService", _descriptor6, this);

      _initializerDefineProperty(this, "loading", _descriptor7, this);

      _initializerDefineProperty(this, "uploadsCollection", _descriptor8, this);

      _initializerDefineProperty(this, "selectedResource", _descriptor9, this);

      _defineProperty(this, "adapterName", 'uploads');

      _defineProperty(this, "collectionAdapterName", 'uploads/collection');

      _defineProperty(this, "filtersAdapterName", 'uploads/filters');

      _defineProperty(this, "finderLabel", this.args.finderLabel || 'files_manager_tags');

      this.crud.addLists(this, ['uploads/lists/resources', 'uploads/lists/tags']);
      this.fetchUploadsTask.perform({
        excludeCopies: false,
        onlyDisplayDefault: this.args.onlyDisplayDefault
      });
    }

    get transformPreviewDefaults() {
      const transformPreview = this.args.transformPreview;

      if (transformPreview) {
        return transformPreview;
      }

      return '/-/resize/x1024/-/format/auto/-/progressive/yes/';
    }

    get translate() {
      return R_.dotPathOr(true, 'args.translate')(this);
    }

    get label() {
      return R_.dotPathOr('upload images', 'args.label')(this);
    }

    get isRunning() {
      return this.loading && this.uploadsCollection || this.fetchUploadsTask.isRunning;
    }

    get allowUploads() {
      const disAllowUploads = R.pathEq(['args', 'allowUploads'], false)(this);

      if (disAllowUploads) {
        return false;
      }

      const allowMultipleItems = R.pathEq(['args', 'allowMultipleItems'], true)(this);

      if (allowMultipleItems) {
        return true;
      }

      return true;
    }

    get filesBrowserFilter() {
      return this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          excludeCopies: true,
          count: 80
        }
      });
    }

    get onlyDisplayDefault() {
      return R.pathOr(false, ['args', 'onlyDisplayDefault'])(this);
    }

    get imageWidth() {
      return R.pathOr('80', ['args', 'imageWidth'])(this);
    }

    get allowMultipleItems() {
      return R.pathOr(true, ['args', 'allowMultipleItems'])(this);
    }

    animateLoading() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }

    afterUploadTask() {
      var _this = this;

      let onCloseDialogActionListBtn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return function* () {
        yield _this.fetchUploadsTask.perform();

        if (typeof onCloseDialogActionListBtn === 'function') {
          onCloseDialogActionListBtn();
        }
      }();
    }

    fetchUploadsTask() {
      var _this2 = this;

      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const {
          excludeCopies = false,
          onlyDisplayDefault = false
        } = options;
        _this2.uploadsCollection = yield _this2.crud.fetchUploadsTask.perform({
          resource: _this2.args.resource,
          resourceKey: _this2.args.resourceKey,
          adapterName: _this2.collectionAdapterName,
          params: {
            excludeCopies,
            onlyDisplayDefault,
            count: 30
          }
        });
      }();
    }

    *onSubmitTask(onCloseDialogAction, options, uploadsModel, data) {
      const setAsDefaultOnSelect = R.pathOr(false, ['args', 'setAsDefaultOnSelect'])(this);
      const allowMultipleItems = R.pathOr(false, ['args', 'allowMultipleItems'])(this);

      if (setAsDefaultOnSelect && !allowMultipleItems) {
        options = R.mergeRight(options, {
          removeCopies: true,
          setAsDefaultOnSelect: true
        });
      }

      return this.copyImageTask.perform(onCloseDialogAction, options, uploadsModel, data);
    }

    *copyImageTask(onCloseDialogAction, options, uploadModels, data) {
      if (RA.isNilOrEmpty(uploadModels)) {
        return;
      } // @NOTE: onSubmit knows about uploadModels, if is an array or a single item


      if (this.args.onSubmit) {
        return this.args.onSubmit(onCloseDialogAction, options, uploadModels, data);
      } // @NOTE: always ensure uploads is an array. CreateBatch, only accepts arrays


      uploadModels = RA.ensureArray(uploadModels);
      this.animateLoading();
      const batch = R.map(model => {
        const data = model.serialize();
        data.resource = this.args.resource;
        data.resourceKey = this.args.resourceKey;
        const newModel = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          data
        });
        const hasOptions = RA.isNotNilOrEmpty(options);

        if (hasOptions) {
          R.forEachObjIndexed((value, key) => newModel.setData(key, value))(options);
        }

        return newModel;
      })(uploadModels);
      const responseData = yield this.crud.createBatchTask.perform({
        appendPath: 'batch/copy',
        adapterName: this.adapterName,
        batch
      });
      R.forEach(upload => {
        const uploadModel = R.find(R.pathEq(['_data', 'uuid'], upload.uuid))(uploadModels);

        if (!uploadModel) {
          return;
        }

        uploadModel.set('isSelected', false);
      })(responseData?.successful);

      if (responseData?.unsuccessful?.length) {
        throw new Error('cannot add items', {
          cause: {
            errors: responseData?.unsuccessful
          }
        });
      }

      yield this.fetchUploadsTask.perform({
        onlyDisplayDefault: this.args.onlyDisplayDefault
      });

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    removeUploadsTask(_ref) {
      var _this3 = this;

      let {
        uploads = []
      } = _ref;
      return function* () {
        const removeAllImagesTasks = R.map(item => _this3.remove.perform(null, {}, item))(uploads);
        yield (0, _emberConcurrency.all)(removeAllImagesTasks);
      }();
    }

    *remove(onCloseDialogAction, options, uploadsModel) {
      yield this.uploadsService.removeTask.perform(onCloseDialogAction, options, uploadsModel);
    }

    *removeAll(file) {
      const data = {
        uuid: file._data.uuid,
        resource: file._data.resource,
        resourceKey: file._data.resourceKey
      };
      return yield this.server.call('DELETE', 'api/protected/uploads/uploadcare/file', data);
    }

    *setAsDefaultImageTask(onCloseDialogActionListBtn, options, uploadsModel, data) {
      this.animateLoading();
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model: uploadsModel
      });
      const hasOptions = RA.isNotEmpty(options);

      if (hasOptions) {
        R.forEachObjIndexed((value, key) => dirty.setData(key, value))(options);
      }

      yield this.crud.updateRecordTask.perform({
        adapterName: this.adapterName,
        appendPath: '/default',
        model: dirty
      });
      yield this.fetchUploadsTask.perform({
        onlyDisplayDefault: this.args.onlyDisplayDefault
      });

      if (this.args.onSetAsDefaultImageTask) {
        yield this.args.onSetAsDefaultImageTask();
      }

      if (onCloseDialogActionListBtn) {
        onCloseDialogActionListBtn();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uploadsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uploadsCollection", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedResource", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterUploadTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "afterUploadTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchUploadsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchUploadsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyImageTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "copyImageTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeUploadsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeUploadsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAll", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setAsDefaultImageTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setAsDefaultImageTask"), _class.prototype)), _class));
  _exports.default = FilesManagerComponent;
});