define("client/pods/components/channels/shop/campaigns/price-filter/component", ["exports", "ramda-adjunct", "@glimmer/component"], function (_exports, RA, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsPriceFilterComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ChannelsShopCampaignsPriceFilterComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dataManager", _descriptor, this);

      this.updateMinRanges();
    }

    newRange() {
      let rangeData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let max = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let min = rangeData.min;

      if (max) {
        min = parseInt(max) + 1;
      }

      const rangeModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/price-filter',
        data: {
          min
        }
      });
      this.addRange(rangeModel);
    }

    addRange(range) {
      const model = this.args.model;
      const shopPriceFilters = model._data.shopPriceFilters || [];
      shopPriceFilters.pushObject(range);
    }

    newBlankRange(range) {
      this.updateMinRanges();
      const isLast = this.isLastRangeIndex(range);

      if (!isLast) {
        return;
      }

      const max = parseInt(range._data.max) || 0;

      if (max !== 0) {
        this.newRange(range, max);
      }
    }

    isLastRangeIndex(range) {
      const model = this.args.model;
      const shopPriceFilters = model._data?.shopPriceFilters || [];
      const rangeIndex = shopPriceFilters.indexOf(range);

      if (rangeIndex === shopPriceFilters.length - 1) {
        return true;
      }

      return false;
    }

    removeRange(range) {
      const isLast = this.isLastRangeIndex(range);
      const model = this.args.model;
      const shopPriceFilters = model._data?.shopPriceFilters || [];
      shopPriceFilters.removeObject(range);

      if (isLast) {
        this.newRange();
      } else {
        model.setData('shopPriceFilters', shopPriceFilters);
      }

      this.updateMinRanges();
    }

    updateMinRanges() {
      const model = this.args.model || {};
      const shopPriceFilters = model._data?.shopPriceFilters || [];

      if (shopPriceFilters.length === 0) {
        this.newRange({
          min: 0
        });
      }

      let previousRange;
      const newRanges = RA.mapIndexed((range, index) => {
        let previousMax = 0;

        if (previousRange) {
          previousMax = previousRange._data.max;
        }

        let currentMin = previousMax;
        const initialMin = range._data?.min || 0;

        if (index === 0 && RA.isNotNilOrEmpty(initialMin)) {
          currentMin = initialMin;
        }

        if (index > 0) {
          currentMin = previousMax + 1;
        }

        Ember.set(range, '_data.min', currentMin);
        let currentMax = parseFloat(range._data?.max) || 0;

        if (currentMax > 0) {
          if (currentMax < currentMin) {
            currentMax = 0;
          }

          Ember.set(range, '_data.max', currentMax);
        }

        previousRange = range;
        return range;
      })(shopPriceFilters);
      Ember.set(model, '_data.shopPriceFilters', newRanges);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "newBlankRange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "newBlankRange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeRange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removeRange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateMinRanges", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateMinRanges"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsPriceFilterComponent;
});