define("client/pods/components/files/files-fetcher/component", ["exports", "ember-concurrency", "client/mixins/crud", "ramda"], function (_exports, _emberConcurrency, _crud, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    tagName: 'span',
    url: 'api/protected/uploads/images',
    fetchUploadsOnInit: true,
    fetchUploadsCollectionOnInit: false,
    page: 1,
    count: 20,
    sort: null,
    adapterName: 'uploads',
    appendPath: '',
    uploadsCollection: null,

    /**
     * @deprecated
     */
    defaultImage: Ember.computed('uploadsCollection._data.uploads.[]', function () {
      const uploadsCollection = this.uploadsCollection;

      if (uploadsCollection) {
        const defaultImage = R.pipe(R.pathOr([], ['_data', 'uploads']), R.filter(R.pathEq(['_data', 'isDefault'], true)), R.view(R.lensPath([0, '_data', 'uuid'])))(uploadsCollection);
        return defaultImage || uploadsCollection.defaultImage;
      }

      return null;
    }),
    didInsertTask: (0, _emberConcurrency.task)(function* (resourceKey) {
      if (this.fetchUploadsOnInit) {
        this._fetchUploadsTask.perform({
          resourceKey
        });
      }

      if (this.fetchUploadsCollectionOnInit) {
        this._fetchUploadsCollectionTask.perform({
          resourceKey
        });
      }

      this.set('sort', [{
        by: 'timestampZ',
        direction: 'DESC'
      }]);
    }),
    _fetchUploadsTask: (0, _emberConcurrency.task)(function () {
      var _arguments = arguments,
          _this = this;

      let {
        resource,
        resourceKey,
        adapterName,
        appendPath,
        filters = {}
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        let results;

        if (resourceKey) {
          if (_this.fetchUploads) {
            results = yield _this.fetchUploads(..._arguments);
          } else {
            resource = resource || _this.resource;
            resourceKey = resourceKey || _this.resourceKey;
            const count = _this.count;
            const page = _this.page;
            const sort = _this.sort;
            const filterData = {
              count: R.pathOr(count, ['_data', 'count'], filters),
              page: R.pathOr(page, ['_data', 'page'], filters),
              sort: R.pathOr(sort, ['_data', 'sort'], filters)
            };
            results = yield _this.dataManager.get('findAll').perform({
              resource,
              resourceKey,
              adapterName,
              appendPath,
              filters: filterData
            });
          }
        }

        _this.set('uploads', results);

        if (_this.onSetImage) {
          _this.onSetImage(results);
        }

        return results;
      }();
    }),

    /**
     * @deprecated
     */
    _fetchUploadsCollectionTask: (0, _emberConcurrency.task)(function () {
      var _arguments2 = arguments,
          _this2 = this;

      let {
        resource,
        resourceKey,
        adapterName,
        appendPath,
        filters
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        if (resourceKey) {
          _this2.set('uploadsCollection', '');

          resource = resource || _this2.resource;
          resourceKey = resourceKey || _this2.resourceKey;
          adapterName = adapterName || _this2.adapterName;
          appendPath = appendPath || _this2.appendPath;
          filters = filters || _this2.filters;
          let results;

          if (_this2.fetchUploads) {
            results = yield _this2.fetchUploads(..._arguments2);
          } else {
            const collectionModel = yield _this2.fetchUploadsTask.perform({
              resource,
              resourceKey,
              adapterName,
              appendPath,
              filters
            });
            results = R.pathOr([], ['_data', 'uploads'], collectionModel);
            const uploadsColl = R.over(R.lensPath(['_data', 'uploads']), uploads => {
              const newUploads = R.filter(R.pathEq(['_data', 'isDefault'], true))(uploads);
              const isEmpty = R.isEmpty(newUploads);
              return isEmpty ? uploads : newUploads;
            })(collectionModel);

            _this2.set('uploadsCollection', uploadsColl);
          }

          _this2.set('uploads', results);

          if (_this2.onSetImage) {
            _this2.onSetImage(results);
          }
        }
      }();
    })
  });

  _exports.default = _default;
});