define("client/pods/components/flows/flow-payload-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _component, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FlowsFlowPayloadEditor = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class FlowsFlowPayloadEditor extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "model", _descriptor2, this);

      this.crud.addLists(this, ['flows/lists/dataKeys']);
    }

    get translateDataKeysList() {
      const dataKeys = R_.dotPathOr([], 'crud.lists.flows-lists-dataKeys')(this);
      return R.pipe(R.reject(R.pathEq(['_data', 'value'], 'custom')), R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.pluck('value'), R.reject(RA.isNilOrEmpty))(dataKeys);
    }

    createModel() {
      const isEditing = this.args.isEditing;

      if (isEditing) {
        const model = this.args.model || {};
        const dirty = this.crud.setupDirty({
          adapterName: 'flows/data',
          model
        });
        const flowPayload = dirty.getData('flowPayload') || {};
        const flowPayloadSteps = this.args.flowPayloadSteps || [];
        const toMerge = R.pipe(R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.reject(R.propEq('dataKey', '')), R.pluck('key'), keys => {
          const keysObject = {};
          R.forEach(key => {
            keysObject[key] = '';
          })(keys);
          return keysObject;
        })(flowPayloadSteps);
        const newFlowPayload = R.mergeLeft(flowPayload, toMerge);
        dirty.setData('flowPayload', newFlowPayload);
        this.model = dirty;
      } else {
        const model = this.crud.setupNewRecord({
          adapterName: 'flows/data',
          data: {}
        });
        this.model = model;
      }
    }

    setFlowPayload(key, value) {
      const flowModel = this.model;
      const flowPayload = flowModel.getData('flowPayload');
      flowPayload[key] = value;
      flowModel.setData('flowPayload', flowPayload);
    }

    cancel(dirty, onCloseDialogAction) {
      if (this.args.isEditing) {
        this.crud.cancelEditing({
          adapterName: 'flows/data',
          dirty
        });
        this.showEditor = false;
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createModel", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "createModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFlowPayload", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setFlowPayload"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  _exports.default = FlowsFlowPayloadEditor;
});