define("client/pods/uploads/service", ["exports", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "ember-concurrency"], function (_exports, _emberConcurrencyDecorators, R, RA, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @module UploadsService
   */
  let UploadsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _emberConcurrencyDecorators.enqueueTask)({
    maxConcurrency: 10
  }), (_class = class UploadsService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "server", _descriptor4, this);

      _defineProperty(this, "adapterName", 'uploads');

      _defineProperty(this, "adapterNameFilters", 'uploads/filters');

      _defineProperty(this, "collectionAdapterName", 'uploads/collection');
    }

    /**
     * Fetch upload file by uuid
     * @param {string} uuid
     * @returns {Generator<*, void, *>}
     */
    *fetchByUuid(uuid) {
      const filters = this.search.setupFilters({
        adapterName: this.adapterNameFilters,
        defaultFiltersData: {
          excludeCopies: true
        }
      });
      return yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters,
        data: {
          uuid
        }
      });
    }

    *removeTask(onCloseDialogAction, options, uploadsModel) {
      const msg = this.intl.t('are you sure you want to delete');
      uploadsModel = RA.ensureArray(uploadsModel);

      if (window.confirm(msg)) {
        const removeAllTasks = R.map(item => this.removeAllTask.perform(item))(uploadsModel);
        yield (0, _emberConcurrency.all)(removeAllTasks);
        return true;
      }

      return false;
    }

    *removeAllTask(file) {
      const data = {
        uuid: file._data.uuid,
        resource: file._data.resource,
        resourceKey: file._data.resourceKey
      };
      return yield this.server.call('DELETE', 'api/protected/uploads/uploadcare/file', data);
    }

    fetchUploadsTask() {
      var _this = this;

      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const {
          excludeCopies = true,
          onlyDisplayDefault = false,
          metadata
        } = options;
        _this.uploadsCollection = yield _this.crud.fetchUploadsTask.perform({ ...metadata,
          adapterName: _this.collectionAdapterName,
          params: {
            excludeCopies,
            onlyDisplayDefault,
            count: 30
          }
        });
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchByUuid", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchByUuid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAllTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeAllTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchUploadsTask", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "fetchUploadsTask"), _class.prototype)), _class));
  _exports.default = UploadsService;
});