define("client/pods/users/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({// beforePopulate (data = {}) {
    //   // @NOTE: when users are stored in other documents, it does not contain
    //   // _id. so would need to make it up to create record
    //   if (data._key && !data._id) {
    //     data._id = `users/${data._key}`
    //   }
    //   return data
    // }
  });

  _exports.default = _default;
});