define("client/pods/components/guests/campaign-main/component", ["exports", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    storage: Ember.inject.service(),
    router: Ember.inject.service(),
    guests: Ember.inject.service(),
    url: '',
    password: '',

    init() {
      this.crud.addLists(this, ['guests/shop/report/lists/subPanels']);

      this._super(...arguments);

      const tokenData = this.guests.getTokenData();
      this.set('tokenData', tokenData);
    },

    afterLoadLists() {
      this._super(...arguments);

      const subPanels = R_.dotPath('crud.lists.guests-shop-report-lists-subPanels')(this) || [];
      const showCommissionsInGuestsView = R.pathEq(['showCommissionsInGuestsView'], true)(this.tokenData);
      const showActivityInGuestsView = R.pathEq(['showActivityInGuestsView'], true)(this.tokenData);

      if (!showCommissionsInGuestsView) {
        const toRemoveObjects = R.filter(panel => R.pathEq(['_data', 'value'], 'commissions', panel))(subPanels);

        if (RA.isNotNilOrEmpty(toRemoveObjects)) {
          subPanels.removeObjects(toRemoveObjects);
        }
      }

      if (!showActivityInGuestsView) {
        const toRemoveObjects = R.filter(panel => R.pathEq(['_data', 'value'], 'activity', panel))(subPanels);

        if (RA.isNotNilOrEmpty(toRemoveObjects)) {
          subPanels.removeObjects(toRemoveObjects);
        }
      }

      const initialPanel = subPanels.get('firstObject');
      this.tabsManager.displaySubPanel(this, initialPanel);
    }

  });

  _exports.default = _default;
});