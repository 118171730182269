define("client/pods/components/guide-menu/guide-menu-nav/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "client/utils/nventor", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _component, R, RA, _nventor, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GuideMenuNavComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('application'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class GuideMenuNavComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "tabsManager", _descriptor, this);

      _initializerDefineProperty(this, "applicationService", _descriptor2, this);

      _initializerDefineProperty(this, "hasInitiated", _descriptor3, this);

      _initializerDefineProperty(this, "_element", _descriptor4, this);

      _initializerDefineProperty(this, "_content", _descriptor5, this);

      _initializerDefineProperty(this, "masterTab", _descriptor6, this);

      _initializerDefineProperty(this, "onInitDeviceIsMobile", _descriptor7, this);

      _initializerDefineProperty(this, "adjustWidth", _descriptor8, this);

      this.masterTab = this.tabsManager.getMasterTab('left');
      this.onInitDeviceIsMobile = this.applicationService.isMobile;
    }

    get showGuideMenu() {
      if (this.args.hide) {
        return false;
      }

      if (this.hasInitiated) {
        // only one menu
        const guideMenuArray = this.guideMenuArray;

        if (guideMenuArray.length === 0) {
          return false;
        }

        if (guideMenuArray.length === 1) {
          const menuSub = R.pathOr([], [0, 'sub'])(guideMenuArray);
          return menuSub.length > 1;
        }
      }

      return true;
    }
    /**
     * To get the registered guide menu
     * @returns {Object}
     */


    get currentGuideMenu() {
      const menuId = this.args?.menuId || '';
      const tab = this.args?.tab || {};

      if (menuId && tab?.get) {
        return tab.get(menuId) || {};
      }

      return {};
    }
    /**
     * To get 'menu' {Array} in current guide menu
     * @returns {Array}
     */


    get guideMenuArray() {
      return this.currentGuideMenu?.menu || [];
    }
    /**
     * To get active menu tab of current guide menu, get the first menu tab by default
     * @returns {Object} current active menuTab or the first menuTab (default)
     */


    get activeGuideMenuTab() {
      const allMenu = this.currentGuideMenu?.menu || [];
      const menuTab = R.find(R.pathEq(['showActive'], true))(allMenu);

      if (!menuTab) {
        return allMenu.firstObject || {};
      }

      return menuTab;
    }
    /**
     * To get the offset of tab header's bottom
     * @returns {String} the vertical position of header
     */


    get offset() {
      const tabHeaderDefaultOffset = 164; // unit: px

      let headerBottom = this.args.headerBottom || tabHeaderDefaultOffset;
      const parent = this.parentPanelGrid;

      if (!this.args.onSetHeaderBottom) {
        headerBottom = this._setHeaderBottom(headerBottom, parent);
      }

      if (this.isModalContainer) {
        const modalHeader = parent.querySelector('.modal-dialog__header');

        if (modalHeader) {
          const offsetRect = modalHeader.getBoundingClientRect();
          headerBottom = offsetRect?.height || 0;
        } else {
          headerBottom = 0;
        }
      }

      return headerBottom;
    }

    _setHeaderBottom(headerBottom, parent) {
      if (RA.isNotNilOrEmpty(headerBottom)) {
        return headerBottom;
      }

      const headerTitle = parent.querySelector('.panel-grid__header-title');

      if (headerTitle && !headerTitle.classList.contains('hidden')) {
        const titleRect = headerTitle?.getBoundingClientRect?.();
        const headerTitleDefaultOffset = 310; // unit: px

        return titleRect?.bottom || headerTitleDefaultOffset;
      }

      const tabHeader = parent.querySelector('.panel-grid__header-with-guide-menu');
      const rectObj = tabHeader?.parentElement?.getBoundingClientRect?.();
      const tabHeaderDefaultOffset = 164; // unit: px

      return rectObj?.bottom || tabHeaderDefaultOffset;
    }
    /**
     * Check if it's inside a modal
     * @returns {boolean}
     */


    get isModalContainer() {
      const parent = this.parentPanelGrid;

      if (parent.classList.contains('is-modal')) {
        return true;
      }

      return false;
    }
    /**
     * Should be inside class='panel-grid'
     * @returns {HTMLDivElement}
     */


    get parentPanelGrid() {
      return this._element.closest('.panel-grid');
    }
    /**
     * To get full visible width
     * @return {string} unit: px
     */


    get fullVisableHeight() {
      const parent = this.parentPanelGrid;
      const tabFooters = parent.querySelectorAll('.panel-grid__footer');

      const _checkLength = tabFooters?.length || 0;

      let rectObj;
      let tabFooter;
      let _count = 0;

      while (_count < _checkLength) {
        tabFooter = tabFooters[_count];
        const tabFooterRect = tabFooter.getBoundingClientRect?.();

        if (tabFooterRect && tabFooterRect?.top > 0) {
          rectObj = tabFooterRect;
          break;
        }

        _count++;
      }

      const footerTop = rectObj?.top || document?.documentElement?.clientHeight;

      if (this.isModalContainer) {
        const _footerTop = tabFooter?.offsetTop || footerTop;

        return `${_footerTop - this.offset}px`;
      }

      return `${footerTop - this.offset}px`;
    }
    /**
     * To get tabFooter's height and used to set paddingBottom of guideMenuNav
     */


    get extraPaddingBottom() {
      if (this.isModalContainer) {
        return '0px';
      }

      const tabFooter = this.parentPanelGrid.querySelector('.panel-grid__footer');
      const rectObj = tabFooter.getBoundingClientRect?.();
      const footerHeight = rectObj?.height || 60;
      return `${footerHeight}px`;
    }
    /**
     * Scroll to the target by classname
     * @param {String} targetClass The specific classname
     * @void
     */


    goToTarget(targetClass) {
      const goTo = this?._content.querySelector?.(`.${targetClass}`) || {};
      const offset = this.offset;

      if (this.isModalContainer) {
        const parent = this.parentPanelGrid.querySelector('.modal-dialog__content');

        _nventor.default.goToElement({
          parent,
          goTo,
          offset
        });
      } else {
        _nventor.default.goToElement({
          goTo,
          offset
        });
      }
    }
    /**
     * setup tracked property _element and adjust for web page / mobile
     * @param {HTMLDivElement} element Default provided by glimmer component
     * @void
     */


    setupElement(element) {
      this._element = element;
    }
    /**
     * setup tracked property _content
     * @param {HTMLDivElement} element Default provided by glimmer component
     * @void
     */


    setupContentElement(element) {
      this._content = element;
      this.modifyLayout(true);
    }
    /**
     * Use to resize guide menu, and uses dropTask to add debounce
     * @param {Object} options contentRect not in use
     */


    resizeTask(_ref) {
      var _this = this;

      let {
        contentRect: {
          width,
          height
        }
      } = _ref;
      return function* () {
        const DEBOUNCE_MS = 500;
        yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
        const tab = _this.args?.tab;
        const parentTab = _this.args?.parentTab;

        if (parentTab?.isActive && tab?.isActive) {
          _this.modifyLayout();
        }
      }();
    }
    /**
     * Modify the layout depends on isMobile
     * @void
     */


    modifyLayout() {
      let isSetup = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.hasInitiated = true;

      if (!this.showGuideMenu) {
        return;
      }

      this._setGuideMenuNavCSSProp();

      if (this.onInitDeviceIsMobile !== this.applicationService.isMobile || isSetup === true) {
        this.onInitDeviceIsMobile = this.applicationService.isMobile;
        Ember.run.scheduleOnce('afterRender', () => {
          this.applicationService.isMobile ? this._adjustForMobile(this._content) : this._adjustForWebPage(this._content);
        });
      }
    }
    /**
     * Use to adjust the offset of the content that guide menu refers to
     * @param {HTMLDivElement} container Uses recursive method to get the closest guide menu content container
     * @void
     */


    _adjustForWebPage() {
      let container = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const navElement = this._element;

      if (RA.isNotNilOrEmpty(container)) {
        let adjustWidth;

        if (this.isModalContainer) {
          adjustWidth = 200;
        } else {
          adjustWidth = navElement?.offsetWidth + navElement?.offsetLeft;
          container.style.marginBottom = '30vh';
        }

        if (adjustWidth > 250) {
          adjustWidth = 250;
        }

        container.style.marginLeft = `${adjustWidth}px`;
        this.currentGuideMenu.width = adjustWidth;
        this.adjustWidth = adjustWidth;
      }
    }
    /**
     * Use to adjust the offset of the content that guide menu refers to
     * @param {HTMLDivElement} container Uses recursive method to get the closest guide menu content container
     * @void
     */


    _adjustForMobile() {
      let container = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (RA.isNotNilOrEmpty(container)) {
        container.style.marginLeft = 0;
        this.adjustWidth = 0;
      }
    }
    /**
     * Before modify layout, set CSS properties
     * @void
     */


    _setGuideMenuNavCSSProp() {
      const element = this._element;

      if (RA.isNotNilOrEmpty(element)) {
        if (this.applicationService.isMobile === false) {
          const style = element.style;
          const fullVisableHeight = this.fullVisableHeight || '40vh';
          const headerBottom = this.args.headerBottom;

          if (!headerBottom) {
            this.args?.onSetHeaderBottom?.();
          }

          Ember.set(style, 'top', `${this.offset}px`);
          Ember.set(style, 'maxHeight', fullVisableHeight);
          Ember.set(style, 'minHeight', fullVisableHeight);
          const extraPaddingBottom = this.extraPaddingBottom;
          Ember.set(style, 'paddingBottom', extraPaddingBottom);
          let adjustWidth = this.adjustWidth;

          if (adjustWidth === null) {
            adjustWidth = element?.offsetWidth + element?.offsetLeft;
          }

          if (adjustWidth > 250) {
            adjustWidth = 250;
          }

          if (adjustWidth !== 0) {
            Ember.set(style, 'maxWidth', `${adjustWidth}px`);
            Ember.set(style, 'minWidth', `${adjustWidth}px`);
          }
        } else {
          const style = element.style;
          Ember.set(style, 'top', '');
          Ember.set(style, 'maxHeight', '');
          Ember.set(style, 'minHeight', '');
          Ember.set(style, 'maxWidth', '');
          Ember.set(style, 'minWidth', '');
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasInitiated", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_element", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_content", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "masterTab", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onInitDeviceIsMobile", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "adjustWidth", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "goToTarget", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "goToTarget"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupElement", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setupElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupContentElement", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setupContentElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resizeTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "resizeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "modifyLayout", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "modifyLayout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_adjustForWebPage", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "_adjustForWebPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_adjustForMobile", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "_adjustForMobile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_setGuideMenuNavCSSProp", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "_setGuideMenuNavCSSProp"), _class.prototype)), _class));
  _exports.default = GuideMenuNavComponent;
});