define("client/pods/components/helpers/info-tooltip-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    tagName: '',
    hasContent: true,
    isViewable: false,
    closeOnOverlayClick: true,
    hasModalOverlay: true,
    autoPosition: true,

    onToggleModalDialog() {
      const hasModalOverlay = this?.hasModalOverlay || false;

      if (hasModalOverlay) {
        Ember.set(this, 'isViewable', false);
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj)), _obj)));

  _exports.default = _default;
});