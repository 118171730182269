define("client/pods/components/payments/card/panels-transactions/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PaymentsCardPanelsTransactionsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('search'), _dec3 = Ember.inject.service('date'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class PaymentsCardPanelsTransactionsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "searchService", _descriptor2, this);

      _initializerDefineProperty(this, "dateService", _descriptor3, this);

      _initializerDefineProperty(this, "period", _descriptor4, this);

      _initializerDefineProperty(this, "pastMonths", _descriptor5, this);

      _defineProperty(this, "adapterName", 'payments/card/transactions');

      _defineProperty(this, "adapterNameFilters", 'payments/card/transactions/filters');

      const nowZ = this.dateService.getMoment();
      const monthNow = nowZ.month() + 1;
      const yearNow = nowZ.year();
      this.mm = monthNow;
      this.yyyy = yearNow;
      this.setPeriod();
      this.pastMonths = this.dateService.getPastMonths(14);
      this.searchRecordsTask.perform();
    }

    updateFilters() {
      this.args.filters.setData('provider', this?.args?.selectedProvider);
      this.args.filters.setData('dateStart', this?.period?.dateStart);
      this.args.filters.setData('dateEnd', this?.period?.dateEnd);
    }

    *searchOnPeriodUpdateTask(period) {
      this.setPeriod(period);
      yield this.searchRecordsTask.perform();
    }

    *searchRecordsTask() {
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: this?.args?.filters,
        resultsProperty: 'results',
        resultsToggle: 'resultsToggle'
      });
    } // @task
    // * onReloadSearchRecordsTask () {
    //   yield this.crud.reloadSearchRecordsTask.perform({
    //     adapterName: this.adapterName,
    //     filters: this?.args?.filters,
    //     resultsProperty: 'results',
    //     resultsToggle: 'resultsToggle',
    //     reset: true
    //   })
    // }


    setPeriod(period) {
      period = period || this?.args?.period || this.dateService.getPeriod();
      this.period = R.mergeRight(period, this.dateService.displayByMonth(period));
      this.updateFilters();
    }

    setupFilename() {
      const period = this.period;
      const {
        dateStart,
        dateEnd
      } = period;
      const filename = `${this.intl.t('credit card transactions')} ${dateStart}-${dateEnd}`;
      Ember.set(this, 'filename', filename);
    }

    displayByMonth(period) {
      period.dateStart = this.dateService.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      period.dateEnd = this.dateService.formatDateForUrl(this.dateService.getMonthEnd(period.yyyy, period.mm));
      this.setPeriod(period);
      this.searchRecordsTask.perform();
      Ember.set(this, 'mm', period.mm);
      Ember.set(this, 'yyyy', period.yyyy);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "searchService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "period", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pastMonths", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchOnPeriodUpdateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchOnPeriodUpdateTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchRecordsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchRecordsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPeriod", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setPeriod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupFilename", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setupFilename"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayByMonth", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "displayByMonth"), _class.prototype)), _class));
  _exports.default = PaymentsCardPanelsTransactionsComponent;
});