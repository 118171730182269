define("client/pods/components/schedules/scheduledPayment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xVMN/cyL",
    "block": "{\"symbols\":[\"@task\",\"@doc\"],\"statements\":[[4,\"forms/form-section\",null,[[\"classNames\"],[\"has-text-left\"]],{\"statements\":[[4,\"forms/form-row\",null,[[\"label\"],[[23,1,[\"_data\",\"description\"]]]],{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[1,[28,\"tt\",[\"scheduled_time\"],null],false],[0,\": \"],[1,[28,\"dateTime\",[[23,1,[\"_data\",\"dateTimeZ\"]]],null],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"tt\",[\"inclTotal\"],null],false],[0,\": \"],[1,[28,\"currency\",[[23,2,[\"_data\",\"netInclTotal\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[5,\"helpers/tab-link\",[],[[\"@isHoverLink\",\"@hasLink\",\"@tabOptions\"],[false,[23,1,[\"_data\",\"master\"]],[28,\"hash\",null,[[\"component\",\"title\",\"docType\",\"docName\",\"loadInstructions\"],[\"documents/tab-container\",[28,\"concat\",[[23,1,[\"_data\",\"docType\"]],\"-\",[23,1,[\"_data\",\"docName\"]]],null],[23,1,[\"_data\",\"docType\"]],[23,1,[\"_data\",\"docName\"]],[28,\"hash\",null,[[\"find\"],[[28,\"hash\",null,[[\"id\"],[[23,1,[\"_data\",\"master\"]]]]]]]]]]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"_data\",\"docNo\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"tag\"],[8],[0,\"\\n          #\"],[1,[23,1,[\"_data\",\"docNo\"]],false],[0,\" - \"],[1,[28,\"tt\",[[28,\"concat\",[[23,1,[\"_data\",\"docType\"]],\"-\",[23,1,[\"_data\",\"docName\"]]],null]],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[7,\"span\",true],[10,\"class\",\"u-models__text u-desc u-desc--mini\"],[8],[0,\"\\n      \"],[7,\"span\",true],[11,\"class\",[29,[\"tag \",[23,1,[\"statusClass\"]]]]],[8],[0,\"\\n        \"],[1,[28,\"tt\",[[23,1,[\"_data\",\"status\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/schedules/scheduledPayment/template.hbs"
    }
  });

  _exports.default = _default;
});