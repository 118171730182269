define("client/pods/components/admin/payments/card/search-filters-activity/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: '',
    adapterName: 'admin/payments/cards',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['admin/payments/card/lists/filters/search', 'admin/payments/card/lists/filters/status', 'admin/payments/card/lists/filters/sort', 'filters/count', 'filters/sortDirection']);
    }

  });

  _exports.default = _default;
});