define("client/pods/components/workflows/workflow-editor/stages-editor/detail-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oCDCDnUV",
    "block": "{\"symbols\":[\"@stage\",\"@model\",\"@isReadonly\",\"@isHeader\"],\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\",true],[8],[0,\"\\n\"],[0,\"    \"],[7,\"th\",true],[8],[0,\"\\n      \"],[1,[28,\"tt\",[\"stage\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"th\",true],[8],[0,\"\\n      \"],[1,[28,\"tt\",[\"description\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \\n\"],[4,\"unless\",[[23,3,[]]],null,{\"statements\":[[0,\"      \"],[7,\"th\",true],[8],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"tr\",true],[8],[0,\"\\n\"],[0,\"    \"],[7,\"td\",true],[8],[0,\"\\n      \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@translatedPlaceholder\",\"@value\"],[[23,3,[]],[28,\"tt\",[\"required\"],null],[23,1,[\"_data\",\"name\"]]]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"td\",true],[8],[0,\"\\n      \"],[5,\"elements/element-textarea\",[],[[\"@inputPvtClassNames\",\"@readonly\",\"@value\",\"@autoResize\"],[\"u-full-width\",[23,3,[]],[23,1,[\"_data\",\"description\"]],true]]],[0,\"\\n    \"],[9],[0,\"\\n    \\n\"],[4,\"unless\",[[23,3,[]]],null,{\"statements\":[[0,\"      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[5,\"elements/btn-delete\",[],[[\"@icon\",\"@label\",\"@onSubmit\",\"@disabled\"],[\"fas fa-trash\",\"\",[28,\"fn\",[[23,2,[\"removeStage\"]],[23,1,[]]],null],[23,3,[]]]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/workflows/workflow-editor/stages-editor/detail-editor/template.hbs"
    }
  });

  _exports.default = _default;
});