define("client/pods/components/files/files-gallery/component", ["exports", "ramda", "ember-concurrency", "client/mixins/crud"], function (_exports, R, _emberConcurrency, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    dragDropSort: Ember.inject.service(),
    crud: Ember.inject.service(),
    classNames: ['images-gallery'],
    url: 'api/protected/uploads/images',
    fetchUploads: true,
    hasError: false,
    onRemove: null,
    onSetDefault: null,
    confirmationOnRemove: true,
    removeConfirmationMsg: 'are you sure you want to delete image?',
    allowSetDefault: true,
    allowRemove: true,
    allowPreview: true,
    translate: true,
    defaultImage: null,
    defaultImageTransfrom: '',
    defaultImageWidth: '200',
    defaultImageHeight: '',
    transform: '',
    imageWidth: '80',
    imageHeight: '',
    allowShowPreviewModal: true,
    indexProp: '_data.index',
    dragDetail: Ember._tracked(),
    dropPosition: Ember._tracked(),
    innerFilesGalleryRowClassNames: Ember.computed('filesGalleryRowClassNames', function () {
      const filesGalleryRowClassNames = R.propOr('', 'filesGalleryRowClassNames')(this);
      return `images-container-items ${filesGalleryRowClassNames}`;
    }),
    _showDefaultImagePreview: Ember.computed('showDefaultImagePreview', 'defaultImageFile._data.uuid', function () {
      if (!this.showDefaultImagePreview) {
        return false;
      }

      return R.path(['defaultImageFile', '_data', 'uuid'])(this) || false;
    }),
    // gets the file for the default image.
    // this is required because default image value may still be there in a model value,
    // but file may have already been removed from uploadsCollection
    defaultImageFile: Ember.computed('uploads.@each.synced', function () {
      const uploads = this.uploads || [];
      return R.find(R.pathEq(['_data', 'isDefault'], true))(uploads);
    }),
    files: Ember.computed('_showDefaultImagePreview', 'uploads.@each.synced', function () {
      let files = this.uploads || [];
      const showDefaultImage = this._showDefaultImagePreview;

      if (showDefaultImage) {
        files = R.reject(R.pathEq(['_data', 'uuid'], showDefaultImage))(files);
      }

      return files;
    }),
    documents: Ember.computed('uploads.@each.synced', function () {
      const uploads = this.uploads || [];
      return uploads.filterBy('isImage', false);
    }),

    async remove(file) {
      const msg = this.intl.t('are you sure you want to delete');

      if (window.confirm(msg)) {
        this.set('hasError', false);
        const data = {
          uuid: file._data.uuid,
          resource: file._data.resource,
          resourceKey: file._data.resourceKey
        };

        try {
          await this.server.call('DELETE', 'api/protected/uploads/uploadcare/file', data);
        } catch (err) {
          this.set('hasError', true);
        }
      }
    },

    showPreview(file) {
      if (this.allowShowPreviewModal) {
        this.set('showPreviewModal', file);
      }
    },

    closePreview() {
      Ember.set(this, 'showPreviewModal', false);
    },

    setAsDefaultImageTask: (0, _emberConcurrency.task)(function* (file) {
      yield this.onSetAsDefaultImageTask(file);
      this.closePreview();
    }),
    reorderDetailsAndUpdateIndexTask: (0, _emberConcurrency.task)(function* (detail) {
      const dragItem = this.dragDetail;
      const onOverItemIndex = detail.get(this.indexProp);
      const itemOver = R.pipe(R.filter(R.pathEq(['_data', 'index'], onOverItemIndex)), R.head)(this.uploads);
      const itemOverIndex = this.uploads.indexOf(itemOver);
      const itemIndex = this.uploads.indexOf(dragItem);
      this.uploads.removeAt(itemIndex);
      this.uploads.insertAt(itemOverIndex, dragItem);
      const forEachIndexed = R.addIndex(R.forEach);
      forEachIndexed((item, index) => {
        item.set('_data.index', index);
      })(this.uploads);
      yield this.crud.ajaxCallTask.perform('PATCH', {
        adapterName: 'uploads/batch',
        allowSubmitWhenEmpty: this.uploads,
        appendPath: '/index',
        model: this.uploads
      });
    })
  }, (_applyDecoratedDescriptor(_obj, "remove", [_dec], Object.getOwnPropertyDescriptor(_obj, "remove"), _obj), _applyDecoratedDescriptor(_obj, "showPreview", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showPreview"), _obj), _applyDecoratedDescriptor(_obj, "closePreview", [_dec3], Object.getOwnPropertyDescriptor(_obj, "closePreview"), _obj)), _obj)));

  _exports.default = _default;
});