define("client/pods/components/guests/campaign-main/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hp0r8j2q",
    "block": "{\"symbols\":[\"subTab\",\"@errors\",\"@tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid panel-grid--has-header\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__tab-header\"],[8],[0,\"\\n\\t\\t\"],[5,\"menus/tab-menu\",[],[[\"@errors\",\"@menu\",\"@onSelect\",\"@tabs\"],[[23,2,[]],[23,0,[\"crud\",\"lists\",\"guests-shop-report-lists-subPanels\"]],[28,\"fn\",[[23,0,[\"tabsManager\",\"displaySubPanel\"]],[23,0,[]]],null],[23,3,[\"subTabs\"]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\n\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[23,3,[\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tab\",\"url\"],[[23,1,[]],[24,[\"url\"]]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/guests/campaign-main/template.hbs"
    }
  });

  _exports.default = _default;
});