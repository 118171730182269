define("client/pods/components/channels/shop/campaigns/campaigns-editor/3rd-party-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dZRz7DcI",
    "block": "{\"symbols\":[\"@headerBottom\",\"@isEditing\",\"@isReadonly\",\"@model\",\"@onSetHeaderBottom\",\"@parentTab\",\"@tab\",\"@tabContentOnly\"],\"statements\":[[5,\"settings/defaults/3rd-party-editor\",[],[[\"@documentationResource\",\"@headerBottom\",\"@hideGuideMenu\",\"@isEditing\",\"@isReadonly\",\"@model\",\"@onSetHeaderBottom\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@tabContentOnly\"],[\"campaigns\",[23,1,[]],false,[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[28,\"if\",[[23,2,[]],[23,0,[\"crud\",\"lists\",\"channels-shop-campaigns-lists-editorSubPanels\"]],[23,0,[\"crud\",\"lists\",\"channels-shop-campaigns-lists-subPanels\"]]],null],[23,7,[]],[23,8,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/3rd-party-editor/template.hbs"
    }
  });

  _exports.default = _default;
});