define("client/pods/components/channels/shop/items-limits-export-btn/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda-extension", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R_, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopItemsLimitsExportBtnComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, (_class = class ChannelsShopItemsLimitsExportBtnComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dateService", _descriptor2, this);

      _initializerDefineProperty(this, "excel", _descriptor3, this);

      _initializerDefineProperty(this, "export", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "search", _descriptor6, this);

      _initializerDefineProperty(this, "fileName", _descriptor7, this);

      _initializerDefineProperty(this, "exportData", _descriptor8, this);

      _initializerDefineProperty(this, "deconstructSet", _descriptor9, this);

      _initializerDefineProperty(this, "onlyHasActivity", _descriptor10, this);

      _initializerDefineProperty(this, "today", _descriptor11, this);

      this.today = this.dateService.formatDate(this.dateService.getToday(), 'YYYYMMDD');
      this.fileName = this.getDefaultFilename();
    }

    getDefaultFilename() {
      const name = R.path(['args', 'model', '_data', 'name'])(this) || '';
      return `${name}-${this.intl.t('limited qty')}-${this.today}`;
    }

    *fetchDataTask(onCloseDialogAction) {
      const model = this.args.model;
      const master = model.getData('_key');
      const filters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/limits/filters',
        defaultFiltersData: {
          master,
          count: 1000
        }
      });
      filters.setData('product.status', ['active']);
      const exportData = yield this.crud.searchRecordsTask.perform({
        adapterName: 'channels/shop/campaigns/details/limits',
        appendPath: `/master/${master}`,
        filters
      });
      const specsForSummary = [{
        column: this.intl.t('product'),
        prop: '_data.item'
      }, {
        column: this.intl.t('product code'),
        prop: '_data.itemCode'
      }, {
        column: this.intl.t('qty sold'),
        color: '',
        prop: '_data.salesQty',

        transform(value, row, spec) {
          return parseInt(value) || 0;
        }

      }, {
        column: this.intl.t('limited sales qty'),
        color: '',
        prop: '_data.hasSalesLimitedQty',

        transform(value, row, spec) {
          if (value) {
            return 'Y';
          }

          return 'N';
        }

      }, {
        column: this.intl.t('limited qty'),
        color: '',
        prop: '_data.salesLimitedQty',

        transform(value, row, spec) {
          return parseInt(value) || 0;
        }

      }, {
        column: this.intl.t('increase/decrease limited qty'),
        color: '',
        prop: '',

        transform(value, row, spec) {
          return parseInt(value) || 0;
        }

      }, {
        column: this.intl.t('allow preOrder'),
        color: '',
        prop: '_data.allowPreOrder',

        transform(value, row, spec) {
          if (value) {
            return 'Y';
          }

          return 'N';
        }

      }, {
        column: this.intl.t('limited preOrder qty'),
        color: '',
        prop: '_data.hasSalesPreOrderLimitedQty',

        transform(value, row, spec) {
          if (value) {
            return 'Y';
          }

          return 'N';
        }

      }, {
        column: this.intl.t('limit preOrder qty'),
        color: '',
        prop: '_data.salesPreOrderLimitedQty',

        transform(value, row, spec) {
          return parseInt(value) || 0;
        }

      }, {
        column: this.intl.t('preOrder-notes'),
        color: '',
        prop: '_data.salesPreOrderLimitedQtyDesc',

        transform(value, row, spec) {
          try {
            return R.trim(value || '');
          } catch (e) {
            return '';
          }
        }

      }, {
        column: this.intl.t('increase/decrease preOrder qty'),
        color: '',
        prop: '',

        transform(value, row, spec) {
          return parseInt(value) || 0;
        }

      }];
      const worksheetData = R.map(row => {
        return R.map(spec => {
          let value;
          const path = spec.prop;

          if (!path) {
            value = '';
          }

          value = R_.dotPath(path)(row);

          if (spec.transform) {
            value = spec.transform(value, row, spec);
          }

          return value;
        })(specsForSummary);
      })(exportData);
      const headers = R.pluck('column')(specsForSummary);
      this.exportData = {
        headers,
        data: worksheetData
      };
    }

    *exportTask(onCloseDialogAction) {
      const exportData = this.exportData;
      const headers = exportData.headers || [];
      const worksheetData = exportData.data || [];
      const sheetWithHeaders = R.prepend(headers)(worksheetData);
      const worksheets = [];
      worksheets.push({
        sheetName: this.today,
        data: sheetWithHeaders
      });
      yield this.excel.exportFile({
        worksheets,
        title: this.fileName
      });
      onCloseDialogAction();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "excel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "export", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fileName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "exportData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "deconstructSet", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "onlyHasActivity", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "today", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchDataTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "exportTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "exportTask"), _class.prototype)), _class));
  _exports.default = ChannelsShopItemsLimitsExportBtnComponent;
});