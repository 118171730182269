define("client/mixins/search-contacts", ["exports", "ember-concurrency", "client/mixins/crud"], function (_exports, _emberConcurrency, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_crud.default, {
    filtersAdapterName: 'contacts/filters',

    /**
     * Deprecated fn, should use contacts service instead
     * @deprecated
     */
    searchContactsTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        relationship,
        query,
        resultsProperty,
        resultsToggle = false,
        disallowForFlatFees = false
      } = _ref;
      return function* () {
        if (query?.trim?.() !== '') {
          const relationships = [];

          if (relationship === 'all') {
            relationships.push('customer');
            relationships.push('supplier');
          } else {
            relationships.push(relationship);
          }

          const filters = _this.search.setupFilters({
            adapterName: _this.filtersAdapterName,
            defaultFiltersData: {
              relationships,
              query,
              match: ['name', 'code', 'taxNumber'],
              sort: [{
                by: 'name',
                direction: 'ASC'
              }],
              status: [_this.get('constants.status.active')],
              disallowForFlatFees
            }
          });

          const adapterName = 'contacts';
          const results = yield _this.searchInputTask.perform({
            adapterName,
            filters,
            query,
            resultsProperty,
            resultsToggle
          });
          return results;
        }

        return [];
      }();
    })
  });

  _exports.default = _default;
});