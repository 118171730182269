define("client/pods/components/extensions/apps/tw/e-invoices/tracks-manager/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwEInvoicesTracksManagerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service('extensions/apps/tw/e-invoices'), _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._action, (_class = class ExtensionsAppsTwEInvoicesTracksManagerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dateService", _descriptor2, this);

      _initializerDefineProperty(this, "eInvoicesService", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "track", _descriptor5, this);

      _initializerDefineProperty(this, "years", _descriptor6, this);

      _initializerDefineProperty(this, "months", _descriptor7, this);

      _initializerDefineProperty(this, "yyyy", _descriptor8, this);

      _initializerDefineProperty(this, "yyyyLabel", _descriptor9, this);

      _initializerDefineProperty(this, "mm", _descriptor10, this);

      _initializerDefineProperty(this, "selectedPeriod", _descriptor11, this);

      _initializerDefineProperty(this, "uploadUnusedBlanks", _descriptor12, this);

      _initializerDefineProperty(this, "uploadedResults", _descriptor13, this);

      const currentPeriod = this.dateService.getPeriod();
      const currentYear = currentPeriod.yyyy;
      this.yyyy = currentYear;
      this.yyyyLabel = currentYear - 1911;
      this.mm = this.dateService.getEvenInvoiceMonth(currentPeriod.mm);
      const period = this.args.period;

      if (RA.isNotNilOrEmpty(period)) {
        this.yyyy = period.yyyy;
        this.mm = period.mm;
      }

      let years = this.dateService.getPastYears({
        number: 2,
        futureYearsNumber: 1
      });
      years = R.map(year => {
        year.set('yyyyLabel', year.yyyy - 1911);
        return year;
      })(years);
      this.years = years;
      const months = R.pipe(R.map(month => {
        const previousMonth = month - 1;
        return Ember.Object.create({
          mm: month,
          label: this.intl.t(`mm${previousMonth}`) + ' / ' + this.intl.t(`month${month}`)
        });
      }))([2, 4, 6, 8, 10, 12]);
      this.months = months;
      const selectedPeriod = R.find(R.propEq('mm', this.mm))(months);
      this.fetchTrackNumbers.perform(selectedPeriod);
    }

    get hasHeader() {
      return R_.dotPathOr(false, 'args.header')(this);
    }

    get translate() {
      return R_.dotPathOr(true, 'args.translate')(this);
    }

    get currentNumber() {
      return R.pipe(R.pathOr([], ['track', '_data', 'current']), R.pathOr(0, ['currentNumber']))(this);
    }

    get allowUploadBlanks() {
      const selectedPeriod = this.selectedPeriod || {};

      if (selectedPeriod.mm) {
        const currentInvoiceMonth = this.dateService.getEvenInvoiceMonth();
        let previousInvoiceMonth;
        const period = this.dateService.getPeriod();
        let isCorrectYear = true;

        if (currentInvoiceMonth === 2) {
          previousInvoiceMonth = 12;
          isCorrectYear = this.yyyy + 1 === period.yyyy;
        } else {
          previousInvoiceMonth = currentInvoiceMonth - 2;
        }

        if (previousInvoiceMonth === selectedPeriod.mm && isCorrectYear) {
          if (period.dd < 5) {
            return true;
          }
        }
      }

      return false;
    }

    *fetchTrackNumbers(selectedPeriod) {
      this.selectedPeriod = selectedPeriod;
      const mm = selectedPeriod.mm;
      const yyyy = this.yyyy;
      const track = yield this.eInvoicesService.fetchTrackNumbers.perform({
        mm,
        yyyy
      });
      this.track = track || {};
    }

    updateTaxYear() {
      this.mm = '';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "eInvoicesService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "track", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "years", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "months", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "yyyy", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "yyyyLabel", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "mm", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectedPeriod", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "uploadUnusedBlanks", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "uploadedResults", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchTrackNumbers", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTrackNumbers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTaxYear", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "updateTaxYear"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwEInvoicesTracksManagerComponent;
});