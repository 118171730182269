define("client/pods/components/settings/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zKELqmoP",
    "block": "{\"symbols\":[\"@tab\"],\"statements\":[[5,\"settings/settings-editor\",[],[[\"@isEditing\",\"@model\",\"@onCancel\",\"@onSaveTask\",\"@tab\"],[true,[23,0,[\"dirty\"]],[28,\"fn\",[[23,0,[\"crud\",\"cancelEditing\"]],[28,\"hash\",null,[[\"adapterName\",\"dirty\",\"onAfter\",\"onAfterOptions\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[\"dirty\"]],[28,\"fn\",[[23,0,[\"displaySubPanel\"]]],null],[28,\"hash\",null,[[\"tab\",\"tabOptions\"],[[23,1,[]],[24,[\"tabOptions\"]]]]],[23,1,[]]]]]],null],[28,\"perform\",[[23,0,[\"crud\",\"updateRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"component\",\"model\",\"onAfter\",\"onAfterOptions\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[]],[23,0,[\"dirty\"]],[28,\"fn\",[[23,0,[\"displaySubPanel\"]]],null],[28,\"hash\",null,[[\"tab\",\"tabOptions\"],[[23,1,[]],[24,[\"tabOptions\"]]]]],[23,1,[]]]]]],null],[23,1,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/panels-edit/template.hbs"
    }
  });

  _exports.default = _default;
});