define("client/pods/components/comments/comments-manager/editor-container/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    isFooter: true,
    isFooterSpacer: false,
    classNames: ['comments__editor-container'],
    height: 60
  });

  _exports.default = _default;
});