define("client/pods/components/channels/shop/items/item-page/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: [],
    images: Ember.computed('item.product.uploadedFiles.[]', function () {
      const images = this.get('item.product.uploadedFiles') || [];
      return images.sortBy('_key');
    }),
    defaultImage: Ember.computed('images', 'item._data.defaultImage', function () {
      if (this.get('item._data.defaultImage')) {
        return this.get('item._data.defaultImage');
      }

      if (this.images.get('firstObject')) {
        const firstImage = this.images.get('firstObject');

        if (firstImage) {
          return firstImage.uuid;
        }
      }

      return false;
    })
  });

  _exports.default = _default;
});