define("client/pods/components/extensions/apps/tw/invoices/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "up+Gtky/",
    "block": "{\"symbols\":[\"result\"],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"u-header-1\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"dateStart\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"date\",[[24,[\"dateStart\"]]],null],false],[0,\" ~ \"],[1,[28,\"date\",[[24,[\"dateEnd\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"date\",[[24,[\"dateEnd\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[7,\"table\",true],[10,\"class\",\"u-table\"],[8],[0,\"\\n\\n\\t\"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"invoice\"],null],false],[9],[0,\"\\n\\t\"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"date\"],null],false],[9],[0,\"\\n\\t\"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"contact\"],null],false],[9],[0,\"\\n\\t\"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"document\"],null],false],[9],[0,\"\\n\\t\"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"total\"],null],false],[9],[0,\"\\n\\t\"],[7,\"th\",true],[8],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"results\"]]],null,{\"statements\":[[0,\"\\n\\t\\t\"],[1,[28,\"extensions/apps/tw/invoices/panels-display/sub/display-item\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/invoices/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});