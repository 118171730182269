define("client/pods/components/extensions/tab-container/component", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    crud: Ember.inject.service(),
    model: null,
    errors: null,

    init() {
      this.crud.addLists(this, ['extensions/lists/menu']);

      this._super(...arguments);
    },

    selectExtension(extensionsSubTabOptions) {
      const self = this;
      const tab = self.get('tab');
      const subTabOptions = {
        component: 'extensions/sub/tab-container',
        options: extensionsSubTabOptions
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

  });

  _exports.default = _default;
});