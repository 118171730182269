define("client/pods/documents/dispatch/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 10,
        query: '',
        master: '',
        match: ['all'],
        documentStatus: [],
        documentStatuses: [],
        dispatchStatus: [],
        notDispatchStatus: [],
        multipleDispatchProcessesCriterias: [],
        date: '',
        dateStart: '',
        dateEnd: '',
        dateZ: '',
        timestampZ_latest: '',
        dispatchDateZ: '',
        docNo: '',
        contact: '',
        code: '',
        ref: '',
        inclTotal: '',
        transporterLabel: '',
        transporterAccount: '',
        waybill: '',
        packages: '',
        paymentMethods: [],
        paymentLastFiveDigits: '',
        sourceKey: '',
        statuses: [this.get('constants.status.active')],
        contactKey: '',
        contactTags: [],
        isVoid: false,
        sort: [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]
      };
    },

    tagsCount: Ember.computed('_data.tags.[]', function () {
      return this.getPropLength('_data.tags');
    }),
    productTags: Ember.computed('_data.productTags.[]', function () {
      return this.getPropLength('_data.productTags');
    }),
    contactTags: Ember.computed('_data.contactTags.[]', function () {
      return this.getPropLength('_data.contactTags');
    }),
    paymentMethodsCount: Ember.computed('_data.paymentMethods.[]', function () {
      return this.getPropLength('_data.paymentMethods');
    }),
    paymentTermsCount: Ember.computed('_data.paymentTerms.[]', function () {
      return this.getPropLength('_data.paymentTerms');
    })
  });

  _exports.default = _default;
});