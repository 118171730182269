define("client/pods/parser/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const parser = new exprEval.Parser();

  var _default = Ember.Service.extend({
    evaluate(expr) {
      let subs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return parser.parse(expr).evaluate(subs);
    }

  });

  _exports.default = _default;
});