define("client/pods/channels/shop/campaigns/details/templates/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/channels/shop/campaigns/details/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/channels/shop/campaigns/details',
    modelObj: _model.default,
    idParam: false,

    serialize(model) {
      const data = this._super(model);

      let isOnSale = model.get('isOnSale');

      if (isOnSale === true) {
        isOnSale = 'on';
      } else {
        isOnSale = 'off';
      }

      data.onSale = isOnSale;
      return data;
    }

  });

  _exports.default = _default;
});