define("client/pods/workflows/stages/model", ["exports", "client/pods/base/model", "client/utils/nventor"], function (_exports, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    intl: Ember.inject.service(),

    defaults() {
      return {
        stageId: _nventor.default.random.alphaNum(8)
      };
    } // name: computed('_data.name', function () {
    //   const isGenerated = R.path(['_data','isGenerated'])(this)
    //   const title = R.path(['_data','name'])(this)
    //   if (title && isGenerated) {
    //     return this.intl.t(title)
    //   }
    //   return title
    // })


  });

  _exports.default = _default;
});