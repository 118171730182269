define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/has-variants/variants-detail/component", ["exports", "client/mixins/crud", "ramda", "ramda-adjunct"], function (_exports, _crud, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, (_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    tagName: '',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['channels/shop/campaigns/lists/commission-filler']);
    },

    setAllChild(_ref) {
      let {
        child,
        commissionPropertyName,
        childIndex
      } = _ref;
      const fillerType = child.get('fillerType');
      const fillerValue = child.getData(commissionPropertyName);
      const itemModel = this?.item || {};
      R.pipe(R.pathOr([], ['_data', 'variantChildren']), RA.mapIndexed((variantChild, index) => {
        if (R.equals(fillerType, 'fillTop') && R.lt(index, childIndex) || R.equals(fillerType, 'fillBottom') && R.gt(index, childIndex) || R.equals(fillerType, 'fillTopAndBottom')) {
          variantChild.setData(commissionPropertyName, fillerValue);
        }
      }))(itemModel);

      if (this.onRecalculatePrice) {
        this.onRecalculatePrice();
      }

      Ember.set(child, 'fillerType', '');
      Ember.set(child, 'fillerLabel', '');
    }

  }, (_applyDecoratedDescriptor(_obj, "setAllChild", [_dec], Object.getOwnPropertyDescriptor(_obj, "setAllChild"), _obj)), _obj)));

  _exports.default = _default;
});