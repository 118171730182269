define("client/pods/components/public/login-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/HXVHkye",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"site\"],[8],[0,\"\\n\\n  \"],[7,\"header\",true],[10,\"class\",\"site-content__header\"],[8],[0,\"\\n\\n\"],[0,\"\\n\"],[0,\"\\n\"],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"site-content__container\"],[8],[0,\"\\n    \"],[1,[22,\"user-login\"],false],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/public/login-page/template.hbs"
    }
  });

  _exports.default = _default;
});