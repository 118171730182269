define("client/pods/components/ledgers/accounts/aging/panels-display/component", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    crud: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      const period = this.get('tab.period');
      this.set('period', period);
    }

  });

  _exports.default = _default;
});