define("client/pods/components/channels/shop/rewards/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cPFTqTB1",
    "block": "{\"symbols\":[\"@tab\",\"@onDisplay\",\"@onCancel\"],\"statements\":[[5,\"channels/shop/rewards/rewards-editor\",[],[[\"@tab\",\"@model\",\"@errors\",\"@isEditing\",\"@isNew\",\"@isRunning\",\"@onSaveTask\",\"@onCancel\"],[[23,1,[]],[23,0,[\"model\"]],[23,0,[\"errors\"]],true,true,[23,0,[\"crud\",\"createRecordTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"crud\",\"createRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"component\",\"model\",\"onAfter\",\"onAfterOptions\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[]],[23,0,[\"model\"]],[23,2,[]],[28,\"hash\",null,[[\"tab\"],[[23,1,[]]]]],[23,1,[]]]]]],null],[23,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/rewards/panels-new/template.hbs"
    }
  });

  _exports.default = _default;
});