define("client/pods/flows/steps/actions/games/model", ["exports", "client/pods/base/model", "ramda", "client/utils/nventor"], function (_exports, _model, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    defaults() {
      return {
        actionType: 'games',
        unsuccessfulMessage: '',
        numberOfPlays: 1,
        prizes: []
      };
    },

    populate(data, attrs) {
      data.prizes = this._transformPrizes(data);

      this._super(data, attrs);
    },

    _transformPrizes(data) {
      return R.pipe(R.propOr([], 'prizes'), R.map(prizeData => {
        return this.dataManager.setAsRecord({
          adapterName: 'flows/steps/actions/games/prizes',
          data: prizeData
        });
      }))(data);
    },

    newPrize() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const prizeModel = this.addPrize();
      const prizeType = prizeModel?._data?.prizeType;

      if (prizeType === 'generatePromoCode') {
        const shopKey = '';
        const shop = '';
        const promoCodeModel = prizeModel.getPromoCodeModel({
          shopKey,
          shop
        });
        prizeModel.setData('defaults', promoCodeModel);
      }

      return prizeModel;
    },

    addPrize() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.subStepId = _nventor.default.random.alphaNum(17);
      const prizeModel = this.dataManager.setAsRecord({
        adapterName: 'flows/steps/actions/games/prizes',
        data
      });
      const prizes = this.getData('prizes') || [];
      prizes.pushObject(prizeModel);
      this.setData('prizes', prizes);
      return prizeModel;
    },

    removePrize(prizeModel) {
      const prizes = this.getData('prizes') || [];
      prizes.removeObject(prizeModel);
      return prizes;
    }

  }, (_applyDecoratedDescriptor(_obj, "newPrize", [_dec], Object.getOwnPropertyDescriptor(_obj, "newPrize"), _obj), _applyDecoratedDescriptor(_obj, "addPrize", [_dec2], Object.getOwnPropertyDescriptor(_obj, "addPrize"), _obj), _applyDecoratedDescriptor(_obj, "removePrize", [_dec3], Object.getOwnPropertyDescriptor(_obj, "removePrize"), _obj)), _obj)));

  _exports.default = _default;
});