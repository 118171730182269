define("client/pods/components/products/inventory/inventory-status-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H5zCSjq8",
    "block": "{\"symbols\":[\"isTooltipVisible\"],\"statements\":[[4,\"if\",[[24,[\"product\",\"hasInventory\"]]],null,{\"statements\":[[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"fetchProductTask\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n    \"],[5,\"helpers/info-tooltip\",[],[[\"@customBlock\"],[true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"showQty\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"products/inventory/inventory-status\",null,[[\"isTag\",\"product\",\"qtyParam\"],[true,[24,[\"product\"]],[24,[\"qtyParam\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"icon\"],[8],[0,\"\\n          \"],[1,[28,\"elements/element-icon\",null,[[\"icon\",\"class\"],[\"fas fa-info-circle\",[28,\"if\",[[24,[\"isEmpty\"]],\"has-text-danger\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \\n\"],[4,\"helpers/info-tooltip-content\",null,[[\"isViewable\",\"hasContent\"],[[23,1,[]],true]],{\"statements\":[[0,\"        \"],[1,[28,\"products/inventory/inventory-manager\",null,[[\"product\",\"isTable\",\"allowAdjustment\"],[[24,[\"product\"]],true,[24,[\"allowAdjustment\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/inventory/inventory-status-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});