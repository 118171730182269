define("client/pods/admin/payments/card/lists/filters/search/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,

    findAll() {
      const data = [{
        value: 'all',
        label: 'all'
      }, {
        value: 'contact',
        label: 'contact'
      }, {
        value: 'ref',
        label: 'reference'
      }, {
        value: 'email',
        label: 'email'
      }, {
        value: 'telephone',
        label: 'telephone'
      }, {
        value: 'address',
        label: 'address'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});