define("client/pods/components/channels/shop/promo-codes/promo-codes-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopPromoCodesPromoCodesEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class ChannelsShopPromoCodesPromoCodesEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "constants", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "search", _descriptor3, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor4, this);

      _initializerDefineProperty(this, "isSetupPreset", _descriptor5, this);

      _defineProperty(this, "disabled", false);

      _defineProperty(this, "translate", true);

      _defineProperty(this, "filtersAdapterName", 'channels/shop/campaigns/filters');

      this.crud.addLists(this, ['discounts/types', 'statuses', 'date/lists/unit-of-time']);
      const campaignFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.campaignFilters = campaignFilters;
    }

    get isReadonly() {
      if (this.args.isNew) {
        return false;
      }

      if (this.args.isEditing) {
        return false;
      }

      return true;
    }

    loadShopSource(model, shop) {
      model.setData('allowedShopKey', shop.getData('_key'));
    }

    unloadShopSource(model) {
      model.setData('allowedShopKey', '');
    }

    editPanel() {
      this.args.onEdit(this.args.model);
    }

    resetDate() {
      const model = this?.args?.model || {};

      if (RA.isNotNilOrEmpty(model)) {
        const useExpiryDate = R.pathOr(false, ['_data', 'useExpiryDate'])(model);

        if (useExpiryDate) {
          model.setData('dateStartZ', '');
          model.setData('dateEndZ', '');
        } else {
          model.setData('expiryDateStart', '');
          model.setData('expiryDateStartUnit', 'days');
          model.setData('expiryDateEnd', '');
          model.setData('expiryDateEndUnit', 'days');
        }
      }
    }

    get allowedProductTagLists() {
      const model = this?.args?.model || {};

      if (RA.isNilOrEmpty(model)) {
        return [];
      }

      const allProductTags = this.crud.lists['products-lists-tags'] || [];
      const deniedProductTags = this?._data?.denyProductTags || [];
      return R.reject(productTag => {
        const productTagKey = productTag?._data?._key || '';
        return R.includes(productTagKey, deniedProductTags);
      })(allProductTags);
    }

    get deniedProductTagLists() {
      const model = this?.args?.model || {};

      if (RA.isNilOrEmpty(model)) {
        return [];
      }

      const allProductTags = this.crud.lists['products-lists-tags'] || [];
      const allowedProductTags = this?._data?.productTags || [];
      return R.reject(productTag => {
        const productTagKey = productTag.getData('_key') || '';
        return R.includes(productTagKey, allowedProductTags);
      })(allProductTags);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isSetupPreset", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.isSetupPreset || false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadShopSource", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadShopSource", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "unloadShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editPanel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "editPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetDate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "resetDate"), _class.prototype)), _class));
  _exports.default = ChannelsShopPromoCodesPromoCodesEditorComponent;
});