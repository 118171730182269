define("client/pods/channels/shop/campaigns/lists/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  const filterTabs = (list, filterByLabel) => R.map(R.pipe(R.unless(R.propSatisfies(R.includes(R.__, filterByLabel), 'label'), R.set(R.lensProp('component'), null))))(list);

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let appendPath = arguments.length > 1 ? arguments[1] : undefined;
      const customData = {
        list: [{
          value: 'settings',
          label: 'settings',
          component: 'channels/shop/campaigns/campaigns-editor/settings-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/settings',
          tabType: 'reuseSubTab',
          errorsTracker: [{
            context: 'name',
            label: 'name'
          }, {
            context: 'shopUrl',
            label: 'shop url (at least 6 characters)'
          }, {
            context: 'password (at least 8 characters)',
            label: 'password'
          }, {
            context: 'shippingFee',
            label: 'shipping fee'
          }, {
            context: 'description',
            label: 'campaign_description'
          }, {
            context: 'dateStartZ',
            label: 'date start'
          }, {
            context: 'dateEndZ',
            label: 'date end'
          }, {
            context: 'autoEnd',
            label: 'automatically end campaign'
          }, {
            context: 'showCountdownTimer',
            label: 'show countdown timer'
          }, {
            context: 'showCampaignHeader',
            label: 'show campaign header'
          }, {
            context: 'allowPickup',
            label: 'allow pick up'
          }, {
            context: 'allowShipping',
            label: 'allow shipping'
          }, {
            context: 'transporterLabel',
            label: 'transporter'
          }, {
            context: 'transporterAccountNumber',
            label: 'transporter account'
          }, {
            context: 'shippingFeeType',
            label: 'shipping fee type'
          }, {
            context: 'shippingFee',
            label: 'shipping fee'
          }, {
            context: 'shippingFeeAlwaysCharge',
            label: 'always charge shipping fee'
          }, {
            context: 'shippingFeeCondition',
            label: 'free shipping for orders greater and equal to'
          }, {
            context: 'pickupLocations',
            label: 'pick up locations'
          }, {
            context: 'shopPaymentMethods',
            label: 'payment methods'
          }, {
            context: 'paymentMethodsDefault',
            label: 'default payment method'
          }, {
            context: 'cashPaymentMethodFee',
            label: 'cash payment fee'
          }, {
            context: 'bankingDetails',
            label: 'banking details'
          }, {
            context: 'autoPrintInvoices',
            label: 'auto print invoices'
          }, {
            context: 'commissionMethod',
            label: 'commission calculation'
          }, {
            context: 'commissionPercentage',
            label: 'commission % per item'
          }, {
            context: 'commissionFlat',
            label: 'commission amount per item'
          }, {
            context: 'companyName',
            label: 'company name'
          }, {
            context: 'companyTel',
            label: 'companyTel'
          }, {
            context: 'contactsTags',
            label: 'customer tags'
          }]
        }, {
          value: 'webpages',
          label: 'webpages',
          component: 'channels/shop/campaigns/campaigns-editor/pages-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/webpages',
          tabType: 'reuseSubTab',
          errorsTracker: [{
            context: 'template',
            label: 'home page'
          }, {
            context: 'expiredTemplate',
            label: 'message to display when campaign has ended'
          }]
        }, // {
        //   value: 'advancedTemplate',
        //   label: 'advancedTemplate',
        //   component: 'websites/websites-editor/pages-editor',
        //   resource: 'api/protected/websites/lists/menu/sub-panels/webpages',
        //   tabType: 'reuseSubTab',
        //   editingComponent: 'websites/websites-editor/pages-editor'
        // },
        // {
        //   value: 'products',
        //   label: 'products',
        //   component: 'channels/shop/campaigns/campaigns-editor/products-editor',
        //   tabType: 'reuseSubTab',
        //   errorsTracker: [
        //     {
        //       context: 'groupByTags',
        //       label: 'product grouping'
        //     },
        //     {
        //       context: 'details',
        //       label: 'products'
        //     }
        //   ]
        // },
        {
          value: 'emails',
          label: 'emails',
          component: 'channels/shop/campaigns/campaigns-editor/emails-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/emails',
          tabType: 'reuseSubTab',
          errorsTracker: [{
            context: 'emailFromName',
            label: 'from email name'
          }, {
            context: 'emailFromAddress',
            label: 'from email address'
          }, {
            context: 'emailSignature',
            label: 'email signature'
          }, {
            context: 'orderedEmailSubject',
            label: 'email subject'
          }, {
            context: 'orderedEmailMessage',
            label: 'email message'
          }, {
            context: 'orderedEmailCopyTo',
            label: 'send a copy of order email to addresses'
          }, {
            context: 'orderMessagesEmailCopyTo',
            label: 'send a copy of order messages to addresses'
          }, {
            context: 'dispatchedEmailSubject',
            label: 'email subject'
          }, {
            context: 'dispatchedEmailMessage',
            label: 'email message'
          }]
        }, {
          value: '3rd-party',
          label: '3rd-party',
          component: 'channels/shop/campaigns/campaigns-editor/3rd-party-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/3rd-party',
          tabType: 'reuseSubTab',
          errorsTracker: [{
            context: 'facebookUrl',
            label: 'facebook page url'
          }, {
            context: 'instagramUrl',
            label: 'instagram url'
          }, {
            context: 'youtubeUrl',
            label: 'youtube channel url'
          }, {
            context: 'lineId',
            label: 'line id'
          }]
        }, {
          value: 'reward',
          label: 'reward',
          component: 'channels/shop/campaigns/campaigns-editor/reward-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/reward',
          tabType: 'reuseSubTab',
          errorsTracker: [{
            context: 'rewardName',
            label: 'reward name'
          }]
        }, {
          value: 'commissions',
          label: 'commissions',
          component: 'channels/shop/campaigns/campaigns-editor/commissions-editor',
          tabType: 'reuseSubTab',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/commissions',
          errorsTracker: [{
            context: 'commissions',
            label: 'commissions'
          }]
        }, {
          value: 'bulk discounts',
          label: 'bulk discounts',
          component: 'channels/shop/campaigns/campaigns-editor/bulk-discounts-editor',
          resource: 'api/protected/channels/shop/campaigns/lists/menu/sub-panels/bulk-discounts',
          tabType: 'reuseSubTab',
          errorsTracker: [{
            context: 'name',
            label: 'name'
          }]
        }]
      };
      customData.list = filterTabs(customData.list, ['settings', 'webpages', 'emails', '3rd-party', 'reward', 'commissions', 'bulk discounts']);
      const path = this.getFullPath('findAllPath', appendPath);
      return this.ajax.POST(path, customData).then(res => res?.data || []);
    }

  });

  _exports.default = _default;
});