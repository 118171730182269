define("client/pods/components/websites/panels-display/sub-panels-pages/nav-menu-nested/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['nav-tree'],
    translate: true,
    isViewable: true,
    disabled: false,
    labelKey: 'label',
    isDraggable: true,
    subProperty: 'sub',

    init() {
      this._super(...arguments);

      this.set('itemClassNames', []);
    },

    actions: {
      selectOrToggleExpand(component, item) {
        for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
          args[_key - 2] = arguments[_key];
        }

        return this.onSelect(item, ...args); // const itemHasContentKey = this.get('itemHasContentKey')
        // if (itemHasContentKey) {
        //   if (item.get(itemHasContentKey)) {
        //     return this.get('onSelect')(item)
        //   }
        // }
        // component.toggleProperty('isExpanded')
      },

      toggleExpand(component) {
        component.toggleProperty('isExpanded');
      },

      drop() {
        if (this.onDrop) {
          this.onDrop(...arguments);
        }
      }

    }
  });

  _exports.default = _default;
});