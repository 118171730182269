define("client/pods/components/documents/pending/panel-display-by-products/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iNNbq8Rl",
    "block": "{\"symbols\":[\"@docName\",\"@docType\",\"@filters\",\"@tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"layouts/table-view\",null,[[\"tab\",\"isRunning\",\"detailsRowComponent\",\"paginationIsFooter\",\"results\",\"filters\",\"onSearchTask\",\"onReloadSearchRecordsTask\",\"onPaginationTask\",\"tableViewOptions\"],[[23,4,[]],[23,0,[\"crud\",\"searchRecordsTask\",\"isRunning\"]],[23,0,[\"tableViewComponent\"]],false,[23,3,[\"results\"]],[23,3,[]],[23,0,[\"crud\",\"searchAdvancedTask\"]],[23,0,[\"crud\",\"reloadSearchRecordsTask\"]],[23,0,[\"crud\",\"paginateSearchRecordsTask\"]],[28,\"hash\",null,[[\"docType\",\"docName\"],[[23,2,[]],[23,1,[]]]]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/pending/panel-display-by-products/template.hbs"
    }
  });

  _exports.default = _default;
});