define("client/pods/components/lists/list-dropdown-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j+u6RiPX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"hasModalOverlay\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"modals/modal-overlay\",null,[[\"toggle\",\"onToggle\"],[[24,[\"toggle\"]],[24,[\"onToggle\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"list-dropdown-container__outer-wrapper\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"list-dropdown-container__wrapper\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"list-dropdown-container__content \",[22,\"dropdownContentClassNames\"]]]],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/lists/list-dropdown-container/template.hbs"
    }
  });

  _exports.default = _default;
});