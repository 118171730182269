define("client/pods/components/export/worksheet/editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExportWorksheetEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class ExportWorksheetEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dataManager", _descriptor2, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);

      _initializerDefineProperty(this, "specsCollection", _descriptor4, this);

      _initializerDefineProperty(this, "listAlreadyUsedInDropdown", _descriptor5, this);

      this.fetchSpecsTask.perform();
      this.listAlreadyUsedInDropdown = this?.args?.model?._data?.defaultExportCustomSheets || [];
      const model = this?.args?.model || {};
      const showSetDetails = model.getData('showSetDetails');

      if (RA.isNilOrEmpty(showSetDetails)) {
        model.setData('showSetDetails', true);
      }

      const isGroupDocs = model.getData('isGroupDocs');
      const source = this?.args?.source || 'dispatch';

      if (source === 'reports') {
        model.setData('isGroupDocs', false);
      }

      if (RA.isNilOrEmpty(isGroupDocs) && source === 'dispatch') {
        model.setData('isGroupDocs', true);
      }
    }

    get availableCustomWorksheets() {
      const originalList = this?.specsCollection?._data?.custom || [];
      const listAlreadyUsedInDropdown = this.listAlreadyUsedInDropdown || [];
      return R.filter(list => {
        const listKey = list._data._key;
        const listKeyAlreadyUsedInDropdown = R.pipe(R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.pluck('value'), R.reject(RA.isNilOrEmpty))(listAlreadyUsedInDropdown);
        return !R.includes(listKey, listKeyAlreadyUsedInDropdown);
      })(originalList);
    }

    addDefaultExportCustomSheets(selectedSpec) {
      const model = this.args.model;
      const defaultCustomSheets = model.getData('defaultExportCustomSheets') || [];
      const data = {
        value: selectedSpec.getData('_key'),
        label: selectedSpec.getData('description'),
        index: defaultCustomSheets.length
      };
      const modelData = this.dataManager.setAsRecord({
        adapterName: 'dispatch/lists/processes/custom-worksheets',
        data
      });
      defaultCustomSheets.pushObject(modelData);
      model.setData('defaultExportCustomSheets', defaultCustomSheets);
      this.listAlreadyUsedInDropdown = model?._data?.defaultExportCustomSheets || [];
    }

    removeListDropdown(customSheet) {
      const model = this.args.model;
      const defaultExportCustomSheets = model.getData('defaultExportCustomSheets');
      defaultExportCustomSheets.removeObject(customSheet);
      RA.mapIndexed((val, idx) => {
        defaultExportCustomSheets.set(`${idx}.index`, idx);
      })(defaultExportCustomSheets);
      model.setData('defaultExportCustomSheets', defaultExportCustomSheets);
    }

    *fetchSpecsTask() {
      const specsCollection = yield this.crud.findTask.perform({
        adapterName: 'specs/collection'
      });
      this.specsCollection = specsCollection;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "specsCollection", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "listAlreadyUsedInDropdown", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addDefaultExportCustomSheets", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "addDefaultExportCustomSheets"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeListDropdown", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "removeListDropdown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchSpecsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchSpecsTask"), _class.prototype)), _class));
  _exports.default = ExportWorksheetEditorComponent;
});