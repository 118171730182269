define("client/pods/components/blogs/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LtJ3tn+3",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"blogs/blogs-editor\",null,[[\"model\",\"errors\",\"saveTask\",\"onEdit\",\"isNew\",\"isEditing\",\"isReadonly\",\"isDisabled\",\"tab\",\"isDuplicate\",\"onDash\",\"onCancel\"],[[24,[\"model\"]],[24,[\"errors\"]],[24,[\"updateRecordTask\"]],[24,[\"onEdit\"]],false,false,true,true,[24,[\"tab\"]],[24,[\"isDuplicate\"]],[24,[\"onDash\"]],[24,[\"onCancel\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/blogs/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});