define("client/pods/components/admin/payments/card/panels-payments/panels-statements/statement-row/component", ["exports", "client/mixins/big", "client/mixins/crud", "client/mixins/date", "ember-concurrency"], function (_exports, _big, _crud, _date, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_big.default, _crud.default, _date.default, {
    tagName: 'tr',
    adapterName: 'admin/payments/card',
    filtersAdapterName: 'admin/payments/card/filters',

    init() {
      this._super(...arguments); // const roundingCurrency = settingsModel.getData('roundingCurrency')


      this.set('roundingCurrency', 0);
      const period = this.period;
      const statement = this.statement;

      if (statement) {
        const filters = this.search.setupFilters({
          adapterName: this.filtersAdapterName,
          defaultFiltersData: {
            podKey: statement.podKey,
            dateStart: period.dateStart,
            dateEnd: period.dateEnd
          }
        });
        this.set('filters', filters);
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      if (!this.isHeader) {
        // fetch payments
        const period = this.period;
        const statement = this.statement;
        this.fetchPaymentsTask.perform(period, statement);
      }
    },

    fetchPaymentsTask: (0, _emberConcurrency.task)(function* (period, statement) {// const payload = period
      // payload.podKey = statement.podKey
      // const response = yield this.server.call('GET', 'api/protected/admin/payments/card/payouts', payload)
      // let results = response.data || []
      // results = R.map(result => EmberObject.create(result), results)
      // this.set('payouts', results)
    }),
    markAsPaidOutTask: (0, _emberConcurrency.task)(function* (statement, payoutAmount) {
      // @TODO: Darwin, is this still used?
      const period = this.period;
      const ref = period.dateStart;
      const dateZ = this.getNowZ();
      const payload = {
        podKey: statement.podKey,
        accountKey: statement.podKey,
        account: statement.company,
        amount: payoutAmount,
        ref: ref,
        dateZ: dateZ
      };
      yield this.server.call('POST', 'api/protected/admin/payments/card/payout', payload);
      yield this.fetchPaymentsTask.perform(period, statement);
    }),
    payoutsTotal: Ember.computed('payouts.[]', function () {
      const payouts = this.payouts;
      const roundingCurrency = this.roundingCurrency;
      return this.sumPropAndAutoRound(roundingCurrency, 'amount', payouts);
    }),
    outstandingTotal: Ember.computed('statement.totalDue', 'payoutsTotal', function () {
      const payoutsTotal = this.payoutsTotal;
      const totalDueBig = this.newBig(this.get('statement.totalDue'));
      const roundingCurrency = this.roundingCurrency;
      return totalDueBig.minus(payoutsTotal).toFixed(roundingCurrency);
    }),
    hasOutstanding: Ember.computed('outstandingTotal', function () {
      const outstandingTotal = this.newBig(this.outstandingTotal);
      return outstandingTotal.gt(0);
    })
  });

  _exports.default = _default;
});