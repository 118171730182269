define("client/pods/channels/shop/campaigns/bulk-discounts/schedules/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        scheduleId: '',
        dateStartZ: '',
        dateEndZ: '',
        rules: []
      };
    },

    populate(data, attrs) {
      data.rules = this._transformRules(data);

      this._super(data, attrs);
    },

    isActive: Ember.computed('_data.{dateStartZ,dateEndZ}', function () {
      const todayZ = this.getStartOfDayZ();
      const dateStartZ = this.getData('dateStartZ');
      const dateEndZ = this.getData('dateEndZ');

      if (dateStartZ && !dateEndZ && dateStartZ <= todayZ) {
        return true;
      }

      if (dateEndZ && !dateStartZ && dateEndZ >= todayZ) {
        return true;
      }

      if (dateStartZ <= todayZ && dateEndZ >= todayZ) {
        return true;
      }

      return false;
    }),
    isActiveNext: Ember.computed('_data.{dateStartZ,dateEndZ}', function () {
      const todayZ = this.getStartOfDayZ();
      const dateStartZ = this.getData('dateStartZ');

      if (dateStartZ > todayZ) {
        return true;
      }

      return false;
    }),

    _transformRules(data) {
      const rules = R.propOr([], 'rules')(data);
      return R.map(rule => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/bulk-discounts/rules',
          data: rule
        });
      })(rules);
    },

    addRule() {
      let rule = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const rules = this.getData('rules') || [];
      const ruleModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/bulk-discounts/rules',
        data: rule
      });
      rules.pushObject(ruleModel);
      this.setData('rules', rules);
    }

  });

  _exports.default = _default;
});