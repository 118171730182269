define("client/pods/components/emails/campaigns/panels-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      if (!this.model) {
        const name = this.name || '';
        const data = {
          name,
          status: this.get('constants.status.active')
        };
        const model = this.crud.setupNewRecord({
          adapterName: 'emails/campaigns',
          data
        });
        this.set('model', model);
      }
    }

  });

  _exports.default = _default;
});