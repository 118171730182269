define("client/pods/components/elements/element-checkbox/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // import TranslateMixin from '../../../../mixins/translate'
  // import jQuery from 'jquery'
  let ElementsElementCheckboxComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class ElementsElementCheckboxComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "_isSelected", _descriptor2, this);
    }

    get translate() {
      return R.pathOr(true, ['args', 'translate'])(this);
    }

    get allowToggle() {
      return R.pathOr(true, ['args', 'allowToggle'])(this);
    }

    get isSelected() {
      return R.pathOr(this._isSelected, ['args', 'value'])(this);
    } // didInsertElement () {
    //   this._super(...arguments)
    //   const initialWidth = jQuery(this.element).outerWidth()
    //   const spans = jQuery(this.element).children('span')
    //   let totalWidth = 0
    //   spans.each((key, element) => {
    //     element = jQuery(element)
    //     totalWidth = totalWidth + element.outerWidth()
    //   })
    //   totalWidth = Math.ceil(totalWidth + 10)
    //   if (initialWidth < totalWidth) {
    //     jQuery(this.element).outerWidth(totalWidth)
    //   }
    // },


    toggle() {
      if (!this.args.disabled && this.allowToggle) {
        const customToggler = this.args.onToggle;
        const isSelected = !this.isSelected;
        this._isSelected = isSelected;

        if (this.args.onToggleProperty) {
          this.args.onToggleProperty(isSelected);
        }

        if (customToggler) {
          return customToggler(isSelected);
        }

        const onAfterToggle = this.args.onAfterToggle;

        if (onAfterToggle) {
          return onAfterToggle(isSelected);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_isSelected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype)), _class));
  _exports.default = ElementsElementCheckboxComponent;
});