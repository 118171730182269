define("client/pods/components/guests/campaign-main/campaign-products/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XcSDOV4O",
    "block": "{\"symbols\":[],\"statements\":[[4,\"layouts/section-row\",null,null,{\"statements\":[[4,\"if\",[[24,[\"campaign\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"channels/shop/campaigns/campaigns-editor/products-editor\",null,[[\"tab\",\"adapterName\",\"model\",\"filters\",\"isGuest\",\"isReadonly\",\"fetchCampaignDetailsTask\"],[[24,[\"tab\"]],\"guests/shop/campaigns/details\",[24,[\"campaign\"]],[24,[\"filters\"]],true,true,[24,[\"fetchCampaignDetailsTask\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/guests/campaign-main/campaign-products/template.hbs"
    }
  });

  _exports.default = _default;
});