define("client/pods/components/lists/list-dropdown-editor-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    keyDown(e) {
      const keyCode = e.keyCode || e.which;

      if (keyCode === 9) {
        e.stopPropagation();
      }
    }

  });

  _exports.default = _default;
});