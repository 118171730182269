define("client/pods/components/extensions/apps/tw/e-invoices/print-all-btn/component", ["exports", "client/pods/components/extensions/apps/tw/e-invoices/mixins/invoices-printer", "client/mixins/date", "ember-concurrency", "ramda"], function (_exports, _invoicesPrinter, _date, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_invoicesPrinter.default, _date.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    eInvoicesService: Ember.inject.service('extensions/apps/tw/e-invoices'),
    intl: Ember.inject.service(),
    server: Ember.inject.service(),
    printer: Ember.inject.service(),
    date: Ember.inject.service(),
    tagName: '',
    isReadonly: false,
    adapterName: 'extensions/apps/tw/e-invoices/to-issue',
    filtersAdapterName: 'extensions/apps/tw/e-invoices/filters',
    includeBeforeStartDate: true,
    isSubmitted: false,
    isOnlyRemaining: false,
    isCustomDateRange: false,
    selectedDateOption: '1_days',

    init() {
      this.crud.addLists(this, ['paymentMethods', 'periods/date-options']);

      this._super(...arguments);

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          count: 20
        }
      });
      this.set('filters', filters); // const shopFilters = this.search.setupFilters({
      //   adapterName: 'channels/shop/campaigns/filters',
      //   defaultFiltersData: {
      //     query: ''
      //   }
      // })
      // this.set('shopFilters', shopFilters)

      const customDateStartZ = this.date.getStartOfDayZ();
      const customDateEndZ = this.date.getStartOfDayZ();
      this.set('customDateStartZ', customDateStartZ);
      this.set('customDateEndZ', customDateEndZ);
    },

    afterLoadLists() {
      const period = this?.period || {};
      this.set('mm', period?.mm);
      this.set('yyyy', period?.yyyy);
    },

    printerButtonIsRunning: Ember.computed('eInvoicesService.{issueAllEInvoicesForPeriodTask,fetchToIssueEInvoicesTask}.isRunning', 'this.printer.fetchOnlinePrintersTask.isRunning', function () {
      return this.eInvoicesService.issueAllEInvoicesForPeriodTask.isRunning || this.eInvoicesService.fetchToIssueEInvoicesTask.isRunning || this.printer.fetchOnlinePrintersTask.isRunning;
    }),
    setupTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let onlyPrintableEInvoices = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return function* () {
        _this.set('isOnlyRemaining', false);

        yield _this.printer.fetchOnlinePrintersTask.perform();

        if (onlyPrintableEInvoices) {
          _this.filters.setData('twEInvoicePrint', true);
        } else {
          _this.filters.setData('twEInvoicePrint', false);
        }

        yield _this.eInvoicesService.fetchToIssueEInvoicesTask.perform({
          component: _this,
          filters: _this.filters,
          period: _this.selectedPeriod,
          includeBeforeStartDate: _this.includeBeforeStartDate,
          dispatchProcess: _this.dispatchProcess
        });
      }();
    }),
    setupRemainingOnlyTask: (0, _emberConcurrency.task)(function () {
      var _this2 = this;

      let onlyPrintableEInvoices = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return function* () {
        _this2.set('isOnlyRemaining', true);

        yield _this2.printer.fetchOnlinePrintersTask.perform();

        if (onlyPrintableEInvoices) {
          _this2.filters.setData('twEInvoicePrint', true);
        } else {
          _this2.filters.setData('twEInvoicePrint', false);
        }

        yield _this2.eInvoicesService.fetchToIssueEInvoicesTask.perform({
          component: _this2,
          filters: _this2.filters,
          period: _this2.selectedPeriod,
          includeBeforeStartDate: _this2.includeBeforeStartDate,
          dispatchProcess: _this2.dispatchProcess,
          onlyRemaining: true
        });
      }();
    }),
    selectedPeriod: Ember.computed('mm', 'yyyy', 'customDateStartZ', 'customDateEndZ', 'includeBeforeStartDate', 'isCustomDateRange', 'selectedDateOption', 'period', function () {
      const includeBeforeStartDate = this?.includeBeforeStartDate;

      if (includeBeforeStartDate) {
        return this.period;
      }

      const customDateStartZ = this.customDateStartZ;
      const customDateEndZ = this.customDateEndZ;
      const period = this.date.getPeriod(customDateStartZ);
      period.monthStart = this.date.formatDate(customDateStartZ);
      period.monthStartZ = customDateStartZ;
      period.monthEnd = this.date.formatDate(customDateEndZ);
      period.monthEndZ = customDateEndZ;
      return period;
    }),

    _getDateRange(selectedDateOption) {
      let dateZ = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      const todayZ = dateZ || this.date.getStartOfDayZ();
      let customDateStartZ = todayZ;
      let customDateEndZ = todayZ;

      if (selectedDateOption) {
        const selectedDateOptionData = this._getSelectedOptionsData(selectedDateOption);

        const timePeriod = selectedDateOptionData.period;
        customDateStartZ = this.date.getMoment(customDateStartZ).toISOString();

        if (timePeriod === 'months') {
          const time = selectedDateOptionData.number;
          const startPeriod = this.date.getPeriod(customDateStartZ);
          customDateStartZ = startPeriod.monthStartZ;
          let futurePeriod = time - 1; // minus 1 because current month is also calculated  

          customDateEndZ = this.date.getMoment(customDateStartZ).add(futurePeriod, timePeriod).endOf('month').toISOString();
        }
      }

      return {
        customDateStartZ,
        customDateEndZ
      };
    },

    _getSelectedOptionsData(selectedDateOption) {
      const selectedDateOptionData = R.split('_', selectedDateOption);
      return {
        number: Number(selectedDateOptionData[0]) || 0,
        period: selectedDateOptionData[1]
      };
    },

    setDateType() {
      let dateZ = this.date.getStartOfDayZ();
      const selectedDateOption = this.selectedDateOption;
      const currentPeriod = this.date.getPeriod();

      const selectedDateOptionData = this._getSelectedOptionsData(selectedDateOption);

      if (selectedDateOptionData.number > 0 && selectedDateOptionData.period === 'months') {
        let cutOffMM = Number(currentPeriod.mm) - selectedDateOptionData.number + 1;

        if (this.yyyy === currentPeriod.yyyy && this.mm > cutOffMM) {
          Ember.set(this, 'mm', cutOffMM);
        }

        dateZ = `${this.yyyy}-${this.date.getMM(this.mm)}-01`;
      }

      const {
        customDateStartZ,
        customDateEndZ
      } = this._getDateRange(selectedDateOption, dateZ);

      this.set('customDateStartZ', customDateStartZ);
      this.set('customDateEndZ', customDateEndZ);
    },

    setMonth() {
      const mm = this.date.getMM(this.mm);
      const dateZ = `${this.yyyy}-${mm}-01`;

      const {
        customDateStartZ,
        customDateEndZ
      } = this._getDateRange(this.selectedDateOption, dateZ);

      this.set('customDateStartZ', customDateStartZ);
      this.set('customDateEndZ', customDateEndZ);
    },

    setDay(dateZ) {
      const {
        customDateStartZ,
        customDateEndZ
      } = this._getDateRange(this.selectedDateOption, dateZ);

      this.set('customDateStartZ', customDateStartZ);
      this.set('customDateEndZ', customDateEndZ);
    },

    printToggleBtnLabel: Ember.computed('period.dateEnd', function () {
      // const { customDateStartZ } = this._getDateRange('3_months', this.period.date)
      // const dateStart = this.formatDate(customDateStartZ)
      const dateEndDefault = this?.period?.date || this.date.getEndOfDay();
      const dateEnd = this.formatDate(dateEndDefault);
      const dateStart = this.formatDate(this.date.getMoment(dateEnd).subtract(3, 'months').startOf('month').toISOString());
      return `${this.intl.t('tw_eInvoives_include_until_current_date')}: ${dateStart} ~ ${dateEnd}`;
    }),
    actions: {
      toggleSubmitted() {
        this.set('isSubmitted', true);
      } // loadShopSource (shop) {
      //   const filters = this.filters
      //   filters.setData('shopKey', shop.getData('_key'))
      // },
      // unloadShopSource () {
      //   const filters = this.filters
      //   filters.setData('shopKey', (''))
      // }


    }
  }, (_applyDecoratedDescriptor(_obj, "setDateType", [_dec], Object.getOwnPropertyDescriptor(_obj, "setDateType"), _obj), _applyDecoratedDescriptor(_obj, "setMonth", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setMonth"), _obj), _applyDecoratedDescriptor(_obj, "setDay", [_dec3], Object.getOwnPropertyDescriptor(_obj, "setDay"), _obj)), _obj)));

  _exports.default = _default;
});