define("client/pods/components/payments/virtual-accounts/tab-container/component", ["exports", "client/mixins/payments-list-by", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _paymentsListBy, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, _paymentsListBy.default, {
    crud: Ember.inject.service(),
    settings: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    // listAdapters: [],
    // results: [],
    adapterName: 'payments/virtualAccounts',
    filtersAdapterName: 'payments/virtualAccounts/filters',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['periods/months', 'periods/years', 'dispatch/lists/processes', 'payments/virtualAccounts/lists/providers']);
      const nowZ = this.dateService.getMoment();
      const monthNow = nowZ.month() + 1;
      const yearNow = nowZ.year();
      this.set('mm', monthNow);
      this.set('yyyy', yearNow);
      const provider = this.settings.getDefaultVirtualAccountProvider();
      this.set('selectedProvider', provider);
      const period = this.getPeriod();
      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          provider: provider
        }
      });
      this.set('filters', filters);
      this.displayByMonth(period);
    },

    displayByMonth(period) {
      const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
      this.set('period', period);
      this.display({
        dateStart,
        dateEnd
      });
      this.set('mm', period.mm);
      this.set('yyyy', period.yyyy);
    },

    display(period) {
      this.set('afterSearchQuery', '');
      const tab = this.tab;
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      filters.setData('provider', this.selectedProvider);
      this.set('filters', filters);
      const exportFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      exportFilters.setData('provider', this.selectedProvider);
      this.set('exportFilters', exportFilters);
      const subTabOptions = {
        component: 'payments/virtual-accounts/panels-display',
        period
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    refreshTask: (0, _emberConcurrency.task)(function* () {
      const period = this.period;
      this.displayByMonth(period);
    }),
    actions: {
      // dash () {
      //   this.set('resultsToggle', false)
      //   this.dash()
      // },
      display(model) {
        this.display(model);
      },

      displayByMonth(period) {
        this.displayByMonth(period);
      },

      displayByCustomMonth(mm, yyyy) {
        this.displayByMonth({
          mm,
          yyyy
        });
      },

      setSearchQuery() {
        this.set('afterSearchQuery', this.filters?._data?.query);
      }

    }
  });

  _exports.default = _default;
});