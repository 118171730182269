define("client/pods/components/extensions/apps/tw/invoices/panels-display/sub/editor-item/component", ["exports", "client/pods/components/extensions/apps/tw/invoices/mixins/invoices-cancel"], function (_exports, _invoicesCancel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_invoicesCancel.default, {
    tagName: ''
  });

  _exports.default = _default;
});