define("client/pods/workflows/items/activities/collection/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/workflows/items/activities/collection/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/workflows/items/activities/collection',
    modelObj: _model.default,
    idParam: 'workflowItemKey',
    idPrefix: 'activitiesCollection'
  });

  _exports.default = _default;
});