define("client/pods/components/admin/payments/card/panels-payments/panels-statements/component", ["exports", "client/mixins/crud", "client/mixins/date", "ember-concurrency"], function (_exports, _crud, _date, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, {
    search: Ember.inject.service(),
    crud: Ember.inject.service(),
    adapterName: 'admin/payments/card',
    filtersAdapterName: 'admin/payments/card/filters',

    didInsertElement() {
      // fetch payments
      this._super(...arguments);

      const period = this.get('tab.period');
      this.set('period', period);
      const statementFilters = this.search.setupFilters({
        adapterName: 'admin/payments/card/statements/filters'
      });
      this.set('statementFilters', statementFilters);
      this.fetchStatementsTask.perform();
    },

    fetchStatementsTask: (0, _emberConcurrency.task)(function* () {
      const appendPath = this.selectedProvider;
      const period = this.period;
      const statementFilters = this.statementFilters;
      statementFilters.setData('dateStart', period.dateStart);
      statementFilters.setData('dateEnd', period.dateEnd);
      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: 'admin/payments/card/statements',
        appendPath: `/${appendPath}`,
        filters: statementFilters
      });
      this.set('statements', results);
    }),
    fetchStatementDetailsTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        statement,
        filters
      } = _ref;
      return function* () {
        filters.setData('provider', _this.selectedProvider);
        const podKey = statement.getData('podKey');
        const results = yield _this.crud.searchRecordsTask.perform({
          adapterName: 'admin/payments/card/statements/details',
          appendPath: `/${podKey}`,
          filters,
          resultsProperty: ''
        });
        statement.set('details', results);
      }();
    }),
    paginateStatementDetailsTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        context,
        filters
      } = _ref2;
      return function* () {
        yield _this2.fetchStatementDetailsTask.perform({
          statement: context,
          filters
        });
      }();
    })
  });

  _exports.default = _default;
});