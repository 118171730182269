define("client/pods/components/extensions/sub/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pTR6QJBX",
    "block": "{\"symbols\":[\"order\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"savedCount\"]]],null,{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\"],[1,[28,\"tt\",[\"saved\"],null],false],[0,\": \"],[1,[22,\"savedCount\"],false],[0,\"\\n\\t\\t\\t\"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"_data\",\"orders\"]]],null,{\"statements\":[[0,\"\\n\\t\\t\"],[1,[28,\"extensions/sub/panels-display/display-item\",null,[[\"extName\",\"model\",\"orders\"],[[24,[\"extName\"]],[23,1,[]],[24,[\"model\",\"_data\",\"orders\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[0,\"\\n\"],[4,\"unless\",[[24,[\"isFetching\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"unless\",[[24,[\"isSaving\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"isReady\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[1,[28,\"elements/element-btn\",null,[[\"classNames\",\"label\",\"disabled\",\"onSubmit\"],[\"is-primary\",\"save\",[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"submit\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"onSubmit\"],[\"select all\",[28,\"action\",[[23,0,[]],\"selectAll\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\\t\"],[1,[28,\"tt\",[\"selected orders\"],null],false],[0,\": \"],[1,[22,\"selectedCount\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/sub/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});