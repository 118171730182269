define("client/pods/components/facebook/facebook-login/component", ["exports", "ember-concurrency", "ramda", "client/config/environment"], function (_exports, _emberConcurrency, R, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /* Bind Functions */
    didInsertElement() {
      this._super(...arguments); // first check if already linked
      // if not then fetch fb login state


      this.setupFb.perform();
    },

    hasPagesList: Ember.computed('model._data.facebookPages', function () {
      const facebookPages = this.get('model._data.facebookPages') || [];
      return facebookPages.length >= 1;
    }),
    loggedIn: false,
    isLoggedIn: Ember.computed('loggedIn', function () {
      return this.loggedIn; // const model = this.get('model')
      // return model.getData('facebookAccessToken')
    }),
    setupFb: (0, _emberConcurrency.task)(function* () {
      // yield go and fetch
      const locale = this.intl.get('locale')[0] || 'en_US';
      const fbSdkUrl = `https://connect.facebook.net/${locale}/sdk.js`;

      window.fbAsyncInit = () => {
        FB.init({
          appId: _environment.default.facebookSettings.appId,
          cookie: true,
          // enable cookies to allow the server to access the session
          xfbml: true,
          // parse social plugins on this page
          version: _environment.default.facebookSettings.sdkVersion // The Graph API version to use for the call

        });
      }; // Load the SDK asynchronously


      yield ((d, s, id) => {
        const fjs = d.getElementsByTagName(s)[0];

        if (d.getElementById(id)) {
          return;
        }

        const js = d.createElement(s);
        js.id = id;
        js.src = fbSdkUrl;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }).drop(),
    loginTask: (0, _emberConcurrency.task)(function* () {
      yield FB.login(resp => {
        this.getFbPageListTask.perform(resp);
        this.set('loggedIn', true);
      }, {
        scope: 'pages_messaging,manage_pages'
      });
    }).drop(),
    getFbPageListTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        authResponse
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const accessToken = R.prop('accessToken', authResponse) || '';

        if (accessToken) {
          const {
            data: pagesList
          } = yield _this.server.callOuter('GET', 'https://graph.facebook.com/v5.0/me/accounts', {
            access_token: accessToken
          });
          const settings = _this.model;
          settings.createFacebookPages(pagesList);
          settings.setData('facebookAccessToken', accessToken);
          let settingsData = settings.get('_data');
          settingsData = R.omit(['facebookPages'])(settingsData);
          settingsData.facebookPages = R.map(R.pick(['id', 'name']))(pagesList);
          yield _this.server.call('PATCH', 'api/protected/settings/facebook/profile', settingsData);
        }
      }();
    }).drop()
  });

  _exports.default = _default;
});