define("client/pods/components/channels/shop/rewards/panels-display/sub-panels-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OTnMPFvi",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"channels/shop/rewards/rewards-editor/details-editor\",[],[[\"@model\",\"@isReadonly\"],[[23,1,[]],true]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/rewards/panels-display/sub-panels-details/template.hbs"
    }
  });

  _exports.default = _default;
});