define("client/pods/components/barcode/print-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m2TlGkZe",
    "block": "{\"symbols\":[\"onCloseDialogAction\"],\"statements\":[[5,\"elements/element-btn-with-modal-dialog\",[],[[\"@disabled\",\"@badge\",\"@icon\",\"@modalDialogTitle\",\"@title\",\"@translate\",\"@closeOnOverlayClick\"],[[28,\"if\",[[23,0,[\"badgeCount\"]],false,true],null],[23,0,[\"badgeCount\"]],\"fas fa-barcode\",\"barcode\",\"barcode\",false,false]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n\\n    \"],[7,\"iframe\",false],[12,\"id\",[29,[[23,0,[\"iframeId\"]]]]],[12,\"class\",\"u-height-100vh\"],[3,\"did-insert\",[[23,0,[\"setContent\"]]]],[8],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/footer-btns\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"layouts/btns-group\",[],[[\"@classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\n        \"],[5,\"elements/btn-cancel\",[],[[\"@isRunning\",\"@onSubmit\"],[[23,0,[\"saveTask\",\"isRunning\"]],[23,1,[]]]]],[0,\"\\n        \"],[5,\"elements/element-btn\",[],[[\"@icon\",\"@classNames\",\"@onSubmit\"],[\"fas fa-print\",\"is-primary\",[23,0,[\"printIFrameContent\"]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/barcode/print-modal/template.hbs"
    }
  });

  _exports.default = _default;
});