define("client/pods/components/schedules/btn-scheduled/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cGYO05Wa",
    "block": "{\"symbols\":[\"onHideDropdown\",\"task\",\"@model\"],\"statements\":[[4,\"if\",[[23,0,[\"results\"]]],null,{\"statements\":[[4,\"lists/list-btn\",null,[[\"tooltipLabel\",\"hasBadge\",\"badge\",\"isRunning\",\"onSubmit\",\"icon\"],[\"scheduled_tasks\",[23,0,[\"pending\"]],[28,\"count\",[[23,0,[\"pending\"]]],null],[23,0,[\"fetchScheduledTasksByResourceTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"fetchScheduledTasksByResourceTask\"]]],null],\"far fa-clock\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,0,[\"results\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"component\",[[28,\"concat\",[\"schedules/\",[23,2,[\"_data\",\"subType\"]]],null]],[[\"task\",\"doc\"],[[23,2,[]],[23,3,[]]]]],false],[0,\"\\n\"],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/schedules/btn-scheduled/template.hbs"
    }
  });

  _exports.default = _default;
});