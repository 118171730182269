define("client/pods/components/barcode/print-modal/component", ["exports", "@glimmer/component", "jsbarcode", "ramda", "ramda-adjunct"], function (_exports, _component, _jsbarcode, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BarcodePrintModalComponent = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class BarcodePrintModalComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "iframeId", `iframe-${Ember.guidFor(this)}`);
    }

    get iframeElement() {
      return document.querySelector(`#${this.iframeId}`);
    }

    get badgeCount() {
      const models = RA.ensureArray(this.args.model) || [];
      const modelsWithBarcode = R.pipe(R.filter(R.allPass([R.pipe(R.pathOr('', ['_data', 'barcode']), RA.isNotNilOrEmpty), R.pipe(R.pathOr('', ['_data', 'barcodeType']), RA.isNotNilOrEmpty)])), R.length)(models);
      return modelsWithBarcode || '';
    }

    setContent(iframeElement) {
      const barcodeHtml = this.generateBarcodeHtml();
      iframeElement = iframeElement || this.iframeElement;

      const html = this._getHtmlDefaults(barcodeHtml);

      iframeElement.src = this._parseHtmlAsSrc(html);
      iframeElement.srcdoc = html;
    }

    _getHtmlDefaults(content) {
      return `<html>
<head>
  <style>
    html, body {
      margin: 0;
      page-break-after: avoid;
      page-break-before: avoid;
    }

    .p-label {
      margin: 0;
      padding-top: 1pt;
      font-size: 8pt;
      text-align: center;
    }

    .svg-barcode {
      padding: 0;
      margin: 0;
      width: 90vw;
      height: auto;
    }

    .barcode-wrapper {
      page-break-after: always;
    }

    /*@media print {*/
    /*  .p-label {*/
    /*    margin: 0;*/
    /*    padding: 0.5pt;*/
    /*    font-size: 8pt;*/
    /*    text-align: center;*/
    /*  }*/

    /*  .svg-barcode {*/
    /*    padding: 0;*/
    /*    margin: 0;*/
    /*    width: 90vw;*/
    /*    height: auto;*/
    /*  }*/

    /*  .barcode-wrapper {*/
    /*    page-break-after: always;*/
    /*  }*/
    /*}*/
  </style>
</head>
<body>${content}</body>
</html>`;
    }

    _parseHtmlAsSrc(html) {
      return `data:text/html;charset=utf-8,${encodeURI(html)}`;
    }

    generateBarcodeHtml() {
      const models = RA.ensureArray(this.args.model) || [];
      const html = models.map(model => {
        const label = R.pipe(R.pathOr('', ['_data', 'name']), R.take(10))(model);
        const value = model._data.barcode;
        const format = model._data.barcodeType;

        if (!value || !format) {
          return '';
        }

        const options = {
          format,
          // margin: 0,
          height: 50 // width: 2,
          // fontSize: 18,
          // textMargin: 0,
          // flat: true

        };
        const div = document.createElement('div');
        div.classList.add('barcode-wrapper');
        const p = document.createElement('p');
        p.classList.add('p-label');
        p.textContent = label;
        const svg = document.createElement('svg');
        svg.classList.add('svg-barcode');
        (0, _jsbarcode.default)(svg, value, options);
        div.appendChild(p);
        div.appendChild(svg);
        return div.outerHTML;
      });
      return html.join('\n');
    }

    printIFrameContent() {
      const iframeElement = this.iframeElement;
      iframeElement.contentWindow.focus();
      iframeElement.contentWindow.print();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setContent", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_getHtmlDefaults", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_getHtmlDefaults"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_parseHtmlAsSrc", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "_parseHtmlAsSrc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateBarcodeHtml", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "generateBarcodeHtml"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "printIFrameContent", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "printIFrameContent"), _class.prototype)), _class));
  _exports.default = BarcodePrintModalComponent;
});