define("client/pods/components/helpers/lazy-loader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DFk9L/6x",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"showWhenLoading\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"helpers/is-loading\",null,[[\"isRunning\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"helpers/lazy-loader/lazy-loader-btn\",null,[[\"hasMore\",\"showLazyLoaderBtn\",\"onAutoLazyLoadTask\"],[[24,[\"hasMore\"]],[24,[\"showWhenLoading\"]],[24,[\"autoLazyLoadTask\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/lazy-loader/template.hbs"
    }
  });

  _exports.default = _default;
});