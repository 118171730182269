define("client/pods/extensions/apps/tw/e-invoices/printer/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        statuses: [],
        docNo: '',
        date: '',
        contact: '',
        ref: '',
        paymentMethods: [],
        inclTotal: '',
        salesPersons: [],
        dispatchProcesses: [],
        sort: [{
          by: 'timestampZ',
          direction: 'DESC'
        }]
      };
    }

  });

  _exports.default = _default;
});