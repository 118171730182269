define("client/pods/components/contacts/search-results-documents/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: 'div',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['contacts/lists/tags']);
    }

  });

  _exports.default = _default;
});