define("client/pods/users/associates/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({// requiredAttrs: Ember.computed('_data.type', 'isNew', function () {
    //   if (this.get('_data.type') === this.get('constants.userTypes.employee')) {
    //     if (this.get('isNew'))  {
    //       return ['_data.name', '_data.username', '_data.email', '_data.password', '_data.confirmPassword'];
    //     }
    //     return ['_data.name', '_data.username', '_data.email'];
    //   }
    //   if (this.get('isNew'))  {
    //     return ['_data.name', '_data.email', '_data.password', '_data.confirmPassword'];
    //   }
    //   return ['_data.name', '_data.email'];
    // }),
  });

  _exports.default = _default;
});