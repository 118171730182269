define("client/pods/components/transporters/waybill-display/list/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TransportersWaybillDisplayListComponent extends _component.default {
    get isPickingList() {
      let tab = R.pathOr('', ['args', 'tab', 'value'])(this);
      return R.includes('pickingList')(tab);
    }

  }

  _exports.default = TransportersWaybillDisplayListComponent;
});