define("client/pods/components/menus/sidebar/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jCJ9NvoU",
    "block": "{\"symbols\":[\"@isRunning\",\"@tooltipLabel\",\"@toggleIsOn\",\"&default\",\"@menu\",\"@isMenuItem\"],\"statements\":[[4,\"if\",[[28,\"and\",[[23,6,[]],[23,5,[]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",false],[3,\"did-insert\",[[23,0,[\"setupMenuItem\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,4]],null,{\"statements\":[[0,\"\\t\"],[14,4],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \\n\"],[4,\"if\",[[23,0,[\"isSetup\"]]],null,{\"statements\":[[0,\"    \"],[5,\"elements/element-btn\",[],[[\"@icon\",\"@isRunning\",\"@isToggler\",\"@hasToggleIcon\",\"@tooltipLabel\",\"@toggleIsOn\",\"@onSubmit\"],[[23,0,[\"icon\"]],[23,1,[]],true,false,[23,2,[]],[23,3,[]],[28,\"fn\",[[23,0,[\"click\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/sidebar/item/template.hbs"
    }
  });

  _exports.default = _default;
});