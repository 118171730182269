define("client/pods/workflows/lists/menu/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let appendPath = arguments.length > 1 ? arguments[1] : undefined;
      const customData = {
        list: [{
          //   resource: 'api/protected/workflows/menu/actions/dash',
          //   component: 'workflows/panels-dash',
          //   icon: 'fas fa-table',
          //   label: 'overview',
          //   action: 'dash',
          //   tabType: 'replaceSubTab'
          // }, {
          resource: 'api/protected/workflows/items/menu/actions/new',
          component: 'workflows/items/panels-new',
          icon: 'fas fa-plus',
          label: 'new',
          action: 'newWorkflowItem',
          tabType: 'replaceSubTab'
        }]
      };
      const path = this.getFullPath('findAllPath', appendPath);
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});