define("client/pods/components/channels/shop/shipping-zones/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EwBajzNe",
    "block": "{\"symbols\":[\"@errors\",\"@tab\",\"@onDash\",\"@onEdit\"],\"statements\":[[5,\"channels/shop/shipping-zones/shipping-zones-editor\",[],[[\"@errors\",\"@isEditing\",\"@isNew\",\"@model\",\"@onDash\",\"@onEdit\",\"@tab\"],[[23,1,[]],false,false,[23,2,[\"model\"]],[23,3,[]],[23,4,[]],[23,2,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});