define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-automations/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['automations/when', 'automations/when/itemQty', 'automations/criterias', 'automations/thenActions', 'automations/thenSelectionTypes', 'channels/shop/campaigns/statuses', 'products/lists/tags']);
      this.set('productsList', []);
      this.fetchAutomationsCollectionTask.perform();
    },

    fetchAutomationsCollectionTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const key = model.getData('_key');
      const collectionModel = yield this.crud.findTask.perform({
        adapterName: 'channels/shop/campaigns/automations/collection',
        appendPath: key
      });
      this.set('automationsCollection', collectionModel);
    })
  });

  _exports.default = _default;
});