define("client/mixins/adapters-new", ["exports", "client/utils/nventor", "client/config/environment", "ramda"], function (_exports, _nventor, _environment, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    host: _environment.default.APP.serverUrl,

    getAdapter(adapterName) {
      const adapter = Ember.getOwner(this).lookup(`adapter:${adapterName}`);

      if (!adapter) {
        _nventor.default.throw(`ADAPTER MIXIN: getAdapterError. Invalid adpaterName: ${adapterName}.`);
      }

      adapter.set('syncAdapterName', adapterName);
      return adapter;
    },

    getPath(pathName, appendPath) {
      let path = this.get(pathName) || this.resourcePath;

      if (appendPath) {
        if (appendPath.charAt(0) !== '/') {
          path = `${path}/${appendPath}`;
        } else {
          path = path + appendPath;
        }
      }

      return path;
    },

    getFullPath(pathName, appendPath) {
      const path = this.getPath(pathName, appendPath);
      return this.host + '/' + path;
    },

    call(_ref) {
      let {
        method,
        appendPath,
        data,
        options = {}
      } = _ref;
      const path = this.getFullPath('resourcePath', appendPath);
      data = this._addAdapterInfo(data, appendPath);
      const {
        filters = {}
      } = options;
      return this.ajax[method](path, data).then(res => {
        if (filters?.set) {
          const hasFullCount = R.hasPath(['fullCount'])(res);

          if (hasFullCount) {
            let fullCount = R.path(['fullCount'])(res);
            fullCount = parseInt(fullCount) || 0;

            if (filters?.set) {
              filters.set('fullCount', fullCount);
            }
          }
        }

        return res.data;
      });
    },

    createModel: function (adapterName, adapter, data) {
      let attrs = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      // attrs.adapterName = adapterName
      // attrs.adapter = adapter
      return _nventor.default.createModel(this.modelObj, data, attrs);
    },
    isModel: function (obj) {
      if (obj._data != null) {
        return true;
      }

      return false;
    },

    serialize(model) {
      // serialize on model
      // if (model.serialize) {
      // @NOTE: the problem with this is that existing custom serialize on adapters need to be moved to model first
      //   return model.serialize()
      // }
      // return this._getAllData(model)
      return this._serialize(model._data);
    },

    _serialize() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      // if (typeof data === 'string') {
      if (data == null) {
        return '';
      }

      if (!Ember.isArray(data) && !_nventor.default.confirm.isObject(data)) {
        return data;
      }

      if (Ember.isArray(data)) {
        return this._serializeArray(data);
      }

      if (data._data) {
        return this._serializeObject(data._data);
      }

      return this._serializeObject(data);
    },

    _serializeArray(data) {
      data = data.filter(item => item != null);
      return data.map(item => {
        let serialized;

        if (item._data) {
          serialized = this._serialize(item._data);
        } else {
          serialized = this._serialize(item);
        }

        if (serialized._data) {
          return serialized._data;
        }

        return serialized;
      });
    },

    _serializeObject(data) {
      const newData = {};
      Object.keys(data).map(key => {
        const value = data[key];

        if (Ember.isArray(value)) {
          newData[key] = this._serializeArray(value);
          return key;
        }

        if (_nventor.default.confirm.get(value, '_data')) {
          newData[key] = this._serialize(value._data);
          return key;
        }

        newData[key] = this._serialize(value);
        return key;
      });
      return newData;
    },

    isNotEmptyDetail(detail, requiredDataAttrs) {
      return requiredDataAttrs.reduce((isEmpty, attr) => {
        if (isEmpty === false) {
          return false;
        }

        if (detail[attr]) {
          return true;
        }

        return false;
      }, true);
    },

    _addAdapterInfo() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let appendPath = arguments.length > 1 ? arguments[1] : undefined;
      // use related adapters when a model belongs to two lists. this way it will push
      // the model changes to both lists
      const adapters = [{
        adapterName: this.syncAdapterName,
        appendPath
      }];
      const relatedAdapterNames = this.relatedAdapters || [];
      data.adapter = {
        adapters: adapters.concat(relatedAdapterNames),
        cacheId: data._cacheId
      };
      delete data._cacheId;
      return data;
    },

    find() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let appendPath = arguments.length > 1 ? arguments[1] : undefined;
      let token = arguments.length > 2 ? arguments[2] : undefined;
      const path = this.getFullPath('findPath', appendPath);
      return this.ajax.GET(path, data, token).then(res => res.data);
    },

    findAll() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let appendPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const path = this.getFullPath('findAllPath', appendPath);
      const filters = options.filters;
      const method = options.method || 'GET';
      let token;

      if (filters) {
        token = filters.get('token');
      }

      return this.ajax[method](path, data, token).then(function (res) {
        const hasFullCount = R.hasPath(['fullCount'])(res);

        if (hasFullCount) {
          let fullCount = R.path(['fullCount'])(res);
          fullCount = parseInt(fullCount) || 0;

          if (filters?.set) {
            filters.set('fullCount', fullCount);
          }
        }

        return res.data || [];
      });
    },

    search(data, appendPath) {
      return this.findAll(data, appendPath);
    },

    save(data, appendPath) {
      // @TODO: test
      const path = this.getFullPath('createPath', appendPath);
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.POST(path, data).then(res => {
        return res.data;
      });
    },

    saveBatch(data) {
      let appendPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'batch';
      // @TODO: test
      const path = this.getFullPath('createPath', appendPath);
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.POST(path, data).then(res => {
        return res.data;
      });
    },

    update(data, appendPath) {
      const path = this.getFullPath('updatePath', appendPath);
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.PATCH(path, data).then(res => {
        return res.data;
      });
    },

    updateBatch(data) {
      let appendPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'batch';
      const path = this.getFullPath('updatePath', appendPath);
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.PATCH(path, data).then(res => {
        return res.data;
      });
    },

    replace(data, appendPath) {
      const path = this.getFullPath('replacePath', appendPath);
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.PUT(path, data).then(res => {
        if (this.afterUpdateRecord) {
          // trigger afterUpdate hook with raw response
          return this.afterUpdateRecord(res.data);
        } else {
          // return model data
          return _nventor.default.confirm.get(res.data, 'model', res.data);
        }
      });
    },

    removeBatch(data) {
      let appendPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'batch';
      const path = this.getFullPath('deletePath', appendPath);
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.DELETE(path, data).then(res => {
        return res.data;
      });
    },

    remove(data) {
      let appendPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      const path = this.getFullPath('deletePath', appendPath);
      data = this._addAdapterInfo(data, appendPath);
      return this.ajax.DELETE(path, data).then(res => {
        const responseData = res.data;

        if (this.afterDeleteRecord) {
          // trigger afterDelete with raw response
          return this.afterDeleteRecord(responseData);
        } else {
          // return deleted keys
          if (R.is(Array(responseData))) {
            return responseData;
          }

          return _nventor.default.confirm.get(responseData, 'deleted', true);
        }
      });
    }

  });

  _exports.default = _default;
});