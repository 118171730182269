define("client/pods/flows/steps/choices/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        index: '',
        value: '',
        label: '',
        status: 'active'
      };
    }

  });

  _exports.default = _default;
});