define("client/pods/components/channels/shop/campaigns/items/limit-qty-selector/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsItemsLimitQtySelectorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ChannelsShopCampaignsItemsLimitQtySelectorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "settings", _descriptor2, this);

      _defineProperty(this, "adapterName", 'channels/shop/campaigns/details/limits');

      this.crud.addLists(this, ['channels/shop/campaigns/lists/limit-adjust-types']);
      this.limitAdjustType = this?.args?.limitAdjustType || 'adjustTotalQty';
    }

    get modalDialogTitle() {
      const type = this?.args?.type || '';

      if (R.equals(type, 'preOrder')) {
        return 'adjust limit preorder qty';
      }

      return 'adjust limit qty';
    }

    get modalLabel() {
      const model = this?.args?.model || {};

      if (RA.isNotNilOrEmpty(model)) {
        const type = this?.args?.type || 'normal';
        const limitAdjustType = this?.args?.limitAdjustType || 'adjustTotalQty';

        if (R.equals(type, 'preOrder')) {
          if (R.equals(limitAdjustType, 'adjustTotalQty')) {
            return model?._data?.salesPreOrderLimitedQty || 0;
          } else {
            return this?.args?.tempCalculatedAvailablePreOrderQtyOnlyBeforeCart || 0;
          }
        } else {
          if (R.equals(limitAdjustType, 'adjustTotalQty')) {
            return model?._data?.salesLimitedQty || 0;
          } else {
            return model?._data?.calculatedAvailableQtyBeforeCart || 0;
          }
        }
      }

      return 0;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChannelsShopCampaignsItemsLimitQtySelectorComponent;
});