define("client/pods/components/reports/dashboard/card-revenue-by-group-with-percentage/card-detail/component", ["exports", "ramda-adjunct", "ramda"], function (_exports, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    big: Ember.inject.service(),
    tagName: '',
    isDate: false,
    roundingCurrency: 0,
    showAverages: true,
    roundingForAveraging: 2,

    getExcl(param, type) {
      return this.get(`${param}.${type}`);
    },

    getIncl(param, type) {
      return this.get(`${param}.${type}`);
    },

    getValue(param, exclParam, inclParam) {
      return this.isTaxExclusive ? this.getExcl(param, exclParam) : this.getIncl(param, inclParam);
    },

    getPaymentMethodLabel(label) {
      let paymentMethodKey = label?.paymentMethodKey || '';
      const paymentMethodCustomLabel = label.paymentMethodCustomLabel || '';

      if (RA.isNotNilOrEmpty(paymentMethodKey) && this.translate !== false) {
        paymentMethodKey = this.intl.t(paymentMethodKey);
      }

      if (RA.isNotNilOrEmpty(paymentMethodCustomLabel)) {
        return `${paymentMethodCustomLabel} (${paymentMethodKey})`;
      }

      return paymentMethodKey;
    },

    // can be used dynamically if no additional condition needed.
    getLabelFromObjectLabel(label) {
      let newLabel = '';
      R.mapObjIndexed((value, key, index) => {
        if (R.equals(index, 0)) {
          newLabel = this.intl.t(value);
        } else {
          newLabel = `{${newLabel} - ${this.intl.t(value)}}`;
        }
      });
      return newLabel;
    },

    qtyPercentage: Ember.computed('qtyTotal', 'detail.{qty,docCount}', 'averageByItemQty', function () {
      const averageByItemQty = R.propOr(false, 'averageByItemQty')(this);
      const qtyTotalBig = this.big.newBig(this?.qtyTotal || 0);
      const detail = this?.detail || {};

      if (RA.isNotNilOrEmpty(detail)) {
        let qtyUsedBig = this.big.newBig(detail?.docCount || 0);

        if (averageByItemQty) {
          qtyUsedBig = this.big.newBig(detail?.qty || 0);
        }

        if (qtyTotalBig.gt(0) && qtyUsedBig.gt(0)) {
          return qtyUsedBig.div(qtyTotalBig).toFixed(4);
        }
      }

      return '0';
    }),
    amountPercentage: Ember.computed('amountTotal', 'amount', function () {
      const amountTotalBig = this.big.newBig(this?.amountTotal || 0);
      const amountBig = this.big.newBig(this?.amount || 0);

      if (amountTotalBig.gt(0) && amountBig.gt(0)) {
        return amountBig.div(amountTotalBig).toFixed(4);
      }

      return '0';
    }),
    segmentLabel: Ember.computed('segmentByLabelKey', 'detail.segmentValue', function () {
      const detail = this.detail;
      let label = detail.segmentValue;

      if (this.segmentByLabelKey) {
        label = R.prop(this.segmentByLabelKey)(detail);
      } // if groupDetailsByInfoProp on api is using multiple params on the segment


      const segmentBy = detail?.segmentBy || [];

      if (R.is(Object, label)) {
        if (R.includes('paymentMethodKey', segmentBy)) {
          label = this.getPaymentMethodLabel(label);
          return label;
        }

        return this.getLabelFromObjectLabel(label);
      }

      if (!label) {
        label = 'none';
      }

      if (this.translate !== false) {
        return this.intl.t(label);
      }

      return label;
    }),
    averageItemPrice: Ember.computed('detail.average.{exclPrice,inclPrice,postDiscExclAmt,postDiscInclAmt}', 'isTaxExclusive', function () {
      let value = this.getValue('detail.average', 'postDiscExclPrice', 'postDiscInclPrice');

      if (RA.isNilOrEmpty(value)) {
        value = this.getValue('detail.average', 'exclPrice', 'inclPrice');
      }

      return value;
    }),
    averageOrderValue: Ember.computed('detail.average.{exclTotal,inclTotal,postDiscExclTotal,postDiscInclTotal}', 'isTaxExclusive', function () {
      let value = this.getValue('detail.average', 'postDiscExclTotal', 'postDiscInclTotal');

      if (RA.isNilOrEmpty(value)) {
        value = this.getValue('detail.average', 'exclTotal', 'inclTotal');
      }

      return value;
    }),
    amount: Ember.computed('detail.{exclAmt,inclAmt,postDiscExclAmt,postDiscInclAmt}', 'isTaxExclusive', function () {
      let value = this.getValue('detail', 'postDiscExclAmt', 'postDiscInclAmt');

      if (RA.isNilOrEmpty(value)) {
        value = this.getValue('detail', 'exclAmt', 'inclAmt');
      }

      return value;
    }),
    isReturns: Ember.computed('detail.docName', function () {
      const docName = R.propOr('', 'docName')(this.detail);

      if (R.equals(docName, 'returns')) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});