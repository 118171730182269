define("client/pods/flows/steps/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/flows/steps/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    applicationService: Ember.inject.service('application'),
    resourcePath: 'api/protected/flows/step/validate',
    modelObj: _model.default,
    idParam: false,

    serialize(model) {
      const data = this._super(model);

      const type = data.type;
      const actionType = data.actionType;

      if (R.equals(type, 'ACTION') && R.equals(actionType, 'generatePromoCode')) {
        const defaults = R.pathOr({}, ['actionData', 'defaults'])(data);
        defaults.emails = this.applicationService.splitToArray(defaults.emails || '');
        defaults.telephones = this.applicationService.splitToArray(defaults.telephones || '');
      }

      return data;
    }

  });

  _exports.default = _default;
});