define("client/pods/components/contacts/statement-print/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['u-print-container'],

    init() {
      this._super(...arguments);

      const contact = this.contact;
      const addressesList = contact.getDetailsList({
        type: 'address',
        defaultProp: 'defaultAddress'
      });
      let defaultAddress = contact.get('defaultAdress');

      if (!defaultAddress) {
        defaultAddress = addressesList.get('firstObject');
      }

      if (defaultAddress) {
        this.set('defaultAddress', defaultAddress._data.value);
      }
    }

  });

  _exports.default = _default;
});