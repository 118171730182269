define("client/pods/components/helpers/repeat-times/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kuHwOaNp",
    "block": "{\"symbols\":[\"index\",\"&default\"],\"statements\":[[4,\"each\",[[24,[\"timesArray\"]]],null,{\"statements\":[[0,\"\\n  \"],[14,2],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/repeat-times/template.hbs"
    }
  });

  _exports.default = _default;
});