define("client/pods/components/documentations/documentation/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentationComponent = (_dec = Ember.inject.service, (_class = class DocumentationComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _defineProperty(this, "adapterName", 'documentation');

      const dynamicList = {
        name: 'documentation/lists/resource',
        appendPath: this.documentationResource
      };
      this.crud.addLists(this, ['documentation/lists/tags', 'statuses', dynamicList]);
    }

    get documentationResource() {
      const documentationResource = this?.args?.documentationResource;
      return documentationResource || this?.args?.options?.documentationResource;
    }

    get documentationId() {
      if (this.args.options) {
        const {
          documentationId,
          documentationResource,
          context
        } = this.args.options;

        if (documentationId) {
          return documentationId;
        }

        if (documentationResource && context) {
          return `${documentationResource}-${context}`;
        }

        return null;
      }

      return null;
    }

    get documentation() {
      const documentationResource = `documentation-lists-resource--${this.documentationResource}`;
      return R.pipe(R.pathOr([], ['crud', 'lists', documentationResource]), R.find(R.pathEq(['_data', 'documentationId'], this.documentationId)))(this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DocumentationComponent;
});