define("client/pods/components/elements/element-toggler-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    toggle: false,
    tagName: 'div',
    classNameBindings: ['hasToggle:element-toggler-container'],
    hasToggle: true,
    actions: {
      toggle() {
        this.toggleProperty('toggle');
      }

    }
  });

  _exports.default = _default;
});