define("client/pods/shipping/fee-type/adapter", ["exports", "ramda", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, R, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let data = [{
        value: 'zones',
        label: 'zones fee'
      }, {
        value: 'flat',
        label: 'flat fee'
      }];

      if (!this.settings.getProp('beta.zones')) {
        data = R.drop(1, data);
      } // @TODO: add based on weight/volume


      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});