define("client/pods/components/channels/shop/campaigns/campaigns-report/component", ["exports", "ember-concurrency", "ramda"], function (_exports, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    guests: Ember.inject.service(),
    router: Ember.inject.service(),
    revenueBy: 'ordered',
    productsBySetsWithChoices: false,
    showReportBy: 'month',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['channels/shop/campaigns/reports/filters', 'reports/lists/show-reports-by', 'taxMethods/filters', 'periods/months', 'periods/years']);
      this.set('showCampaignReportBy', Ember.Object.create({
        revenueBy: this.revenueBy,
        taxMethods: 'inclusive'
      }));
    },

    dispatchStatusFilter: Ember.computed('showCampaignReportBy.revenueBy', function () {
      const revenueBy = this.get('showCampaignReportBy.revenueBy');

      if (revenueBy === 'ordered') {
        return 'all';
      }

      return this.get('constants.dispatchStatus.dispatched');
    }),

    didInsertElement() {
      this._super(...arguments);

      let sourceKey = '';

      if (!this.isGuest) {
        const model = this.model;
        sourceKey = model.getData('_key');
        const hasPos = model.getData('hasPos');

        if (hasPos) {
          this.set('showReportBy', 'day');
        }

        if (!model?._data?.dateEndZ) {
          this._setCurrentPeriod();
        }
      } else {
        const tokenData = this.tokenData || {};

        if (tokenData?.version) {
          if (!tokenData?.campaignDateEndZ) {
            this._setCurrentPeriod();
          }
        }
      }

      this.fetchRevenueReportTask.perform({
        sourceKey
      });
    },

    _setCurrentPeriod() {
      const period = this.dateService.getPeriod();
      const todayZ = this.dateService.getStartOfDayZ();
      this.set('mm', period.mm);
      this.set('yyyy', period.yyyy);
      this.set('customDateStartZ', todayZ);
    },

    resetPeriodTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        sourceKey,
        onAfter
      } = _ref;
      return function* () {
        _this.set('dateStart', '');

        _this.set('dateEnd', '');

        _this.set('mm', '');

        _this.set('yyyy', '');

        yield _this.fetchRevenueReportTask.perform({
          sourceKey,
          onAfter
        });
      }();
    }),
    fetchRevenueReportTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        sourceKey,
        onAfter
      } = _ref2;
      return function* () {
        const campaignsRevenue = yield _this2._fetchReportTask.perform('campaigns-revenue', {
          sourceKey,
          segments: ['activity', 'orderDateZ', 'expectedDispatchDateZ', 'dispatchDateZ', 'contact', 'item', 'itemWithGroupedSetWithChoices', 'trackingCodes', 'paymentMethodKey', 'status']
        });

        _this2.set('campaignsRevenue', campaignsRevenue);

        _this2.set('activityReport', []);

        _this2.set('itemReport', []);

        _this2.set('paymentMethodKeyReport', []);

        _this2.set('contactReport', []);

        if (onAfter) {
          onAfter();
        }
      }();
    }),
    displayActivityReportTask: (0, _emberConcurrency.task)(function (_ref3) {
      var _this3 = this;

      let {
        sourceKey
      } = _ref3;
      return function* () {
        const activityReport = yield _this3._fetchReportTask.perform('campaigns-activity', {
          sourceKey,
          segments: ['activity', 'itemWithGroupedSetWithChoices', 'trackingCodes']
        });

        _this3.set('activityReport', activityReport);
      }();
    }),
    displayItemReportTask: (0, _emberConcurrency.task)(function (_ref4) {
      var _this4 = this;

      let {
        sourceKey
      } = _ref4;
      return function* () {
        const itemReport = yield _this4._fetchReportTask.perform('campaigns-activity', {
          sourceKey,
          segments: ['orderDateZ', 'expectedDispatchDateZ', 'dispatchDateZ', 'item', 'itemWithGroupedSetWithChoices', 'trackingCodes', 'status']
        });

        _this4.set('itemReport', itemReport);
      }();
    }),
    displayPaymentMethodKeyReportTask: (0, _emberConcurrency.task)(function (_ref5) {
      var _this5 = this;

      let {
        sourceKey
      } = _ref5;
      return function* () {
        const paymentMethodKeyReport = yield _this5._fetchReportTask.perform('campaigns-activity', {
          sourceKey,
          segments: ['paymentMethodKey']
        });

        _this5.set('paymentMethodKeyReport', paymentMethodKeyReport);
      }();
    }),
    displayContactReportTask: (0, _emberConcurrency.task)(function (_ref6) {
      var _this6 = this;

      let {
        sourceKey
      } = _ref6;
      return function* () {
        const isGuest = _this6?.isGuest;
        let segments = ['contact', 'customerType', 'memberType', 'gender', 'age'];

        if (!isGuest) {
          segments = R.append('contactTags')(segments);
        }

        const contactReport = yield _this6._fetchReportTask.perform('campaigns-activity', {
          sourceKey,
          segments
        });

        _this6.set('contactReport', contactReport);
      }();
    }),
    _fetchReportTask: (0, _emberConcurrency.task)(function (reportName, _ref7) {
      var _this7 = this;

      let {
        sourceKey,
        segments = []
      } = _ref7;
      return function* () {
        const params = {
          status: _this7.get('constants.documentsStatus.final'),
          dispatchStatus: _this7.dispatchStatusFilter,
          // segments: [
          //   'activity',
          //   'orderDateZ',
          //   'expectedDispatchDateZ',
          //   'dispatchDateZ',
          //   'contact',
          //   'item',
          //   'itemWithGroupedSetWithChoices',
          //   'trackingCodes',
          //   'paymentMethodKey',
          //   'status',
          //   'customerType',
          //   'memberType',
          //   'gender',
          //   'age'
          // ]
          segments
        };
        let dateStart = '';
        let dateEnd = '';
        const showReportBy = _this7.showReportBy;

        if (R.equals(showReportBy, 'month')) {
          const mm = _this7.mm;
          const yyyy = _this7.yyyy;

          if (mm && yyyy) {
            const monthEnd = _this7.dateService.getMonthEnd(yyyy, mm).format('YYYY-MM-DD');

            dateStart = `${yyyy}-${mm}-01`;
            dateEnd = monthEnd;
          }
        }

        if (R.equals(showReportBy, 'day')) {
          const customDateStartZ = R.clone(_this7?.customDateStartZ || '');

          if (customDateStartZ) {
            dateStart = _this7.dateService.getMoment(customDateStartZ).format('YYYY-MM-DD');
            dateEnd = R.clone(dateStart);
          }
        }

        const revenueBy = _this7.showCampaignReportBy?.revenueBy;

        if (revenueBy === 'dispatched') {
          params.dispatchDateStart = dateStart;
          params.dispatchDateEnd = dateEnd;
        } else {
          params.dateStart = dateStart;
          params.dateEnd = dateEnd;
        }

        _this7.set('dateStart', dateStart);

        _this7.set('dateEnd', dateEnd);

        let report;

        if (_this7.isGuest) {
          params.report = reportName;

          try {
            report = yield _this7.guests.call('GET', 'api/guests/channels/shop/campaigns/report', params);
          } catch (e) {
            return _this7.guests.logout();
          }
        } else {
          params.sourceKey = sourceKey;
          report = yield _this7.server.call('GET', `api/protected/reports/${reportName}`, params);
        }

        return report.data;
      }();
    }),
    isTaxExclusive: Ember.computed('showCampaignReportBy.taxMethods', function () {
      if (this.get('showCampaignReportBy.taxMethods') === 'exclusive') {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});