define("client/pods/print/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { task } from 'ember-concurrency'
  // import RSVP from 'rsvp'
  var _default = Ember.Service.extend({// dataManager: service()
    // getAsDirty (model) {
    //   const docType = model.getData('docType')
    //   const docName = model.getData('docName')
    //   const adapterName = `documents/${docType}/${docName}`
    //   const dirty = this.get('dataManager').getDirtyRecord(adapterName, model)
    //   return dirty
    // },
    // setDispatchProcessBatchTask: task(function * ({ batch, dispatchProcess }) {
    //   const q = R.map(model => {
    //     return this.get('setDispatchProcessTask').perform({ model, dispatchProcess })
    //   }, batch)
    //   return yield RSVP.all(q)
    // }).enqueue(),
    // setDispatchProcessTask: task(function * ({ model, dispatchProcess }) {
    //   const docType = model.getData('docType')
    //   const docName = model.getData('docName')
    //   const adapterName = `documents/${docType}/${docName}`
    //   const dirty = this.getAsDirty(model)
    //   dirty.setData('dispatchProcess', dispatchProcess.getData('code'))
    //   const appendPath = 'dispatch/process'
    //   let saved = yield this.get('dataManager').updateRecord(adapterName, appendPath, dirty)
    //   return saved
    // }).enqueue()
  });

  _exports.default = _default;
});