define("client/pods/components/messages/tab-container/messages-window/message-bubble/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v11Wgw9a",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[24,[\"messageTypeComponentName\"]]],[[\"messageBubble\",\"fromUs\"],[[24,[\"messageBubble\"]],[24,[\"fromUs\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"_data\",\"_id\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[\"fas fa-check\"]]],false],[0,\"\\n  \\n  \"],[1,[28,\"dateTime\",[[24,[\"model\",\"_data\",\"timestampZ\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"helpers/is-loading\",null,[[\"isRunning\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/messages/tab-container/messages-window/message-bubble/template.hbs"
    }
  });

  _exports.default = _default;
});