define("client/pods/components/extensions/apps/tw/invoices/panels-display/sub/display-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0xm9k7c5",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n\\t\"],[1,[28,\"extensions/apps/tw/invoices/panels-display/sub/editor-item\",null,[[\"updateFollowingInvoices\",\"model\"],[true,[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"tr\",true],[8],[0,\"\\n\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\"],[7,\"table\",true],[10,\"class\",\"u-table\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"_data\",\"items\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"tr\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[23,1,[\"p\"]],false],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[23,1,[\"t\"]],false],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"tr\",true],[8],[0,\"\\n\\t\"],[7,\"td\",true],[10,\"colspan\",\"99\"],[10,\"class\",\"u-table__td u-table__td--border-bottom\"],[8],[0,\"\\n\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/invoices/panels-display/sub/display-item/template.hbs"
    }
  });

  _exports.default = _default;
});