define("client/pods/components/faqs/component", ["exports", "@glimmer/component", "client/utils/nventor", "client/pods/documents/proxy/model", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _nventor, _model, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * DO NOT USE ANY PATTER FROM HERE
   * @deprecated
   */
  let FaqComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class FaqComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "showAllDetails", _descriptor2, this);

      _initializerDefineProperty(this, "model", _descriptor3, this);

      _initializerDefineProperty(this, "adapterName", _descriptor4, this);

      _initializerDefineProperty(this, "faqsProxy", _descriptor5, this);

      _initializerDefineProperty(this, "faqsProxyTemp", _descriptor6, this);

      _initializerDefineProperty(this, "sorted", _descriptor7, this);

      this.detailsRowComponent = this.args.detailsRowComponent || 'faqs/table-view/faqs-row';
      this.createFaqModels(this.args.model._data.faqs);
      this.indexFaqs(this.faqsProxy);
    }

    createFaqModels() {
      let faqs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      this.faqsProxy = R.map(faq => _nventor.default.createModel(_model.default, {}, {
        model: this.model,
        faq,
        isSelected: false,
        showDetails: false
      }))(faqs);
    }

    indexFaqs(faqs) {
      this.faqsProxy = R.pipe(R.mapObjIndexed((model, index) => {
        model.faq.setData('index', parseInt(index));
        model.setData('index', parseInt(index));
        model.set('index', parseInt(index));
        return model;
      }), R.values)(faqs);
    }

    outerDrop(fromModelIndex, droppedAtIndex, results) {
      this.faqsProxy = results;
      this.indexFaqs(results);
      const faqs = R.pipe(R.propOr([], 'faqsProxy'), R.pluck('faq'))(this);
      this.args.model.setData('faqs', faqs);
      this.updateFaqsIndex.perform();
      this.toggleTableView();
    }

    toggleTableView() {
      this.sorted = !this.sorted;
      this.faqsProxy = R.propOr({}, 'faqsProxy')(this);
    }

    *updateFaqsIndex() {
      yield this.crud.updateRecordTask.perform({
        adapterName: this.args.adapterName,
        component: this,
        model: this.args.model,
        updateProps: ['faqs']
      });
    }

    addFaq() {
      let onCloseDialogAction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let productsSaved = arguments.length > 1 ? arguments[1] : undefined;
      const faqs = R.pathOr([], ['_data', 'faqs'])(productsSaved);
      this.createFaqModels(faqs);
      this.indexFaqs(this.faqsProxy);
      this.toggleTableView();
      this.model = this.args.model;
      this.faqsProxyTemp = R.map(faq => faq)(this.faqsProxy);

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    restoreFaqs() {
      const faqsProxyLength = R.pathOr('', ['faqsProxy', 'length'])(this);
      const faqsProxyTempLength = R.pathOr('', ['faqsProxyTemp', 'length'])(this);
      const faqsProxyData = R.pipe(R.propOr([{}], 'faqsProxy'), R.map(R.pathOr([], ['faq', '_data'])))(this);
      const faqsProxyTempData = R.pipe(R.propOr([{}], 'faqsProxyTemp'), R.map(R.pathOr([], ['faq', '_data'])))(this);

      if (faqsProxyLength === 0 && faqsProxyTempLength === 0) {
        this.createFaqModels(this.args.model._data.faqs);
        this.indexFaqs(this.faqsProxy);
        return;
      }

      if (faqsProxyLength === faqsProxyTempLength || R.equals(faqsProxyData, faqsProxyTempData)) {
        return;
      }

      if (faqsProxyTempLength > 0) {
        this.faqsProxy = this.faqsProxyTemp;
      }
    }

    afterUpdate(onCloseDialogAction, saved) {
      const faqs = R.pathOr([], ['_data', 'faqs'])(saved);
      const faqsData = R.pluck('_data')(faqs);
      const proxyFaqs = R.pipe(R.propOr([], 'faqsProxy'), R.map(R.pipe(R.pathOr([], ['faq', '_data']), R.omit(['index']))))(this);
      const shouldRemoveFaqs = faqsData.length !== proxyFaqs.length; // Remove faqs

      if (shouldRemoveFaqs) {
        R.pipe(R.without(faqsData), R.map(faq => {
          return R.findIndex(R.pathEq(['faq', '_data', 'question'], faq.question))(this.faqsProxy);
        }), R.forEach(removeFaqIndex => {
          this.faqsProxy.removeAt(removeFaqIndex);
        }))(proxyFaqs);
      }

      R.pipe(R.propOr([], 'faqsProxy'), R.forEachObjIndexed((proxy, index) => {
        faqs[index].setData('index', parseInt(index));
        proxy.set('faq', faqs[index]);
      }))(this);
      this.faqsProxyTemp = R.map(faq => faq)(this.faqsProxy);

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showAllDetails", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.model;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "adapterName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.adapterName;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "faqsProxy", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "faqsProxyTemp", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "sorted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "createFaqModels", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "createFaqModels"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "indexFaqs", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "indexFaqs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "outerDrop", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "outerDrop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleTableView", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleTableView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFaqsIndex", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateFaqsIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addFaq", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "addFaq"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "restoreFaqs", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "restoreFaqs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterUpdate", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "afterUpdate"), _class.prototype)), _class));
  _exports.default = FaqComponent;
});