define("client/pods/components/documents/editor-main/editor-inventory-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4/yERJUT",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"detailInventoryData\",\"index\",\"@model\"],\"statements\":[[5,\"modals/modal-dialog\",[],[[\"@title\",\"@modalDialogIsVisible\",\"@closeOnOverlayClick\",\"@onClose\"],[\"inventory notification\",[23,0,[\"modalDialogIsVisible\"]],false,[28,\"fn\",[[23,0,[\"onCloseDetailsInventoryDataDialog\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"table\",true],[10,\"class\",\"table u-full-width\"],[8],[0,\"\\n\"],[4,\"each\",[[23,4,[\"detailsInventoryData\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[5,\"documents/editor-main/editor-inventory-notification/editor-inventory-notification-detail\",[],[[\"@model\",\"@detailInventoryData\",\"@index\"],[[23,4,[]],[23,2,[]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"\\t\\t\"],[9],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/footer-btns\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"layouts/btns-group\",[],[[\"@classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\n        \"],[5,\"elements/btn-save\",[],[[\"@icon\",\"@label\",\"@onSubmit\"],[false,\"done\",[28,\"fn\",[[23,0,[\"onCloseDetailsInventoryDataDialog\"]],[23,1,[]]],null]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/editor-main/editor-inventory-notification/template.hbs"
    }
  });

  _exports.default = _default;
});