define("client/pods/components/products/inventory/inventory-status-tooltip/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    tagName: '',
    isTable: false,
    allowAdjustment: true,
    autoFetchProduct: true,
    isEmptyWarning: true,
    qtyParam: '_data.availableQty',

    didInsertElement() {
      this._super(...arguments);

      this.fetchProductTask.perform();
    },

    qty: Ember.computed('qtyParam', 'product.inventory._data.{onHandQty,onBookQty,salesInvoicesUndispatchedQty,salesConsignmentsUndispatchedQty,salesOrdersPendingQty,salesConsignmentPendingQty,purchasesPendingQty,purchasesConsignmentPendingQty,availableQty}', function () {
      const inventory = this.get('product.inventory');

      if (inventory) {
        const qtyParam = this.qtyParam;
        return inventory.get(qtyParam);
      }

      return '0';
    }),
    isEmpty: Ember.computed('isEmptyWarning', 'qty', 'product.inventory._data.{onHandQty,onBookQty,salesInvoicesUndispatchedQty,salesConsignmentsUndispatchedQty,salesOrdersPendingQty,salesConsignmentPendingQty,purchasesPendingQty,purchasesConsignmentPendingQty,availableQty}', function () {
      if (this.isEmptyWarning) {
        const qty = this.qty;
        const inventory = this.get('product.inventory');

        if (inventory) {
          return inventory.isLessThanEqualQty(0, qty);
        }
      }

      return false;
    }),
    fetchProductTask: (0, _emberConcurrency.task)(function* () {
      const itemKey = this.itemKey;
      const autoFetchProduct = this.autoFetchProduct;

      if (!this.get('product.inventory') && itemKey && autoFetchProduct) {
        const product = yield this.findTask.perform({
          adapterName: 'products',
          appendPath: itemKey
        });
        this.set('product', product);
      }
    }),
    actions: {
      togglePopup() {
        this.toggleProperty('showPopup');
      }

    }
  });

  _exports.default = _default;
});