define("client/pods/components/channels/shop/campaigns/campaigns-editor/faqs-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oydp3mag",
    "block": "{\"symbols\":[\"menuNav\",\"metaData\",\"@headerBottom\",\"@tabContentOnly\",\"@onSetHeaderBottom\",\"@parentTab\",\"@tab\",\"@resourceList\",\"@isDisabled\",\"@isReadonly\",\"@lists\",\"@model\"],\"statements\":[[5,\"guide-menu/guide-menu-nav\",[],[[\"@headerBottom\",\"@hide\",\"@menuId\",\"@onSetHeaderBottom\",\"@parentTab\",\"@tab\"],[[23,3,[]],[23,4,[]],\"campaignsFaqsMenu\",[23,5,[]],[23,6,[]],[23,7,[]]]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[5,\"forms/form-container\",[],[[\"@containerId\",\"@context\",\"@isMenu\",\"@menuId\",\"@resourceList\",\"@tab\"],[\"faqs\",\"faqs\",true,[23,1,[\"menu\"]],[23,8,[]],[23,7,[]]]],{\"statements\":[[0,\"\\n\"],[0,\"    \"],[5,\"forms/form-entity\",[],[[\"@context\",\"@documentationResource\",\"@isMenu\",\"@menuId\",\"@parent\",\"@tab\",\"@title\"],[\"faqsSettings\",\"campaigns\",true,[23,1,[\"menu\"]],[23,2,[\"parentComponent\"]],[23,7,[]],\"defaults faqs\"]],{\"statements\":[[0,\"\\n      \"],[5,\"faqs\",[],[[\"@adapterName\",\"@appendPath\",\"@isDisabled\",\"@isReadonly\",\"@lists\",\"@model\",\"@tab\"],[\"settings\",\"/partial\",[23,9,[]],[23,10,[]],[23,11,[]],[23,0,[\"settings\",\"settingsModel\"]],[23,7,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[0,\"    \"],[5,\"forms/form-entity\",[],[[\"@context\",\"@documentationResource\",\"@isMenu\",\"@menuId\",\"@parent\",\"@tab\",\"@title\"],[\"faqsCampaigns\",\"campaigns\",true,[23,1,[\"menu\"]],[23,2,[\"parentComponent\"]],[23,7,[]],\"faqs for shop\"]],{\"statements\":[[0,\"\\n      \"],[5,\"faqs\",[],[[\"@adapterName\",\"@appendPath\",\"@isDisabled\",\"@isReadonly\",\"@lists\",\"@model\",\"@tab\"],[\"channels/shop/campaigns\",\"\",[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,7,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/faqs-editor/template.hbs"
    }
  });

  _exports.default = _default;
});