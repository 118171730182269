define("client/pods/components/menus/nav-tree/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['nav-tree'],
    translate: true,
    menu: Ember.computed('list.[]', 'sub.[]', function () {
      const sub = this.sub || [];

      if (!R.isEmpty(sub)) {
        return sub;
      }

      let list = this.get('list') || [];

      if (R.isNil(list)) {
        list = [];
      }

      return list.map(function (item) {
        return item;
      });
    }),
    favoriteMenu: Ember.computed('list.[]', function () {
      let list = this.get('list') || [];

      if (R.isNil(list)) {
        list = [];
      }

      const favoriteMenu = R.find(R.pathEq(['_data', 'label'], 'favorites'))(list);

      if (favoriteMenu) {
        const favoriteMenuSub = favoriteMenu.getData('sub') || [];
        return favoriteMenuSub;
      }

      return [];
    }),
    actions: {
      toggleSubMenu(component) {
        component.toggleProperty('isExpanded');
      }

    }
  });

  _exports.default = _default;
});