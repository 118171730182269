define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/item-editor/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    adapterName: 'channels/shop/campaigns/automations/details',

    init() {
      this.setup();

      this._super(...arguments);
    },

    async setup() {
      const item = this.item;
      const dirty = this.setupDirty({
        model: item
      });
      this.set('dirty', dirty);
      const product = await this.findTask.perform({
        adapterName: 'products',
        appendPath: dirty.getData('itemKey')
      });
      dirty.set('product', product);
    },

    afterUpdateRecordTask() {
      this.onCloseDialogAction();
    },

    afterCancelEditing() {
      this.onCloseDialogAction();
    } // actions: {
    //   cancelEditing () {
    //     console.log('cancel editing of automation item')
    //   }
    // }


  });

  _exports.default = _default;
});