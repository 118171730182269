define("client/helpers/calculate", ["exports", "client/mixins/big"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_big.default, {
    compute(params, hash) {
      const rounding = hash.rounding || 0; // eg. {{calculate index '+' '1'}}

      const n1 = params[0] || 0;
      const operation = params[1];
      const n2 = params[2] || 0;

      if (operation === '+' || operation === 'plus') {
        return this.newBig(n1).plus(n2).toFixed(rounding);
      }

      if (operation === '-' || operation === 'minus') {
        return this.newBig(n1).minus(n2).toFixed(rounding);
      }

      if (operation === '*' || operation === 'times') {
        return this.newBig(n1).times(n2).toFixed(rounding);
      }

      if (operation === '/' || operation === 'div') {
        return this.newBig(n1).div(n2).toFixed(rounding);
      }

      throw new Error(`invalid operation: ${operation}`);
    }

  });

  _exports.default = _default;
});