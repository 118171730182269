define("client/pods/components/documents/editor-main/editor-inventory-notification/editor-inventory-notification-detail/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentsEditorMainEditorInventoryNotificationEditorInventoryNotificationDetail = (_dec = Ember.inject.service, (_class = class DocumentsEditorMainEditorInventoryNotificationEditorInventoryNotificationDetail extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "big", _descriptor, this);
    }

    get orderedQty() {
      let orderedQty = 0;
      const model = this.args.model;

      if (model) {
        const details = model?._data?.details || [];
        const detailInventoryData = this.args.detailInventoryData || {};
        const itemKey = detailInventoryData?._key || '';
        const detail = R.find(R.pathEq(['_data', 'itemKey'], itemKey))(details);
        orderedQty = detail?._data?.qty || 0;
      }

      return orderedQty;
    } // get undispatchedQty () {
    //   const undispatchedQtyBig = this.big.newBig(0)
    //   const detailInventoryData = this.args.detailInventoryData || {}
    //   const salesInvoicesUndispatchedQty = detailInventoryData?.inventory?.salesInvoicesUndispatchedQty || 0
    //   const salesConsignmentsUndispatchedQty = detailInventoryData?.inventory?.salesConsignmentsUndispatchedQty || 0
    //   return undispatchedQtyBig.plus(salesInvoicesUndispatchedQty).plus(salesConsignmentsUndispatchedQty).toFixed(0)
    // }


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "big", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DocumentsEditorMainEditorInventoryNotificationEditorInventoryNotificationDetail;
});