define("client/pods/components/websites/tab-container/component", ["exports", "client/mixins/crud", "client/pods/components/websites/mixins/websites-list-by", "ember-concurrency"], function (_exports, _crud, _websitesListBy, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_websitesListBy.default, _crud.default, {
    crud: Ember.inject.service(),
    results: null,
    showResults: true,
    query: '',
    adapterName: 'websites',
    filtersAdapterName: 'websites/filters',

    init() {
      this.crud.addLists(this, ['websites/lists/menu']);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments);

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      this.loadTabTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const model = loadInstructions.getData('model');

        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.findTask.perform({
            appendPath: id
          });
          this.display(found);
        }
      } else {
        const adapterName = this.adapterName;
        const filters = this.filters;
        const results = yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters,
          resultsProperty: 'results'
        });
        this.set('results', results); // this.dash(results)
      }
    }),

    dash(results) {
      // because website/panels-dash menu is disabled
      // const menuActionsList = R_.dotPath('crud.lists.websites-lists-menu')(this) || []
      // const found = menuActionsList.findBy('_data.component', 'websites/panels-dash')
      // if (!found) {
      //   return this.schedule()
      // }
      // if (!results) {
      //   const adapterName = this.adapterName
      //   const filters = this.filters
      //   this.fetchDefaultDataTask.perform({ adapterName, filters, resultsProperty: 'results' })
      // }
      const tab = this.tab;
      this.set('showResults', true);
      const panelOptions = {
        component: 'websites/panels-empty'
      };
      return this.tabsManager.replaceSubTab(tab, panelOptions);
    },

    display(model) {
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.set('showResults', false);
      const tab = this.tab; // tabOptions = tabOptions || this.tabsManager.getDefaultLoadInstructions(tab)

      const panelOptions = {
        component: 'websites/panels-display',
        model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
    },

    new(panelOptions) {
      this.set('resultsToggle', false); // add new contact sub tab

      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, panelOptions);
    },

    actions: {
      dash() {
        this.set('showResults', false); // should deselect results if any..
        // this.deselectResult();

        this.dash();
      },

      schedule() {
        // should deselect results if any..
        // this.deselectResult();
        this.schedule();
      },

      display(model) {
        let {
          tabOptions
        } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        this.set('resultsToggle', false);

        if (model) {
          this.display(...arguments);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },

      new(panelOptions) {
        this.new(...arguments);
      },

      edit(model) {
        let {
          tabOptions
        } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        this.set('resultsToggle', false);
        const tab = this.tab;
        tabOptions = tabOptions || this.tabsManager.getDefaultLoadInstructions(tab);
        const subTabOptions = {
          component: 'websites/panels-edit',
          model: model
        };
        this.tabsManager.replaceSubTab(tab, subTabOptions, tabOptions);
      }

    }
  });

  _exports.default = _default;
});