define("client/pods/components/app-logged-in/component", ["exports", "ember-concurrency", "ramda", "ramda-adjunct", "client/config/environment"], function (_exports, _emberConcurrency, R, RA, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // the only purpose the app-container serves is to keep track of isLoggedIn state
  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    applicationService: Ember.inject.service('application'),
    auth: Ember.inject.service(),
    crud: Ember.inject.service(),
    users: Ember.inject.service(),
    search: Ember.inject.service(),
    excel: Ember.inject.service(),
    settings: Ember.inject.service(),
    socket: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    tabsManager: Ember.inject.service(),
    notificationsService: Ember.inject.service('notifications'),
    updates: Ember.inject.service(),
    token: '',
    // token is only stored if its valid
    classNames: ['u-full-height'],
    isMaintenanceMode: false,
    isBotVisible: false,
    isNotificationsVisible: false,
    tabHeaderContainerClassNames: '.tab-header-container',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['application/lists/main', 'contacts/transporters', 'languages']);
      const userKey = this.users.getUserKey();
      const userMenuFilters = this.search.setupFilters({
        adapterName: 'users/personal-menu/filters',
        defaultFiltersData: {
          sort: [{
            by: 'index',
            direction: 'ASC'
          }],
          userKey,
          menuId: 'application/lists/main'
        }
      });
      this.set('userMenuFilters', userMenuFilters);
      this.socket.connect(this);
      this.setupTask.perform();
    },

    // @TODO: remove
    afterLoadLists() {
      this.openHomeTab();
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      this.fetchSettingsTask.perform();
      const notifications = yield this.notificationsService.fetchNotificationsTask.perform();
      this.set('notifications', notifications);
      yield this.getCustomizedUserMenuTask.perform();
    }),
    allowedMenu: Ember.computed('crud.lists.application-lists-main.[]', 'userMenu.synced', 'settings.settingsModel.synced', function () {
      let allowed = this.crud.lists['application-lists-main'] || []; // @TODO: temp fix. remove when merging wizards

      allowed = R.reject(R.pathEq(['_data', 'label'], 'home'))(allowed);
      const allPinned = R.pathOr([], ['userMenu', '_data', 'collection'])(this);
      return this.applicationService.processMenu({
        allowed,
        allPinned
      });
    }),
    getCustomizedUserMenuTask: (0, _emberConcurrency.task)(function* () {
      const userMenu = yield this.crud.findTask.perform({
        adapterName: 'users/personal-menu/collection',
        filters: this.userMenuFilters
      });
      const allowedMenu = this.allowedMenu || [];
      userMenu.set('allowedMenu', allowedMenu);
      this.set('userMenu', userMenu);
    }),
    fetchSettingsTask: (0, _emberConcurrency.task)(function* () {
      yield this.settings.setupTask.perform();
    }),
    total: 0,
    successful: 0,
    tries: 0,
    // maintenanceTask: task(function * () {
    // //   const model = this.settings.settingsModel
    // //   console.log(model)
    // // yield this.server.call('PATCH', 'api/protected/admin/payments/card/query-and-update/449343849733/BK1YKRON5DP0')
    // //   // if (this.isMaintenanceMode) {
    // //   //   // count = count || 1
    // //   //   // console.log('===count: ' + count)
    //   yield this.server.call('GET', 'api/protected/admin/sync-packages-status').then(results => {
    //       //     if (this?.isDestroyed === false) {
    //       //       this.set('total', this.total + results.total)
    //       //       this.set('tries', this.tries + results.tries)
    //       //       this.set('successful', this.successful + results.successful)
    //       //       if (results.hasMore === false) {
    //       //         // console.log('DONE!!')
    //       //       } else {
    //       //         this.maintenanceTask.perform()
    //       //       }
    //       //     }
    //   })
    //   // }
    // }),
    hasMultiplePods: Ember.computed('podChanged', function () {
      const pods = this.auth.getUserProp('pods') || [];

      if (pods.length > 1) {
        return true;
      }

      return false;
    }),
    companyStatusClassNames: Ember.computed('isConnected', function () {
      const isSocketConnected = this.isConnected;

      if (!isSocketConnected) {
        return 'inactive';
      }

      return 'active';
    }),

    setConnectedStatus(connectedStatus) {
      this.set('isConnected', connectedStatus);
    },

    // menuClassnames: computed('settings.settingsModel._data.company', 'isConnected', function () {
    //   let classnames = 'element-btn--border'
    //   if (!this.isConnected) {
    //     return `${classnames} is-danger`
    //   }
    //   return classnames
    // }),
    companyLogoUrl: Ember.computed('settings.settingsModel._data.company', function () {
      return this.settings.settingsModel?._data?.defaultLogo || '';
    }),
    companyName: Ember.computed('settings.settingsModel._data.company', function () {
      return this.settings.settingsModel?._data?.company || this.intl.t('logged out');
    }),
    companyNameShort: Ember.computed('companyName', 'applicationService.isMobile', 'isConnected', function () {
      let label = this.companyName;
      let isConnected = this.isConnected;

      if (!label || !isConnected) {
        return this.intl.t('logged out');
      }

      if (this.companyLogoUrl) {
        return '';
      }

      return label;
    }),
    loggedInAsLabel: Ember.computed('podChanged', 'companyName', function () {
      return this.auth.getUserProp('username');
    }),

    // loggedInAsLabelShort: computed('loggedInAsLabel', 'applicationService.isMobile', function () {
    //   let label = this.loggedInAsLabel
    //   let limit = 15
    //   if (this.applicationService.isMobile) {
    //     limit = 8
    //   }
    //   if (label.length > limit) {
    //     label = label.substring(0, limit - 3) + '...'
    //   }
    //   return label
    // }),
    getUserMenuLabelList() {
      const userMenu = this.userMenu;

      if (RA.isNilOrEmpty(userMenu)) {
        return [];
      }

      const userMenuSub = userMenu.getData('sub');
      return R.map(menu => {
        const label = R.pathOr('', ['_data', 'label'])(menu);
        return R.pathOr(label, ['_data', 'pinLabel'])(menu);
      })(userMenuSub);
    },

    getPinnedUserMenu(menuLabel) {
      const userMenu = this.userMenu;
      const userMenuSub = userMenu.getData('sub');
      let pinnedUserMenu = R.find(R.pathEq(['_data', 'pinLabel'], menuLabel))(userMenuSub);

      if (RA.isNilOrEmpty(pinnedUserMenu)) {
        pinnedUserMenu = R.find(R.pathEq(['_data', 'label'], menuLabel))(userMenuSub);
      }

      return pinnedUserMenu;
    },

    pinMenuTask: (0, _emberConcurrency.task)(function* (menu) {
      const userMenu = this.userMenu || [];
      const lastIndex = userMenu.length || 0;
      const isPinned = menu.getData('isPinned');

      if (isPinned) {
        const toRemove = menu.get('pinnedItem') || menu;
        yield this.crud.removeRecordTask.perform({
          adapterName: 'users/personal-menu',
          model: toRemove,
          noConfirm: true
        }); // yield this.updateMenuIndexTask.perform({ resetIndex: true })
      } else {
        const userKey = this.users.getUserKey();
        menu.setData('userKey', userKey);
        menu.setData('menuId', 'application/lists/main');
        menu.setData('index', lastIndex);
        yield this.crud.createRecordTask.perform({
          adapterName: 'users/personal-menu',
          model: menu
        });
      }
    }),
    _updateMenuIndexTask: (0, _emberConcurrency.task)(function* (menu) {
      const dirty = this.crud.setupDirty({
        adapterName: 'users/personal-menu',
        model: menu
      });
      yield this.crud.updateRecordTask.perform({
        adapterName: 'users/personal-menu',
        model: dirty
      });
    }).enqueue(),
    updateMenuIndexTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        resetIndex = false
      } = _ref;
      return function* () {
        if (resetIndex) {
          yield _this.getCustomizedUserMenuTask.perform();
        }

        const userMenu = _this.userMenu;
        let userMenuSub = userMenu.getData('sub');
        const q = [];

        if (resetIndex) {
          userMenuSub = _this.resetIndexInArrayOfObject({
            array: userMenuSub,
            indexPropName: 'index'
          });
        }

        R.forEach(menu => {
          q.push(_this._updateMenuIndexTask.perform(menu));
        })(userMenuSub);
        yield (0, _emberConcurrency.all)(q);
        yield _this.getCustomizedUserMenuTask.perform();
      }();
    }),
    userNavInsertedTask: (0, _emberConcurrency.task)(function* (event) {
      const DEBOUNCE_MS = 500;
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      const userNav = event?.target;
      const tabHeaderContainer = userNav?.querySelector?.(this.tabHeaderContainerClassNames);
      const inlineSize = window?.innerWidth;

      if (!tabHeaderContainer || !inlineSize) {
        return;
      }

      const offsetLeft = tabHeaderContainer?.offsetLeft;
      const maxTabHeaderWidth = inlineSize - offsetLeft; // tabHeaderContainer.style.maxWidth = '100%'
    }).restartable(),

    resetIndexInArrayOfObject(_ref2) {
      let {
        array = [],
        indexPropName = 'index'
      } = _ref2;
      const mapIndexed = R.addIndex(R.map);
      return mapIndexed((obj, idx) => {
        obj.setData(indexPropName, idx);
        return obj;
      })(array);
    },

    showModal() {
      this.set('isShowingModal', true);
    },

    openHomeTab() {
      this._openDefaultTab();
    },

    _openDefaultTab() {
      if (_environment.default.environment === 'development') {
        const menuList = this.crud.lists['application-lists-main']; // eg. 'contacts' or 'products' etc
        // change this to open default tab. currently cannot handle nested menu items
        // add nested item to favorites first if you need it to open automatically

        const defaultTabLabel = 'campaigns';

        if (RA.isNotNilOrEmpty(menuList) && defaultTabLabel) {
          // const tabOptions = R.find(R.pathEq(['_data', 'label'], 'home'))(menuList)
          let tabOptions = R.find(R.pathEq(['_data', 'label'], defaultTabLabel))(menuList);

          if (!tabOptions) {
            const favorites = R.pathOr([], ['userMenu', '_data', 'collection'])(this);
            tabOptions = R.find(R.pathEq(['_data', 'label'], defaultTabLabel))(favorites);
          }

          if (tabOptions) {
            this.tabsManager.openTab('left', tabOptions);
          }
        }
      }
    },

    actions: {
      openTab(tabOptions) {
        // hide menu
        this.toggleProperty('mainMenuActive');
        tabOptions.setData('isModalParent', true);
        this.tabsManager.openTab('left', tabOptions);
      },

      openChatTab() {
        this.toggleProperty('isMessagesVisible');
        const tabOptions = {
          resource: '/api/protected/messages',
          component: 'messages/tab-container',
          label: 'chat messages',
          icon: 'fas fa-comments',
          tabPosition: 'sidebarRight',
          tabType: 'singleton'
        };
        this.tabsManager.openTab('sidebarRight', tabOptions);
      },

      logout() {
        const reload = true;
        this.auth.logout(reload);
      },

      markAllAsRead() {
        Ember.set(this, 'mainMenuActive', false);
        this.notifications.markAllAsRead();
      },

      setLanguage(item) {
        this.auth.setLanguage(item.getData('value'));
        window.location.reload();
      },

      onChangePod(onCloseDialogAction, loginData) {
        this.set('podChanged', Math.random()); // onCloseDialogAction()
      }

    } // exportTask: task(function * (onCloseDialogAction /* filter */) {
    //   let data = invData
    //   data = R.pipe(
    //     R.reject(R.propEq('void', true)),
    //     R.map(d => {
    //       d.dispatchedDate = this.dateService.formatDate(d.dispatchDateZ, 'YYYY-MM-DD')
    //       d.timestamp = this.dateService.formatDate(d.timestampZ, 'YYYY-MM-DD')
    //       d.inclTotal = parseInt(d.inclTotal)
    //       d.item = R.join(' | ')(d.item)
    //       return R.omit([
    //         'docNo',
    //         'cancelledInvoiceNumber',
    //         'dispatchDateZ',
    //         'timestampZ',
    //         'void',
    //         'status',
    //         'invoiceTotal'
    //       ])(d)
    //     }),
    //     R.reject(R.equals(false))
    //   )(data)
    //   const columns = R.keys(data[0])
    //   data = R.map(R.values)(data)
    //   data.unshift(columns)
    //   const worksheets = [{
    //     sheetName: 'data',
    //     data
    //   }]
    //   yield this.excel.exportFile({
    //     worksheets,
    //     title: '11DataexportFile'
    //   })
    // }).drop()

  }, (_applyDecoratedDescriptor(_obj, "setConnectedStatus", [_dec], Object.getOwnPropertyDescriptor(_obj, "setConnectedStatus"), _obj), _applyDecoratedDescriptor(_obj, "showModal", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showModal"), _obj), _applyDecoratedDescriptor(_obj, "openHomeTab", [_dec3], Object.getOwnPropertyDescriptor(_obj, "openHomeTab"), _obj)), _obj)));

  _exports.default = _default;
});