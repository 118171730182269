define("client/pods/components/flows/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UmWMeL0i",
    "block": "{\"symbols\":[\"@tab\",\"@onDash\",\"@onEdit\"],\"statements\":[[5,\"flows/flows-editor\",[],[[\"@model\",\"@errors\",\"@isDisplay\",\"@onDash\",\"@isEditing\",\"@isNew\",\"@onEdit\",\"@tab\",\"@isRunning\"],[[23,1,[\"model\"]],[23,0,[\"errors\"]],true,[23,2,[]],false,false,[23,3,[]],[23,1,[]],[23,0,[\"crud\",\"createRecordTask\",\"isRunning\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/flows/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});