define("client/pods/components/guests/campaign-main/campaign-report/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "47ZRpxDB",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"channels/shop/campaigns/campaigns-report\",null,[[\"isGuest\",\"tokenData\"],[true,[24,[\"tokenData\"]]]]],false],[0,\"\\n\"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/guests/campaign-main/campaign-report/template.hbs"
    }
  });

  _exports.default = _default;
});