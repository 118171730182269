define("client/pods/components/extensions/apps/tw/momo/products/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CgFeYPrW",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[5,\"helpers/tab-link\",[],[[\"@hasLink\",\"@tabOptions\"],[[23,1,[\"_data\",\"itemKey\"]],[28,\"hash\",null,[[\"component\",\"title\",\"loadInstructions\"],[\"products/tab-container\",\"products\",[28,\"hash\",null,[[\"find\"],[[28,\"hash\",null,[[\"id\"],[[23,1,[\"_data\",\"itemKey\"]]]]]]]]]]]]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"lists/list-searcher\",[],[[\"@tagName\",\"@inputPvtClassNames\",\"@tagClassNames\",\"@placeholder\",\"@allowCustom\",\"@list\",\"@itemValueKey\",\"@itemLabelKey\",\"@value\",\"@selectedItemValueKey\",\"@hasSelectedKey\",\"@hasSelected\",\"@onSearch\",\"@hasSelectedIcon\",\"@isRunning\",\"@onDeselect\"],[\"span\",\"table-form__input table-form__input--120\",\"list-searcher__tag-discrete\",\"search products\",true,[23,0,[\"productsFilters\",\"productsList\"]],\"_data._key\",\"_data.name\",[23,1,[\"_data\",\"name\"]],\"_data.name\",\"_data._key\",[23,1,[\"_data\",\"itemKey\"]],[28,\"perform\",[[23,0,[\"searchProductsTask\"]]],null],\"fas fa-bookmark\",[23,0,[\"searchProductsTask\",\"isRunning\"]],[23,0,[\"afterCreateProductRecord\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/momo/products/link/template.hbs"
    }
  });

  _exports.default = _default;
});