define("client/pods/components/files/files-browser/component", ["exports", "client/mixins/crud", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _crud, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: '',
    resource: 'files',
    resourceKey: 'files',
    allowUploads: true,
    filtersAdapterName: 'uploads/filters',
    resultsProperty: 'uploads',
    adapterName: 'uploads',
    selectedItems: null,
    selectedTagKeys: null,
    untagSelectedTagKeys: false,
    hasSelectedItemsLimit: false,
    selectedItemsLimit: 0,

    init() {
      this._super(...arguments);

      const resultsProperty = this.resultsProperty;

      if (!this.filters) {
        const filters = this.search.setupFilters({
          adapterName: this.filtersAdapterName,
          defaultFiltersData: {
            resultsProperty
          }
        });
        this.set('filters', filters);
      }

      this.set('selectedItems', []);
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
      this.crud.addLists(this, ['uploads/lists/tags']);

      if (R.isEmpty(this.selectedItems) && RA.isNotNilOrEmpty(this.parentSelectedItems)) {
        this.selectedItems.addObjects(this.parentSelectedItems);
      }
    },

    hasSelectedItems: Ember.computed('parentSelectedItems.length', 'selectedItems.length', function () {
      if (RA.isNotNilOrEmpty(this.selectedItems)) {
        return true;
      }

      if (RA.isNotNilOrEmpty(this.parentSelectedItems)) {
        return true;
      }

      return false;
    }),
    isRunning: Ember.computed('removeTask.isRunning', 'onSubmit.isRunning', 'onSubmitTask.isRunning', function () {
      const isRemoveTaskRunning = R.pathOr(false, ['removeTask', 'isRunning'])(this);
      const isOnSubmitTaskRunning = R.pathOr(false, ['onSubmit', 'isRunning'])(this);
      const isOnSubmitTaskTaskRunning = R.pathOr(false, ['onSubmitTask', 'isRunning'])(this);

      if (isRemoveTaskRunning || isOnSubmitTaskRunning || isOnSubmitTaskTaskRunning) {
        return true;
      }

      return false;
    }),
    loadingImagesIsRunning: Ember.computed('fetchUploadsTask.isRunning', 'searchRecordsTask.isRunning', 'customPaginateSearchRecordsTask.isRunning', 'crud.searchRecordsTask.isRunning', function () {
      const fetchUploadsTaskIsRunning = R.pathOr(false, ['fetchUploadsTask', 'isRunning'])(this);
      const searchRecordsTaskIsRunning = R.pathOr(false, ['searchRecordsTask', 'isRunning'])(this);
      const crudSearchRecordsTaskIsRunning = R.pathOr(false, ['customPaginateSearchRecordsTask', 'isRunning'])(this);
      const customPaginateSearchRecordsTaskIsRunning = R.pathOr(false, ['crud', 'searchRecordsTask', 'isRunning'])(this);

      if (fetchUploadsTaskIsRunning || searchRecordsTaskIsRunning || crudSearchRecordsTaskIsRunning || customPaginateSearchRecordsTaskIsRunning) {
        return true;
      }

      return false;
    }),
    uploadBtnLabel: Ember.computed('resource', function () {
      return `${this.intl.t('upload images')}: ${this.resource}`;
    }),

    willDestroyElement() {
      if (this?.isDestroyed === false) {
        this._super(...arguments);

        this.resetSelectedItems();
      }
    },

    setUploadsProxy(results) {
      const selectedItems = this?.selectedItems || [];
      R.forEach(item => {
        const uuid = item?._data?.uuid || '';
        const result = R.find(R.pathEq(['_data', 'uuid'], uuid))(results);

        if (RA.isNotNilOrEmpty(result)) {
          result.set('isSelected', true);
        }
      })(selectedItems);
      this.set('uploads', results);
    },

    customPaginateSearchRecordsTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.paginateSearchRecordsTask.perform(...arguments);
      this.setUploadsProxy(results);
    }),

    resetSelectedItems(item) {
      const getItemKey = R.path(['_data', '_key']);
      const selectedItem = getItemKey(item);
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
      const uploads = this.uploads || [];
      R.forEach(file => {
        const fetchItem = getItemKey(file);

        if (fetchItem !== selectedItem) {
          file.set('isSelected', false);
        }
      })(uploads);
      this.set('selectedItems', []);
    },

    fetchUploadsTask: (0, _emberConcurrency.task)(function* () {
      let results = this.results || [];

      if (this.fetchUploadsOnInit) {
        results = yield this.crud.searchRecordsTask.perform({
          adapterName: this.adapterName,
          filters: this.filters,
          data: {
            resource: this.resource,
            resourceKey: this.resourceKey
          }
        });
      }

      this.setUploadsProxy(results);
    }),
    removeTask: (0, _emberConcurrency.task)(function* (files) {
      files = RA.ensureArray(files);
      const isDeleted = yield this.onRemove(files, {
        onAfter: this.fetchUploadsTask
      });

      if (!isDeleted) {
        return;
      }

      const uploadsKeys = R.map(R.path(['_data', '_key']))(files);
      const selectedItems = R.pipe(R.propOr([], 'selectedItems'), R.reject(R.pipe(R.path(['_data', '_key']), R.contains(R.__, uploadsKeys))))(this);
      this.set('selectedItems', selectedItems);
      yield this.fetchUploadsTask.perform();
    }),
    onSubmitTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, showPreviewModal) {
      if (this.allowMultipleItems) {
        yield this.onSubmit(this.selectedItems);
        this.resetSelectedItems();
      } else {
        const uploadsModel = R.path(['selectedItems', 0])(this);
        yield this.onSubmit(uploadsModel);
      }

      if (onCloseDialogAction && !onCloseDialogAction?.type) {
        onCloseDialogAction();
      }
    }),
    selectedItemIndex: Ember.computed('selectedItems.[]', function () {
      let index = 1;
      const selectedItems = this?.selectedItems || [];
      return R.reduce((acc, item) => {
        acc[item?._data?.uuid] = index;
        index = index + 1;
        return acc;
      }, {})(selectedItems);
    }),

    addToSelected(item, toggleModel) {
      const isOverLimit = this?.selectedItems?.length >= this.selectedItemsLimit;

      if (this.hasSelectedItemsLimit && isOverLimit) {
        if (toggleModel.toggleIsOn) {
          item.isSelected = false;
        } else {
          this.selectedItems.removeObject(item);
        }

        return;
      }

      const allowMultipleItems = R.propOr(false, 'allowMultipleItems')(this);

      if (!allowMultipleItems) {
        this.resetSelectedItems(item);
        this.selectedItems.clear();
        this.selectedItems.addObject(item); // Reset selectedTagKeys

        this.selectedItems[0].set('isSelected', true);
        this.onSelectItem(item);
      } else {
        if (this.selectedItems.includes(item)) {
          this.selectedItems.removeObject(item);
          this.onDeselectItem(item);
        } else {
          this.selectedItems.addObject(item);
          this.onSelectItem(item);
        }
      }

      this.set('lastSelected', item);
    },

    addToSelectedAndCloseDialog(item, onCloseDialogAction, toggleModel) {
      this.addToSelected(item, toggleModel);
      onCloseDialogAction();
    },

    showPreview(file) {
      this.set('showPreviewModal', file);
    },

    resetSelectedTagKeys() {
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
    },

    cancel() {
      this.resetSelectedItems();
      this.onCancel();
    }

  }, (_applyDecoratedDescriptor(_obj, "addToSelected", [_dec], Object.getOwnPropertyDescriptor(_obj, "addToSelected"), _obj), _applyDecoratedDescriptor(_obj, "addToSelectedAndCloseDialog", [_dec2], Object.getOwnPropertyDescriptor(_obj, "addToSelectedAndCloseDialog"), _obj), _applyDecoratedDescriptor(_obj, "showPreview", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showPreview"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedTagKeys", [_dec4], Object.getOwnPropertyDescriptor(_obj, "resetSelectedTagKeys"), _obj), _applyDecoratedDescriptor(_obj, "cancel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "cancel"), _obj)), _obj)));

  _exports.default = _default;
});