define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/bulk-discount-editor/rules-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorRulesEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorRulesEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "dataManager", _descriptor3, this);

      _initializerDefineProperty(this, "constants", _descriptor4, this);
    }

    get bulkDiscountsWhen() {
      const whenLists = this.crud.lists['automations-when'];
      const bulkDiscountsWhenLists = R.concat(whenLists, []);
      return R.filter(whenData => {
        const when = R.pathOr('', ['_data', 'value'])(whenData);
        return R.includes(when, ['itemQty', 'itemValue']);
      })(bulkDiscountsWhenLists);
    }

    get rewardMemberLevels() {
      const model = this.args.model;
      const rewardData = model.getData('rewardData') || {};

      if (RA.isNotNilOrEmpty) {
        const memberLevels = R.propOr([], 'memberLevels')(rewardData);
        return R.map(memberLevel => {
          return this.dataManager.setAsRecord({
            adapterName: 'channels/shop/rewards/member-levels',
            data: memberLevel
          });
        })(memberLevels);
      }

      return [];
    }

    onSubmitRemoveRule(schedule, rule) {
      const rules = schedule.getData('rules');
      rules.removeObject(rule);
    }

    onToggleSetDescription(rule) {
      const isDefaultDescription = rule.getData('isDefaultDescription');

      if (isDefaultDescription) {
        rule.setData('description', '');
      }
    }

    onInputCheckHasDuplicate() {
      const rule = this.args?.rule || {};
      const schedule = this.args?.schedule || {};
      let hasDuplicate = false;

      if (RA.isNotNilOrEmpty(rule)) {
        const rules = schedule.getData('rules') || [];
        const criteria = rule.getData('criteria') || '';
        const value = rule.getData('value') || '';
        hasDuplicate = R.pipe(R.reject(eachRule => {
          const hasMemberLevels = eachRule.getData('hasMemberLevels') || false;
          const memberLevelId = eachRule.getData('memberLevelId') || '';
          return hasMemberLevels && RA.isNotNilOrEmpty(memberLevelId);
        }), R.filter(R.allPass([R.pathEq(['_data', 'criteria'], criteria), R.anyPass([R.pathEq(['_data', 'value'], value), R.pathEq(['_data', 'value'], parseInt(value))])])), R.length, R.gt(R.__, 1))(rules);
      }

      rule.set('hasDuplicate', hasDuplicate);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmitRemoveRule", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveRule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleSetDescription", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSetDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInputCheckHasDuplicate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onInputCheckHasDuplicate"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorRulesEditorComponent;
});