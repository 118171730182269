define("client/pods/components/transporters/t-cat-waybill/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TransportersTCatWaybillComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('dispatch'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = class TransportersTCatWaybillComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "server", _descriptor2, this);

      _initializerDefineProperty(this, "date", _descriptor3, this);

      _initializerDefineProperty(this, "dispatchService", _descriptor4, this);

      _initializerDefineProperty(this, "printType", _descriptor5, this);

      _initializerDefineProperty(this, "transporterModel", _descriptor6, this);

      _defineProperty(this, "adapterNameContacts", 'contacts');

      _defineProperty(this, "transporterExtension", 't-cat');

      this.crud.addLists(this, ['extensions/apps/tw/t-cat/lists/waybill-print-type']);
      this.fetchTransporterTask.perform();
    }

    get tCatDocs() {
      const tCatKey = this?.dispatchService?.tCatModel?._data?._key;
      return R.pipe(R.pathOr([], ['args', 'results']), R.filter(R.pathEq(['_data', 'transporterKey'], tCatKey)))(this);
    }

    get tCatDocsProxy() {
      return R.map(model => ({
        model
      }))(this.tCatDocs);
    }

    get tCatDocsDisplayList() {
      const tCatKey = this?.dispatchService?.tCatModel?._data?._key;
      return R.filter(R.anyPass([R.pathEq(['args', 'dispatchedResults', 'model', '_data', 'transporterExtension'], this.transporterExtension), R.pathEq(['_data', 'transporterKey'], tCatKey)]))(this);
    }

    *generateAndDownloadWaybillTask() {
      return yield this.dispatchService.tCatGenerateAndDownloadWaybillTask.perform({
        component: this,
        documents: this.tCatDocs,
        printType: this.printType
      });
    }

    *fetchTransporterTask() {
      this.transporterModel = yield this.dispatchService.tCatGetModel.perform();
      this.printType = this?.transporterModel?._data?.defaultTransporterWaybillPrintType;

      if (this?.args?.onSetPrintType) {
        this.args.onSetPrintType(this.printType);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dispatchService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "printType", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "generateAndDownloadWaybillTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "generateAndDownloadWaybillTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchTransporterTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTransporterTask"), _class.prototype)), _class));
  _exports.default = TransportersTCatWaybillComponent;
});