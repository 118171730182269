define("client/pods/workflows/lists/items/activities/types/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor", "client/constants/index"], function (_exports, _adapter, _adaptersNew, _model, _nventor, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: _index.default.workflowsItemsActivitiesTypes.notes,
        label: 'workflows_items_activities_notes'
      }, {
        value: _index.default.workflowsItemsActivitiesTypes.email,
        label: 'workflows_items_activities_email'
      }, {
        value: _index.default.workflowsItemsActivitiesTypes.call,
        label: 'workflows_items_activities_call'
      }, {
        value: _index.default.workflowsItemsActivitiesTypes.event,
        label: 'workflows_items_activities_event'
      }, {
        value: _index.default.workflowsItemsActivitiesTypes.meeting,
        label: 'workflows_items_activities_meeting'
      }, {
        value: _index.default.workflowsItemsActivitiesTypes.review,
        label: 'workflows_items_activities_review'
      }, {
        value: _index.default.workflowsItemsActivitiesTypes.authorization,
        label: 'workflows_items_activities_authorization'
      }, {
        value: _index.default.workflowsItemsActivitiesTypes.todo,
        label: 'workflows_items_activities_todo'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});