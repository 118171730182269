define("client/pods/components/guests/campaign-main/campaign-activity/component", ["exports", "ramda", "client/mixins/big", "client/mixins/doc-status", "ember-concurrency", "client/pods/documents/model", "client/utils/nventor"], function (_exports, R, _big, _docStatus, _emberConcurrency, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_big.default, _docStatus.default, {
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    dataManager: Ember.inject.service(),
    big: Ember.inject.service(),
    intl: Ember.inject.service(),
    users: Ember.inject.service(),
    guests: Ember.inject.service(),
    isSearching: false,

    didInsertElement() {
      this._super(); // this.set('filters', filters)


      this.fetchDocumentsTask.perform();
    },

    fetchDocumentsTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.search.setupFilters({
        adapterName: 'documents/dispatch/filters'
      });
      yield this._fetchDataTask.perform(filters);
    }),
    paginateSearchRecordsTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        filters
      } = _ref;
      return function* () {
        yield _this._fetchDataTask.perform(filters);
      }();
    }),
    _fetchDataTask: (0, _emberConcurrency.task)(function* (filters) {
      this.set('filters', filters);
      const attrs = {};
      attrs.dataManager = this.dataManager;
      attrs.crud = this.crud;
      attrs.dateService = this.dateService;
      attrs.big = this.big;
      attrs.users = this.users;
      attrs.intl = this.intl;
      const data = filters.serialize();
      let response;

      try {
        response = yield this.guests.call('GET', 'api/guests/channels/shop/campaigns/activity', data);
      } catch (error) {
        return this.guests.logout();
      }

      const fullCount = R.propOr(0, 'fullCount')(response);
      filters.set('fullCount', fullCount);
      const results = R.pipe(R.propOr([], 'data'), R.map(result => {
        return _nventor.default.createModel(_model.default, result, attrs);
      }))(response);
      this.set('results', results);
    }),
    customPaginationTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        filters
      } = _ref2;
      return function* () {
        let page = filters.getData('page');
        page = parseInt(page) || 0;
        page = page + 1;
        yield _this2._fetchDataTask.perform(filters);
      }();
    }),
    searchCampaignTask: (0, _emberConcurrency.task)(function* (query) {
      const filters = this.filters;
      const results = [];

      if (query === '') {
        yield this.fetchDocumentsTask.perform();
        return;
      }

      filters.setData('page', 1);
      yield this._fetchDataTask.perform(filters);
    }),
    allActivity: Ember.computed('results.[]', 'filter', function () {
      const results = this.results || [];
      const filter = this.filter;

      if (!Ember.isEmpty(results)) {
        return R.filter(result => {
          if (filter) {
            const detailValues = R.pipe(R.propOr([], 'details'), R.pluck(['_data']), R.values, R.pluck(['item']))(result._data);
            const values = R.pipe(R.pick(['telephone', 'contact', 'transporterContactPerson', 'waybill', 'address', 'docNo']), R.values, R.concat(detailValues))(result._data);
            const matches = R.filter(value => {
              if (value.indexOf(filter) > -1) {
                return true;
              }

              return false;
            })(values);

            if (matches.length > 0) {
              return true;
            }

            return false;
          }

          return true;
        })(results);
      }

      return [];
    }),
    // total: computed('allActivity.[]', function () {
    //   const isVoid = this.get('constants.documentsStatus.void')
    //   const allActivity = this.allActivity || []
    //   return R.pipe(
    //     R.pluck('_data'),
    //     R.values,
    //     R.reject(doc => doc.status === isVoid),
    //     this.sumPropAndAutoRound(0, 'inclTotal')
    //   )(allActivity)
    // }),
    actions: {
      listByDate(onHideDropdown, dateZ, date) {
        return this.fetchDocumentsTask.perform({
          date,
          dateZ,
          resultsToggle: 'resultsToggle'
        }, onHideDropdown);
      }

    }
  });

  _exports.default = _default;
});