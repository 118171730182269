define("client/pods/components/elements/element-toggle-btn/component", ["exports", "client/mixins/has-dropdown"], function (_exports, _hasDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_hasDropdown.default, {
    tagName: 'span',
    attributeBindings: ['disabled', 'tabindex'],
    tabindex: 0,
    translate: true,
    classNames: ['button', 'button__toggle'],
    classNameBindings: ['disabled', 'toggleIsOn:on'],
    // classNameBindings: ['disabled', 'toggleIsOn:is-link:button__toggle--off', 'isRunning:is-loading'],
    iconClassName: null,
    // defaultToggleState: null, //determine if toggleIsOn should be true/false on init
    toggleIsOn: false,
    // property to toggle
    delegateToggle: false,
    // if true, let toggleAction perform actual toggling on/off
    confirmMsg: null,
    submit: function () {
      if (this.disabled !== true) {
        let confirmed = true;
        const confirmMsg = this.confirmMsg;

        if (confirmMsg) {
          confirmed = window.confirm(confirmMsg);
        }

        if (confirmed) {
          if (!this.delegateToggle) {
            this.toggleProperty('toggleIsOn');
          }

          if (this.onSubmit) {
            this.onSubmit(this);
          }
        }
      }
    },
    click: function (e) {
      this.submit(e);
    },
    keyUp: function (e) {
      if (e.which === 13) {
        this.submit(e);
      }
    }
  });

  _exports.default = _default;
});