define("client/pods/components/documents/document-print/footer-sales-delivery-notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IO+mvCqJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"documents/document-print/footer-sales-signature\",null,[[\"model\",\"hasGroups\"],[[24,[\"model\"]],[24,[\"hasGroups\"]]]]],false],[0,\"\\n\\n\"],[1,[28,\"documents/document-print/footer-sales-terms\",null,[[\"model\",\"selectedTerms\"],[[24,[\"model\"]],[24,[\"selectedTerms\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/document-print/footer-sales-delivery-notes/template.hbs"
    }
  });

  _exports.default = _default;
});