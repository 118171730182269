define("client/pods/components/websites/panels-display/sub-panels-pages/pages-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iYCQJcx5",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"websites/websites-editor/pages-editor\",null,[[\"tab\",\"isEditing\",\"isNew\",\"model\",\"website\",\"pagesCollection\",\"onEdit\",\"toggleShowWebsitePagesNav\",\"onNew\",\"onAfterRemove\",\"onRemove\"],[[24,[\"tab\"]],false,false,[24,[\"model\"]],[24,[\"website\"]],[24,[\"pagesCollection\"]],[24,[\"onEdit\"]],[24,[\"toggleShowWebsitePagesNav\"]],[24,[\"onNew\"]],[24,[\"onAfterRemove\"]],[28,\"perform\",[[23,0,[\"crud\",\"removeRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"onAfter\"],[\"websites/pages\",[24,[\"model\"]],[24,[\"onAfterRemove\"]]]]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-pages/pages-display/template.hbs"
    }
  });

  _exports.default = _default;
});