define("client/pods/components/messages/search-filters/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    tagName: '',

    init() {
      this.crud.addLists(this, ['filters/count', 'filters/sortDirection']);

      this._super(...arguments);
    },

    listByLastestTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.filters;
      filters.reset();
      filters.setData('sort', [{
        by: 'timestampZ',
        direction: 'DESC'
      }]);
      yield this.onSearchRecordsTask.perform({
        filters,
        adapterName: this.adapterName
      });
    })
  });

  _exports.default = _default;
});