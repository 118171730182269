define("client/pods/components/dispatch/import-btn/import-paid/component", ["exports", "ember-concurrency", "client/mixins/crud", "client/mixins/date", "client/mixins/transporter", "client/utils/nventor", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _emberConcurrency, _crud, _date, _transporter, _nventor, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, _transporter.default, {
    crud: Ember.inject.service(),
    excel: Ember.inject.service(),
    tagName: 'span',
    showImportModal: false,
    showImportPaidModal: false,
    isImported: false,
    isPaidImported: false,
    templateFilename: '',
    sheets: null,
    successful: null,
    unsuccessful: null,

    init() {
      this._super(...arguments);

      this.set('importTemplate', [{
        headerValue: '收貨人',
        prop: 'contact',
        required: true
      }, {
        headerValue: '收貨人電話',
        prop: 'telephone',
        required: true
      }, {
        headerValue: '收貨人地址',
        prop: 'address',
        required: true
      }, {
        headerValue: '付款方式',
        prop: 'paymentMethodKey'
      }, {
        headerValue: '總計',
        prop: 'inclTotal'
      }, {
        headerValue: '備註',
        prop: 'notes'
      }, {
        headerValue: '單號',
        prop: 'ref'
      }, {
        headerValue: '託運單號',
        prop: 'waybill',
        required: true
      }]);
      this.crud.addLists(this, ['documents/lists/import/paid/specs']);
      const templateFilename = this.intl.t('import_transporter_template');
      this.set('templateFilename', templateFilename);
      this.set('selectedSheets', Ember.Object.create({
        sheets: []
      }));
      this.resetImportData();
    },

    specsForImportDataHeaders: Ember.computed('crud.lists.documents-lists-import-paid-specs.[]', function () {
      const specsForImportData = R_.dotPath('crud.lists.documents-lists-import-paid-specs')(this) || [];
      return R.pipe(R.pluck('_data'), R.pluck('headerValue'), R.reject(_nventor.default.isNilOrEmpty))(specsForImportData);
    }),
    specsForImportDataValues: Ember.computed('crud.lists.documents-lists-import-paid-specs.[]', function () {
      const specsForImportData = R_.dotPath('crud.lists.documents-lists-import-paid-specs')(this) || [];
      return R.pipe(R.pluck('_data'), R.pluck('value'), R.reject(_nventor.default.isNilOrEmpty))(specsForImportData);
    }),

    resetImportData() {
      this.set('importData', '');
      this.set('successful', []);
      this.set('hasUnsuccessful', false);
      this.set('unsuccessfulImportData', []);
      this.set('isImported', false);
    },

    zipHeadersWithRowData(headers, row) {
      // const importDataHeaderObjects = this.get('importDataHeaders')
      const importDataHeaders = R.pluck('value')(headers);
      const rowData = {};

      _nventor.default.mapIndexed((header, index) => {
        if (!R.isEmpty(header)) {
          rowData[header] = row[index];
        }
      })(importDataHeaders);

      return rowData;
    },

    getSheetData(importDispatchDateZ, sheetObj) {
      const sheetData = R.propOr([], 'data', sheetObj);
      const sheetHeaders = R.propOr([], 'headers', sheetObj); // const importTransporter = this.importTransporter
      // // const importTransporterKey = this.get('importTransporterKey')
      // const importTransporterAccountNumber = this.importTransporterAccountNumber

      return R.pipe(R.map(row => {
        const rowData = this.zipHeadersWithRowData(sheetHeaders, row); // if (importTransporter) {
        //   rowData.transporterLabel = importTransporter
        //   rowData.transporterAccountNumber = importTransporterAccountNumber
        // }
        // rowData.dispatchProcess = 'dispatched'
        // rowData.isDispatched = true
        // if (!rowData.dispatchDateZ) {
        //   rowData.dispatchDateZ = importDispatchDateZ
        // }

        return rowData;
      }))(sheetData);
    },

    importDataTask: (0, _emberConcurrency.task)(function* () {
      const filteredWorkbook = this.filteredWorkbook; // const importTransporter = this.importTransporter
      // const importTransporterKey = this.get('importTransporterKey')
      // const importTransporterAccountNumber = this.importTransporterAccountNumber

      let importDispatchDateZ = this.importDispatchDateZ;
      importDispatchDateZ = this.getStartOfDayZ(importDispatchDateZ);
      const importData = R.pipe(R.mapObjIndexed(sheetObj => {
        return this.getSheetData(importDispatchDateZ, sheetObj);
      }), R.values, R.flatten)(filteredWorkbook);
      const path = 'api/protected/documents/sales/invoices/import/paid';
      const payload = {
        dispatchProcess: 'unprepared',
        batch: importData
      };
      const specsForImportDataValues = this.specsForImportDataValues;

      try {
        const response = yield this.server.call('POST', path, payload);
        this.set('isImported', true);
        const successful = R.pathOr([], ['data', 'successful'], response);
        this.set('successful', successful);
        const unsuccessful = R.pipe(R.pathOr([], ['data', 'unsuccessful']), R.map(row => {
          row.cells = R.values(R.pick(specsForImportDataValues, row));
          return row;
        }), R.values)(response); // const unsuccessfulImportData = this.matchUnsuccessful(importData, unsuccessful) || []

        if (unsuccessful.length > 0) {
          this.set('hasUnsuccessful', true);
          this.set('unsuccessfulImportData', unsuccessful);
        }
      } catch (err) {
        this.set('isImported', false);
        this.setValidationErrors('errors', err);
      }
    }).drop(),
    importParamValues: Ember.computed('crud.lists.documents-lists-import-paid-specs.[]', function () {
      const specs = R_.dotPath('crud.lists.documents-lists-import-paid-specs')(this) || [];
      const values = R.pipe(R.map(R.path(['_data', 'value'])), R.reject(RA.isNilOrEmpty))(specs);
      return values;
    }),
    // matchUnsuccessful (importData, unsuccessful) {
    //   const allowedspecs = this.get('importParamValues')
    //   unsuccessful = R.map(unsuccessfulDoc => {
    //     const toMatchData = {}
    //     R.mapObjIndexed((docData, key) => {
    //       if (R.includes(key, allowedspecs)) {
    //         toMatchData[key] = docData
    //       }
    //       return docData
    //     }, unsuccessfulDoc)
    //     return toMatchData
    //   }, unsuccessful)
    //   return R.filter(row => {
    //     const rowData = this.zipHeadersWithRowData(row)
    //     let isUnsuccessful = false
    //     R.forEach(toMatchData => {
    //       if (!isUnsuccessful) {
    //         isUnsuccessful = R.equals(rowData, toMatchData)
    //       }
    //     }, unsuccessful)
    //     return isUnsuccessful
    //   })(importData)
    // },
    // toDisplayImportData: computed('isImported', 'importData.[]', 'unsuccessfulImportData.[]', function () {
    //   if (!this.get('isImported')) {
    //     return this.get('importData') || []
    //   }
    //   return this.get('unsuccessfulImportData')
    // }),
    successfulCount: Ember.computed('successful.[]', function () {
      const data = this.successful || [];
      return data.length;
    }),
    unsuccessfulCount: Ember.computed('unsuccessfulImportData.[]', function () {
      const data = this.unsuccessfulImportData || [];
      return data.length;
    }),
    createImportTemplateTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      const templateFilename = this.templateFilename;
      const importTemplate = this.get('importTemplate');
      const importTemplateHeaders = R.pluck('headerValue')(importTemplate);
      const worksheets = [];
      worksheets.push({
        sheetName: '物流資料',
        data: [importTemplateHeaders]
      });
      yield this.excel.exportFile({
        worksheets,
        title: templateFilename
      });
      onCloseDialogAction();
    }),

    createWorkbook() {
      const sheets = this.sheets || {};
      const name = this.specsForImportDataHeaders[0];
      return R.mapObjIndexed(sheetData => {
        const sheetObject = Ember.Object.create();
        sheetObject.set('headers', this.matchDataWithHeaders(sheetData));
        sheetData = R.reject(row => {
          if (R.includes(name, row)) {
            return true;
          }

          return false;
        })(sheetData);
        sheetObject.set('data', sheetData);
        return sheetObject;
      })(sheets);
    },

    filteredWorkbook: Ember.computed('sheets.[]', 'workbook', 'selectedSheets.sheets.[]', function () {
      const workbook = this.workbook || {};
      const selectedSheets = this.get('selectedSheets.sheets') || [];
      const allowedWorkbook = {};
      R.mapObjIndexed((sheetObject, sheetName) => {
        if (R.includes(sheetName, selectedSheets)) {
          allowedWorkbook[sheetName] = sheetObject;
          return sheetObject;
        }
      })(workbook);
      return allowedWorkbook;
    }),

    matchDataWithHeaders(data) {
      if (data.length > 0) {
        const importDataHeaders = R.head(data);
        const allowedHeaders = R_.dotPath('crud.lists.documents-lists-import-paid-specs')(this) || [];
        return R.map(importDataHeader => {
          importDataHeader = R.trim(`${importDataHeader}`);
          const found = R.find(R.pathEq(['_data', 'headerValue'], importDataHeader))(allowedHeaders);
          const value = R.pathOr('', ['_data', 'value'], found);
          return Ember.Object.create({
            value
          });
        })(importDataHeaders);
      }

      return false;
    },

    actions: {
      resetImportData() {
        this.resetImportData();
      },

      showImportModal(sheets) {
        this.set('sheets', sheets);
        const sheetNames = R.pipe(R.keys, R.map(sheetName => {
          return Ember.Object.create({
            value: sheetName,
            label: sheetName
          });
        }))(sheets);
        this.set('sheetNames', sheetNames);
        const workbook = this.createWorkbook();
        this.set('workbook', workbook);
        const selectedSheets = this.get('selectedSheets.sheets');
        R.forEach(sheetName => {
          selectedSheets.pushObject(sheetName.value);
        }, sheetNames);
        this.set('showImportModal', true);
      },

      closeImportModal(refresh) {
        const hasSuccessful = R.pipe(R.propOr([], 'successful'), RA.isNotNilOrEmpty)(this);

        if (refresh && this.isImported && hasSuccessful) {
          if (this.onRefresh) {
            this.onRefresh(false, false);
          }
        }

        this.set('showImportModal', false);
      }

    }
  });

  _exports.default = _default;
});