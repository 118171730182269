define("client/helpers/uploadcareSrc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    uploadcare: Ember.inject.service(),

    /**
     * params are unnamed params passed in to the helper. order is important for params
     * hashes are named params
     * hash.defaultImage - defaultImage fileName
     * hash.images - array of fileNames
     * eg. {{helper-name param1 param2 hash1=123 hash2=345}}
     */
    compute(params) {
      const baseUrl = this.uploadcare.get('cdnBase'); // params = R.map(param => {
      //   let array = R.splitEvery(1, param)
      //   if (array[0] === '/') {
      //     array = R.drop(1, array)
      //   }
      //   if (array[array.length - 1] === '/') {
      //     array = R.dropLast(1, array)
      //   }
      //   return R.join('', array)
      // })(params)

      let path = params.join('');
      return baseUrl + '/' + path + '/';
    }

  });

  _exports.default = _default;
});