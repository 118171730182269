define("client/pods/components/documents/btn-split/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    label: '',
    splitQtyLabel: 'dispatched',
    actions: {
      afterSplit(onCloseDialogAction) {
        onCloseDialogAction();
        const onAfterSplit = this.onAfterSplit;

        if (onAfterSplit) {
          onAfterSplit();
        }
      }

    }
  });

  _exports.default = _default;
});