define("client/pods/contacts/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 10,
        query: '',
        match: ['all'],
        statuses: [this.get('constants.status.active')],
        // relationships: ['customer', 'supplier'],
        relationships: [],
        name: '',
        code: '',
        tags: [],
        telephone: '',
        fax: '',
        email: '',
        address: '',
        salesPersons: [],
        sort: [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]
      };
    },

    tagsCount: Ember.computed('_data.tags.[]', function () {
      return this.getPropLength('_data.tags');
    }),
    relationshipsCount: Ember.computed('_data.relationships.[]', function () {
      return this.getPropLength('_data.relationships');
    })
  });

  _exports.default = _default;
});