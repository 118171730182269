define("client/pods/components/settings/settings-editor/3rd-party-editor/component", ["exports", "ramda", "ramda-extension", "ember-concurrency-decorators"], function (_exports, R, R_, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    applicationService: Ember.inject.service('application'),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['google/tracking', 'facebook/pixels', 'paymentMethods/creditCards/banks', 'paymentMethods/installmentPeriods']);
    },

    setDefaultVirtualAccount(defaultVirtualAccountModel) {
      //Reset virtual accounts
      const settingsModel = R.pathOr(null, ['model'])(this);

      if (settingsModel) {
        settingsModel.setBspDefaultVirtualAccount(defaultVirtualAccountModel);
      }
    },

    toggleEcPayPayments() {
      const model = this.model;
      const enabledCreditCard = R.pathOr(false, ['_data', 'tw_payments_ecPay', 'enabledCreditCard'])(model);
      const enabledCreditCardInstallments = R.pathOr(false, ['_data', 'tw_payments_ecPay', 'enabledCreditCardInstallments'])(model);
      const enabledVirtualAccount = R.pathOr(false, ['_data', 'tw_payments_ecPay', 'enabledVirtualAccount'])(model);
      model.setData('tw_payments_ecPay.enabled', enabledCreditCard || enabledCreditCardInstallments || enabledVirtualAccount);
    },

    selectBank(key, bank) {
      const bankLabel = bank.getData('label');
      const model = this.model;
      model.setData(`${key}.bankLabel`, bankLabel);
    },

    toggleEInvoice(provider, value) {
      const model = this.model;
      const systemleadBeta = R_.dotPath('_data.beta.systemlead')(model);
      const ecPayBeta = R_.dotPath('_data.beta.ecPay')(model);

      if (systemleadBeta && ecPayBeta) {
        if (provider === 'systemlead') {
          if (value) {
            model.setData('tw_eInvoice_ecPay.enabled', false);
          }

          if (provider === 'ecPay') {
            if (value) {
              model.setData('tw_eInvoice_systemlead.enabled', false);
            }
          }
        }
      }
    },

    ecPayInstallmentPeriods: Ember.computed('crud.lists.paymentMethods-installmentPeriods.[]', function () {
      const periods = this.crud.lists['paymentMethods-installmentPeriods'] || [];
      const allowed = [3, 6, 12, 18, 24];
      return R.filter(period => {
        return R.includes(period?._data?.value)(allowed);
      })(periods);
    })
  }, (_applyDecoratedDescriptor(_obj, "setDefaultVirtualAccount", [_dec], Object.getOwnPropertyDescriptor(_obj, "setDefaultVirtualAccount"), _obj), _applyDecoratedDescriptor(_obj, "toggleEcPayPayments", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleEcPayPayments"), _obj), _applyDecoratedDescriptor(_obj, "selectBank", [_dec3], Object.getOwnPropertyDescriptor(_obj, "selectBank"), _obj), _applyDecoratedDescriptor(_obj, "toggleEInvoice", [_dec4], Object.getOwnPropertyDescriptor(_obj, "toggleEInvoice"), _obj)), _obj)));

  _exports.default = _default;
});