define("client/pods/workflows/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _model, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    intl: Ember.inject.service(),

    defaults() {
      return {
        related: [],
        stages: []
      };
    },

    // name: computed('_data.name', function () {
    //   const isGenerated = R.path(['_data','isGenerated'])(this)
    //   const title = R.path(['_data','name'])(this)
    //   if (title && isGenerated) {
    //     return this.intl.t(title)
    //   }
    //   return title
    // }),
    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.stages = this._transformStages(data);
      data.related = this._transformRelated(data);
      data.users = this._transformUsers(data);
      data.tags = R.pipe(R.propOr([], 'tags'), _nventor.default.safeHeadOr(''))(data);

      this._super(...arguments);
    },

    _transformStages(data) {
      return R.pipe(R.propOr([], 'stages'), R.reject(RA.isNilOrEmpty), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'workflows/stages',
          data
        });
      }))(data);
    },

    addStage() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data = data || {};
      const stages = this.getOrSetData('stages', []); // get last index

      let index = 0;
      const last = stages.get('lastObject');

      if (RA.isNotNilOrEmpty(last)) {
        index = last?._data?.index + 1;
      }

      data.index = index;
      const stage = this.dataManager.setAsRecord({
        adapterName: 'workflows/stages',
        data
      });
      stages.pushObject(stage);
      this.setData('stages', stages);
      return stage;
    },

    removeStage(stage) {
      const stages = this.getData('stages') || [];
      stages.removeObject(stage);
    },

    removeUnassignedStage() {
      const stages = this.getData('stages') || [];
      const unassigned = R.find(R.pathEq(['_data', 'stageId'], 'unassigned'))(stages);
      stages.removeObject(unassigned);
    },

    getNewItemData() {
      let stages = this.getData('stages') || [];
      stages = R.reject(R.pathEq(['_data', 'stageId'], 'unassigned'))(stages);
      const firstStage = stages.get('firstObject');

      if (RA.isNotNilOrEmpty(firstStage)) {
        return {
          workflowKey: this._data?._key,
          users: this._data?.users,
          stageId: firstStage._data?.stageId
        };
      }

      return {};
    }

  }, (_applyDecoratedDescriptor(_obj, "addStage", [_dec], Object.getOwnPropertyDescriptor(_obj, "addStage"), _obj), _applyDecoratedDescriptor(_obj, "removeStage", [_dec2], Object.getOwnPropertyDescriptor(_obj, "removeStage"), _obj), _applyDecoratedDescriptor(_obj, "removeUnassignedStage", [_dec3], Object.getOwnPropertyDescriptor(_obj, "removeUnassignedStage"), _obj), _applyDecoratedDescriptor(_obj, "getNewItemData", [_dec4], Object.getOwnPropertyDescriptor(_obj, "getNewItemData"), _obj)), _obj)));

  _exports.default = _default;
});