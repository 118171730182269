define("client/pods/dispatch/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate(data, attrs) {
      // Override old paymentData with payment record from payment collection
      this._super(data, attrs);
    },

    translateAccountName: Ember.computed('_data.transporterExtension', function () {
      return R.pathEq(['model', '_data', 'transporterExtension'], 't-cat')(this);
    }),
    waybill: Ember.computed('_data.waybill', function () {
      return R.pipe(R.pathOr([], ['model', '_data', 'waybill']), R.replace(/,/g, '<br>'))(this);
    }),
    latestRev: Ember.computed('model._data._rev', function () {
      return this.model._data._rev;
    }),
    isReadyToPrint: Ember.computed('model._data.transporterWaybillRecords.@each.isReadyToPrint', function () {
      return this.model?._data?.transporterWaybillRecords?.[0]?.isReadyToPrint === true;
    })
  });

  _exports.default = _default;
});