define("client/pods/components/messages/tab-container/messages-window/message-bubble/image-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q52f4RQB",
    "block": "{\"symbols\":[],\"statements\":[[5,\"modals/modal-dialog\",[],[[\"@modalDialogIsVisible\",\"@onToggle\"],[[22,\"isShowPreview\"],[28,\"fn\",[[28,\"mut\",[[24,[\"isShowPreview\"]]],null]],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"img\",true],[11,\"src\",[22,\"messageUrl\"]],[10,\"class\",\"message-image\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"figure\",true],[10,\"class\",\"image is-128x128\"],[8],[0,\"\\n  \"],[7,\"img\",true],[11,\"src\",[22,\"messageUrl\"]],[10,\"class\",\"message-image\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"showPreview\"],null]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/messages/tab-container/messages-window/message-bubble/image-type/template.hbs"
    }
  });

  _exports.default = _default;
});