define("client/mixins/templates", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const self = {
    replaceTemplate(template) {
      let templateImages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      this.setData('template', template);
      this.setData('templateImages', templateImages);
    },

    insertTemplate(template) {
      let templateImages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      const currentTemplate = this.getData('template');
      const currentTemplateImages = this.getData('templateImages') || [];
      let newTemplate = '';

      if (currentTemplate) {
        newTemplate = `${currentTemplate}<br>`;
      }

      newTemplate = `${newTemplate} ${template}`;
      const newTemplateImages = R.concat(currentTemplateImages, templateImages);
      this.setData('template', newTemplate);
      this.setData('templateImages', newTemplateImages);
    }

  };

  var _default = Ember.Mixin.create(self);

  _exports.default = _default;
});