define("client/helpers/findBy", ["exports", "ramda", "ramda-extension"], function (_exports, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params, hash) {
      const value = params[0];
      const array = R.propOr('', 'array')(hash);
      const dotPath = R.propOr('', 'dotPath')(hash);
      const defaultValue = R.propOr('', 'defaultValue')(hash);
      return R.find(R_.dotPathEq(dotPath, value))(array) || defaultValue;
    }

  });

  _exports.default = _default;
});