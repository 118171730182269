define("client/pods/components/dispatch/export-excel-btn/component", ["exports", "ember-concurrency", "client/mixins/date", "client/mixins/big", "client/mixins/adapters-new", "client/mixins/crud", "client/utils/nventor", "ramda-extension", "ramda-adjunct", "ramda"], function (_exports, _emberConcurrency, _date, _big, _adaptersNew, _crud, _nventor, R_, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_adaptersNew.default, _big.default, _crud.default, _date.default, (_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    constants: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    excel: Ember.inject.service(),
    bigService: Ember.inject.service('big'),
    exportService: Ember.inject.service('export'),
    dateService: Ember.inject.service('date'),
    tagName: '',
    isExporting: false,
    nextDispatchProcessCode: '',
    exportOrdersTotal: true,
    exportOrdersDetails: true,
    exportPayOnDeliveryOrders: true,
    exportPayOnDeliveryTransporter: true,
    exportPaidOrders: true,
    exportPaidTransporter: true,
    exportTransportersTotal: true,
    isSaveBtnClicked: false,
    dispatchProcesses: null,
    documentStatuses: null,
    filtersAdapterName: 'channels/shop/campaigns/filters',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['channels/shop/campaigns/commissions-date-ranges', 'dispatch/lists/processes', 'dispatch/lists/exportByStatuses', 'dispatch/lists/export-file-type', 'documents/lists/statuses', 'documents/lists/statuses']);
      this.set('documentStatuses', [this.constants.documentsStatus.final]);
    },

    setup() {
      this.set('customSource', this?.source || '');
      this.set('customSourceKey', this?.sourceKey || '');
      this.set('selectedDispatchProcess', []);
      this.set('useOriginalCashCollectionInclTotal', true);
      this.set('filename', this.getDefaultFilename());
      this.set('dispatchProcesses', []);
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      let selectedPeriod = 'campaignPeriod';
      let selectedDispatchPeriod = 'campaignPeriod';

      if (!this.hasEndDate) {
        selectedPeriod = 'lastMonth';
        selectedDispatchPeriod = 'lastMonth';
      }

      this.set('selectedPeriod', selectedPeriod);
      this.set('selectedDispatchPeriod', selectedDispatchPeriod);
      this.set('thisMonthPeriod', this.dateService.getPeriod());
      this.set('lastMonthPeriod', this.dateService.getPreviousPeriod());
      this.set('customDateStartZ', this.thisMonthPeriod.monthStart);
      this.set('customDateEndZ', this.thisMonthPeriod.monthEnd);
      this.set('customDispatchDateStartZ', this.thisMonthPeriod.monthStart);
      this.set('customDispatchDateEndZ', this.thisMonthPeriod.monthEnd);
      const campaignFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('campaignFilters', campaignFilters);

      if (this.dispatchProcess) {
        const model = this.crud.setupDirty({
          adapterName: 'dispatch/lists/processes',
          model: this.dispatchProcess
        });
        const exportFileType = model.getData('exportFileType');
        const exportFileTypeLabel = model.getData('exportFileType');

        if (RA.isNilOrEmpty(exportFileType) || RA.isNilOrEmpty(exportFileTypeLabel)) {
          model.setData('exportFileType', 'xlsx');
          model.setData('exportFileTypeLabel', 'xlsx');
        }

        this.set('model', model);
      }

      const defaultExportSheets = this.get('model._data.defaultExportSheets') || [];
      const defaultExportCustomSheets = this.get('model._data.defaultExportCustomSheets') || {};
      this.set('sharedWorksheets', defaultExportSheets);
      this.set('customWorksheets', defaultExportCustomSheets);
      const dispatchFilters = this.search.setupFilters({
        adapterName: 'dispatch/export/filters',
        defaultFiltersData: {
          count: 1000,
          dispatchProcess: 'customSelected',
          exportByStatus: 'byDispatchProcesses',
          sort: [{
            by: 'transporterLabel',
            direction: 'ASC'
          }, {
            by: 'transporterAccount',
            direction: 'ASC'
          }, {
            by: 'timestampZ',
            direction: 'ASC'
          }],
          // Show isPos on export reports
          isPos: false
        }
      });
      this.set('dispatchFilters', dispatchFilters);
      this.set('selectedDispatchProcess', []);
      this.set('selectedDispatchProcessCode', []);
      const isDispatchedDispatchProcess = R.find(R.pathEq(['_data', 'isDispatched'], true))(dispatchProcesses);
      this.dispatchProcesses.pushObject(isDispatchedDispatchProcess?._data?.code);
      this.addDispatchProcess(isDispatchedDispatchProcess); // do it twice, once for normal date, once for dispatch date

      this.updateDateParams(false);
      this.updateDateParams(true);
    },

    getDefaultFilename() {
      const today = this.formatDate(this.getToday(), 'YYYYMMDD');
      const returnString = `export-${today}`;
      return returnString;
    },

    fetchExportDataTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        documentStatuses,
        dispatchProcess,
        period,
        sourceKey
      } = _ref;
      return function* () {
        let dispatchProcessesCriterias = {};
        R.forEach(dispatchProcess => {
          const criterias = R_.dotPathOr([], '_data.criterias.dispatchProcesses')(dispatchProcess);
          dispatchProcessesCriterias.dispatchProcesses = R.concat(dispatchProcessesCriterias?.dispatchProcesses || [], criterias);
        })(_this.selectedDispatchProcess);
        const dispatchFilters = _this.dispatchFilters;
        dispatchFilters.setData('sourceKey', sourceKey);
        const exportByStatus = dispatchFilters.getData('exportByStatus');

        if (exportByStatus === 'byDispatchProcesses') {
          dispatchFilters.setData('dispatchProcessesCriterias', dispatchProcessesCriterias);
          dispatchFilters.setData('documentStatuses', []);
        } else {
          dispatchFilters.setData('documentStatuses', documentStatuses);
          dispatchFilters.setData('dispatchProcessesCriterias', []);
        }

        const dateParams = _this.dateParams || {};

        if (_this.hasUndispatchedSelected) {
          const dateStart = _this.dateService.getStartOfDayZ(dateParams?.dateStart || '');

          const dateEnd = _this.dateService.getEndOfDayZ(dateParams?.dateEnd || '');

          dispatchFilters.setData('dateStart', dateStart);
          dispatchFilters.setData('dateEnd', dateEnd);
        }

        if (_this.hasDispatchedSelected) {
          const dispatchDateStart = _this.dateService.getStartOfDayZ(dateParams?.dispatchDateStart || '');

          const dispatchDateEnd = _this.dateService.getEndOfDayZ(dateParams?.dispatchDateEnd || '');

          dispatchFilters.setData('dispatchDateStart', dispatchDateStart);
          dispatchFilters.setData('dispatchDateEnd', dispatchDateEnd);
        }

        const adapterName = 'dispatch/export';
        let results = yield _this.crud.searchRecordsTask.perform({
          adapterName,
          filters: dispatchFilters,
          rawResponse: true
        });
        results = R.map(result => {
          const docType = result?.docType || '';
          const docName = result?.docName || '';

          if (R.equals(docType, 'sales') && R.equals(docName, 'invoices')) {
            // modify e-invoice data for export
            const eInvoices = R.clone(result?.eInvoices || []);
            const newEInvoices = {
              // only need number for now
              numbers: R.pipe(R.pluck('number'), R.reject(RA.isNilOrEmpty), R.join(','))(eInvoices)
            };
            result.eInvoices = newEInvoices;
          }

          return result;
        })(results);
        return results;
      }();
    }),
    fetchSpecsTask: (0, _emberConcurrency.task)(function* () {
      const specsCollection = yield this.crud.findTask.perform({
        adapterName: 'specs/collection'
      });
      this.set('sharedSpecs', specsCollection._data.shared);
      this.set('specsCollection', specsCollection);
    }),
    exportTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      yield this.fetchSpecsTask.perform();

      if (this.hasUpdateStatus && !this.nextDispatchProcessCode) {
        this.set('errorMsg', 'please select');
        return false;
      }

      this.set('isExporting', true);
      let filename = this.filename;

      if (!filename) {
        filename = this.getDefaultFilename();
      }

      const dispatchProcess = this.dispatchProcess;
      const period = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd
      };
      const sourceKey = this.customSourceKey;
      const documentStatuses = this.documentStatuses || [];
      const specsModel = this.crud.setupNewRecord({
        adapterName: 'specs'
      }); // for sales report, default false

      const isGroupDocs = R.pathOr(false, ['model', '_data', 'isGroupDocs'])(this);
      const options = {
        showSetDetails: R.pathOr(false, ['model', '_data', 'showSetDetails'])(this),
        hideSetParents: R.pathOr(false, ['model', '_data', 'hideSetParents'])(this),
        availableNormalSpecs: R.pathOr([], ['availableNormalSpecs'])(specsModel),
        detailsProp: 'details',
        isGroupDocs
      };
      let isGroupDocsFromDifferentShops = R.pathOr(false, ['model', '_data', 'isGroupDocsFromDifferentShops'])(this);

      if (!sourceKey) {
        isGroupDocsFromDifferentShops = true;
      }

      let serialized = yield this.fetchExportDataTask.perform({
        documentStatuses,
        dispatchProcess,
        period,
        sourceKey
      }); // don't change the order _transformGroupDocsFromDifferentShops, then _transformGroupedDocs

      serialized = this._transformGroupDocsFromSpecificShops({
        serialized,
        isGroupDocsFromDifferentShops,
        sourceKey
      });
      serialized = this._transformGroupedDocs({
        serialized,
        isGroupDocs
      });
      const useOriginalCashCollectionInclTotal = this.useOriginalCashCollectionInclTotal;
      serialized = this.exportService.calculateDocsNetReturns({
        serialized,
        useOriginalCashCollectionInclTotal,
        options
      });
      const serializedCustomSpecs = this.specsCollection.getSerializedByProp({
        prop: 'custom',
        worksheets: this.customWorksheets,
        selected: true
      });
      let serializedSharedSpecs = this.specsCollection.getSerializedByProp({
        prop: 'shared',
        worksheets: this.sharedWorksheets,
        selected: false
      });
      let newSerializedCustomSpecs = [];
      R.forEach(customWorksheet => {
        const key = R_.dotPathOr('', '_data.value', customWorksheet);
        R.forEach(serializedCustomSpec => {
          if (R.equals(key, serializedCustomSpec._key)) {
            newSerializedCustomSpecs = R.append(R.merge(customWorksheet, serializedCustomSpec), newSerializedCustomSpecs);
          }
        })(serializedCustomSpecs);
      })(this.customWorksheets);
      const worksheets = []; // const serialized = this.resultsData || []
      // @TODO: move the logic into 3rdParty service

      const showShopDotComCommissions = R.pathOr(null, ['model', 'settings', 'settingsModel', '_data', 'thirdParty', 'shopDotCom', 'enabled'])(this);

      if (!showShopDotComCommissions) {
        serializedSharedSpecs = R.map(specs => {
          specs.specs = R.pipe(R.propOr([], 'specs'), R.map(specRow => {
            return R.reject(R.propEq('prop', 'shopDotComCommission'))(specRow);
          }))(specs);
          return specs;
        })(serializedSharedSpecs);
      }

      R.forEach(worksheet => {
        const method = R.prop('method')(worksheet);

        if (method) {
          const sharedSheetToPush = this.exportService.exportByMethod({
            specs: worksheet,
            docData: serialized,
            options
          });
          worksheets.push(sharedSheetToPush);
        }
      })(serializedSharedSpecs);
      R.forEach(worksheet => {
        const method = R.prop('method')(worksheet);

        if (method) {
          const customSheetToPush = this.exportService.exportByMethod({
            specs: worksheet,
            docData: serialized,
            options
          });
          worksheets.push(customSheetToPush);
        }
      })(newSerializedCustomSpecs);

      if (!R.isEmpty(worksheets)) {
        yield this.excel.exportFile({
          worksheets,
          title: filename,
          bookType: this?.model?._data?.exportFileType,
          bookTypeLabel: this?.model?._data?.exportFileTypeLabel
        });

        if (this.hasUpdateStatus) {
          yield this.setDispatchProcessAfterExportTask.perform();
        }

        this.set('isExporting', false);
        onCloseDialogAction();
      } else {
        this.set('isExporting', false);
        const msg = this.intl.t('no data to export');
        window.alert(msg);
      }
    }).drop(),

    _exportOrders(specs) {
      let detailsSpecs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      let results = arguments.length > 2 ? arguments[2] : undefined;
      let hasSummary = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      const columns = R.pluck('column', specs);
      let detailProps = false;

      if (!R.isEmpty(detailsSpecs)) {
        detailProps = R.pluck('prop', detailsSpecs);
      }

      let index = 0; // let totalQty = 0

      let data = R.pipe(R.map(doc => {
        index = index + 1;
        let detailsRows = [];

        if (this.exportOrdersDetails) {
          detailsRows = R.propOr([], 'details', doc);
        }

        let inclTotalBig = this.newBig(0); // detailsRows = R.pipe(
        // @TODO: filter by product/tag/supplier
        // R.filter(
        //   detail => {
        //     if (R.pathEq(['_data', 'itemKey'], '3040094470030', detail)) {
        //       totalQty = totalQty + parseInt(R.path(['_data', 'qty'], detail))
        //     }
        //     return R.pathEq(['_data', 'itemKey'], '3040094470030', detail) || R.pathEq(['_data', 'item'], '運費', detail)
        //   }
        // ),

        detailsRows = R.map(detailData => {
          inclTotalBig = inclTotalBig.plus(detailData.postDiscInclAmt);
          return this._getDataBySpecs({
            data: detailData
          }, detailsSpecs);
        })(detailsRows); // return R.pipe(
        //   R.pick(detailProps),
        //   R.values,
        //   R.concat(['']),
        //   R.concat([''])
        // )(detailData)
        // })
        // )(detailsRows)
        // }

        const docInfoRow = this._getDataBySpecs({
          data: doc,
          inclTotalBig,
          index
        }, specs);

        if (detailProps) {
          return R.concat([docInfoRow], detailsRows);
        } else {
          return [docInfoRow];
        }
      }), R.unnest, R.concat([columns]))(results);

      if (hasSummary && detailProps) {
        data = this._summaryRows(data, results);
      }

      return data;
    },

    _summaryRows(data, results) {
      const intl = this.intl;

      const groupByItem = doc => {
        const itemKey = R.prop('itemKey')(doc);

        if (itemKey) {
          return itemKey;
        }

        return R.prop('item')(doc);
      };

      let grandTotal = 0;
      data = R.pipe(R.pluck('details'), R.flatten, R.groupBy(groupByItem), R.mapObjIndexed(groupData => {
        // const groupTotalQty = parseInt(this.sumPropAndAutoRound(0, 'qty', groupData)) || 0
        const groupTotalInclAmt = parseInt(this.sumPropAndAutoRound(0, 'postDiscInclAmt', groupData)) || 0;
        const groupedByPrice = R.groupBy(R.prop('discInclPrice'))(groupData);
        const item = R.pipe(R.head, R.propOr('', 'item'))(groupData);
        const itemCode = R.pipe(R.head, R.propOr('', 'itemCode'))(groupData);
        const byPricesRows = R.pipe(R.mapObjIndexed((byPriceGroupData, groupPrice) => {
          const groupTotalQty = parseInt(this.sumPropAndAutoRound(0, 'qty', byPriceGroupData)) || 0;
          const groupTotalInclAmt = parseInt(this.sumPropAndAutoRound(0, 'postDiscInclAmt', byPriceGroupData)) || 0;
          groupPrice = R.pipe(this.bigService.toFixed(2), parseInt)(groupPrice);
          return ['', item, itemCode, groupTotalQty, groupPrice, groupTotalInclAmt];
        }), R.values)(groupedByPrice);
        grandTotal = grandTotal + groupTotalInclAmt;
        return byPricesRows; // let mainRow = [
        //   groupTotalQty,
        //   item,
        //   itemCode,
        //   groupTotalInclAmt
        // ]
        // // if (byPricesRows.length > 1) {
        // mainRow = R.concat([mainRow])(byPricesRows)
        // return mainRow
        // }
        // return [mainRow]
      }), R.values, R.unnest, R.concat([[], ['', intl.t('product'), intl.t('code'), intl.t('qty'), intl.t('price'), intl.t('inclusive amount')]]), R.concat(data), R.append([]))(results);
      data = R.append(['', '', '', intl.t('total'), grandTotal], data);
      return data;
    },

    _exportSummary(today, specs) {
      let detailSpecs = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      let results = arguments.length > 3 ? arguments[3] : undefined;
      let hasSummary = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      // const intl = this.get('intl')
      const allSpecs = R.concat(specs, detailSpecs);
      const columns = R.pluck('column', allSpecs); // const props = R.pluck('prop', specs)

      if (!R.is(Array)(detailSpecs)) {
        detailSpecs = [detailSpecs];
      }

      let detailProps = false;

      if (!R.isEmpty(detailSpecs)) {
        detailProps = R.pipe(R.pluck('prop'), R.uniq)(detailSpecs);
      }

      let index = 0; // let totalQty = 0

      const data = R.pipe(R.map(doc => {
        index = index + 1;
        let detailsRows = R.propOr([], 'details', doc);
        detailsRows = R.pipe(R.map(R.pick(['item', 'qty'])), R.groupBy(R.prop('item')), R.mapObjIndexed(group => {
          return R.reduce((acc, detail) => {
            acc.item = detail.item;
            const qty = parseInt(detail.qty);
            acc.qty = qty + acc.qty;
            return acc;
          }, {
            qty: 0
          })(group);
        }), R.values, R.flatten)(detailsRows);
        detailsRows = this._getDataBySpecs({
          data: detailsRows
        }, detailSpecs);

        const docInfoRow = this._getDataBySpecs({
          data: doc,
          index,
          today
        }, specs);

        if (detailProps) {
          return [R.concat([docInfoRow[0], docInfoRow[1]], detailsRows[0])];
        } else {
          return [docInfoRow];
        }
      }), R.unnest, R.concat([columns]))(results);
      return data;
    },

    _getDataBySpecs(_ref2, specs) {
      let {
        data,
        inclTotalBig = false,
        index,
        today
      } = _ref2;
      return R.map(spec => {
        let props = R.prop('prop', spec);

        if (!R.is(Array, props)) {
          props = [props];
        }

        const values = R.map(prop => {
          return this._getPropData({
            today,
            index,
            data,
            spec,
            prop,
            inclTotalBig
          });
        })(props);

        if (props.length > 1) {
          const joinWith = R.prop('joinWith', spec) || '-';
          return R.pipe(R.reject(RA.isNilOrEmpty), R.join(joinWith))(values);
        }

        return _nventor.default.safeHeadOr('', values);
      }, specs);
    },

    _getPropData(_ref3) {
      let {
        today,
        index,
        data,
        spec,
        prop,
        inclTotalBig
      } = _ref3;
      const intl = this.intl;

      if (prop == null) {
        return '';
      }

      if (spec.returnProp) {
        return R.propOr('', spec.returnProp)(data);
      }

      const transform = R.prop('transform')(spec);
      let value = R_.dotPathOr('', prop, data);

      if (prop === '_index_') {
        value = index;
      }

      if (prop === '_today_') {
        return today;
      }

      if (prop === 'paymentMethodKey') {
        const v = R_.dotPathOr('', prop, data);

        if (v) {
          value = intl.t(v);
        }
      }

      if (prop === '_inclTotal_' && inclTotalBig) {
        value = inclTotalBig.toFixed(0);
      }

      if (transform) {
        value = transform(value, data);
      }

      return value;
    },

    exportData() {
      let {
        results,
        filterByProp,
        filterValue,
        isReject = false,
        fromNo,
        toNo
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (fromNo != null && toNo != null) {
        results = R.pipe(R.drop(fromNo), R.take(toNo))(results);
      }

      let filterByPath = filterByProp;

      if (!R.is(Array, filterByProp)) {
        filterByPath = [filterByProp];
      }

      if (filterByProp) {
        if (isReject) {
          results = R.reject(R.pathEq(filterByPath, filterValue))(results);
        } else {
          results = R.filter(R.pathEq(filterByPath, filterValue))(results);
        }
      }

      return results;
    },

    hasUpdateStatus: Ember.computed('exportOption', function () {
      if (this.exportOption === 'exportAndUpdateStatus') {
        return true;
      }

      return false;
    }),
    hasDispatchedSelected: Ember.computed('dispatchProcesses.[]', 'dispatchFilters._data.exportByStatus', function () {
      const exportByStatus = R.pathOr('', ['dispatchFilters', '_data', 'exportByStatus'])(this);

      if (R.equals(exportByStatus, 'byDispatchProcesses')) {
        const dispatchProcesses = this?.dispatchProcesses || [];

        if (R.includes('dispatched', dispatchProcesses)) {
          return true;
        }
      }

      return false;
    }),
    hasUndispatchedSelected: Ember.computed('dispatchProcesses.[]', 'dispatchFilters._data.exportByStatus', function () {
      const exportByStatus = R.pathOr('', ['dispatchFilters', '_data', 'exportByStatus'])(this);

      if (R.equals(exportByStatus, 'byDispatchProcesses')) {
        let dispatchProcesses = R.reject(R.equals('dispatched'))(this?.dispatchProcesses || []);

        if (RA.isNotNilOrEmpty(dispatchProcesses)) {
          return true;
        }
      } else if (R.equals(exportByStatus, 'byDocumentsStatuses')) {
        return true;
      }

      return false;
    }),
    allowExport: Ember.computed('hasDispatchedSelected', 'hasUndispatchedSelected', 'dateParams', function () {
      const hasDispatchedSelected = this.hasDispatchedSelected;
      const hasUndispatchedSelected = this.hasUndispatchedSelected;
      const dateParams = this.dateParams || {};
      const dateStart = this.dateService.getStartOfDayZ(dateParams?.dateStart || '');
      const dateEnd = this.dateService.getEndOfDayZ(dateParams?.dateEnd || '');
      const dispatchDateStart = this.dateService.getStartOfDayZ(dateParams?.dispatchDateStart || '');
      const dispatchDateEnd = this.dateService.getEndOfDayZ(dateParams?.dispatchDateEnd || '');

      if (hasDispatchedSelected) {
        if (RA.isNilOrEmpty(dispatchDateStart) || RA.isNilOrEmpty(dispatchDateEnd)) {
          return false;
        }

        return true;
      }

      if (hasUndispatchedSelected) {
        if (RA.isNilOrEmpty(dateStart) || RA.isNilOrEmpty(dateEnd)) {
          return false;
        }

        return true;
      }

      return false;
    }),
    // resultsData: computed('filteredResults.[]', function () {
    //   const results = this.filteredResults
    //   return R.pipe(
    //     R.map(result => {
    //       const model = result.model
    //       return this.serialize(model)
    //     })
    //   )(results)
    // }),
    isValidationError: Ember.computed('sourceKey', 'dispatchProcesses.[]', 'documentStatuses', function () {
      const sourceKey = this.customSourceKey;
      const selectedDispatchProcess = this.selectedDispatchProcess;
      const documentStatuses = this.documentStatuses;

      if (RA.isNilOrEmpty(selectedDispatchProcess) && RA.isNilOrEmpty(this.documentStatuses)) {
        // if (RA.isNilOrEmpty(sourceKey) || RA.isNilOrEmpty(selectedDispatchProcess)) {
        return true;
      }

      return false;
    }),

    addDispatchProcess(dispatchProcess) {
      let selectedDispatchProcess = this.get('selectedDispatchProcess') || [];
      selectedDispatchProcess = R.append(dispatchProcess, selectedDispatchProcess);
      this.set('selectedDispatchProcess', selectedDispatchProcess);
    },

    _sumProperty(_ref4) {
      let {
        property,
        docData,
        groups = []
      } = _ref4;
      const roundingCurrency = docData.roundingCurrency || 0;
      let totalBig = this.newBig(docData[property]);
      R.forEach(group => {
        const groupTotalBig = this.newBig(group[property]);
        totalBig = totalBig.add(groupTotalBig);
      })(groups);
      return totalBig.toFixed(roundingCurrency);
    },

    _transformGroupDocsFromSpecificShops(_ref5) {
      let {
        serialized,
        isGroupDocsFromDifferentShops = true,
        sourceKey = ''
      } = _ref5;

      if (!sourceKey) {
        return serialized;
      }

      return R.map(docData => {
        const groups = R.propOr([], 'groups')(docData);
        let newGroups = groups;

        if (sourceKey) {
          // @NOTE: need to remove child docs inside master, because it cause duplicate (API dont use aql.filter('d.isGrouped != true')) when have sourceKey
          // need to reject only from same shop
          newGroups = R.reject(R.propEq('sourceKey', sourceKey))(groups);
        }

        if (!isGroupDocsFromDifferentShops) {
          newGroups = R.filter(R.propEq('sourceKey', sourceKey))(newGroups);
        }

        docData.groups = newGroups;
        return docData;
      })(serialized);
    },

    _transformGroupedDocs(_ref6) {
      let {
        serialized,
        isGroupDocs = true
      } = _ref6;
      const toSumProps = ['cashCollectionInclTotal', 'discExclTotal', 'discInclTotal', 'exclTotal', 'exclTotalOnSale', 'inclTotal', 'inclTotalOnSale', 'netInclTotal', 'paidInclTotal', 'preDiscExclTotal', 'preDiscInclTotal', 'postDiscExclTotal', 'postDiscInclTotal', 'returnRewardAdjustmentAmount', 'returnRewardAdjustmentPoints', 'rewardAmountTotal', 'rewardAmountTotalWithoutPromoCode', 'rewardExclTotal', 'rewardInclTotal', 'rewardPointApplied', 'rewardPointEarned', 'taxTotal'];

      if (isGroupDocs) {
        serialized = R.map(docData => {
          const groups = R.propOr([], 'groups')(docData);
          const paymentMethodKey = R.propOr('', 'paymentMethodKey')(docData);
          let details = R.propOr([], 'details')(docData);
          let paymentMethodKeys = [paymentMethodKey];
          let unpaidInclTotal = this.newBig(0);

          if (docData.paymentMethodKey === 'cash') {
            unpaidInclTotal = this.newBig(docData.cashCollectionInclTotal);
          }

          R.forEach(group => {
            const groupDetails = R.propOr([], 'details')(group);
            details = R.concat(details, groupDetails);
            const groupPaymentMethodKey = R.propOr('', 'paymentMethodKey')(group);
            paymentMethodKeys = R.append(groupPaymentMethodKey, paymentMethodKeys);

            if (group.paymentMethodKey === 'cash') {
              unpaidInclTotal = unpaidInclTotal.add(group.cashCollectionInclTotal);
            }
          })(groups);
          R.forEach(property => {
            docData[property] = this._sumProperty({
              property,
              docData,
              groups
            });
          })(toSumProps);
          docData.details = details;
          docData.paymentMethodKey = R.uniq(paymentMethodKeys) || [];
          docData.unpaidInclTotal = unpaidInclTotal.toFixed(docData.roundingCurrency);
          return docData;
        })(serialized);
      } else if (!isGroupDocs) {
        serialized = R.pipe(R.reduce((obj, docData) => {
          const groups = R.propOr([], 'groups')(docData);
          const docDataMaster = [R.omit(['groups'], docData)]; // should be array

          const docDataMasterAndChilds = R.concat(docDataMaster, groups);
          return R.concat(obj, docDataMasterAndChilds);
        }, []), R.flatten)(serialized);
      }

      return serialized;
    },

    commissionDateRanges: Ember.computed('crud.lists.channels-shop-campaigns-commissions-date-ranges', 'hasEndDate', function () {
      const commissionDateRanges = this.crud?.lists['channels-shop-campaigns-commissions-date-ranges'] || [];
      const hasEndDate = this.hasEndDate;

      if (!hasEndDate) {
        return R.reject(model => R.equals(model?._data?.value, 'campaignPeriod'))(commissionDateRanges);
      }

      return commissionDateRanges;
    }),
    hasEndDate: Ember.computed('model._data.dateEndZ', function () {
      const periodDateEndZ = this?.campaignModel?._data?.dateEndZ;

      if (RA.isNilOrEmpty(periodDateEndZ)) {
        return false;
      }

      return true;
    }),

    updateDateParams(isDispatchDate) {
      let customDateStartParam = 'customDateStartZ';
      let customDateEndParam = 'customDateEndZ';
      let shownDateStartParam = 'shownDateStart';
      let shownDateEndParam = 'shownDateEnd';
      let dateStartParam = 'dateStart';
      let dateEndParam = 'dateEnd';
      let selectedPeriodParam = 'selectedPeriod';

      if (isDispatchDate) {
        customDateStartParam = 'customDispatchDateStartZ';
        customDateEndParam = 'customDispatchDateEndZ';
        shownDateStartParam = 'shownDispatchDateStart';
        shownDateEndParam = 'shownDispatchDateEnd';
        dateStartParam = 'dispatchDateStart';
        dateEndParam = 'dispatchDateEnd';
        selectedPeriodParam = 'selectedDispatchPeriod';
      }

      const model = this?.campaignModel || {};
      const selectedPeriod = this.get(selectedPeriodParam);
      let periodDateStartZ;
      let periodDateEndZ;

      if (RA.isNotNilOrEmpty(model)) {
        periodDateStartZ = model._data.dateStartZ;
        periodDateEndZ = model._data.dateEndZ;
      }

      const customDateStartZ = this.get(customDateStartParam);
      const customDateEndZ = this.get(customDateEndParam);
      const dateParams = this.dateParams || {};
      let dateStartString = '';
      let dateEndString = '';

      if (R.equals(selectedPeriod, 'campaignPeriod')) {
        if (periodDateStartZ) {
          dateStartString = periodDateStartZ;
        }

        if (periodDateEndZ) {
          dateEndString = periodDateEndZ;
        }
      }

      if (R.equals(selectedPeriod, 'customDate')) {
        dateStartString = customDateStartZ;
        dateEndString = customDateEndZ;
      }

      if (R.equals(selectedPeriod, 'thisMonth')) {
        dateStartString = this.thisMonthPeriod.monthStartZ;
        dateEndString = this.thisMonthPeriod.monthEndZ;
      }

      if (R.equals(selectedPeriod, 'lastMonth')) {
        dateStartString = this.lastMonthPeriod.monthStartZ;
        dateEndString = this.lastMonthPeriod.monthEndZ;
      } // default date range if not specified is this month start - end for now


      if (RA.isNilOrEmpty(dateStartString)) {
        dateStartString = this.thisMonthPeriod.monthStartZ;
      }

      if (RA.isNilOrEmpty(dateEndString)) {
        dateEndString = this.thisMonthPeriod.monthEndZ;
      }

      let dateEnd;
      let dateStart;

      if (dateStartString > dateEndString) {
        dateEnd = this.dateService.formatDate(dateStartString);
        dateStart = this.dateService.formatDate(dateEndString);
      } else {
        dateEnd = this.dateService.formatDate(dateEndString);
        dateStart = this.dateService.formatDate(dateStartString);
      }

      dateParams[dateStartParam] = dateStart;
      dateParams[dateEndParam] = dateEnd;
      this.set('dateParams', dateParams);
      this.set(shownDateStartParam, dateStart);
      this.set(shownDateEndParam, dateEnd);

      if (R.equals(selectedPeriod, 'customDate')) {
        this.set(customDateStartParam, dateStart);
        this.set(customDateEndParam, dateEnd);
      }
    },

    actions: {
      cancel(onCloseDialogAction) {
        onCloseDialogAction();
      },

      selectDispatchProcess(dispatchProcess) {
        this.set('dispatchProcess', dispatchProcess);
      },

      addDispatchProcess(dispatchProcess) {
        this.addDispatchProcess(dispatchProcess);
      },

      removeDispatchProcess(dispatchProcess) {
        let selectedDispatchProcess = this.get('selectedDispatchProcess') || [];
        selectedDispatchProcess = R.reject(selected => R.pathEq(['_data', 'code'], selected?._data?.code || '', dispatchProcess), selectedDispatchProcess);
        this.set('selectedDispatchProcess', selectedDispatchProcess);
      },

      loadShopSource(shop) {
        this.set('customSourceKey', shop.getData('_key'));
      },

      unloadShopSource() {
        this.set('customSourceKey', '');
      },

      preExportTask(onCloseDialogAction) {
        this.set('isSaveBtnClicked', true);

        if (!this.isValidationError) {
          this.exportTask.perform(onCloseDialogAction);
        }
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj), _applyDecoratedDescriptor(_obj, "updateDateParams", [_dec2], Object.getOwnPropertyDescriptor(_obj, "updateDateParams"), _obj)), _obj)));

  _exports.default = _default;
});