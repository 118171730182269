define("client/pods/components/channels/shop/items/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EZIwdndc",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"elements/element-input\",null,[[\"autofocus\",\"placeholder\",\"value\",\"isRunning\",\"onSubmit\",\"isSearchBar\"],[[24,[\"autofocus\"]],\"search\",[24,[\"filters\",\"_data\",\"query\"]],[28,\"or\",[[24,[\"searchTask\",\"isRunning\"]],[24,[\"listByTask\",\"isRunning\"]]],null],[28,\"perform\",[[24,[\"searchTask\"]],[24,[\"searchOptions\"]],[28,\"hash\",null,[[\"query\",\"filters\",\"onAfter\"],[[24,[\"query\"]],[24,[\"filters\"]],[24,[\"onAfterSearch\"]]]]]],null],true]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"results\"]]],null,{\"statements\":[[4,\"forms/form-section\",null,[[\"contentClassNames\"],[\"is-gray\"]],{\"statements\":[[0,\"    \"],[1,[28,\"layouts/table-view\",null,[[\"tab\",\"tableClassNames\",\"detailsRowComponent\",\"filters\",\"onPaginationTask\",\"onDisplay\",\"onEdit\",\"onMainAction\",\"onReloadSearchRecordsTask\",\"onSearchTask\",\"paginationIsFooter\",\"results\",\"tableViewOptions\"],[[24,[\"tab\"]],\"new-table-view\",\"channels/shop/campaigns/items/table-view/detail-row\",[24,[\"filters\"]],[23,0,[\"customPaginationTask\"]],[24,[\"onDisplay\"]],[24,[\"onEdit\"]],[24,[\"onSelect\"]],[24,[\"reloadSearchRecordsTask\"]],[24,[\"searchAdvancedTask\"]],false,[24,[\"results\"]],[24,[\"tableViewOptions\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/items/search-bar/template.hbs"
    }
  });

  _exports.default = _default;
});