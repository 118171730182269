define("client/pods/components/websites/panels-display/sub-panels-pages/nav-menu-nested/nav-nested/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    tagName: 'div',
    classNames: ['nav-nested'],
    translate: true,
    item: null,
    isExpanded: false,
    childIsExpanded: false,
    disabled: false,
    isViewable: true,

    init() {
      this._super(...arguments);

      this.set('childIsExpanded', this.isExpanded);
      const selected = this.selected;
      const item = this.item;

      if (selected) {
        if (selected._data._key === item._data._key) {
          if (!this.disabled) {
            this.set('disabled', true);
          }
        }
      }
    },

    wasUpdated(isExpanded) {
      this.set('childIsExpanded', isExpanded);
    },

    draggable: Ember.computed('item._data.isHome', function () {
      if (this.get('item._data.isHome')) {
        return false;
      }

      return true;
    }),
    actions: {
      drop() {
        if (this.onDrop) {
          this.onDrop(...arguments);
        }
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "wasUpdated", [_dec], Object.getOwnPropertyDescriptor(_obj, "wasUpdated"), _obj)), _obj)));

  _exports.default = _default;
});