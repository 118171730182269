define("client/pods/components/transporters/shopee-print-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p8dTksxl",
    "block": "{\"symbols\":[\"@printCount\"],\"statements\":[[5,\"elements/element-btn\",[],[[\"@badge\",\"@label\",\"@isRunning\",\"@onSubmit\"],[[23,1,[]],\"next step\",[23,0,[\"dispatch\",\"generateAndDownloadWaybillsTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"getWaybillTask\"]]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/shopee-print-btn/template.hbs"
    }
  });

  _exports.default = _default;
});