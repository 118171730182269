define("client/pods/channels/shop/campaigns/service", ["exports", "ember-concurrency-decorators", "ramda-adjunct"], function (_exports, _emberConcurrencyDecorators, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, (_class = class ChannelsShopCampaignsService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "server", _descriptor3, this);

      _initializerDefineProperty(this, "constants", _descriptor4, this);

      _initializerDefineProperty(this, "shopsList", _descriptor5, this);
    }

    *searchInputTask(args) {
      let {
        query = '',
        resultsProperty = 'results',
        adapterName = 'channels/shop/campaigns',
        filtersAdapterName = 'channels/shop/campaigns/filters',
        component,
        resultsToggle = false,
        global = true,
        filters
      } = args;

      if (RA.isNilOrEmpty(filters)) {
        filters = this.search.setupFilters({
          adapterName: filtersAdapterName,
          defaultFiltersData: {
            query
          }
        });
      }

      const results = yield this.crud.searchInputTask.perform({
        adapterName,
        filters,
        resultsProperty,
        component,
        resultsToggle,
        allowEmptyQuery: true
      }, query);

      if (global && resultsProperty) {
        this[resultsProperty] = results;
      }

      return results;
    }

    checkHasAnyCommissionData(_ref) {
      var _this = this;

      let {
        model
      } = _ref;
      return function* () {
        let results = [];
        const master = model.getData('_key');
        results = yield _this.crud.findTask.perform({
          adapterName: 'channels/shop/campaigns/details/preset-commissions',
          appendPath: `/master/${master}/exists`,
          rawResponse: true
        });
        return results || {};
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "shopsList", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchInputTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchInputTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkHasAnyCommissionData", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "checkHasAnyCommissionData"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsService;
});