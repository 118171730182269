define("client/pods/components/channels/shop/promo-codes/search-results-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',

    init() {
      this.crud.addLists(this, ['discounts/types', 'statuses']);

      this._super(...arguments);
    },

    statusTagClassName: Ember.computed('result._data.status', function () {
      const status = this.get('result._data.status');

      if (status === this.get('constants.status.active')) {
        return 'list-tags__item--active-status';
      } else {
        return 'list-tags__item--inactive-status';
      }
    })
  });

  _exports.default = _default;
});