define("client/helpers/truncate", ["exports", "client/utils/nventor"], function (_exports, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params, hash) {
      const text = params[0] || '';
      const char = hash.char || 100;
      const byLines = hash.byLines || false;
      return _nventor.default.truncate({
        text,
        char,
        byLines
      });
    }

  });

  _exports.default = _default;
});