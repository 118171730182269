define("client/pods/components/elements/btn-delete/component", ["exports", "ramda", "ember-concurrency-decorators"], function (_exports, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    application: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: '',
    label: 'delete',
    title: 'delete',
    btnClassNames: 'button--delete',
    icon: 'fas fa-trash',
    requiresConfirmation: true,
    typeToDelete: false,

    init() {
      this._super(...arguments);

      this.set('typedConfirmationText', '');
    },

    _requiresConfirmation: Ember.computed('requiresConfirmation', 'typeToDelete', 'confirmMsg', 'confirmMsgTranslate', function () {
      if (this.requiresConfirmation || this.typeToDelete || this.confirmMsg || this.confirmMsgTranslate) {
        return true;
      }

      return false;
    }),
    isDeleteDisabled: Ember.computed('typedConfirmationText', function () {
      const deleteKeyword = this.intl.t('delete_keyword');
      const typedConfirmationText = this?.typedConfirmationText || '';
      const confirmation = R.pipe(R.trim, R.toUpper)(typedConfirmationText);

      if (confirmation === deleteKeyword) {
        return false;
      }

      return true;
    }),

    *deleteWithTypedConfirmationTask(onCloseDialogAction) {
      for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        rest[_key - 1] = arguments[_key];
      }

      yield this.onSubmit(...rest, {
        preOnAfter: onCloseDialogAction
      });
    },

    cancel(onCloseDialogAction) {
      this.set('typedConfirmationText', '');
      onCloseDialogAction();
    },

    clearTypeToDelete() {
      this.set('typedConfirmationText', '');
    },

    copyText() {
      this.application.copyText(this.intl.t('delete_keyword'));
    }

  }, (_applyDecoratedDescriptor(_obj, "deleteWithTypedConfirmationTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "deleteWithTypedConfirmationTask"), _obj), _applyDecoratedDescriptor(_obj, "cancel", [_dec], Object.getOwnPropertyDescriptor(_obj, "cancel"), _obj), _applyDecoratedDescriptor(_obj, "clearTypeToDelete", [_dec2], Object.getOwnPropertyDescriptor(_obj, "clearTypeToDelete"), _obj), _applyDecoratedDescriptor(_obj, "copyText", [_dec3], Object.getOwnPropertyDescriptor(_obj, "copyText"), _obj)), _obj)));

  _exports.default = _default;
});