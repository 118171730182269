define("client/pods/components/elements/steps-bar/step-node/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RbZE6xo5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"li\",false],[12,\"class\",[29,[\"step-node \",[28,\"if\",[[24,[\"isSelected\"]],\"active\"],null],\" \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null],\" \",[28,\"if\",[[24,[\"notAllowSubmit\"]],\"not-allowed\"],null]]]],[3,\"action\",[[23,0,[]],\"submit\",[24,[\"step\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasCounter\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"customStepLabel\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",\"step-node__counter\"],[3,\"action\",[[23,0,[]],\"customSubmit\",[24,[\"step\"]]]],[8],[0,\"\\n        \"],[1,[22,\"stepNo\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"step-node__counter\"],[8],[0,\"\\n        \"],[1,[22,\"stepNo\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[4,\"if\",[[24,[\"customStepLabel\"]]],null,{\"statements\":[[0,\"    \"],[14,1,[[28,\"action\",[[23,0,[]],\"customSubmit\",[24,[\"step\"]]],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"step-node__label\"],[8],[0,\"\\n      \"],[1,[28,\"tt\",[[24,[\"name\"]]],[[\"translate\"],[[24,[\"step\",\"_data\",\"translate\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/steps-bar/step-node/template.hbs"
    }
  });

  _exports.default = _default;
});