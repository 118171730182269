define("client/pods/components/msgs/error-msg/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    translate: true,
    showError: true,
    showErrorLabel: false,
    icon: 'fas fa-exclamation-circle',
    showMultipleErrors: false,
    iconClassNames: 'has-text-danger',
    errorClassNames: 'error-msg',
    hasErrors: Ember.computed('errors.[]', 'context', 'error', function () {
      const error = this.error;

      if (error) {
        if (R.is(String, error)) {
          return [{
            message: error,
            label: ''
          }];
        }

        return [error];
      }

      const errors = this.errors || [];
      let context = this.context || [];

      if (!R.is(Array, context)) {
        context = [context];
      }

      let contextArray = this.contextArray || [];

      if (!R.is(Array, contextArray)) {
        contextArray = [contextArray];
      }

      const contextDotPath = R.join('.')(context);
      const contextArrayDotPath = R.join('.')(contextArray);
      let filtered = errors;

      if (context) {
        filtered = R.filter(error => {
          if (contextArrayDotPath) {
            const errorDotPath = R.pipe(R.pathOr([], ['path']), R.reject(Number.isInteger), R.join('.'))(error);

            if (errorDotPath === contextArrayDotPath) {
              return true;
            }
          } else {
            const errorContext = R.pathOr('', ['context', 'key'], error);

            if (R.includes(errorContext, context)) {
              return true;
            }

            const errorDotPathWithoutIndex = R.pipe(R.pathOr([], ['path']), R.reject(Number.isInteger), R.join('.'))(error);

            if (errorDotPathWithoutIndex === contextDotPath) {
              return true;
            }

            return false;
          }
        })(filtered);
      }

      if (!this.showMultipleErrors && filtered.length > 0) {
        return R.take(1)(filtered);
      }

      return filtered;
    })
  });

  _exports.default = _default;
});