define("client/helpers/tt", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const key = R.pathOr('', [0])(params);
      const defaultKey = R.pathOr('', [1])(params);
      const optionalHash = R.pathOr({}, [2])(params);
      hash = R.mergeRight(hash, optionalHash);

      if (!key && defaultKey) {
        return this.intl.t(defaultKey, hash);
      }

      let translate = true; // in the event no translate key is passed, use default translate settings

      if (hash.translate === '' || hash.translate == null) {
        if (hash.defaultTranslate != null) {
          translate = hash.defaultTranslate;
        }
      }

      if (hash.translate === false) {
        translate = false;
      }

      if (translate && key) {
        return this.intl.t(key, hash);
      }

      return key;
    }

  });

  _exports.default = _default;
});