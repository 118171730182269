define("client/pods/payment-methods/model", ["exports", "client/pods/base/model", "ramda-adjunct"], function (_exports, _model, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        customLabel: '',
        value: '',
        label: '',
        hasPaymentTerms: false
      };
    },

    populate(data, attrs) {
      const label = data.label;
      const customLabel = data.customLabel;

      if (RA.isNilOrEmpty(customLabel)) {
        data.customLabel = label;
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});