define("client/pods/channels/shop/campaigns/members-campaign-menu/collection/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.collection = this._transformCollection(data);

      this._super(...arguments);
    },

    _transformCollection(data) {
      return R.pipe(R.propOr([], 'collection'), R.map(menuData => {
        // menuData.isPinned = true
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/members-campaign-menu',
          data: menuData
        });
      }))(data);
    },

    userCampaignMenu: Ember.computed('synced', 'settings.settingsModel.synced', function () {
      const campaignsBookmarkCollection = R.pathOr([], ['_data', 'collection'])(this);
      const campaignsBookmark = R.pipe(R.sortWith([R.descend(R.path(['_data', 'timestampZ_latest']))]), R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.pluck('campaign'), R.reject(RA.isNilOrEmpty), R.map(campaign => {
        campaign.setData('isPinned', true);
        return campaign;
      }))(campaignsBookmarkCollection);
      return campaignsBookmark;
    })
  });

  _exports.default = _default;
});