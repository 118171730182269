define("client/pods/components/files/files-uploader/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    name: 'content',
    label: 'upload files',
    translate: true,
    uploadcare: Ember.inject.service(),
    resource: '',
    resourceKey: '',
    // settings
    multiple: true,
    multipleMax: '',
    multipleMin: '',
    imagesOnly: false,
    previewStep: false,
    crop: false,
    imageShrink: '',
    clearable: false,
    // should always be false as no way to hook into "remove" function of widget
    tabs: 'file url',

    didReceiveAttrs() {
      // @TODO: this will be called twice!
      // @TODO: once on init, and another time when resourceKey is available
      this._super(...arguments);

      if (this.uploadcare.secureSignature) {
        this.createWidgetSingleton();
      } else {
        this.uploadcare.getSecureTokenTask.perform().then(token => {
          if (this?.isDestroyed === false) {
            this.createWidgetSingleton();
          }
        });
      }
    },

    createWidgetSingleton() {
      const self = this;
      self.set('isUpdatingServer', false);
      self.set('widgets', []); // self.set('error', '');

      if (self.get('resourceKey') && self.get('resource')) {
        self.get('widgets').pushObject(Ember.Object.create({
          component: 'files/uploadcare-widget'
        }));
      }
    },

    updateServerTask: (0, _emberConcurrency.task)(function* (info) {
      const self = this;
      const resource = this.resource;
      const resourceKey = this.resourceKey;
      self.set('isUpdatingServer', true);

      try {
        if (this.tagKey) {
          info.tags = [this.tagKey];
        }

        if (self.get('multiple')) {
          yield self.get('uploadcare').updateFileGroupToServer(resource, resourceKey, info);
        } else {
          yield self.get('uploadcare').updateFileToServer(resource, resourceKey, info);
        }

        const onAfterUpload = self.get('onAfterUpload');

        if (onAfterUpload) {
          yield onAfterUpload(resource, resourceKey, info);
        }

        if (!self.get('isDestroyed')) {
          self.set('error', '');
          self.createWidgetSingleton();
        }

        self.set('isUpdatingServer', false);
      } catch (e) {
        self.set('error', 'error occurred please try again later');
        self.createWidgetSingleton();
      }
    }) // actions: {
    //   updateServer (info) {
    //     // @TODO: handle single uploads
    //   }
    // }

  });

  _exports.default = _default;
});