define("client/pods/grapesjs/plugins/blocks", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global grapesjs */
  const pluginName = 'bigordr-blocks';
  const contentComponentClassName = 'bigordr-row';
  const rowComponentClassName = 'bigordr-inner-row';
  const cellComponentClassName = 'bigordr-cell';
  const Plugin = grapesjs.plugins.add(pluginName, (editor, options) => {
    const blocksOptions = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const {
      /** @type {Categories} */
      categories
    } = blocksOptions;
    /**
     * merge default options with a property from options
     *
     * @param {string} optionsProp
     * @param {object} defaultOptions
     */

    const _mergeOptions = (optionsProp, defaultOptions) => {
      return R.merge(defaultOptions, R.prop(optionsProp, options));
    };

    const BlockManager = editor.BlockManager; // const styleCell = `
    //   .${cellComponentClassName} {
    //     min-height: 50px;
    //     flex-grow: 1;
    //     flex-basis: 100%;
    //     font-family: Arial, Helvetica, sans-serif;
    //   }
    // `
    // const resizerBtm = { tl: 0, tc: 1, tr: 0, cl: 0, cr: 0, bl: 0, br: 0, minDim }

    const resizerRight = {
      tl: 0,
      tc: 0,
      tr: 0,
      cl: 1,
      cr: 1,
      bl: 0,
      br: 0,
      bc: 0
    };
    resizerRight.keyWidth = 'flex-basis';
    const resizerImg = {
      tl: 1,
      tc: 0,
      tr: 1,
      cl: 0,
      cr: 0,
      bl: 1,
      bc: 0,
      br: 1,
      ratioDefault: 1
    };
    const privateCls = [`.${rowComponentClassName}`, `.${cellComponentClassName}`];
    editor.on('selector:add', selector => privateCls.indexOf(selector.getFullName()) >= 0 && selector.set('private', 1));

    const attrsToString = attrs => {
      const result = [];

      for (const key in attrs) {
        let value = attrs[key];
        const toParse = value instanceof Array || value instanceof Object;
        value = toParse ? JSON.stringify(value) : value;
        result.push(`${key}=${toParse ? `'${value}'` : `"${value}"`}`);
      }

      return result.length ? ` ${result.join(' ')}` : '';
    };

    const isCellDraggable = {
      'data-gjs-draggable': `.${cellComponentClassName}, [data-gjs-type='link']`
    }; // curried function to use when wanting to add isCellDraggable attribute

    const mergeWithOnlyCellDraggableAttrs = function () {
      let attrs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      attrs = R.mergeRight(isCellDraggable)(attrs);
      return attrsToString(attrs);
    };

    const onlyCellDraggableAttrString = attrsToString(isCellDraggable); // const colAttr = {
    //   class: cellComponentClassName,
    //   'data-gjs-draggable': `.${contentComponentClassName}, .${rowComponentClassName}`,
    //   'data-gjs-resizable': resizerRight,
    //   'data-gjs-name': 'Cell',
    //   'data-gjs-type': 'cell',
    //   'data-gjs-unstylable': ['width'],
    //   'data-gjs-stylable-require': ['flex-basis']
    // }
    // const attrsCell = attrsToString(colAttr)
    // BlockManager.add('text', _mergeOptions('text', {
    //   type: 'text',
    //   category: categories.basic,
    //   content: {
    //     type: 'text',
    //     content: 'Insert your text here',
    //     style: { padding: '10px' },
    //     draggable: `.${cellComponentClassName}, [data-gjs-type='link']`
    //   },
    //   render: () => `
    //     <svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/'
    // style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;'> <g transform='matrix(1,0,0,1,49.9262,39.6375)'> <g transform='matrix(1,0,0,1,-34.9989,-70.467)'> <rect x='203.744' y='249.501' width='586.657'
    // height='586.657' style='fill:white;stroke:black;stroke-width:15px;'/> </g> <g transform='matrix(1,0,0,1,-57.9517,-98.8077)'> <rect x='765.514' y='816.659' width='95.679' height='95.679'/> </g> <g
    // transform='matrix(1,0,0,1,-644.609,-98.8077)'> <rect x='765.514' y='816.659' width='95.679' height='95.679'/> </g> <g transform='matrix(1,0,0,1,-644.609,-685.465)'> <rect x='765.514' y='816.659' width='95.679' height='95.679'/> </g>
    // <g transform='matrix(1,0,0,1,-57.9517,-685.465)'> <rect x='765.514' y='816.659' width='95.679' height='95.679'/> </g> <g transform='matrix(0.715811,0,0,0.676173,154.154,151.358)'> <g
    // transform='matrix(551.145,0,0,551.145,260.627,673.073)'> <path d='M0.599,-0.72L0.599,-0.592L0.384,-0.592L0.384,0L0.232,0L0.232,-0.592L0.016,-0.592L0.016,-0.72L0.599,-0.72Z' style='fill-rule:nonzero;'/> </g> </g> </g> </svg> ` }))
    // const attrsText = mergeWithOnlyCellDraggableAttrs({
    //   style: 'padding:10px'
    // })
    // const attrsTextBlock = mergeWithOnlyCellDraggableAttrs({
    //   style: 'padding:10px'
    // })
    // BlockManager.add('text-block', _mergeOptions('text-block', {
    //   label: 'Text section',
    //   category: categories.basic,
    //   type: 'text-block',
    //   content: `<section ${attrsTextBlock}>
    //     <h1 ${onlyCellDraggableAttrString} class='title'>Insert title here</h1>
    //     <p ${attrsText} style='padding:0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
    //   </section>`,
    //   render: () => `
    //     <svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/'
    // style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;'> <g transform='matrix(1,0,0,1,49.9262,39.6375)'> <g transform='matrix(1,0,0,1,-34.9989,-70.467)'> <rect x='203.744' y='249.501' width='586.657'
    // height='586.657' style='fill:white;stroke:black;stroke-width:15px;'/> </g> <g transform='matrix(1,0,0,1,-57.9517,-98.8077)'> <rect x='765.514' y='816.659' width='95.679' height='95.679'/> </g> <g
    // transform='matrix(1,0,0,1,-644.609,-98.8077)'> <rect x='765.514' y='816.659' width='95.679' height='95.679'/> </g> <g transform='matrix(1,0,0,1,-644.609,-685.465)'> <rect x='765.514' y='816.659' width='95.679' height='95.679'/> </g>
    // <g transform='matrix(1,0,0,1,-57.9517,-685.465)'> <rect x='765.514' y='816.659' width='95.679' height='95.679'/> </g> </g> <g transform='matrix(0.697024,0,0,0.646383,87.8947,149.831)'> <rect x='301.902' y='349.059' width='385.98'
    // height='98.525'/> </g> <g transform='matrix(1.11832,0,0,1,-62.6481,-73.0593)'> <rect x='322.785' y='550.771' width='382.132' height='25.78'/> </g> <g transform='matrix(1.11832,0,0,1,-62.6481,-21.5278)'> <rect x='322.785' y='550.771'
    // width='382.132' height='25.78'/> </g> <g transform='matrix(1.11832,0,0,1,-62.6481,24.6035)'> <rect x='322.785' y='550.771' width='382.132' height='25.78'/> </g> <g transform='matrix(1.11832,0,0,1,-62.6481,71.9924)'> <rect x='322.785'
    // y='550.771' width='382.132' height='25.78'/> </g> </svg> ` }))

    /*
      BlockManager.add('link-block', _mergeOptions('link-block', {
        label: 'Link Block',
        category: categories.basic,
        content: {
          type: 'link',
          editable: false,
          droppable: true,
          draggable: true,
          style: {
            display: 'block',
            padding: '0',
            width: '100%',
            'min-height': '50px',
            'min-width': '50px'
          }
        },
        render: () => `
          <svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;'>
            <g transform='matrix(1,0,0,1,49.9262,39.6375)'>
                <g transform='matrix(1,0,0,1,-34.9989,-70.467)'>
                    <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:15px;'/>
                </g>
                <g transform='matrix(1,0,0,1,-57.9517,-98.8077)'>
                    <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
                </g>
                <g transform='matrix(1,0,0,1,-644.609,-98.8077)'>
                    <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
                </g>
                <g transform='matrix(1,0,0,1,-644.609,-685.465)'>
                    <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
                </g>
                <g transform='matrix(1,0,0,1,-57.9517,-685.465)'>
                    <rect x='765.514' y='816.659' width='95.679' height='95.679'/>
                </g>
            </g>
            <g transform='matrix(8.41257e-16,13.7388,-13.7388,8.41257e-16,684.75,346.784)'>
                <path d='M5.563,0C4.137,0 2.707,0.543 1.625,1.625C-0.539,3.789 -0.539,7.336 1.625,9.5L5.281,13.156C5.668,13.555 6.305,13.559 6.703,13.172C7.102,12.785 7.105,12.148 6.719,11.75L3.031,8.063C1.633,6.664 1.633,4.43 3.031,3.031C4.43,1.633 6.664,1.633 8.063,3.031L12.969,7.938C14.367,9.336 14.367,11.57 12.969,12.969C12.805,13.133 12.621,13.25 12.438,13.375C11.98,13.688 11.859,14.309 12.172,14.766C12.484,15.223 13.105,15.344 13.563,15.031C13.848,14.836 14.125,14.625 14.375,14.375C16.539,12.211 16.539,8.664 14.375,6.5L9.5,1.625C8.418,0.543 6.988,0 5.563,0ZM10.781,8.875C10.738,8.883 10.695,8.895 10.656,8.906C10.508,8.938 10.371,9 10.25,9.094C10.039,9.254 9.82,9.43 9.625,9.625C7.461,11.789 7.461,15.336 9.625,17.5L14.5,22.375C16.664,24.539 20.211,24.539 22.375,22.375C24.539,20.211 24.539,16.664 22.375,14.5L18.719,10.875C18.477,10.578 18.09,10.441 17.715,10.527C17.344,10.613 17.051,10.906 16.965,11.277C16.879,11.652 17.016,12.039 17.313,12.281L20.969,15.938C22.367,17.336 22.367,19.57 20.969,20.969C19.57,22.367 17.336,22.367 15.938,20.969L11.031,16.063C9.633,14.664 9.633,12.43 11.031,11.031C11.152,10.906 11.301,10.82 11.438,10.719C11.84,10.473 12.016,9.977 11.855,9.531C11.699,9.086 11.25,8.813 10.781,8.875Z' style='fill-rule:nonzero;'/>
            </g>
          </svg>
        `
      }))
    */

    BlockManager.add('optimized-image', _mergeOptions('optimized-image', {
      label: 'Image',
      category: categories.basic,
      content: {
        type: 'optimized-image',
        imgType: 'optimized-image',
        draggable: `.${cellComponentClassName}, [data-gjs-type='link']`,
        resizable: resizerImg,
        activeOnRender: 1
      },
      render: () => `
      <svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;'>
        <g transform='matrix(1,0,0,1,-1.46126,37.2616)'>
            <g>
                <g>
                    <g transform='matrix(1.00794,0,0,0.792537,12.4421,44.5258)'>
                        <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:38.6px;'/>
                    </g>
                    <g transform='matrix(1.08069,0,0,1.08069,-48.6111,-30.4861)'>
                        <g transform='matrix(1.51835,-6.97105e-19,-6.97105e-19,1.52404,-502.354,-212.761)'>
                            <circle cx='567.803' cy='388.754' r='27.858' style='stroke:black;stroke-width:18.25px;stroke-linecap:round;stroke-linejoin:round;'/>
                        </g>
                        <g transform='matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)'>
                            <g transform='matrix(-0.93623,0,0,0.939727,1014.78,130.716)'>
                                <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                            </g>
                            <g transform='matrix(-1.01027,0,0,0.756475,916.709,228.489)'>
                                <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
      </svg>
    `
    }));
    editor.Commands.add('core:component-delete', {
      run(ed, sender) {
        let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        let components = opts.component || ed.getSelectedAll();
        components = R.is(Array, components) ? [...components] : [components]; // It's important to deselect components first otherwise,
        // with undo, the component will be set with the wrong `collection`

        ed.select(null);
        components.forEach(component => {
          if (!component || !component.get('removable')) {
            return;
          }

          if (R.pathEq(['attributes', 'type'], 'cell', component)) {
            const parent = component.view.$el[0].parentElement;
            const parentClassNames = R.propOr([], 'classList')(parent);
            const parentIsContentRow = R.includes('bigordr-row', parentClassNames);
            const parentIsInnerRow = R.includes('bigordr-inner-row', parentClassNames);
            const parentParentIsTabContent = component?.parent()?.parent()?.get('type') === 'tab-content';
            const parentIsTabContent = component?.parent()?.get('type') === 'tab-content';
            const isLastCell = R.pipe(R.propOr([], 'children'), R.length, R.equals(1))(parent);

            if ((parentIsInnerRow || parentIsContentRow) && isLastCell && !parentParentIsTabContent && !parentIsTabContent) {
              const coll = component.collection;
              const row = coll.parent.trigger('component:destroy');
              row.remove();
            }

            if ((parentParentIsTabContent || parentIsTabContent) && !isLastCell && (parentIsInnerRow || parentIsContentRow)) {
              const coll = component.collection;
              component.trigger('component:destroy');
              coll && coll.remove(component);
              return;
            }
          }

          const parentParentIsTabContent = component?.parent()?.parent()?.get('type') === 'tab-content';
          const parentIsTabContent = component?.parent()?.get('type') === 'tab-content';

          if (component && !parentParentIsTabContent && !parentIsTabContent) {
            const coll = component.collection;
            component.trigger('component:destroy');
            coll && coll.remove(component);
          } else if (component && parentIsTabContent) {
            const tabComponent = component?.parent();
            const rowComponents = tabComponent.findType('row');
            const allowRemoveSelectedRowComponent = rowComponents.length > 1;

            if (!allowRemoveSelectedRowComponent) {
              return;
            }

            const coll = component.collection;
            component.trigger('component:destroy');
            coll && coll.remove(component);
          }
        });
        return components;
      }

    });
    editor.Commands.add('tlb-move', {
      run(ed, sender) {
        let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        let dragger;
        const em = ed.getModel();
        const event = opts && opts.event;
        const {
          target
        } = opts;
        const sel = target || ed.getSelected();
        const selAll = target ? [target] : [...ed.getSelectedAll()];
        const nativeDrag = event && event.type === 'dragstart';
        const defComOptions = {
          preserveSelected: 1
        };
        const modes = ['absolute', 'translate'];

        const hideTlb = () => em.stopDefault(defComOptions); // Dirty patch to prevent parent selection on drop (in absolute mode)


        em.set('_cmpDrag', 1);

        if (!sel || !sel.get('draggable')) {
          console.warn('The element is not draggable');
          return;
        }

        if (R.pathEq(['attributes', 'type'], 'cell', sel)) {
          const parent = sel.view.$el[0].parentElement;
          const parentClassNames = R.propOr([], 'classList')(parent);
          const parentIsContentRow = R.includes('bigordr-row', parentClassNames);
          const parentIsInnerRow = R.includes('bigordr-inner-row', parentClassNames);
          const isLastCell = R.pipe(R.propOr([], 'children'), R.length, R.equals(1))(parent);

          if ((parentIsInnerRow || parentIsContentRow) && isLastCell) {
            return;
          }
        }

        const mode = sel.get('dmode') || em.get('dmode'); // Without setTimeout the ghost image disappears

        nativeDrag ? setTimeout(hideTlb, 0) : hideTlb();

        const onEnd = (e, opts) => {
          em.runDefault(defComOptions);
          selAll.forEach(sel => sel.set('status', 'selected'));
          ed.select(selAll);
          sel.emitUpdate();
        };

        if (R.includes(mode, modes)) {
          // TODO move grabbing func in editor/canvas from the Sorter
          dragger = ed.runCommand('core:component-drag', {
            guidesInfo: 1,
            mode,
            target: sel,
            onEnd,
            event
          });
        } else {
          if (nativeDrag) {
            event.dataTransfer.setDragImage(sel.view.el, 0, 0);
          }

          const cmdMove = ed.Commands.get('move-comp');
          cmdMove.onEndMoveFromModel = onEnd;
          cmdMove.initSorterFromModels(selAll);
        }

        selAll.forEach(sel => sel.set('status', 'freezed-selected'));
      }

    });
  });
  var _default = Plugin;
  _exports.default = _default;
});