define("client/pods/components/channels/shop/rewards/rewards-editor/component", ["exports", "@glimmer/component", "ramda-extension", "client/utils/nventor"], function (_exports, _component, R_, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopRewardsRewardsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class ChannelsShopRewardsRewardsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "constants", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);

      this.crud.addLists(this, ['channels/shop/rewards/lists/sub-panels-editor']);
    }

    afterLoadLists() {
      const tab = this.args.tab;
      const subPanels = R_.dotPathOr([], 'crud.lists.channels-shop-rewards-lists-sub-panels-editor')(this);

      const initialPanel = this.tabsManager.getLoadInstructions(tab) || _nventor.default.safeHeadOr({}, subPanels);

      this.tabsManager.displaySubPanel(this, initialPanel);
    }

    get isReadonly() {
      if (this.args.isEditing) {
        return false;
      }

      return true;
    }

    get isDisabled() {
      if (this.args.isEditing) {
        return false;
      }

      return true;
    }

    editPanel() {
      const activeSubTab = this.tabsManager.getActiveSubTab(this.args.tab);
      const tabOptions = {
        loadInstructions: {
          component: activeSubTab.get('component')
        }
      };
      this.args.onEdit(this.args.model, tabOptions);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "editPanel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "editPanel"), _class.prototype)), _class));
  _exports.default = ChannelsShopRewardsRewardsEditorComponent;
});