define("client/pods/components/tabs/tab-headers/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['tabs'],
    translate: true // actions: {
    //   selected: function (tab) {
    //     this.set('action', tab.get('_data.action'))
    //     this.sendAction('action', tab._data)
    //   }
    // }

  });

  _exports.default = _default;
});