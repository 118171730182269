define("client/pods/components/dispatch/processes-item-dispatched/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    dispatchProcessCountComputed: Ember.computed('dispatchProcessesByCount._data.processes.[]', 'dateZ', function () {
      const dispatchProcessesByCount = this.dispatchProcessesByCount;

      if (dispatchProcessesByCount) {
        const code = this.get('dateZ');
        const count = R.pathOr(0, ['_data', code, 'count'])(dispatchProcessesByCount);
        return Number(count);
      }

      return 0;
    })
  });

  _exports.default = _default;
});