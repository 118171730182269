define("client/pods/components/msgs/error-badge/component", ["exports", "ramda", "client/utils/nventor"], function (_exports, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    translate: true,
    showError: true,
    showErrorLabel: true,
    icon: 'fas fa-exclamation-circle',
    hasErrors: Ember.computed('errors.[]', 'errorsTracker.[]', function () {
      const getByContext = (errors, errorsTracker) => {
        return R.pipe(R.map(error => {
          const context = R.path(['context', 'key'])(error);

          if (context) {
            const found = R.find(R.anyPass([R.equals(context), R.propEq('context', context)]))(errorsTracker);

            if (found) {
              const label = R.propOr(found, 'label', found);
              error.set('label', label);
              return error;
            }
          }

          return false;
        }), R.reject(R.equals(false)))(errors);
      };

      const getByContextWithDotPath = (errors, errorsTracker) => {
        return R.pipe(R.map(error => {
          const contextKey = R.path(['context', 'key'])(error);
          let contextDotPath = '';

          if (!Number.isInteger(contextKey) && contextKey != null) {
            if (typeof contextKey === 'string') {
              contextDotPath = R.split('.')(contextKey);
            }
          }

          if (contextDotPath.length > 1) {
            let found = false;
            let foundTrackerLabel = '';
            R.forEach(trackerContextObject => {
              const trackerContext = R.propOr(trackerContextObject, 'context')(trackerContextObject);
              const trackerContextLength = R.pipe(R.split('.'), R.length)(trackerContext);
              const trackerLabel = R.propOr('', 'label')(trackerContextObject);

              if (trackerContextLength > 0) {
                const toMatchContext = R.pipe(R.take(trackerContextLength), R.join('.'))(contextDotPath);

                if (trackerContext === toMatchContext) {
                  found = trackerContext;
                  foundTrackerLabel = trackerLabel;
                }
              }
            })(errorsTracker);

            if (found) {
              const label = foundTrackerLabel;
              error.set('label', label); // error.set('context', found)

              return error;
            }
          }

          return false;
        }), R.reject(R.equals(false)))(errors);
      };

      let errors = this.errors || null;

      if (!_nventor.default.isNilOrEmpty(errors)) {
        errors = R.map(error => {
          return Ember.Object.create(error);
        }, errors);
        const errorsTracker = this.errorsTracker || [];
        let foundErrors = getByContext(errors, errorsTracker);

        if (R.isEmpty(foundErrors)) {
          foundErrors = getByContextWithDotPath(errors, errorsTracker);
        }

        return foundErrors;
      }

      return null;
    })
  });

  _exports.default = _default;
});