define("client/pods/components/documents/links-conflict/generic-conflict/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    translatedValue: Ember.computed('conflictCategory.{translate,translatePrefix}', 'valueGroup.value', function () {
      const translate = this.get('conflictCategory.translate') || false;
      const value = this.get('valueGroup.value');

      if (translate) {
        const translatePrefix = this.get('conflictCategory.translatePrefix') || '';
        return this.intl.t(`${translatePrefix}${value}`);
      }

      return value;
    }),
    isSelected: Ember.computed('conflictCategory.selected.[]', function () {
      const value = this.valueGroup;
      return this.get(`conflictCategory.selected`).get('firstObject') === value;
    })
  });

  _exports.default = _default;
});