define("client/mixins/transporter", ["exports", "ramda", "client/mixins/crud", "client/mixins/search-contacts", "ember-concurrency", "ramda-extension"], function (_exports, R, _crud, _searchContacts, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_crud.default, _searchContacts.default, {
    crud: Ember.inject.service(),
    autoFetchTransportersList: true,

    didInsertElement() {
      if (this.autoFetchTransportersList) {
        this.crud.addLists(this, ['contacts/transporters'], {
          onAfter: this.afterLoadTransporters(this)
        });
      } else {
        const transporters = R_.dotPath('crud.lists.contacts-transporters')(this) || [];

        if (!R.isEmpty(transporters)) {
          const model = this.dirty || this.model;

          if (model) {
            this.setSelectedTransporter(model);
          }
        }
      }

      this._super(...arguments);
    },

    afterLoadTransporters: R.curry((component, results) => {
      if (!component?.isDestroyed) {
        const transporters = R_.dotPath('crud.lists.contacts-transporters')(component) || [];

        if (!R.isEmpty(transporters)) {
          const model = component.dirty || component.model;

          if (model) {
            component.setSelectedTransporter(model);
          }
        }

        component.set('listsIsLoaded', Math.random());
      }
    }),
    transportersList: Ember.computed('listsIsLoaded', 'crud.lists.contacts-transporters.[]', 'externalTransportersList.[]', function () {
      if (this.externalTransportersList) {
        return this.externalTransportersList;
      }

      return R_.dotPath('crud.lists.contacts-transporters')(this) || [];
    }),

    findTransporterByKey(transporterKey) {
      return this.transportersList.findBy('_data._key', transporterKey);
    },

    findTransporterByName(transporterName) {
      return this.transportersList.findBy('_data.name', transporterName);
    },

    // @TODO: remove side effects
    loadTransporter(model, transporter) {
      const key = transporter.getData('_key');
      model.setData('transporterKey', key);

      if (!this.isPickerOnly) {
        model.setData('transporterExtension', transporter.getData('transporterExtension'));
        this.setSelectedTransporter(model);
      }
    },

    // selectedTransporter: computed('updatedTransporter', 'model._data.transporterKey', 'dirty._data.transporterKey', 'transportersList.[]', function () {
    //   console.log('====selectedTransporter===')
    //   const model = this.get('dirty') || this.get('model')
    //   const transporterKey = model.getData('transporterKey')
    //   if (transporterKey) {
    //     return this.findTransporterByKey(transporterKey)
    //   }
    //   return false
    // }),
    // transporterAccounts: computed('selectedTransporter', function () {
    //   console.log('====transporterAccounts===')
    //   return this.get('selectedTransporter._data.transporterAccounts') || []
    // }),
    setTransporterAccounts(transporter) {
      // @TODO: not used?
      let accounts = [];

      if (transporter) {
        accounts = transporter._data.transporterAccounts || [];
      }

      this.set('accounts', accounts);
    },

    setSelectedTransporter(model) {
      const transporterKey = R.pathOr('', ['_data', 'transporterKey'])(model);
      let transporter = false;

      if (transporterKey) {
        transporter = this.findTransporterByKey(transporterKey);
      }

      this.set('selectedTransporter', transporter);
      this.setTransporterAccounts(transporter);
    },

    // @TODO: remove side effects
    unloadTransporter(model) {
      model.setData('transporterKey', '');

      if (!this.isPickerOnly) {
        model.setData('transporterExtension', '');
        model.setData('transporter', '');
        model.setData('transporterBatchNumber', '');
        model.setData('transporterBatchLabel', '');
        model.setData('transporterAccountNumber', '');
        model.setData('transporterAccount', '');
        model.setData('packages', '');
        model.setData('transporterWeightRate', '');
        model.setData('transporterRegion', '');
        model.setData('transporterRegionIsOuter', '');
        model.setData('person', '');
        model.setData('transporterContactPhone', '');
        this.setSelectedTransporter(model);
      }
    },

    fetchTransporterTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        model
      } = _ref;
      return function* () {
        const transporterKey = model.getData('transporterKey');

        if (transporterKey) {
          const transporter = yield _this.findTask.perform({
            adapterName: 'contacts',
            appendPath: transporterKey
          });

          _this.loadTransporterAccounts(model, transporter);
        }
      }();
    }),

    loadTransporterAccounts(model) {
      let transporter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const transporterAccounts = transporter?._data?.transporterAccounts || [];
      this.set('transporterAccounts', transporterAccounts);

      if (!model.getData('transporterAccountNumber')) {
        const setDefaultTransporterAccount = R.pipe(R.filter(account => account.getData('isDefault') === true), R.map(defaultAccount => {
          model.setData('transporterAccount', defaultAccount.getData('name'));
          model.setData('transporterAccountNumber', defaultAccount.getData('account'));
        }));
        setDefaultTransporterAccount(transporterAccounts);
      }
    },

    // @TODO: setting/checking isHCT will not be used by all clients.
    // @TODO: only should do this step if HCT module is in use.
    // @TODO: how to use modules and also automation module functions?
    fetchAndLoadTransporterForContactTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        contact,
        model
      } = _ref2;
      return function* () {
        const transporterKey = contact.getData('transporterKey');
        const transporterLabel = contact.getData('transporterLabel');

        if (transporterKey) {
          const transporter = yield _this2.findTask.perform({
            adapterName: 'contacts',
            appendPath: transporterKey
          });

          if (transporter) {
            model.setData('transporterLabel', transporter.getData('name'));

            _this2.loadTransporter(model, transporter); // model.setData('transporterExtension', transporter.getData('transporterExtension'))
            // model.setData('transporterKey', transporterKey)
            // this.set('selectedTransporter', transporter)

          }
        } else {
          // this.set('selectedTransporter', '')
          model.setData('transporterExtension', '');
          model.setData('transporterLabel', transporterLabel);
          model.setData('transporterKey', transporterKey);
        }

        _this2.setSelectedTransporter(model);
      }();
    }),
    actions: {
      loadTransporter(model, transporter) {
        return this.loadTransporter(model, transporter);
      },

      unloadTransporter(model) {
        return this.unloadTransporter(model);
      }

    }
  });

  _exports.default = _default;
});