define("client/pods/components/documents/editor-main/editor-info/component", ["exports", "ember-concurrency", "client/mixins/date", "client/mixins/search-contacts", "client/mixins/crud", "client/mixins/big", "client/utils/nventor", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, _date, _searchContacts, _crud, _big, _nventor, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * documents editor shared functions
   */
  var _default = Ember.Component.extend(_crud.default, _date.default, _big.default, _searchContacts.default, {
    contactsService: Ember.inject.service('contacts'),
    constants: Ember.inject.service(),
    crud: Ember.inject.service(),
    ajax: Ember.inject.service(),
    tagName: '',
    roundingCurrency: 0,
    unroundedCurrency: 8,
    rewardFiltersAdapter: 'channels/shop/rewards/filters',
    rewardAdapter: 'channels/shop/rewards',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['contacts/lists/relationships']);
      const campaignFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/filters'
      });
      this.set('campaignFilters', campaignFilters);
      const rewardFilters = this.search.setupFilters({
        adapterName: this.rewardFiltersAdapter,
        defaultFiltersData: {
          resultsProperty: 'rewardsList',
          query: ''
        }
      });
      this.set('rewardFilters', rewardFilters);
      const contactsFilters = this.search.setupFilters({
        adapterName: 'contacts/filters',
        defaultFiltersData: {
          sort: [{
            by: 'name',
            direction: 'ASC'
          }],
          status: [this.constants.status.active]
        }
      });
      this.set('contactsFilters', contactsFilters); // this.getAllowedCountriesTask.perform()
    },

    afterLoadLists() {
      this._super(...arguments);

      const allowedRelationships = R.pathOr([], ['crud', 'lists', 'contacts-lists-relationships'])(this);
      this.set('allowedRelationships', allowedRelationships);
    },

    // getAllowedCountriesTask: task(function * () {
    //   const allowedCountries = yield this.server.call('GET', 'api/protected/settings/countries', {})
    //   this.set('allowedCountries', allowedCountries?.data || [])
    // }),
    filteredPaymentMethods: Ember.computed('crud.lists.paymentMethods.[]', 'crud.lists.paymentMethods.@each.synced', 'model._data.docName', 'model._data.details.@each.synced', function () {
      const docName = this?.model?._data?.docName || '';
      const paymentMethods = this?.crud?.lists?.paymentMethods || [];
      const details = this?.model?._data?.details || [];
      const detailsHasConsignmentsPendingId = R.pipe(R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.pluck('consignmentsPendingId'), R.reject(RA.isNilOrEmpty), R.uniq)(details);
      const paymentMethodConsignmentsOnly = R.filter(paymentMethod => {
        const value = paymentMethod?._data?.value || '';

        if (R.equals(value, 'consignment')) {
          return true;
        }

        return false;
      })(paymentMethods);

      if (R.equals(docName, 'consignments') || R.equals(docName, 'consignments-returns')) {
        return paymentMethodConsignmentsOnly;
      }

      if (RA.isNotNilOrEmpty(detailsHasConsignmentsPendingId)) {
        return paymentMethodConsignmentsOnly;
      }

      return paymentMethods;
    }),
    shouldDisableTwEInvoicePrint: Ember.computed('model._data.twEInvoiceCarrierType', function () {
      const isReadonly = this.isReadonly;

      if (isReadonly) {
        return true;
      }

      const isCarrierTypeMobile = R.pathEq(['model', '_data', 'twEInvoiceCarrierType'], 'mobile')(this);

      if (isCarrierTypeMobile) {
        return true;
      }

      return false;
    }),
    isCashBeforeDelivery: Ember.computed('model._data.paymentMethodKey', function () {
      const cashBeforeDelivery = R.pathOr('', ['constants', 'paymentMethods', 'cashBeforeDelivery'])(this);
      return R.pathEq(['model', '_data', 'paymentMethodKey'], cashBeforeDelivery)(this);
    }),
    paymentMethodLabelPrefix: Ember.computed('model._data.paymentMethodCustomLabel', function () {
      const paymentMethodCustomLabel = R.pathOr('', ['model', '_data', 'paymentMethodCustomLabel'])(this);

      if (RA.isNotNilOrEmpty(paymentMethodCustomLabel)) {
        return `${paymentMethodCustomLabel} (`;
      }

      return '';
    }),
    paymentMethodLabelSuffix: Ember.computed('model._data.paymentMethodCustomLabel', function () {
      const paymentMethodCustomLabel = R.pathOr('', ['model', '_data', 'paymentMethodCustomLabel'])(this);

      if (RA.isNotNilOrEmpty(paymentMethodCustomLabel)) {
        return ')';
      }

      return '';
    }),
    docContactRelationship: Ember.computed('docType', 'docName', function () {
      const docType = this.docType;
      const docName = this.docName;

      if (R.includes(docName, ['invoices', 'returns'])) {
        return 'all';
      }

      if (docType === 'sales') {
        return 'customer';
      }

      return 'supplier';
    }),
    showContactRelationship: Ember.computed('docName', function () {
      const docName = this.docName;

      if (R.includes(docName, ['invoices', 'returns'])) {
        return true;
      }

      return false;
    }),
    isPaymentMethodReadonly: Ember.computed('isReadonly', 'model._data.{paymentMethodKey,paymentCreditCardTransactionCode,virtualAccount}', function () {
      const model = this?.model;
      const isSalesInvoice = model?._data?.docType === 'sales' && model?._data?.docName === 'invoices';
      const hasCreditCardTransactionCode = R.pathOr('', ['_data', 'paymentCreditCardTransactionCode'])(model);
      const hasLinePayTransactionCode = R.pathOr('', ['_data', 'paymentLinePayTransactionCode'])(model);

      if (isSalesInvoice) {
        if (hasCreditCardTransactionCode || hasLinePayTransactionCode) {
          return true;
        }
      }

      if (this.isReadonly) {
        return true;
      }

      if (hasCreditCardTransactionCode) {
        return true;
      }

      const hasVirtualAccount = R.anyPass([_nventor.default.isNilOrEmptyDotPathOr(false, '_data.virtualAccount.virtualAccountNumber'), R.pathEq(['_data', 'paymentData', 'type'], 'virtualAccountNative')])(model);
      const isPaid = R.pathEq(['_data', 'virtualAccount', 'status'], 'paid')(model);
      const isPaymentDataStatusApproved = R.pathEq(['_data', 'paymentData', 'status'], 'approved')(model);

      if (hasVirtualAccount && (isPaid || isPaymentDataStatusApproved)) {
        return true;
      }

      return false;
    }),
    showDiscountMethodLabel: Ember.computed('model._data.{discountMethod,discountRate,discountAmount}', function () {
      const model = this.model;
      const discountMethodKey = model.getData('discountMethod');
      const discountRate = model.getData('discountRate');
      const discountAmount = model.getData('discountAmount');
      let showDiscount = true;

      if (discountMethodKey === this.get('constants.discountMethods.onTotal')) {
        // per total
        if ((Ember.isEmpty(discountRate) || discountRate === 0 || discountRate === '0') && (Ember.isEmpty(discountAmount) || discountAmount === 0 || discountAmount === '0')) {
          showDiscount = false;
        }
      }

      return showDiscount;
    }),
    showSettingsAutoPrintInvoices: Ember.computed('model._data.autoPrintInvoices', function () {
      const autoPrintInvoices = this.get('model._data.autoPrintInvoices');

      if (!autoPrintInvoices || autoPrintInvoices === 'auto') {
        return true;
      }

      return false;
    }),
    isQuote: Ember.computed('model._data.docName', function () {
      const docName = this.docName;

      if (docName === 'quotes') {
        return true;
      }

      return false;
    }),

    toggleDeliveryDateZ(deliveryDateOptionModel) {
      const deliveryDateOption = deliveryDateOptionModel.getData('value');
      const model = this.model;
      const details = model.getData('details');

      if (deliveryDateOption === 'multiple') {
        // changing from single to multiple
        const deliveryDateZ = model.getData('deliveryDateZ');
        details.forEach(function (detail) {
          detail.setData('deliveryDateZ', deliveryDateZ);
        });
      } else {
        // changing from multiple to single
        // use latest delivery date in details
        let latestDeliveryDateZ = this.getStartOfDayZ();
        details.forEach(function (detail) {
          if (detail.getData('deliveryDateZ') > latestDeliveryDateZ) {
            latestDeliveryDateZ = detail.getData('deliveryDateZ');
          }
        });
        model.setData('deliveryDateZ', latestDeliveryDateZ);
      }
    },

    /**
     * Observer: re-sums totals when details has changed
     * @type {[type]}
     */
    // detailsHasChanged: observer('recalculateTotals', 'model._data.discountAmount', 'model._data.discountRate', function () {
    //   this.sumTotals()
    // }),

    /**
     * assign sales rep to contact
     */
    assignSalesPerson(person) {
      const model = this.model;
      model.addSalesPerson(person.serialize());
    },

    /**
     * assign sales rep to contact
     */
    unassignSalesPerson(person) {
      const model = this.model;
      model.removeSalesPerson(person);
    },

    fetchRewardTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        query
      } = _ref;
      return function* () {
        query = query.trim();

        if (query) {
          const filters = _this.rewardFilters;
          const adapterName = _this.rewardAdapter;
          filters._data.query = query;
          const results = yield _this.crud.searchInputTask.perform({
            adapterName,
            filters,
            component: _this,
            resultsProperty: 'rewardsList'
          });
          return results;
        }
      }();
    }),
    rewardName: Ember.computed('model._data.rewardData.name', function () {
      return this.model.getData('rewardData.name') || '';
    }),
    setAllowedRelationshipsTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        contactKey
      } = _ref2;
      return function* () {
        const contactModel = yield _this2.crud.findTask.perform({
          adapterName: 'contacts',
          appendPath: `/${contactKey}`
        });
        const contactRelationships = contactModel?._data?.relationships || [];
        let allowedRelationships = R.pathOr([], ['crud', 'lists', 'contacts-lists-relationships'])(_this2);

        if (R.equals(R.length(contactRelationships), 1)) {
          allowedRelationships = R.filter(R.pathEq(['_data', 'value'], R.head(contactRelationships)))(allowedRelationships);
        }

        _this2.set('allowedRelationships', allowedRelationships);
      }();
    }),
    loadContactOnRelationshipChangeTask: (0, _emberConcurrency.task)(function (_ref3, selectedContactRelationship, originalContactRelationship) {
      var _this3 = this;

      let {
        contactKey,
        onLoadContact
      } = _ref3;
      return function* () {
        const newContactRelationship = selectedContactRelationship?._data?.value;

        if (!R.equals(newContactRelationship, originalContactRelationship)) {
          const contactModel = yield _this3.crud.findTask.perform({
            adapterName: 'contacts',
            appendPath: `/${contactKey}`
          });

          if (onLoadContact) {
            _this3.onLoadContact(contactModel);
          }
        }
      }();
    }),
    actions: {
      addAndLoadContactAndUpdateDoInfo(onCloseDialogAction, model, contact) {
        onCloseDialogAction();

        if (this.isEditing) {
          model.setData('contact', contact.getData('name'));
          this.onLoadContact(contact);
        } else {
          return this.onLoadContactAndUpdateDoInfo(model, contact); // const dirty = this.get('onSetupDirty')({
          //   adapterName,
          //   model: this.get('model')
          // })
          // dirty.setData('contact', contact.getData('name'))
          // this._loadContact(dirty, docType, contact)
          // return this.get('onUpdateDocInfoTask').linked().perform(dirty)
        } // model.setData('contact', contact.getData('name'))
        // update contact
        // this.get('onLoadContact')(contact)

      },

      cancelAddContact(onCloseDialogAction) {
        onCloseDialogAction();
      },

      loadShopSource(shop) {
        const model = this.model;
        model.setData('sourceKey', shop.getData('_key'));
        const rewardData = shop.getData('rewardData') || {};
        let rewardDataSerialized = rewardData;

        if (rewardData.serialize) {
          rewardDataSerialized = rewardData.serialize();
        }

        model.setData('rewardData', rewardDataSerialized);

        if (RA.isNotNilOrEmpty(rewardData)) {
          Ember.set(this, 'rewardName', rewardData.name);
        }
      },

      unloadShopSource() {
        const model = this.model;
        model.set('rewardKey', '');
        model.setData('sourceKey', '');
      },

      loadExternalPod(pod) {
        const model = this.model;
        model.setData('externalPodKey', pod.getData('podKey'));
      },

      unloadExternalPod() {
        const model = this.model;
        model.setData('externalPodKey', '');
      },

      loadPaymentMethod(paymentMethod) {
        this.model.loadPaymentMethod({
          paymentMethod
        });
      },

      loadPaymentTerms(paymentTerms) {
        this.model.loadPaymentTerms({
          paymentTerms
        });
      },

      toggleDeliveryDateZ(deliveryDateOptionModel) {
        this.toggleDeliveryDateZ(deliveryDateOptionModel);
      },

      /**
       * assign sales rep to contact
       */
      assignSalesPerson(person) {
        this.assignSalesPerson(person);
      },

      /**
       * unassign sales rep
       */
      unassignSalesPerson(person) {
        this.unassignSalesPerson(person);
      },

      toggleChannelSource(shop) {
        const model = this.model;

        if (shop.getData('_key')) {
          model.setData('source', shop.getData('name'));
        } else {
          model.setData('source', '');
        }
      },

      onLoadRewardItem(selectedItem) {
        const rewardData = R.pipe(R.propOr({}, '_data'), R.pick(['_key', 'name', 'memberLevels']))(selectedItem);
        this.model.setData('rewardData', rewardData);
      },

      onResetRewardItem() {
        this.model.setData('rewardData', {});
      },

      setCarrierType() {
        const model = this.model;

        if (model.getData('taxNumber')) {
          this.model.setData('twEInvoiceCarrierType', 'b2b'); // this.model.setData('twEInvoiceCarrierId', '')
        } else {
          this.model.setData('twEInvoiceCarrierType', ''); // this.model.setData('twEInvoiceCarrierId', '')
        }
      }

    }
  });

  _exports.default = _default;
});