define("client/pods/components/schedules/btn-scheduled/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SchedulesComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = class SchedulesComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "constants", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "search", _descriptor3, this);

      _initializerDefineProperty(this, "settings", _descriptor4, this);

      _initializerDefineProperty(this, "results", _descriptor5, this);

      _initializerDefineProperty(this, "pending", _descriptor6, this);

      let resources = this.args.resources || [];
      resources = R.reject(_nventor.default.isNilOrEmptyDotPath('resourceKey'))(resources);
      const filters = this.search.setupFilters({
        adapterName: 'schedules/filters',
        appendPath: '/resources',
        defaultFiltersData: {
          resources
        }
      });
      this.filters = filters;
      this.fetchScheduledTasksByResourceTask.perform();
    }

    get isDisabled() {
      const hasResults = RA.isNotNilOrEmpty(this.results);

      if (hasResults) {
        return false;
      }

      return true;
    }

    fetchScheduledTasksByResourceTask() {
      var _this = this;

      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const results = yield _this.crud.searchRecordsTask.perform({
          adapterName: 'schedules',
          filters: _this.filters
        });
        _this.results = results;
        _this.pending = R.filter(R.pathEq(['_data', 'status'], _this.constants.schedulesStatus.pending))(results);
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "results", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pending", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchScheduledTasksByResourceTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchScheduledTasksByResourceTask"), _class.prototype)), _class));
  _exports.default = SchedulesComponent;
});