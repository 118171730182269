define("client/pods/channels/shop/shipping-zones/countries/regions/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate(data, attrs) {
      data.hasSelected = false;
      data.isShow = true;
      data.districts = R.pipe(R.propOr([], 'districts'), R.map(district => {
        return attrs.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/countries/districts',
          data: district
        });
      }))(data);

      this._super(data);
    }

  });

  _exports.default = _default;
});