define("client/pods/components/documents/document-totals/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    big: Ember.inject.service(),
    tagName: '',
    roundingCurrency: 0,
    isNotDocSalesInvoice: Ember.computed('model._data.{docName,docType}', function () {
      const docName = this.model.getData('docName');
      const docType = this.model.getData('docType');

      if (docName === 'invoices' && docType === 'sales') {
        return false;
      }

      return true;
    }),
    hasValidPoints: Ember.computed('model._data.fetchedContactRewardData.validPoints', function () {
      const contactValidPoints = this.get('model._data.fetchedContactRewardData.validPoints') || 0;

      if (parseFloat(contactValidPoints) > 0) {
        return true;
      }

      return false;
    }),
    groupedDocsTotal: Ember.computed('hasGroups', 'model._data.{groups.@each.synced,exclTotal,inclTotal,taxTotal,rewardPointEarned,returnRewardAdjustmentPoints,returnRewardAdjustmentAmount}', function () {
      const model = this.model || {};
      const hasGroups = this.get('hasGroups') || false;

      if (model) {
        return model.getGroupedDocumentsTotal({
          document: model,
          hasGroups
        });
      }

      return {
        exclTotal: 0,
        taxTotal: 0,
        inclTotal: 0,
        cashCollectionInclTotal: 0,
        rewardPointEarned: 0,
        returnRewardAdjustmentPoints: 0,
        returnRewardAdjustmentAmount: 0
      };
    }),
    groupedDocsReturnsTotal: Ember.computed('hasReturnsDetails', 'hasGroups', 'hasGroupsReturns', 'model.{returnsInclTotal,netInclTotal,creditCardRefundTotal,_data.groups.@each.synced}', function () {
      const model = this.model || {};
      const hasGroups = this.hasGroups || false;
      const hasGroupsReturns = this.hasGroupsReturns || false;
      const roundingCurrency = model.getData('roundingCurrency') || 0;
      let returnsInclTotal = this.big.newBig(model.get('returnsInclTotal') || 0);
      let netInclTotal = this.big.newBig(model.get('netInclTotal') || 0);
      let creditCardRefundTotal = this.big.newBig(model.get('creditCardRefundTotal') || 0);

      if (hasGroups && hasGroupsReturns) {
        const groups = model.getData('groups') || [];
        R.forEach(group => {
          const groupReturnsInclTotal = group.get('returnsInclTotal') || 0;
          const groupCreditCardRefundTotal = group.get('creditCardRefundTotal') || 0;
          let groupNetInclTotal = group.getData('inclTotal') || 0;

          if (group.hasReturnsDetails) {
            groupNetInclTotal = group.get('netInclTotal') || 0;
          }

          returnsInclTotal = returnsInclTotal.add(groupReturnsInclTotal);
          netInclTotal = netInclTotal.add(groupNetInclTotal);
          creditCardRefundTotal = creditCardRefundTotal.add(groupCreditCardRefundTotal);
        })(groups);
      }

      return {
        returnsInclTotal: returnsInclTotal.toFixed(roundingCurrency),
        netInclTotal: netInclTotal.toFixed(roundingCurrency),
        creditCardRefundTotal: creditCardRefundTotal.toFixed(roundingCurrency)
      };
    }),
    actions: {
      removePromoCode(model) {
        model.setData('promoCode', '');
        model.setData('discountAmount', '');
        model.setData('discountRate', '');
      },

      toggleDiscountType(item) {
        const discType = item.getData('value');
        const model = this.model;

        if (discType === this.get('constants.discountTypes.rate')) {
          const currentDiscValue = model.getData('discountAmount') || model.getData('discountRate');
          model.setData('discountRate', currentDiscValue);
          model.setData('discountAmount', '');
        } else {
          const currentDiscValue = model.getData('discountRate') || model.getData('discountAmount');
          model.setData('discountAmount', currentDiscValue);
          model.setData('discountRate', '');
        }

        model.sumTotals();
      }

    }
  });

  _exports.default = _default;
});