define("client/helpers/hasNotEnoughColumns", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params, hash) {
      const columnsForCurrentRow = params[0] || [];
      const allRows = params[1];
      const adjustments = hash.adjustments || 0;
      const maxColumns = R.reduce((acc, columns) => {
        const currentRowLength = columns.length + adjustments;

        if (currentRowLength > acc) {
          return currentRowLength;
        }

        return acc;
      }, 0)(allRows);

      if (columnsForCurrentRow.length < maxColumns) {
        return true;
      }

      return false;
    }

  });

  _exports.default = _default;
});