define("client/pods/components/files/image-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    transform: '',
    width: '',
    height: '',
    showNotFound: false,
    uploadcareService: Ember.inject.service('uploadcare'),
    src: Ember.computed('image', '_transform', function () {
      return this.uploadcareService.getFileUrl(this.image, this._transform);
    }),
    showImage: Ember.computed('showNotFound', 'image', function () {
      if (!this.showNotFound && !this.image) {
        return false;
      }

      return true;
    }),
    _transform: Ember.computed('transform', 'width', 'height', function () {
      if (this.transform) {
        return this.transform;
      }

      let width = this.width || '';

      if (width === 'auto') {
        width = '';
      }

      let height = this.height || '';

      if (height === 'auto') {
        height = '';
      }

      if (width || height) {
        return `/-/resize/${width}x${height}`;
      }

      return '';
    }),
    actions: {
      onSubmit() {
        if (this.onSubmit) {
          return this.onSubmit();
        }
      }

    }
  });

  _exports.default = _default;
});