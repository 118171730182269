define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-activity/component", ["exports", "ramda", "ramda-extension", "client/utils/nventor"], function (_exports, R, R_, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('docType', 'sales');
      this.crud.addLists(this, [{
        name: 'documents/lists/types/sales'
      }]);
    },

    afterLoadLists() {
      this._super(...arguments);

      const initialPanel = this.tabsManager.getLoadInstructions(this.tab) || _nventor.default.safeHeadOr({}, this.activityDocTypes);

      this.tabsManager.displaySubPanel(this, initialPanel);
    },

    activityDocTypes: Ember.computed('crud.lists.documents-lists-types-sales.[]', function () {
      const docTypesList = R_.dotPath('crud.lists.documents-lists-types-sales')(this) || [];
      return R.pipe(R.filter(option => option._data.docName === 'invoices' || option._data.docName === 'returns'), R.map(resourceData => {
        const docType = resourceData.getData('docType');
        const docName = resourceData.getData('docName');
        resourceData.setData('tabType', 'replaceSubTab');
        resourceData.setData('id', `${docType}-${docName}`);
        resourceData.setData('component', 'channels/shop/campaigns/panels-display/sub-panels-activity/sub-panels-activity-doc');
        return resourceData;
      }))(docTypesList);
    })
  });

  _exports.default = _default;
});