define("client/pods/components/elements/accordian-btn/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    attributeBindings: ['disabled', 'tabindex'],
    tabindex: 0,
    translate: true,
    classNames: ['accordian-btn'],
    classNameBindings: ['disabled', 'toggle:accordian-btn--on:accordian-btn--off'],
    toggleOnIconClassName: 'fas fa-caret-up',
    toggleOffIconClassName: 'fas fa-caret-down',

    click() {
      this.toggleProperty('toggle');
    }

  });

  _exports.default = _default;
});