define("client/pods/components/transporters/t-cat-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TransportersTCatEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class TransportersTCatEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "transporterModel", _descriptor4, this);

      _initializerDefineProperty(this, "transporterKey", _descriptor5, this);

      _initializerDefineProperty(this, "selectedSenderName", _descriptor6, this);

      _initializerDefineProperty(this, "customSenderDetailsToggle", _descriptor7, this);

      _initializerDefineProperty(this, "hadCustomSenderDetails", _descriptor8, this);

      _initializerDefineProperty(this, "customSenderDetails", _descriptor9, this);

      _initializerDefineProperty(this, "previousSenderPersonId", _descriptor10, this);

      _defineProperty(this, "adapterNameContacts", 'contacts');

      this.crud.addLists(this, ['extensions/apps/tw/t-cat/lists/thermosphere', 'extensions/apps/tw/t-cat/lists/package-size', 'extensions/apps/tw/t-cat/lists/product-type', 'extensions/apps/tw/t-cat/lists/waybill-print-type', 'extensions/apps/tw/t-cat/lists/delivery-time']);
      this.transporterKey = R.pathOr('', ['args', 'model', '_data', 'transporterKey'])(this);
      this.storeCustomSenderDetails(this.args?.model);
      this.setupTransporterTask.perform();
    }

    get disallowChangeSenderData() {
      return this.args.isReadonly;
    }

    setTransporterDefaults() {
      const transporterData = R.pathOr({}, ['transporterModel', '_data'])(this);
      const docSalesModel = R.pathOr({}, ['args', 'model'])(this);
      const docSalesData = R.propOr({}, '_data')(docSalesModel);

      if (!this.disallowChangeSenderData && RA.isNilOrEmpty(docSalesData.transporterSenderName)) {
        this.setTransporterSender({
          name: transporterData.senderName
        });
      }

      if (!this.disallowChangeSenderData && RA.isNilOrEmpty(docSalesData.transporterSenderTelephone)) {
        this.setTransporterSender({
          telephone: transporterData.senderTelephone
        });
      }

      if (!this.disallowChangeSenderData && RA.isNilOrEmpty(docSalesData.transporterSenderAddress)) {
        this.setTransporterSender({
          address: transporterData.senderAddress
        });
      }

      if (RA.isNilOrEmpty(docSalesData.transporterAccountNumber)) {
        const defaultTransporterAccount = this.transporterModel.defaultTransporterAccount;
        docSalesModel.setData('transporterAccountNumber', defaultTransporterAccount);
        docSalesModel.setData('transporterAccount', defaultTransporterAccount);
      }

      if (RA.isNilOrEmpty(docSalesData.transporterPackageSize)) {
        docSalesModel.setData('transporterPackageSize', transporterData.packageSizeDefault);
      }

      if (RA.isNilOrEmpty(docSalesData.transporterPackageType)) {
        docSalesModel.setData('transporterPackageType', transporterData.productTypeDefault);
      }

      if (RA.isNilOrEmpty(docSalesData.transporterDeliveryTime)) {
        docSalesModel.setData('transporterDeliveryTime', transporterData.defaultDeliveryTime);
      }

      if (RA.isNilOrEmpty(docSalesData.packages)) {
        docSalesModel.setData('packages', 1);
      }

      if (RA.isNilOrEmpty(docSalesData.person) && RA.isNotNilOrEmpty(docSalesData.transporterContactPerson)) {
        docSalesModel.setData('person', docSalesData.transporterContactPerson);
      }
    }

    storeCustomSenderDetails(model) {
      if (!model) {
        return;
      }

      const hasCustomSenderAddress = model?._data?.hasCustomSenderAddress;

      if (hasCustomSenderAddress) {
        this.customSenderDetailsToggle = true;
        this.hadCustomSenderDetails = true;
        this.customSenderDetails = {
          name: model?._data?.transporterSenderName,
          telephone: model?._data?.transporterSenderTelephone,
          address: model?._data?.transporterSenderAddress,
          personId: ''
        };
      }
    }

    loadCustomSenderDetails(model) {
      if (!this.hadCustomSenderDetails || !model) {
        return;
      }

      this.setTransporterSender(this.customSenderDetails);
    }

    setTransporterSender() {
      let transporterData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const docSalesModel = this.args.model;

      if (!docSalesModel) {
        return;
      }

      if (transporterData?.name) {
        docSalesModel.setData('transporterSenderName', transporterData?.name);
      }

      if (transporterData?.telephone) {
        docSalesModel.setData('transporterSenderTelephone', transporterData?.telephone);
      }

      if (transporterData?.address) {
        docSalesModel.setData('transporterSenderAddress', transporterData?.address);
      }

      if (transporterData?.personId) {
        docSalesModel.setData('transporterDefaultPickupLocationPersonId', transporterData?.personId);
      }
    }

    toggleHasCustomSenderAddress(value) {
      this.args.model.set('_data.hasCustomSenderAddress', value);
      this.args.model.set('_data.transporterSenderPostNumber', '');

      if (value) {
        this.previousSenderPersonId = this.args.model._data.transporterDefaultPickupLocationPersonId;
        this.args.model.set('_data.transporterDefaultPickupLocationPersonId', '');
      } else {
        this.args.model.set('_data.transporterDefaultPickupLocationPersonId', this.previousSenderPersonId);
      }

      this.loadCustomSenderDetails(this.args?.model);
    }

    updateTransporterAccount(transporterAccountModel) {
      const transporterAccountNumber = R.pathOr('', ['_data', 'account'])(transporterAccountModel);
      this.args.model.setData('transporterAccount', transporterAccountNumber);
    }

    updateSenderDetails(sender) {
      this.selectedSenderName = sender?.name;
      this.setTransporterSender(sender);
    }

    refreshModel(model) {
      this.transporterModel = model;
    }

    *setupTransporterTask() {
      yield this.fetchTransporterTask.perform();
      this.setTransporterDefaults();
    }

    *fetchTransporterTask() {
      this.transporterModel = yield this.crud.findTask.perform({
        adapterName: this.adapterNameContacts,
        appendPath: this.transporterKey
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "transporterKey", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedSenderName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "customSenderDetailsToggle", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hadCustomSenderDetails", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "customSenderDetails", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "previousSenderPersonId", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "storeCustomSenderDetails", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "storeCustomSenderDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadCustomSenderDetails", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "loadCustomSenderDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTransporterSender", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setTransporterSender"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleHasCustomSenderAddress", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "toggleHasCustomSenderAddress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTransporterAccount", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "updateTransporterAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSenderDetails", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "updateSenderDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupTransporterTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTransporterTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchTransporterTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTransporterTask"), _class.prototype)), _class));
  _exports.default = TransportersTCatEditorComponent;
});