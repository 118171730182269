define("client/pods/components/channels/shop/shipping-zones/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cXplxSQc",
    "block": "{\"symbols\":[\"@onCancel\",\"@tab\",\"@onDisplay\"],\"statements\":[[5,\"channels/shop/shipping-zones/shipping-zones-editor\",[],[[\"@errors\",\"@isEditing\",\"@isNew\",\"@isRunning\",\"@model\",\"@onCancel\",\"@onSaveTask\",\"@tab\"],[[23,0,[\"errors\"]],true,true,[23,0,[\"crud\",\"createRecordTask\",\"isRunning\"]],[23,0,[\"model\"]],[23,1,[]],[28,\"perform\",[[23,0,[\"crud\",\"createRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"component\",\"model\",\"onAfter\",\"onAfterOptions\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[]],[23,0,[\"model\"]],[23,3,[]],[28,\"hash\",null,[[\"tab\"],[[23,2,[]]]]],[23,2,[]]]]]],null],[23,2,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/panels-new/template.hbs"
    }
  });

  _exports.default = _default;
});