define("client/pods/components/ledgers/journals/cashbook/cashbook-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension"], function (_exports, _component, _emberConcurrencyDecorators, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LedgersJournalsCashbookCashbookEditor = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, (_class = class LedgersJournalsCashbookCashbookEditor extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "date", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "search", _descriptor4, this);

      _initializerDefineProperty(this, "model", _descriptor5, this);

      _initializerDefineProperty(this, "errors", _descriptor6, this);

      _initializerDefineProperty(this, "txType", _descriptor7, this);

      _initializerDefineProperty(this, "contactsList", _descriptor8, this);

      _initializerDefineProperty(this, "docType", _descriptor9, this);

      _initializerDefineProperty(this, "account", _descriptor10, this);

      _initializerDefineProperty(this, "accountKey", _descriptor11, this);

      _defineProperty(this, "adapterName", 'ledgers/journals/cashbook');

      this.crud.addLists(this, ['ledgers/accounts/lists/banks', 'contacts/lists/relationships']);
      this.txFilters = this.search.setupFilters({
        adapterName: 'ledgers/journals/cashbook/filters'
      });
    }

    *setupTask() {
      const txType = this?.args?.tab?.txType || '';
      const accountKey = this?.args?.accountKey || '';
      const txFilters = this.txFilters;
      txFilters.setData('txType', txType);
      txFilters.setData('accountKey', accountKey);
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: txFilters
      });
      const banksList = R_.dotPath('crud.lists.ledgers-accounts-lists-banks')(this) || [];
      const account = banksList.findBy('_data._key', accountKey);
      this.account = account;
      this.contactsLists = [];
      this.txType = txType;
      this.accountKey = accountKey;
      this.docType = this?.args?.tab?.docType || '';
    }

    fetchStatementTask(_ref) {
      var _this = this;

      let {
        key,
        docType,
        dateStart,
        dateEnd
      } = _ref;
      return function* () {
        const params = {
          key,
          docType,
          dateStart,
          dateEnd
        };
        const result = yield _this.crud.findTask.perform({
          adapterName: 'contacts/statements',
          appendPath: `/${docType}`,
          params
        });
        return result;
      }();
    }

    get displayAction() {
      return this.txType;
    }

    get defaultDescription() {
      let desc = 'payment';

      if (this.txType === 'cashIn') {
        desc = 'receipt';
      }

      return this.intl.t(desc);
    }

    get isAllBanks() {
      const account = this?.args?.account?._data?._key || '';

      if (R.equals(account, 'allBanks')) {
        return true;
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "txType", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "contactsList", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "docType", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "account", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "accountKey", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchStatementTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchStatementTask"), _class.prototype)), _class));
  _exports.default = LedgersJournalsCashbookCashbookEditor;
});