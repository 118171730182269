define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zk/n37Fg",
    "block": "{\"symbols\":[\"@automationModel\",\"@tab\"],\"statements\":[[5,\"forms/form-section\",[],[[\"@classNames\",\"@isNarrowOuterContainer\",\"@contentClassNames\"],[\"form-section__outer-container--no-border\",true,\"is-gray\"]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-input-row\",[],[[\"@customBlock\"],[true]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"table-view-container\"],[8],[0,\"\\n      \"],[5,\"layouts/table-view\",[],[[\"@detailsRowComponent\",\"@tableClassNames\",\"@hasFilters\",\"@onDisplay\",\"@onEdit\",\"@onMainAction\",\"@paginationIsFooter\",\"@results\",\"@tab\",\"@tableViewOptions\"],[\"channels/shop/campaigns/items/table-view/detail-row\",\"new-table-view\",false,[22,\"onDisplay\"],[22,\"onEdit\"],[22,\"onDisplay\"],false,[23,1,[\"sorted\"]],[23,2,[]],[28,\"hash\",null,[[\"adapterName\",\"campaign\",\"campaignDetails\",\"isAutomations\"],[\"channels/shop/campaigns/automations/details\",[23,1,[]],[24,[\"automationDetails\"]],true]]]]]],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[1,[28,\"msgs/error-msg\",null,[[\"errors\",\"context\"],[[24,[\"errors\"]],\"details\"]]],false],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/template.hbs"
    }
  });

  _exports.default = _default;
});