define("client/pods/components/elements/slider/component", ["exports", "ramda", "@glimmer/component", "tiny-slider/src/tiny-slider"], function (_exports, R, _component, _tinySlider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ElementsSliderComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class ElementsSliderComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "carousels", _descriptor, this);

      _initializerDefineProperty(this, "originalSection", _descriptor2, this);

      _initializerDefineProperty(this, "tinySlider", _descriptor3, this);

      _initializerDefineProperty(this, "dragDetail", _descriptor4, this);
    }

    sliderBtnsColorClassName(sliderBtnsColor) {
      if (sliderBtnsColor === 'lightColor') {
        return 'light-color';
      }

      if (sliderBtnsColor === 'darkColor') {
        return 'dark-color';
      }

      return '';
    }

    updateButtonStyle(btn, btnClassesNames, icon, controlsSize) {
      if (!btn) {
        return;
      }

      btn.textContent = '';
      R.forEach(className => {
        btn.classList.add(className);
      })(btnClassesNames);
      const span = document.createElement('span');
      span.className = 'icon';
      const i = document.createElement('i');
      const iconClassNamesStr = `${icon} size-${controlsSize}`;
      const iconClassNamesArr = R.split(' ')(iconClassNamesStr);
      i.classList.add(...iconClassNamesArr);
      span.appendChild(i);
      btn.appendChild(span);
    }

    get customStyle() {
      const styles = [];
      const tnsNavColor = this.args.options.navStyle.color;

      if (tnsNavColor) {
        styles.push(`.tns-nav-item { background-color: ${tnsNavColor}; }`);
      }

      const tnsNavActiveColor = this.args.options.navStyle.activeColor;

      if (tnsNavActiveColor) {
        styles.push(`.tns-nav-item.tns-nav-active { background-color: ${tnsNavActiveColor}; }`);
      } else {
        styles.push('.tns-nav-item.tns-nav-active { filter: brightness(0.5); }');
      }

      return `
      <style>
        ${styles.join('\n')}
      </style>
    `;
    }

    setProductImage(product, img) {
      img.src = product.getDefaultImageUrl('/-/scale_crop/200x200/smart/');
    }

    setup() {
      const options = this.args.options || {};
      const defaultOptions = {
        controls: true,
        autoplay: false,
        container: '.tiny-slider-container',
        gutter: 50,
        items: 1,
        mouseDrag: true,
        slideBy: 1,
        swipeAngle: false,
        nav: false // navPosition: 'bottom'

      };
      const tnsOptions = R.mergeRight(defaultOptions, options);
      this.tinySlider = (0, _tinySlider.tns)(tnsOptions);

      if (tnsOptions.controls) {
        this.updateBtn(options);
      }

      if (tnsOptions.nav) {
        this.updateNav(options);
      }
    }

    updateBtn() {
      let {
        controlsStyle,
        controlsPosition,
        controlsSize
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (this.tinySlider) {
        const slider = this.tinySlider.getInfo();
        const btnClassesNames = ['slider-button'];
        const nextBtn = slider.nextButton;
        nextBtn.style.color = controlsStyle.color;
        this.updateButtonStyle(nextBtn, btnClassesNames, 'fas fa-angle-right', controlsSize);
        const prevBtn = slider.prevButton;
        prevBtn.style.color = controlsStyle.color;
        this.updateButtonStyle(prevBtn, btnClassesNames, 'fas fa-angle-left', controlsSize);
        let positionClassName = 'tns-controls-container--inside-img';

        if (controlsPosition === 'outsideImage') {
          positionClassName = 'tns-controls-container--outside-img';
        }
        /** @type {HTMLDivElement} */


        const parentWrapper = slider.container.closest('.tns-ovh');
        parentWrapper.classList.add(positionClassName);
      }
    }

    updateNav() {
      let {
        navItemStyle,
        navPosition,
        navItemSize
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (this.tinySlider) {
        const slider = this.tinySlider.getInfo();
        let positionClassName;

        if (navPosition === 'insideImage') {
          positionClassName = 'tns-nav-container--inside-img';
        }

        if (navPosition === 'outsideImage') {
          positionClassName = 'tns-nav-container--outside-img';
        }

        const navContainerClassNames = ['flex-container-row', 'flex-container--justify-center', `tns-nav-container--${navItemSize}`, positionClassName];
        const navContainer = slider.navContainer;
        navContainer.classList.add(...navContainerClassNames);
        const navItemClassNames = ['tns-nav-item', `tns-nav-item--${navItemSize}`];

        if (navItemStyle === 'navItemDot') {
          navItemClassNames.push('tns-nav-item--dot');
        }

        if (navItemStyle === 'navItemLine') {
          navItemClassNames.push('tns-nav-item--line');
        }

        const navItems = slider.navItems;
        R.forEach(navItem => {
          navItem.textContent = '';
          navItem.classList.add(...navItemClassNames);
        })(navItems);
      }
    }

    addTappable(el, link) {
      const tappable = 'u-tappable';
      const alreadyTappable = el.classList.toString().includes(tappable);
      const isLinkEmpty = R.isEmpty(link.toString());

      if (alreadyTappable && isLinkEmpty) {
        el.classList.remove(tappable);
      } else if (!isLinkEmpty) {
        el.classList.add(tappable);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "carousels", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "originalSection", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tinySlider", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dragDetail", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setProductImage", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setProductImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateBtn", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateBtn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateNav", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateNav"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTappable", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "addTappable"), _class.prototype)), _class));
  _exports.default = ElementsSliderComponent;
});