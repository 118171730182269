define("client/pods/components/extensions/apps/tw/shopee/orders/table-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5JL/cc/8",
    "block": "{\"symbols\":[\"@fetchOrdersTask\",\"@tab\",\"@currentOrders\",\"@ordersFilters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onEdit\",\"@onDisplay\",\"@refreshOrders\"],\"statements\":[[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,1,[\"isRunning\"]]]],{\"statements\":[[0,\"\\n\"],[0,\"\\n  \"],[5,\"layouts/table-view\",[],[[\"@tab\",\"@detailsRowComponent\",\"@results\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onEdit\",\"@onDisplay\",\"@tableViewOptions\"],[[23,2,[]],\"extensions/apps/tw/shopee/orders/table-view/detail-row\",[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,1,[]],[23,7,[]],[23,8,[]],[28,\"hash\",null,[[\"refreshOrders\"],[[23,9,[]]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/shopee/orders/table-view/template.hbs"
    }
  });

  _exports.default = _default;
});