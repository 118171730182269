define("client/pods/components/elements/accordian-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "izDmxdv/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[4,\"if\",[[24,[\"customBlock\"]]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"accordian-btn__label u-float-left\"],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"accordian-btn__icon u-float-right\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"toggle\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"elements/element-icon\",null,[[\"iconClassName\"],[[24,[\"toggleOnIconClassName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"elements/element-icon\",null,[[\"iconClassName\"],[[24,[\"toggleOffIconClassName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"u-float-clear\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"accordian-btn__label u-float-left\"],[8],[0,\"\\n    \"],[1,[28,\"tt\",[[24,[\"label\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"accordian-btn__icon u-float-right\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"toggle\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"elements/element-icon\",null,[[\"iconClassName\"],[[24,[\"toggleOnIconClassName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"elements/element-icon\",null,[[\"iconClassName\"],[[24,[\"toggleOffIconClassName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"u-float-clear\"],[8],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/accordian-btn/template.hbs"
    }
  });

  _exports.default = _default;
});