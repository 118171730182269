define("client/pods/components/contacts/statement-print-with-documents/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataManager: Ember.inject.service(),
    classNames: ['u-print-container'],

    init() {
      this._super(...arguments);

      const contact = this.contact;
      const addressesList = contact.getDetailsList({
        type: 'address',
        defaultProp: 'defaultAddress'
      });
      let defaultAddress = contact.get('defaultAdress');

      if (!defaultAddress) {
        defaultAddress = addressesList.get('firstObject');
      }

      if (defaultAddress) {
        this.set('defaultAddress', defaultAddress._data.value);
      }
    } // transactionsModel: computed('statement._data.transactions.[]', function () {
    //   const transactions = this?.statement?._data?.transactions || []
    //   if (RA.isNotNilOrEmpty(transactions)) {
    //     const transformedTransactions = R.map(transaction => {
    //       const docName = transaction?.docName || ''
    //       const docType = transaction?.docType || ''
    //       return this.dataManager.setAsRecord({
    //         adapterName: `documents/${docType}/${docName}`,
    //         data: transaction
    //       })
    //     })(transactions)
    //     return transformedTransactions
    //   }
    //   return transactions
    // })


  });

  _exports.default = _default;
});