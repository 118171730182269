define("client/pods/workflows/related/types/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'contacts',
        label: 'contacts'
      }, {
        value: 'campaigns',
        label: 'campaigns'
      }, {
        value: 'products',
        label: 'products'
      }, {
        value: 'websites',
        label: 'websites'
      }, {
        value: 'sales-consignments',
        label: 'sales-sales-consignments'
      }, {
        value: 'sales-consignments-returns',
        label: 'sales-sales-consignments-returns'
      }, {
        value: 'sales-invoices',
        label: 'sales-sales-invoices'
      }, {
        value: 'sales-orders',
        label: 'sales-sales-orders'
      }, {
        value: 'sales-quotes',
        label: 'sales-sales-quotes'
      }, {
        value: 'sales-returns',
        label: 'sales-sales-returns'
      }, {
        value: 'purchases-consignments',
        label: 'purchases-purchases-consignments'
      }, {
        value: 'purchases-consignments-returns',
        label: 'purchases-purchases-consignments-returns'
      }, {
        value: 'purchases-invoices',
        label: 'purchases-purchases-invoices'
      }, {
        value: 'purchases-orders',
        label: 'purchases-purchases-orders'
      }, {
        value: 'purchases-quotes',
        label: 'purchases-purchases-quotes'
      }, {
        value: 'purchases-returns',
        label: 'purchases-purchases-returns'
      }, {
        value: 'documents-public',
        label: 'documents-public'
      }, {
        value: 'documents',
        label: 'documents'
      }, {
        value: 'comments',
        label: 'customer service'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});