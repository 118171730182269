define("client/pods/components/modals/modal-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZV8HzRNI",
    "block": "{\"symbols\":[\"@modalDialogIsVisible\",\"@title\",\"@titleSuffix\",\"@isSidebar\",\"@modelContainerClassNames\",\"@modalDialogContainerClassName\",\"@modalDialogContentClassName\",\"@isRunning\",\"&default\"],\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"  \"],[5,\"modals/modal-container-new\",[],[[\"@closeOnOverlayClick\",\"@onClose\",\"@onToggle\",\"@hasModalOverlay\",\"@isDialog\",\"@isSidebar\",\"@modelContainerClassNames\"],[[23,0,[\"closeOnOverlayClick\"]],[28,\"fn\",[[23,0,[\"close\"]]],null],[28,\"fn\",[[28,\"mut\",[[23,1,[]]],null]],null],true,true,[23,4,[]],[23,5,[]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-dialog__container \",[23,6,[]],\" \",[23,0,[\"modalSizeClassName\"]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasModalDialogHeader\"]]],null,{\"statements\":[[0,\"        \"],[5,\"modals/modal-dialog/modal-header\",[],[[\"@onClose\",\"@title\",\"@titleSuffix\",\"@translate\"],[[28,\"fn\",[[28,\"mut\",[[23,1,[]]],null]],null],[23,2,[]],[23,3,[]],[23,0,[\"translate\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-dialog__content \",[23,7,[]]]]],[8],[0,\"\\n        \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,8,[]]]],{\"statements\":[[0,\"\\n          \"],[14,9,[[28,\"fn\",[[23,0,[\"close\"]]],null]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/modals/modal-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});