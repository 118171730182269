define("client/pods/components/ledgers/journals/cashbook/panels-display/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class LedgersJournalsCashbookPanelsDisplay extends _component.default {}

  _exports.default = LedgersJournalsCashbookPanelsDisplay;
});