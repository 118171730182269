define("client/pods/dispatch/lists/processes/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/dispatch/lists/processes/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/dispatch/steps',
    modelObj: _model.default,
    idParam: '_id',
    dispatch: Ember.inject.service(),

    // cacheList: false,
    async findAll() {
      const results = await this._super(...arguments);
      const specsCollectionModel = await this.crud.findTask.perform({
        adapterName: 'specs/collection'
      });
      return this.dispatch.getDispatchProcesses({
        hasDispatched: true,
        results,
        defaultExportSheets: specsCollectionModel.defaultSelectedExportSheets,
        settings: this?.settings?.settingsModel || {}
      });
    }

  });

  _exports.default = _default;
});