define("client/pods/components/faqs/faqs-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FaqsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = class FaqsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "currentIndex", _descriptor3, this);

      _initializerDefineProperty(this, "errors", _descriptor4, this);

      _initializerDefineProperty(this, "faq", _descriptor5, this);

      _initializerDefineProperty(this, "model", _descriptor6, this);

      _initializerDefineProperty(this, "showAllDetails", _descriptor7, this);

      _defineProperty(this, "adapterName", this.args.adapterName);

      _defineProperty(this, "removeMessage", 'are you sure you want to delete');

      this.setupEditing();
    }

    setupEditing() {
      const originalModel = this.model;
      this.model = this.crud.setupDirty({
        adapterName: this.args.adapterName,
        model: this.model
      });

      if (this.args.isNew) {
        const faqModel = this.crud.setupNewRecord({
          adapterName: 'faqs'
        });
        this.faq = faqModel;

        this.model._data.faqs.pushObject(faqModel);
      } else {
        const faqModel = this.args.faq;
        const currentIndex = R.indexOf(faqModel)(originalModel._data.faqs);
        const dirtyFaq = this.crud.setupDirty({
          adapterName: 'faqs',
          model: faqModel
        });

        this.model._data.faqs.replace(currentIndex, 1, [dirtyFaq]);

        this.faq = dirtyFaq;
      }
    }

    removeFaqTask(_ref) {
      var _this = this;

      let {
        adapterName,
        onAfter
      } = _ref;
      return function* () {
        const msg = _this.intl.t(_this.removeMessage);

        if (window.confirm(msg)) {
          const dirty = _this.model;
          const faq = dirty._data.faqs[_this.currentIndex];

          dirty._data.faqs.removeObject(faq);

          yield _this.crud.updateRecordTask.perform({
            adapterName,
            onAfter,
            model: dirty
          });

          _this.args.onRemove();
        }
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentIndex", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.currentIndex;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "faq", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.model;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showAllDetails", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "removeFaqTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "removeFaqTask"), _class.prototype)), _class));
  _exports.default = FaqsEditorComponent;
});