define("client/helpers/pod/logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    settings: Ember.inject.service(),

    compute() {
      return this.settings.getDefaultLogo();
    }

  });

  _exports.default = _default;
});