define("client/pods/payments/card/transactions/model", ["exports", "client/pods/documents/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PaymentsCardTransactionsModel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember._tracked, (_class = class PaymentsCardTransactionsModel extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "constants", _descriptor, this);

      _initializerDefineProperty(this, "dateService", _descriptor2, this);

      _initializerDefineProperty(this, "_data", _descriptor3, this);

      _defineProperty(this, "overDueAfter", 20);
    }

    // minutes
    get ccNumber() {
      const partialNumber = R.pathOr(null, ['_data', 'paymentCreditCard', 'partial_card_number'])(this);

      if (partialNumber) {
        return partialNumber;
      }

      const paymentCreditCardLastFourDigits = R.pathOr(null, ['_data', 'paymentCreditCardLastFourDigits'])(this);

      if (paymentCreditCardLastFourDigits) {
        return paymentCreditCardLastFourDigits;
      }

      const tapPayCardInfo = R.pathOr(null, ['_data', 'firstPaymentResponse', 'card_info'])(this);
      const lastFour = R.propOr(null, 'last_four')(tapPayCardInfo);

      if (lastFour) {
        return lastFour;
      }

      return '';
    }

    get isTapPay() {
      const provider = R.pathOr('', ['_data', 'paymentCreditCardProvider'])(this);
      return provider === 'tapPay';
    }

    get isSuccessfulTransaction() {
      const hasTransactionCode = R.pipe(R.pathOr('', ['_data', 'paymentCreditCardTransactionCode']), RA.isNotNilOrEmpty)(this);
      const hasSuccessfulMessage = R.pipe(R.pathOr('', ['_data', 'paymentResponse', 'msg']), R.toLower, R.equals('success'))(this);
      return hasTransactionCode && hasSuccessfulMessage;
    }

    get isUnsuccessfulTransaction() {
      const isUnsuccessful = R.pipe(R.pathOr('', ['_data', 'paymentsResponses', 'msg']), R.toLower, R.equals('success'), R.not)(this);

      if (isUnsuccessful && !this.isAwaitingPayment) {
        return true;
      } // const isPending = this._data.paymentCreditCardStatus === this.constants.paymentCreditCardStatus.pending
      // const isNotSuccessful = this._data.isSuccessful === false
      // return isPending && isNotSuccessful


      return false;
    }

    get isAwaitingPayment() {
      const isPending = this._data.paymentCreditCardStatus === this.constants.paymentCreditCardStatus.pending;
      const isNotSuccessful = this._data.isSuccessful === false;
      return isPending && isNotSuccessful && !this.paymentTransactionOverDue;
    }

    get paymentTransactionOverDue() {
      const has3ds = R.pathOr(false, ['_data', 'paymentCreditCardThreeDomainSecure'])(this);

      if (!has3ds) {
        return false;
      }

      const now = this.dateService.getMoment().subtract(this.overDueAfter, 'm');
      const paymentCreditCardTransactionTimestampZ = R.pipe(R.pathOr('', ['_data', 'timestampZ']), time => this.dateService.getMoment(time))(this);
      return now.isAfter(paymentCreditCardTransactionTimestampZ);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  /**
   * @typedef ccTemp
   * @type {Object}
   * @property {string} paymentCreditCardProvider
   * @property {string} paymentCreditCardStatus
   * @property {string} paymentsResponses
   * @property {boolean} isSuccessful
   */

  _exports.default = PaymentsCardTransactionsModel;
});