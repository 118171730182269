define("client/pods/components/helpers/info-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KjNXyw4B",
    "block": "{\"symbols\":[\"&default\",\"@msg\",\"@label\",\"&attrs\",\"@customBlock\"],\"statements\":[[4,\"if\",[[24,[\"hasInfoTooltip\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",false],[12,\"class\",\"info-tooltip\"],[13,4],[3,\"on\",[\"click\",[24,[\"toggleIsVisible\"]]]],[3,\"on\",[\"mouseenter\",[23,0,[\"handleMouseEnter\"]]]],[3,\"on\",[\"mouseleave\",[23,0,[\"handleMouseLeave\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,5,[]]],null,{\"statements\":[[0,\"      \"],[14,1,[[23,0,[\"isTooltipVisible\"]],[28,\"fn\",[[23,0,[\"toggleClickToView\"]]],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"tt\",[[23,3,[]]],[[\"translate\"],[[23,0,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"isTooltipVisible\"]]],null,{\"statements\":[[4,\"helpers/info-tooltip-content\",null,[[\"closeOnOverlayClick\",\"hasModalOverlay\",\"isViewable\",\"hasContent\"],[true,[23,0,[\"hasModalOverlay\"]],[23,0,[\"isTooltipVisible\"]],[23,0,[\"hasContent\"]]]],{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"            \"],[14,1,[[28,\"fn\",[[23,0,[\"isTooltipVisible\"]],[23,0,[\"toggleClickToView\"]]],null]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"            \"],[1,[23,2,[]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1,[[23,0,[\"isTooltipVisible\"]],[28,\"fn\",[[23,0,[\"toggleClickToView\"]]],null]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/info-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});