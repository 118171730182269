define("client/pods/components/helpers/repeat-times/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    times: 0,
    timesArray: Ember.computed('times', function () {
      const times = parseInt(this.times);
      return new Array(times);
    })
  });

  _exports.default = _default;
});