define("client/pods/components/qr-code-generator/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global QRCode */
  class QrCodeGeneratorComponent extends _component.default {
    get QRCodeSVG() {
      const data = this.args.data;
      const hasData = RA.isNotNilOrEmpty(data);

      if (hasData) {
        const qrCodeSvg = QRCode.generateSVG(data);
        return qrCodeSvg?.outerHTML || '';
      }

      return null;
    }

    get qrCodeSrc() {
      const data = this.args.data;
      const hasData = RA.isNotNilOrEmpty(data);
      let url = 'https://chart.googleapis.com/chart?cht=qr&chs=180x180&choe=UTF-8&chld=L|2';

      if (hasData) {
        url += `&chl=${encodeURIComponent(data)}`;
        return url;
      }

      return url;
    }

  }

  _exports.default = QrCodeGeneratorComponent;
});