define("client/pods/flows/steps/actions/generate-promo-code/presets/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @TODO: check if used. if not remove
  var _default = _model.default.extend({
    defaults() {
      return {
        allowedShop: '',
        allowedShopKey: '',
        allowedShops: '',
        allowOnSale: '',
        code: '',
        dateEndZ: '',
        dateStartZ: '',
        description: '',
        disallowedShops: '',
        discountAmount: '',
        discountRate: '',
        discountRateOnSale: '',
        isUnlimited: '',
        minRequiredAmount: '',
        status: '',
        type: '',
        unlimited: '',
        usageLimit: '',
        expiryDateStartUnit: 'days',
        expiryDateEndUnit: 'days',
        useExpiryDate: ''
      };
    }

  });

  _exports.default = _default;
});