define("client/pods/components/menus/nav-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rHRWkXfj",
    "block": "{\"symbols\":[\"item\",\"item\",\"&default\"],\"statements\":[[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"if\",[[25,3]],null,{\"statements\":[[4,\"each\",[[24,[\"menu\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[14,3,[[23,2,[]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"menu\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"menus/nav-menu/nav-item\",null,[[\"item\",\"selected\",\"translate\",\"errors\",\"onSelect\"],[[23,1,[]],[24,[\"selected\"]],[24,[\"translate\"]],[24,[\"errors\"]],[28,\"action\",[[23,0,[]],\"selected\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/nav-menu/template.hbs"
    }
  });

  _exports.default = _default;
});