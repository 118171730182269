define("client/pods/components/extensions/apps/tw/shopee/import/products/table-view/detail-row/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwShopeeImportProductsTableViewDetailRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('search'), _dec3 = Ember.inject.service('tabsManager'), _dec4 = Ember.inject.service('extensions/apps/tw/shopee/products'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class ExtensionsAppsTwShopeeImportProductsTableViewDetailRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "searchService", _descriptor2, this);

      _initializerDefineProperty(this, "tabsManagerService", _descriptor3, this);

      _initializerDefineProperty(this, "shopeeProductsService", _descriptor4, this);

      _initializerDefineProperty(this, "productModel", _descriptor5, this);

      _initializerDefineProperty(this, "tab", _descriptor6, this);

      _defineProperty(this, "productsAdapterName", 'products');

      _defineProperty(this, "productsFiltersAdapterName", 'products/filters');

      this.crud.addLists(this, ['products/lists/types', 'statuses']);
      this.tabsManagerService.replaceSubTab(this.args.tableViewOptions.tab, {});
      this.setupProducts();
    }

    get isRunning() {
      if (this.shopeeProductsService.syncProduct.isRunning) {
        return true;
      }

      if (this.shopeeProductsService.syncProducts.isRunning) {
        return true;
      }

      if (this.crud.createRecordTask.isRunning) {
        return true;
      }

      return false;
    }

    setupProducts() {
      const products = this.args?.model?._data || {};
      const data = R.mergeRight(products, {
        name: products.item,
        shopeeCode: products.shopeeCode,
        type: 'inventory'
      });
      this.productModel = this.crud.setupNewRecord({
        data,
        adapterName: this.productsAdapterName
      });
      this.tab = this.tabsManagerService.getActiveSubTab(this.args.tableViewOptions.tab);
      this.productsFilters = this.searchService.setupFilters({
        adapterName: this.productsFiltersAdapterName,
        defaultFiltersData: {
          resultsProperty: 'productsList',
          count: 20,
          page: 1
        }
      });
    }

    afterCreateProductRecord(onCloseDialogAction, product) {
      debugger; // this.args.model.set('_data.name', product._data.name)
      // this.args.model.set('_data.itemKey', product._data._key)

      if (this.args.tableViewOptions.updateOrderDetailsProductKeys) {
        const detail = this?.args?.model;
        this.args.tableViewOptions.updateOrderDetailsProductKeys(product, detail);
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    linkProduct(onCloseDialogAction) {
      const selectedItemKey = this?.args?.model?._data?.itemKey;
      const product = this.productsFilters.productsList.find(item => item._data._key === selectedItemKey);
      product.set('_data.shopeeCode', this?.args?.model?._data?.shopeeCode);
      this.afterCreateProductRecord(onCloseDialogAction, product);
    }

    onCancelProductLink(onCloseDialogAction) {
      const model = this?.args?.model;

      if (model) {
        model.set('_data.name', null);
        model.set('_data.itemKey', null);
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    searchProductsTask() {
      var _this = this;

      let query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return function* () {
        if (query.trim() !== '') {
          const filters = _this.productsFilters; // @TODO: SHOULD ONLY SEARCH FOR PRODUCTS THAT HAVE NOT BEEN ADDED BEFORE

          return yield _this.crud.searchInputTask.perform({
            adapterName: _this.productsAdapterName,
            filters
          }, query);
        }
      }();
    }

    *saveProductTask(model) {
      debugger;
      const productModel = yield this.shopeeProductsService.syncProduct.perform({
        appendPath: 'download',
        model
      });
      this.afterCreateProductRecord(null, productModel);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "searchService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManagerService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "shopeeProductsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "productModel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tab", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupProducts", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setupProducts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterCreateProductRecord", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "afterCreateProductRecord"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "linkProduct", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "linkProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancelProductLink", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onCancelProductLink"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchProductsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveProductTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveProductTask"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwShopeeImportProductsTableViewDetailRowComponent;
});