define("client/helpers/pod/get", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    settings: Ember.inject.service(),

    compute(params, hash) {
      const dotPath = params[0];
      const defaultValue = hash?.defaultValue;
      const value = this.settings.getProp(dotPath);

      if (R.isNil(value) && !R.isNil(defaultValue)) {
        return defaultValue;
      }

      return value;
    }

  });

  _exports.default = _default;
});