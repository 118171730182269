define("client/pods/printer/service", ["exports", "ember-concurrency-decorators", "ramda"], function (_exports, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PrinterService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = class PrinterService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "server", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "printerModels", _descriptor5, this);

      _initializerDefineProperty(this, "selectedPrinter", _descriptor6, this);
    }

    get hasNoOnlinePrinters() {
      const printerModels = this.printerModels || [];
      return printerModels.length === 0;
    }

    *fetchOnlinePrintersTask() {
      const printerModels = yield this.crud.searchRecordsTask.perform({
        adapterName: 'extensions/apps/tw/e-invoices/printer',
        appendPath: '/online',
        filters: this.search.setupFilters({
          adapterName: 'extensions/apps/tw/e-invoices/printer/filters'
        })
      });
      this.printerModels = printerModels;

      if (printerModels.length >= 1) {
        this.selectedPrinter = R.pathOr('', ['printerModels', 0, '_data', 'printerId'])(this);
      }

      return printerModels;
    }

    *rePrintEInvoicesTask(component, document) {
      const msg = this.intl.t('are you sure you want to reprint');

      if (window.confirm(msg)) {
        const appendPath = `/${document.getData('_key')}`;
        const payload = {
          printerId: this.selectedPrinter
        };
        component.set('issueErrors', []);

        try {
          return yield this.server.getAdapter('extensions/apps/tw/e-invoices').rePrint({
            appendPath,
            payload
          });
        } catch (err) {
          this.crud.setValidationErrors('issueErrors', err, component);
          return false;
        }
      }
    }

    *issueAndPrintTask(component, onCloseDialogAction, document, custom, selectedPrinter) {
      const onlinePrinters = yield this.fetchOnlinePrintersTask.perform();

      if (R.isEmpty(onlinePrinters)) {
        throw new Error('no printers found');
      }

      custom.twEInvoicePrint = true;
      custom.twEInvoicePrinterId = selectedPrinter;
      component.set('issueErrors', []);
      const payload = {
        _key: document.getData('_key'),
        custom
      };
      let results;

      try {
        const isPos = R.pathEq(['_data', 'isPos'], true)(document);

        if (isPos) {
          results = yield this.server.getAdapter('extensions/apps/tw/e-invoices').posIssue({
            payload
          });
        } else {
          results = yield this.server.getAdapter('extensions/apps/tw/e-invoices').issue({
            payload
          });
        }

        onCloseDialogAction();
        return results;
      } catch (err) {
        this.crud.setValidationErrors('issueErrors', err, component);
        return false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "printerModels", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedPrinter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchOnlinePrintersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchOnlinePrintersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rePrintEInvoicesTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "rePrintEInvoicesTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "issueAndPrintTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "issueAndPrintTask"), _class.prototype)), _class));
  _exports.default = PrinterService;
});