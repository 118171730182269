define("client/pods/extensions/apps/tw/t-cat/lists/product-type/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtensionsAppsTwTCatListsProductTypeAdapter extends _model.default {}

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: ExtensionsAppsTwTCatListsProductTypeAdapter,
    idParam: false,

    findAll() {
      const data = [{
        value: 'general food',
        label: 'general food'
      }, {
        value: 'specialty and local good',
        label: 'specialty and local good'
      }, {
        value: 'alcoholic beverage / oil / vinegar / sauce',
        label: 'alcoholic beverage / oil / vinegar / sauce'
      }, {
        value: 'grains, vegetables and fruits',
        label: 'grains, vegetables and fruits'
      }, {
        value: 'seafood / meat',
        label: 'seafood / meat'
      }, {
        value: 'computer, communication and consumer electronics',
        label: 'computer, communication and consumer electronics'
      }, {
        value: 'household appliances',
        label: 'household appliances'
      }, {
        value: 'clothing and accessories',
        label: 'clothing and accessories'
      }, {
        value: 'daily necessities',
        label: 'daily necessities'
      }, {
        value: 'makeup and cosmetics',
        label: 'makeup and cosmetics'
      }, {
        value: 'dietary supplement',
        label: 'dietary supplement'
      }, {
        value: 'medical supplies',
        label: 'medical supplies'
      }, {
        value: 'pet supplies',
        label: 'pet supplies'
      }, {
        value: 'printed products',
        label: 'printed products'
      }, {
        value: 'other',
        label: 'other'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});