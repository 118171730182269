define("client/pods/components/channels/shop/campaigns/panels-edit/component", ["exports", "client/mixins/crud", "ramda-extension", "ramda-adjunct", "ember-concurrency"], function (_exports, _crud, R_, RA, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    adapterName: 'channels/shop/campaigns',

    init() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      this.crud.addLists(this, ['channels/shop/campaigns/lists/subPanels']);
      const model = this.get('tab.model');
      const dirty = this.setupDirty({
        model
      });
      this.set('dirty', dirty); // if (!dirty.getData('password')) {
      //   const password = nventor.random.alphaNum(8).toLowerCase()
      //   dirty.setData('password', password)
      // }

      this.tabsManager.setHeader(this.tabParent, model.getData('name'));
    },

    _displayCampaign(model) {
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);
      const subPanels = R_.dotPath('crud.lists.channels-shop-campaigns-lists-subPanels')(this) || [];
      let subTabOptions = subPanels.findBy('_data.value', activeSubTab.get?.('value'));

      if (RA.isNilOrEmpty(subTabOptions)) {
        subTabOptions = subPanels.findBy('_data.editingComponent', activeSubTab.get?.('component'));
      }

      const tabOptions = {
        loadInstructions: {
          component: subTabOptions.get?.('_data.component'),
          value: subTabOptions.get?.('_data.value')
        }
      };
      return this.onDisplay(model, {
        tabOptions
      });
    },

    afterUpdateRecordTask(saved) {
      this._displayCampaign(saved);
    },

    afterReplaceRecordTask(saved) {
      this._displayCampaign(saved);
    },

    afterCancelEditing(original) {
      this._displayCampaign(original);
    },

    onSaveTask: (0, _emberConcurrency.task)(function* () {
      yield this.crud.replaceRecordTask.perform({
        component: this,
        adapterName: this.adapterName,
        model: this.dirty,
        tab: this.tab,
        onAfter: this.afterReplaceRecordTask
      });
    }),
    actions: {
      cancel(dirty) {
        this.cancelEditing({
          adapterName: this.adapterName,
          dirty: dirty
        });
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "afterUpdateRecordTask", [_dec], Object.getOwnPropertyDescriptor(_obj, "afterUpdateRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "afterReplaceRecordTask", [_dec2], Object.getOwnPropertyDescriptor(_obj, "afterReplaceRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "afterCancelEditing", [_dec3], Object.getOwnPropertyDescriptor(_obj, "afterCancelEditing"), _obj)), _obj)));

  _exports.default = _default;
});