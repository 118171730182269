define("client/mixins/emails-dispatched", ["exports", "ramda", "ember-concurrency", "client/utils/nventor", "ramda-adjunct"], function (_exports, R, _emberConcurrency, _nventor, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getToSendBatchEmailsTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        dispatchProcess,
        dispatchDateZ,
        errorsProp = 'errors'
      } = _ref;
      return function* () {
        try {
          _this.clearValidationErrors(errorsProp);

          dispatchDateZ = dispatchDateZ || _this.get('period.dateZ');
          const response = yield _this.server.call('GET', 'api/protected/documents/emails/process', {
            dispatchDateZ,
            dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
            dispatchProcess: dispatchProcess.getData('code')
          });
          return R.propOr([], 'data')(response);
        } catch (err) {
          _this.setValidationErrors(errorsProp, err);

          _nventor.default.throw('getToSendBatchEmailsTask failed', err);

          return false;
        }
      }();
    }),
    sendBatchEmailsTask: (0, _emberConcurrency.task)(function (onCloseDialogAction, _ref2) {
      var _this2 = this;

      let {
        subject,
        message,
        dispatchProcess,
        dispatchDateZ,
        errorsProp = 'errors'
      } = _ref2;
      return function* () {
        try {
          _this2.clearValidationErrors(errorsProp);

          dispatchDateZ = dispatchDateZ || _this2.get('period.dateZ');
          const resp = yield _this2.server.call('PUT', 'api/protected/documents/emails/process', {
            subject,
            message,
            dispatchDateZ,
            dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
            dispatchProcess: dispatchProcess.getData('code')
          });
          let sent = 0;

          if (resp.data) {
            sent = resp.data.length;
          }

          onCloseDialogAction();
          window.alert('sent: ' + sent + ' emails.');
        } catch (err) {
          _this2.setValidationErrors(errorsProp, err);

          _nventor.default.throw('getToSendBatchEmailsTask failed', err);

          return false;
        }
      }();
    }),
    getToSendAllDispatchedEmailsTask: (0, _emberConcurrency.task)(function (_ref3) {
      var _this3 = this;

      let {
        dispatchDateZ,
        errorsProp = 'errors'
      } = _ref3;
      return function* () {
        try {
          _this3.clearValidationErrors(errorsProp);

          dispatchDateZ = dispatchDateZ || _this3.get('period.dateZ');
          const response = yield _this3.server.call('GET', 'api/protected/documents/emails/dispatched', {
            dispatchDateZ
          });
          return R.propOr([], 'data')(response);
        } catch (err) {
          _this3.setValidationErrors(errorsProp, err);

          _nventor.default.throw('getToSendBatchEmailsTask failed', err);

          return false;
        }
      }();
    }),
    sendAllDispatchedEmailsTask: (0, _emberConcurrency.task)(function (onCloseDialogAction, _ref4) {
      var _this4 = this;

      let {
        useDefaultDispatchedEmailMessage,
        subject,
        message,
        dispatchDateZ,
        useDefaultsDispatchedEmailEstimatedTransporterArrivalDate = true,
        dispatchedEmailHasEstimateTransporterArrivalDate = true,
        dispatchedEmailEstimateTransporterArrivalDaysFromDispatch = 1,
        dispatchedEmailTransporterArrivalDateSkipWeekends = false,
        errorsProp = 'errors'
      } = _ref4;
      return function* () {
        try {
          _this4.clearValidationErrors(errorsProp);

          dispatchDateZ = dispatchDateZ || _this4.get('period.dateZ');

          if (useDefaultDispatchedEmailMessage) {
            subject = '';
            message = '';
          }

          const resp = yield _this4.server.call('PUT', 'api/protected/documents/emails/dispatched', {
            subject,
            message,
            dispatchDateZ,
            useDefaultsDispatchedEmailEstimatedTransporterArrivalDate,
            dispatchedEmailHasEstimateTransporterArrivalDate,
            dispatchedEmailEstimateTransporterArrivalDaysFromDispatch,
            dispatchedEmailTransporterArrivalDateSkipWeekends,
            useDefaultDispatchedEmailMessage
          });
          let sent = 0;

          if (resp.data) {
            sent = resp.data.length;
          }

          onCloseDialogAction();
          window.alert('sent: ' + sent + ' emails.');
        } catch (err) {
          _this4.setValidationErrors(errorsProp, err);

          _nventor.default.throw('getToSendBatchEmailsTask failed', err);

          return false;
        }
      }();
    }),
    getToSendDispatchedEmailsTask: (0, _emberConcurrency.task)(function (_ref5) {
      var _this5 = this;

      let {
        dispatchDateZ,
        errorsProp = 'errors'
      } = _ref5;
      return function* () {
        const docKey = _this5.get('model._data._key');

        try {
          _this5.clearValidationErrors(errorsProp);

          const docType = _this5.get('model._data.docType');

          const docName = _this5.get('model._data.docName');

          const groupId = _this5.get('model._data.groupId');

          const response = yield _this5.server.call('GET', `api/protected/documents/emails/dispatched/${docKey}`, {
            docType,
            docName,
            groupId
          });
          return R.propOr([], 'data')(response);
        } catch (err) {
          _this5.setValidationErrors(errorsProp, err);

          _nventor.default.throw('getToSendBatchEmailsTask failed', err);

          return false;
        }
      }();
    }),
    sendDispatchedEmailsTask: (0, _emberConcurrency.task)(function (onCloseDialogAction, _ref6) {
      var _this6 = this;

      let {
        useDefaultDispatchedEmailMessage,
        message,
        dispatchDateZ,
        useDefaultsDispatchedEmailEstimatedTransporterArrivalDate = true,
        dispatchedEmailHasEstimateTransporterArrivalDate = true,
        dispatchedEmailEstimateTransporterArrivalDaysFromDispatch = 1,
        dispatchedEmailTransporterArrivalDateSkipWeekends = false,
        docKey,
        docType,
        docName
      } = _ref6;
      return function* () {
        dispatchDateZ = dispatchDateZ || _this6.get('period.dateZ');

        if (useDefaultDispatchedEmailMessage) {
          message = '';
        }

        const groupId = _this6.get('model._data.groupId');

        const resp = yield _this6.server.call('PUT', `api/protected/documents/emails/dispatched/${docKey}`, {
          message,
          dispatchDateZ,
          docType,
          docName,
          groupId,
          useDefaultsDispatchedEmailEstimatedTransporterArrivalDate,
          dispatchedEmailHasEstimateTransporterArrivalDate,
          dispatchedEmailEstimateTransporterArrivalDaysFromDispatch,
          dispatchedEmailTransporterArrivalDateSkipWeekends
        });
        const data = resp?.data || [];
        const failedEmails = R.filter(R.equals(false))(data);
        const sentEmails = R.reject(R.equals(false))(data);
        let msg = '';

        if (_ramdaAdjunct.default.isNotNilOrEmpty(failedEmails)) {
          msg += `${_this6.intl.t('email not sent')}: ${R.length(failedEmails)}`;

          if (_ramdaAdjunct.default.isNotNilOrEmpty(sentEmails)) {
            msg += '\\n';
          }
        }

        if (_ramdaAdjunct.default.isNotNilOrEmpty(sentEmails)) {
          const emails = R.pipe(R.pluck('email'), R.reject(_ramdaAdjunct.default.isNilOrEmpty), R.uniq, R.join(', '))(sentEmails);
          msg += _this6.intl.t('email sent to') + ': ' + emails;
          onCloseDialogAction();
        }

        window.alert(msg);
      }();
    }),
    sendPaymentReminderEmailsTask: (0, _emberConcurrency.task)(function (onCloseDialogAction, _ref7) {
      var _this7 = this;

      let {
        email,
        message,
        dispatchDateZ,
        docKey,
        docType,
        docName
      } = _ref7;
      return function* () {
        const payload = email._data || {};
        payload.docType = docType;
        payload.docName = docName;
        const resp = yield _this7.server.call('POST', `api/protected/documents/emails/payment-reminder/${docKey}`, payload);
        const emailSent = R.path(['data', 'email'], resp);

        let msg = _this7.intl.t('email not sent');

        if (emailSent) {
          msg = _this7.intl.t('email sent to') + ': ' + emailSent;
          onCloseDialogAction();
        }

        window.alert(msg);
      }();
    })
  });

  _exports.default = _default;
});