define("client/pods/components/documents/document-print/document-thermal-print/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentsDocumentPrintDocumentThermalPrintComponent = (_dec = Ember._tracked, (_class = class DocumentsDocumentPrintDocumentThermalPrintComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isTermsVisible", _descriptor, this);
    }

    get source() {
      const model = this.args?.model || {};
      const source = model?._data?.source || '';

      if (!source || typeof source !== 'string') {
        return '';
      }

      if (source.length > 30) {
        return `${source.substring(0, 27)}...`;
      }

      return source;
    }

    get paymentMethodKeys() {
      const model = this.args?.model || {};
      const hasGroups = this.args?.hasGroups || false;

      if (model) {
        return model.getGroupedDocumentsPaymentMethodKeys({
          document: model,
          hasGroups,
          hasPaymentLastFiveDigits: true,
          isJoinResults: true,
          isTranslate: true
        });
      }

      return [];
    }

    get showSubHeader() {
      const model = this.args?.model || {};
      const ref = model?._data?.ref || '';
      const paymentMethodKey = model?._data?.paymentMethodKey || '';
      const showFromPerson = this?.args?.showFromPerson || false;

      if (ref) {
        return true;
      }

      if (paymentMethodKey) {
        return true;
      }

      if (showFromPerson) {
        return true;
      }

      return false;
    }

    get hasPrintTerms() {
      if (!this.isTermsVisible) {
        return false;
      }

      const model = this.args?.model || {};
      return RA.isNotNilOrEmpty(model?._data?.terms);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isTermsVisible", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  })), _class));
  _exports.default = DocumentsDocumentPrintDocumentThermalPrintComponent;
});