define("client/pods/components/forms/form-sub-section/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FormsFormSubSection extends _component.default {
    get translate() {
      return R.pathOr(true, ['args', 'translate'])(this);
    }

  }

  _exports.default = FormsFormSubSection;
});