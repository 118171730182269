define("client/initializers/storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'storage', 'service:storage');
    application.inject('adapter', 'storage', 'service:storage');
    application.inject('component', 'storage', 'service:storage');
    application.inject('service:server', 'storage', 'service:storage');
    application.inject('service:extensions', 'storage', 'service:storage');
    application.inject('service:search', 'storage', 'service:storage');
    application.inject('service:socket', 'storage', 'service:storage');
    application.inject('service:grapesjs', 'storage', 'service:storage');
  }

  var _default = {
    name: 'storage',
    initialize: initialize
  };
  _exports.default = _default;
});