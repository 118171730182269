define("client/pods/products/choices/model", ["exports", "ramda", "client/pods/base/model", "client/mixins/sortable-details", "client/mixins/products-children"], function (_exports, R, _model, _sortableDetails, _productsChildren) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object'
  // import nventor from '../../../utils/nventor'
  // import ChoicesDetailsModel from './details/model'
  var _default = _model.default.extend(_productsChildren.default, _sortableDetails.default, {
    toSortProp: '_data.details',

    init() {
      this._super(...arguments);

      this.set('childAdapters', {
        choices: 'products/choices',
        'choices-details': 'products/choices/details',
        'sets-details': 'products/sets/details',
        variants: 'products/variants',
        'variants-options': 'products/variants/options',
        'variants-children': 'products/variants/children'
      });
    },

    defaults() {
      return {};
    },

    toSortArray: Ember.computed('_data.details.[]', 'synced', function () {
      return this.get('_data.details');
    }),

    setChildProductPrice(child, historyData) {
      const isCampaign = R.prop('campaign', historyData);

      this._setItemPrice(isCampaign, child, historyData);

      this._setVariantChildrenPrices(isCampaign, child, historyData);
    },

    _setItemPrice(isCampaign, item, historyData) {
      if (isCampaign) {
        const price = R.propOr('', 'price', historyData);
        const priceDiscounted = R.propOr('', 'priceDiscounted', historyData);
        const onSale = R.propOr('', 'onSale', historyData);

        if (onSale) {
          item.setData('onSale', onSale);
        }

        item.setData('price', price);
        item.setData('priceDiscounted', priceDiscounted);
      } else {
        let price;
        price = historyData.inclPrice;

        if (!price) {
          price = R.propOr('', 'salesInclPrice', historyData);
        }

        item.setData('price', price);
        item.setData('priceDiscounted', '');
      }
    },

    _setVariantChildrenPrices(isCampaign, item, historyData) {
      const variantChildrenHistory = R.propOr([], 'variantChildren')(historyData);
      R.pipe(R.pathOr([], ['_data', 'variantChildren']), R.forEach(child => {
        const variantChildId = child._data.variantChildId;
        let childHistory = R.find(R.propEq('variantChildId', variantChildId))(variantChildrenHistory);

        if (!childHistory) {
          const variantChildUid = child._data.variantChildUid;
          childHistory = R.find(R.propEq('variantChildUid', variantChildUid))(variantChildrenHistory);
        }

        if (childHistory) {
          this._setItemPrice(isCampaign, child, childHistory);
        }
      }))(item);
    },

    addChoiceDetail(data) {
      const details = this._data.details || [];
      const lastDetail = details.get('lastObject');
      let index = 1;

      if (lastDetail) {
        const lastIndex = parseInt(lastDetail._data.index) || 0;
        index = lastIndex + 1;
      }

      const defaultData = {
        index,
        name: '',
        status: this.get('constants.status.active')
      };
      data = data || defaultData;
      const detailModel = this.dataManager.setAsRecord({
        adapterName: 'products/choices/details',
        data
      });
      details.pushObject(detailModel);
      this.setData('details', details);
      return data;
    },

    removeChoiceDetail(detail) {
      const details = this._data.details || [];

      if (details.get('lastObject._data.index') === detail._data.index) {
        // do not remove detail, clear detail only
        this.resetChoiceDetail(detail);
      } else {
        details.removeObject(detail);
        this.resetIndexes(details);
      }
    },

    resetChoiceDetail(detail) {
      const data = detail.get('_data');
      const keys = Object.keys(data);
      keys.forEach(function (key) {
        let allowReset = true; // @TODO: could set a disallowResetAttrs array,
        // @TODO: eg. disallowResetAttrs: ['index', 'deliveryDateZ']
        // @TODO: then it will not allow resetting those values

        const allowResetKey = R.anyPass([R.equals('index'), R.equals('status')])(key);

        if (allowResetKey) {
          allowReset = false;
        }

        if (allowReset) {
          detail.setData(key, '');
        }
      });
    }

  });

  _exports.default = _default;
});