define("client/pods/products/templates/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductsTemplatesModel = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class ProductsTemplatesModel extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "_data", _descriptor, this);
    }

    defaults() {
      return {
        template: '',
        header: this.intl.t('product information'),
        status: this.get('constants.status.active')
      };
    }

    replaceTemplate(template) {
      let templateImages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      this.setData('template', template);
      this.setData('templateImages', templateImages);
    }

    insertTemplate(template) {
      let templateImages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      const currentTemplate = this.getData('template');
      const currentTemplateImages = this.getData('templateImages') || [];
      let newTemplate = '';

      if (currentTemplate) {
        newTemplate = `${currentTemplate}<br>`;
      }

      newTemplate = `${newTemplate} ${template}`;
      const newTemplateImages = R.concat(currentTemplateImages, templateImages);
      this.setData('template', newTemplate);
      this.setData('templateImages', newTemplateImages);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "replaceTemplate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "replaceTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insertTemplate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "insertTemplate"), _class.prototype)), _class));
  _exports.default = ProductsTemplatesModel;
});