define("client/pods/products/service", ["exports", "ramda", "ramda-adjunct", "ember-concurrency", "client/utils/nventor"], function (_exports, R, RA, _emberConcurrency, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    settings: Ember.inject.service(),
    server: Ember.inject.service(),
    bigService: Ember.inject.service('big'),
    constants: Ember.inject.service(),
    search: Ember.inject.service(),
    crud: Ember.inject.service(),

    init() {
      this._super('...arguments');

      this.set('statusClasses', {
        [this.get('constants.productsStatus.active')]: 'is-primary',
        [this.get('constants.productsStatus.inactive')]: 'is-danger',
        [this.get('constants.productsStatus.preOrder')]: 'is-warning',
        [this.get('constants.productsStatus.draft')]: 'is-warning',
        [this.get('constants.productsStatus.outOfStock')]: 'is-black',
        [this.get('constants.productsStatus.soldOut')]: 'is-black'
      });
    },

    searchInputTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        let {
          adapterName = 'products',
          filtersAdapterName = 'products/filters',
          query = '',
          resultsProperty = '',
          component,
          resultsToggle = false,
          global = true,
          filters
        } = args;

        if (RA.isNilOrEmpty(filters)) {
          filters = _this.search.setupFilters({
            adapterName: filtersAdapterName,
            defaultFiltersData: {
              query,
              match: [],
              sort: [{
                by: 'name',
                direction: 'ASC'
              }],
              status: [_this.get('constants.status.active')]
            }
          });
        }

        const results = yield _this.crud.searchInputTask.perform({
          adapterName,
          filters,
          query,
          resultsProperty,
          component,
          resultsToggle
        });

        if (global && resultsProperty) {
          // this[resultsProperty] = results
          _this.set(resultsProperty, results);
        }

        return results;
      }();
    }),

    getStatusClass(status) {
      const statusClasses = this.statusClasses;
      return R.propOr(this.unknownStatusClass, status, statusClasses);
    },

    fetchDocHistoryTask: (0, _emberConcurrency.task)(function* (docModel, preferredKey, product, itemKey) {
      const docType = docModel.getData('docType');
      const docName = docModel.getData('docName');
      const contactKey = docModel.getData('contactKey');
      const contact = docModel.getData('contact');
      const priceHistoryData = yield this.getHistoryTask.perform({
        docType,
        docName,
        contact,
        contactKey,
        itemKey
      });
      let preferredHistoryData;
      preferredHistoryData = this.getLatestFor(preferredKey, priceHistoryData);

      if (!preferredHistoryData) {
        preferredHistoryData = product.serialize({
          exclude: ['_key', '_rev', '_id']
        });
      }

      const isPos = R.pathEq(['_data', 'isPos'], true)(docModel);

      if (isPos) {
        priceHistoryData.history = R.pipe(R.propOr({}, 'history'), R.over(R.lensProp('others'), function () {
          let others = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
          return R.sortWith([R.descend(R.prop('isPos'))])(others);
        }))(priceHistoryData);
      }

      return {
        priceHistoryData,
        preferredHistoryData
      };
    }).enqueue().maxConcurrency(4),
    fetchPurchasesHistoryTask: (0, _emberConcurrency.task)(function* (docModel, preferredKey, product, itemKey) {
      docModel.setData('docType', 'purchases');
      docModel.setData('docName', 'invoices');
      return yield this.fetchDocHistoryTask.perform(docModel, preferredKey, product, itemKey);
    }),
    getHistoryTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this2 = this;

      let {
        docType,
        docName,
        contact,
        contactKey,
        item,
        itemKey,
        docTypes
      } = _ref;
      return function* () {
        const data = {
          contact,
          contactKey,
          item,
          itemKey,
          docTypes
        };
        const results = yield _this2.server.call('GET', `api/protected/documents/data/prices/${docType}/${docName}`, data);
        return R.propOr({}, 'data', results);
      }();
    }).enqueue().maxConcurrency(4),

    getFor(prop, historyData) {
      return R.pathOr([], ['history', prop], historyData);
    },

    getLatestFor(prop, historyData) {
      // return R.pathOr([], ['history', prop], historyData)
      const forProp = this.getFor(prop, historyData);
      let found = false;

      if (!R.isEmpty(forProp)) {
        found = R.head(forProp);
      }

      return found;
    },

    getLatestDetail(props, historyData) {
      let found = '';
      R.forEach(prop => {
        if (!found) {
          const forProp = this.getFor(prop, historyData);

          if (!R.isEmpty(forProp)) {
            found = R.head(forProp);
          }
        }
      }, props);
      return found || false;
    },

    setPrice(model, historyData, onHideDropdown) {
      model.setPrice(historyData);

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    setDetailPrice(detail, child, historyData, onHideDropdown) {
      detail.setDetailPrice(child, historyData);

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    setProductCommissionsBaseCost(detail, historyData, onHideDropdown) {
      detail.setProductCommissionsBaseCost(detail, historyData);

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    selectChildHistoryPrice(detail, historyData, onHideDropdown) {
      detail.setChildProductPrice(detail, historyData);

      if (R.pathEq(['_data', 'isSet'], true, detail)) {
        detail.recalculatePriceForSet(detail);
      }

      if (onHideDropdown) {
        onHideDropdown();
      }
    },

    getTaxRate(docType, taxes, product) {
      // @TODO: should get taxRateKey from product to fetch tax rate from lists.tax
      // also fetch tax rate label from lists.tax
      // if no taxRate from product then get from settings
      // PRO: if tax rate is removed, then assume taxrate no longer available
      //      but if using taxrate and label stored in products, it will use old taxrate/label instead of settings
      // CON: requires additional step to search lists.tax
      const taxRateKey = product.getData(`${docType}TaxRateKey`);
      let taxRate;

      if (taxRateKey) {
        const productTax = taxes.findBy('_data._key', taxRateKey);

        if (productTax) {
          taxRate = productTax.getData('value');
        }
      }

      if (!taxRateKey || taxRateKey === 'auto') {
        const defaultTaxRate = this.settings.getDefaultTaxRate(taxes);
        taxRate = defaultTaxRate;
      }

      if (taxRateKey === 'none') {
        taxRate = 0;
      }

      return taxRate;
    },

    getRecurringFiltersTask: (0, _emberConcurrency.task)(function* () {
      yield this.crud.addLists(this, ['products/lists/recurring-filters', 'recurring/schedules/options']);
      const productsListsRecurringFilters = R.pathOr([], ['crud', 'lists', 'products-lists-recurring-filters'])(this);
      const recurringSchedulesOptions = R.pathOr([], ['crud', 'lists', 'recurring-schedules-options'])(this);
      let recurringFilters = R.concat(productsListsRecurringFilters, recurringSchedulesOptions);
      recurringFilters = R.map(recurringFilter => {
        const hasTranslate = R.pathEq(['_data', 'translate'], true)(recurringFilter);

        if (recurringFilter?._data) {
          Ember.set(recurringFilter, '_data.translate', hasTranslate);
        }

        return recurringFilter;
      })(recurringFilters) || [];
      return recurringFilters;
    }),
    resetInventoryDataTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this3 = this;

      let {
        inventoryResetDateZ
      } = _ref2;
      return function* () {
        if (RA.isNilOrEmpty(inventoryResetDateZ)) {
          return;
        }

        yield _this3.server.call('POST', 'api/protected/products/reset-inventory-data', {
          inventoryResetDateZ,
          revalidateAllReport: true
        });
      }();
    }),
    setAsDefaultImageTask: (0, _emberConcurrency.task)(function (_ref3) {
      var _this4 = this;

      let {
        adapterName = 'products',
        resource = 'products',
        resourceKey,
        uploadsModel
      } = _ref3;
      return function* () {
        // no need to send entire record over, should only update record, not replace
        const product = yield _this4.dataManager.findRecord({
          adapterName,
          appendPath: `/${resourceKey}`
        });

        const dirty = _this4.dataManager.getDirtyRecord(adapterName, product);

        dirty.setData('defaultImage', uploadsModel?._data?.uuid);

        try {
          return yield _this4.dataManager.updateRecord({
            adapterName,
            dirty
          });
        } catch (err) {
          _this4.set('errors', err.data);

          _nventor.default.throw('setAsDefaultImageTask failed', err);
        }
      }();
    }).drop()
  });

  _exports.default = _default;
});