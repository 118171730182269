define("client/pods/components/modals/modal-dialog/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ModalsModalDialogComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class ModalsModalDialogComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "hasModalDialogHeader", _ramdaAdjunct.default.isNotNilOrEmpty(this.args.hasModalDialogHeader) ? this.args.hasModalDialogHeader : true);

      if (this.args.onInit) {
        this.args.onInit(this);
      }
    }

    get translate() {
      return R.pathOr(true, ['args', 'translate'])(this);
    }

    get closeOnOverlayClick() {
      return R.pathOr(true, ['args', 'closeOnOverlayClick'])(this);
    }

    get modalSizeClassName() {
      const modalSize = this.args?.modalSize || 'large';

      if (modalSize === 'small') {
        return 'modal-dialog__container-small';
      }

      if (modalSize === 'medium') {
        return 'modal-dialog__container-medium';
      }

      return '';
    }

    close() {
      if (this.args.onToggle) {
        this.args.onToggle(false);
      }

      if (this.args.onClose) {
        this.args.onClose();
      }
    }

    willDestroyElement() {
      if (this?.isDestroyed === false) {
        this.close();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "close", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyElement", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyElement"), _class.prototype)), _class));
  _exports.default = ModalsModalDialogComponent;
});