define("client/pods/components/helpers/creator-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    tagName: 'span',
    classNames: ['u-desc', 'u-desc--mini', 'u-desc--italics'],
    iconOnly: false,
    popupIsVisible: false,
    showPopup: false,
    sortedModified: Ember.computed('model._data.modified.[]', function () {
      let modified = this.get('model._data.modified') || [];

      if (!Ember.isArray(modified)) {
        modified = [modified];
      }

      return modified.sortBy('md');
    }),
    isDropdownVisible: Ember.computed('showDropdown', 'iconOnly', 'sortedModified.[]', function () {
      if (this.iconOnly && this.showDropdown) {
        return true;
      }

      if (this.sortedModified.length > 0 && this.showDropdown) {
        return true;
      }

      return false;
    }),

    togglePopup() {
      this.set('showDropdown', true);
    },

    onToggleModalDialog() {
      this.set('showDropdown', false);
    }

  }, (_applyDecoratedDescriptor(_obj, "togglePopup", [_dec], Object.getOwnPropertyDescriptor(_obj, "togglePopup"), _obj), _applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj)), _obj)));

  _exports.default = _default;
});