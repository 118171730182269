define("client/router", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor() {
      super(...arguments);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('protected', {
      path: 'p'
    }, function () {
      this.route('companies', {
        path: '/:user_name/:user_id'
      });
      this.route('company', {
        path: '/c/:user_name/:user_id/:company_name'
      });
    });
    this.route('guests', function () {
      this.route('shop', function () {
        this.route('login', {
          path: '/login/:guest_resource'
        });
        this.route('report', {
          path: '/report/:guest_resource'
        });
      });
    });
    this.route('payments', function () {
      this.route('card', function () {
        this.route('lists', function () {
          this.route('panels');
        });
      });
    });
    this.route('reset');
  });
});