define("client/pods/uploads/lists/resources/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/base/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ResourcesModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class ResourcesModel extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "_data", _descriptor, this);

      _initializerDefineProperty(this, "selected", _descriptor2, this);
    }

    get name() {
      return this._data.name;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/uploads/resources',
    modelObj: ResourcesModel,
    idParam: false
  });

  _exports.default = _default;
});