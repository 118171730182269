define("client/pods/components/websites/websites-editor/templates/private-template/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WebsitesWebsitesEditorTemplatesPrivateTemplateComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class WebsitesWebsitesEditorTemplatesPrivateTemplateComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "showPreview", _descriptor, this);

      _initializerDefineProperty(this, "page", _descriptor2, this);
    }

    onClick() {
      const onClick = R.pathOr(null, ['templateOptions', 'onClick'])(this.args);

      if (onClick) {
        onClick(...arguments);
      }
    }

    togglePreview(page) {
      this.page = page;
      this.showPreview = !!!this.showPreview;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showPreview", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePreview", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "togglePreview"), _class.prototype)), _class));
  _exports.default = WebsitesWebsitesEditorTemplatesPrivateTemplateComponent;
});