define("client/pods/channels/shop/campaigns/model", ["exports", "client/pods/base/model", "client/mixins/templates", "client/mixins/sales-persons", "client/mixins/sortable-details", "client/utils/nventor", "client/pods/faqs/model", "ramda", "ramda-adjunct", "client/pods/open-hours/model"], function (_exports, _model, _templates, _salesPersons, _sortableDetails, _nventor, _model2, R, RA, _model3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend(_salesPersons.default, _sortableDetails.default, _templates.default, (_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    users: Ember.inject.service(),
    crud: Ember.inject.service(),
    settings: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    indexProp: '_data.index',
    toSortProp: 'details',

    init() {
      this._super(...arguments);

      this.set('requiredDataAttrs', ['name', 'description', 'shippingFee', 'shippingFeeCondition']);
      this.set('detailsRequiredAttrs', ['itemKey', 'item']);
      this.set('draftRequiredDataAttrs', ['name']);
      this.set('childAdapters.salesPersons', 'users');
      this.set('childAdapters.reviewSteps', 'workflows/items/activities');
      this.set('childAdapters.influencers', 'channels/shop/campaigns/influencers');
      this.set('childAdapters.workflowsListsItemsActivitiesTemplates', 'workflows/lists/items/activities/templates');
      this.set('childAdapters.automations', 'channels/shop/campaigns/automations/details');
      this.set('childAdapters.products', 'products');
      this.set('childAdapters.details', 'channels/shop/campaigns/details');
      this.set('childAdapters.price-filter', 'channels/shop/campaigns/price-filter');
      this.set('childAdapters.choices', 'products/choices');
      this.set('childAdapters.choices-details', 'products/choices/details');
      this.set('childAdapters.sets-details', 'products/sets/details');
      this.set('childAdapters.variants', 'products/variants');
      this.set('childAdapters.variants-options', 'products/variants/options');
      this.set('childAdapters.variants-children', 'products/variants/children');
    },

    defaults() {
      const companyName = this.settings.getProp('company') || '';
      const shopPaymentMethods = this.settings.getProp('shopPaymentMethods');
      const shopGroupByTags = this.settings.getProp('shopGroupByTags');
      const shopShowEnterPromoCode = this.settings.getProp('shopShowEnterPromoCode', true);
      const shopAllowShipping = this.settings.getProp('shopAllowShipping');
      const shopAllowPickup = this.settings.getProp('shopAllowPickup');
      const variantSelectorStyle = this.settings.getProp('variantSelectorStyle') || this.get('constants.variantSelectorStyle.dropdown');
      const shopPaymentMethodsDefault = this.settings.getProp('shopPaymentMethodsDefault');
      const cashShopPaymentMethodFee = this.settings.getProp('cashShopPaymentMethodFee');
      const shopCampaignExpiredTemplate = this.settings.getProp('shopCampaignExpiredTemplate');
      const shopOverSalesLimitedQtyStatus = this.settings.getProp('shopOverSalesLimitedQtyStatus');
      const facebookPage = this.settings.getProp('facebookDefaultPage');

      const password = _nventor.default.random.alphaNum(8).toLowerCase();

      const autoPrintInvoicesForShop = this.settings.getProp('autoPrintInvoicesForShop') || this.settings.getProp('autoPrintInvoices'); // if not yet set, use from settings

      let isAllowToCrawl = this?._data?.isAllowToCrawl;

      if (RA.isNilOrEmpty(isAllowToCrawl)) {
        isAllowToCrawl = this.settings.getProp('isAllowToCrawl');
      }

      let cartBtnStyle = this?._data?.cartBtnStyle;

      if (RA.isNilOrEmpty(cartBtnStyle)) {
        cartBtnStyle = this.settings.getProp('cartBtnStyle');
      }

      let successOrderDescription = this?._data?.successOrderDescription || '';
      const useDefaultsSuccessOrderDescription = R.pathOr(true, ['_data', 'useDefaultsSuccessOrderDescription'])(this);

      if (useDefaultsSuccessOrderDescription) {
        successOrderDescription = this.settings.getProp('successOrderDescription');
      }

      return {
        autoEnd: this.get('constants.togglesAuto.off'),
        companyName,
        companyTel: '',
        companyAddress: '',
        showCountdownTimer: this.get('constants.togglesOnOff.off'),
        showCampaignHeader: this.get('constants.togglesOnOff.on'),
        showEnterPromoCode: shopShowEnterPromoCode,
        // onSale: this.get('constants.togglesOnOff.off'),
        showLimitedQty: this.get('constants.togglesOnOff.off'),
        showProductDescription: false,
        showItemDescriptionLinesCount: 2,
        deliveryDateSettings: this.get('constants.deliveryDateSettings.fromOrderDate'),
        status: this.get('constants.status.active'),
        taxMethodKey: this.get('constants.taxMethods.inclusive'),
        leadTime: 0,
        shippingFeeType: this.get('constants.shippingFeeType.flat'),
        shippingFee: this.settings.getProp('shippingFee'),
        shippingFeeAlwaysCharge: this.settings.getProp('shippingFeeAlwaysCharge'),
        shippingFeeCondition: this.settings.getProp('shippingFeeCondition'),
        defaultReviewStepsKey: [],
        useDefaultsSocialLinks: true,
        useDefaultsFacebook: true,
        useDefaultsContactInfo: true,
        useDefaultsShopPriceFilter: true,
        useDefaultsShippingFlatFee: false,
        useDefaultsPaymentMethodsAllowInstallments: true,
        useDefaultsPickupSettings: false,
        useDefaultsVariantSelectorStyle: true,
        useDefaultsRecurringAgreement: true,
        useDefaultsGoogleTrackingKeys: true,
        useDefaultsFacebookPixelKeys: true,
        useDefaultsCheckoutSummaryDescription: true,
        useDefaultsCheckoutDetailsDescription: true,
        useDefaultsCheckoutPaymentDescription: true,
        useDefaultsSuccessOrderDescription: true,
        checkoutSummaryDescription: this.settings.getProp('shopCheckoutSummaryDescription'),
        checkoutDetailsDescription: this.settings.getProp('shopCheckoutDetailsDescription'),
        checkoutPaymentDescription: this.settings.getProp('shopCheckoutPaymentDescription'),
        successOrderDescription,
        facebookPluginEnable: true,
        variantSelectorStyle: variantSelectorStyle,
        paymentMethods: shopPaymentMethods,
        paymentMethodsDefault: shopPaymentMethodsDefault,
        cashPaymentMethodFee: cashShopPaymentMethodFee,
        expiredTemplate: shopCampaignExpiredTemplate,
        groupByTags: shopGroupByTags,
        allowPickup: shopAllowPickup,
        allowShipping: shopAllowShipping,
        enableShopPriceFilter: true,
        overSalesLimitedQtyStatus: shopOverSalesLimitedQtyStatus,
        facebookPage: facebookPage,
        facebookPixelKeys: [],
        googleTrackingKeys: [],
        pickupLocations: [],
        productsImagesRatio: this.settings.getProp('shopProductsImagesRatio'),
        productsOverviewStyle: this.settings.getProp('shopProductsOverviewStyle'),
        password: password,
        shippingZonesIds: [],
        faqs: [],
        thirdParty: {
          shopDotCom: {
            advertiserId: '',
            enabled: false,
            commissinRatio: '',
            offerId: '',
            useDefaultsFromSettings: false,
            antiFraudText: ''
          },
          shopee: {
            partnerId: '',
            partnerKey: '',
            shopId: ''
          }
        },
        isAllowEarnReward: true,
        isAllowSpendReward: true,
        isAllowReferrals: true,
        isCheckoutNotesRequired: false,
        advancedTemplate: {},
        openHours: {
          hasBreakTime: false,
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
          sunday: {}
        },
        docSalesInvoicesAuthorizationRequest: [],
        defaultWorkflowsItemsActivities: {},
        workflowsItemsActivities: [],
        influencers: [],
        shopPriceFilters: [],
        recurringAgreement: '',
        transporterUseDefaultPickupLocation: true,
        useDefaultsProductsDisplaySettings: true,
        productsImagesSize: this.settings.getProp('productsImagesSize'),
        productsImagesHoverFilter: this.settings.getProp('imagesHoverFilter'),
        productsImagesHoverTransform: this.settings.getProp('imagesHoverTransform'),
        productsNameFontSize: this.settings.getProp('productsNameFontSize'),
        autoPrintInvoicesForShop,
        filterTags: 'all',
        showItemCode: this.settings.getProp('showItemCode'),
        isAllowToCrawl,
        isAllowShopping: true,
        isDisallowShopping: false,
        useInventoryAsLimitedQty: this.settings.getProp('useInventoryAsLimitedQty'),
        isShowPrices: false,
        cartBtnStyle
      };
    },

    populate(data, attrs) {
      data.salesPersons = this._transformSalesPersons(data.salesPersons);
      data.reviewSteps = this._transformReviewSteps(data.reviewSteps);
      data.influencers = this._transformInfluencers(data.influencers);
      data.workflowsItemsActivities = this._transformWorkflowsItemsActivities(data.workflowsItemsActivities);
      data.orderedEmailCopyTo = this.emails.joinAsString(data.orderedEmailCopyTo);
      data.orderMessagesEmailCopyTo = this.emails.joinAsString(data.orderMessagesEmailCopyTo);
      data.faqs = this._transformFaqs(data);
      data.bulkDiscounts = this._transformBulkDiscounts(data);
      data.bulkDiscountConflictDetails = this._transformBulkDiscountConflictDetails(data);
      data.advancedTemplate = this._transformAdvancedTemplate(data);
      data.shopPriceFilters = this._transformRates(data.shopPriceFilters);
      data.openHours = this._transformOpenHours(data);
      data.rewardData = R.propOr({}, 'rewardData')(data);

      if (!R.is(Object, data.rewardData)) {
        data.rewardData = {};
      }

      data.rewardData.memberLevels = R.pipe(R.pathOr([], ['rewardData', 'memberLevels']), R.map(memberLevel => {
        return Ember.Object.create(memberLevel || {});
      }))(data);

      this._super(data, attrs);
    },

    populatePartial(data, attrs) {
      data.bulkDiscountConflictDetails = this._transformBulkDiscountConflictDetails(data);

      if (data.detailsUpdated) {
        data.detailsLimitsUpdated = true;
        data.detailsUpdated = R.pipe(R.propOr([], 'detailsUpdated'), R.map(newDetailData => {
          return this.dataManager.setPartialRecord({
            adapterName: this.get('childAdapters.details'),
            data: newDetailData
          });
        }))(data);
      }

      if (data.influencers) {
        data.influencers = this._transformInfluencers(data.influencers);
      }

      this._super(data, attrs);
    },

    _transformOpenHours(data) {
      const defaults = this.defaults() || {};
      const defaultsOpenHours = defaults.openHours || {};
      const openHours = R.propOr(defaultsOpenHours, 'openHours')(data);
      return R.mapObjIndexed((value, key) => {
        if (R.type(value) === 'Boolean') {
          return value;
        }

        value.name = key;
        return _nventor.default.createModel(_model3.default, value);
      })(openHours);
    },

    _transformBulkDiscountConflictDetails(data) {
      const bulkDiscountConflictDetails = R.propOr([], 'bulkDiscountConflictDetails')(data);
      return R.map(conflict => {
        return this.dataManager.setPartialRecord({
          adapterName: 'channels/shop/campaigns/details',
          data: conflict
        });
      })(bulkDiscountConflictDetails);
    },

    _transformFaqs(data) {
      return R.pipe(R.propOr([], 'faqs'), R.reject(_nventor.default.isNilOrEmpty), R.map(faq => _nventor.default.createModel(_model2.default, faq)))(data);
    },

    _transformSalesPersons() {
      let salesPersons = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return salesPersons.map(data => {
        return this.dataManager.setPartialRecord({
          adapterName: this.get('childAdapters.salesPersons'),
          data
        });
      });
    },

    _transformRates(shopPriceFilters) {
      if (!shopPriceFilters) {
        const _ranges = [{
          _data: {
            min: 0,
            max: 99
          }
        }, {
          _data: {
            min: 100,
            max: 499
          }
        }, {
          _data: {
            min: 500,
            max: 999
          }
        }, {
          _data: {
            min: 1000
          }
        }];
        return _ranges;
      }

      return shopPriceFilters.map(range => {
        return this.dataManager.setPartialRecord({
          adapterName: this.get('childAdapters.price-filter'),
          data: range
        });
      });
    },

    _transformReviewSteps() {
      let reviewSteps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return reviewSteps.map(data => {
        return this.dataManager.setPartialRecord({
          adapterName: this.get('childAdapters.reviewSteps'),
          data
        });
      });
    },

    _transformInfluencers() {
      let reviewSteps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return reviewSteps.map(data => {
        return this.dataManager.setPartialRecord({
          adapterName: this.get('childAdapters.influencers'),
          data
        });
      });
    },

    _transformWorkflowsItemsActivities() {
      let reviewSteps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return reviewSteps.map(data => {
        return this.dataManager.setPartialRecord({
          adapterName: this.get('childAdapters.workflowsListsItemsActivitiesTemplates'),
          data
        });
      });
    },

    enabledBulkDiscountsList: Ember.computed('synced', '_data.bulkDiscounts.@each.synced', function () {
      const bulkDiscounts = this.getData('bulkDiscounts') || [];
      return R.filter(R.pathEq(['_data', 'status'], 'enabled'))(bulkDiscounts);
    }),
    isOnSale: Ember.computed('_data.onSale', function () {
      if (this.get('_data.onSale') === this.get('constants.togglesOnOff.on')) {
        return true;
      }

      return false;
    }),
    isPage: Ember.computed('_data', function () {
      return true;
    }),
    hasCashPaymentFee: Ember.computed('_data.paymentMethods.[]', function () {
      const paymentMethods = this.getData('paymentMethods');

      if (paymentMethods.indexOf('cash') > -1) {
        return true;
      }

      return false;
    }),
    hasCashBeforeDelivery: Ember.computed('_data.paymentMethods.[]', function () {
      const paymentMethods = this.getData('paymentMethods');

      if (paymentMethods.indexOf('cashBeforeDelivery') > -1) {
        return true;
      }

      return false;
    }),
    hasSalesPersons: Ember.computed('_data.salesPersons.[]', function () {
      const salesPersons = this.getData('salesPersons') || [];

      if (R.isEmpty(salesPersons)) {
        return false;
      }

      return salesPersons;
    }),

    addDetail(data) {
      const adapterName = this.childAdapters.details;

      const detail = this._addDetail({
        adapterName,
        data
      });

      const onSale = this.getData('onSale');
      detail.setData('onSale', onSale);
      return detail;
    },

    _addDetail(_ref) {
      let {
        adapterName,
        data
      } = _ref;
      const detail = this.crud.setupNewRecord({
        adapterName,
        data
      });
      const master = this.getData('_key');
      const overSalesLimitedQtyStatus = this.getData('overSalesLimitedQtyStatus');
      detail.setData('master', master);
      detail.setData('overSalesLimitedQtyStatus', overSalesLimitedQtyStatus);
      return detail;
    },

    addFaq(faq) {
      this.getData('faqs').pushObject(faq);
    },

    hasDetailsChanged: Ember.computed('detailsUpdated.[]', function () {
      const detailsUpdated = this.detailsUpdated || [];

      if (R.isEmpty(detailsUpdated)) {
        return [];
      }

      return detailsUpdated;
    }),
    hasDetailsChangedByOthers: Ember.computed('hasDetailsChanged.[]', function () {
      const hasDetailsChanged = this.hasDetailsChanged || [];
      const uid = this.users.getProp('uid');
      const detailsUpdatedByOthers = R.reject(R.pathEq(['_data', 'creator', 'userKey'], uid))(hasDetailsChanged);

      if (R.isEmpty(detailsUpdatedByOthers)) {
        return [];
      }

      return detailsUpdatedByOthers;
    }),
    hasDetailsLimitsChanged: Ember.computed('hasDetailsChanged.[]', 'detailsLimitsUpdated', function () {
      const hasDetailsChanged = this.hasDetailsChanged || [];

      if (this.detailsLimitsUpdated) {
        if (R.isEmpty(hasDetailsChanged)) {
          return false;
        }

        return hasDetailsChanged;
      }

      return false;
    }),
    isLinkToWebsite: Ember.computed('_data.website', function () {
      const website = this?._data?.website || {};

      if (RA.isNotNilOrEmpty(website)) {
        return true;
      }

      return false;
    }),

    _transformBulkDiscounts(data) {
      const bulkDiscounts = R.propOr([], 'bulkDiscounts')(data);
      return R.map(bulkDiscount => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/bulk-discounts',
          data: bulkDiscount
        });
      })(bulkDiscounts);
    },

    addBulkDiscount() {
      let bulkDiscount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const bulkDiscounts = this.getData('bulkDiscounts') || [];
      const bulkDiscountModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/bulk-discounts',
        data: bulkDiscount
      });
      bulkDiscounts.pushObject(bulkDiscountModel);
      this.setData('bulkDiscounts', bulkDiscounts);
      bulkDiscountModel.addRule();
    },

    _transformAdvancedTemplate(data) {
      return R.pipe(R.propOr({}, 'advancedTemplate'), advancedTemplate => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/campaigns/advanced-template',
          data: {
            displayInShop: false,
            displayProductsInShop: true,
            ...advancedTemplate,
            hasTemplate: true,
            url: data.url
          }
        });
      })(data);
    },

    sortedOpenHours: Ember.computed('_data.openHours.monday.{open,close,hasOpeningHours}', '_data.openHours.tuesday.{open,close,hasOpeningHours}', '_data.openHours.wednesday.{open,close,hasOpeningHours}', '_data.openHours.thursday.{open,close,hasOpeningHours}', '_data.openHours.friday.{open,close,hasOpeningHours}', '_data.openHours.saturday.{open,close,hasOpeningHours}', '_data.openHours.sunday.{open,close,hasOpeningHours}', function () {
      const openHours = this._data.openHours;
      const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      const sortDays = R.pipe(R.toPairs, R.sortBy(R.pipe(R.head, R.indexOf(R.__, weekdays))), R.fromPairs, R.values, R.reject(day => R.type(day) === 'Boolean'));
      return sortDays(openHours);
    }),
    url: Ember.computed('_data.website.{domain,url}', function () {
      const shopUrl = R.pathOr('', ['_data', 'url'])(this);
      const hasVerifiedDomain = R.pathEq(['_data', 'website', 'domainVerified'], true)(this);

      if (hasVerifiedDomain) {
        const domain = R.pathOr('', ['_data', 'website', 'domain'])(this);
        return `https://${domain}/shop/${shopUrl}`;
      }

      const subdomain = R.path(['_data', 'website', 'subdomain'])(this);

      if (subdomain) {
        const websiteHost = R.pathOr('', ['settings', 'config', 'APP', 'websiteHost'])(this);
        return `https://${subdomain}.${websiteHost}/shop/${shopUrl}`;
      }

      const bigordrShopUrl = R.pathOr('', ['settings', 'config', 'APP', 'shopUrl'])(this);
      return `${bigordrShopUrl}/${shopUrl}`;
    }),

    reorderDetails(details, detail, dragItem) {
      const toSortProp = this.toSortProp;
      const indexProp = this.indexProp;
      const dragIndex = dragItem.get(indexProp);
      const onOverItemIndex = detail.get(indexProp);
      details = R.reject(R.pathEq(['_data', 'isAutomation'], true))(details);

      if (dragIndex === onOverItemIndex) {// do nothing
      } else if (dragIndex < onOverItemIndex) {
        // move to below onOverItemIndex
        this.set(toSortProp, details.map(detail => this.moveDetailBelow(detail, dragIndex, onOverItemIndex)));
      } else {
        // move to above onOverItemIndex
        this.set(toSortProp, details.map(detail => this.moveDetailAbove(detail, dragIndex, onOverItemIndex)));
      }

      if (this.updateSynced) {
        this.updateSynced();
      }

      if (this.sortDetails) {
        this.sortDetails();
      }

      return R.filter(R.pathEq(['changed'], true))(details);
    },

    loadSalesPersonSettings() {
      let model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let selectedCommissionBaseCostMethod;
      let isUsingAverage;
      let isCalculatedByExclAmt;
      let isUsingSetChild;
      let hasAdjustedShippingFee;
      const settingsModel = this?.settings?.settingsModel || {};

      if (RA.isNotNilOrEmpty(settingsModel)) {
        selectedCommissionBaseCostMethod = R.pathOr('normalPercent', ['_data', 'selectedCommissionBaseCostMethod'], settingsModel);
        isUsingAverage = R.pathOr(true, ['_data', 'isCommissionsUsingAverage'], settingsModel);
        isCalculatedByExclAmt = R.pathOr(true, ['_data', 'isCommissionsCalculatedByExclAmt'], settingsModel);
        isUsingSetChild = R.pathOr(false, ['_data', 'isCommissionsUsingSetChild'], settingsModel);
        hasAdjustedShippingFee = R.pathOr(false, ['_data', 'isCommissionsHasAdjustedShippingFee'], settingsModel);
      }

      const salesPersons = this.getData('salesPersons') || [];

      const salesPerson = _nventor.default.safeHead(salesPersons);

      if (RA.isNotNilOrEmpty(salesPerson)) {
        selectedCommissionBaseCostMethod = R.pathOr(selectedCommissionBaseCostMethod, ['_data', 'selectedCommissionBaseCostMethod'], salesPerson);
        isUsingAverage = R.pathOr(isUsingAverage, ['_data', 'isCommissionsUsingAverage'], salesPerson);
        isCalculatedByExclAmt = R.pathOr(isCalculatedByExclAmt, ['_data', 'isCommissionsCalculatedByExclAmt'], salesPerson);
        isUsingSetChild = R.pathOr(isUsingSetChild, ['_data', 'isCommissionsUsingSetChild'], salesPerson);
        hasAdjustedShippingFee = R.pathOr(hasAdjustedShippingFee, ['_data', 'isCommissionsHasAdjustedShippingFee'], salesPerson);
      }

      this.setData('selectedCommissionBaseCostMethod', selectedCommissionBaseCostMethod);
      this.setData('isCommissionsUsingAverage', isUsingAverage);
      this.setData('isCommissionsCalculatedByExclAmt', isCalculatedByExclAmt);
      this.setData('isCommissionsUsingSetChild', isUsingSetChild);
      this.setData('isCommissionsHasAdjustedShippingFee', hasAdjustedShippingFee);
    }

  }, (_applyDecoratedDescriptor(_obj, "reorderDetails", [_dec], Object.getOwnPropertyDescriptor(_obj, "reorderDetails"), _obj), _applyDecoratedDescriptor(_obj, "loadSalesPersonSettings", [_dec2], Object.getOwnPropertyDescriptor(_obj, "loadSalesPersonSettings"), _obj)), _obj)));

  _exports.default = _default;
});