define("client/pods/extensions/apps/tw/shopee/products/model", ["exports", "client/pods/channels/shop/campaigns/details/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwShopeeProducts = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class ExtensionsAppsTwShopeeProducts extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isSelected", _descriptor, this);

      _initializerDefineProperty(this, "_data", _descriptor2, this);
    }

    populate(data, attrs) {
      super.populate(data, attrs);
    }

    get image() {
      return R.pathOr('', ['_data', 'images', 0, 'file'])(this);
    }

    get imageWidth() {
      return 60;
    }

    get imageHeight() {
      return 60;
    }

    get allowDownload() {
      const isUpToDate = R.pathEq(['_data', 'sync', 'isUpToDate'], true)(this);
      const isExistInShopee = R.pathEq(['_data', 'sync', 'isExistInShopee'], true)(this);
      const isExistInCampaign = R.pathEq(['_data', 'sync', 'isExistInCampaign'], true)(this);
      const isExistInProducts = R.pathEq(['_data', 'sync', 'isExistInProducts'], true)(this);

      if (isExistInShopee) {
        if (!isExistInCampaign || !isExistInProducts) {
          return true;
        }

        if (!isUpToDate) {
          return true;
        }
      }

      return false;
    }

    get allowUpload() {
      const isUpToDate = R.pathEq(['_data', 'sync', 'isUpToDate'], true)(this);
      const isExistInShopee = R.pathEq(['_data', 'sync', 'isExistInShopee'], true)(this);
      const isExistInCampaign = R.pathEq(['_data', 'sync', 'isExistInCampaign'], true)(this);
      const isExistInProducts = R.pathEq(['_data', 'sync', 'isExistInProducts'], true)(this);

      if (isExistInShopee) {
        if (!isUpToDate && isExistInCampaign && isExistInProducts) {
          return true;
        }
      } else {
        if (isExistInCampaign && isExistInProducts) {
          return true;
        }
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isSelected", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ExtensionsAppsTwShopeeProducts;
});