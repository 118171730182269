define("client/pods/products/batch/statuses/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new"], function (_exports, R, _adapter, _adaptersNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/products',
    idParam: false,

    serialize(products) {
      const batch = R.map(product => {
        return {
          _key: product?._data._key
        };
      })(products);
      return {
        batch
      };
    }

  });

  _exports.default = _default;
});