define("client/mixins/dispatch", ["exports", "ramda", "client/mixins/date"], function (_exports, R, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_date.default, {
    actions: {
      showPreDispatchProcessDialog(modelProxy, dispatchDateZ) {
        modelProxy.set('showPreDispatchProcess', true);
        dispatchDateZ = dispatchDateZ || this.getStartOfDayZ();
        modelProxy.set('dispatchDateZ', dispatchDateZ);
      },

      closePreDispatchProcessDialog(modelProxy) {
        modelProxy.set('showPreDispatchProcess', false);
      },

      showWaybills(modelProxy, model, dispatchProcess) {
        if (!R.is(Array, model) && dispatchProcess.getData('code') === 'dispatched' && model.getData('transporterExtension') === 'hct') {
          const postDispatchShippingWaybillsModal = this.settings.getProp('postDispatchShippingWaybillsModal');

          if (postDispatchShippingWaybillsModal) {
            modelProxy.set('showWaybills', true);
          }
        }
      }

    }
  });

  _exports.default = _default;
});