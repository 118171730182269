define("client/pods/components/products/search-bar-container/component", ["exports", "client/mixins/products-list-by", "client/mixins/crud"], function (_exports, _productsListBy, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _productsListBy.default, {
    crud: Ember.inject.service(),
    tagName: '',
    query: '',
    filters: null,
    autofocus: true,
    adapterName: 'products',
    searchMethod: 'searchInputTask',
    searchOptions: null,
    page: 1,
    count: 20,

    init() {
      this.crud.addLists(this, ['products/lists/filters/search', 'products/lists/filters/sort', 'products/lists/filters/status', 'products/lists/types', 'products/lists/tags', 'filters/count', 'filters/sortDirection']);

      this._super(...arguments);
    } // afterLoadLists () {
    //   this._super(...arguments)
    //   const filters = this.setupSearchFilters({
    //     match: ['all'],
    //     status: [this.get('constants.status.active')],
    //     types: ['all'],
    //     sort: {
    //       by: 'name',
    //       direction: 'ASC'
    //     }
    //   })
    //   this.set('filters', filters)
    // }
    // searchTask: task(function * (customSearchOptions, defaultSearchOptions, ...rest) {
    //   const searchMethod = this.get('searchMethod')
    //   const searchOptions = R.mergeRight(defaultSearchOptions, customSearchOptions)
    //   return yield this.get(searchMethod).perform(searchOptions, ...rest)
    // })


  });

  _exports.default = _default;
});