define("client/pods/components/news/files/files-default-image/component", ["exports", "client/pods/components/news/files/mixins/news-files"], function (_exports, _newsFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_newsFiles.default, {
    fetchUploads: true
  });

  _exports.default = _default;
});