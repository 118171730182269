define("client/pods/components/helpers/tab-link/contacts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HS4/o/au",
    "block": "{\"symbols\":[\"@tabOptions\",\"@toggleClickToViewAction\"],\"statements\":[[0,\"    \"],[5,\"contacts/shortcuts\",[],[[\"@contactKey\",\"@model\",\"@hasSales\",\"@hasPurchases\",\"@onSubmit\",\"@onCloseModalDialog\"],[[23,1,[\"contactKey\"]],[23,1,[\"model\"]],true,true,[23,2,[]],[23,2,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/tab-link/contacts/template.hbs"
    }
  });

  _exports.default = _default;
});