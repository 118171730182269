define("client/pods/components/lists/list-option/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    isCheckbox: false,
    multiSelect: false,
    classNameBindings: ['isSelectedClass:is-selected', 'isTag:tag', 'disabled'],
    isTag: false,
    rememberSelected: true,
    // determine if it should remember selected state
    isSelected: false,
    translate: true,
    disabled: false,
    optionsComponents: null,
    // selectedComponents: null, //array to keep track of selected components
    optionLabelKey: '_data.label',
    // key that points to option's label
    optionValueKey: '_data.value',
    // key that points to option's value
    prefix: '',
    // prefix added to option
    prefixKey: '',
    suffix: '',
    // suffix added to option
    suffixKey: '',
    optionClassNames: '',

    didInsertElement() {
      this._super(...arguments);

      this.setup();
    },

    prefixLabel: Ember.computed('prefix', 'prefixKey', function () {
      const prefix = this.get('prefix');
      const prefixKey = this.get('prefixKey');

      if (prefixKey) {
        const prefixValue = this.option.get(prefixKey);

        if (prefixValue) {
          return `(${prefixValue})`;
        }

        return '';
      }

      return prefix;
    }),
    suffixLabel: Ember.computed('suffix', 'suffixKey', function () {
      const suffix = this.get('suffix');
      const suffixKey = this.get('suffixKey');

      if (suffixKey) {
        const suffixValue = this.option.get(suffixKey);

        if (suffixValue) {
          return `(${suffixValue})`;
        }

        return '';
      }

      return suffix;
    }),
    isSelectedClass: Ember.computed('isSelected', function () {
      if (this.isCheckbox) {
        return false;
      }

      return this.isSelected;
    }),
    allowTranslate: Ember.computed('option._data.translate', function () {
      const optionTranslate = this.get('option._data.translate');

      if (optionTranslate === '' || optionTranslate == null) {
        return this.translate;
      }

      return optionTranslate;
    }),

    // determine whether the option component should be selected or not
    setup() {
      var self = this;

      if (!self.get('isTag') && self.get('rememberSelected') === true) {
        // only setup selected if isTag=false
        let isSelected = false;
        const optionValue = self.get('optionValue');
        const selected = self.get('selected');

        if (R.is(Array, selected)) {
          if (self.get('selected').indexOf(optionValue) > -1) {
            isSelected = true;
          }
        } else {
          if (selected === self.get('optionValue') && self.get('optionValue') != null) {
            isSelected = true;
          }
        }

        if (isSelected) {
          self.set('isSelected', isSelected);
          self.get('selectedComponents').addObject(self);
        }

        self.get('optionsComponents').addObject(self);
      }
    },

    // handle click action
    click() {
      if (!this.disabled) {
        if (this.onToggle) {
          const option = this.option;
          this.onToggle(option, this);
        }
      }
    },

    optionLabel: Ember.computed('option', 'option.synced', function () {
      return this.get('option.' + this.optionLabelKey);
    }),
    optionValue: Ember.computed('option', 'option.synced', function () {
      return this.get('option.' + this.optionValueKey);
    }),

    getValueKey() {
      return this.key;
    },

    actions: {
      edit(e) {
        e.stopPropagation();

        if (!this.disabled) {
          const option = this.option;
          this.onEdit(option);
        }
      }

    }
  });

  _exports.default = _default;
});