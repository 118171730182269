define("client/pods/components/guests/campaign-main/campaign-report/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    guests: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const tokenData = this.guests.getTokenData();
      this.set('tokenData', tokenData);
    }

  });

  _exports.default = _default;
});