define("client/pods/components/channels/shop/rewards/panels-display/sub-panels-details/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChannelsShopRewardsPanelsDisplaySubPanelsDetailsComponent extends _component.default {}

  _exports.default = ChannelsShopRewardsPanelsDisplaySubPanelsDetailsComponent;
});