define("client/pods/contacts/lists/import-columns/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'name',
        label: 'name'
      }, {
        value: 'telephone',
        label: 'detailType_phone'
      }, {
        value: 'email',
        label: 'detailType_email'
      }, {
        value: 'address',
        label: 'detailType_address'
      }, {
        value: 'note',
        label: 'detailType_note'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});