define("client/pods/components/layouts/table-view/table-row-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    rowIndex: Ember.computed('index', 'filters._data.{page,count}', function () {
      const page = parseInt(this.get('filters._data.page')) || 1;
      const count = parseInt(this.get('filters._data.count')) || 20;
      const index = parseInt(this.index) + 1;
      return (page - 1) * count + index;
    })
  });

  _exports.default = _default;
});