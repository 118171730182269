define("client/pods/components/contacts/details/editor-details/editor-note/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    listAdapters: null,
    tagName: '',
    autofocus: true,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['contacts/lists/subTypesNote']);
    }

  });

  _exports.default = _default;
});