define("client/pods/documents/data/links/filters/model", ["exports", "client/pods/documents/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        query: '',
        contact: '',
        contactKey: '',
        itemKey: '',
        excludeFromLinkQty: {},
        includeCompletedPending: false
      };
    }

  });

  _exports.default = _default;
});