define("client/pods/components/helpers/data-updated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0eoXbLwy",
    "block": "{\"symbols\":[\"@onSubmit\",\"@translate\",\"@badge\",\"@hasBadge\",\"@isRunning\",\"@wasUpdated\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,6,[]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"icon\",\"isRunning\",\"hasBadge\",\"badge\",\"translate\",\"onSubmit\"],[[23,0,[\"label\"]],[23,0,[\"icon\"]],[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/data-updated/template.hbs"
    }
  });

  _exports.default = _default;
});