define("client/pods/components/channels/shop/rewards/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0IZU7ElX",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@tab\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onEdit\",\"@onDisplay\",\"@errors\"],\"statements\":[[5,\"layouts/table-view\",[],[[\"@tab\",\"@tableClassNames\",\"@detailsRowComponent\",\"@results\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onEdit\",\"@onDisplay\"],[[23,2,[]],\"rewards-dash\",\"channels/shop/rewards/table-view/detail-row\",[23,0,[\"model\"]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]]]]],[0,\"\\n\\n\"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"u-table-align-middle u-full-width\"],[8],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[5,\"helpers/pagination-controls\",[],[[\"@showPageNumbers\",\"@classNames\",\"@filters\",\"@list\",\"@onPaginationTask\"],[true,\"pagination-controls-container--small\",[23,3,[]],[23,0,[\"model\"]],[23,6,[]]]]],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"is-pulled-right\"],[8],[0,\"\\t\\n            \"],[5,\"elements/element-btn-with-modal-dialog\",[],[[\"@icon\",\"@modalDialogTitleTranslate\",\"@modalDialogTitle\",\"@isRunning\",\"@label\"],[\"fas fa-gift\",true,\"reward\",\"\",\"send reward manually\"]],{\"statements\":[[0,\"\\n              \"],[5,\"channels/shop/rewards/panels-dash/rewards-dialog\",[],[[\"@tab\",\"@sendLabel\",\"@onCloseDialogAction\",\"@errors\"],[[23,2,[]],\"send reward\",[23,1,[]],[23,9,[]]]]],[0,\"\\n            \"]],\"parameters\":[1]}],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/rewards/panels-dash/template.hbs"
    }
  });

  _exports.default = _default;
});