define("client/pods/components/extensions/apps/tw/hct/credentials/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtensionsAppsTwHctCredentialsComponent extends _component.default {}

  _exports.default = ExtensionsAppsTwHctCredentialsComponent;
});