define("client/pods/components/elements/btn-next/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    tooltipLabel: 'next',
    label: '',
    btnClassNames: '',
    icon: 'fas fa-arrow-right'
  });

  _exports.default = _default;
});