define("client/mixins/crud", ["exports", "ember-concurrency", "client/utils/nventor", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, _nventor, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    page: 1,

    /**
     * @deprecated
     * */
    setValidationErrors(errorsProp, err) {
      const errors = err.data || {};

      if (R.is(String, errors) || !err.data) {
        const msg = this.intl.t('error occurred please try again later'); // window.alert(msg)

        console.log('Error!!!');
        console.log(msg);
        return false;
      } // when handlers are still using old nvalidator, there will not be an errors.errors array


      if (!errors.errors) {
        try {
          errors.errors = R.values(errors);
          errors.errors = R.map(error => {
            error.context = {
              key: error.context
            };
            return error;
          })(errors.errors);
          this.set(errorsProp, errors.errors);
        } catch (e) {
          console.log(e);
        }
      } // let errorsTracker = this.get('errorsTracker')
      // if (errorsTracker && errors.errors) {
      //   errors.errors = R.map(error => {
      //     const context = R.pathOr('', ['context', 'key'], error)
      //     if (errorsTracker[context]) {
      //       error.tab = errorsTracker[context]
      //       error.label = error.tab.label
      //     }
      //     return error
      //   })(errors.errors)
      // }

    },

    /**
     * @deprecated
     * */
    clearValidationErrors(errorsProp) {
      this.set(errorsProp, '');
    },

    /**
     * @deprecated
     * */
    getAdapter(adapterName) {
      adapterName = adapterName || this.adapterName;
      return this.server.getAdapter(adapterName);
    },

    /**
     * @deprecated
     * @param {NewRecordType?} dataOptions
     * @return {Object}
     */
    setupNewRecord() {
      let dataOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let {
        adapterName,
        data = {},
        attrs = {}
      } = dataOptions;
      adapterName = adapterName || this.adapterName;
      data = data || {};
      attrs = attrs || {};
      const model = this.dataManager.newRecord({
        adapterName,
        data,
        attrs
      });
      return model;
    },

    /**
     * @deprecated
     * */
    setupDirty() {
      let {
        adapterName,
        model
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      model = model || this.model;
      model = model || this.get('tab.model');
      adapterName = adapterName || this.adapterName;
      const dirty = this.dataManager.getDirtyRecord(adapterName, model);
      return dirty;
    },

    /**
     * fetch default data task. should be implemented by tab-container
     * @deprecated
     * */
    fetchDefaultDataTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        adapterName,
        filters,
        resultsProperty,
        byLatest = true
      } = _ref;
      return function* () {
        // the function implementing needs to also have the correct list by mixin
        const results = yield _this.listByTask.perform({
          adapterName,
          filters,
          byLatest
        });

        if (resultsProperty) {
          _this.set(resultsProperty, results);
        }

        return results;
      }();
    }).drop(),

    /**
     * @deprecated
     * */
    searchAdvancedTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        filters,
        resultsProperty = 'results'
      } = _ref2;
      return function* () {
        const DEBOUNCE_MS = 550;
        yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
        const adapterName = _this2.adapterName;
        filters.setData('page', 1);
        const results = yield _this2.searchRecordsTask.perform({
          adapterName,
          appendPath: '/advanced',
          filters
        });

        _this2.set(resultsProperty, results);

        return results;
      }();
    }).restartable(),

    /**
     * @deprecated
     * */
    searchInputTask: (0, _emberConcurrency.task)(function () {
      var _this3 = this;

      let {
        adapterName,
        appendPath,
        data,
        filters,
        options,
        resultsProperty = 'results',
        lastSearchSettingsProperty,
        newFilters = false,
        copyFilters = false,
        onAfter
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let query = arguments.length > 1 ? arguments[1] : undefined;
      let e = arguments.length > 2 ? arguments[2] : undefined;
      return function* () {
        const DEBOUNCE_MS = 550;
        yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
        filters.setData('page', 1);
        query = query || filters.getData('query');
        query = query.trim();
        filters.setData('query', query);
        const results = yield _this3.searchRecordsTask.perform({
          adapterName,
          appendPath,
          query,
          data,
          filters,
          newFilters,
          copyFilters,
          options,
          resultsProperty,
          lastSearchSettingsProperty
        }, e);

        if (onAfter) {
          return onAfter(results);
        }

        if (_this3.afterSearchInputTask) {
          return _this3.afterSearchInputTask(results);
        }

        return results;
      }();
    }).restartable(),

    /**
     * @deprecated
     * */
    paginateSearchRecordsTask: (0, _emberConcurrency.task)(function (_ref3) {
      var _this4 = this;

      let {
        filters,
        lastSearchSettingsProperty = 'lastSearchSettings',
        resultsProperty,
        searchSettings
      } = _ref3;
      return function* () {
        if (RA.isNilOrEmpty(searchSettings)) {
          searchSettings = filters.get(lastSearchSettingsProperty);
        }

        if (RA.isNilOrEmpty(searchSettings)) {
          searchSettings = {};
        }

        searchSettings.lastSearchSettingsProperty = lastSearchSettingsProperty;
        searchSettings.resultsProperty = resultsProperty || filters.getData('resultsProperty');
        searchSettings.filters = filters;
        const results = yield _this4.searchRecordsTask.perform(searchSettings);

        if (_this4.afterPaginateSearchRecordsTask) {
          return _this4.afterPaginateSearchRecordsTask(results);
        }

        return results;
      }();
    }),

    /**
     * @deprecated
     * */
    reloadSearchRecordsTask: (0, _emberConcurrency.task)(function* (args) {
      const {
        filters,
        lastSearchSettingsProperty = 'lastSearchSettings',
        reset = false,
        onAfter
      } = args;
      let {
        searchSettings
      } = args;
      filters.setData('page', 1);

      if (reset) {
        filters.reset(args);
      }

      if (RA.isNilOrEmpty(searchSettings)) {
        searchSettings = filters.get(lastSearchSettingsProperty);
      }

      if (RA.isNilOrEmpty(searchSettings)) {
        searchSettings = {};
      }

      searchSettings.lastSearchSettingsProperty = lastSearchSettingsProperty;
      searchSettings.filters = filters;
      searchSettings.onAfter = onAfter;
      return yield this.searchRecordsTask.perform(searchSettings);
    }),

    /**
     * @deprecated
     * */
    searchRecordsTask: (0, _emberConcurrency.task)(function*
      /* searchSettings, e */
    () {
      return yield this.searchRecordsConcurrentlyTask.perform(...arguments);
    }),

    /**
     * api/p/messages/{fb}/{name}
     * adapterName: 'channels/shop/details'
     * appendPath: `/${type}/${name}`
     * @deprecated
     * */
    searchRecordsConcurrentlyTask: (0, _emberConcurrency.task)(function () {
      var _this5 = this;

      let {
        adapterName,
        modelAdapterName,
        appendPath,
        rawResponse,
        newFilters = false,
        copyFilters = false,
        query = '',
        data = {},
        filters,
        options,
        resultsProperty = 'results',
        lastSearchSettingsProperty = 'lastSearchSettings',
        errorsProp = 'errors',
        onAfter
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        adapterName = adapterName || _this5.adapterName;

        if (copyFilters) {
          filters = _this5.search.copyFilters({
            filters
          });
        }

        query = query || filters.getData('query'); // CATCH CASE WHEN QUERY IS NOT A STRING AND SET IT TO AN EMPTY STRING

        const isNotString = typeof query !== 'string';

        if (isNotString) {
          query = '';
        }

        if (newFilters) {
          filters = _this5.search.setupFilters({
            adapterName: filters._data.filtersAdapterName
          });
        }

        filters.setData('query', query);
        resultsProperty = R.path(['_data', 'resultsProperty'])(filters) || resultsProperty;

        if (!resultsProperty) {
          resultsProperty = 'results';
        }

        try {
          _this5.clearValidationErrors(errorsProp);

          if (query?.trim?.() !== '') {
            data.query = query.trim();
          }

          const results = yield _this5.dataManager.findAll({
            adapterName,
            appendPath,
            modelAdapterName,
            data,
            filters,
            options,
            rawResponse
          });

          if (filters) {
            if (!filters.getData('page')) {
              _this5.set('page', 1);
            }
          }

          if (!data.page) {
            _this5.set('page', 1);
          }

          if (filters) {
            filters.set('lastSearchSettingsProperty', lastSearchSettingsProperty);
            filters.set(lastSearchSettingsProperty, {
              adapterName,
              appendPath,
              resultsProperty,
              query,
              options,
              data,
              filters
            });
          }

          if (resultsProperty) {
            _this5.set(resultsProperty, results);

            if (filters) {
              filters.set(resultsProperty, results);
              filters.updateSynced();
            }
          }

          if (onAfter) {
            return onAfter(results);
          }

          if (_this5.afterSearchRecordsTask) {
            return _this5.afterSearchRecordsTask(results);
          }

          return results;
        } catch (err) {
          _this5.setValidationErrors(errorsProp, err);

          _nventor.default.throw('searchRecordsTask failed', err);

          return false;
        }
      }();
    }).enqueue(),

    /**
     * @deprecated
     * */
    findTask: (0, _emberConcurrency.task)(function*
      /* { adapterName, appendPath, params, errorsProp = 'errors', rawResponse } = {} */
    () {
      return yield this.findConcurrentTask.perform(...arguments);
    }).drop(),

    /**
     * @deprecated
     * */
    findConcurrentTask: (0, _emberConcurrency.task)(function () {
      var _this6 = this;

      let {
        adapterName,
        appendPath,
        params,
        errorsProp = 'errors',
        rawResponse
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        adapterName = adapterName || _this6.adapterName; // debugger @TODDO: check not to use this

        try {
          _this6.clearValidationErrors(errorsProp);

          const model = yield _this6.dataManager.findRecord({
            adapterName,
            appendPath,
            params,
            rawResponse
          });

          if (_this6.afterFindRecordTask) {
            return _this6.afterFindRecordTask(model);
          }

          return model;
        } catch (err) {
          _this6.setValidationErrors(errorsProp, err);

          _nventor.default.throw('findTask failed', err);

          return false;
        }
      }();
    }).enqueue().maxConcurrency(4),

    /**
     * @deprecated
     * */
    validateTask: (0, _emberConcurrency.task)(function () {
      var _this7 = this;

      let {
        adapterName,
        appendPath,
        model,
        errorsProp = 'errors'
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        try {
          _this7.clearValidationErrors(errorsProp);

          adapterName = adapterName || _this7.adapterName;

          const adapter = _this7.dataManager.getAdapter(adapterName);

          const data = adapter.serialize(model);
          const path = adapter.getPath('resourcePath', appendPath);
          const validated = yield _this7.server.call('POST', path, data);
          model.populate(validated.data);
          return model;
        } catch (err) {
          _this7.setValidationErrors(errorsProp, err);

          _nventor.default.throw('validation failed', err);

          return false;
        }
      }();
    }).drop(),

    /**
     * @deprecated
     * */
    createRecordTask: (0, _emberConcurrency.task)(function () {
      var _this8 = this;

      let {
        adapterName,
        appendPath,
        model,
        errorsProp = 'errors',
        onAfter,
        onAfterOptions,
        onAfterCreateRecordTask,
        rawResponse
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        adapterName = adapterName || _this8.adapterName;
        model = model || _this8.model;

        try {
          _this8.clearValidationErrors(errorsProp);

          if (_this8.beforeCreateRecord) {
            model = _this8.beforeCreateRecord(model);
          }

          const saved = yield _this8.dataManager.createRecord({
            adapterName,
            appendPath,
            model,
            rawResponse
          });

          if (onAfter) {
            return onAfter(saved, onAfterOptions);
          }

          if (_this8.afterCreateRecordTask) {
            return _this8.afterCreateRecordTask(saved, onAfterOptions);
          }

          if (onAfterCreateRecordTask) {
            return onAfterCreateRecordTask(saved, onAfterOptions);
          }

          if (_this8.onAfterCreateRecordTask) {
            return _this8.onAfterCreateRecordTask(saved, onAfterOptions);
          }

          if (_this8.onDisplay) {
            _this8.onDisplay(saved, onAfterOptions);

            return saved;
          }

          return saved;
        } catch (err) {
          _this8.setValidationErrors(errorsProp, err);

          _nventor.default.throw('createRecordTask failed', err);

          throw err;
        }
      }();
    }).drop(),
    queueUpdateRecordTask: (0, _emberConcurrency.task)(function* () {
      return yield this.updateRecordTask(...arguments);
    }).enqueue(),

    /**
     * @TODO: rename all updateRecordTask model to dirty
     * @deprecated
     * */
    updateRecordTask: (0, _emberConcurrency.task)(function () {
      var _this9 = this;

      let {
        adapterName,
        appendPath,
        model,
        errorsProp = 'errors',
        onBefore,
        onAfter,
        onAfterOptions,
        rawResponse,
        hasUpdateProps = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        adapterName = adapterName || _this9.adapterName;
        model = model || _this9.dirty;
        let updateProps;

        if (hasUpdateProps) {
          const originalModel = model.get('originalSerialized');
          const originalData = JSON.parse(originalModel);
          const modifiedData = JSON.parse(JSON.stringify(model.serialize()));
          updateProps = _this9.getDifferentProperties(originalData, modifiedData);
        } // when updating specific properties but there is no changes at all


        if (hasUpdateProps && RA.isNilOrEmpty(updateProps)) {
          return model;
        }

        try {
          _this9.clearValidationErrors(errorsProp);

          if (_this9.beforeUpdateRecord) {
            model = _this9.beforeUpdateRecord(model);
          }

          if (onBefore) {
            model = onBefore(model);
          }

          const saved = yield _this9.dataManager.updateRecord({
            adapterName,
            appendPath,
            dirty: model,
            rawResponse,
            updateProps
          });

          if (onAfter) {
            return onAfter(saved, onAfterOptions);
          }

          if (_this9.afterUpdateRecordTask) {
            _this9.afterUpdateRecordTask(saved, onAfterOptions);
          }

          if (_this9.onDisplay) {
            // if (!onAfterOptions) {
            //   const activeSubTab = this.tabsManager.getActiveSubTab(this.tab)
            //   onAfterOptions = {
            //     loadInstructions: {
            //       component: activeSubTab.get('component')
            //     }
            //   }
            // }
            return _this9.onDisplay(saved, onAfterOptions);
          }

          return saved;
        } catch (err) {
          _this9.setValidationErrors(errorsProp, err);

          _nventor.default.throw('updateRecordTask failed', err);

          throw err;
        }
      }();
    }).drop(),

    /**
     * @deprecated
     * */
    replaceRecordTask: (0, _emberConcurrency.task)(function () {
      var _this10 = this;

      let {
        adapterName,
        appendPath,
        model,
        errorsProp = 'errors',
        onAfter,
        rawResponse
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        adapterName = adapterName || _this10.adapterName;
        model = model || _this10.dirty;

        try {
          _this10.clearValidationErrors(errorsProp);

          if (_this10.beforeReplaceRecord) {
            model = _this10.beforeReplaceRecord(model);
          }

          const saved = yield _this10.dataManager.replaceRecord({
            adapterName,
            appendPath,
            dirty: model,
            rawResponse
          });

          if (onAfter) {
            return onAfter(saved);
          }

          if (_this10.afterReplaceRecordTask) {
            return _this10.afterReplaceRecordTask(saved);
          }

          if (_this10.onDisplay) {
            return _this10.onDisplay(saved);
          }

          return saved;
        } catch (err) {
          _this10.setValidationErrors(errorsProp, err);

          _nventor.default.throw('replaceRecordTask failed', err);

          throw err;
        }
      }();
    }).drop(),

    /**
     * @deprecated
     * */
    removeRecordTask: (0, _emberConcurrency.task)(function () {
      var _this11 = this;

      let {
        adapterName,
        appendPath,
        batch,
        model,
        errorsProp = 'errors',
        onAfter,
        confirmMsg,
        rawResponse,
        noConfirm = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const intl = _this11.intl;
        const msg = confirmMsg || intl.t('are you sure you want to delete');
        const confirmed = noConfirm || window.confirm(msg);

        if (confirmed) {
          adapterName = adapterName || _this11.adapterName;

          if (RA.isNilOrEmpty(batch)) {
            model = model || _this11.model;
            model = model || _this11.dirty;
          }

          _this11.set('hasError', false);

          try {
            if (_this11.beforeRemoveRecord) {
              model = _this11.beforeRemoveRecord(model);
            }

            const success = yield _this11.dataManager.removeRecord({
              adapterName,
              appendPath,
              batch,
              model,
              rawResponse
            });

            if (onAfter) {
              if (!RA.isNilOrEmpty(batch)) {
                return onAfter(batch);
              }

              return onAfter(model);
            }

            if (_this11.afterRemoveRecordTask) {
              if (!RA.isNilOrEmpty(batch)) {
                return _this11.afterRemoveRecordTask(batch);
              }

              return _this11.afterRemoveRecordTask(model);
            }

            if (_this11.onDash) {
              if (!RA.isNilOrEmpty(batch)) {
                return _this11.onDash(batch);
              }

              return _this11.onDash(model);
            }

            return success;
          } catch (err) {
            _this11.setValidationErrors(errorsProp, err);

            _nventor.default.throw('removeRecordTask failed', err);
          }
        }
      }();
    }).drop(),

    /**
     * @deprecated
     * */
    cancelEditing() {
      let {
        adapterName,
        dirty,
        onAfter
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      adapterName = adapterName || this.adapterName;
      dirty = dirty || this.dirty;
      const original = this.dataManager.cancelDirty(adapterName, dirty);

      if (onAfter) {
        return onAfter();
      }

      if (this.afterCancelEditing) {
        return this.afterCancelEditing(original);
      }

      if (this.onDisplay) {
        return this.onDisplay(original);
      }

      return original;
    },

    getDifferentProperties(original, modified) {
      if (R.equals(original, modified)) {
        return [];
      }

      return R.pipe(R.keys, R.concat(R.keys(modified)), R.map(key => {
        if (modified[key] !== original[key] && !R.equals(original[key], modified[key])) {
          return key;
        }
      }), R.reject(RA.isNilOrEmpty), R.uniq)(original);
    },

    /**
     * @deprecated
     * */
    fetchUploadsTask: (0, _emberConcurrency.task)(function (_ref4) {
      var _this12 = this;

      let {
        resource,
        resourceKey,
        filters,
        adapterName,
        appendPath,
        params = {}
      } = _ref4;
      return function* () {
        const collectionModel = yield _this12.findTask.perform({
          adapterName,
          appendPath,
          params: {
            resource,
            resourceKey,
            filters,
            ...params
          }
        });
        return collectionModel;
      }();
    }).enqueue(),

    /**
     * @deprecated
     * */
    adapterCallTask: (0, _emberConcurrency.task)(function (method) {
      var _this13 = this;

      let {
        adapterName,
        appendPath,
        data
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return function* () {
        return yield _this13.dataManager.adapterCall(method, adapterName, appendPath, data);
      }();
    }).drop(),
    actions: {
      /**
       * @deprecated
       * */
      cancelEditing() {
        this.cancelEditing(...arguments);
      },

      /**
       * @deprecated
       * */
      setupDirty() {
        return this.setupDirty(...arguments);
      },

      /**
       * @deprecated
       * */
      afterSearch(dashComponent, results) {
        const tab = this.tab;

        if (!_nventor.default.isNilOrEmpty(results)) {
          if (this.tabsManager.isActiveSubTab(tab, {
            component: dashComponent
          })) {
            return this.dash({}, results);
          }
        }

        return this.set('showResults', true);
      }

    }
  });

  _exports.default = _default;
});