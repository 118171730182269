define("client/pods/documents/details/calculations-exclusive-or-inclusive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * returns line amt (qty * price), irrespective of exclusive/inclusive
     * @return {object}  lineAmt as a Big class
     */
    getLineAmtBig() {
      const qty = this.getDataBig('qty');
      const price = this.getDataBig('price');
      const lineAmtBig = qty.times(price);
      return this._deductDiscount(lineAmtBig);
    },

    /**
     * calculate line totals
     */
    calculateLineTotals(_ref) {
      let {
        isTaxExclusive,
        hasTaxMethod
      } = _ref;

      // only calculate if has details
      if (isTaxExclusive) {
        // prices are exclusive
        this._calculateLineWhenPricesAreExclusive(hasTaxMethod);
      } else {
        this._calculateLineWhenPricesAreInclusive(hasTaxMethod);
      }

      this._calculateLineCommission(); // this.sumTotals()

    },

    /**
     * calculate line amounts when prices are exclusive
     * @return {[type]} [description]
     */
    _calculateLineWhenPricesAreExclusive(hasTaxMethod) {
      // number of decimal places to round
      const lineAmtBig = this.getLineAmtBig(); // lineAmtBig = this._deductDiscount(lineAmtBig);
      // use unrounded lineAmt to do tax calculations

      const {
        roundingCurrency,
        unroundedCurrency,
        roundingInventoryPrice
      } = this.getRounding();
      const exclAmt = lineAmtBig.toFixed(roundingCurrency);
      let taxRate = 0;

      if (hasTaxMethod) {
        taxRate = this.getData('taxRate') || 0;
      }

      const taxRateBig = this.newBig(taxRate);
      const inclPrice = this.getDataBig('price').times(taxRateBig.plus(1)).toFixed(roundingInventoryPrice);
      const taxAmtBig = lineAmtBig.times(taxRate); // tax should not be rounded yet
      // incl amt is not required (as tax is only rounded when summing up)

      const taxAmt = taxAmtBig.toFixed(unroundedCurrency);
      const inclAmt = taxAmtBig.plus(exclAmt).toFixed(roundingCurrency);
      this.setData('inclPrice', inclPrice);
      this.setData('exclAmt', exclAmt);
      this.setData('taxAmt', taxAmt);
      this.setData('inclAmt', inclAmt);
    },

    /**
     * calculate line amounts when prices are inclusive
     * @return {[type]} [description]
     */
    _calculateLineWhenPricesAreInclusive(hasTaxMethod) {
      // number of decimal places to round
      const {
        roundingCurrency,
        unroundedCurrency,
        roundingInventoryPrice
      } = this.getRounding();
      const lineAmtBig = this.getLineAmtBig();
      let taxRateBig = this.newBig(0);

      if (hasTaxMethod) {
        taxRateBig = this.getDataBig('taxRate');
      }

      const exclPrice = this.getDataBig('price').div(taxRateBig.plus(1)).toFixed(roundingInventoryPrice);
      const inclAmt = lineAmtBig.toFixed(roundingCurrency); // use unrounded lineAmt to do tax calculations

      const taxAmtBig = lineAmtBig.times(taxRateBig).div(taxRateBig.plus(1));
      const taxAmt = taxAmtBig.toFixed(unroundedCurrency); // use rounded tax amt to calculate excl amt

      const exclAmt = lineAmtBig.minus(taxAmt).toFixed(roundingCurrency);
      this.setData('exclPrice', exclPrice);
      this.setData('exclAmt', exclAmt);
      this.setData('taxAmt', taxAmt);
      this.setData('inclAmt', inclAmt);
    }

  });

  _exports.default = _default;
});