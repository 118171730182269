define("client/pods/components/extensions/apps/tw/invoices/mixins/invoices-cancel", ["exports", "client/pods/components/extensions/apps/tw/invoices/mixins/invoices", "client/mixins/crud", "ember-concurrency"], function (_exports, _invoices, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @TODO: move fetchInvoice and cancelInvoice out of this mixin,
  // those are for the outer components whereas the other functions are for inner components
  // outer component: makes use of 'invoices' (array of invoices for given masterKey)
  // inner component: only makes use of 1 invoice
  var _default = Ember.Mixin.create(_crud.default, _invoices.default, {
    cancelInvoiceTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const number = model.getData('number');
      const msg = this.intl.t('are you sure you want to cancel invoice number') + ': ' + number + '?';

      if (window.confirm(msg)) {
        const adapterName = 'extensions/apps/tw/invoices';
        const appendPath = `?mode=update`;
        const dirty = this.setupDirty({
          adapterName,
          model
        });
        dirty.setData('status', 'cancelled');
        dirty.setData('cancelled', 1);
        yield this.replaceRecordTask.perform({
          adapterName,
          model: dirty,
          appendPath
        }); // this.get('onCancelInvoiceAction')(saved)

        this.set('isEditing', false);
      }
    }).drop(),
    undoCancelInvoiceTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const adapterName = 'extensions/apps/tw/invoices';
      const appendPath = `?mode=update`;
      const dirty = this.setupDirty({
        adapterName,
        model
      });
      dirty.setData('status', 'printed');
      dirty.setData('cancelled', 0);
      yield this.replaceRecordTask.perform({
        adapterName,
        model: dirty,
        appendPath
      });
      this.set('isEditing', false);
    }).drop(),
    actions: {
      edit() {
        this.set('isEditing', true);
      } // async cancelInvoice () {
      // },
      // async undoCancelInvoice () {
      //   const model = this.get('model')
      //   const adapterName = 'extensions/apps/tw/invoices'
      //   const appendPath = `?mode=update`
      //   const dirty = this.setupDirty({
      //     adapterName,
      //     model
      //   })
      //   dirty.setData('status', 'printed')
      //   dirty.setData('cancelled', 0)
      //   await this.get('replaceRecordTask').perform({
      //     adapterName,
      //     model: dirty,
      //     appendPath
      //   })
      //   this.set('isEditing', false)
      // }


    }
  });

  _exports.default = _default;
});