define("client/pods/socket/service", ["exports", "client/config/environment", "ramda", "client/mixins/date", "client/utils/nventor"], function (_exports, _environment, R, _date, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global io */
  const setupListeners = function (self, _ref) {
    let {
      socketName,
      tokenData,
      token,
      host
    } = _ref;
    // const setupListeners = function (self, { socketName, tokenData, token, host }) {
    const version = _environment.default.version;
    const apiVersion = _environment.default.apiVersion;
    const socketOptions = {
      transports: ['websocket'],
      upgrade: false
    };
    socketOptions.path = `/socket-${apiVersion}`;
    let socket;

    if (self.get('isConnected') && self.get('isConnected') === tokenData.pod) {
      if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {
        console.log('===socket already connected===');
      }

      socket = self.socket;
      return false;
    } else {
      if (self.socket) {
        // disconnect from old socket
        socket = self.socket;
        console.log(`disconnecting...${tokenData.pod}`);
        socket.disconnect();
      }

      socket = io.connect(`${host}`, socketOptions);
      self.set('isConnected', tokenData.pod);
    }

    socket.on('connect', () => {
      if (self.get('isConnected') && self.get('isConnected') !== tokenData.pod) {
        self.disconnect();
      }

      if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {
        console.log(`===socket is connecting to ${socketName}: ${host} -- ${tokenData.pod} ===`);
      }

      const data = {
        version,
        host: window.location.hostname // id: self.get('socketId')

      };
      socket.emit('initiate', token, data);
      self.set('socketChanged', Math.random());
      self.set('isConnected', tokenData.pod);
      self.loggedInComponent?.setConnectedStatus?.(tokenData.pod);
      self.get('server').call('POST', 'api/protected/app/updates', data).then(res => {
        return self.get('updates').isUpdateRequired(R.propOr({}, 'data', res));
      });
    });
    socket.on('disconnect', () => {
      self.set('isConnected', false);
      self.loggedInComponent?.setConnectedStatus?.(false);
      self.set('disconnectedAt', self.getNowZ());
      self.set('socketChanged', Math.random());

      if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {
        console.log('***APP: socket DISCONNECTED.***');
      }
    });
    socket.on('afterInitiate', data => {
      console.log(`===socket connected SUCCESSFULLY: ${socketName}: ${host} (id: ${data.id}, pod: ${data.pod})===`);

      if (self.get('isConnected') !== data.pod) {
        console.log(`disconnecting...${data.pod}`);
        socket.disconnect();
        return false;
      }

      self.set('socketId', data.id);
      self.set('apiVersion', data.apiVersion);
    }); // socket.on('apiVersion', data => {
    //   self.set('apiVersion', data.apiVersion)
    //   const socketId = self.get('socketId')
    //   const disconnectedAt = data.disconnectedAt || self.get('disconnectedAt')
    //   return self.get('updates').checkForReSyncData(socketId, disconnectedAt)
    // })

    socket.on('alert', data => {
      data.action = data.action || 'arrayPush';
      data.id = data.id || 'notifications';
      data.propPath = _nventor.default.isNilOrEmptyDotPathOr(['alerts'], 'propPath')(data);

      if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {
        console.log('======alert data=====');
        console.log(data);
      }

      self.get('dataManager').syncUpdate(data);
    });
    socket.on('update', data => {
      if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {// console.log('======sync update data=====')
        // console.log(data)
      }

      self.get('dataManager').syncUpdate(data);
    });
    socket.on('updateBatch', data => {
      if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {// console.log('======sync update batch=====')
        // console.log(data.batch)
      }

      let batch = R.propOr([], 'batch')(data);

      if (!R.is(Array, batch)) {
        batch = [batch];
      }

      if (data?.debug) {
        debugger; //eslint-disable-line
      }

      R.forEach(data => {
        self.get('dataManager').syncUpdate(data);
      })(batch);
    });
    socket.on('remove', data => {
      self.get('dataManager').syncRemove(data);
    });
    socket.on('message', data => {
      console.log('----receiving message----');
      console.log(data);
    });
    socket.on('updateRequired', data => {
      self.get('updates').isUpdateRequired(data);
    });
    self.set(socketName, socket);
    return socket;
  };
  /**
   * performs and manages connections to servers
   */


  var _default = Ember.Service.extend(_date.default, {
    updates: Ember.inject.service(),
    server: Ember.inject.service(),
    host: _environment.default.APP.socketUrl,
    isConnected: false,
    socketChanged: '',

    init() {
      this._super(...arguments);

      this.set('session', Math.random()); // connects to socket on init
      // this.connect()
    },

    // isConnected: computed('connected', funciton () {
    //   return this.get('connected')
    // }),
    connect(loggedInComponent) {
      const self = this;
      self.loggedInComponent = loggedInComponent;
      const token = self.getToken();

      if (token) {
        const tokenData = this.get('storage').get('tokenData');
        const host = this.host;
        setupListeners(self, {
          socketName: 'podSocket',
          tokenData,
          token,
          host
        });
      } else {
        this.disconnect();
      }
    },

    disconnect() {
      this._leaveSocket('pod');
    },

    getToken() {
      return this.storage.get('token');
    },

    _leaveSocket(name) {
      const socket = this.get(`${name}Socket`);

      if (socket) {
        socket.disconnect();
      }
    },

    /**
     * emit event to server
     */
    send(eventName, data) {
      const token = this.getToken();
      this.podSocket.emit(eventName, token, data);
    }
    /**
     * join pod socket connection.
     * (only for logged in users)
     * only users of pods will receive broadcast
     */
    // _joinPodSocket() {
    //
    //   console.log('...POD: connecting to socket...');
    //   const tokenData = this.get('storage').get('tokenData');
    //
    //   const pod = tokenData.pod;
    //   console.log('pod: ' + pod);
    //   const namespace = this.get('host') + `/${pod}`;
    //   const nsp = io(namespace);
    //   nsp.on('connect', function () {
    //
    //     console.log('===POD: socket connection started.===');
    //
    //     nsp.on('pod', function (data) {
    //
    //       console.log('----pod receiving sync----');
    //       console.log(data);
    //     });
    //   });
    //
    //   nsp.on('disconnect', function () {
    //
    //     console.log('***POD: socket DISCONNECTED.***');
    //   });
    //
    //   this.set('podSocket', nsp);
    // }


  });

  _exports.default = _default;
});