define("client/pods/components/documentations/documentation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y2irAe2I",
    "block": "{\"symbols\":[\"@tab\",\"&default\"],\"statements\":[[7,\"span\",true],[8],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"documentations/documentation-icon\",[],[[\"@documentation\",\"@documentationId\",\"@documentationResource\",\"@component\",\"@tab\"],[[23,0,[\"documentation\"]],[23,0,[\"documentationId\"]],[23,0,[\"documentationResource\"]],[23,0,[]],[23,1,[]]]]],[0,\"\\n\\n    \"],[5,\"documentations/documentation-desc\",[],[[\"@documentation\",\"@documentationId\",\"@documentationResource\",\"@tab\"],[[23,0,[\"documentation\"]],[23,0,[\"documentationId\"]],[23,0,[\"documentationResource\"]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documentations/documentation/template.hbs"
    }
  });

  _exports.default = _default;
});