define("client/pods/components/products/panels-display/sets-with-choices-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YXSAOoWc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\n\\t\\t\"],[1,[28,\"products/products-editor/sets-with-choices-editor\",null,[[\"tab\",\"model\",\"isNew\",\"isReadonly\",\"isDisabled\"],[[24,[\"tab\"]],[24,[\"model\"]],false,[24,[\"isReadonly\"]],[24,[\"isDisabled\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"hasInventory\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__sidebar-right\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"products/inventory/inventory-manager\",null,[[\"isTable\",\"product\"],[true,[24,[\"model\"]]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\t\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-display/sets-with-choices-display/template.hbs"
    }
  });

  _exports.default = _default;
});