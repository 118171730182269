define("client/pods/components/line/line-login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R28YyuKa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-input-row\",null,null,{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"hasExchange\",\"disabled\",\"isRunning\",\"onSubmit\"],[\"login\",[24,[\"hasExchange\"]],[24,[\"isReadonly\"]],[24,[\"loginTask\",\"isRunning\"]],[28,\"action\",[[23,0,[]],\"login\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/line/line-login/template.hbs"
    }
  });

  _exports.default = _default;
});