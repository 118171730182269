define("client/pods/components/documents/document-print/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    classNames: ['u-print-container'],
    hasPrices: true,
    hasPrintBtn: true,
    hasImages: false,
    imageHeight: 48,
    // 32 (actual image height) + 16 (margin)
    originalRows: Ember.A([]),

    // @NOTE: PAGE SIZE INFO
    // A4: 210 × 297 millimeters OR 8.27 × 11.69 inches
    // US Letter: 216 × 279 millimeters or 8.5 × 11 inches
    // pixels (1px = 1/96th of 1in)
    // inches (1in = 96px = 2.54cm)
    // work out height in px for given page size
    // A4 SIZES:
    //  HEIGHT: 11.69*96 = 1122.24 ~ round to: 1122px for A4
    //  WIDTH: 8.27*96 = 793.92 ~ round to: 793px
    //
    // US LETTER
    // 11*96 = 1056
    init() {
      this.set('firstRender', true);
      this.set('page', {
        a4: 1040
      });
      this.crud.addLists(this, ['documents/lists/print']);

      this._super(...arguments); // const model = this.model
      // const details = model.getData('details')
      // const details = model.detailsWithDiscountAndReward


      const details = this.allDetails;
      const pagesData = [{
        page: 1,
        details: details,
        isLastPageWithDetails: false
      }];
      this.set('pagesData', pagesData);
      const printImagesOnDocuments = this?.settings?.settingsModel?._data?.printImagesOnDocuments || false;

      if (printImagesOnDocuments) {
        this.set('hasImages', true);
      }

      this.setup();
    },

    setShowFromPerson(docType, docName) {
      let showFromPerson = false;

      if (R.equals(docType, 'purchases')) {
        if (R.equals(docName, 'consignments-returns')) {
          showFromPerson = true;
        }

        if (R.equals(docName, 'quotes')) {
          showFromPerson = true;
        }

        if (R.equals(docName, 'returns')) {
          showFromPerson = true;
        }
      }

      if (R.equals(docType, 'sales')) {
        if (R.equals(docName, 'quotes')) {
          showFromPerson = true;
        }
      }

      this.set('showFromPerson', showFromPerson);
    },

    setHasReturnsDetails(docType, docName) {
      let hasReturnsDetails = false;

      if (R.equals(docType, 'purchases')) {
        if (R.equals(docName, 'invoices')) {
          hasReturnsDetails = true;
        }
      }

      if (R.equals(docType, 'sales')) {
        if (R.equals(docName, 'invoices')) {
          hasReturnsDetails = true;
        }
      }

      this.set('hasReturnsDetails', hasReturnsDetails);
    },

    setHasGroups(docType, docName) {
      let hasGroups = false;

      if (R.equals(docType, 'sales')) {
        if (R.equals(docName, 'invoices')) {
          hasGroups = true;
        }
      }

      this.set('hasGroups', hasGroups);
    },

    setHasEmptyRows(docType, docName) {
      let hasEmptyRows = false;

      if (R.equals(docType, 'purchases')) {
        if (R.equals(docName, 'quotes')) {
          hasEmptyRows = true;
        }
      }

      this.set('hasEmptyRows', hasEmptyRows);
    },

    allDetails: Ember.computed('model.{detailsWithDiscountAndReward,_data.groups.[],returnsDetails.[]}', 'hasGroups', 'hasReturnsDetails', 'showAllReturns', function () {
      const model = this.model || {};
      const details = model.detailsWithDiscountAndReward || [];
      const hasReturnsDetails = this.hasReturnsDetails || this.showAllReturns;
      const hasGroups = this.hasGroups;

      if (!hasGroups && !hasReturnsDetails) {
        return details;
      }

      const groups = model?._data?.groups || [];
      let newDetails = [];
      newDetails = R.concat(newDetails, details);
      const returnsDetails = model?.returnsDetails || [];

      if (RA.isNotNilOrEmpty(returnsDetails) && hasReturnsDetails) {
        const newReturnDetails = this.getFormattedReturnsDetails({
          details: returnsDetails
        });
        newDetails = R.concat(newDetails, newReturnDetails);
      }

      if (RA.isNotNilOrEmpty(groups) && hasGroups) {
        R.forEach(group => {
          const groupDetails = group?.detailsWithDiscountAndReward || [];
          newDetails = R.concat(newDetails, groupDetails);
          const groupReturnsDetails = group?.returnsDetails || [];

          if (RA.isNotNilOrEmpty(groupReturnsDetails) && hasReturnsDetails) {
            const newGroupReturnDetails = this.getFormattedReturnsDetails({
              details: groupReturnsDetails
            });
            newDetails = R.concat(newDetails, newGroupReturnDetails);
          }
        })(groups);
      }

      return newDetails;
    }),

    getFormattedReturnsDetails(_ref) {
      let {
        details = []
      } = _ref;
      // return header (credit note)
      const returnHeader = Ember.Object.create({
        isReturnHeader: true,
        isReturnDetail: true
      });
      let newReturnDetails = R.prepend(returnHeader, details); // return points

      R.pipe(R.pluck('_data'), R.pluck('docData'), R.reject(RA.isNilOrEmpty), R.uniqBy(R.prop('_key')), R.forEach(doc => {
        const rewardPointApplied = doc?.rewardPointApplied || 0;

        if (R.gt(rewardPointApplied, 0)) {
          const returnPoints = Ember.Object.create({
            rewardExclTotal: doc.rewardExclTotal,
            rewardInclTotal: doc.rewardInclTotal,
            rewardPointApplied,
            isReturnPoints: true
          });
          newReturnDetails = R.append(returnPoints, newReturnDetails);
        }
      }))(details);
      return newReturnDetails || [];
    },

    // detect any change from details and call setupPagesData()
    allDetailsObserver: Ember.observer('allDetails', 'hasImages', 'hasReturnsDetails', function () {
      this.setupPagesData();
    }),

    setup() {
      const isPrintAllFromContactStatements = this?.isPrintAllFromContactStatements || false;

      if (isPrintAllFromContactStatements) {
        const model = this.model || {};
        const docType = model?._data?.docType || '';
        const docName = model?._data?.docName || '';
        const showFromPerson = this?.showFromPerson;

        if (RA.isNilOrEmpty(showFromPerson)) {
          this.setShowFromPerson(docType, docName);
        }

        const hasReturnsDetails = this?.hasReturnsDetails;

        if (RA.isNilOrEmpty(hasReturnsDetails)) {
          this.setHasReturnsDetails(docType, docName);
        }

        const hasGroups = this?.hasGroups;

        if (RA.isNilOrEmpty(hasGroups)) {
          this.setHasGroups(docType, docName);
        }

        const hasEmptyRows = this?.hasEmptyRows;

        if (RA.isNilOrEmpty(hasEmptyRows)) {
          this.setHasEmptyRows(docType, docName);
        }
      }
    },

    // didInsertElement () {
    //   const self = this
    //   self._super(...arguments)
    //   const model = self.get('model')
    //   // const details = model.getData('details')
    //   // const details = model.detailsWithDiscountAndReward
    //   const details = this.allDetails
    //   const pageHeight = self.get('page.a4')
    //   const headerHeight = self.$('.documents__header-container').outerHeight() || 0
    //   const totalsHeight = self.$('.documents__totals-container').outerHeight() || 0
    //   let allowedHeight = pageHeight - headerHeight - totalsHeight
    //   // debugger
    //   let currentPage = 0
    //   let pageAccumulatedHeight = 0
    //   const pagesData = []
    //   const rows = self.$('.u-print-table__data-row')
    //   rows.each((index, row) => {
    //     row = self.$(row)
    //     const rowHeight = row.outerHeight() || 0
    //     if (index === (rows.length - 1)) {
    //       if (RA.isNotNilOrEmpty(pagesData[currentPage])) {
    //         pagesData[currentPage].isLastPageWithDetails = true
    //       }
    //       allowedHeight = pageHeight - headerHeight - totalsHeight - 10
    //     } else {
    //       allowedHeight = pageHeight - headerHeight - totalsHeight - 50
    //     }
    //     pageAccumulatedHeight = pageAccumulatedHeight + rowHeight
    //     if (pageAccumulatedHeight > allowedHeight) {
    //       ++currentPage
    //       pageAccumulatedHeight = 0
    //     }
    //     let isLastPageWithDetails = false
    //     if (rows.length === 1) {
    //       isLastPageWithDetails = true
    //     }
    //     if (!pagesData[currentPage]) {
    //       pagesData[currentPage] = {
    //         page: currentPage + 1,
    //         details: [],
    //         isLastPageWithDetails
    //       }
    //     }
    //     pagesData[currentPage].details.pushObject(details.objectAt(index))
    //   })
    //   self.set('pagesData', pagesData)
    // },
    hasGroupsReturns: Ember.computed('model._data.groups.@each.synced', function () {
      const model = this.model || {};
      let hasGroupsReturns = false;

      if (model && this.model) {
        const groups = model.getData('groups') || [];
        R.forEach(group => {
          const groupReturnDetails = group.get('returnsDetails') || [];

          if (RA.isNotNilOrEmpty(groupReturnDetails)) {
            hasGroupsReturns = true;
          }
        })(groups);
      }

      return hasGroupsReturns;
    }),

    didRenderLastPage(pagesData, index) {
      this._super(...arguments);

      if (pagesData.length - 1 === index) {
        if (this.firstRender) {
          Ember.run.scheduleOnce('afterRender', () => {
            const footerContainer = this.$('.documents__footer-container');
            const footerContainerHeight = footerContainer.outerHeight() || 0;
            const pageHeight = this.get('page.a4');
            const pages = this.$('.u-print-page');
            const lastPage = pages.last();
            const lastPageHeight = lastPage.outerHeight() || 0;
            const pagesData = this.pagesData;

            if (lastPageHeight > pageHeight) {
              this.$('.documents__footer-container').remove();
              let nextPage = parseInt(pages.length);
              nextPage = nextPage + 1;
              pagesData.pushObject({
                page: nextPage,
                details: [],
                isLastPageWithDetails: false
              });
              this.set('pagesData', pagesData);
            }
          });
          this.set('firstRender', false);
        }
      }
    },

    useWebsiteLogo: Ember.computed('settings.settingsModel._data.useWebsiteLogo', function () {
      return this.get('settings.settingsModel._data.useWebsiteLogo') || false;
    }),
    isDocumentHasWebsiteKeyAndLogo: Ember.computed('model._data.{websiteKey,docLogo}', function () {
      const model = this?.model || {};
      const websiteKey = model?._data?.websiteKey;
      const docLogo = model?._data?.docLogo;

      if (websiteKey && docLogo) {
        return true;
      }

      return false;
    }),
    subHeaderComponent: Ember.computed('model._data.{docType,docName}', function () {
      return 'documents/document-print/sub-header-references';
    }),
    detailsComponent: Ember.computed('model._data.{docType,docName}', function () {
      return 'documents/document-print/details-prices';
    }),
    footerComponent: Ember.computed('model._data.{docType,docName}', function () {
      const docType = this.model?._data?.docType;
      const docName = this.model?._data?.docName;

      if (docType === 'sales') {
        if (docName === 'quotes') {
          return 'documents/document-print/footer-sales-terms';
        }

        if (docName === 'orders') {
          return 'documents/document-print/footer-sales-terms';
        }

        if (docName === 'invoices') {
          return 'documents/document-print/footer-sales-delivery-notes';
        }

        if (docName === 'consignments') {
          return 'documents/document-print/footer-sales-delivery-notes';
        }
      }

      if (docType === 'purchases') {
        if (docName === 'quotes') {
          return 'documents/document-print/footer-purchases-terms';
        }

        if (docName === 'orders') {
          return 'documents/document-print/footer-purchases-orders';
        }

        if (docName === 'invoices') {
          return 'documents/document-print/footer-purchases-orders';
        }

        if (docName === 'consignments') {
          return 'documents/document-print/footer-purchases-orders';
        }
      }

      return '';
    }),
    showTaxes: Ember.computed('model.{_data.taxMethodKey}', 'settings.settingsModel._data.taxRateKey', function () {
      const taxSettings = this.get('settings.settingsModel._data.taxRateKey');

      if (taxSettings === 'none' || taxSettings === 'zero') {
        return false;
      }

      const modelTaxMethod = this.get('model._data.taxMethodKey');

      if (modelTaxMethod === 'none' || modelTaxMethod === 'zero') {
        return false;
      }

      return true;
    }),

    setupInit() {
      this.set('firstRender', true);
      this.set('page', {
        a4: 1040
      });

      this._super(...arguments); // const model = this.model
      // const details = model.detailsWithDiscountAndReward


      const details = this.allDetails;
      const pagesData = [{
        page: 1,
        details: details,
        isLastPageWithDetails: false
      }];
      this.set('pagesData', pagesData);
      this.setup();
    },

    setupPagesData() {
      const self = this;

      self._super(...arguments); // const model = self.get('model')
      // const details = model.getData('details')
      // const details = model.detailsWithDiscountAndReward


      const details = this.allDetails;
      const pageHeight = self.get('page.a4');
      const headerHeight = self.$('.documents__header-container').outerHeight() || 0;
      const totalsHeight = self.$('.documents__totals-container').outerHeight() || 0;
      let allowedHeight = pageHeight - headerHeight - totalsHeight;
      let currentPage = 0;
      let pageAccumulatedHeight = 0;
      const pagesData = [];
      self.set('pagesData', pagesData);
      const rows = self.$('.u-print-table__data-row');
      const rowsLength = parseInt(rows?.length) || 0;
      const detailsLength = parseInt(details?.length) || 0;

      if (rowsLength < detailsLength) {
        const difference = detailsLength - rowsLength;

        for (let i = 0; i < difference; i++) {
          rows.push(rows[0]);
        }
      }

      if (rowsLength > detailsLength) {
        const difference = rowsLength - detailsLength;
        rows.splice(difference);
      }

      const hasImages = this?.hasImages || false;
      rows.each((index, row) => {
        row = self.$(row);
        const isProduct = row.attr('data-item-type') === 'product';
        const originalHeight = R.pipe(R.find(R.propEq('index', index)), R.propOr(null, 'originalHeight'))(this.originalRows); // if not found, should be undefined or null

        let rowHeight = originalHeight || row?.outerHeight() || 0;

        if (hasImages && isProduct) {
          this.originalRows.pushObject({
            index,
            originalHeight: rowHeight
          });

          if (rowHeight < this.imageHeight) {
            rowHeight = this.imageHeight; // images + margin
          }
        }

        if (index === rows.length - 1) {
          if (RA.isNotNilOrEmpty(pagesData[currentPage])) {
            // pagesData[currentPage].isLastPageWithDetails = true
            Ember.set(pagesData[currentPage], 'isLastPageWithDetails', true);
          }

          allowedHeight = pageHeight - headerHeight - totalsHeight - 10;
        } else {
          allowedHeight = pageHeight - headerHeight - 50;
        }

        pageAccumulatedHeight = pageAccumulatedHeight + rowHeight;

        if (pageAccumulatedHeight > allowedHeight) {
          ++currentPage;
          pageAccumulatedHeight = 0;
        }

        let isLastPageWithDetails = false;

        if (rows.length === 1) {
          isLastPageWithDetails = true;
        }

        if (!pagesData[currentPage]) {
          pagesData[currentPage] = {
            page: currentPage + 1,
            details: [],
            isLastPageWithDetails
          };
        }

        pagesData[currentPage].details.pushObject(details.objectAt(index));
      });
      self.set('pagesData', pagesData);
    },

    deliveryNotePrintFormat: Ember.computed('settings.settingsModel._data.deliveryNotePrintFormat', 'model.isSalesInvoices', function () {
      const model = this?.model || {};
      const isSalesInvoices = model?.isSalesInvoices || false;

      if (!isSalesInvoices) {
        return 'default';
      }

      return this?.settings?.settingsModel?._data?.deliveryNotePrintFormat || 'default';
    }),
    showUnit: Ember.computed('settings.settingsModel._data.showUnit', function () {
      return R.pathOr('showUnitBelowItems', ['settings', 'settingsModel', '_data', 'showUnit'])(this);
    }),
    showProductCode: Ember.computed('settings.settingsModel._data.showProductCode', function () {
      return R.pathOr('showCodeBelowItems', ['settings', 'settingsModel', '_data', 'showProductCode'])(this);
    })
  }, (_applyDecoratedDescriptor(_obj, "didRenderLastPage", [_dec], Object.getOwnPropertyDescriptor(_obj, "didRenderLastPage"), _obj), _applyDecoratedDescriptor(_obj, "setupInit", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setupInit"), _obj), _applyDecoratedDescriptor(_obj, "setupPagesData", [_dec3], Object.getOwnPropertyDescriptor(_obj, "setupPagesData"), _obj)), _obj)));

  _exports.default = _default;
});