define("client/pods/emails/campaigns/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        name: '',
        description: '',
        contactsGroupKey: '',
        contactsGroup: '',
        subject: '',
        scheduledDateZ: '',
        status: this.get('constants.status.draft'),
        template: ''
      };
    },

    allowSend: Ember.computed('_data.{contactsGroupKey,template,status,subject}', function () {
      if (this?._data?.contactsGroupKey && this?._data?.template && this?._data?.subject && this?._data?.status) {
        return true;
      }

      return false;
    }),
    isSendDisabled: Ember.computed('allowSend', function () {
      return !this.allowSend;
    })
  });

  _exports.default = _default;
});