define("client/mixins/editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../utils/nventor';
  //

  /**
   * common functions shared by all components that have editing capabilities
   */
  var _default = Ember.Mixin.create({
    didRender() {
      this._super(...arguments);

      this.setTabAsEditing();
    },

    setTabAsEditing() {
      const tab = this.tab;

      if (tab) {
        tab.set('isEditing', true);
      }
    }

  });

  _exports.default = _default;
});