define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-overview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fq1fsgyb",
    "block": "{\"symbols\":[\"@model\",\"@allowDuplicating\",\"@copyErrors\",\"@dirty\",\"@duplicateRecordTask\",\"@errorMsg\",\"@onAssignSalesPerson\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@parentTab\",\"@resourceList\",\"@tab\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-report\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\\n\"],[5,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",[],[[\"@allowDuplicating\",\"@copyErrors\",\"@dirty\",\"@duplicateRecordTask\",\"@errorMsg\",\"@hasEditBtn\",\"@model\",\"@onAssignSalesPerson\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@parentTab\",\"@resourceList\",\"@tab\"],[[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],true,[23,1,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]],[23,14,[]],[23,15,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-overview/template.hbs"
    }
  });

  _exports.default = _default;
});