define("client/pods/channels/shop/campaigns/automations/details/model", ["exports", "client/mixins/products-children", "client/mixins/uploads", "client/pods/channels/shop/campaigns/details/model"], function (_exports, _productsChildren, _uploads, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const ChannelShopCampaignsAutomationsDetailsBaseModel = _model.default.extend(_productsChildren.default, _uploads.default, {
    init() {
      this._super(...arguments);

      this.set('requiredAttrs', ['_data.itemKey', '_data.item', '_data.priceDiscounted']);
    },

    defaults() {
      return {
        productSync: ['item', 'templates', 'productTags', 'productDisplayTags', 'description', 'additionalDescription', 'recurringSchedules'],
        automationQty: 1,
        status: this.get('constants.status.active'),
        deliveryDateSettings: this.get('constants.togglesAuto.auto'),
        // showOriginalPrice: this.get('constants.togglesAuto.auto'),
        onSale: this.get('constants.togglesOnOff.on'),
        showLimitedQty: this.get('constants.togglesAuto.auto'),
        showCountdownTimer: this.get('constants.togglesAuto.auto'),
        shippingFeeType: this.get('constants.shippingFeeType.flat'),
        commissionMethod: this.get('constants.togglesAuto.auto')
      };
    }

  });

  let ChannelShopCampaignsAutomationsDetailsModel = (_dec = Ember._tracked, (_class = class ChannelShopCampaignsAutomationsDetailsModel extends ChannelShopCampaignsAutomationsDetailsBaseModel {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "_data", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChannelShopCampaignsAutomationsDetailsModel;
});