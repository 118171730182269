define("client/pods/components/websites/websites-editor/shop-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({// actions: {
    //   loadShopSource (shop) {
    //     const model = this.get('model')
    //     model.setData('sourceKey', shop.getData('_key'))
    //   },
    //   unloadShopSource () {
    //     const model = this.get('model')
    //     model.setData('sourceKey', '')
    //   }
    // }
  });

  _exports.default = _default;
});