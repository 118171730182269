define("client/pods/components/helpers/is-access-allowed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fbO3aDIc",
    "block": "{\"symbols\":[\"&default\",\"@resource\"],\"statements\":[[7,\"span\",false],[3,\"did-insert\",[[28,\"perform\",[[23,0,[\"didInsertTask\"]],[23,2,[]]],null]]],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isAccessAllowed\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/is-access-allowed/template.hbs"
    }
  });

  _exports.default = _default;
});