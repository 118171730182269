define("client/pods/components/elements/print-content-container/component", ["exports", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    dispatchService: Ember.inject.service('dispatch'),
    constantsService: Ember.inject.service('constants'),
    tagName: 'div',
    classNames: ['print-content-container'],
    hasPrintBtn: true,
    hasTotalDue: false,
    batchStatus: null,

    didInsertElement() {
      this._super(...arguments);

      this.setPrintContainer();
    },

    willDestroyElement() {
      if (this?.isDestroyed === false) {
        window.$('#print-container').html('');
      }
    },

    setPrintContainer() {
      const html = this.$().find('.print-content:visible').html();
      window.$('#print-container').html(html);
    },

    allowRequestNewWaybill: Ember.computed('lastPrintTimestamp', function () {
      const isHCT = R.pathEq(['model', '_data', 'transporterExtension'], 'hct')(this);

      if (isHCT) {
        return true;
      }

      return false;
    }),
    hasWaybill: Ember.computed('lastPrintTimestamp', function () {
      const hasWaybill = R.pipe(R.path(['model', '_data', 'waybill']), RA.isNotNilOrEmpty)(this);

      if (hasWaybill) {
        return true;
      }

      return false;
    }),
    generateWaybillsTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        isNewWaybill = false,
        isUpdate = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const documents = [_this.model];
        const response = yield _this.dispatchService.generateAndDownloadWaybillsTask.perform({
          component: _this,
          documents,
          transporterExtension: _this.constantsService.transporterExtensions.hct,
          isNewWaybill,
          isUpdate
        });

        _this.set('batchStatus', response);

        const lastPrintTimestamp = new Date().getTime();

        _this.set('lastPrintTimestamp', lastPrintTimestamp);

        return response;
      }();
    }),

    resetBatchStatus() {
      this.set('batchStatus', null);
    },

    actions: {
      printDialog() {
        this.setPrintContainer();

        if (this.onPrintDialog) {
          this.onPrintDialog();
        }

        window.print();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "resetBatchStatus", [_dec], Object.getOwnPropertyDescriptor(_obj, "resetBatchStatus"), _obj)), _obj)));

  _exports.default = _default;
});