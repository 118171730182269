define("client/pods/settings/service", ["exports", "ramda", "ramda-adjunct", "client/config/environment", "ember-concurrency"], function (_exports, R, RA, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    dataManager: Ember.inject.service(),
    constants: Ember.inject.service(),
    storage: Ember.inject.service(),
    config: _environment.default,
    setupTask: (0, _emberConcurrency.task)(function* () {
      const settingsModel = yield this.dataManager.findRecord({
        adapterName: 'settings'
      });
      this.set('settingsModel', settingsModel);
    }),

    getPodKey() {
      const tokenData = this.storage.get('tokenData');
      return tokenData.pod;
    },

    getModel() {
      return this.settingsModel;
    },

    getProp(prop, defaultValue) {
      let override = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (this.settingsModel) {
        if (RA.isNotNilOrEmpty(override)) {
          return override.getData('roundingInventoryQty', this.settingsModel.getData(prop, defaultValue));
        }

        return this.settingsModel.getData(prop, defaultValue);
      }
    },

    // overrideWith ({ prop, override, defaultValue }) {
    //   if (override != null) {
    //     return override
    //   }
    //   return this.get('settingsModel').getData(prop, defaultValue)
    // },
    getDefaultTaxRate() {
      let taxes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      const taxRateKey = this.getProp('taxRateKey');
      const defaultTax = taxes.findBy('_data._key', taxRateKey);

      if (defaultTax) {
        return defaultTax.getData('value');
      }

      const firstObject = taxes.get('firstObject');

      if (firstObject) {
        return firstObject.getData('value') || '0';
      }

      return '0';
    },

    getDefaultLogo() {
      return this.getProp('defaultLogo');
    },

    getDefaultTerms(docType, docName) {
      docName = docName.charAt(0).toUpperCase() + docName.slice(1);
      return this.getProp(`${docType}${docName}Terms`);
    },

    shouldTranslateLabel(value, translate, itemTranslate) {
      if (translate) {
        if (itemTranslate === true) {
          return true;
        }

        if (itemTranslate === '' || itemTranslate == null) {
          return true;
        }

        return itemTranslate;
      }

      if (itemTranslate === '' || itemTranslate == null) {
        return false;
      }

      return itemTranslate;
    },

    roundingCurrency: Ember.computed('settingsModel._data.roundingCurrency', function () {
      return this.getProp('roundingCurrency') || 0;
    }),
    roundingInventoryPrice: Ember.computed('settingsModel._data.roundingInventoryPrice', function () {
      return this.getProp('roundingInventoryPrice') || 2;
    }),
    roundingInventoryQty: Ember.computed('settingsModel._data.roundingInventoryQty', function () {
      return this.getProp('roundingInventoryQty') || 2;
    }),
    unroundedCurrency: 8,

    isBetaAllowed(betaPath) {
      if (!betaPath) {
        return true;
      }

      return this.getProp(betaPath);
    },

    shopUrlPrefix: Ember.computed('settingsModel._data.shopUrlPrefix', function () {
      return this.get('settingsModel._data.shopUrlPrefix');
    }),

    allowTwInvoicing(docType, docName) {
      if (docType === 'sales' && docName === 'invoices') {
        const hasTwInvoicesFeatures = this.getProp('features.extensions.tw_invoices');
        const twInvoicesSetting = this.getProp('tw_invoices');
        const hasTwEInvoicesFeatures = this.getProp('features.extensions.tw_eInvoices');
        const twEInvoicesSetting = this.getProp('tw_eInvoices');

        if (hasTwInvoicesFeatures && twInvoicesSetting === this.get('constants.togglesOnOff.on') || hasTwEInvoicesFeatures && twEInvoicesSetting === this.get('constants.togglesOnOff.on')) {
          return true;
        }
      }

      return false;
    },

    hasTwInvoices(document) {
      const hasTwInvoicesFeature = this.getProp('features.extensions.tw_invoices');

      if (hasTwInvoicesFeature) {
        const hasTwInvoices = this.getProp('tw_invoices');

        if (hasTwInvoices === this.get('constants.invoicing.on')) {
          const paymentMethodKey = document.getData('paymentMethodKey');

          if (paymentMethodKey !== 'free') {
            // @TODO: should change document.auotPrintInvoices to document.printInvoices
            if (document.getData('autoPrintInvoices') === this.get('constants.invoicing.on')) {
              return true;
            }
          }
        }
      }

      return false;
    },

    hasTwEInvoices(document) {
      const hasTwInvoicesFeature = this.getProp('features.extensions.tw_eInvoices');

      if (hasTwInvoicesFeature) {
        const hasTwInvoices = this.getProp('tw_eInvoices');
        const settingsAutoPrintInvoices = this.getProp('autoPrintInvoices');

        if (hasTwInvoices === this.get('constants.invoicing.on')) {
          const paymentMethodKey = document.getData('paymentMethodKey');

          if (paymentMethodKey !== 'free') {
            // @TODO: should change document.auotPrintInvoices to document.printInvoices
            const isSettingsSetToAutoPrint = settingsAutoPrintInvoices === this.get('constants.invoicing.on');
            const isDocSetToNotPrint = R.anyPass([R.equals('off')])(document.getData('autoPrintInvoices'));

            if (isDocSetToNotPrint) {
              return false;
            }

            const isDocSetToPrint = R.anyPass([R.equals('on')])(document.getData('autoPrintInvoices'));

            if (isDocSetToPrint) {
              return true;
            }

            if (isSettingsSetToAutoPrint) {
              return true;
            }
          }
        }
      }

      return false;
    },

    getDefaultEInvoiceProvider() {
      const systemLead = this.getProp('tw_eInvoice_systemlead', {});
      const ecPay = this.getProp('tw_eInvoice_ecPay', {});

      if (systemLead?.enabled) {
        return 'systemlead';
      }

      if (ecPay?.enabled) {
        return 'bigOrdr';
      }

      return 'bigOrdr';
    },

    getDefaultCreditCardProvider() {
      const tapPay = this.getProp('creditCard_tapPay', {});
      const gmo = this.getProp('creditCard_gmo', {});

      if (tapPay?.enabled) {
        return 'tapPay';
      }

      if (gmo?.enabled) {
        return 'bigOrdr';
      }

      return 'bigOrdr';
    },

    getDefaultVirtualAccountProvider() {
      const eSun = this.getProp('tw_payments_eSun', {});
      const ubot = this.getProp('tw_payments_ubot', {});
      const bsp = this.getProp('tw_payments_bsp', {});
      const isDefaultProvider = R.pipe(R.propOr([], 'virtualAccounts'), R.find(R.allPass([R.propEq('isDefault', true), R.propEq('enabled', true)])));

      if (isDefaultProvider(eSun)) {
        return 'eSun';
      }

      if (isDefaultProvider(bsp)) {
        return 'bsp';
      }

      if (isDefaultProvider(ubot)) {
        return 'ubot';
      }

      return '';
    }

  });

  _exports.default = _default;
});