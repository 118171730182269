define("client/helpers/substring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params
    /* , hash */
    ) {
      //returns substring using start and end indexes
      //@NOTE: don't confuse with substr.
      const str = params[0] + '';
      const indexStart = params[1];
      const indexEnd = params[2];

      if (str) {
        return str.substring(indexStart, indexEnd);
      }

      return '';
    }

  });

  _exports.default = _default;
});