define("client/pods/components/extensions/apps/tw/momo/credentials/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwMomoCredentialsComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class ExtensionsAppsTwMomoCredentialsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "shopeeAuthorizeWindow", _descriptor2, this);
    }

    get label() {
      if (this.args?.model?._data?.momo?.shopId) {
        return 'momo logout';
      }

      return 'momo login';
    }

    closeMomoAuthorizeWindow(rev, shopId) {
      if (this.shopeeAuthorizeWindow && this.shopeeAuthorizeWindow.close) {
        console.log(rev);
        console.log(shopId);
        return this.shopeeAuthorizeWindow.close();
      }
    }

    *shopeeLoginTask() {
      // let url = yield this.crud.updateRecordTask.perform({
      //   adapterName: 'channels/shop/campaigns',
      //   appendPath: 'momo/authorize',
      //   rawResponse: true,
      //   model: this.args.model
      // })
      let url = yield this.crud.ajaxCallTask.perform('PATCH', {
        adapterName: 'channels/shop/campaigns',
        appendPath: 'shopee/authorize',
        model: this.args.model
      }); // const model = yield this.crud.createRecordTask.perform({
      //   adapterName: 'extensions/apps/tw/shopee/authorizations',
      //   appendPath: 'authorize',
      //   rawResponse: true,
      //   model: this.args?.model
      // })
      //
      // const url = model?.rawResponse

      if (!url) {
        url = yield this.crud.ajaxCallTask.perform('GET', {
          adapterName: 'channels/shop/campaigns',
          appendPath: 'shopee/authorize'
        });
      }

      if (!url) {
        return;
      }

      console.log('SHOPEE::url', url);
      this.shopeeAuthorizeWindow = window.open(url, '_blank');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "shopeeAuthorizeWindow", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "closeMomoAuthorizeWindow", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "closeMomoAuthorizeWindow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "shopeeLoginTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "shopeeLoginTask"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwMomoCredentialsComponent;
});