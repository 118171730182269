define("client/pods/components/extensions/sub/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mE2COtlP",
    "block": "{\"symbols\":[\"subTab\",\"@errors\",\"@tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__header-title\"],[8],[0,\"\\n\\t\\t\"],[5,\"menus/tab-menu\",[],[[\"@errors\",\"@menu\",\"@onSelect\",\"@tabs\"],[[23,2,[]],[23,0,[\"crud\",\"lists\",\"extensions-lists-submenu\"]],[28,\"fn\",[[23,0,[\"tabsManager\",\"triggerMenuAction\"]],[23,0,[]]],null],[23,3,[\"subTabs\"]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__content u-section\"],[8],[0,\"\\n\\t\\t\"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,0,[\"fetchOrdersTask\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n    \\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[23,3,[\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tabParent\",\"tab\",\"onFetch\"],[[23,3,[]],[23,1,[]],[28,\"action\",[[23,0,[]],\"fetch\"],null]]]],false],[0,\"\\n    \\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/sub/tab-container/template.hbs"
    }
  });

  _exports.default = _default;
});