define("client/pods/components/news/search-bar/component", ["exports", "ramda", "client/mixins/products-list-by", "client/mixins/crud", "ember-concurrency"], function (_exports, R, _productsListBy, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _productsListBy.default, {
    crud: Ember.inject.service(),
    tagName: '',
    query: '',
    filters: null,
    autofocus: true,
    adapterName: 'products',
    searchMethod: 'searchInputTask',
    searchOptions: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['products/lists/filters/search', 'products/lists/filters/sort', 'products/lists/filters/status', 'products/lists/types', 'filters/count', 'filters/sortDirection']);
    },

    searchTask: (0, _emberConcurrency.task)(function* (customSearchOptions, defaultSearchOptions) {
      const searchMethod = this.searchMethod;
      const searchOptions = R.mergeRight(defaultSearchOptions, customSearchOptions);

      for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        rest[_key - 2] = arguments[_key];
      }

      return yield this.get(searchMethod).perform(searchOptions, ...rest);
    })
  });

  _exports.default = _default;
});