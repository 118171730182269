define("client/pods/components/msgs/sync-updated/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    icon: 'fas fa-user-circle',
    syncUpdated: Ember.computed('model.synced', function () {
      const synced = this.get('model.synced');

      if (synced) {
        let modified = this.get('model._data.modified') || this.get('model.modified');

        if (!R.is(Array, modified)) {
          modified = this.get('model.modified._data.modified');
        }

        if (R.is(Array, modified)) {
          // only return one value
          return [modified.get('firstObject')];
        }
      }

      return [];
    })
  });

  _exports.default = _default;
});