define("client/pods/components/payments/linepay/search-filters/component", ["exports", "client/mixins/payments-list-by", "client/mixins/crud"], function (_exports, _paymentsListBy, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _paymentsListBy.default, {
    crud: Ember.inject.service(),
    tagName: '',
    adapterName: 'payments/linepay',

    init() {
      this.crud.addLists(this, ['payments/linepay/lists/filters/search', 'payments/linepay/lists/filters/status', 'payments/linepay/lists/filters/sort', 'filters/count', 'filters/sortDirection']);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});