define("client/pods/components/dispatch/search-filters/component", ["exports", "client/mixins/contacts-list-by", "client/mixins/crud"], function (_exports, _contactsListBy, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _contactsListBy.default, {
    crud: Ember.inject.service(),
    tagName: '',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['contacts/lists/filters/search', 'contacts/lists/filters/relationships', 'contacts/lists/filters/status', 'contacts/lists/filters/sort', 'filters/count', 'filters/sortDirection']);
    }

  });

  _exports.default = _default;
});