define("client/pods/components/payments/virtual-accounts/table-view/panels-activity/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    crud: Ember.inject.service(),
    hideExcl: true,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['payments/virtualAccounts/lists/statuses', 'filters/count']);
      const campaignFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/filters',
        defaultFiltersData: {}
      });
      this.set('campaignFilters', campaignFilters);
    },

    actions: {
      toggleView() {
        this.toggleProperty('showDetails');
      },

      loadShopSource(shop) {
        const filters = this.filters;
        filters.setData('sourceKey', shop.getData('_key'));
        this.onSearchTask.perform({
          adapterName: 'payments/virtualAccounts',
          filters
        });
      },

      unloadShopSource() {
        const filters = this.filters;
        filters.setData('sourceKey', '');
        this.onSearchTask.perform({
          adapterName: 'payments/virtualAccounts',
          filters
        });
      },

      selectDispatchProcesses() {
        const filters = this.filters;
        const dispatchProcessesModels = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
        const dispatchProcesses = filters.getData('dispatchProcesses');
        const dispatchProcessesCriterias = R.pipe(R.filter(dispatchProcess => {
          return R.includes(dispatchProcess.getData('code'), dispatchProcesses);
        }), R.map(R.path(['_data', 'criterias'])))(dispatchProcessesModels);
        filters.setData('multipleDispatchProcessesCriterias', dispatchProcessesCriterias);
      }

    }
  });

  _exports.default = _default;
});