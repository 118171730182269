define("client/pods/components/reports/dashboard/card-stat/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N2vW5Nox",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card report__card-stat u-full-width\"],[8],[0,\"\\n  \"],[7,\"header\",true],[10,\"class\",\"card-header\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"card-header-title\"],[8],[0,\"\\n      \"],[1,[28,\"tt\",[[24,[\"title\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card-content has-text-centered\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/reports/dashboard/card-stat/template.hbs"
    }
  });

  _exports.default = _default;
});