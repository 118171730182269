define("client/pods/components/elements/element-label-dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    // attributeBindings: ['for'],
    classNames: ['element-label-dropdown'],
    classNameBindings: ['isDropdownVisible:on:off'],
    translate: true,
    attributeBindings: ['disabled', 'tabindex'],
    tabindex: 0,
    isDropdownVisible: false,
    toggleOnIconClassName: 'fas fa-caret-up',
    toggleOffIconClassName: 'fas fa-caret-down',
    toggleIconClassName: Ember.computed('isDropdownVisible', function () {
      if (this.isDropdownVisible) {
        return this.toggleOnIconClassName;
      }

      return this.toggleOffIconClassName;
    }),

    click(e) {
      this.submit(e);
    },

    keyUp(e) {
      if (e.which === 13) {
        this.submit(e);
      }
    },

    submit() {
      if (this.disabled !== true) {
        // if button has toggler then toggle on/off
        // var isToggler = this.get('isToggler');
        // if (isToggler) {
        //   this.toggleProperty('toggleIsOn');
        // }
        this.toggleProperty('isDropdownVisible');

        if (this.onSubmit) {
          this.onSubmit(this);
        }
      }
    }

  });

  _exports.default = _default;
});