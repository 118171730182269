define("client/pods/documents/data/products/transactions/model", ["exports", "client/pods/documents/details/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    qtyIn: Ember.computed('_data.{qty,inventoryDirection}', function () {
      const inventoryDirection = this.get('_data.inventoryDirection');

      if (inventoryDirection === 'increase') {
        return this.get('_data.qty');
      }

      return '';
    }),
    qtyOut: Ember.computed('_data.{qty,inventoryDirection}', function () {
      const inventoryDirection = this.get('_data.inventoryDirection');

      if (inventoryDirection === 'decrease') {
        return this.get('_data.qty');
      }

      return '';
    })
  });

  _exports.default = _default;
});