define("client/pods/workflows/items/model", ["exports", "client/pods/base/model", "ramda", "client/constants"], function (_exports, _model, R, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        status: 'open'
      };
    },

    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.users = this._transformUsers(data);
      data.related = this._transformRelated(data);
      data.workflowsItemsActivities = this._transformItemsActivities(data);

      this._super(...arguments);
    },

    _transformItemsActivities(data) {
      return R.pipe(R.pathOr([], ['workflowsItemsActivities']), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'workflows/items/activities',
          data
        });
      }))(data);
    },

    isCompleted: Ember.computed('synced', '_data.{isSuccessful,isClosed}', function () {
      const isSuccessful = R.pathOr(false, ['_data', 'isSuccessful'])(this);
      const isClosed = R.pathOr(false, ['_data', 'isClosed'])(this);
      return isSuccessful && isClosed;
    }),
    allowBulkMarkAsCompleted: Ember.computed('synced', '_data.workflowsItemsActivities.@each.synced', function () {
      const workflowsItemsActivities = R.path(['_data', 'workflowsItemsActivities'])(this);

      if (!workflowsItemsActivities) {
        return false;
      }

      const hasAuthorizationType = R.pipe(R.map(R.pathOr([], ['_data', 'type'])), R.any(R.equals(_constants.default.workflowsItemsActivitiesTypes.authorization)))(workflowsItemsActivities);

      if (hasAuthorizationType) {
        return true;
      }

      return true;
    })
  });

  _exports.default = _default;
});