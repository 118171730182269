define("client/pods/components/files/files-fetcher/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5aCf5SB1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\",false],[3,\"did-insert\",[[28,\"perform\",[[24,[\"didInsertTask\"]],[24,[\"resourceKey\"]]],null]]],[3,\"did-update\",[[28,\"perform\",[[24,[\"didInsertTask\"]],[24,[\"resourceKey\"]]],null]]],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"uploadsCollection\"],[[24,[\"uploadsCollection\"]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/files-fetcher/template.hbs"
    }
  });

  _exports.default = _default;
});