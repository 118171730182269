define("client/pods/grapesjs/plugins/slider-native/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.elHasClass = void 0;

  const elHasClass = (el, toFind) => {
    let cls = el.className;
    cls = cls && cls.toString();
    if (cls && cls.split(' ').indexOf(toFind) >= 0) return 1;
  };

  _exports.elHasClass = elHasClass;
});