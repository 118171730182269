define("client/pods/components/documentations/documentation-desc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "to71SQkm",
    "block": "{\"symbols\":[\"&attrs\",\"@documentation\"],\"statements\":[[4,\"if\",[[23,2,[\"_data\",\"description\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"documentation-description\"],[13,1],[8],[0,\"\\n    \"],[1,[23,2,[\"_data\",\"description\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documentations/documentation-desc/template.hbs"
    }
  });

  _exports.default = _default;
});