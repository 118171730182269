define("client/pods/components/contacts/pickup/editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactsPickupEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('constants'), _dec3 = Ember.inject.service('settings'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class ContactsPickupEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "constantsService", _descriptor2, this);

      _initializerDefineProperty(this, "settingsService", _descriptor3, this);

      _initializerDefineProperty(this, "model", _descriptor4, this);

      _initializerDefineProperty(this, "personModel", _descriptor5, this);

      _initializerDefineProperty(this, "detailAddressModel", _descriptor6, this);

      _initializerDefineProperty(this, "detailTelephoneModel", _descriptor7, this);

      _initializerDefineProperty(this, "errors", _descriptor8, this);

      _initializerDefineProperty(this, "personDetails", _descriptor9, this);

      _defineProperty(this, "adapterName", 'contacts');

      this.setupDirty();
    }

    get outerModel() {
      let model = this?.args?.model;
      const addItemOptions = this.args?.addItemOptions || {};
      const isListSelector = addItemOptions.isListSelector === true;

      if (isListSelector) {
        model = this?.args?.parentModel;
      }

      const transporterModel = addItemOptions.transporterModel;

      if (transporterModel) {
        model = transporterModel;
      }

      return model;
    }

    get tCatPersonDetails() {
      return [{
        customLabel: 'preferred delivery time',
        icon: 'fas fa-clock',
        type: 'deliveryTime',
        value: 'anytime'
      }, {
        customLabel: 'transporter package temperature',
        icon: 'fas fa-thermometer-half',
        type: 'transporterAccount',
        value: 'unrefrigerated'
      }, {
        customLabel: 'package size',
        icon: 'fas fa-ruler-combined',
        type: 'packageSize',
        value: '60cm'
      }, {
        customLabel: 'product type',
        icon: 'fas fa-cube',
        type: 'packageType',
        value: 'specialty and local good'
      }];
    }

    get hctPersonDetails() {
      return [{
        customLabel: 'transporter package temperature',
        icon: 'fas fa-thermometer-half',
        type: 'transporterPackageTemperature',
        value: 'unrefrigerated'
      }, {
        customLabel: 'transporter account',
        icon: 'fas fa-user-circle',
        type: 'transporterAccount',
        value: 'unrefrigerated'
      }];
    }

    get isNewPerson() {
      const personId = this.personModel._data.personId;
      const transporterPickupLocations = this.outerModel?._data?.persons || [];
      const personInTransporter = transporterPickupLocations.find(person => person._data.personId === personId);
      return RA.isNilOrEmpty(personInTransporter);
    }

    get isTCat() {
      const model = this.outerModel;
      const transporterExtension = model?._data?.transporterExtension || '';
      return transporterExtension === this.constantsService.transporterExtensions.tCat;
    }

    get isHct() {
      const model = this.outerModel;
      const transporterExtension = model?._data?.transporterExtension || '';
      return transporterExtension === this.constantsService.transporterExtensions.hct;
    }

    getDetailByType(type) {
      return R.pipe(R.pathOr([], ['personModel', '_data', 'details']), R.find(R.pathEq(['_data', 'subType'], type)))(this);
    }

    setupDirty() {
      const model = this.outerModel;
      const isListSelector = this?.args?.addItemOptions?.isListSelector === true;

      if (model._data._key) {
        this.model = this.crud.setupDirty({
          adapterName: this.adapterName,
          model
        });
      } else {
        this.model = model;
      }

      let person = this?.args?.person;
      const isEditing = this.args.isEditing === true;

      if (isListSelector && isEditing && !person) {
        person = this?.args?.model?.model;
      }

      if (person) {
        this.personModel = R.pipe(R.pathOr([], ['model', '_data', 'persons']), R.find(R.pathEq(['_data', 'personId'], person?._data?.personId)))(this);
        this.detailAddressModel = this.getDetailByType('address');
        this.detailTelephoneModel = this.getDetailByType('telephone');
        this.setupPersonDetails(person);
      } else {
        this.personModel = this.model.addPerson({
          type: 'pickupLocation',
          name: this.settingsService.settingsModel._data.company
        });
        this.detailAddressModel = this.personModel.addDetail({
          type: 'pickupLocation',
          subType: 'address',
          isDefault: true,
          value: this.settingsService.settingsModel._data.address
        });
        this.detailTelephoneModel = this.personModel.addDetail({
          type: 'pickupLocation',
          subType: 'telephone',
          isDefault: true,
          value: this.settingsService.settingsModel._data.companyTel
        });
        this.setupPersonDetails(); // this.mock()
      }
    } // NOTE: THIS FN SHOULD ONLY BE CALLED WHEN TRANSPORTER KEY IS T-CAT


    setupPersonDetails(person) {
      var _this = this;

      this.personDetails = {};
      let types;

      if (this.isTCat) {
        types = this.tCatPersonDetails;
      } else if (this.isHct) {
        types = this.hctPersonDetails;
      }

      types.forEach(function () {
        let {
          type = '',
          value = '',
          customLabel = '',
          icon = ''
        } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        if (person) {
          _this.personDetails[type] = _this.getDetailByType(type);
        } else {
          _this.personDetails[type] = _this.personModel.addDetail({
            customLabel,
            icon,
            type: 'pickupLocation',
            subType: type,
            isDefault: true,
            value
          });
        }
      });
      this.personDetails = R.prop('personDetails')(this);
    }
    /**
     * @description this is only for development. Set sender name, address and telephone
     */


    mock() {
      const timestamp = new Date().getTime().toString().substring(10);
      this.personModel.set('_data.name', `Mr Mike_${timestamp}`);
      this.detailAddressModel.set('_data.value', `406台中市北屯區芳庭路${timestamp}號`);
      const telephone = `0${Math.floor(Math.random() * (999999999 - 900000000 + 1) + 900000000)}`;
      this.detailTelephoneModel.set('_data.value', telephone.slice(0, 7) + timestamp);
    }

    updateTask(onCloseDialogAction) {
      var _this2 = this;

      let {
        dirty
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return function* () {
        const fn = args => args;

        const addItemOptions = _this2.args?.addItemOptions || {};
        const refreshModel = addItemOptions.refreshModel || fn;
        const refreshTransporterModel = addItemOptions.refreshTransporterModel || fn;
        const pickupLocations = R.pathOr([], ['model', '_data', 'pickupLocations'])(_this2); // this.personModel.set('_data.details', [this.detailAddressModel, this.detailTelephoneModel])

        pickupLocations.push(_this2.personModel);

        _this2.model.set('_data.pickupLocations', pickupLocations);

        dirty = dirty || _this2.model;

        if (!dirty?._data?._key) {
          const model = _this2.outerModel;
          model.set('_data.defaultPickupLocationPersonId', _this2?.personModel?._data?.personId);
          refreshModel(model);
          return onCloseDialogAction();
        }

        if (_this2.args.updateContactTask) {
          _this2.args.updateContactTask.perform(dirty);
        } else {
          const updatedModel = yield _this2.crud.updateRecordTask.perform({
            adapterName: _this2.adapterName,
            model: dirty,
            component: _this2
          });

          if (!updatedModel) {
            return;
          }

          refreshModel(updatedModel);
          refreshTransporterModel(updatedModel);
        }

        onCloseDialogAction();
      }();
    }

    deleteTask(person, model) {
      var _this3 = this;

      let onCloseDialogAction = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : fn => fn;
      return function* () {
        const pickupLocations = model?._data?.persons || _this3?.model?._data?.persons || [];
        const updatedPickupLocations = pickupLocations.filter(personModel => {
          return personModel._data.personId !== person._data.personId;
        });
        model.set('_data.persons', updatedPickupLocations);
        return _this3.updateTask.perform(onCloseDialogAction, {
          dirty: model
        });
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constantsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settingsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "personModel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "detailAddressModel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "detailTelephoneModel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "personDetails", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupDirty", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupPersonDetails", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setupPersonDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mock", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "mock"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "deleteTask"), _class.prototype)), _class));
  _exports.default = ContactsPickupEditorComponent;
});