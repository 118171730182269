define("client/pods/components/extensions/apps/tw/shopee/settings-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qqV3GLsi",
    "block": "{\"symbols\":[\"@errors\",\"@tab\",\"@isReadonly\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@errors\",\"@tab\"],[\"shopee.login\",\"settings\",[23,1,[]],[23,2,[]]]],{\"statements\":[[0,\"\\n  \"],[5,\"elements/element-btn\",[],[[\"@classNames\",\"@label\",\"@disabled\",\"@isRunning\",\"@onSubmit\"],[\"is-primary\",\"shopee login\",[28,\"if\",[[23,3,[]],false,true],null],[23,0,[\"shopeeLoginTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"shopeeLoginTask\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/shopee/settings-editor/template.hbs"
    }
  });

  _exports.default = _default;
});