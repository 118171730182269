define("client/pods/components/contacts/contacts-editor/add-btn/component", ["exports", "ramda", "ember-concurrency", "client/mixins/crud"], function (_exports, R, _emberConcurrency, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    constants: Ember.inject.service(),
    adapterName: 'contacts',
    tagName: 'span',
    showDetailEditor: false,
    hasAddDepartment: true,
    hasAddPerson: true,
    hasAddDetail: true,
    init: function () {
      this.crud.addLists(this, ['contacts/lists/detailTypes']);

      this._super(...arguments);
    },
    contactListsDetailsTypes: Ember.computed('crud.lists.contacts-lists-detailTypes', function () {
      const list = this.crud.lists['contacts-lists-detailTypes'];
      return list;
    }),
    allowMultiplePickupLocations: Ember.computed('model._data.transporterExtension', 'editPickupLocations', 'hasPickupLocations', function () {
      if (this.hasPickupLocations || this.editPickupLocations) {
        // Is transporter allowed to have multiple pickup locations
        const allowTransporter = R.pipe(R.pathOr('', ['model', '_data', 'transporterExtension']), R.anyPass([R.equals(this.constants.transporterExtensions.tCat), R.equals(this.constants.transporterExtensions.hct)]))(this);

        if (allowTransporter) {
          return true;
        }
      }

      return false;
    }),
    updateContactTask: (0, _emberConcurrency.task)(function* (dirty) {
      const adapterName = this.adapterName;
      dirty = this.dirty;

      try {
        const saved = yield this.dataManager.updateRecord({
          adapterName,
          dirty
        });
        this.set('showDetailEditor', false);
        return saved;
      } catch (err) {
        // contacts handler uses populateArrayItems to validate details. must normalize the errors
        // to be the same as other newer handlers
        const errors = err.data || {};
        const detailErrors = R.pipe(R.pathOr([], ['details', 'errors']), R.concat(R.pathOr([], ['persons', 'details', 'errors'], errors)), R.concat(R.pathOr([], ['departments', 'details', 'errors'], errors)), R.concat(R.pathOr([], ['departments', 'persons', 'details', 'errors'], errors)), R.concat(R.pathOr([], ['persons', 'errors'], errors)), R.concat(R.pathOr([], ['departments', 'persons', 'errors'], errors)), R.concat(R.pathOr([], ['departments', 'errors'], errors)))(errors);
        this.set('errors', detailErrors);
      }
    }),

    getDirtyParentModel(parent, dirty) {
      let parentModel;

      if (parent) {
        const personId = parent.getData('personId');

        if (personId) {
          parentModel = this.findByPersonId(personId, dirty);
        }

        const departmentId = parent.getData('departmentId');

        if (departmentId) {
          parentModel = this.findByDepartmentId(departmentId, dirty);
        }
      }

      if (!parentModel) {
        return dirty;
      }

      return parentModel;
    },

    _findBy(idParam, id, arrayParam, parentArray) {
      let found;
      parentArray.forEach(child => {
        const childArray = child.getData(arrayParam);
        const childFound = childArray.findBy(idParam, id);

        if (childFound) {
          found = childFound;
        }
      });
      return found;
    },

    findByDetailId(detailId, model) {
      const modelDetails = model.getData('details') || [];
      let found = modelDetails.findBy('_data.detailId', detailId);

      if (!found) {
        const persons = model.getData('persons') || [];
        found = this._findBy('_data.detailId', detailId, 'details', persons);
      }

      if (!found) {
        const departments = model.getData('departments') || [];
        found = this._findBy('_data.detailId', detailId, 'details', departments);
        departments.forEach(department => {
          const personsInDepartment = department.getData('persons');

          const foundInDeptPerson = this._findBy('_data.detailId', detailId, 'details', personsInDepartment);

          if (foundInDeptPerson) {
            found = foundInDeptPerson;
          }
        });
      }

      return found;
    },

    findByPersonId(personId, model) {
      const persons = model.getData('persons') || [];
      let found = persons.findBy('_data.personId', personId);

      if (!found) {
        const departments = model.getData('departments') || [];
        departments.forEach(department => {
          if (!found) {
            const deptPersons = department.getData('persons') || [];
            const foundPerson = deptPersons.findBy('_data.personId', personId);

            if (foundPerson) {
              found = foundPerson;
            }
          }
        });
      }

      return found;
    },

    findByDepartmentId(departmentId, model) {
      const departments = model.getData('departments') || [];
      return departments.findBy('_data.departmentId', departmentId);
    },

    clearAllDirty() {
      const dirty = this.dirty;

      if (dirty) {
        const adapterName = this.adapterName;
        this.dataManager.cancelDirty(adapterName, dirty);
      }

      this.set('dirty', '');
      this.set('newDetail', '');
      this.set('newPerson', '');
      this.set('newDepartment', '');
      this.set('errors', []);
    },

    /**
     * SOMEHOW, DETAIL IS MISSING IN SOME SCENARIOS
     * @param parent
     * @param detail
     * @param detailData
     * @private
     */
    _editDetail(_ref) {
      let {
        parent,
        detail,
        detailData
      } = _ref;
      this.clearAllDirty(); // how does person.detail get added?

      const adapterName = this.adapterName;
      const model = this.model;
      const dirty = this.setupDirty({
        adapterName,
        model
      });
      this.set('dirty', dirty);
      const dirtyParent = this.getDirtyParentModel(parent, dirty);

      if (detailData) {
        detail = dirtyParent.addDetail(detailData);
        this.set('newDetail', detail);
      } else if (detail) {
        const detailId = detail.getData('detailId');
        const dirtyDetail = this.findByDetailId(detailId, dirtyParent);
        this.set('newDetail', dirtyDetail);
      }

      if (dirtyParent) {
        this.set('dirtyParent', dirtyParent);
        this.set('showDetailEditor', true);
      }
    },

    _editPerson(_ref2) {
      let {
        parent,
        person
      } = _ref2;
      this.clearAllDirty();
      const adapterName = this.adapterName;
      const model = this.model;
      const dirty = this.setupDirty({
        adapterName,
        model
      });
      this.set('dirty', dirty);
      const dirtyParent = this.getDirtyParentModel(parent, dirty);

      if (!person) {
        person = dirtyParent.addPerson();
        this.set('newPerson', person);
      } else {
        // when editing parent == person (set in display-person template)
        const personId = person.getData('personId');
        const dirtyPerson = this.findByPersonId(personId, dirtyParent);
        this.set('newPerson', dirtyPerson);
      }

      this.set('dirtyParent', dirtyParent);
      this.set('showDetailEditor', true);
    },

    _editDepartment(_ref3) {
      let {
        parent,
        department
      } = _ref3;
      this.clearAllDirty(); // how does person.detail get added?

      const adapterName = this.adapterName;
      const model = this.model;
      const dirty = this.setupDirty({
        adapterName,
        model
      });
      this.set('dirty', dirty);
      const dirtyParent = this.getDirtyParentModel(parent, dirty);

      if (!department) {
        department = dirtyParent.addDepartment();
        this.set('newDepartment', department);
      } else {
        const departmentId = department.getData('departmentId');
        const dirtyDepartment = this.findByDepartmentId(departmentId, dirtyParent);
        this.set('newDepartment', dirtyDepartment);
      }

      this.set('dirtyParent', dirtyParent);
      this.set('showDetailEditor', true);
    },

    actions: {
      newDetail(parent, detailType) {
        const detailData = {
          type: detailType.getData('value'),
          subType: detailType.getData('defaultSubType')
        };

        this._editDetail({
          parent,
          detailData
        });
      },

      newPerson(parent) {
        this._editPerson({
          parent
        });
      },

      newDepartment(parent) {
        this.clearAllDirty(); // how does person.detail get added?

        const adapterName = this.adapterName;
        const model = this.model;
        const dirty = this.setupDirty({
          adapterName,
          model
        });
        this.set('dirty', dirty);
        const dirtyParent = this.getDirtyParentModel(parent, dirty);
        const department = dirtyParent.addDepartment();
        this.set('dirtyParent', dirtyParent);
        this.set('newDepartment', department);
        this.set('showDetailEditor', true);
      },

      cancel() {
        this.clearAllDirty();
        this.set('showDetailEditor', false);
      },

      editDetail(parent, detail) {
        this._editDetail({
          parent,
          detail
        });
      },

      editPerson(parent, person) {
        this._editPerson({
          parent,
          person
        });
      },

      editDepartment(parent, department) {
        this._editDepartment({
          parent,
          department
        });
      },

      removeDetail(dirtyParent, detail) {
        const msg = this.intl.t('are you sure you want to delete');

        if (window.confirm(msg)) {
          dirtyParent.removeDetail(detail);
          this.updateContactTask.perform(detail);
        }
      },

      removePerson(dirtyParent, person) {
        const msg = this.intl.t('are you sure you want to delete');

        if (window.confirm(msg)) {
          dirtyParent.removePerson(person);
          this.updateContactTask.perform(person);
        }
      },

      removeDepartment(dirtyParent, department) {
        const msg = this.intl.t('are you sure you want to delete');

        if (window.confirm(msg)) {
          dirtyParent.removeDepartment(department);
          this.updateContactTask.perform(department);
        }
      },

      toggleMarkAsDefault(detail) {
        if (detail.getData('isDefault')) {
          detail.setData('isDefault', true);
          detail.setData('markAsDefault', true);
        } else {
          detail.setData('isDefault', false);
          detail.setData('markAsDefault', false);
        }
      },

      toggleMarkAsDefaultForDocs(detail) {
        if (detail.getData('isDefault')) {
          detail.setData('isDefault', true);
          detail.setData('isDefaultFor', 'all');
          detail.setData('isDefaultForDocs', []);
          detail.setData('markAsDefault', true);
        } else {
          detail.setData('isDefault', false);
          detail.setData('isDefaultFor', '');
          detail.setData('isDefaultForDocs', []);
          detail.setData('markAsDefault', false);
        }
      }

    }
  });

  _exports.default = _default;
});