define("client/pods/components/channels/shop/shop-commissions-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iFeLsM5b",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[4,\"if\",[[23,1,[\"_data\",\"commissionsLatest\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"tag\"],[8],[0,\"\\n    \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[\"icon fa-solid fa-sack-dollar\"]]],false],[0,\"\\n     \\n    \"],[1,[28,\"tt\",[\"commissions calculated\"],null],false],[0,\": \"],[1,[28,\"date\",[[23,1,[\"_data\",\"commissionsLatest\",\"dateZ\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shop-commissions-status/template.hbs"
    }
  });

  _exports.default = _default;
});