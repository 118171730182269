define("client/pods/components/admin/payments/card/panels-payments/payout-btn/component", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    actions: {
      setPayoutDetails() {
        this.set('payoutAmount', this.outstandingTotal);
        this.set('payoutDateZ', this.getStartOfDayZ());
      }

    }
  });

  _exports.default = _default;
});