define("client/pods/components/channels/shop/items/item-price/item-price-old/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    oldPrice: Ember.computed('price', 'item.oldPrice', 'item.priceDiscounted', function () {
      if (this.price) {
        return this.price;
      }

      return this.get('item.oldPrice') || this.get('item.priceDiscounted');
    })
  });

  _exports.default = _default;
});