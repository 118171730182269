define("client/pods/components/channels/shop/rewards/search-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Suk4U3g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-results__text\"],[8],[0,\"\\n  \"],[1,[28,\"highlight\",[[23,0,[\"result\",\"_data\",\"name\"]],[23,0,[\"query\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"result\",\"_data\",\"code\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n    \"],[1,[28,\"highlight\",[[23,0,[\"result\",\"_data\",\"code\"]],[23,0,[\"query\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n  \"],[5,\"lists/list-tags\",[],[[\"@list\",\"@value\"],[[23,0,[\"crud\",\"lists\",\"statuses\"]],[23,0,[\"result\",\"_data\",\"statuses\"]]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/rewards/search-results/template.hbs"
    }
  });

  _exports.default = _default;
});