define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/recurring-schedules-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yTJeNl7Z",
    "block": "{\"symbols\":[\"@model\",\"@isReadonly\",\"@isAutomations\"],\"statements\":[[4,\"if\",[[23,1,[\"_data\",\"itemKey\"]]],null,{\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"pod/get\",[\"beta.allowRecurringSchedules\"],null],[28,\"pod/get\",[\"features.allowRecurringSchedules\"],null]],null]],null,{\"statements\":[[4,\"unless\",[[23,3,[]]],null,{\"statements\":[[4,\"forms/form-section\",null,[[\"title\"],[\"recurring schedules\"]],{\"statements\":[[0,\"        \"],[5,\"recurring/schedules-table\",[],[[\"@model\",\"@isReadonly\"],[[23,1,[]],[28,\"or\",[[28,\"contains\",[\"recurringSchedules\",[23,1,[\"_data\",\"productSync\"]]],null],[23,2,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-item-editor/recurring-schedules-editor/template.hbs"
    }
  });

  _exports.default = _default;
});