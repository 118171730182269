define("client/pods/components/line/line-login/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      login() {
        this.open('https://access.line.me/oauth2/v2.1/authorize');
      }

    }
  });

  _exports.default = _default;
});