define("client/pods/components/admin/maintenance/tab-container/component", ["exports", "ember-concurrency", "ramda"], function (_exports, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    admin: Ember.inject.service(),
    createIndexesTask: (0, _emberConcurrency.task)(function* () {
      yield this.server.call('POST', 'api/protected/admin/maintenance/indexes', {});
    }).drop(),
    createAllIndexesTask: (0, _emberConcurrency.task)(function* () {
      yield this.server.call('POST', 'api/protected/admin/maintenance/indexes/all', {});
    }).drop(),
    createInventoryCachesTask: (0, _emberConcurrency.task)(function* () {
      yield this.server.call('POST', 'api/protected/admin/maintenance/createInventoryCaches', {});
    }).drop(),
    createDetailsSimplifiedTask: (0, _emberConcurrency.task)(function* () {
      yield this.server.call('POST', 'api/protected/admin/maintenance/createDetailsSimplified', {});
    }).drop(),
    getContactsMemberLevelTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.server.call('GET', 'api/protected/admin/get-member-levels', {});
      this.set('results', R.propOr([], 'data')(results));
    }).drop(),
    updateContactsMemberLevelTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        podKey = '',
        contactKey = ''
      } = _ref;
      return function* () {
        yield _this.server.call('PUT', 'api/protected/admin/update-member-levels', {
          podKey,
          contactKey
        });
        yield _this.getContactsMemberLevelTask.perform();
      }();
    }).drop(),
    getAutomationsTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.server.call('GET', 'api/protected/admin/get-automations', {});
      this.set('automationsResults', R.propOr([], 'data')(results));
    }).drop(),
    updateAutomationsTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        podKey = '',
        campaignKey = '',
        automationKey = '',
        itemKey = ''
      } = _ref2;
      return function* () {
        yield _this2.server.call('PUT', 'api/protected/admin/update-automations', {
          podKey,
          campaignKey,
          automationKey,
          itemKey
        });
        yield _this2.getAutomationsTask.perform();
      }();
    }).drop(),
    updateMissingEarnRewardDocTask: (0, _emberConcurrency.task)(function* () {
      yield this.server.call('PATCH', 'api/protected/admin/fix-missing-earn-reward', {});
    }).drop()
  });

  _exports.default = _default;
});