define("client/pods/components/flows/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eQ/jIjPD",
    "block": "{\"symbols\":[\"@onPaginationTask\",\"@results\",\"@filters\",\"@tab\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onDisplay\",\"@onEdit\"],\"statements\":[[5,\"layouts/table-view\",[],[[\"@tab\",\"@tableClassNames\",\"@detailsRowComponent\",\"@results\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onDisplay\",\"@onEdit\",\"@onMainAction\",\"@tableViewOptions\"],[[23,4,[]],\"products-dash\",\"flows/table-view/detail-row\",[23,3,[\"results\"]],[23,3,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,7,[]],[28,\"hash\",null,[[\"allowUploads\"],[true]]]]]],[0,\"\\n\\n\"],[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[4,\"layouts/footer-btns\",null,null,{\"statements\":[[4,\"layouts/btns-group\",null,[[\"classNames\"],[\"is-left\"]],{\"statements\":[[0,\"      \"],[1,[28,\"helpers/pagination-controls\",null,[[\"filters\",\"list\",\"showPageNumbers\",\"onPaginationTask\"],[[23,3,[]],[23,2,[]],true,[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/flows/panels-dash/template.hbs"
    }
  });

  _exports.default = _default;
});