define("client/pods/components/elements/element-dropdown-btn/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    tagName: 'span',
    classNames: ['list-btn'],
    hasDropdown: true,
    showDropdown: false,
    onSubmit: 'defaultToggleDropdown',
    // can supply custom function to search on onSubmit action
    readonly: false,
    translate: false,
    value: '',
    // list: [],
    resultsComponent: 'lists/list-results',
    // component to display results (defaults to list-results)

    /**
     * determines if dropdown should be shown.
     * only show if isDropdownVisible and has list
     */
    isDropdownVisible: Ember.computed('showDropdown', function () {
      if (this.readonly === false && this.showDropdown) {
        return true;
      }

      return false;
    }),
    actions: {
      defaultToggleDropdown() {// @TODO: should remove this
        // this.set('showDropdown', true);
      },

      hideDropdown() {
        this.set('showDropdown', false);
      },

      showDropdown() {
        this.set('showDropdown', true);
      }

    },

    onToggleModalDialog() {
      this.set('showDropdown', false);
    }

  }, (_applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj)), _obj)));

  _exports.default = _default;
});