define("client/pods/comments/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate(data, attrs) {
      data.workflowsItemsActivities = this._transformWorkflowsItemsActivities(data);

      this._super(data, attrs);
    },

    _transformWorkflowsItemsActivities(data) {
      return R.pipe(R.pathOr([], ['workflowsItemsActivities']), workflowsItemsActivities => {
        // ensure array
        if (!R.is(Array, workflowsItemsActivities)) {
          return [];
        }

        return workflowsItemsActivities;
      }, R.map(workflowsItemsActivity => this.dataManager.setAsRecord({
        adapterName: 'workflows/items/activities',
        data: workflowsItemsActivity
      })))(data);
    }

  });

  _exports.default = _default;
});