define("client/pods/components/notifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fN0b+Vxp",
    "block": "{\"symbols\":[\"alert\",\"alert\",\"@notifications\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[\"unread\"]]],null,{\"statements\":[[0,\"    \"],[5,\"notifications/alert\",[],[[\"@alert\"],[[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"each\",[[23,3,[\"read\"]]],null,{\"statements\":[[0,\"    \"],[5,\"notifications/alert\",[],[[\"@alert\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/notifications/template.hbs"
    }
  });

  _exports.default = _default;
});