define("client/mixins/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    validationError: Ember.computed('errorIndex', 'errors.[]', function () {
      const errors = this.errors || [];

      if (errors.length > 0) {
        const errorIndex = this.errorIndex;
        return errors.filterBy('index', errorIndex);
      }

      return [];
    })
  });

  _exports.default = _default;
});