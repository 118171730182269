define("client/pods/components/color-themes/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ColorThemesComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ColorThemesComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "colorsResults", _descriptor3, this);

      if (!this.args.displaySelected) {
        this.colorsFilters = this.search.setupFilters({
          adapterName: 'websites/filters',
          defaultFiltersData: {
            resultsProperty: 'colorsResults',
            match: ['all']
          }
        });
        this.searchTask.perform();
      }
    }

    *searchTask() {
      const q = [];
      const query = this.query;
      q.push(this.searchColorTemplatesTask.perform({
        query,
        filters: this.colorsFilters
      }));
      yield (0, _emberConcurrency.all)(q);

      if (this.args.autoSelect) {
        this.selectColorTheme(this.colorsResults[0]);
      }
    }

    searchColorTemplatesTask(_ref) {
      var _this = this;

      let {
        adapterName = 'websites',
        query,
        filters
      } = _ref;
      return function* () {
        const resultsProperty = R.pathOr('', ['_data', 'resultsProperty'])(filters);
        _this[resultsProperty] = yield _this.crud.searchRecordsConcurrentlyTask.unlinked().perform({
          adapterName,
          appendPath: 'sharedColorThemes',
          filters,
          query
        });
        return _this[resultsProperty];
      }();
    }

    displayColorTemplateModal() {
      this.args.toggleColorTemplates();
    }

    selectColorTheme(selectedTheme) {
      if (this.args.isReadonly) {
        return;
      }

      if (this.args.displaySelected) {
        return this.displayColorTemplateModal(...arguments);
      }

      const themeName = R.pathOr('', ['_data', 'name'])(selectedTheme);
      R.pipe(R.pathOr({}, ['_data']), R.toPairs, R.filter(R.pipe(R.head, R.test(/^theme/))), R.fromPairs, R.mapObjIndexed((value, key) => {
        this.args.model.setData(key, value);
      }))(selectedTheme);
      this.args.model.setData('themeName', themeName);
      const onSelect = this.args.onSelect;

      if (onSelect) {
        onSelect(selectedTheme);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "colorsResults", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchColorTemplatesTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchColorTemplatesTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayColorTemplateModal", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "displayColorTemplateModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectColorTheme", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectColorTheme"), _class.prototype)), _class));
  _exports.default = ColorThemesComponent;
});