define("client/pods/automations/then-actions/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor", "client/constants/index"], function (_exports, _adapter, _adaptersNew, _model, _nventor, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        label: 'automation_action_mustAdd',
        value: _index.default.automationsThenActions.mustAdd
      }, {
        label: 'automation_action_mayAdd',
        value: _index.default.automationsThenActions.mayAdd
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});