define("client/pods/components/forms/form-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+IY3lB3u",
    "block": "{\"symbols\":[\"@descriptionClassNames\",\"@translateDescription\",\"@description\",\"@value\",\"&default\",\"@context\",\"@documentationId\",\"@documentationResource\",\"@isRequired\",\"@label\",\"@tab\",\"@rowClassNames\",\"@errors\"],\"statements\":[[4,\"if\",[[23,0,[\"showTemplate\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"form-row \",[23,12,[]],\" \",[23,0,[\"hasErrorClassNames\"]]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"customBlock\"]]],null,{\"statements\":[[0,\"\\n\\t\\t\\t\"],[14,5],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,10,[]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[5,\"elements/element-label\",[],[[\"@context\",\"@documentationId\",\"@documentationResource\",\"@isRequired\",\"@label\",\"@translate\",\"@tab\"],[[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,0,[\"translate\"]],[23,11,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,5]],null,{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[14,5],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[23,4,[]],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[11,\"class\",[29,[\"has-text-small \",[23,1,[]]]]],[8],[1,[28,\"tt\",[[23,3,[]]],[[\"translate\"],[[23,2,[]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"validationError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"validation-error\"],[8],[0,\"\\n        \"],[1,[23,0,[\"validationError\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[5,\"msgs/error-msg\",[],[[\"@context\",\"@errors\",\"@label\",\"@tab\"],[[23,6,[]],[23,13,[]],[23,10,[]],[23,11,[]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/forms/form-row/template.hbs"
    }
  });

  _exports.default = _default;
});