define("client/pods/components/documents/search-results-documents/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iLqHBBkz",
    "block": "{\"symbols\":[\"result\"],\"statements\":[[4,\"lists/list-results\",null,[[\"query\",\"list\",\"onSelect\"],[[24,[\"query\"]],[24,[\"list\"]],[24,[\"onSelect\"]]]],{\"statements\":[[0,\"\\n\\t\"],[1,[28,\"documents/search-results\",null,[[\"query\",\"result\",\"dispatchProcesses\"],[[24,[\"query\"]],[23,1,[]],[23,0,[\"crud\",\"lists\",\"dispatch-lists-processes\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/search-results-documents/template.hbs"
    }
  });

  _exports.default = _default;
});