define("client/pods/flows/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        name: '',
        greeting: '',
        shopOrWebsite: '',
        eventTypes: [],
        eventDisplays: [],
        statuses: [],
        sort: [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]
      };
    },

    eventTypesCount: Ember.computed('_data.eventTypes.[]', function () {
      return this.getPropLength('_data.eventTypes');
    }),
    eventDisplaysCount: Ember.computed('_data.eventDisplays.[]', function () {
      return this.getPropLength('_data.eventDisplays');
    })
  });

  _exports.default = _default;
});