define("client/pods/components/documents/links-modal/links-modal-details/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentsLinksModalLinksModalDetails = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class DocumentsLinksModalLinksModalDetails extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "big", _descriptor, this);

      _defineProperty(this, "showSearchBar", true);

      _defineProperty(this, "tagName", '');
    }

    get hasMultipleSelected() {
      const selected = this.args.linksListProxy.filterBy('isSelected', true) || [];

      if (selected.length > 0) {
        return selected;
      }

      return false;
    }

    toggleAddLinkItem(linkFromDetail) {
      const model = this?.args?.model || {};

      if (!model) {
        return false;
      }

      if (this.isNotSelectable) {
        return false;
      }

      if (model?._data?.docType === 'sales' && model?._data?.docName === 'returns') {
        // dont allow doc sales returns to be linked to mixed documents
        const firstSelectedLinkFromDocKey = model?.firstSelectedLinkFromDocKey;

        if (firstSelectedLinkFromDocKey) {
          if (firstSelectedLinkFromDocKey !== linkFromDetail?._data?.doc?._key) {
            return false;
          }
        }
      }

      linkFromDetail.toggleProperty('isSelected');
      const linkQtyRemainder = this.linkQtyRemainder;
      linkFromDetail.setData('linkQtyRemainder', linkQtyRemainder);

      if (model?._data?.docType === 'sales' && model?._data?.docName === 'returns') {
        const selected = model?.selectedLinkFromDocKeys || [];

        if (linkFromDetail?.isSelected) {
          selected.pushObject(linkFromDetail?._data?.doc?._key);
        } else {
          selected.removeObject(linkFromDetail?._data?.doc?._key);
        }

        model.set('selectedLinkFromDocKeys', selected);
      }
    }

    get linkQtyRemainder() {
      const qtyForLinkedList = this?.args?.qtyForLinkedList || {};
      const linkFromDetail = this?.args?.linkFromDetail || {};

      if (RA.isNotNilOrEmpty(qtyForLinkedList)) {
        const fromDocName = linkFromDetail.getData('docName') || '';
        let pendingId = '';

        if (RA.isNotNilOrEmpty(fromDocName)) {
          pendingId = linkFromDetail.getData(`${fromDocName}PendingId`);

          if (R.equals(fromDocName, 'invoices')) {
            pendingId = linkFromDetail.getData('detailKey');
          }
        }

        const qtyForLinkedListKeys = R.keys(qtyForLinkedList);

        if (RA.isNotNilOrEmpty(pendingId) && R.includes(pendingId, qtyForLinkedListKeys)) {
          const linkQty = linkFromDetail?._data?.linkQty || '0';
          const qtyAlreadyLinked = this.big.newBig(qtyForLinkedList[pendingId]);
          const qtyRemainder = this.big.newBig(linkQty).minus(qtyAlreadyLinked);
          return qtyRemainder.toFixed(0);
        }

        return linkFromDetail?._data?.linkQty || '0';
      }

      return linkFromDetail?._data?.linkQty || '0';
    }

    get isNotSelectable() {
      const model = this?.args?.model || {};
      const paymentMethodKey = model?._data?.paymentMethodKey || '';
      const linkFromDetail = this?.args?.linkFromDetail || {};
      const isSelected = R.propOr(false, 'isSelected')(linkFromDetail); // for calling the get function

      const linkQtyRemainder = this.linkQtyRemainder || '';
      const linkDocPaymentMethodKey = linkFromDetail?._data?.doc?.paymentMethodKey || '';

      if (RA.isNotNilOrEmpty(linkQtyRemainder) && R.lte(linkQtyRemainder, 0)) {
        return true;
      }

      if (R.equals(paymentMethodKey, 'consignment')) {
        if (!R.equals(linkDocPaymentMethodKey, 'consignment')) {
          return true;
        }

        return false;
      }

      const selected = this.args.linksListProxy.filterBy('isSelected', true) || [];
      const selectedPendingIdType = R.pipe(R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.pluck('docName'), R.reject(RA.isNilOrEmpty), R.uniq)(selected);

      if (RA.isNotNilOrEmpty(selectedPendingIdType)) {
        const selectedPendingIdTypeArray = RA.ensureArray(selectedPendingIdType);
        const pendingIdType = R.head(selectedPendingIdTypeArray) || '';
        const linkDocDocName = linkFromDetail?._data?.doc?.docName || '';

        if (R.equals('consignments', pendingIdType) && R.equals('orders', linkDocDocName) || R.equals('orders', pendingIdType) && R.equals('consignments', linkDocDocName)) {
          return true;
        }
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "big", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleAddLinkItem", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAddLinkItem"), _class.prototype)), _class));
  _exports.default = DocumentsLinksModalLinksModalDetails;
});