define("client/pods/components/export/worksheet/editor/customworksheet-nested/customworksheet-item/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _component, _ramda, RA, _ramdaExtension) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExportWorksheetEditorCustomspecItem = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class ExportWorksheetEditorCustomspecItem extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dragAndDrop", _descriptor, this);

      _defineProperty(this, "tagName", 'span');

      _defineProperty(this, "classNames", []);

      _defineProperty(this, "translate", true);

      _defineProperty(this, "item", null);

      _defineProperty(this, "isViewable", true);

      _defineProperty(this, "labelKey", 'label');

      _defineProperty(this, "isDraggable", true);

      _defineProperty(this, "hasDropBelowContainer", true);

      _defineProperty(this, "hasDropAboveContainer", true);

      _defineProperty(this, "draggable", true);
    }

    get availableCustomWorksheetAndOwnWorksheets() {
      const originalList = this?.args?.specsCollection?._data?.custom || [];
      const availableCustomWorksheets = this?.args?.availableCustomWorksheets || [];

      if (RA.isNilOrEmpty(availableCustomWorksheets)) {
        return [];
      }

      const customSheet = this?.args?.customSheet;

      const customSpec = _ramda.default.find(_ramdaExtension.default.dotPathEq('_data._key', customSheet?._data?.value))(originalList);

      return _ramda.default.insert(1, customSpec, availableCustomWorksheets);
    }

    get _hasDropAboveContainer() {
      if (this.hasDropAboveContainer && this.index === 0) {
        return true;
      }

      return false;
    }

    drop() {
      if (this.onDrop) {
        this.onDrop(...arguments);
      }
    }

    select(disabled, item) {
      if (!disabled) {
        this.onSelect(item);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dragAndDrop", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "drop", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.default = ExportWorksheetEditorCustomspecItem;
});