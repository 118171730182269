define("client/pods/components/workflows/create-dynamic/workflow-editor-wrapper/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowsCreateDynamicWorkflowEditorWrapperComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('workflows'), _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class WorkflowsCreateDynamicWorkflowEditorWrapperComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "workflowsService", _descriptor2, this);

      _initializerDefineProperty(this, "dataManager", _descriptor3, this);

      _initializerDefineProperty(this, "model", _descriptor4, this);

      this.crud.addLists(this, ['workflows/related/types']);
      this.setModel();
      this.setDefaults();
    }

    setModel() {
      if (this?.args?.isEditing) {
        this.model = this.crud.setupDirty({
          adapterName: 'workflows',
          model: this?.args?.model
        });
      } else {
        this.model = this.workflowsService.newWorkflow({
          type: 'workflows'
        });
      }
    }

    setDefaults() {
      const options = R.pathOr({}, ['args', 'addItemOptions'])(this);
      const type = R.prop('type')(options);
      const name = R.path(['metaData', 'name'])(options);
      const master = R.prop('master')(options);

      if (type === 'documents') {
        return;
      } // "workflows/related"


      const related = this.dataManager.setAsRecord({
        adapterName: 'workflows/related',
        data: {
          resource: type,
          resourceValue: name,
          resourceKey: master
        }
      });
      this?.model?.setData('name', 'TODO');
      this?.model?._data?.related?.pushObject(related);
      this?.model?._data?.stages[0]?.setData('name', 'TODO');
    }

    onClose() {
      const onCloseDialogAction = R.path(['args', 'onCloseDialogAction'])(this);
      const toggleShowEditor = R.path(['args', 'toggleShowEditor'])(this);

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }

      if (toggleShowEditor) {
        toggleShowEditor();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "workflowsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype)), _class));
  _exports.default = WorkflowsCreateDynamicWorkflowEditorWrapperComponent;
});