define("client/pods/components/documents/pos/view/checkout-details/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentsPosViewCheckoutDetailsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class DocumentsPosViewCheckoutDetailsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "constants", _descriptor2, this);

      this.crud.addLists(this, ['paymentMethods', 'extensions/apps/tw/e-invoices/carrier-types']);
    }

    get posPaymentMethods() {
      const shopPaymentMethods = R.pathOr([this.constants.paymentMethods.cash], ['args', 'posOptions', 'shop', '_data', 'paymentMethods'])(this);
      const shopPaymentMethodsFilters = R.map(paymentMethod => R.pathEq(['_data', 'value'], paymentMethod))(shopPaymentMethods); // @NOTE: ALLOW FOR NOW

      const posMandatoryPaymentMethodsFilters = [R.pathEq(['_data', 'value'], this.constants.paymentMethods.cash), R.pathEq(['_data', 'value'], this.constants.paymentMethods.other)];
      return R.pipe(R.pathOr([], ['crud', 'lists', 'paymentMethods']), R.filter(R.anyPass(posMandatoryPaymentMethodsFilters)))(this);
    }

    afterLoadLists() {
      const cashPaymentMethod = R.pipe(R.pathOr([], ['crud', 'lists', 'paymentMethods']), R.find(R.pathEq(['_data', 'value'], this.constants.paymentMethods.cash)))(this);
      this.loadPaymentMethod(cashPaymentMethod);
    }

    loadPaymentMethod(paymentMethod) {
      this.args.model.loadPaymentMethod({
        paymentMethod
      });
    }

    submitPayment() {
      if (this.args.onPreSubmit) {
        this.args.onPreSubmit(...arguments);
      }

      if (this.args.onSavePreSaveModalTask) {
        this.args.onSavePreSaveModalTask(...arguments);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterLoadLists", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "afterLoadLists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadPaymentMethod", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "loadPaymentMethod"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitPayment", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "submitPayment"), _class.prototype)), _class));
  _exports.default = DocumentsPosViewCheckoutDetailsComponent;
});