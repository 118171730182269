define("client/pods/channels/shop/shipping-zones/lists/sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let appendPath = arguments.length > 1 ? arguments[1] : undefined;
      let options = arguments.length > 2 ? arguments[2] : undefined;
      const list = [{
        value: 'details',
        label: 'details',
        component: 'channels/shop/shipping-zones/panels-display/sub-panels-details',
        tabType: 'reuseSubTab',
        resource: 'api/protected/channels/shop/shipping-zones',
        editingComponent: 'channels/shop/shipping-zones/shipping-zones-editor/details-editor'
      }];
      const customData = {
        list
      };
      const path = this.getFullPath('findAllPath', appendPath);
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});