define("client/pods/documents/mixins/calculations", ["exports", "client/pods/documents/mixins/calculations-exclusive-or-inclusive"], function (_exports, _calculationsExclusiveOrInclusive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import TaxCalculationsMethodMixin from './calculations-always-inclusive'
  var _default = Ember.Mixin.create(_calculationsExclusiveOrInclusive.default);

  _exports.default = _default;
});