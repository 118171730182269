define("client/pods/components/helpers/header-sort/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OgQbeZqg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"direction\"]],\"ASC\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[\"fas fa-caret-up\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"direction\"]],\"DESC\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[\"fas fa-caret-down\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/header-sort/template.hbs"
    }
  });

  _exports.default = _default;
});