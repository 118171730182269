define("client/pods/components/countdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gwEUxbeI",
    "block": "{\"symbols\":[\"isTooltipVisible\"],\"statements\":[[4,\"if\",[[23,0,[\"countdownTime\"]]],null,{\"statements\":[[0,\"  \"],[5,\"helpers/info-tooltip\",[],[[\"@customBlock\"],[true]],{\"statements\":[[0,\"\\n\\n    \"],[7,\"span\",true],[11,\"class\",[29,[[23,0,[\"classNames\"]]]]],[8],[0,\"\\n      \"],[1,[23,0,[\"countdownTime\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[5,\"helpers/info-tooltip-content\",[],[[\"@isViewable\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[5,\"elements/element-label\",[],[[\"@classNames\",\"@label\"],[\"u-link--text\",\"shopee_logistics_one_hour_limit_message\"]]],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/countdown/template.hbs"
    }
  });

  _exports.default = _default;
});