define("client/helpers/ramda", ["exports", "client/mixins/big", "ramda"], function (_exports, _big, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_big.default, {
    compute(params) {
      let hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const fnName = params[0];
      const args = R.drop(1, params);

      try {
        return R.apply(R[fnName], args);
      } catch (e) {
        return R.prop('defaultValue')(hash) || '';
      }
    }

  });

  _exports.default = _default;
});