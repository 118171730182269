define("client/pods/components/line/line-profile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    input() {
      this._super(...arguments);

      const channelId = this.get('model._data.lineChannelId');
      const callback = `https://service.bigordr.com/biordr-msg-bot/line/webhook/${channelId}`;
      this.set('model._data.lineCallbackUrl', callback);
    }

  });

  _exports.default = _default;
});