define("client/pods/components/contacts/panels-duplicates/row/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactsPanelsDuplicatesComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = class ContactsPanelsDuplicatesComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dataManager", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "mergedData", _descriptor3, this);

      _initializerDefineProperty(this, "mergedIntoKey", _descriptor4, this);

      _initializerDefineProperty(this, "mergePreviewModel", _descriptor5, this);
    }

    *fetchMergePreviewModelTask(duplicateData) {
      const mergePreviewData = yield this.dataManager.ajaxCall({
        method: 'GET',
        adapterName: 'contacts',
        appendPath: '/duplicates/merge',
        data: {
          email: duplicateData.email
        }
      });
      const previewModelData = mergePreviewData?.modelPreview || {};
      this.mergePreviewModel = this.dataManager.setAsRecord({
        adapterName: 'contacts/preview',
        data: previewModelData
      });
    }

    *mergeContactsTask(duplicateData, onCloseDialogAction) {
      const msg = this.intl.t('are you sure you want to merge contacts');

      if (window.confirm(msg)) {
        this.mergedData = yield this.dataManager.ajaxCall({
          method: 'PATCH',
          adapterName: 'contacts',
          appendPath: '/duplicates/merge',
          data: {
            email: duplicateData.email
          }
        });
        this.mergedIntoKey = this.mergedData?.model?._key;
        onCloseDialogAction();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "mergedData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "mergedIntoKey", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "mergePreviewModel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchMergePreviewModelTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchMergePreviewModelTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mergeContactsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "mergeContactsTask"), _class.prototype)), _class));
  _exports.default = ContactsPanelsDuplicatesComponent;
});