define("client/pods/components/extensions/apps/tw/e-invoices/print-manager/cancel-btn/component", ["exports", "ramda", "client/mixins/date", "ramda-extension"], function (_exports, R, _date, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    crud: Ember.inject.service(),
    tagName: '',
    reason: '',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['extensions/apps/tw/e-invoices/reasons']);
    },

    afterLoadLists() {
      this._super(...arguments);

      const reasons = R_.dotPath('crud.lists.extensions-apps-tw-e-invoices-reasons')(this) || [];
      const reason = reasons.get('firstObject');

      if (reason) {
        let allInvoicesToCancel = this.invoices || [this.invoice];
        allInvoicesToCancel = R.map(invoice => {
          invoice.set('reason', reason._data.value);
          return invoice;
        })(allInvoicesToCancel);
        this.set('allInvoicesToCancel', allInvoicesToCancel);
      }
    },

    actions: {
      loadReason(invoice, reason) {
        invoice.set('reason', reason.getData('value'));
      }

    }
  });

  _exports.default = _default;
});