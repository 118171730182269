define("client/pods/components/products/products-editor/recurring-schedules-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tL6nX+fC",
    "block": "{\"symbols\":[\"@isReadonly\",\"@model\",\"@tab\"],\"statements\":[[5,\"recurring/schedules-table\",[],[[\"@isReadonly\",\"@model\",\"@tab\"],[[23,1,[]],[23,2,[]],[23,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/products-editor/recurring-schedules-editor/template.hbs"
    }
  });

  _exports.default = _default;
});