define("client/helpers/sumBig", ["exports", "ramda", "client/mixins/big"], function (_exports, R, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_big.default, {
    compute(params, hash) {
      const rounding = hash.rounding || 0;
      const values = R.values(params) || [];
      let accBig = this.newBig(0);
      accBig = R.reduce((acc, value) => {
        return acc.plus(value);
      }, accBig, values);
      return accBig.toFixed(rounding);
    }

  });

  _exports.default = _default;
});