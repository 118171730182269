define("client/pods/users/related/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/users/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/users/related',
    modelObj: _model.default,
    idParam: '_key',
    // _id may not be available everywhere
    idPrefix: 'users'
  });

  _exports.default = _default;
});