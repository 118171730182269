define("client/pods/components/dispatch/panels-display/display-doc-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vp1Xe8vU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,0,[]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dispatch/panels-display/display-doc-row/template.hbs"
    }
  });

  _exports.default = _default;
});