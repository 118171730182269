define("client/pods/components/flows/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VCFO6mk9",
    "block": "{\"symbols\":[\"@onDisplay\",\"@onDash\",\"@tab\"],\"statements\":[[5,\"flows/flows-editor\",[],[[\"@model\",\"@errors\",\"@onSaveTask\",\"@isNew\",\"@adapterName\",\"@onDash\",\"@isEditing\",\"@tab\",\"@isReadonly\",\"@onCancel\",\"@isRunning\"],[[23,0,[\"dirty\"]],[23,0,[\"errors\"]],[28,\"perform\",[[23,0,[\"crud\",\"updateRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"onAfter\",\"component\"],[[23,0,[\"adapterName\"]],[23,0,[\"dirty\"]],[28,\"fn\",[[23,1,[]],[23,0,[\"model\"]]],null],[23,0,[]]]]]],null],false,[23,0,[\"adapterName\"]],[23,2,[]],true,[23,3,[]],false,[28,\"fn\",[[23,0,[\"crud\",\"cancelEditing\"]],[28,\"hash\",null,[[\"adapterName\",\"dirty\",\"onAfter\"],[[23,0,[\"adapterName\"]],[23,0,[\"dirty\"]],[28,\"fn\",[[23,1,[]],[23,0,[\"model\"]]],null]]]]],null],[23,0,[\"crud\",\"updateRecordTask\",\"isRunning\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/flows/panels-edit/template.hbs"
    }
  });

  _exports.default = _default;
});