define("client/pods/components/workflows/workflow-editor/stages-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZF1SnWns",
    "block": "{\"symbols\":[\"stage\",\"@isReadonly\",\"@model\"],\"statements\":[[4,\"if\",[[23,3,[\"_data\",\"stages\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\",true],[10,\"class\",\"u-table table-form\"],[8],[0,\"\\n    \"],[5,\"workflows/workflow-editor/stages-editor/detail-editor\",[],[[\"@isHeader\",\"@model\",\"@isReadonly\"],[true,[23,3,[]],[23,2,[]]]]],[0,\"\\n\"],[4,\"each\",[[23,3,[\"_data\",\"stages\"]]],null,{\"statements\":[[0,\"      \"],[5,\"workflows/workflow-editor/stages-editor/detail-editor\",[],[[\"@model\",\"@stage\",\"@isReadonly\"],[[23,3,[]],[23,1,[]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[5,\"elements/element-btn\",[],[[\"@label\",\"@classNames\",\"@icon\",\"@disabled\",\"@onSubmit\"],[\"add\",\"u-margin-top-10\",\"fas fa-plus\",[23,2,[]],[28,\"fn\",[[23,3,[\"addStage\"]],\"\"],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/workflows/workflow-editor/stages-editor/template.hbs"
    }
  });

  _exports.default = _default;
});