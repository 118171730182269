define("client/pods/components/channels/shop/campaigns/panels-schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nqIzX+h9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"calendar/calendar-month\",null,[[\"period\",\"data\",\"scheduleComponent\",\"onPreviousMonth\",\"onNextMonth\"],[[24,[\"current\"]],[24,[\"results\"]],\"channels/shop/campaigns/panels-schedule/day-schedule\",[28,\"action\",[[23,0,[]],\"previousMonth\"],null],[28,\"action\",[[23,0,[]],\"nextMonth\"],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-schedule/template.hbs"
    }
  });

  _exports.default = _default;
});