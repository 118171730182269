define("client/pods/components/files/import-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oZ5EJzU1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"elements/element-btn\",null,[[\"classNames\",\"label\",\"onSubmit\"],[\"images-uploader__btn\",[24,[\"label\"]],[28,\"action\",[[23,0,[]],\"selectUploads\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"elements/element-inputfile\",null,[[\"classNames\",\"multiple\",\"accept\",\"onChange\",\"value\"],[\"files-uploader__input\",[24,[\"multiple\"]],[24,[\"acceptedFormats\"]],[28,\"action\",[[23,0,[]],\"uploadsChanged\"],null],[24,[\"selectedFiles\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showProgress\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"images-uploader__progress-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"images-uploader__progress-bar\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"msgs/error-msg\",null,[[\"errors\",\"msg\"],[[24,[\"errors\"]],[24,[\"error\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[25,1],[23,0,[\"hasResults\"]]],null]],null,{\"statements\":[[0,\"  \"],[14,1,[[23,0,[\"hasResults\"]],[23,0,[\"results\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/import-btn/template.hbs"
    }
  });

  _exports.default = _default;
});