define("client/pods/components/grapesjs/btn-modal/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GrapesjsBtnModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class GrapesjsBtnModalComponent extends _component.default {
    constructor() {
      super(...arguments); // this.setDefaults()

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "selectedTheme", _descriptor3, this);

      _initializerDefineProperty(this, "gjsBtn", _descriptor4, this);

      _initializerDefineProperty(this, "gjsBtnContent", _descriptor5, this);

      _initializerDefineProperty(this, "gjsBtnHref", _descriptor6, this);

      _initializerDefineProperty(this, "gjsBtnTarget", _descriptor7, this);

      _initializerDefineProperty(this, "tempModel", _descriptor8, this);

      this._setup();
    }

    _setup() {
      const selected = this.args.editor.getSelected();
      this.gjsBtn = selected;
      const data = {};
      const gjsBtnAttributes = selected.getAttributes(); // if (selected) {

      const attributes = R.pathOr([], ['attributes', 'attributes'])(selected);
      this.selectedTheme = R.pathOr('button1', ['data-gjs-button-theme'])(gjsBtnAttributes);
      this.gjsBtnContent = R.pathOr('button', ['content'])(gjsBtnAttributes); // support old links

      const gjsBtnTarget = R.pathOr('_self', ['target'])(gjsBtnAttributes);
      const gjsBtnHref = R.pathOr('https://bigordr.com', ['href'])(gjsBtnAttributes);
      data.linkType = R.pathOr(data.linkType || 'url', ['data-href-link-type'])(attributes);
      data.linkTitle = R.pathOr('', ['data-href-title'])(attributes);
      data.linkUrl = R.pathOr(gjsBtnHref, ['data-href'])(attributes);
      data.linkResourceName = R.pathOr('', ['data-href-resource-name'])(attributes);
      data.linkResourceType = R.pathOr('', ['data-href-resource-type'])(attributes);
      data.linkResourceKey = R.pathOr('', ['data-href-resource-key'])(attributes);
      data.linkTarget = R.pathOr(gjsBtnTarget, ['data-href-target'])(attributes); // }

      const tempModel = this.crud.setupNewRecord({
        adapterName: 'websites/links',
        data
      });
      Ember.set(this, 'tempModel', tempModel);
    }

    get page() {
      const button = `<button class='button ${this.selectedTheme}' style='margin: 0 10px;'>${this.gjsBtnContent}</button>`;
      return {
        pageDataJson: {
          html: `<div class='ember-view' id='ember3'>
          <div class='columns page-container '>
            <div class='column page__content right'>
              <div class='ember-view' id='ember21'>
                <div id='page-custom-content' style='background-color: var(--themeBackgroundPrimaryColor);'>
                  <div class='bigordr-inner-row' style='background-color: var(--themeBackgroundPrimaryColor);'>
                    <br>
                    <div class='bigordr-cell' style='display: flex;justify-content: center;'>
                    ${button}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>`
        }
      };
    }

    selectTheme(themeName, onCloseDialogAction) {
      this.selectedTheme = themeName;
      onCloseDialogAction();
    }

    setBtnTheme(onCloseDialogAction) {
      const gjsBtnClasses = this.gjsBtn.getClasses();
      this.gjsBtn.setClass(R.reject(R.test(/button\d/))(gjsBtnClasses));

      if (!R.includes(this.selectedTheme)(gjsBtnClasses)) {
        this.gjsBtn.addClass(this.selectedTheme);
      }

      const previousAttributes = this.args.editor.getSelected().getAttributes();
      const tempModel = this.tempModel;
      const linkType = tempModel?._data?.linkType || '';
      const linkTitle = tempModel?._data?.linkTitle || '';
      const linkUrl = tempModel?._data?.linkUrl || '';
      const linkResourceName = tempModel?._data?.linkResourceName || '';
      const linkResourceType = tempModel?._data?.linkResourceType || '';
      const linkResourceKey = tempModel?._data?.linkResourceKey || '';
      const linkTarget = tempModel?._data?.linkTarget || '';
      const updatedAttributes = R.mergeRight(previousAttributes, {
        'data-gjs-button-theme': this.selectedTheme,
        content: this.gjsBtnContent,
        'data-href-target': linkTarget,
        'data-href': linkUrl || linkResourceKey,
        'data-href-link-type': linkType,
        'data-href-title': linkTitle,
        'data-href-resource-name': linkResourceName,
        'data-href-resource-type': linkResourceType,
        'data-href-resource-key': linkResourceKey
      });
      this.args.editor.getSelected().setAttributes(updatedAttributes);
      onCloseDialogAction();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedTheme", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button1';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "gjsBtn", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "gjsBtnContent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "gjsBtnHref", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'https://bigordr.com';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "gjsBtnTarget", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '_self';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tempModel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_setup", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "_setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTheme", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "selectTheme"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setBtnTheme", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setBtnTheme"), _class.prototype)), _class));
  _exports.default = GrapesjsBtnModalComponent;
});