define("client/pods/components/channels/shop/shop-status/component", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    tagName: '',
    badgeOnly: false,

    // classNames: ['tag'],
    // classNameBindings: ['isOnline:is-primary:is-danger'],
    getDateEndZ() {
      const now = this.getMoment();
      let dateEndZ = this.get('model._data.dateEndZ') || now;
      return this.getEndOfDayZ(dateEndZ);
    },

    status: Ember.computed('model.{_data.status,_data.dateStartZ,_data.dateEndZ}', function () {
      const status = this.get('model._data.status');
      const now = this.getMoment();
      const dateStartZ = this.get('model._data.dateStartZ') || now;
      const dateEndZ = this.getDateEndZ();
      const intl = this.intl;

      if (status === 'archived') {
        return intl.t('archived');
      }

      if (now.isSameOrAfter(dateStartZ) && now.isSameOrBefore(dateEndZ) && status === this.get('constants.status.active')) {
        return intl.t('online');
      }

      if (status === this.get('constants.status.inactive') || status === this.get('constants.status.suspended')) {
        return intl.t('offline') + ': ' + intl.t(status);
      }

      if (now.isBefore(dateStartZ)) {
        return intl.t('offline') + ': ' + intl.t(status);
      }

      if (now.isAfter(dateEndZ)) {
        return intl.t('offline') + ': ' + intl.t('expired');
      }
    }),
    isOnline: Ember.computed('model.{_data.status,_data.dateStartZ,_data.dateEndZ}', function () {
      const status = this.get('model._data.status');
      const now = this.getMoment();
      const dateStartZ = this.get('model._data.dateStartZ') || now;
      const dateEndZ = this.getDateEndZ();

      if (now.isSameOrAfter(dateStartZ) && now.isSameOrBefore(dateEndZ) && status === this.get('constants.status.active')) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});