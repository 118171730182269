define("client/pods/components/websites/websites-editor/settings-editor/component", ["exports", "ember-concurrency", "ramda", "ramda-extension", "client/mixins/crud", "client/mixins/search-contacts"], function (_exports, _emberConcurrency, R, R_, _crud, _searchContacts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, _searchContacts.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    settings: Ember.inject.service(),
    users: Ember.inject.service(),
    transporterAccounts: null,
    showColorTemplates: false,
    hasNextPanel: true,
    currentPanelIndex: 0,
    validateDomainLabel: 'validate now',

    init() {
      this.crud.addLists(this, ['channels/shop/campaigns/lists/productsImagesRatio', 'contacts/lists/tags', 'swatches', 'websites/lists/logo-menu-position', 'websites/lists/logo-centered-scroll-method', 'websites/lists/logo-icon-position', 'websites/lists/layout/menu-logo-size', 'websites/lists/layout/menu-logo-height', 'websites/lists/layout/menu-logo-height-separate-line', 'websites/lists/layout/menu-font-size', 'websites/lists/layout/menu-position', 'websites/lists/layout/menu-style', 'websites/lists/layout/menu-spacing', 'websites/lists/new-website-wizard', 'websites/lists/shared-color-themes']);

      this._super(...arguments);

      if (this.get('model._data.transporterKey')) {
        this.fetchTransporterTask.perform({
          model: this.model
        });
      }
    },

    afterLoadLists() {
      this._super(...arguments);

      if (!this.isNew) {
        return;
      }

      const initialPanel = this.tabsManager.getLoadInstructions(this.tab) || this.crud.lists['websites-lists-new-website-wizard'].get('firstObject');
      this.tabsManager.displaySubPanel(this, initialPanel);
    },

    // validatedDomainLabel: computed('model._data.domainVerified', function () {
    //   const domainVerified = R.pathOr(false, ['model', '_data', 'domainVerified'])(this)
    //   if (domainVerified) {
    //     return 'valid'
    //   }
    //   return 'validate now'
    // }),
    isCurrentStepComplete: Ember.computed('crud.lists.websites-lists-new-website-wizard.@each.isCompleted', 'model._data.{hasCustomDomain,domainVerified}', function () {
      // @TODO: this needs to change. using lists.websites to keep track of states is global. this will be shared accross all website instances
      const hasCustomDomain = R.pathOr(false, ['model', '_data', 'hasCustomDomain'])(this);
      const domainVerified = R.pathOr(false, ['model', '_data', 'domainVerified'])(this);

      if (hasCustomDomain && !domainVerified) {
        return true;
      }

      if (this.crud.lists['websites-lists-new-website-wizard']) {
        return !this.crud.lists['websites-lists-new-website-wizard'][this.currentPanelIndex].isCompleted;
      }

      return true;
    }),
    isWizardEnabled: Ember.computed('settings.settingsModel._data.{wizardEnable}', 'showWizard', function () {
      const settingsModel = R.pathOr(null, ['settingsModel'])(this.settings);

      if (settingsModel) {
        const wizardEnable = settingsModel.getData('wizardEnable') || false;
        return wizardEnable || this.showWizard;
      }

      return false;
    }),
    isConnectDomainDisabled: Ember.computed('model._data.domainVerified', function () {
      const domainVerified = R.pathOr(false, ['model', '_data', 'domainVerified'])(this);
      const isReadonly = R.pathOr(true, ['isReadonly'])(this);

      if (domainVerified) {
        return isReadonly;
      }

      return false;
    }),
    isWizardIncomplete: Ember.computed('model.wizardRequirementsMet.[]', function () {
      return R.includes(false)(this.model.wizardRequirementsMet);
    }),
    validDomainIconComputed: Ember.computed('model._data.{domainVerified,hasCustomDomain,domain}', function () {
      const domainVerified = R.pathOr(false, ['model', '_data', 'domainVerified'])(this);

      if (domainVerified) {
        return 'fas fa-check';
      } else {
        return 'fas fa-times';
      }
    }),
    isSameDomain: Ember.computed('model._data.domain', 'previousDomain', function () {
      const domain = R.pathOr('', ['model', '_data', 'domain'])(this);
      const previousDomain = R.pathOr('', ['previousDomain'])(this);
      return domain === previousDomain;
    }),
    headerTabs: Ember.computed('crud.lists.websites-lists-new-website-wizard.[]', 'model.wizardRequirementsMet.[]', function () {
      const wizardHeaderWebsites = this.crud.lists['websites-lists-new-website-wizard'] || [];
      const requirementsMet = this.model.wizardRequirementsMet || [];
      const hasTabs = !R.isEmpty(wizardHeaderWebsites);

      if (hasTabs) {
        return R.pipe(R.mapObjIndexed((tab, index, tabs) => {
          index = parseInt(index);
          const isComplete = requirementsMet[index] || false;

          if (tab.set) {
            tab.set('isCompleted', isComplete);
            const maxIndex = tabs.length - 1;

            if (maxIndex !== index) {
              tabs[++index].setData('enable', isComplete);
            }

            return tab;
          }

          return false;
        }), R.reject(R.equals(false)), R.toPairs, R.flatten, R.reject(R.is(String)))(wizardHeaderWebsites);
      }

      return wizardHeaderWebsites;
    }),
    fetchTransporterTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        model
      } = _ref;
      return function* () {
        const transporterKey = model.getData('transporterKey');
        const transporter = yield _this.findTask.perform({
          adapterName: 'contacts',
          appendPath: transporterKey
        });

        _this.loadTransporterAccounts(model, transporter);
      }();
    }),

    loadTransporterAccounts(model, transporter) {
      const transporterAccounts = transporter.getData('transporterAccounts') || [];
      this.set('transporterAccounts', transporterAccounts);

      if (!model.getData('transporterAccountNumber')) {
        const setDefaultTransporterAccount = R.pipe(R.filter(account => account.getData('isDefault') === true), R.map(defaultAccount => {
          model.setData('transporterAccount', defaultAccount.getData('name'));
          model.setData('transporterAccountNumber', defaultAccount.getData('account'));
        }));
        setDefaultTransporterAccount(transporterAccounts);
      }
    },

    shopPaymentMethodsDefaultsList: Ember.computed('crud.lists.paymentMethods-shop.[]', 'model._data.paymentMethods.[]', function () {
      const paymentMethods = R_.dotPath('crud.lists.paymentMethods-shop')(this) || [];
      const allowedPaymentMethods = this.get('model._data.paymentMethods') || [];
      return R.filter(method => R.includes(method.getData('value'), allowedPaymentMethods), paymentMethods);
    }),

    goToPanel(gotToHeaderPanel) {
      const headerPanels = this.crud.lists['websites-lists-new-website-wizard'];
      let nextIndex;

      if (gotToHeaderPanel) {
        nextIndex = headerPanels.indexOf(gotToHeaderPanel);
      } else {
        nextIndex = ++this.currentPanelIndex;
        gotToHeaderPanel = headerPanels[this.currentPanelIndex];
      }

      this.set('currentPanelIndex', nextIndex);
      const headerPanelsTotalIndex = headerPanels.length - 1;

      if (this.currentPanelIndex < headerPanelsTotalIndex) {
        this.set('hasNextPanel', true);
      } else {
        this.set('hasNextPanel', false);
      }

      return this.tabsManager.displaySubPanel(this, gotToHeaderPanel);
    },

    validateDomainTask: (0, _emberConcurrency.task)(function* () {
      const dirty = R.pathOr({}, ['dirty'])(this);
      const domain = R.pathOr('', ['_data', 'domain'])(dirty);
      const result = yield this.server.call('POST', 'api/protected/websites/validate/domain', {
        domain,
        _key: dirty.getData('_key')
      });
      const domainVerified = R.pathOr(false, ['data', 'domainVerified'])(result); // @TODO: remove

      const model = this.model;
      model.setData('domainVerified', domainVerified);
    }).drop(),

    editDomain() {
      this.set('isEditDomain', true);
      const dirty = this.crud.setupDirty({
        adapterName: 'websites',
        model: this.model
      });
      dirty.setData('hasCustomDomain', true);
      this.set('dirty', dirty);
    },

    updateDomainTask: (0, _emberConcurrency.task)(function* () {
      this.set('domainErrors', []);
      const updated = yield this.crud.updateRecordTask.perform({
        adapterName: 'websites',
        model: this.dirty,
        component: this,
        errorsProp: 'domainErrors'
      });

      if (!updated) {
        return false;
      }

      this.set('isEditDomain', false);
      const model = this.model;
      this.set('dirty', model);
    }),
    onSetAsDefaultImageTask: (0, _emberConcurrency.task)(function* () {
      return this.saveTask.perform({
        adapterName: 'websites',
        model: this.model,
        component: this
      });
    }),

    onOpenGraphImageChange(onCloseDialogAction, options, uploadsModel) {
      const uuid = R.pathOr('', ['_data', 'uuid'])(uploadsModel);
      this.model.set('_data.openGraph.imageUuid', uuid);
      onCloseDialogAction();
    },

    onFavIconChange(onCloseDialogAction, options, uploadsModel) {
      const uuid = R.pathOr('', ['_data', 'uuid'])(uploadsModel);
      this.model.set('_data.favicon', uuid);
      onCloseDialogAction();
    },

    removeFavIcon(onCloseDialogAction, options, uploadsModel) {
      this.model.set('_data.favicon', '');
    },

    onLogoMobileChange(onCloseDialogAction, options, uploadsModel) {
      const uuid = R.pathOr('', ['_data', 'uuid'])(uploadsModel);
      this.model.set('_data.logoMobile', uuid);
      onCloseDialogAction();
    },

    removeLogoMobile(onCloseDialogAction, options, uploadsModel) {
      this.model.set('_data.logoMobile', '');
    },

    removeOpenGraphImage() {
      this.model.set('_data.openGraph.imageUuid', '');
    },

    toggleAllowSeo(hasCustomDomain) {
      this.model.setData('allowSeo', hasCustomDomain);
    },

    actions: {
      openConnectDomain() {
        this.set('isEditDomain', false);
        const model = this.model;
        this.set('dirty', model);

        if (!model.getData('domain')) {
          this.editDomain();
        }
      },

      editDomain() {
        this.editDomain();
      },

      toggleColorTemplates() {
        this.set('showColorTemplates', !this.showColorTemplates);
      },

      selectHeaderPanel(gotToHeaderPanel) {
        this.goToPanel(gotToHeaderPanel);
      },

      selectNextPanel() {
        this.goToPanel();
      },

      resetDomainVerification(onCloseDialogAction) {
        onCloseDialogAction();

        if (this.dirty) {
          this.crud.cancelEditing({
            adapterName: 'websites',
            dirty: this.dirty
          });
          this.set('isEditDomain', false);
          this.set('dirty', null);
        }
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "onOpenGraphImageChange", [_dec], Object.getOwnPropertyDescriptor(_obj, "onOpenGraphImageChange"), _obj), _applyDecoratedDescriptor(_obj, "onFavIconChange", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onFavIconChange"), _obj), _applyDecoratedDescriptor(_obj, "removeFavIcon", [_dec3], Object.getOwnPropertyDescriptor(_obj, "removeFavIcon"), _obj), _applyDecoratedDescriptor(_obj, "onLogoMobileChange", [_dec4], Object.getOwnPropertyDescriptor(_obj, "onLogoMobileChange"), _obj), _applyDecoratedDescriptor(_obj, "removeLogoMobile", [_dec5], Object.getOwnPropertyDescriptor(_obj, "removeLogoMobile"), _obj), _applyDecoratedDescriptor(_obj, "removeOpenGraphImage", [_dec6], Object.getOwnPropertyDescriptor(_obj, "removeOpenGraphImage"), _obj), _applyDecoratedDescriptor(_obj, "toggleAllowSeo", [_dec7], Object.getOwnPropertyDescriptor(_obj, "toggleAllowSeo"), _obj)), _obj)));

  _exports.default = _default;
});