define("client/pods/components/forms/grid-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ojy/FOES",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"customBlock\"]]],null,{\"statements\":[[0,\"\\n\\t\"],[14,1],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\"],[1,[28,\"forms/form-label\",null,[[\"label\",\"translate\",\"classNames\",\"isRequired\"],[[24,[\"label\"]],[24,[\"translate\"]],[24,[\"labelClassNames\"]],[24,[\"isRequired\"]]]]],false],[0,\"\\n\\n\"],[4,\"forms/form-input\",null,null,{\"statements\":[[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\n\\t\\t\"],[1,[28,\"msgs/error-msg\",null,[[\"msg\"],[[24,[\"error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/forms/grid-row/template.hbs"
    }
  });

  _exports.default = _default;
});