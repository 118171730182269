define("client/pods/extensions/apps/tw/shopee/logistics/transporter/addresses/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /** @typedef {object} ExtensionsAppsTwShopeeLogisticsTransporterAddressesModelData
   * @property {number} id
   * @property {number} addressId
   * @property {string} region
   * @property {string} state
   * @property {string} city
   * @property {string} district
   * @property {string} town
   * @property {string} address
   * @property {string} zipcode
   * @property {string[]} addressFlag
   * @property {object[]} timeSlotList
   * @property {number} timeSlotList.date
   * @property {string} timeSlotList.pickupTimeId
   */
  let ExtensionsAppsTwShopeeLogisticsTransporterAddressesModel = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class ExtensionsAppsTwShopeeLogisticsTransporterAddressesModel extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dataManager", _descriptor, this);

      _initializerDefineProperty(this, "dateService", _descriptor2, this);

      _initializerDefineProperty(this, "results", _descriptor3, this);

      _initializerDefineProperty(this, "_data", _descriptor4, this);
    }

    get address() {
      return `${this._data.zipcode} ${this._data.city} ${this._data.address}`;
    }

    get addressId() {
      return this._data.addressId;
    }

    get id() {
      return this._data.id;
    }

    get pickupTimes() {
      return R.pipe(R.pathOr({}, ['_data', 'timeSlotList']), R.map(data => {
        return {
          addressId: this.addressId,
          date: data.date,
          dateZ: data.dateZ,
          dateLabel: this.dateService.getMoment(data.dateZ).format('YYYY-MM-DD HH:mm'),
          pickupTimeId: data.pickupTimeId
        };
      }))(this);
    }

    get branchIds() {
      return R.pipe(R.pathOr([], ['branchList', '_data']))(this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "results", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ExtensionsAppsTwShopeeLogisticsTransporterAddressesModel;
});