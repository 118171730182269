define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/zone-tables/districts-column/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, _ramda) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SettingsSettingsEditorZonesEditorZoneEditorZoneTablesDistrictsColumnComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class SettingsSettingsEditorZonesEditorZoneEditorZoneTablesDistrictsColumnComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "showDistricts", _descriptor2, this);

      _initializerDefineProperty(this, "selectedDistrictsForRegion", _descriptor3, this);

      this.setSelectedDistrictsForRegion();
    }

    setSelectedDistrictsForRegion() {
      const region = this.args.region || {};
      const country = this.args.country;
      const allSelectedDistricts = country?._data?.districts || [];
      this.selectedDistrictsForRegion = _ramda.default.pipe(_ramda.default.pathOr([], ['_data', 'districts']), _ramda.default.filter(district => {
        const postcode = district.getData('postcode');
        return _ramda.default.includes(postcode)(allSelectedDistricts);
      }))(region);
    }

    get filteredDistricts() {
      const region = this.args.region || [];
      const districts = region.getData('districts') || [];
      return districts;
    }

    onToggleSelectAllDistrict() {
      const country = this.args.country;
      const region = this.args.region || {};

      const districtsForRegion = _ramda.default.pathOr([], ['_data', 'districts'])(region);

      const selectedDistricts = country.getData('districts') || []; // R.forEach(country => {
      // const code = country?._data?.code

      _ramda.default.forEach(district => {
        const postcode = _ramda.default.path(['_data', 'postcode'])(district);

        selectedDistricts.pushObject(postcode);
      })(districtsForRegion);

      country.setData('districts', []);
      this.showDistricts = false;
      Ember.run.scheduleOnce('afterRender', () => {
        country.setData('districts', selectedDistricts);
        this.setSelectedDistrictsForRegion();
        this.showDistricts = true;
      });
    }

    onToggleDeselectAllDistrict() {
      const country = this.args.country;
      const region = this.args.region || {};

      const districtsForRegion = _ramda.default.pathOr([], ['_data', 'districts'])(region);

      const selectedDistricts = country.getData('districts') || [];

      _ramda.default.forEach(district => {
        const postcode = _ramda.default.path(['_data', 'postcode'])(district);

        selectedDistricts.removeObject(postcode);
      })(districtsForRegion);

      this.showDistricts = false;
      country.setData('districts', []);
      Ember.run.scheduleOnce('afterRender', () => {
        country.setData('districts', selectedDistricts);
        this.setSelectedDistrictsForRegion();
        this.showDistricts = true;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showDistricts", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedDistrictsForRegion", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setSelectedDistrictsForRegion", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedDistrictsForRegion"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleSelectAllDistrict", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleSelectAllDistrict"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleDeselectAllDistrict", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleDeselectAllDistrict"), _class.prototype)), _class));
  _exports.default = SettingsSettingsEditorZonesEditorZoneEditorZoneTablesDistrictsColumnComponent;
});