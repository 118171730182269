define("client/pods/components/workflows/dynamic-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SOVDhRmu",
    "block": "{\"symbols\":[\"&default\",\"@tab\",\"@master\",\"@metaData\",\"@type\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"onCreateContact\"],[[23,0,[\"onCreateContact\"]]]]]]],[0,\"\\n\\n\"],[5,\"workflows/create-dynamic\",[],[[\"@tab\",\"@master\",\"@metaData\",\"@type\"],[[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/workflows/dynamic-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});