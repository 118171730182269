define("client/pods/dispatch/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/documents/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/dispatch/processes',
    modelObj: _model.default,
    idParam: '_id',
    resultsProperty: 'docs' // findAll returns an object { docs: [results], summaryData } instead of the standard [results] array
    // @NOTE: there is a potential for problems here.
    // individual document models are created using document/sales/invoices (or others) adapter,
    // but when viewing dispatch, document models are created using the dispatch adapter.
    // there are differences. ideally they should use the document model's adapter
    // but how? since dispatch is a collection of models (eg. invoices/consignments). how to determine the correct model

  });

  _exports.default = _default;
});