define("client/pods/extensions/apps/tw/e-invoices/docs/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/extensions/apps/tw/e-invoices/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Model from '../../../../../documents/model'
  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/extensions/apps/tw/e-invoices/docs',
    modelObj: _model.default,
    idParam: false,
    resultsProperty: 'docs' // findAll returns an object { docs: [results], summaryData } instead of the standard [results] array

  });

  _exports.default = _default;
});