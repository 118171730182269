define("client/pods/components/documents/btn-split/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2r9/fU3w",
    "block": "{\"symbols\":[\"onCloseDialogAction\"],\"statements\":[[4,\"if\",[[24,[\"model\",\"allowSplit\"]]],null,{\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"icon\",\"tooltipLabel\"],[[24,[\"icon\"]],\"split_document\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"documents/panel-split\",null,[[\"model\",\"label\",\"splitQtyLabel\",\"appendPath\",\"onCloseDialogAction\",\"onAfterSplit\"],[[24,[\"model\"]],[24,[\"label\"]],[24,[\"splitQtyLabel\"]],[24,[\"splitAppendPath\"]],[23,1,[]],[28,\"action\",[[23,0,[]],\"afterSplit\",[23,1,[]]],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/btn-split/template.hbs"
    }
  });

  _exports.default = _default;
});