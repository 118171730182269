define("client/pods/components/workflows/items/card/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowsPanelDisplayComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('workflows'), _dec5 = Ember.inject.service('auth'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrencyDecorators.task)({
    enqueue: true,
    maxConcurrency: 3
  }), (_class = class WorkflowsPanelDisplayComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dragAndDrop", _descriptor2, this);

      _initializerDefineProperty(this, "search", _descriptor3, this);

      _initializerDefineProperty(this, "workflowsService", _descriptor4, this);

      _initializerDefineProperty(this, "authService", _descriptor5, this);

      _initializerDefineProperty(this, "dirty", _descriptor6, this);

      _initializerDefineProperty(this, "newItemActivityModel", _descriptor7, this);

      this.crud.addLists(this, ['users/related', 'workflows/lists/tags']);
    }

    get currentUserUid() {
      return this.authService.getUserProp('uid');
    }

    *bulkMarkActivityAsCompleted() {
      const workflowsItemsActivities = R.pathOr([], ['args', 'item', '_data', 'workflowsItemsActivities'])(this);
      const updateTask = R.map(workflowItemActivity => {
        workflowItemActivity.setData('isComplete', true);
        return this.markActivityAsCompletedTask.perform(workflowItemActivity);
      })(workflowsItemsActivities);
      yield (0, _emberConcurrency.all)(updateTask);
    }

    *markActivityAsCompletedTask(activity) {
      return yield this.workflowsService.markActivityAsCompleted.perform({
        activity,
        component: this
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dragAndDrop", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "workflowsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "authService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "newItemActivityModel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "bulkMarkActivityAsCompleted", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "bulkMarkActivityAsCompleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markActivityAsCompletedTask", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "markActivityAsCompletedTask"), _class.prototype)), _class));
  _exports.default = WorkflowsPanelDisplayComponent;
});