define("client/pods/components/workflows/workflow-btn/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WorkflowsWorkflowBtnComponent extends _component.default {// dispatchProcessCount: computed('dispatchProcessesByCount._data.processes.@each.count', 'dispatchProcess._data.code', function () {
    //   const dispatchProcessesByCount = this.dispatchProcessesByCount
    //   if (dispatchProcessesByCount) {
    //     const code = this.get('dispatchProcess._data.code')
    //     return dispatchProcessesByCount.getData(code) || 0
    //   }
    //   return 0
    // }),
    // isEditable: computed('dispatchProcess._data._key', 'dispatchProcessCount', function () {
    //   if (this.get('dispatchProcess._data._key')) {
    //     return true
    //   }
    //   return false
    // }),
    // hasProcessCount: computed('dispatchProcess._data._key', 'dispatchProcessCount', function () {
    //   if (this.dispatchProcessCount > 0) {
    //     return true
    //   }
    //   return false
    // })
  }

  _exports.default = WorkflowsWorkflowBtnComponent;
});