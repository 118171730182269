define("client/helpers/has", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params) {
      const arrayOrObject = params[0] || [];
      const key = params[1];

      if (key) {
        if (R.is(Array, arrayOrObject)) {
          if (arrayOrObject.indexOf(key) > -1) {
            return true;
          }
        }

        if (R.is(Object)) {
          if (arrayOrObject[key] != null) {
            return true;
          }
        }
      }

      return false;
    }

  });

  _exports.default = _default;
});