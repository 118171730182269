define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-history-selector/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostHistorySelector = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, (_class = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostHistorySelector extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "settings", _descriptor3, this);

      _initializerDefineProperty(this, "big", _descriptor4, this);

      _initializerDefineProperty(this, "commissionBaseCostHistoryFilters", _descriptor5, this);

      _initializerDefineProperty(this, "commissionBaseCostHistoryData", _descriptor6, this);

      _defineProperty(this, "tagName", 'span');

      _defineProperty(this, "classNames", ['']);

      _defineProperty(this, "isReadonly", false);

      _defineProperty(this, "adapterName", 'channels/shop/campaigns/details/commission-base-cost/history');

      _defineProperty(this, "filtersAdapterName", 'channels/shop/campaigns/details/commission-base-cost/history/filters');

      const commissionBaseCostHistoryFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.commissionBaseCostHistoryFilters = commissionBaseCostHistoryFilters;
    }

    *fetchHistoryOnInitTask() {
      const isUsingAverage = this?.args?.isUsingAverage;
      const itemKey = this?.args?.itemKey;
      const selectedCommissionBaseCostMethod = this.args.selectedCommissionBaseCostMethod;
      const hasAdjustedShippingFee = this.args.hasAdjustedShippingFee;
      const type = this.args.type;
      const commissionBaseCostHistoryFilters = this.commissionBaseCostHistoryFilters;
      commissionBaseCostHistoryFilters.setData('itemKey', itemKey);
      commissionBaseCostHistoryFilters.setData('isUsingAverage', isUsingAverage);
      commissionBaseCostHistoryFilters.setData('selectedCommissionBaseCostMethod', selectedCommissionBaseCostMethod);
      commissionBaseCostHistoryFilters.setData('hasAdjustedShippingFee', hasAdjustedShippingFee);
      commissionBaseCostHistoryFilters.setData('type', type);

      if (hasAdjustedShippingFee) {
        let taxRate = this?.args?.model?._data?.taxRate;

        if (RA.isNilOrEmpty(taxRate)) {
          const taxRateFromSettings = this?.settings?.settingsModel?._data?.taxRate || 0;
          taxRate = taxRateFromSettings;
        }

        const isModelCalculatedByExclAmt = R.pathOr(true, ['args', 'isCalculatedByExclAmt'])(this);
        commissionBaseCostHistoryFilters.setData('taxRate', taxRate);
        commissionBaseCostHistoryFilters.setData('isModelCalculatedByExclAmt', isModelCalculatedByExclAmt);
      }

      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: commissionBaseCostHistoryFilters
      });
      this.commissionBaseCostHistoryData = results;
    }

    get isDisabled() {
      if (!this?.args?.itemKey) {
        return true;
      }

      return this.isReadonly;
    }

    select(model, campaignsDetailsModel, selectedCommissionBaseCostMethod, type, history, onHideDropdown) {
      this.args.onSelect.perform({
        model,
        campaignsDetailsModel,
        selectedCommissionBaseCostMethod,
        type,
        history,
        onHideDropdown
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "big", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "commissionBaseCostHistoryFilters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "commissionBaseCostHistoryData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchHistoryOnInitTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchHistoryOnInitTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostHistorySelector;
});