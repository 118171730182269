define("client/pods/components/lists/list-btn/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // @TODO: rename option to item
  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    intl: Ember.inject.service(),
    tagName: 'span',
    itemValueKey: '_data.value',
    itemLabelKey: '_data.label',
    classNames: ['list-btn', 'u-tappable'],
    selectedOptionValueKey: '_data.label',
    // input value to set using option.key when an option is selected
    hasDropdown: true,
    showDropdown: false,
    autoPosition: true,
    onSubmit: 'defaultToggleDropdown',
    // can supply custom function to search on onSubmit action
    readonly: false,
    translate: true,
    tooltipTranslate: true,
    value: '',
    isToggler: true,
    hasToggleIcon: true,
    list: [],
    resultsComponent: 'lists/list-results',

    // component to display results (defaults to list-results)
    didInsertElement() {
      this._super(...arguments);

      if (this.onDidInsert) {
        this.onDidInsert(this);
      }
    },

    /**
     * determines if dropdown should be shown.
     * only show if isDropdownVisible and has list
     */
    isDropdownVisible: Ember.computed('showDropdown', 'list', function () {
      // const list = this.get('list') || []
      if (this.readonly === false && this.showDropdown) {
        // if (this.get('readonly') === false && this.get('showDropdown') && list.length > 0) {
        return true;
      }

      return false;
    }),

    toggleOnDropdown() {
      this.set('showDropdown', true);
    },

    /**
       * get selected dropdown value
       * @param {object} item model
       * @return {string} selected item value
       */
    getSelectedLabel: function (item) {
      if (!item) {
        const value = this.value;
        const list = this.list;

        if (value != null && !R.isEmpty(list)) {
          if (!item) {
            item = this.getSelectedItem(value);
          }
        }
      }

      if (item) {
        const itemLabelKey = this.itemLabelKey;
        return item.get(itemLabelKey);
      }

      if (!item) {
        return this.label || '';
      }
    },

    getSelectedItem(value) {
      const list = this.list;
      const itemValueKey = this.itemValueKey;
      let i = 0;
      let item;

      if (R.is(Array, list)) {
        while (list[i]) {
          if (R.equals(list.get(`${i}.${itemValueKey}`), value)) {
            item = list[i];
            i = list.length + 1;
          }

          i++;
        }
      }

      return item;
    },

    selectedLabel: Ember.computed('translate', 'defaultLabel', 'value', 'list.[]', 'selected', 'selected.{_data.translate,synced}', 'label', 'updateLabel', function () {
      const value = this.value;
      const label = this.getSelectedLabel() || this.label;
      const selected = this.getSelectedItem(value);

      if (!selected) {
        const defaultLabel = this.label;

        if (defaultLabel) {
          if (this.translate) {
            return this.intl.t(defaultLabel);
          }

          return defaultLabel;
        }
      }

      let itemTranslate;

      if (selected) {
        itemTranslate = selected.get('_data.translate');
        const shouldTranslateLabel = this.settings.shouldTranslateLabel(value, this.translate, itemTranslate);

        if (shouldTranslateLabel) {
          return this.intl.t(label);
        }
      }

      if (label && this.translate && this.list && value != null && value !== '') {
        const shouldTranslateLabel = this.settings.shouldTranslateLabel(value, this.translate, itemTranslate);

        if (shouldTranslateLabel) {
          return this.intl.t(label);
        }
      } // if (value != null && value != '' && this.get('list')) {
      // }


      return label;
    }),
    actions: {
      defaultToggleDropdown() {// @TODO: should remove this
        // this.set('showDropdown', true);
      },

      hideDropdown() {
        this.set('showDropdown', false);
      },

      showDropdown() {
        this.toggleOnDropdown();
      },

      selectDropdownItem(option
      /* , listSelectorComponent */
      ) {
        // @TODO: no need to keep selectedOptionValueKey
        // @TODO: no need to set value
        // set value of input to option.selectedValueKey
        const selectedOptionValueKey = this.selectedOptionValueKey;

        if (selectedOptionValueKey) {
          this.set('value', option.get(selectedOptionValueKey));
        } // select dropdown option, set component value


        this.set('showDropdown', false);

        if (this.onSelect) {
          this.onSelect(option, this);
        }
      },

      deselectDropdownItem() {
        this.onDeselect();
      }

    },

    onToggleModalDialog() {
      this.set('showDropdown', false);
    }

  }, (_applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj)), _obj)));

  _exports.default = _default;
});