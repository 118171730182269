define("client/pods/components/grapesjs/scratch-card-modal/component", ["exports", "ramda", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, R, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const IMAGE_WIDTH = 'data-image-width';
  const IMAGE_HEIGHT = 'data-image-height';
  const IMAGE_RATIO = 'data-image-ratio';
  const COVER_TYPE = 'data-cover-type';
  const COVER_IMAGE_SET = 'data-cover-image-set';
  const COVER_IMAGE_URL = 'data-cover-image-url';
  const COVER_TEXT = 'data-cover-text';
  const COVER_TEXT_COLOR = 'data-cover-text-color';
  const COVER_BG_COLOR = 'data-cover-bg-color';
  const BACKGROUND_TYPE = 'data-background-type';
  const BACKGROUND_IMAGE_SET = 'data-background-image-set';
  const BACKGROUND_IMAGE_URL = 'data-background-image-url';
  const BACKGROUND_TEXT = 'data-background-text';
  const BACKGROUND_TEXT_COLOR = 'data-background-text-color';
  const BACKGROUND_BG_COLOR = 'data-background-bg-color';
  const COVER_SELECTOR = '[data-gjs-type="bigordr-scratch-card-cover"]';
  const BACKGROUND_SELECTOR = '[data-gjs-type="bigordr-scratch-card-background"]';
  let GrapesjsScratchCardModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class GrapesjsScratchCardModalComponent extends _component.default {
    // unit: px
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dragDropSort", _descriptor2, this);

      _initializerDefineProperty(this, "scratchCard", _descriptor3, this);

      _initializerDefineProperty(this, "scratchCardErrors", _descriptor4, this);

      _initializerDefineProperty(this, "coverImageSet", _descriptor5, this);

      _initializerDefineProperty(this, "coverImageUrl", _descriptor6, this);

      _initializerDefineProperty(this, "backgroundImageSet", _descriptor7, this);

      _initializerDefineProperty(this, "backgroundImageUrl", _descriptor8, this);

      _defineProperty(this, "previewWidth", 400);

      _defineProperty(this, "typeList", [{
        label: 'background image',
        value: 'backgroundImage'
      }, {
        label: 'background color',
        value: 'backgroundColor'
      }]);

      _defineProperty(this, "ratioList", [{
        label: '2x1',
        value: '2x1'
      }, {
        label: '3x2',
        value: '3x2'
      }, {
        label: '4x3',
        value: '4x3'
      }, {
        label: '1x1',
        value: '1x1'
      }, {
        label: '3x4',
        value: '3x4'
      }, {
        label: '2x3',
        value: '2x3'
      }, {
        label: '1x2',
        value: '1x2'
      }]);

      this.getDefaultsScratchCards();
    } // unit: px


    get previewHeight() {
      if (this.imageRatio) {
        const _ratio = R.split('x', this.imageRatio);

        const _width = parseInt(_ratio[0]);

        const _height = parseInt(_ratio[1]);

        return parseInt(this.previewWidth * _height / _width);
      }

      return 300;
    }

    getDefaultsScratchCards() {
      const selected = this.args.editor.getSelected();

      if (selected) {
        this.scratchCard = selected.closest('[data-gjs-type="bigordr-scratch-card"]');
      }

      const tempModel = Ember.Object.create({
        _data: {
          coverBgColor: '',
          coverTextColor: '',
          coverType: '',
          backgroundBgColor: '',
          backgroundTextColor: '',
          backgroundType: ''
        }
      });

      tempModel.setData = (key, value) => {
        Ember.set(tempModel._data, `${key}`, value);
      };

      Ember.set(this, 'tempModel', tempModel);

      if (!this.scratchCard) {
        return;
      }

      const attributes = R.pathOr([], ['attributes', 'attributes'])(this.scratchCard);
      Ember.set(this, 'imageWidth', R.pathOr(400, [IMAGE_WIDTH])(attributes));
      Ember.set(this, 'imageHeight', R.pathOr(300, [IMAGE_HEIGHT])(attributes));
      Ember.set(this, 'imageRatio', R.propOr('4x3', [IMAGE_RATIO])(attributes));
      Ember.set(this, 'coverImageUrl', R.pathOr('', [COVER_IMAGE_URL])(attributes));
      Ember.set(this, 'coverText', R.pathOr('', [COVER_TEXT])(attributes));
      Ember.set(tempModel._data, 'coverBgColor', R.pathOr('', [COVER_BG_COLOR])(attributes));
      Ember.set(tempModel._data, 'coverTextColor', R.pathOr('', [COVER_TEXT_COLOR])(attributes));
      Ember.set(tempModel._data, 'coverType', R.propOr('backgroundImage', [COVER_TYPE])(attributes));
      Ember.set(this, 'backgroundImageUrl', R.pathOr('', [BACKGROUND_IMAGE_URL])(attributes));
      Ember.set(this, 'backgroundText', R.pathOr('', [BACKGROUND_TEXT])(attributes));
      Ember.set(tempModel._data, 'backgroundBgColor', R.pathOr('', [BACKGROUND_BG_COLOR])(attributes));
      Ember.set(tempModel._data, 'backgroundTextColor', R.pathOr('', [BACKGROUND_TEXT_COLOR])(attributes));
      Ember.set(tempModel._data, 'backgroundType', R.pathOr('backgroundImage', [BACKGROUND_TYPE])(attributes));
    }

    submit() {
      let onCloseDialogAction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : () => null;
      let metadata = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let image = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      const onAddImage = (uploadsModel, isCover) => {
        if (isCover) {
          this.coverImageUrl = uploadsModel?.getUrl?.();
          this.coverImageSet = uploadsModel?.imgSet || {};
        } else {
          this.backgroundImageUrl = uploadsModel?.getUrl?.();
          this.backgroundImageSet = uploadsModel?.imgSet || {};
        }
      };

      onAddImage(image, metadata?.isCover);
      onCloseDialogAction();
    }

    setScratchCardAttr() {
      const tempModelData = this.tempModel._data;
      const scratchCardAttributes = this.scratchCard.getAttributes();
      scratchCardAttributes[IMAGE_WIDTH] = this.previewWidth;
      scratchCardAttributes[IMAGE_HEIGHT] = this.previewHeight;
      scratchCardAttributes[IMAGE_RATIO] = this.imageRatio;
      scratchCardAttributes[COVER_IMAGE_URL] = this.coverImageUrl;
      scratchCardAttributes[COVER_TEXT] = this.coverText;
      scratchCardAttributes[COVER_BG_COLOR] = tempModelData.coverBgColor;
      scratchCardAttributes[COVER_TEXT_COLOR] = tempModelData.coverTextColor;
      scratchCardAttributes[COVER_TYPE] = tempModelData.coverType;
      scratchCardAttributes[BACKGROUND_IMAGE_URL] = this.backgroundImageUrl;
      scratchCardAttributes[BACKGROUND_TEXT] = this.backgroundText;
      scratchCardAttributes[BACKGROUND_BG_COLOR] = tempModelData.backgroundBgColor;
      scratchCardAttributes[BACKGROUND_TEXT_COLOR] = tempModelData.backgroundTextColor;
      scratchCardAttributes[BACKGROUND_TYPE] = tempModelData.backgroundType;

      const transformImgSet = function () {
        let {
          desktopUrl,
          mobileUrl,
          tabletUrl
        } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        return JSON.stringify({
          desktopUrl,
          mobileUrl,
          tabletUrl
        });
      };

      scratchCardAttributes[COVER_IMAGE_SET] = transformImgSet(this.coverImageSet);
      scratchCardAttributes[BACKGROUND_IMAGE_SET] = transformImgSet(this.backgroundImageSet);
      this.scratchCard.setAttributes(scratchCardAttributes);
    }

    renderScratchCard() {
      const scratchCardEl = this.scratchCard.getEl();
      const coverEl = scratchCardEl.querySelector(COVER_SELECTOR);
      const backgroundEl = scratchCardEl.querySelector(BACKGROUND_SELECTOR);
      const tempModelData = this.tempModel._data; // set for cover

      this._setInnerLayout({
        el: coverEl,
        imageSet: this.coverImageSet,
        imageUrl: this.coverImageUrl,
        text: this.coverText,
        textColor: tempModelData.coverTextColor,
        type: tempModelData.coverType,
        bgColor: tempModelData.coverBgColor
      }); // set gor background


      this._setInnerLayout({
        el: backgroundEl,
        imageSet: this.backgroundImageSet,
        imageUrl: this.backgroundImageUrl,
        text: this.backgroundText,
        textColor: tempModelData.backgroundTextColor,
        type: tempModelData.backgroundType,
        bgColor: tempModelData.backgroundBgColor
      });
    }

    _setInnerLayout() {
      let {
        el,
        imageSet,
        imageUrl,
        text,
        textColor,
        type,
        bgColor
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const hasChildren = el.children.length > 0;

      if (!hasChildren) {
        el.appendChild(document.createElement('div'));
      }

      const width = this.previewWidth;
      const height = this.previewHeight;
      const innerEl = document.createElement('div');
      innerEl.style.width = `${width}px`;
      innerEl.style.height = `${height}px`;
      innerEl.style.color = textColor;
      innerEl.style.display = 'flex';
      innerEl.style.alignItems = 'center';
      innerEl.style.justifyContent = 'center';
      const textContent = document.createElement('p');
      textContent.innerHTML = text;
      innerEl.appendChild(textContent); // @TODO: use different imageUrl from imageSet based on preview device

      const isImage = !!(type === 'backgroundImage' && imageUrl);

      if (isImage) {
        innerEl.style.background = `url(${imageUrl}-/scale_crop/${width}x${height}/center/)`;
      } else {
        innerEl.style.backgroundColor = bgColor;
      }

      const replaceTarget = el.children[0];
      replaceTarget.replaceWith(innerEl);
    }

    *onSaveTask(onCloseDialogAction) {
      if (!this.scratchCard) {
        if (onCloseDialogAction) {
          onCloseDialogAction();
        }

        return;
      }

      this.setScratchCardAttr();
      this.renderScratchCard();

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    onCancel(onCloseDialogAction) {
      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dragDropSort", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "scratchCard", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "scratchCardErrors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "coverImageSet", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "coverImageUrl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "backgroundImageSet", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "backgroundImageUrl", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSaveTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSaveTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCancel", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onCancel"), _class.prototype)), _class));
  _exports.default = GrapesjsScratchCardModalComponent;
});