define("client/pods/components/contacts/groups/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tDJlJHrX",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"layouts/table-view\",null,[[\"tab\",\"detailsRowComponent\",\"results\",\"filters\",\"onSearchTask\",\"onReloadSearchRecordsTask\",\"onPaginationTask\",\"onDisplay\",\"onEdit\",\"onMainAction\",\"tableViewOptions\"],[[24,[\"tab\"]],\"contacts/groups/table-view/detail-row\",[23,0,[\"filters\",\"results\"]],[23,0,[\"filters\"]],[24,[\"onSearchTask\"]],[24,[\"onReloadSearchRecordsTask\"]],[24,[\"onPaginationTask\"]],[24,[\"onDisplay\"]],[24,[\"onEdit\"]],[24,[\"onDisplay\"]],[28,\"hash\",null,[[\"onDash\"],[[28,\"perform\",[[23,0,[\"fetchDefaultDataTask\"]]],null]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/groups/panels-dash/template.hbs"
    }
  });

  _exports.default = _default;
});