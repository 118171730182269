define("client/pods/components/settings/defaults/3rd-party-editor/social-links/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hCbYQkKb",
    "block": "{\"symbols\":[\"@documentationResource\",\"@isReadonly\",\"@model\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@label\"],[\"facebookUrl\",[23,1,[]],\"facebook page url\"]],{\"statements\":[[0,\"\\n  \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@value\"],[[23,2,[]],[23,3,[\"_data\",\"facebookUrl\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@label\"],[\"instagramUrl\",[23,1,[]],\"instagram url\"]],{\"statements\":[[0,\"\\n  \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@value\"],[[23,2,[]],[23,3,[\"_data\",\"instagramUrl\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@label\"],[\"lineId\",[23,1,[]],\"line id\"]],{\"statements\":[[0,\"\\n  \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@value\"],[[23,2,[]],[23,3,[\"_data\",\"lineId\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@label\"],[\"youtubeUrl\",[23,1,[]],\"youtube channel url\"]],{\"statements\":[[0,\"\\n  \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@value\"],[[23,2,[]],[23,3,[\"_data\",\"youtubeUrl\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/defaults/3rd-party-editor/social-links/template.hbs"
    }
  });

  _exports.default = _default;
});