define("client/pods/contacts/transporters/filters/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/contacts/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    resourcePath: 'api/protected/contacts/transporters',
    idParam: false,
    findAll: function () {
      const data = {};
      return this._super(data).then(results => {
        const noneOption = {
          _key: '',
          name: 'pick up',
          translate: true
        };
        results.unshift(noneOption);
        return results;
      });
    }
  });

  _exports.default = _default;
});