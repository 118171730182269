define("client/pods/guests/documents/sales/invoices/adapter", ["exports", "@ember-data/adapter", "client/pods/documents/mixins/adapters", "client/pods/documents/model"], function (_exports, _adapter, _adapters, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adapters.default, {
    resourcePath: 'api/guests/documents/sales/invoices',
    modelObj: _model.default,
    idParam: '_id'
  });

  _exports.default = _default;
});