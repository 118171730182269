define("client/pods/components/products/products-editor/variants-editor/variant-editor/options-editor/component", ["exports", "client/mixins/errors", "client/mixins/droppable"], function (_exports, _errors, _droppable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_droppable.default, _errors.default, {
    tagName: 'tr',
    originalOptionName: '',

    init() {
      this._super(...arguments);

      this.set('originalOptionName', this?.option?._data?.name || '');
    },

    droppable: Ember.computed('options.[]', 'index', function () {
      const index = parseInt(this.index) + 1;
      const options = this.options || [];

      if (index >= options.length) {
        return false;
      }

      return true;
    }),
    draggable: Ember.computed('droppable', 'isReadonly', function () {
      if (!this.isReadonly) {
        return this.droppable;
      }

      return false;
    }),
    isVariantOptionReadonly: Ember.computed('isReadonly', 'option.{noInitialOptionUid,_data.name}', function () {
      let isVariantOptionReadonly = false;
      const option = this.option;

      if (this.isEditing && option.get('noInitialOptionUid') && option.get('_data.name')) {
        isVariantOptionReadonly = true;
      }

      if (!isVariantOptionReadonly) {
        isVariantOptionReadonly = this.isReadonly;
      }

      return isVariantOptionReadonly;
    }),
    actions: {
      checkForDuplicateValues(option, prop) {
        const value = option.getData(prop);

        if (value.trim() !== '') {
          const options = this.get('variant._data.options') || [];
          const found = options.filterBy(`_data.${prop}`, value);

          if (found.length > 1) {
            const msg = this.intl.t('duplicate value');
            window.alert(msg);
            option.setData(prop, '');
            return false;
          }
        }
      }

    }
  });

  _exports.default = _default;
});