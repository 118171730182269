define("client/pods/components/lists/list-searcher/list-searcher-tag/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['list-searcher__tag'],

    // tabindex: -1,
    // attributeBindings: ['tabindex'],
    didRender() {
      this._super(...arguments);

      const tagAutoFocus = this.tagAutoFocus;

      if (tagAutoFocus) {
        this.$().focus();
      }
    }

  });

  _exports.default = _default;
});