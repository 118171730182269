define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/has-variants/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRowHasVariants extends _component.default {}

  _exports.default = ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRowHasVariants;
});