define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y4ACKBA3",
    "block": "{\"symbols\":[\"menuNav\",\"metaData\",\"bulkDiscount\",\"@model\",\"@originalModel\",\"@tab\",\"@onUpdateBulkDiscountTask\",\"@headerBottom\",\"@tabContentOnly\",\"@onSetHeaderBottom\",\"@parentTab\",\"@resourceList\"],\"statements\":[[5,\"guide-menu/guide-menu-nav\",[],[[\"@headerBottom\",\"@hide\",\"@menuId\",\"@onSetHeaderBottom\",\"@parentTab\",\"@tab\"],[[23,8,[]],[23,9,[]],\"campaignsBulkDiscountsMenu\",[23,10,[]],[23,11,[]],[23,6,[]]]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[5,\"forms/form-container\",[],[[\"@containerId\",\"@context\",\"@isMenu\",\"@menuId\",\"@resourceList\",\"@tab\"],[\"bulk discounts\",\"bulk discounts\",true,[23,1,[\"menu\"]],[23,12,[]],[23,6,[]]]],{\"statements\":[[0,\"\\n\"],[0,\"    \"],[5,\"forms/form-entity\",[],[[\"@context\",\"@documentationResource\",\"@isNarrowOuterContainer\",\"@isMenu\",\"@menuId\",\"@parent\",\"@tab\",\"@title\"],[\"campaigns\",\"campaigns\",true,true,[23,1,[\"menu\"]],[23,2,[\"parentComponent\"]],[23,6,[]],\"bulk discounts\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,4,[\"_data\",\"bulkDiscounts\"]]],null,{\"statements\":[[4,\"each\",[[23,4,[\"_data\",\"bulkDiscounts\"]]],null,{\"statements\":[[0,\"          \"],[5,\"channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/bulk-discount-editor\",[],[[\"@bulkDiscount\",\"@model\",\"@originalModel\",\"@tab\",\"@onUpdateBulkDiscountTask\"],[[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]]]]],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[0,\"\\n          \"],[7,\"i\",true],[8],[1,[28,\"tt\",[\"no data\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"],[5,\"channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog\",[],[[\"@model\"],[[23,4,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/template.hbs"
    }
  });

  _exports.default = _default;
});