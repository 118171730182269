define("client/pods/components/channels/shop/campaigns/mixins/campaigns-list-by", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_crud.default, _date.default, {
    listByTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        adapterName,
        appendPath,
        query,
        filters,
        match,
        byLatest,
        campaignStatus,
        dateStart,
        dateEnd,
        resultsProperty,
        resultsToggle,
        component
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let onHideDropdown = arguments.length > 1 ? arguments[1] : undefined;
      return function* () {
        filters.setData('page', 1);

        if (match) {
          filters.setData('match', match);
        }

        if (dateStart) {
          filters.setData('dateStart', dateStart);
        }

        if (dateEnd) {
          filters.setData('dateEnd', dateEnd);
        }

        if (campaignStatus) {
          filters.setData('campaignStatus', campaignStatus);
        }

        if (byLatest || campaignStatus) {
          filters.setData('sort', [{
            by: 'timestampZ_latest',
            direction: 'DESC'
          }]);
        }

        const searchSettings = {
          adapterName,
          appendPath,
          resultsProperty,
          resultsToggle,
          query,
          filters,
          component
        };
        const results = yield _this.crud.searchRecordsTask.perform(searchSettings);

        if (onHideDropdown) {
          onHideDropdown();
        }

        _this.set('showResults', true);

        return results;
      }();
    }),
    actions: {
      listByDate(onHideDropdown, dateZ, date) {
        return this.listByTask.perform({
          filters: this.filters,
          date,
          dateZ,
          resultsToggle: 'resultsToggle'
        }, onHideDropdown);
      }

    }
  });

  _exports.default = _default;
});