define("client/pods/components/documents/editor-main/editor-btns-editing/component", ["exports", "ember-concurrency", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _emberConcurrency, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    documents: Ember.inject.service(),
    crud: Ember.inject.service(),
    tagName: '',
    hasReturns: true,
    isApplyReturnAdjustment: true,

    init() {
      this._super(...arguments);

      const docType = this.docType;
      const docName = this.docName;

      if (docType === 'sales' && docName === 'invoices') {
        this.setup.perform();
      } else {
        this.set('hasReturns', false);
      }

      const originalInclTotal = R.pathOr('', ['model', '_data', 'inclTotal'])(this);
      this.set('originalInclTotal', originalInclTotal);
    },

    setup: (0, _emberConcurrency.task)(function* () {
      const quickLinkFromDocType = R_.dotPathOr('sales', 'args.quickLinkFromDocType')(this);
      const quickLinkFromDocName = R_.dotPathOr('invoices', 'args.quickLinkFromDocName')(this);
      const quickLinkToDocType = R_.dotPathOr('sales', 'args.quickLinkToDocType')(this);
      const quickLinkToDocName = R_.dotPathOr('returns', 'args.quickLinkToDocName')(this);
      const model = this.model;
      const returnsByDetailKey = yield this.documents.hasReturns.perform(model, {
        docType: quickLinkFromDocType,
        docName: quickLinkFromDocName,
        toDocType: quickLinkToDocType,
        toDocName: quickLinkToDocName
      });
      this.set('hasReturns', RA.isNotNilOrEmpty(returnsByDetailKey));
    }),
    validateSalesReturnsTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const isApplyReturnAdjustment = this.isApplyReturnAdjustment;
      model.setData('isApplyReturnAdjustment', isApplyReturnAdjustment);
      const validatedModel = yield this.crud.createRecordTask.perform({
        adapterName: 'documents/sales/returns',
        appendPath: '/validateReturnsWithRewards',
        model
      });

      if (validatedModel) {
        const validatedData = validatedModel.serialize();
        let returnRewardAdjustmentPoints = validatedData.returnRewardAdjustmentPoints || 0;
        returnRewardAdjustmentPoints = parseInt(returnRewardAdjustmentPoints, 10);
        model.populate(validatedData);
        const isApplyReturnAdjustmentAndHasNoReturnAdjustment = isApplyReturnAdjustment && returnRewardAdjustmentPoints === 0;

        if (isApplyReturnAdjustmentAndHasNoReturnAdjustment) {
          this.onPreSubmit(model, this.onSubmitFinalTask);
        }

        model.set('isValidatedReturnsWithRewards', true);
      }
    }),
    isOriginalPriceEqualNewPrice: Ember.computed('model.{isTaxExclusive,isSalesInvoices}', 'originalInclTotal', 'model._data.{inclTotal,detailsLocked}', function () {
      const detailsLocked = this.get('model._data.detailsLocked') || false;
      const isSalesInvoices = this.get('model.isSalesInvoices');

      if (this.isSplitting || this.isMerging) {
        return true;
      }

      if (detailsLocked && isSalesInvoices) {
        const newInclTotal = this.get('model._data.inclTotal') || '';
        const originalInclTotal = this.get('originalInclTotal') || '';

        if (R.equals(`${originalInclTotal}`, `${newInclTotal}`)) {
          return true;
        }

        return false;
      }

      return true;
    }),
    isSalesReturnsWithReward: Ember.computed('model._data.{docName,docType,rewardData}', function () {
      const model = this.model;

      if (RA.isNotNilOrEmpty(model)) {
        const docName = model.getData('docName');
        const docType = model.getData('docType');
        const rewardData = model.getData('rewardData');
        const isSalesReturns = docType === 'sales' && docName === 'returns';

        if (isSalesReturns && RA.isNotNilOrEmpty(rewardData)) {
          return true;
        }
      }

      return false;
    }),
    showValidateSalesReturnsBtn: Ember.computed('isSalesReturnsWithReward', 'model.isValidatedReturnsWithRewards', function () {
      const isSalesReturnsWithReward = this.get('isSalesReturnsWithReward');
      const isValidatedReturnsWithRewards = this.get('model.isValidatedReturnsWithRewards') || false;

      if (isSalesReturnsWithReward && !isValidatedReturnsWithRewards) {
        return true;
      }

      return false;
    }),
    bulkInsertDetailsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, bulkInsertInstructions) {
      yield this.onBulkInsertDetailsTask.perform(bulkInsertInstructions);
      onCloseDialogAction();
    })
  });

  _exports.default = _default;
});