define("client/pods/components/extensions/apps/tw/e-invoices/blank-upload-status/component", ["exports", "@glimmer/component", "ramda-extension"], function (_exports, _component, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtensionsAppsTwEInvoicesBlankUploadStatusComponent extends _component.default {
    get statusLabel() {
      const status = R_.dotPath('args.blank.status')(this);

      if (!status) {
        return 'tw_eInvoices_numbers_upload_blanks_not_uploaded';
      }

      return `tw_eInvoices_blankUploaded_${status}`;
    }

    get statusClassNames() {
      const status = R_.dotPath('args.blank.status')(this);

      if (status === 'successful') {
        return 'is-successful';
      }

      return 'is-warning';
    }

  }

  _exports.default = ExtensionsAppsTwEInvoicesBlankUploadStatusComponent;
});