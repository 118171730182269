define("client/pods/transporter/service", ["exports", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _emberConcurrencyDecorators, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TransporterService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class TransporterService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _defineProperty(this, "isPickerOnly", true);

      _initializerDefineProperty(this, "accounts", _descriptor3, this);

      _initializerDefineProperty(this, "listsIsLoaded", _descriptor4, this);

      _initializerDefineProperty(this, "externalTransportersList", _descriptor5, this);

      _initializerDefineProperty(this, "selectedTransporter", _descriptor6, this);

      _initializerDefineProperty(this, "transporterAccounts", _descriptor7, this);

      _initializerDefineProperty(this, "transportersList", _descriptor8, this);

      _initializerDefineProperty(this, "sevenElevenWaybillNotificationModel", _descriptor9, this);

      this.crud.addLists(this, ['contacts/transporters']);
    }

    get transportersList() {
      debugger;
      const transporters = this.crud.lists['contacts-transporters'] || [];

      if (this.externalTransportersList) {
        return this.externalTransportersList;
      }

      return transporters || [];
    }

    get defaultTransporterAccount() {
      const transporterAccounts = this.transporterAccounts || [];
      const defaultAccount = R.find(R.pathEq(['_data', 'isDefault'], true))(transporterAccounts);

      if (RA.isNilOrEmpty(defaultAccount)) {
        return R.head(transporterAccounts) || {};
      }

      return defaultAccount;
    }

    get defaultTransporterAccountNumber() {
      return this.get('defaultTransporterAccount._data.account') || '';
    }

    loadTransporterAccounts(model, transporter) {
      const transporterExtension = transporter.getData('transporterExtension') || [];
      model.setData('transporterExtension', transporterExtension);
      const transporterAccounts = transporter.getData('transporterAccounts') || [];
      Ember.set(this, 'transporterAccounts', transporterAccounts);

      if (!model.getData('transporterAccountNumber')) {
        const setDefaultTransporterAccount = R.pipe(R.filter(account => account.getData('isDefault') === true), R.map(defaultAccount => {
          model.setData('transporterAccount', defaultAccount.getData('name'));
          model.setData('transporterAccountNumber', defaultAccount.getData('account'));
        }));
        setDefaultTransporterAccount(transporterAccounts);
      }
    }

    fetchTransporterTask(_ref) {
      var _this = this;

      let {
        model,
        transporterKey
      } = _ref;
      return function* () {
        transporterKey = transporterKey || model?._data?.transporterKey;

        if (transporterKey) {
          const transporter = yield _this.crud.findTask.perform({
            adapterName: 'contacts',
            appendPath: transporterKey
          });

          if (model) {
            _this.loadTransporterAccounts(model, transporter);
          }

          return transporter;
        }
      }();
    }

    fetchTransportersByExtensionTask() {
      var _this2 = this;

      let {
        model,
        transporterExtensions
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        transporterExtensions = transporterExtensions || model?._data?.transporterExtensions || '';

        if (transporterExtensions) {
          const filters = _this2.search.setupFilters({
            adapterName: 'contacts/transporters',
            defaultFiltersData: {
              all: true
            }
          });

          const transporter = yield _this2.crud.searchRecordsTask.perform({
            adapterName: `contacts/transporters`,
            appendPath: `extension/${transporterExtensions}`,
            filters
          });

          if (model) {
            _this2.loadTransporterAccounts(model, transporter);
          }

          return transporter;
        }
      }();
    } // @TODO: moved from mixin


    setSelectedTransporter(model) {
      const transporterKey = R.pathOr('', ['_data', 'transporterKey'])(model);
      let transporter = false;

      if (transporterKey) {
        transporter = this.findTransporterByKey(transporterKey);
      }

      this.selectedTransporter = transporter;
      this.setTransporterAccounts(transporter);
    } // @TODO: moved from mixin


    setTransporterAccounts(transporter) {
      let accounts = [];

      if (transporter) {
        accounts = transporter._data.transporterAccounts || [];
      }

      this.accounts = accounts;
    } // @TODO: remove side effects (moved from mixin)


    loadTransporter(model, transporter) {
      const key = transporter.getData('_key');
      model.setData('transporterKey', key);

      if (!this.isPickerOnly) {
        model.setData('transporterExtension', transporter.getData('transporterExtension'));
        this.setSelectedTransporter(model);
      }
    } // @TODO: remove side effects (moced from mixin)


    unloadTransporter(model) {
      model.setData('transporterKey', '');

      if (!this.isPickerOnly) {
        model.setData('transporterExtension', '');
        model.setData('transporter', '');
        model.setData('transporterBatchNumber', '');
        model.setData('transporterBatchLabel', '');
        model.setData('transporterAccountNumber', '');
        model.setData('transporterAccount', '');
        model.setData('packages', '');
        model.setData('transporterWeightRate', '');
        model.setData('transporterRegion', '');
        model.setData('transporterRegionIsOuter', '');
        model.setData('person', '');
        model.setData('transporterContactPhone', '');
        this.setSelectedTransporter(model);
      }
    } // @TODO: R.curry is used in Mixin need to check if it should be used


    afterLoadTransporters(component) {
      if (!component?.isDestroyed) {
        const transporters = R_.dotPath('crud.lists.contacts-transporters')(component) || [];

        if (!R.isEmpty(transporters)) {
          const model = component?.dirty || component?.model;

          if (model) {
            component?.setSelectedTransporter?.(model);
          }
        }

        component.listsIsLoaded = Math.random();
      }
    }
    /**
     * Fetches waybill notification for the given model.
     * If the model is not a transporter with the name 'SevenEleven', the method returns without doing anything.
     * If the model does not have a waybill, it sets the 'sevenElevenWaybillNotificationModel' property to null and returns.
     * Otherwise, it performs a find task using the provided adapter and appends the waybill to the path,
     * and sets the 'sevenElevenWaybillNotificationModel' property to the found model if it has a valid key,
     * otherwise sets it to null.
     *
     * @param {Object} model - docSales Model
     */


    *fetchWaybillNotification(model) {
      if (!model.isTransporterSevenEleven) {
        return;
      }

      const waybill = model?._data?.waybill;

      if (!waybill) {
        model.set('sevenElevenWaybillNotificationModel', null);
        return;
      }

      const sevenElevenWaybillNotificationModel = yield this.crud.findTask.perform({
        adapterName: 'extensions/apps/tw/seven-eleven/notification',
        appendPath: waybill
      });

      if (sevenElevenWaybillNotificationModel?._data?._key) {
        model.set('sevenElevenWaybillNotificationModel', sevenElevenWaybillNotificationModel);
      } else {
        model.set('sevenElevenWaybillNotificationModel', null);
      }
    }
    /**
     * Updates the waybill notification.
     *
     * @param {Object} dirty - The dirty object to update the waybill notification.
     * @returns {Object} - The updated waybill notification model.
     */


    *updateWaybillNotification(dirty) {
      const model = yield this.crud.updateRecordTask.perform({
        appendPath: 'transporter/re-route',
        adapterName: 'documents/sales/invoices',
        model: dirty,
        component: this
      });
      yield this.fetchWaybillNotification.perform(model);
      return model;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "accounts", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "listsIsLoaded", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "externalTransportersList", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedTransporter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "transporterAccounts", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "transportersList", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "sevenElevenWaybillNotificationModel", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchTransporterTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTransporterTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchTransportersByExtensionTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTransportersByExtensionTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSelectedTransporter", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedTransporter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setTransporterAccounts", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setTransporterAccounts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadTransporter", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "loadTransporter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadTransporter", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "unloadTransporter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterLoadTransporters", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "afterLoadTransporters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchWaybillNotification", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchWaybillNotification"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateWaybillNotification", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateWaybillNotification"), _class.prototype)), _class));
  _exports.default = TransporterService;
});