define("client/pods/components/app-companies/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BYb4pxT+",
    "block": "{\"symbols\":[\"@onCancel\",\"@companyLogoUrl\",\"@hasFooter\"],\"statements\":[[5,\"lists/list-dropdown\",[],[[\"@imageUrl\",\"@imageHeight\",\"@imageWidth\",\"@imageTransform\",\"@imageSrcsetTransform\",\"@isBtn\",\"@list\",\"@translate\",\"@itemValueKey\",\"@itemLabelKey\",\"@value\",\"@isRunning\",\"@onSelect\"],[[23,2,[]],\"30px\",\"auto\",\"/-/scale_crop/60x60/center/-/format/auto/-/progressive/yes/\",\"/-/scale_crop/120x120/center/-/format/auto/-/progressive/yes/ 2x\",true,[23,0,[\"pods\"]],false,\"podKey\",\"company\",[23,0,[\"selectedPod\"]],[23,0,[\"loginToCompanyTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"loginToCompanyTask\"]]],null]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[4,\"layouts/footer-btns\",null,null,{\"statements\":[[4,\"layouts/btns-group\",null,[[\"classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"elements/btn-cancel\",null,[[\"onSubmit\"],[[28,\"fn\",[[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[1,[28,\"elements/btn-save\",null,[[\"icon\",\"label\",\"isRunning\",\"onSubmit\"],[\"\",\"select\",[23,0,[\"loginToCompanyTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"loginToCompanyTask\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/app-companies/template.hbs"
    }
  });

  _exports.default = _default;
});