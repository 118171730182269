define("client/pods/components/products/search-bar-container/search-results/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: '',

    init() {
      this.crud.addLists(this, ['products/lists/filters/status']);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});