define("client/pods/components/dispatch/panels-display/print-all-dialog/component", ["exports", "ramda", "ember-concurrency", "ramda-extension", "ramda-adjunct"], function (_exports, R, _emberConcurrency, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    constants: Ember.inject.service(),
    constantsService: Ember.inject.service('constants'),
    tagName: 'span',
    hasPrices: true,
    hasGroups: true,
    hasReturnsDetails: true,
    nextDispatchProcessCode: '',
    isPrinted: false,
    hidePrinted: true,
    markAsPrintedOnClose: true,
    printType: '',
    lastPrintTimestamp: '',
    batchStatus: null,
    waybillsPerPage: 4,
    shopeeShopId: null,
    shopeeData: {},
    documentsBatchStatusAlwaysVisible: false,

    init() {
      this.crud.addLists(this, ['documents/lists/hide-printed', 'documents/lists/print', 'documents/lists/print/unit', 'documents/lists/print/product-code', 'extensions/apps/tw/t-cat/lists/waybill-print-type']);

      this._super(...arguments);

      const subPanels = this.printSubPanels || [];

      if (subPanels.length > 0) {
        this.tabsManager.displaySubPanel(this, subPanels.get('firstObject'));
      }

      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      this.set('dispatchProcesses', dispatchProcesses);
      this.set('isPrinted', false);
      this.set('errors', []);
      const printImagesOnDocuments = this?.settings?.settingsModel?._data?.printImagesOnDocuments || false;

      if (printImagesOnDocuments) {
        this.set('hasImages', true);
      }
    },

    afterLoadLists() {
      const hidePrinted = R.pathOr([], ['crud', 'lists', 'documents-lists-hide-printed', 0, '_data', 'value'])(this);
      this.set('hidePrinted', hidePrinted);
    },

    setupTask: (0, _emberConcurrency.task)(function* (onFetchUnpaginatedData) {
      this.set('isPrinted', false);
      this.set('markAsPrintedOnClose', true);
      this.set('errors', []);
      return onFetchUnpaginatedData({
        hidePrinted: this.hidePrinted
      });
    }).drop(),
    printBadge: Ember.computed('toPrintList.[]', 'hasSelected', function () {
      if (this.hasSelected > 0) {
        const models = this.toPrintList || [];
        return models.length;
      }

      return this.intl.t('all');
    }),
    printContentResults: Ember.computed('toPrintList.[]', 'toPrintListTCat.[]', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', function () {
      const activeSubTab = R.pipe(R.pathOr([], ['tab', 'subTabsArray']), R.find(R.propEq('isActive', true)))(this);
      let provider = R.propOr('', 'value')(activeSubTab);
      const isHct = R.pipe(R.propOr('', 'label'), R.includes(this.constantsService.transporterExtensions.hct))(activeSubTab);

      if (isHct) {
        provider = this.constantsService.transporterExtensions.hct;
      }

      const allResultsWhen = ['pickingList', 'deliveryNotes', 'hctSummary'];
      const shouldShowAllResults = R.includes(provider)(allResultsWhen);

      if (shouldShowAllResults) {
        return this.toPrintModelsList;
      }

      return R.pipe(R.propOr([], 'toPrintModelsList'), R.filter(R.pathEq(['_data', 'transporterExtension'], provider)))(this);
    }),
    printContentListToPrint: Ember.computed('toPrintList.[]', 'toPrintListTCat.[]', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', 'shopeeShopId', 'shopeeData.transporterMethod', function () {
      const activeSubTab = R.pipe(R.pathOr([], ['tab', 'subTabsArray']), R.find(R.propEq('isActive', true)))(this);
      let provider = R.propOr('', 'value')(activeSubTab);
      const isHct = R.pipe(R.propOr('', 'label'), R.includes(this.constantsService.transporterExtensions.hct))(activeSubTab);

      if (isHct) {
        provider = this.constantsService.transporterExtensions.hct;
      }

      const allResultsWhen = ['pickingList', 'deliveryNotes', 'hctSummary'];
      const shouldShowAllResults = R.includes(provider)(allResultsWhen);

      if (shouldShowAllResults) {
        return this.toPrintList;
      }

      if (provider === 'shopee') {
        return this.getShopeeDocSales(this.toPrintList);
      }

      return R.pipe(R.propOr([], 'toPrintList'), R.filter(R.pathEq(['model', '_data', 'transporterExtension'], provider)))(this);
    }),
    shopeeShopIdList: Ember.computed('toPrintList.[]', 'printContentListToPrint.[]', function () {
      return R.pipe(R.propOr([], 'toPrintList'), R.map(R.pathOr({}, ['model', '_data', 'thirdParty', 'shopee'])), R.filter(R.pipe(R.pathOr('', ['_data', 'shopId']), RA.isNotNilOrEmpty)), R.uniqBy(R.path(['_data', 'shopId'])))(this);
    }),
    shopeeLogisticsByTransporterMethod: Ember.computed('shopeeShopIdList', 'toPrintList.[]', 'shopeeData.transporterMethod', 'printContentListToPrint.[]', function () {
      return R.pipe(R.propOr([], 'printContentListToPrint'), R.map(R.pathOr([], ['model', '_data', 'shopeeData', 'transporter', 'transporterMethod', this.shopeeData?.transporterMethod])), R.flatten)(this);
    }),
    shopeeLogisticsTransporterAddress: Ember.computed('shopeeLogisticsByTransporterMethod', function () {
      return R.pipe(R.propOr([], 'shopeeLogisticsByTransporterMethod'), R.uniqBy(R.prop('addressId')))(this);
    }),
    shopeeLogisticsTransporterAddressPickUpTime: Ember.computed('shopeeLogisticsByTransporterMethod', 'shopeeData.transporterAddressId', function () {
      return R.pipe(R.propOr([], 'shopeeLogisticsByTransporterMethod'), R.map(R.pathOr([], ['pickupTimes'])), R.flatten, R.filter(R.propEq('addressId', this.shopeeData?.transporterAddressId)), R.uniqBy(R.prop('pickupTimeId')))(this);
    }),
    shopeeLogisticsTransporterBranches: Ember.computed('shopeeLogisticsByTransporterMethod', function () {
      return R.pipe(R.propOr([], 'shopeeLogisticsByTransporterMethod'), R.filter(transporterMethod => {
        const hasBranchId = R.pipe(R.pathOr('', ['_data', 'branchId']), RA.isNotNilOrEmpty)(transporterMethod);

        if (hasBranchId) {
          return true;
        }

        return false;
      }), R.uniqBy(R.prop('id')))(this);
    }),
    shopeeLogisticsTransporterSlugs: Ember.computed('shopeeLogisticsByTransporterMethod', function () {
      return R.pipe(R.propOr([], 'shopeeLogisticsByTransporterMethod'), R.filter(transporterMethod => {
        const haSlug = R.pipe(R.pathOr('', ['_data', 'slug']), RA.isNotNilOrEmpty)(transporterMethod);

        if (haSlug) {
          return true;
        }

        return false;
      }), R.uniqBy(R.prop('id')))(this);
    }),
    printCount: Ember.computed('printContentListToPrint', 'printContentResults', 'toPrintList.[]', 'toPrintListTCat.[]', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', function () {
      return R.length(this.printContentListToPrint);
    }),
    printListWithTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'printContentResults.@each._data.transporterWaybillRecord', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', function () {
      return R.pipe(R.propOr([], 'printContentResults'), R.reject(R.pipe(R.path(['_data', 'transporterWaybillRecord']), RA.isNilOrEmpty)))(this);
    }),
    printListCountTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'printListWithTransporterWaybillRecord.@each._data.transporterWaybillRecord', 'printContentResults', function () {
      return R.length(this.printListWithTransporterWaybillRecord);
    }),
    missingTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'printContentResults.@each._data.transporterWaybillRecord', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', function () {
      return R.pipe(R.propOr([], 'printContentResults'), R.filter(R.pipe(R.path(['_data', 'transporterWaybillRecord']), RA.isNilOrEmpty)))(this);
    }),
    printCountMissingTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'missingTransporterWaybillRecord.@each._data.transporterWaybillRecord', 'printContentResults', function () {
      return R.length(this.missingTransporterWaybillRecord);
    }),
    showMarkAsPrintedBtn: Ember.computed('isDispatched', 'isPrinted', 'dispatchProcess._data.allowPrintAllWaybills', function () {
      const allowPrintAllWaybills = R.pathEq(['dispatchProcess', '_data', 'allowPrintAllWaybills'], true)(this);

      if (allowPrintAllWaybills) {
        return this.isPrinted;
      }

      return this.isDispatched && this.isPrinted;
    }),
    // toPrintList: computed('hidePrinted', 'toPrintList.[]', 'toPrintList.@each.synced', function () {
    //   const toPrintList = this.toPrintList || []
    //   return R.filter(modelProxy => {
    //     if (modelProxy?.model?._data?.isPrinted && this.hidePrinted) {
    //       return false
    //     }
    //     return true
    //   })(toPrintList)
    // }),
    printSubPanels: Ember.computed('isDispatched', 'crud.lists.dispatch-lists-print-all-sub-panels.[]', 'dispatchProcess._data.allowPrintAllWaybills', function () {
      const subPanels = R_.dotPath('crud.lists.dispatch-lists-print-all-sub-panels')(this) || [];
      const isDispatched = this.isDispatched;
      const allowPrintAllWaybills = R.pathEq(['dispatchProcess', '_data', 'allowPrintAllWaybills'], true)(this);

      if (isDispatched || allowPrintAllWaybills) {
        return subPanels;
      }

      return subPanels.filter(model => {
        const allowedPanels = ['pickingList', 'deliveryNotes'];
        return allowedPanels.includes(model?._data?.value);
      });
    }),
    updateBtnLabel: Ember.computed('nextDispatchProcessCode', function () {
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      let label = this.intl.t('update status');

      if (nextDispatchProcessCode) {
        const dispatchService = this.dispatch;
        const dispatchProcesses = this.dispatchProcesses || [];
        const nextDispatchProcess = dispatchService.findDispatchProcess(dispatchProcesses, nextDispatchProcessCode);

        if (nextDispatchProcess) {
          let name = nextDispatchProcess.getData('name');

          if (nextDispatchProcess.getData('translate')) {
            name = this.intl.t(name);
          }

          label = `${label}: ${name}`;
        }
      }

      return label;
    }),
    isDisableSetNextDispatchProcess: Ember.computed('nextDispatchProcessCode', 'dispatchProcess', function () {
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      const dispatchProcess = this.dispatchProcess;

      if (nextDispatchProcessCode && dispatchProcess.getData('code') !== nextDispatchProcessCode) {
        return false;
      }

      return true;
    }),
    disablePrintBtn: Ember.computed('toPrintList.@each.model._data.{waybill,transporterWaybillRecord}', 'printListWithTransporterWaybillRecord', 'printListCountTransporterWaybillRecord', 'lastPrintTimestamp', function () {
      // Should only allow to print if all documents contains waybills
      const allDocHaveWaybill = R.pipe(R.propOr([], 'toPrintList'), R.map(R.pathOr('', ['model', '_data', 'waybill'])), R.reject(RA.isNilOrEmpty), R.allPass([RA.isNotNilOrEmpty]))(this);

      if (allDocHaveWaybill) {
        return false;
      }

      return true;
    }),
    printCountOuterRegion: Ember.computed('toPrintListOuterRegion', function () {
      const toPrintListOuterRegion = R.pipe(R.prop('toPrintListOuterRegion'), R.length)(this);
      return toPrintListOuterRegion;
    }),
    toPrintListOuterRegion: Ember.computed('toPrintList.@each.model._data.transporterRegionIsOuter', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', 'lastPrintTimestamp', function () {
      const printCountOuterRegion = R.pipe(R.prop('toPrintList'), R.filter(R.pathEq(['model', '_data', 'transporterRegionIsOuter'], true)))(this);
      return printCountOuterRegion;
    }),
    setNextDispatchProcessTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      // const models = this.toPrintModelsList
      const dispatchService = this.dispatch;
      const filters = this.filters;
      const dispatchProcesses = this.dispatchProcesses || [];
      const dispatchProcess = this.dispatchProcess;
      const nextDispatchProcessCode = this.nextDispatchProcessCode;

      if (dispatchProcess.getData('code') !== nextDispatchProcessCode) {
        const nextDispatchProcess = dispatchService.findDispatchProcess(dispatchProcesses, nextDispatchProcessCode);

        if (nextDispatchProcess) {
          const filtersData = filters.serialize();
          const defaultFiltersData = R.mergeRight(filtersData, {
            matches: [],
            count: 5000,
            dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
            dispatchProcess: dispatchProcess.getData('code'),
            sort: filters.getData('sort')
          });

          if (this.period) {
            defaultFiltersData.dispatchDateZ = this.period.date;
          } else {
            defaultFiltersData.dispatchDateZ = '';
          }

          const unpaginatedFilters = this.search.setupFilters({
            adapterName: 'documents/dispatch/filters',
            defaultFiltersData
          });
          const results = yield this.get('dispatch.updateEntireDispatchProcessTask').perform(unpaginatedFilters, nextDispatchProcess);
          this.onRefresh(nextDispatchProcess);
          onCloseDialogAction();
          return results;
        }

        onCloseDialogAction();
      }
    }).drop(),
    selectSubTabTask: (0, _emberConcurrency.task)(function* (subTab, a, b) {
      if (subTab.getData('value') === 'hctSummary') {
        // fetch all unpaginated
        const allResults = yield this.onFetchUnpaginatedData({
          setResults: false,
          hidePrinted: false
        });
        const allUnpaginated = R.prop('docs')(allResults) || [];
        this.set('allUnpaginated', allUnpaginated);
      }

      return this.tabsManager.displaySubPanel(this, subTab);
    }),
    generateWaybillsTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        onPrintDialogAction,
        subTab,
        isNewWaybill = false,
        onlyFetch = false,
        onlyGenerate = false,
        onlyMissingTransporterWaybillRecord = false,
        onlyWithTransporterWaybillRecord = false,
        waybillsPerPage
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const isTCat = R.pipe(R.prop('value'), R.includes(_this.constantsService.transporterExtensions.tCat))(subTab);
        let provider = R.propOr('', 'value')(subTab);
        const isHct = R.pipe(R.propOr('', 'label'), R.includes(_this.constantsService.transporterExtensions.hct))(subTab);

        if (isHct) {
          provider = _this.constantsService.transporterExtensions.hct;
        }

        const isAllowTransporter = R.anyPass([R.includes(_this.constantsService.transporterExtensions.tCat), R.includes(_this.constantsService.transporterExtensions.hct), R.includes(_this.constantsService.transporterExtensions.sevenEleven)])(provider);
        let documents = _this.printContentResults;

        if (isTCat) {
          return _this.dispatch.tCatGenerateAndDownloadWaybillTask.perform({
            component: _this,
            documents,
            printType: _this.printType
          });
        } else if (isAllowTransporter) {
          if (onlyMissingTransporterWaybillRecord) {
            documents = _this.missingTransporterWaybillRecord;
          } else if (onlyWithTransporterWaybillRecord) {
            documents = _this.printListWithTransporterWaybillRecord;
          }

          const response = yield _this.dispatch.generateAndDownloadWaybillsTask.perform({
            component: _this,
            transporterExtension: provider,
            documents,
            isNewWaybill,
            onlyFetch,
            onlyGenerate,
            waybillsPerPage
          });

          _this.set('batchStatus', response);

          const lastPrintTimestamp = new Date().getTime();

          _this.set('lastPrintTimestamp', lastPrintTimestamp);

          return response;
        }

        if (subTab.value !== 'hctSummary') {
          _this.set('isPrinted', true);
        }

        if (onPrintDialogAction) {
          onPrintDialogAction(subTab);
        }
      }();
    }),

    setDocumentsBatchStatusAlwaysVisible(bool) {
      this.set('documentsBatchStatusAlwaysVisible', bool);
    },

    setBatchStatus(batchStatus) {
      this.set('batchStatus', batchStatus);
    },

    resetBatchStatus() {
      this.set('batchStatus', null);
    },

    setWaybillsPerPage(model) {
      const waybillsPerPage = R.pathOr(4, ['_data', 'value'])(model);
      this.set('waybillsPerPage', waybillsPerPage);
    },

    getShopeeDocSales(documentList) {
      if (!this.shopeeShopId) {
        return R.pipe(R.filter(R.pipe(R.pathOr('', ['model', '_data', 'thirdParty', 'shopee', '_data', 'shopId']), RA.isNotNilOrEmpty)))(documentList);
      }

      const docSalesByShopeeShopId = R.filter(R.pathEq(['model', '_data', 'thirdParty', 'shopee', '_data', 'shopId'], this.shopeeShopId))(documentList);

      if (this.shopeeData?.transporterMethod) {
        return R.filter(R.pipe(R.path(['model', '_data', 'shopeeData', 'transporter', 'transporterMethodAllow']), R.includes(this.shopeeData?.transporterMethod)))(docSalesByShopeeShopId);
      }

      return docSalesByShopeeShopId;
    },

    setShopeeShopId(model) {
      const shopeeShopId = model?._data?.shopId;
      this.set('shopeeShopId', shopeeShopId);
    },

    setShopeeLogisticsTransporter(model) {
      const shopeeLogisticsTransporters = model?._data?.value;
      this.set('shopeeData.transporterMethod', shopeeLogisticsTransporters);
    },

    setShopeeLogisticsTransporterAddressId(model) {
      this.set('shopeeData.transporterAddressId', model?.id);
    },

    setShopeeLogisticsTransporterBranchId(model) {
      this.resetShopeeData();
      this.set('shopeeData.transporterBranchId', model?.id);
    },

    setShopeeLogisticsTransporterSlug(model) {
      this.resetShopeeData();
      this.set('shopeeData.transporterSlug', model?.id);
    },

    setShopeeLogisticsSenderRealName() {
      this.resetShopeeData(['transporterSlug', 'transporterBranchId']);
    },

    resetShopeeData() {
      let omitKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      if (!omitKeys.length) {
        omitKeys = ['transporterSlug', 'transporterBranchId', 'senderRealName'];
      }

      const shopeeData = R.pipe(R.propOr({}, 'shopeeData'), R.omit(omitKeys))(this);
      this.set('shopeeData', shopeeData);
    },

    actions: {
      setPrintType(printType) {
        this.printType = R.pathOr(printType, ['_data', 'value'])(printType);
      },

      print(onPrintDialogAction, subTab) {
        let provider = R.propOr('', 'value')(subTab);

        if (subTab.value !== 'hctSummary') {
          this.set('isPrinted', true);
        }

        const generateWaybillData = {
          onPrintDialogAction,
          subTab
        };

        if (provider === this.constantsService.transporterExtensions.sevenEleven) {
          generateWaybillData.isNewWaybill = true;
          generateWaybillData.waybillsPerPage = this.waybillsPerPage;
        }

        this.generateWaybillsTask.perform(generateWaybillData);
        onPrintDialogAction(subTab);
      }

    },
    deliveryNotePrintFormat: Ember.computed('settings.settingsModel._data.deliveryNotePrintFormat', function () {
      return this?.settings?.settingsModel?._data?.deliveryNotePrintFormat || 'default';
    }),
    showUnit: Ember.computed('settings.settingsModel._data.showUnit', function () {
      return R.pathOr('showUnitBelowItems', ['settings', 'settingsModel', '_data', 'showUnit'])(this);
    }),
    showProductCode: Ember.computed('settings.settingsModel._data.showProductCode', function () {
      return R.pathOr('showCodeBelowItems', ['settings', 'settingsModel', '_data', 'showProductCode'])(this);
    }),
    isDeliveryNotesSubTab: Ember.computed('tab.subTabs.@each.isActive', function () {
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);

      if (activeSubTab.value === 'deliveryNotes') {
        return true;
      }

      return false;
    })
  }, (_applyDecoratedDescriptor(_obj, "setDocumentsBatchStatusAlwaysVisible", [_dec], Object.getOwnPropertyDescriptor(_obj, "setDocumentsBatchStatusAlwaysVisible"), _obj), _applyDecoratedDescriptor(_obj, "setBatchStatus", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setBatchStatus"), _obj), _applyDecoratedDescriptor(_obj, "resetBatchStatus", [_dec3], Object.getOwnPropertyDescriptor(_obj, "resetBatchStatus"), _obj), _applyDecoratedDescriptor(_obj, "setWaybillsPerPage", [_dec4], Object.getOwnPropertyDescriptor(_obj, "setWaybillsPerPage"), _obj), _applyDecoratedDescriptor(_obj, "getShopeeDocSales", [_dec5], Object.getOwnPropertyDescriptor(_obj, "getShopeeDocSales"), _obj), _applyDecoratedDescriptor(_obj, "setShopeeShopId", [_dec6], Object.getOwnPropertyDescriptor(_obj, "setShopeeShopId"), _obj), _applyDecoratedDescriptor(_obj, "setShopeeLogisticsTransporter", [_dec7], Object.getOwnPropertyDescriptor(_obj, "setShopeeLogisticsTransporter"), _obj), _applyDecoratedDescriptor(_obj, "setShopeeLogisticsTransporterAddressId", [_dec8], Object.getOwnPropertyDescriptor(_obj, "setShopeeLogisticsTransporterAddressId"), _obj), _applyDecoratedDescriptor(_obj, "setShopeeLogisticsTransporterBranchId", [_dec9], Object.getOwnPropertyDescriptor(_obj, "setShopeeLogisticsTransporterBranchId"), _obj), _applyDecoratedDescriptor(_obj, "setShopeeLogisticsTransporterSlug", [_dec10], Object.getOwnPropertyDescriptor(_obj, "setShopeeLogisticsTransporterSlug"), _obj), _applyDecoratedDescriptor(_obj, "setShopeeLogisticsSenderRealName", [_dec11], Object.getOwnPropertyDescriptor(_obj, "setShopeeLogisticsSenderRealName"), _obj), _applyDecoratedDescriptor(_obj, "resetShopeeData", [_dec12], Object.getOwnPropertyDescriptor(_obj, "resetShopeeData"), _obj)), _obj)));

  _exports.default = _default;
});