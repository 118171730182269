define("client/pods/components/ledgers/journals/cashbook/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kC1k4Mt2",
    "block": "{\"symbols\":[\"subTab\",\"@errors\",\"@tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__header-title\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"lists/list-dropdown\",null,[[\"isBtn\",\"readonly\",\"classNames\",\"list\",\"itemValueKey\",\"itemLabelKey\",\"value\",\"addItemComponent\",\"onSelect\"],[true,[24,[\"isReadonly\"]],\"list-dropdown u-tappable-flex-start\",[23,0,[\"accountsListsBanksWithAll\"]],\"_data._key\",\"_data.name\",[24,[\"selectedBankAccountKey\"]],\"ledgers/accounts/banks-editor\",[28,\"fn\",[[23,0,[\"setBankAccount\"]]],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__sidebar u-results\"],[8],[0,\"\\n\\t\\t\"],[5,\"menus/tab-menu\",[],[[\"@classNames\",\"@errors\",\"@itemClassNames\",\"@menu\",\"@onSelect\",\"@tabs\"],[\"list-results u-results__list\",[23,2,[]],\"u-results__item\",[23,0,[\"crud\",\"lists\",\"ledgers-journals-cashbook-lists-menu\"]],[28,\"fn\",[[23,0,[\"tabsManager\",\"displaySubPanel\"]],[23,0,[]]],null],[23,3,[\"subTabs\"]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__content u-section\"],[8],[0,\"\\n\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[23,3,[\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tabParent\",\"tab\",\"account\",\"accountKey\",\"onReloadAccountTask\"],[[23,3,[]],[23,1,[]],[23,0,[\"account\"]],[23,0,[\"selectedBankAccountKey\"]],[24,[\"fetchAccountTask\"]]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/ledgers/journals/cashbook/tab-container/template.hbs"
    }
  });

  _exports.default = _default;
});