define("client/pods/components/contacts/panels-display/info-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: '',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['taxes', 'statuses', 'contacts/lists/relationships', 'contacts/lists/tags', 'contacts/lists/panels']);
    }

  });

  _exports.default = _default;
});