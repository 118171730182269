define("client/pods/components/img-banner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VB9sIOuH",
    "block": "{\"symbols\":[\"@isReadonly\",\"@context\",\"@errors\",\"@label\",\"@model\",\"@tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex-container-column flex-container--align-items-start\"],[8],[9],[0,\"\\n\"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@errors\",\"@formInputClassNames\",\"@label\",\"@overrideFormInputClassNames\"],[[23,2,[]],\"websites\",[23,3,[]],\"flex-container-column flex-container--align-items-start\",[23,4,[]],true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"imageUuid\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"slider__products-wrapper\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card slider__products-card\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"slider__products-image\"],[8],[0,\"\\n          \"],[5,\"files/image-item\",[],[[\"@height\",\"@image\",\"@transform\",\"@width\"],[[23,0,[\"height\"]],[23,0,[\"imageUuid\"]],[23,0,[\"transform\"]],[23,0,[\"width\"]]]]],[0,\"\\n        \"],[9],[0,\"\\n\"],[4,\"unless\",[[23,1,[]]],null,{\"statements\":[[0,\"          \"],[7,\"a\",false],[12,\"class\",\"delete is-medium bigordr-slider-image-delete-btn\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"removeBanner\"]]],null]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[5,\"files-manager\",[],[[\"@allowMultipleItems\",\"@allowRemove\",\"@hideFilesGallery\",\"@model\",\"@onSubmit\",\"@resetSubTab\",\"@resource\",\"@resourceKey\",\"@showDefaultImagePreview\",\"@tab\",\"@isReadonly\"],[false,true,true,[23,5,[]],[23,0,[\"onSubmit\"]],true,\"websites\",[23,5,[\"_data\",\"_key\"]],true,[23,6,[]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/img-banner/template.hbs"
    }
  });

  _exports.default = _default;
});