define("client/pods/components/admin/payments/card/tab-container/component", ["exports", "client/mixins/payments-list-by", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _paymentsListBy, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, _paymentsListBy.default, {
    crud: Ember.inject.service(),
    adapterName: 'admin/payments/card',
    resultsToggle: false,
    query: '',

    init() {
      this._super(...arguments);

      this.set('results', []);
      this.crud.addLists(this, ['admin/payments/card/lists/panels', 'payments/card/lists/providers']);
      const provider = 'tapPay';
      this.set('selectedProvider', provider);
    },

    reApplyVirtualAccountsTask: (0, _emberConcurrency.task)(function* () {
      if (window.confirm('are you sure you want to re-apply virtual account web hook requests?')) {
        yield this.server.call('POST', 'api/protected/admin/payments/card/virtual-accounts/webhooks/reapply', {});
      }
    }).drop(),
    actions: {
      displayPanel(resource) {
        const tab = this.tab;
        const subTabOptions = {
          component: resource.getData('component')
        };
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      }

    }
  });

  _exports.default = _default;
});