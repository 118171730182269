define("client/pods/components/channels/shop/promo-codes/panels-new/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    adapterName: 'channels/shop/promoCodes',
    init: function () {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      if (!this.model) {
        const adapterName = this.adapterName;
        const model = this.crud.setupNewRecord({
          adapterName
        });
        this.set('model', model);
      }
    }

  });

  _exports.default = _default;
});