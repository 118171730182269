define("client/pods/components/reports/dashboard/card-page-views/card-conversions/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isDate: false,
    roundingCurrency: 0,

    getExcl(param, type) {
      return this.get(`${param}.${type}`);
    },

    getIncl(param, type) {
      return this.get(`${param}.${type}`);
    },

    getValue(param, exclParam, inclParam) {
      return this.isTaxExclusive ? this.getExcl(param, exclParam) : this.getIncl(param, inclParam);
    },

    averageItemPrice: Ember.computed('detail.average.{exclPrice,inclPrice}', 'isTaxExclusive', function () {
      let value = this.getValue('detail.average', 'postDiscExclPrice', 'postDiscInclPrice');

      if (RA.isNilOrEmpty(value)) {
        value = this.getValue('detail.average', 'exclPrice', 'inclPrice');
      }

      return value;
    }),
    averageOrderValue: Ember.computed('detail.average.{exclTotal,inclTotal}', 'isTaxExclusive', function () {
      let value = this.getValue('detail.average', 'postDiscExclTotal', 'postDiscInclTotal');

      if (RA.isNilOrEmpty(value)) {
        value = this.getValue('detail.average', 'exclTotal', 'inclTotal');
      }

      return value;
    }),
    amount: Ember.computed('detail.{exclAmt,inclAmt}', 'isTaxExclusive', function () {
      let value = this.getValue('detail', 'postDiscExclAmt', 'postDiscInclAmt');

      if (RA.isNilOrEmpty(value)) {
        value = this.getValue('detail', 'exclAmt', 'inclAmt');
      }

      return value;
    }),
    choiceDetailsProxy: Ember.computed('detail.choiceDetails.[]', 'isTaxExclusive', function () {
      const choiceDetails = this.detail.choiceDetails || [];
      return R.map(choiceDetail => {
        if (this.isTaxExclusive) {
          choiceDetail.amount = choiceDetail.exclAmt;
        } else {
          choiceDetail.amount = choiceDetail.inclAmt;
        }

        return choiceDetail;
      })(choiceDetails);
    })
  });

  _exports.default = _default;
});