define("client/pods/components/faqs/table-view/faqs-row/component", ["exports", "client/mixins/crud", "client/mixins/dispatch", "client/mixins/transporter", "ramda"], function (_exports, _crud, _dispatch, _transporter, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _dispatch.default, _transporter.default, {
    dragAndDrop: Ember.inject.service(),
    tagName: '',
    showAllDetails: true,

    init() {
      this._super(...arguments);

      if (this.isFilters) {
        const campaignFilters = this.search.setupFilters({
          adapterName: 'channels/shop/campaigns/filters'
        });
        this.set('campaignFilters', campaignFilters);
      }

      if (this.model) {
        const modelProxy = this.model;
        this.set('modelProxy', modelProxy);
        this.set('model', modelProxy.get('model'));
      }
    },

    faqComputed: Ember.computed('model.synced', 'model._data.faqs.[]', 'currentIndex', function () {
      const faqs = R.path(['model', '_data', 'faqs'])(this) || [];
      return faqs[this.currentIndex] || '';
    }),
    actions: {
      select() {
        if (this.onMainAction) {
          this.onMainAction(...arguments);
        }
      },

      toggleAllDetails() {
        let showAllDetails = this.showAllDetails;
        showAllDetails = !showAllDetails;
        this.set('showAllDetails', showAllDetails);
        const results = this.results;
        results.forEach(modelProxy => {
          modelProxy.set('showDetails', showAllDetails);
        });
      }

    }
  });

  _exports.default = _default;
});