define("client/pods/big/service", ["exports", "client/utils/nventor", "ramda", "ramda-extension"], function (_exports, _nventor, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BigService extends Ember.Service {
    constructor() {
      super(...arguments);

      _defineProperty(this, "toFixed", R.curry((rounding, bigNumber) => {
        bigNumber = this.newBig(bigNumber);

        if (rounding == null) {
          rounding = 8;
        }

        rounding = parseInt(rounding);
        return bigNumber.toFixed(rounding);
      }));

      _defineProperty(this, "autoRound", R.curry((rounding, bigNumber) => {
        bigNumber = this.newBig(bigNumber);

        if (rounding === 0) {
          return this.toFixed(rounding, bigNumber);
        }

        return R.pipe(this.toFixed(rounding), R.dropLastWhile(x => x === '0'), R.dropLastWhile(x => x === '.'))(bigNumber);
      }));

      _defineProperty(this, "sumArray", R.reduce((accumulator, amt) => accumulator.plus(amt), new Big(0)));

      _defineProperty(this, "sumPropAndAutoRound", R.curry((rounding, dotPath, arrayOfObjects) => {
        arrayOfObjects = arrayOfObjects || [];
        return R.pipe(R.map(R_.dotPath(dotPath)), R.reject(_nventor.default.isNilOrEmpty), this.sumArray, this.autoRound(rounding))(arrayOfObjects);
      }));

      _defineProperty(this, "sumPropAndRound", R.curry((rounding, dotPath, arrayOfObjects) => {
        arrayOfObjects = arrayOfObjects || [];
        return R.pipe(R.map(R_.dotPath(dotPath)), R.reject(_nventor.default.isNilOrEmpty), this.sumArray, this.toFixed(rounding))(arrayOfObjects);
      }));

      _defineProperty(this, "sumProp", R.curry((dotPath, arrayOfObjects) => {
        arrayOfObjects = arrayOfObjects || [];
        return R.pipe(R.map(R_.dotPath(dotPath)), R.reject(_nventor.default.isNilOrEmpty), this.sumArray)(arrayOfObjects);
      }));

      _defineProperty(this, "eq", R.curry((value, bigNumber) => {
        bigNumber = this.newBig(bigNumber);
        return bigNumber.eq(value);
      }));
    }

    /**
     * create new big number
     * @param  {string|integer|float} number
     * @return {class} big number class instance
     */
    newBig(number) {
      number = parseFloat(number) || 0;
      return new Big(number || 0);
    }
    /**
     * create new big number from object's property value
     * @param  {string} property name
     * @return {class} big number class instance
     */


    getBig(prop) {
      var number = parseFloat(this.get(prop));
      return new Big(number || 0);
    }
    /**
     * create new big number from object's _data.property value
     * @param  {string} property name
     * @return {class} big number class instance
     */


    getDataBig(prop, model) {
      var number = parseFloat(model.getData(prop));
      return new Big(number || 0);
    }

  }

  _exports.default = BigService;
});