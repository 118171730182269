define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/is-set/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HPEN0SFF",
    "block": "{\"symbols\":[\"child\",\"index\",\"@fetchPriceHistoryTask\",\"@campaignOrAutomationModel\",\"@automationModel\",\"@campaign\"],\"statements\":[[4,\"forms/form-section\",null,null,{\"statements\":[[0,\"\\t\"],[7,\"table\",true],[10,\"class\",\"table-form table-form--no-last-border u-full-width table-form__td--padding-8\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"channels/shop/campaigns/campaigns-item-editor/is-set/is-set-detail\",null,[[\"tab\",\"hasSalesPersons\",\"isHeaders\"],[[24,[\"tab\"]],[24,[\"hasSalesPersons\"]],true]]],false],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"item\",\"_data\",\"setDetails\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"channels/shop/campaigns/campaigns-item-editor/is-set/is-set-detail\",null,[[\"tab\",\"campaign\",\"automationModel\",\"campaignOrAutomationModel\",\"parentModel\",\"item\",\"isSetPriceOnBundle\",\"isReadonly\",\"hasSalesPersons\",\"child\",\"fetchPriceHistoryTask\"],[[24,[\"tab\"]],[23,6,[]],[23,5,[]],[23,4,[]],[24,[\"parentModel\"]],[24,[\"item\"]],[24,[\"isSetPriceOnBundle\"]],[24,[\"isReadonly\"]],[24,[\"hasSalesPersons\"]],[23,1,[]],[23,3,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-item-editor/is-set/template.hbs"
    }
  });

  _exports.default = _default;
});