define("client/pods/components/accordion/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "apciYjl6",
    "block": "{\"symbols\":[\"&default\",\"@accordionPanelClassNames\",\"@label\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"card accordion-panel \",[28,\"if\",[[23,0,[\"isActive\"]],\"accordion-panel-active\"],null],\" \",[23,2,[]]]]],[8],[0,\"\\n  \"],[7,\"a\",false],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"toggleIsActive\"]]],null]]],[8],[0,\"\\n    \"],[7,\"header\",true],[10,\"class\",\"card-header\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"card-header-title\"],[8],[1,[28,\"tt\",[[23,3,[]]],null],false],[9],[0,\"\\n      \"],[7,\"a\",true],[10,\"class\",\"card-header-icon\"],[10,\"aria-label\",\"more options\"],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"icon\"],[8],[0,\"\\n            \"],[7,\"i\",true],[11,\"class\",[29,[\"fas \",[28,\"if\",[[23,0,[\"isActive\"]],\"fa-angle-up\",\"fa-angle-down\"],null]]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n          \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card-content\"],[10,\"style\",\"padding-top: 5px\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isActive\"]]],null,{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/accordion/template.hbs"
    }
  });

  _exports.default = _default;
});