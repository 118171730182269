define("client/pods/documents/lists/import/paid/specs/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    async findAll() {
      return [{
        headerValue: '',
        value: '',
        label: 'none'
      }, {
        headerValue: '帳號',
        value: 'paymentLastFiveDigits',
        label: 'payment last 5 digits',
        required: true
      }, {
        headerValue: '存',
        value: 'inclTotal',
        label: 'inclTotal',
        required: true
      }];
    }

  });

  _exports.default = _default;
});