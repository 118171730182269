define("client/pods/components/dispatch/panels-display/list-summary/summary-item/component", ["exports", "ramda", "client/mixins/big"], function (_exports, R, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_big.default, {
    tagName: '',
    selectedProcesses: null,
    product: Ember.computed('productsList.[]', 'item.itemKey', function () {
      const productsList = this.productsList || [];
      const itemKey = this.get('item.itemKey');
      return R.find(R.pathEq(['inventory', '_data', 'itemKey'], itemKey), productsList);
    }),
    totalQty: Ember.computed('item', 'product', 'allowedDispatchProcessesCodes.[]', function () {
      const item = this.item;
      const product = this.product;
      const allowedDispatchProcessesCodes = this.allowedDispatchProcessesCodes || [];
      const qtys = R.pipe(R.pluck('qty'), R.mapObjIndexed((qty, dispatchProcessCode) => {
        if (R.includes(dispatchProcessCode, allowedDispatchProcessesCodes)) {
          return qty;
        }

        return 0;
      }), R.values, R.reject(R.isNil))(item);
      const sumBig = this.sumArray(qtys);
      const roundingInventoryQty = this.settings.getProp('roundingInventoryQty', 0, product);
      return sumBig.toFixed(roundingInventoryQty);
    })
  });

  _exports.default = _default;
});