define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/bulk-discount-conflicts/bulk-discount-conflicts-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KCrshVIK",
    "block": "{\"symbols\":[\"@updateCampaignDetailBulkDiscountTask\",\"@bulkDiscount\"],\"statements\":[[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,1,[\"isRunning\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"td\",false],[12,\"class\",[29,[\"documents__conflict-option u-container \",[28,\"if\",[[23,2,[\"_data\",\"isCurrent\"]],\"is-selected\"],null]]]],[3,\"on\",[\"click\",[28,\"perform\",[[23,0,[\"chooseBulkDiscountTask\"]],[23,2,[\"_data\",\"bulkDiscountId\"]]],null]]],[8],[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"if\",[[23,2,[\"_data\",\"isCurrent\"]],\" has-text-link \"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"_data\",\"isCurrent\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"tt\",[\"current bulk discount\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"tt\",[\"new bulk discount\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"u-desc\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"_data\",\"isRemove\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"tt\",[\"remove bulk discount\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[23,0,[\"currentBulkDiscountName\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-conflicts-dialog/bulk-discount-conflicts/bulk-discount-conflicts-item/template.hbs"
    }
  });

  _exports.default = _default;
});