define("client/pods/automations/when/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/constants", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _constants, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: _constants.default.automationsWhen.orderValue,
        label: 'order value',
        component: 'channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/order-value'
      }, {
        value: _constants.default.automationsWhen.itemQty,
        label: 'item quantity',
        component: 'channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/item-qty'
      }, {
        value: _constants.default.automationsWhen.itemValue,
        label: 'item value',
        type: 'value',
        component: 'channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/item-value'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});