define("client/pods/components/products/tab-container/component", ["exports", "client/mixins/crud", "ember-concurrency", "client/mixins/products-list-by", "client/utils/nventor"], function (_exports, _crud, _emberConcurrency, _productsListBy, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, _productsListBy.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    settingsService: Ember.inject.service('settings'),
    results: null,
    resultsToggle: false,
    showFilters: true,
    adapterName: 'products',
    filtersAdapterName: 'products/filters',

    init() {
      this.crud.addLists(this, [{
        name: 'taxes',
        appendPath: 'products'
      }, 'filters/count', 'filters/criterias', 'products/lists/menu', 'products/inventory/lists/qtyTypes', 'products/lists/invoiceDescriptions', 'products/lists/tags', 'products/lists/display-tags', 'products/lists/product-display-position', 'products/lists/tracking', 'products/lists/types', 'products/lists/setTypes', 'products/lists/setTypesFilters', 'settings/roundingAuto', 'statuses', 'toggles/yesNo', 'units', 'units/dimensions', 'units/weight']);
      const hideVariantChildrenInProductsSearch = this.settingsService.getProp('hideVariantChildrenInProductsSearch') || false;
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          fetchWithActiveCampaigns: true,
          hideVariantChildrenInProductsSearch
        }
      });
      this.set('filters', filters);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments);

      this.loadTabTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const model = loadInstructions.getData('model');

        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.findTask.perform({
            appendPath: id
          });
          this.display(found);
        }
      } else {
        const adapterName = this.adapterName;
        const filters = this.filters;
        const results = yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
        this.set('results', results);
        this.dash({}, results);
      }
    }),

    dash(model, results) {
      this.set('allowQuickAdd', true);
      this.set('showResults', false);
      this.set('showFilters', true);
      const tab = this.tab;

      if (_nventor.default.isNilOrEmpty(results)) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        filters.setData('query', '');
        results = this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
        this.set('results', results);
      }

      this.set('newModel', false);
      const subTabOptions = {
        component: 'products/panels-dash'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    /**
     * display the product
     * @param  {object} product model
     */
    display(model) {
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.set('allowQuickAdd', false);
      this.set('showFilters', true);
      const browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (browserWidth < 1300) {
        this.set('showResults', false);
      }

      const tab = this.tab; // tabOptions = tabOptions || this.tabsManager.getDefaultLoadInstructions(tab)

      const panelOptions = {
        component: 'products/panels-display',
        model: model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
    },

    importTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, importData) {
      const payload = {
        batch: importData
      };
      yield this.server.call('POST', 'api/protected/products/batch', payload);
      onCloseDialogAction();
    }).drop(),

    new(subTabOptions) {
      this.set('showFilters', false);
      this.set('allowQuickAdd', false);
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    inventory(subTabOptions) {
      // create tab within product tab
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    toggleResults(results) {
      const subTab = this.tabsManager.getActiveSubTab(this.tab);

      if (subTab?.component === 'products/panels-dash') {
        return results;
      }

      Ember.set(this, 'showResults', true);
      return results;
    },

    quickAddNew() {
      const name = this.name || '';

      const resourceKeyTemp = _nventor.default.random.alphaNum(8);

      const data = {
        name,
        resourceKeyTemp
      }; // setup new products model

      const model = this.crud.setupNewRecord({
        adapterName: 'products',
        data
      });
      this.set('newModel', model);
    },

    afterQuickAddNew() {
      this.quickAddNew();
      const adapterName = this.adapterName;
      const filters = this.filters;
      filters.setData('query', '');
      const results = this.fetchDefaultDataTask.perform({
        adapterName,
        filters
      });
      this.set('results', results);
    },

    edit(model) {
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.set('showFilters', false);
      this.set('allowQuickAdd', false);
      const tab = this.tab;
      const panelOptions = {
        component: 'products/panels-edit',
        model: model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
    },

    actions: {
      dash() {
        this.dash();
      },

      display(model) {
        let {
          tabOptions
        } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        if (model) {
          this.display(...arguments);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },

      inventory(subTabOptions) {
        // create tab within product tab
        this.inventory(subTabOptions);
      },

      new(subTabOptions) {
        // create tab within product tab
        this.new(subTabOptions);
      },

      import(subTabOptions) {
        const tab = this.tab;
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      },

      quickAddNew() {
        this.quickAddNew(...arguments);
      },

      afterQuickAddNew() {
        this.afterQuickAddNew(...arguments);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "dash", [_dec], Object.getOwnPropertyDescriptor(_obj, "dash"), _obj), _applyDecoratedDescriptor(_obj, "toggleResults", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleResults"), _obj), _applyDecoratedDescriptor(_obj, "quickAddNew", [_dec3], Object.getOwnPropertyDescriptor(_obj, "quickAddNew"), _obj), _applyDecoratedDescriptor(_obj, "edit", [_dec4], Object.getOwnPropertyDescriptor(_obj, "edit"), _obj)), _obj)));

  _exports.default = _default;
});