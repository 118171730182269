define("client/pods/components/barcode/modal/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BarcodeGeneratorComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, (_class = class BarcodeGeneratorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "barcodeCanvas", _descriptor2, this);

      _defineProperty(this, "labelLengthDefault", 15);

      this.crud.addLists(this, ['barcodes']);
    }

    get badgeCount() {
      const models = this.args.models || [];
      const modelsWithBarcode = R.pipe(R.filter(R.allPass([R.pipe(R.pathOr('', ['_data', 'barcode']), RA.isNotNilOrEmpty), R.pipe(R.pathOr('', ['_data', 'barcodeType']), RA.isNotNilOrEmpty)])), R.length)(models);
      return modelsWithBarcode || '';
    }

    get barcodeItemLabel() {
      return '';
      const modelData = this.args?.model?._data || {};
      const name = R.pipe(R.pathOr('', ['name']), R.take(4))(modelData);
      const price = R.pathOr('0', ['price'])(modelData);
      return `${name} - NT ${price}`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "barcodeCanvas", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BarcodeGeneratorComponent;
});