define("client/pods/components/settings/tab-container/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    adapterName: 'settings',
    query: '',

    init() {
      this.crud.addLists(this, ['taxes', 'documents/lists/print']);

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.fetchAndDisplayTask.perform();
    },

    fetchAndDisplayTask: (0, _emberConcurrency.task)(function* () {
      const model = yield this.crud.findTask.perform({
        adapterName: this.adapterName
      });
      this.display(model);
    }),

    edit(model) {
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const tab = this.tab;
      const subTabOptions = {
        component: 'settings/panels-edit',
        model
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions, tabOptions);
    },

    display(model) {
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const tab = this.tab;
      const subTabOptions = {
        component: 'settings/panels-display',
        model
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions, tabOptions);
    }

  }, (_applyDecoratedDescriptor(_obj, "edit", [_dec], Object.getOwnPropertyDescriptor(_obj, "edit"), _obj), _applyDecoratedDescriptor(_obj, "display", [_dec2], Object.getOwnPropertyDescriptor(_obj, "display"), _obj)), _obj)));

  _exports.default = _default;
});