define("client/pods/components/channels/shop/promo-codes/panels-dash/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    search: Ember.inject.service(),
    crud: Ember.inject.service(),
    filtersAdapterName: 'products/filters',
    loadPrintDataTask: (0, _emberConcurrency.task)(function* () {
      // const filters = this.filters
      const toPrintFilters = this.search.copyFilters({
        filters: this.filters,
        adapterName: this.filtersAdapterName,
        altStorageProp: 'toPrintResults',
        defaultFiltersData: {
          types: ['inventory']
        }
      }); // toPrintFilters.setData('count', 30)

      const lastSearchSettingsProperty = this.filters.get('lastSearchSettingsProperty');
      const lastSearchSettings = this.filters.get(lastSearchSettingsProperty);
      toPrintFilters.set(lastSearchSettingsProperty, lastSearchSettings);
      this.set('toPrintFilters', toPrintFilters);
      const toPrintResults = yield this.crud.reloadSearchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: toPrintFilters
      });
      this.set('toPrintResults', toPrintResults);
    })
  });

  _exports.default = _default;
});