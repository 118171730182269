define("client/pods/channels/shop/campaigns/price-filter/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChannelsShopCampaignsPriceFilterModel extends _model.default {
    constructor() {
      super(...arguments);
      this.requiredAttrs = ['min', 'max'];
    }

  }

  _exports.default = ChannelsShopCampaignsPriceFilterModel;
});