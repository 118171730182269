define("client/pods/notifications/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/notifications/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: '',
    modelObj: _model.default,
    idParam: '_id',

    find() {
      const data = {
        _id: 'notifications',
        alerts: []
      };
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});