define("client/pods/excel/service", ["exports", "client/utils/nventor", "client/config/environment", "ramda", "ramda-adjunct"], function (_exports, _nventor, _environment, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),
    settings: Ember.inject.service(),

    _convertToArray(workbook) {
      const getColumns = sheetData => {
        return R.pipe(R.keys, R.map(_nventor.default.withoutNumbers), R.uniq)(sheetData);
      };

      const getMaxRows = sheetData => {
        return R.pipe(R.keys, R.map(_nventor.default.withoutAlpha), _nventor.default.maxInList)(sheetData);
      };

      const getCellValue = (sheetData, column, row) => {
        // get row number
        const cellData = sheetData[`${column}${row}`];
        return R.propOr('', 'v')(cellData);
      };

      const convertToRowToArray = (maxRows, sheetData) => {
        const columns = getColumns(sheetData);
        const rows = [];
        R.times(i => {
          const row = i + 1;
          const rowData = R.reduce((acc, column) => {
            acc.push(getCellValue(sheetData, column, row));
            return acc;
          }, [], columns);
          rows.push(rowData);
        })(maxRows);
        return rows;
      };

      return R.pipe(R.propOr({}, 'Sheets'), R.mapObjIndexed(sheetData => {
        sheetData = R.omit(['!ref', '!margins'])(sheetData);
        const maxRows = getMaxRows(sheetData);
        return convertToRowToArray(maxRows, sheetData);
      }))(workbook);
    },

    async readFile(file) {
      const reader = new window.FileReader();
      return new Ember.RSVP.Promise((resolve, reject) => {
        try {
          reader.onload = e => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, {
              type: 'array'
            });

            const sheets = this._convertToArray(workbook);

            resolve(sheets);
          };

          reader.readAsArrayBuffer(file);
        } catch (e) {
          reject(e);
        }
      });
    },

    /**
     *
     * @param {array} worksheets: [{
     *  sheetName: string,
     *  data: array [ [col1, col2, ...], [1, 2, ...] ]
     * }]
     */
    exportFile(_ref) {
      let {
        worksheets,
        title,
        subject,
        author,
        bookType = 'xlsx',
        bookTypeLabel = 'xlsx'
      } = _ref;

      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);

        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;

        return buf;
      }

      try {
        const workBook = XLSX.utils.book_new();
        workBook.Props = {
          Title: title,
          Subject: subject || title,
          Author: author || this.auth.getUserProp('name'),
          CreatedDate: new Date()
        };
        R.forEach(_ref2 => {
          let {
            sheetName,
            data
          } = _ref2;
          // duplicate checker
          const re = new RegExp('\b' + sheetName + '\b');
          let count = 0;
          R.forEach(sheet => {
            const matchBool = R.match(re, sheet);

            if (RA.isNotNilOrEmpty(matchBool)) {
              count++;
            }
          })(workBook.SheetNames);

          if (count > 0) {
            const newSheetName = R.concat(sheetName, `(${count})`);
            workBook.SheetNames.push(newSheetName);
          } else {
            workBook.SheetNames.push(sheetName);
          }

          const worksheet = XLSX.utils.aoa_to_sheet(data);
          const objectMaxLength = [];
          data.forEach(arr => {
            arr.forEach((value, key) => {
              let len = 10;

              switch (R.type(value)) {
                case 'Number':
                  len = 10;
                  break;

                case 'String':
                  len = value.length >= 10 ? value.length : value.length + len;
                  break;

                case 'Object':
                  if (value instanceof Date) len = 10;
                  break;
              }

              if (RA.isNilOrEmpty(objectMaxLength[key])) {
                objectMaxLength[key] = len;
              }

              objectMaxLength[key] = Math.max(objectMaxLength[key], len);
            });
          });
          const worksheetCols = objectMaxLength.map(width => {
            return {
              width
            };
          });
          worksheet['!cols'] = worksheetCols;
          workBook.Sheets[sheetName] = worksheet;
        }, worksheets);
        const workBookExport = XLSX.write(workBook, {
          bookType,
          type: 'binary'
        });
        return saveAs(new Blob([s2ab(workBookExport)], {
          type: 'application/octet-stream'
        }), `${title}.${bookTypeLabel}`);
      } catch (err) {
        if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {
          console.log(err);
          throw err;
        }
      }
    }

  });

  _exports.default = _default;
});