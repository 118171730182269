define("client/pods/components/settings/settings-editor/shipping-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['channels/shop/campaigns/lists/locations', 'channels/shop/shipping-zones/all', 'extensions/apps/tw/convenience-stores/lists/brands']);
    },

    openTab() {
      this.tabsManager.openTab('left', {
        component: 'channels/shop/shipping-zones/tab-container',
        title: 'shipping zones'
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "openTab", [_dec], Object.getOwnPropertyDescriptor(_obj, "openTab"), _obj)), _obj)));

  _exports.default = _default;
});