define("client/pods/swatches/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/swatches/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/swatches',
    modelObj: _model.default,
    idParam: '_id',

    async findAll() {
      const defaults = [{
        _id: '1',
        color: '#F6F5F5',
        isDefault: true
      }, {
        _id: '2',
        color: '#145374',
        isDefault: true
      }, {
        _id: '3',
        color: '#00334E',
        isDefault: true
      }, {
        _id: '4',
        color: '#EE6F57',
        isDefault: true
      }, {
        _id: '5',
        color: '#206A5D',
        isDefault: true
      }, {
        _id: '6',
        color: '#81B214',
        isDefault: true
      }, {
        _id: '7',
        color: '#BFDCAE',
        isDefault: true
      }, {
        _id: '8',
        color: '#F1F1E8',
        isDefault: true
      }, {
        _id: '9',
        color: '#1B262C',
        isDefault: true
      }, {
        _id: '10',
        color: '#0F4C75',
        isDefault: true
      }, {
        _id: '11',
        color: '#00B7C2',
        isDefault: true
      }, {
        _id: '12',
        color: '#FDCB9E',
        isDefault: true
      }, {
        _id: '13',
        color: '#B0CAC7',
        isDefault: true
      }, {
        _id: '14',
        color: '#F7D6BF',
        isDefault: true
      }];
      const list = await this._super(...arguments);
      return R.concat(defaults, list);
    }

  });

  _exports.default = _default;
});