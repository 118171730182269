define("client/pods/components/contacts/panels-display/sub-panels-member-levels/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactsPanelsDisplaySubPanelsMemberLevelsComponent = (_dec = Ember.inject.service('date'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class ContactsPanelsDisplaySubPanelsMemberLevelsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dateService", _descriptor, this);

      _initializerDefineProperty(this, "constants", _descriptor2, this);

      _initializerDefineProperty(this, "crud", _descriptor3, this);

      _initializerDefineProperty(this, "contactRewardData", _descriptor4, this);

      _initializerDefineProperty(this, "isShowHistory", _descriptor5, this);

      _initializerDefineProperty(this, "selectedMonths", _descriptor6, this);
    }

    fetchContactRewardHistoryTask(_ref) {
      var _this = this;

      let {
        rewardKey
      } = _ref;
      return function* () {
        if (RA.isNotNilOrEmpty(rewardKey)) {
          const model = _this.args.model;
          const contactKey = model.getData('_key');
          let startDateZ = '';
          let endDateZ = '';
          const selectedMonths = _this.selectedMonths;

          if (parseInt(selectedMonths) > 0) {
            startDateZ = _this.dateService.getMoment().subtract(selectedMonths, 'months').toISOString();
            endDateZ = _this.dateService.getMoment().toISOString();
          }

          const contact = yield _this.crud.findTask.perform({
            adapterName: 'contacts/rewards',
            appendPath: contactKey,
            params: {
              rewardKey,
              startDateZ,
              endDateZ
            }
          });
          const contactRewardsData = contact.getData('pointsData') || {};
          const selectedRewardKey = R.propOr('', 'rewardKey')(contactRewardsData);
          const currentPoints = R.propOr(0, 'validPoints')(contactRewardsData);
          let rewardHistory = R.propOr([], 'rewardHistory')(contactRewardsData);
          const nextExpirePoints = R.find(R.propEq('isExpireSoon', true))(rewardHistory);
          const openingPoints = R.find(R.propEq('isOpening', true))(rewardHistory);
          rewardHistory = R.reject(R.propEq('isOpening', true))(rewardHistory);
          rewardHistory = R.map(contactRewardsData => {
            if (contactRewardsData.externalId) {
              contactRewardsData.ref = R.pipe(R.propOr('', 'externalId'), R.toUpper, R.splitEvery(4), R.join('-'))(contactRewardsData);
            }

            return contactRewardsData;
          })(rewardHistory);
          model.set('rewardHistory', rewardHistory);
          model.set('nextExpirePoints', nextExpirePoints);
          model.set('openingPoints', openingPoints);
          model.set('currentPoints', currentPoints);
          model.set('selectedRewardKey', selectedRewardKey);
        }
      }();
    }

    onSubmitShowRewardHistory(rewardKey) {
      Ember.set(this, 'isShowHistory', true);
      this.fetchContactRewardHistoryTask.perform({
        rewardKey
      });
    }

    onSubmitGetRewardHistory() {
      const model = this.args.model;
      this.fetchContactRewardHistoryTask.perform({
        rewardKey: model.get('selectedRewardKey')
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contactRewardData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isShowHistory", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedMonths", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 3;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchContactRewardHistoryTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchContactRewardHistoryTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitShowRewardHistory", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitShowRewardHistory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitGetRewardHistory", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitGetRewardHistory"), _class.prototype)), _class));
  _exports.default = ContactsPanelsDisplaySubPanelsMemberLevelsComponent;
});