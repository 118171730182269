define("client/pods/flows/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/flows/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../../utils/nventor'
  var _default = _adapter.default.extend(_adaptersNew.default, {
    applicationService: Ember.inject.service('application'),
    resourcePath: 'api/protected/flows',
    modelObj: _model.default,
    idParam: '_id',

    serialize(model) {
      const data = this._super(model);

      data.flowsCreatedEmailCopyTo = this.applicationService.splitToArray(data.flowsCreatedEmailCopyTo);
      data.steps = R.map(step => {
        const type = step.type;
        const actionType = step.actionType;

        if (R.equals(type, 'ACTION') && R.equals(actionType, 'generatePromoCode')) {
          const defaults = R.pathOr({}, ['actionData', 'defaults'])(step);
          defaults.emails = this.applicationService.splitToArray(defaults.emails || '');
          defaults.telephones = this.applicationService.splitToArray(defaults.telephones || '');
        }

        return step;
      })(data.steps || []);
      return data;
    }

  });

  _exports.default = _default;
});