define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/item-thumbnail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g6pyHnwV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"automations-editor__details-thumbnail-container\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"defaultImage\"]]],null,{\"statements\":[[0,\"        \"],[7,\"img\",true],[10,\"class\",\"store-items__thumbnail\"],[11,\"src\",[28,\"uploadcareSrc\",[[24,[\"defaultImage\"]],\"/-/resize/40x\"],null]],[10,\"alt\",\"Placeholder image\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n      \"],[1,[28,\"number\",[[24,[\"item\",\"_data\",\"qty\"]]],null],false],[0,\" x \"],[1,[24,[\"item\",\"_data\",\"item\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"item\",\"_data\",\"itemCode\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"has-text-centered u-desc u-desc--mini\"],[8],[0,\"\\n        \"],[1,[24,[\"item\",\"_data\",\"itemCode\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"p\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n      \"],[1,[28,\"channels/shop/items/item-price\",null,[[\"campaign\",\"item\"],[[24,[\"campaign\"]],[24,[\"item\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/item-thumbnail/template.hbs"
    }
  });

  _exports.default = _default;
});