define("client/pods/documents/lists/print/product-code/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'hideCode',
        label: 'hide product code',
        translate: true
      }, {
        value: 'showCodeBelowItems',
        label: 'show product code below items',
        translate: true
      }, {
        value: 'showCodeNextToItems',
        label: 'show product code next to items',
        translate: true
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});