define("client/pods/websites/lists/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor", "ramda"], function (_exports, _adapter, _adaptersNew, _model, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  const filterTabs = (list, filterByLabel) => R.map(R.pipe(R.unless(R.propSatisfies(R.includes(R.__, filterByLabel), 'label'), R.set(R.lensProp('component'), null))))(list);

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/channels/website/campaigns/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let appendPath = arguments.length > 1 ? arguments[1] : undefined;
      const customData = {
        list: [{
          value: 'settings',
          label: 'settings',
          component: 'websites/websites-editor/settings-editor',
          resource: 'api/protected/websites/sub-panels-edit/settings',
          tabType: 'reuseSubTab',
          errorsTracker: [{
            context: 'name',
            label: 'name'
          }, {
            context: 'shopUrl',
            label: 'shop url (at least 6 characters)'
          }, {
            context: 'password (at least 8 characters)',
            label: 'password'
          }, {
            context: 'shippingFee',
            label: 'shipping fee'
          }, {
            context: 'description',
            label: 'campaign_description'
          }, {
            context: 'dateStartZ',
            label: 'date start'
          }, {
            context: 'dateEndZ',
            label: 'date end'
          }, {
            context: 'autoEnd',
            label: 'automatically end campaign'
          }, {
            context: 'showCountdownTimer',
            label: 'show countdown timer'
          }, {
            context: 'showCampaignHeader',
            label: 'show campaign header'
          }, {
            context: 'allowPickup',
            label: 'allow pick up'
          }, {
            context: 'allowShipping',
            label: 'allow shipping'
          }, {
            context: 'transporterLabel',
            label: 'transporter'
          }, {
            context: 'transporterAccountNumber',
            label: 'transporter account'
          }, {
            context: 'shippingFeeType',
            label: 'shipping fee type'
          }, {
            context: 'shippingFee',
            label: 'shipping fee'
          }, {
            context: 'shippingFeeAlwaysCharge',
            label: 'always charge shipping fee'
          }, {
            context: 'shippingFeeCondition',
            label: 'free shipping for orders greater and equal to'
          }, {
            context: 'pickupLocations',
            label: 'pick up locations'
          }, {
            context: 'shopPaymentMethods',
            label: 'payment methods'
          }, {
            context: 'paymentMethodsDefault',
            label: 'default payment method'
          }, {
            context: 'cashPaymentMethodFee',
            label: 'cash payment fee'
          }, {
            context: 'bankingDetails',
            label: 'banking details'
          }, {
            context: 'autoPrintInvoices',
            label: 'auto print invoices'
          }, {
            context: 'commissionMethod',
            label: 'commission calculation'
          }, {
            context: 'commissionPercentage',
            label: 'commission % per item'
          }, {
            context: 'commissionFlat',
            label: 'commission amount per item' // }, {
            //   context: 'commissionBaseCost',
            //   label: 'base cost'

          }, {
            context: 'companyName',
            label: 'company name'
          }, {
            context: 'companyTel',
            label: 'companyTel'
          }, {
            context: 'contactsTags',
            label: 'customer tags'
          }]
        }, {
          value: '3rd-party',
          label: '3rd-party',
          component: 'websites/websites-editor/3rd-party-editor',
          resource: 'api/protected/websites/sub-panels-edit/3rd-party',
          tabType: 'reuseSubTab',
          errorsTracker: [{
            context: 'facebookUrl',
            label: 'facebook page url'
          }, {
            context: 'instagramUrl',
            label: 'instagram url'
          }, {
            context: 'youtubeUrl',
            label: 'youtube channel url'
          }, {
            context: 'lineId',
            label: 'line id'
          }]
        }]
      }; // customData.list = filterTabs(customData.list, ['settings', '3rd-party'])
      // const path = this.getFullPath('findAllPath', appendPath)
      // return this.ajax.POST(path, customData).then(res => res?.data || [])
      // will be replaced by the code above

      return _nventor.default.resolve(customData.list);
    }

  });

  _exports.default = _default;
});