define("client/pods/contacts/lists/panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../../../../utils/nventor'
  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let appendPath = arguments.length > 1 ? arguments[1] : undefined;
      let options = arguments.length > 2 ? arguments[2] : undefined;
      const list = [{
        resource: '/api/protected/contacts',
        component: 'contacts/panels-display/sub-panels-details',
        label: 'details',
        tabType: 'replaceSubTab'
      }, {
        resource: '/api/protected/contacts',
        component: 'contacts/panels-display/sub-panels-member-levels',
        label: 'member levels',
        tabType: 'replaceSubTab'
      }, {
        resource: '/api/protected/contacts/statements/sales',
        component: 'contacts/panels-display/sub-panels-statements',
        label: 'customer statements',
        tabType: 'replaceSubTab',
        docType: 'sales'
      }, {
        resource: '/api/protected/contacts/activity/sales',
        component: 'contacts/panels-display/sub-panels-activity',
        label: 'customer activity',
        tabType: 'replaceSubTab',
        docType: 'sales'
      }, {
        resource: '/api/protected/contacts/statements/purchases',
        component: 'contacts/panels-display/sub-panels-statements',
        label: 'supplier reconciliations',
        tabType: 'replaceSubTab',
        docType: 'purchases'
      }, {
        resource: '/api/protected/contacts/activity/purchases',
        component: 'contacts/panels-display/sub-panels-activity',
        label: 'supplier activity',
        tabType: 'replaceSubTab',
        docType: 'purchases'
      }, {
        resource: '/api/protected/documents/data/pending',
        component: 'documents/pending/tab-container',
        label: 'doc-pending',
        tabType: 'replaceSubTab',
        docType: 'sales',
        docName: 'orders' // docType: 'consignment'

      }];
      const customData = {
        list
      };
      const path = this.getFullPath('findAllPath', appendPath);
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});