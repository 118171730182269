define("client/pods/components/barcode/generator/component", ["exports", "@glimmer/component", "jsbarcode", "ramda"], function (_exports, _component, _jsbarcode, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BarcodeGeneratorComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class BarcodeGeneratorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "barcodeDomElement", _descriptor, this);

      _defineProperty(this, "svgId", `barcode-svg-${Ember.guidFor(this)}`);
    }

    get label() {
      const label = this.args.label;

      if (!label) {
        return '';
      }

      const labelLength = this.args.labelLength || 0;

      if (labelLength >= 1) {
        return label.slice(0, labelLength);
      }

      return label;
    }

    setBarcode(value, format) {
      const svgElement = document.querySelector(`#${this.svgId}`);
      this.barcodeDomElement = document.createElement('img');
      const barcodeValue = this.cropString(value, format);
      const options = {
        format,
        margin: 0,
        height: 70,
        width: 2,
        // fontSize: 18,
        textMargin: 0 // flat: true

      };
      (0, _jsbarcode.default)(svgElement, barcodeValue, options);
    }

    cropString() {
      let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let format = arguments.length > 1 ? arguments[1] : undefined;
      // Max Length
      const upc = 12;
      const ean8 = 7;
      const ean5 = 5;
      const ean2 = 2;

      switch (format) {
        case 'UPC':
          return R.take(upc)(str);

        case 'EAN8':
          return R.take(ean8)(str);

        case 'EAN5':
          return R.take(ean5)(str);

        case 'EAN2':
          return R.take(ean2)(str);

        default:
          return str;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "barcodeDomElement", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setBarcode", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setBarcode"), _class.prototype)), _class));
  _exports.default = BarcodeGeneratorComponent;
});