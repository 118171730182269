define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/onsale-schedules/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ra4+ip/u",
    "block": "{\"symbols\":[\"@isReadonly\",\"@schedule\",\"@model\",\"@isHeader\",\"@isBody\"],\"statements\":[[4,\"if\",[[23,4,[]]],null,{\"statements\":[[4,\"if\",[[23,3,[\"isStandardProduct\"]]],null,{\"statements\":[[0,\"    \"],[7,\"th\",true],[8],[1,[28,\"tt\",[\"inclusive discounted price\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,5,[]]],null,{\"statements\":[[4,\"if\",[[23,3,[\"isStandardProduct\"]]],null,{\"statements\":[[0,\"    \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@value\"],[[23,1,[]],[23,2,[\"_data\",\"priceDiscounted\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-item-editor/onsale-schedules/template.hbs"
    }
  });

  _exports.default = _default;
});