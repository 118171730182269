define("client/pods/channels/shop/campaigns/lists/products-filters/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,

    findAll() {
      const data = [{
        label: 'recommended',
        value: 'recommended'
      }, {
        label: 'newestFirst',
        value: 'newestFirst'
      }, {
        label: 'priceAsc',
        value: 'priceAsc'
      }, {
        label: 'priceDesc',
        value: 'priceDesc'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});