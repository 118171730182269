define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/bulk-discount-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, R, _ramdaAdjunct, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "search", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "dataManager", _descriptor4, this);

      _initializerDefineProperty(this, "date", _descriptor5, this);

      _initializerDefineProperty(this, "isReadonly", _descriptor6, this);

      _initializerDefineProperty(this, "dirty", _descriptor7, this);

      const bulkDiscount = this.args.bulkDiscount;
      const schedules = bulkDiscount.getData('schedules') || [];

      if (_ramdaAdjunct.default.isNilOrEmpty(schedules)) {
        bulkDiscount.addSchedule({
          scheduleId: _nventor.default.random.alphaNum(18),
          dateStartZ: this.date.getStartOfDayZ()
        });
      }

      this.filters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/bulk-discounts/filters',
        defaultFiltersData: {
          query: ''
        }
      });
    }

    get isDisabled() {
      const isReadonly = this?.isReadonly;
      const isNew = this.args?.isNew || false;
      const isEditing = this.args?.isEditing || false;

      if (isEditing) {
        return false;
      }

      if (isNew) {
        return false;
      }

      return isReadonly;
    }

    get hasOneSchedule() {
      const bulkDiscount = this.args.bulkDiscount;
      const schedules = bulkDiscount.getData('schedules') || [];
      return R.length(schedules) === 1;
    }

    checkOverlapDate() {
      let {
        isRemove = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const bulkDiscount = this.args.bulkDiscount;
      const overlapDate = bulkDiscount.checkOverlapDate({
        bulkDiscount
      });
      const schedules = bulkDiscount.getData('schedules') || [];

      if (overlapDate.hasOverlap && !isRemove) {
        const overlapIds = overlapDate.ids || [];
        R.forEach(schedule => {
          const scheduleId = schedule.getData('scheduleId');

          if (R.includes(scheduleId, overlapIds)) {
            schedule.set('hasOverlap', true);
          } else {
            schedule.set('hasOverlap', false);
          }
        })(schedules);
        bulkDiscount.set('hasBulkDiscountOverlap', true);
      }

      if (!overlapDate.hasOverlap || isRemove) {
        R.forEach(schedule => {
          schedule.set('hasOverlap', false);
        })(schedules);
        bulkDiscount.set('hasBulkDiscountOverlap', false);
      }
    }

    fetchAllCampaignsBulkDiscounts(_ref) {
      var _this = this;

      let {
        query = ''
      } = _ref;
      return function* () {
        if (query) {
          query = query.trim();
          const filters = _this.filters;
          filters.setData('query', query);
          yield _this.crud.searchInputTask.perform({
            adapterName: 'channels/shop/campaigns/bulk-discounts',
            filters,
            component: _this
          });
        }
      }();
    }

    onSubmitAddRule(schedule) {
      schedule.addRule();
    }

    onSubmitAddSchedule(bulkDiscount) {
      const schedule = {
        dateStartZ: this.date.getStartOfDayZ(),
        rules: []
      };
      bulkDiscount.addSchedule(schedule);
      this.checkOverlapDate();
    }

    onSubmitRemoveSchedule(bulkDiscount, schedule) {
      const schedules = bulkDiscount.getData('schedules');

      if (R.length(schedules) > 1) {
        schedules.removeObject(schedule);
      }

      this.checkOverlapDate();
    }

    onSelectAndCopyBulkDiscount(args, selectedBulkDiscount) {
      const {
        bulkDiscount
      } = args;

      if (selectedBulkDiscount) {
        const selectedBulkDiscountName = selectedBulkDiscount.getData('name') || '';
        let selectedBulkDiscountCopy = [selectedBulkDiscount];
        selectedBulkDiscountCopy = R.pipe(R.concat([]), R.head)(selectedBulkDiscountCopy) || {};
        const bulkDiscounts = this.args.model.getData('bulkDiscounts');
        const duplicateBulkDiscountsLength = R.pipe(R.filter(R.pathEq(['_data', 'name'], selectedBulkDiscountName)), R.length)(bulkDiscounts);
        selectedBulkDiscountCopy.setData('bulkDiscountId', '');
        selectedBulkDiscountCopy.setData('name', `${selectedBulkDiscountName} (${duplicateBulkDiscountsLength})`);
        selectedBulkDiscountCopy.setData('isApplyToAllProducts', false);
        const bulkDiscountName = bulkDiscount.getData('name') || '';

        if (bulkDiscountName && bulkDiscountName !== selectedBulkDiscountName) {
          const msg = `${this.intl.t('replace')} ${bulkDiscountName} ${this.intl.t('to')} ${selectedBulkDiscountName}?`;

          if (window.confirm(msg)) {
            bulkDiscount.set('_data', R.propOr({}, '_data')(selectedBulkDiscountCopy));
          }
        }

        if (!bulkDiscountName) {
          bulkDiscount.set('_data', R.propOr({}, '_data')(selectedBulkDiscountCopy));
        }
      }
    }

    onToggleCheckHasActiveAllProduct(bulkDiscount) {
      const model = this.args.model;
      const bulkDiscounts = model.getData('bulkDiscounts');
      const bulkDiscountId = bulkDiscount.getData('bulkDiscountId');
      const isApplyToAllProducts = bulkDiscount.getData('isApplyToAllProducts');

      if (isApplyToAllProducts === true) {
        const hasActiveAllProduct = R.pipe(R.reject(bulkDiscount => {
          const eachBulkDiscountId = R.pathOr('', ['_data', 'bulkDiscountId'])(bulkDiscount);

          if (_ramdaAdjunct.default.isNilOrEmpty(eachBulkDiscountId) || eachBulkDiscountId === bulkDiscountId) {
            return true;
          }

          return false;
        }), R.find(R.pathEq(['_data', 'isApplyToAllProducts'], true)), _ramdaAdjunct.default.isNotNilOrEmpty)(bulkDiscounts);

        if (hasActiveAllProduct) {
          bulkDiscount.setData('isApplyToAllProducts', false);
          R.forEach(bulkDiscount => {
            bulkDiscount.set('hasErrorForAllProduct', false);
          })(bulkDiscounts);
          bulkDiscount.set('hasErrorForAllProduct', true);
        }

        if (!hasActiveAllProduct) {
          bulkDiscount.setData('isApplyToAllProducts', true);
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isReadonly", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "checkOverlapDate", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "checkOverlapDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchAllCampaignsBulkDiscounts", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchAllCampaignsBulkDiscounts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitAddRule", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddRule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitAddSchedule", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitAddSchedule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSubmitRemoveSchedule", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveSchedule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectAndCopyBulkDiscount", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectAndCopyBulkDiscount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onToggleCheckHasActiveAllProduct", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onToggleCheckHasActiveAllProduct"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorBulkDiscountsEditorBulkDiscountEditorComponent;
});