define("client/pods/components/lists/list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IC/zrGy3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"item\",\"_data\",\"icon\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[[24,[\"item\",\"_data\",\"icon\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"item\",\"_data\",\"svgIconTop\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[24,[\"item\",\"_data\",\"svgIconTop\"]],true],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \\n  \"],[1,[28,\"highlight\",[[28,\"tt\",[[28,\"get\",[[24,[\"item\"]],[24,[\"itemLabelKey\"]]],null]],[[\"translate\"],[[24,[\"shouldTranslate\"]]]]],[24,[\"query\"]]],[[\"disabled\"],[[24,[\"hasAdded\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"isEditable\"]]],null,{\"statements\":[[4,\"unless\",[[24,[\"item\",\"_data\",\"isNotEditable\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-pushbtn\",null,[[\"classNames\",\"tabindex\",\"onSubmit\"],[\"u-edit-btn\",-1,[28,\"action\",[[23,0,[]],\"edit\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"hasAdded\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"u-desc u-desc--mini\"],[8],[0,\"\\n      \"],[1,[28,\"tt\",[\"added\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"msgs/deleted-error\",null,[[\"model\"],[[24,[\"item\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/lists/list-item/template.hbs"
    }
  });

  _exports.default = _default;
});