define("client/pods/ages/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,

    findAll() {
      const data = [{
        value: '<17',
        label: '<17'
      }, {
        value: '18-24',
        label: '18-24'
      }, {
        value: '25-34',
        label: '25-34'
      }, {
        value: '35-44',
        label: '35-44'
      }, {
        value: '45-54',
        label: '45-54'
      }, {
        value: '55-64',
        label: '55-64'
      }, {
        value: '>65',
        label: '>65'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});