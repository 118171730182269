// import BaseModel from '../../base/model'
// import constants from '../../../constants'
// export default BaseModel.extend({
//   defaults () {
//     return {
//       index: '',
//       criteria: constants.automationsCriterias.greaterThanOrEqualTo,
//       itemKey: '',
//       itemCode: '',
//       when: constants.automationsWhen.orderValue,
//       whenItemQtyType: '',
//       whenItemQtyTags: '',
//       thenAction: false,
//       value: '',
//       status: constants.status.active
//     }
//   }
// })
define("client/pods/flows/criterias/model", [], function () {
  "use strict";
});