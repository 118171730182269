define("client/pods/reports/commissions/data/adjustment/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend((_dec = Ember._action, (_obj = {
    defaults() {
      return {
        selectedCommissionBaseCostMethod: 'normalPercent',
        isCalculatedByExclAmt: true,
        paymentTermsKey: ''
      };
    },

    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data = data || {};
      data = this.transformDetails(data); // populate docName info

      this.overwriteData(data);

      this._super(...arguments);
    },

    transformDetails(rawData) {
      // const self = this
      rawData.details = R.pipe(R.pathOr([], ['details']), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'reports/commissions/data/adjustment/details',
          data
        });
      }))(rawData);
      return rawData;
    },

    addNewRow(data) {
      if (RA.isNilOrEmpty(data)) {
        data = {
          item: '',
          itemKey: '',
          manual: true // commissionBaseCost: '',

        };
      }

      const detailModel = this.dataManager.setAsRecord({
        adapterName: 'reports/commissions/data/adjustment/details',
        data
      });
      let details = this.getData('details') || [];
      details.pushObject(detailModel);
      return detailModel;
    }

  }, (_applyDecoratedDescriptor(_obj, "addNewRow", [_dec], Object.getOwnPropertyDescriptor(_obj, "addNewRow"), _obj)), _obj)));

  _exports.default = _default;
});