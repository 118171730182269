define("client/pods/components/products/files/files-default-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4DKqbsgS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",false],[12,\"class\",\"product-image-thumb-container\"],[3,\"did-update\",[[28,\"perform\",[[24,[\"didInsertTask\"]],[24,[\"resourceKey\"]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"resourceKey\"]],[24,[\"product\",\"defaultImage\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"files/image-item\",[],[[\"@height\",\"@image\",\"@transform\",\"@width\"],[[22,\"height\"],[24,[\"product\",\"defaultImage\"]],[22,\"transform\"],[22,\"width\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"elements/element-icon\",[],[[\"@classNames\",\"@icon\"],[\"u-no-image\",\"far fa-image\"]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/files/files-default-image/template.hbs"
    }
  });

  _exports.default = _default;
});