define("client/pods/channels/shop/campaigns/lists/cart-btn-style/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    constants: Ember.inject.service(),
    idParam: false,
    modelObj: _model.default,

    findAll() {
      const data = [{
        label: 'addToCartAndChooseOptions',
        value: 'addToCartAndChooseOptions',
        translate: true
      }, {
        label: 'addToCartForAll',
        value: 'addToCartForAll',
        translate: true
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});