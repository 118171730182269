define("client/pods/documents/details/model", ["exports", "client/pods/base/model", "client/pods/documents/details/calculations-exclusive-or-inclusive", "ramda", "ramda-adjunct"], function (_exports, _model, _calculationsExclusiveOrInclusive, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend(_calculationsExclusiveOrInclusive.default, (_dec = Ember._action, (_obj = {
    big: Ember.inject.service(),

    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const self = this;
      self.set('pending', data.pending);
      delete data.pending;
      self.set('consignments', data.consignments);
      delete data.consignments;
      const docModel = this.transformDoc(data);
      self.set('doc', docModel);

      if (data.detail) {
        // this is for populating results from listing by details
        const detailData = data.detail;
        self.overwriteData(detailData);
      } else {
        self.overwriteData(data);
      }
    },

    transformDoc(rawData) {
      if (rawData.doc) {
        if (rawData?.doc?.details) {
          delete rawData.doc.details;
        }

        const docData = rawData.doc;
        delete rawData.doc;
        let adapterName;

        if (RA.isNotNilOrEmpty(docData.docType) && RA.isNotNilOrEmpty(docData.docName)) {
          adapterName = `documents/${docData.docType}/${docData.docName}`;
        } else {
          adapterName = 'products/inventory/adjustments';
        }

        return this.dataManager.setPartialRecord({
          adapterName,
          data: docData
        });
      }

      return false;
    },

    statusClass: Ember.computed('_data.productStatus', function () {
      const productStatus = this?._data?.productStatus || '';

      if (productStatus === 'preOrder') {
        return 'is-warning';
      }

      return '';
    }),

    getRounding() {
      return {
        roundingCurrency: this.getData('roundingCurrency', this.get('settings.roundingCurrency')),
        unroundedCurrency: this.getData('unroundedCurrency', this.get('settings.unroundedCurrency')),
        roundingInventoryPrice: this.getData('roundingInventoryPrice', this.get('settings.roundingInventoryPrice'))
      };
    },

    isProduct: Ember.computed('_data.type', function () {
      const itemType = this._data.type || '';

      if (itemType === 'shippingFee' || itemType === 'paymentFee') {
        return false;
      }

      return true;
    }),
    isDiscountRate: Ember.computed('_data.discountType', function () {
      const discType = this.getData('discountType');

      if (discType === this.get('constants.discountTypes.rate')) {
        return true;
      }

      return false;
    }),

    /**
     * @deprecated
     * deduct discount from lineAmt (discount rate set on each item:)
     * Discount is rounded per line item
     */
    _deductDiscount(lineAmtBig) {
      // const { roundingCurrency } = this.getRounding()
      // let discRateBig = this.getDataBig('discountRate')
      // let discAmountBig = this.getDataBig('discountAmount')
      // if (this.isDiscountRate && discRateBig.gt(0)) {
      //   // discount is %, divide by 100
      //   discRateBig = discRateBig.div(100)
      //   discAmountBig = lineAmtBig.times(discRateBig)
      // }
      // if (discAmountBig.gt(0)) {
      //   const discAmtRounded = discAmountBig.toFixed(roundingCurrency)
      //   // use rounded discount
      //   lineAmtBig = lineAmtBig.minus(discAmtRounded)
      //   // store discounted unit price for display and reporting purposes only
      //   const qtyBig = this.getDataBig('qty')
      //   const discPriceRounded = lineAmtBig.div(qtyBig).toFixed(roundingCurrency)
      //   this.setData('discPrice', discPriceRounded)
      //   this.setData('discAmt', discAmtRounded)
      // } else {
      //   this.setData('discPrice', '')
      //   this.setData('discAmt', '')
      // }
      return lineAmtBig;
    },

    _calculateLineCommission() {
      const {
        roundingCurrency
      } = this.getRounding();
      const commissionBaseCost = this.getDataBig('commissionBaseCost'); // const commissionBaseCost = 0

      let commission = 0;

      if (commissionBaseCost > 0 && !Ember.isEmpty(this.get('model._data.salesPersons'))) {
        const exclAmt = this.getDataBig('exclAmt');
        const qty = this.getDataBig('qty');
        const baseAmt = qty.times(commissionBaseCost);
        commission = exclAmt.minus(baseAmt).toFixed(roundingCurrency);
      }

      this.setData('commissionAmt', commission);
    },

    // @TODO: #473 refactor to handle decimals
    remainingQty: Ember.computed('_data.{qty,originalQty,allReturnedQty}', function () {
      const originalQty = parseInt(this._data.originalQty) || 0;
      const qty = parseInt(this._data.qty) || 0;
      const allReturnedQty = parseInt(this._data.allReturnedQty) || 0;
      return originalQty - qty - allReturnedQty;
    }),
    originalRemainingQty: Ember.computed('_data.{originalQty,allReturnedQty}', function () {
      const originalQty = parseInt(this._data.originalQty) || 0;
      const allReturnedQty = parseInt(this._data.allReturnedQty) || 0;
      return originalQty - allReturnedQty;
    }),
    hasDiscount: Ember.computed('_data.allocatedInclDiscountUnrounded', function () {
      const allocatedDisc = parseInt(R.path(['_data', 'allocatedInclDiscountUnrounded'])(this)) || 0;

      if (allocatedDisc > 0) {
        return true;
      }

      return false;
    }),
    detailQtyWhenLinkedExceedAllowedQty: Ember.computed('_data.{fromDocName,fromDetailKey,ordersPendingId,consignmentsPendingId,maxQtyWhenLinked,qty}', function () {
      const fromDocName = R.pathOr('', ['_data', 'fromDocName'])(this);
      let pendingId = '';

      if (RA.isNotNilOrEmpty(fromDocName)) {
        pendingId = R.pathOr('', ['_data', `${fromDocName}PendingId`])(this);

        if (R.equals(fromDocName, 'invoices')) {
          pendingId = R.pathOr('', ['_data', 'fromDetailKey'])(this);
        }
      }

      if (RA.isNotNilOrEmpty(pendingId)) {
        const maxQtyWhenLinked = R.pathOr('', ['_data', 'maxQtyWhenLinked'])(this);
        const detailQty = R.pathOr(0, ['_data', 'qty'])(this);

        if (RA.isNotNilOrEmpty(maxQtyWhenLinked)) {
          if (this.big.newBig(detailQty).gt(maxQtyWhenLinked)) {
            return true;
          }
        }

        return false;
      }

      return false;
    }),
    isDocumentDetail: Ember.computed('_data.{docName,itemKey,item,itemCode,qty}', function () {
      return true;
    }),

    toggleShowQtyWarningMsg() {
      this.set('showQtyWarningMsg', true);
    }

  }, (_applyDecoratedDescriptor(_obj, "toggleShowQtyWarningMsg", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleShowQtyWarningMsg"), _obj)), _obj)));

  _exports.default = _default;
});