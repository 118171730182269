define("client/pods/components/dispatch/pre-dispatch-modal/component", ["exports", "client/mixins/date", "client/mixins/transporter", "client/mixins/hct-fetch", "client/mixins/doc-contacts", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _date, _transporter, _hctFetch, _docContacts, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, _docContacts.default, _hctFetch.default, _transporter.default, {
    dispatch: Ember.inject.service(),
    transporterService: Ember.inject.service('transporter'),

    init() {
      this._super(...arguments);

      if (this.startWithDirty && this.model) {
        this.setupDirty();
        this.editTransporter.perform(this.model);
        this.set('original', {
          transporterLabel: this?.model?._data?.transporterLabel,
          waybill: this?.model?._data?.waybill,
          packages: this?.model?._data?.packages,
          contactPerson: this?.model?._data?.person,
          address: this?.model?._data?.address,
          telephone: this?.model?._data?.telephone,
          dateZ: this?.model?._data?.transporterArrivalDateZ,
          account: this?.model?._data?.transporterAccountNumber,
          transporterNotes: this?.model?._data?.transporterNotes,
          transporterBatchLabel: this?.model?._data?.transporterBatchLabel,
          transporterRegion: this?.model?._data?.transporterRegion
        });
      }
    },

    getAdapterName(model) {
      const docType = model.getData('docType');
      const docName = model.getData('docName');
      return `documents/${docType}/${docName}`;
    },

    updateTransporterTask: (0, _emberConcurrency.task)(function* () {
      const dirty = this.dirty;
      const adapterName = this.getAdapterName(dirty);
      const appendPath = 'transporter';
      let saved;
      /** @NOTE: NEW HCT IMPLEMENTATION DOES NOT REQUIRE REGION CODE */

      const isNewHct = R.pipe(R.path(['_data', 'transporterDefaultPickupLocationPersonId']), RA.isNotNilOrEmpty)(dirty);

      if (!isNewHct && this.settings.getProp('features.extensions.tw_hct') && dirty._data.transporterExtension === 'hct') {
        // show modal
        const transporterRegion = dirty.getData('transporterRegion');

        if (!transporterRegion) {
          // fetch
          yield this.fetchHCTRegionDataTask.perform(dirty);
        }

        if (!this.isHCTIncomplete(dirty)) {
          saved = yield this.updateRecordTask.perform({
            adapterName,
            appendPath,
            model: dirty
          });
        }
      } else {
        saved = yield this.updateRecordTask.perform({
          adapterName,
          appendPath,
          model: dirty
        });
      }

      if (saved) {
        const dirty = this.dirty;
        this.set('original', {
          transporterLabel: dirty.get('_data.transporterLabel'),
          waybill: dirty.get('_data.waybill'),
          packages: dirty.get('_data.packages'),
          contactPerson: dirty.get('_data.person'),
          address: dirty.get('_data.address'),
          telephone: dirty.get('_data.telephone'),
          dateZ: dirty.get('_data.transporterArrivalDateZ'),
          account: dirty.get('_data.transporterAccountNumber'),
          transporterNotes: dirty.get('_data.transporterNotes'),
          transporterBatchLabel: dirty.get('_data.transporterBatchLabel'),
          transporterRegion: dirty.get('_data.transporterRegion')
        });
        this.set('dirty', '');
        this.setupDirty();
      }

      if (this?.model?.isDispatched) {
        const onClosePreDispatchProcessDialog = this.onClosePreDispatchProcessDialog;

        if (onClosePreDispatchProcessDialog) {
          onClosePreDispatchProcessDialog();
        }
      }

      return saved;
    }),
    preSetIsDispatchedProcessTask: (0, _emberConcurrency.task)(function* () {
      const modelProxy = this.modelProxy;
      modelProxy.set('isSelected', false);
      return yield this.onSetIsDispatchedProcessTask.perform(...arguments);
    }),

    setupDirty() {
      const model = this.model;
      const adapterName = this.getAdapterName(model);
      const dirty = this.dataManager.getDirtyRecord(adapterName, model);
      this.set('dirty', dirty);
    },

    editTransporter: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.model;
      return function* () {
        const adapterName = _this.getAdapterName(model);

        const contactKey = model.getData('contactKey');

        const dirty = _this.dirty || _this.dataManager.getDirtyRecord(adapterName, model); // this.findAndLoadTransporterByKey(dirty, dirty.getData('transporterKey'))


        if (contactKey) {
          const contact = yield _this.findTask.perform({
            adapterName: 'contacts',
            appendPath: contactKey
          });
          dirty.set('selectedContact', contact);
        }

        _this.set('dirty', dirty);
      }();
    }),
    hasTransporterDataChanged: Ember.computed('dirty._data.{transporterLabel,waybill,packages,person,address,telephone,dateZ,transporterAccountNumber,transporterNotes,transporterBatchLabel,transporterRegion}', 'original.{transporterLabel,waybill,packages,person,address,telephone,dateZ,transporterAccountNumber,transporterNotes,transporterBatchLabel,transporterRegion}', function () {
      const dirty = this.dirty;
      const transporterLabel = dirty.get('_data.transporterLabel');
      const waybill = dirty.get('_data.waybill');
      let packages = dirty.get('_data.packages');

      if (R.is(Number)(packages)) {
        packages = R.toString(packages);
      }

      let originalPackages = this?.original?.packages;

      if (R.is(Number)(originalPackages)) {
        originalPackages = R.toString(originalPackages);
      }

      const contactPerson = dirty.get('_data.person');
      const address = dirty.get('_data.address');
      const telephone = dirty.get('_data.telephone');
      const dateZ = dirty.get('_data.transporterArrivalDateZ');
      const account = dirty.get('_data.transporterAccountNumber');
      const transporterNotes = dirty.get('_data.transporterNotes');
      const transporterBatchLabel = dirty.get('_data.transporterBatchLabel');
      const transporterRegion = dirty.get('_data.transporterRegion');

      if (!R.isEmpty(dirty)) {
        const original = this?.original;

        if (original?.transporterLabel !== transporterLabel || original?.waybill !== waybill || originalPackages !== packages || original?.contactPerson !== contactPerson || original?.address !== address || original?.telephone !== telephone || original?.dateZ !== dateZ || original?.account !== account || original?.transporterNotes !== transporterNotes || original?.transporterBatchLabel !== transporterBatchLabel || original?.transporterRegion !== transporterRegion) {
          return true;
        }
      }

      return false;
    }),
    actions: {
      cancelEditTransporter() {
        const dirty = this.dirty;
        const adapterName = this.getAdapterName(dirty);
        this.dataManager.cancelDirty(adapterName, dirty);
        this.set('dirty', '');
      },

      close(onCloseDialogAction) {
        onCloseDialogAction();
        const onClosePreDispatchProcessDialog = this.onClosePreDispatchProcessDialog;

        if (onClosePreDispatchProcessDialog) {
          onClosePreDispatchProcessDialog();
        }
      }

    }
  });

  _exports.default = _default;
});