define("client/pods/components/emails/campaigns/tab-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    // listAdapters: [],
    // results: [],
    resultsToggle: false,
    query: '',

    init() {
      this.crud.addLists(this, ['emails/lists/campaigns/menu']);

      this._super(...arguments);

      this.activity();
    },

    activity() {
      // create dashboard
      const tab = this.tab;
      const subTabOptions = {
        component: 'emails/campaigns/panels-activity'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    new() {
      const tab = this.tab;
      const subTabOptions = {
        component: 'emails/campaigns/panels-new'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    edit(model) {
      const tab = this.tab;
      const subTabOptions = {
        component: 'emails/campaigns/panels-edit',
        model
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    // scheduled () {
    //   // create dashboard
    //   const tab = this.tab
    //   const subTabOptions = {
    //     component: 'emails/panels-scheduled'
    //   }
    //   this.tabsManager.replaceSubTab(tab, subTabOptions)
    // },
    actions: {
      activity() {
        this.activity();
      },

      edit() {
        this.edit(...arguments);
      },

      cancel() {
        this.activity();
      },

      scheduled() {
        this.scheduled();
      }

    }
  });

  _exports.default = _default;
});