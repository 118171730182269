define("client/pods/components/products/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XpbWQUXu",
    "block": "{\"symbols\":[\"@onCancel\",\"@onImportTask\",\"@tab\",\"@onAfterCreateRecordTask\"],\"statements\":[[5,\"products/products-editor\",[],[[\"@adapterName\",\"@isDuplicate\",\"@isNew\",\"@isEditingVariants\",\"@isRunning\",\"@model\",\"@onCancel\",\"@onImportTask\",\"@onSaveTask\",\"@tab\"],[[23,0,[\"adapterName\"]],[22,\"isDuplicate\"],true,[22,\"isEditingVariants\"],[23,0,[\"crud\",\"createRecordTask\",\"isRunning\"]],[23,0,[\"model\"]],[23,1,[]],[23,2,[]],[28,\"perform\",[[23,0,[\"crud\",\"createRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"onAfter\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[\"model\"]],[23,4,[]],[23,3,[]]]]]],null],[23,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-new/template.hbs"
    }
  });

  _exports.default = _default;
});