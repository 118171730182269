define("client/pods/components/transporters/hct-summary/component", ["exports", "ramda", "client/mixins/date", "ember-concurrency", "ramda-extension", "ramda-adjunct"], function (_exports, R, _date, _emberConcurrency, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    crud: Ember.inject.service(),
    pageLimit: 10,
    filterTransporterAccountNumber: 'all',
    filterTransporterAccountNumberLabel: 'all',
    filterTransporterBatchNumber: 'all',
    filterTransporterBatchNumberLabel: 'all',
    hctSummarySubmitted: false,
    alreadySetup: false,

    init() {
      this.crud.addLists(this, ['extensions/apps/tw/hct/accounts/filters', 'extensions/apps/tw/hct/batches/filters']);

      this._super(...arguments);
    },

    afterLoadLists() {
      this.set('listsIsLoaded', Math.random());
      this.setupTask.perform();
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      const today = this.getPeriod(this.getStartOfDayZ());
      const period = this.period;

      if (today.date === period.date) {
        // only auto submit for current day
        this.submitHCTSummaryTask.perform();
      } else {
        this.fetchHCTSummaryFileTask.perform({
          date: period.date
        });
      }
    }),
    allSummaryList: Ember.computed('listsIsLoaded', 'crud.lists.{extensions-apps-tw-hct-accounts-filters.[],extensions-apps-tw-hct-batches-filters}', 'results.[]', 'allResults.[]', 'filterTransporterAccountNumber', 'filterTransporterBatchNumber', function () {
      let results = this.allResults || [];

      if (RA.isNilOrEmpty(results)) {
        results = this.results || [];
      }

      const all = [];
      const filterTransporterAccountNumber = this.filterTransporterAccountNumber;
      const filterTransporterBatchNumber = this.filterTransporterBatchNumber;
      const accounts = R_.dotPath('crud.lists.extensions-apps-tw-hct-accounts-filters')(this) || [];
      const batches = R_.dotPath('crud.lists.extensions-apps-tw-hct-batches-filters')(this) || [];

      if (this.listsIsLoaded && !R.isEmpty(accounts) && !R.isEmpty(batches)) {
        // get summary lists for each account and each batch
        accounts.forEach(accountModel => {
          const accountNumber = accountModel.getData('value');
          batches.forEach(batchModel => {
            const batchNumber = batchModel.getData('value'); // const page = 1;

            if ((filterTransporterAccountNumber === 'all' || filterTransporterAccountNumber === accountNumber) && (filterTransporterBatchNumber === 'all' || filterTransporterBatchNumber === batchNumber)) {
              const summaryDetails = this.getSummaryDetails(accounts, results, accountNumber, batchNumber);

              if (summaryDetails) {
                // @TODO: remove
                all.pushObject({
                  account: accountModel,
                  batch: batchModel,
                  totals: summaryDetails.totals,
                  pages: summaryDetails.pages,
                  weightRate: accountModel.getData('weightRate')
                });
              }
            }
          });
        });
      }

      return all;
    }),

    getSummaryDetails(accounts, results, filterTransporterAccountNumber, filterTransporterBatchNumber) {
      const filterTransporters = [this.get('constants.transporterExtensions.hct')];
      const filterDispatchStatus = this.get('constants.dispatchStatus.dispatched');
      const pageLimit = this.pageLimit;

      if (RA.isNilOrEmpty(results)) {
        results = [];
      } // @TODO: sort by account!
      // @TODO: sort by account!
      // @TODO: sort by account!
      // @TODO: sort by account!


      let totalPackages = 0;
      let subPackages = 0;
      let subCount = 0;
      let totalCount = 0;
      const pages = [];
      let page = 0;
      results.forEach(result => {
        // filter by transporter name
        const transporterExtension = result.getData('transporterExtension', '');
        const dispatchStatus = result.getData('dispatchStatus', this.get('constants.dispatchStatus.undispatched'));
        const transporterAccountNumber = result.getData('transporterAccountNumber', '');
        const transporterBatchNumber = result.getData('transporterBatchNumber', '');

        if (this._filterByTransporters(filterTransporters, transporterExtension) && this._filterByDispatchStatus(filterDispatchStatus, dispatchStatus)) {
          const hasOnlyAll = filterTransporterAccountNumber === 'all' && accounts.length === 1;

          if ((hasOnlyAll || transporterAccountNumber === filterTransporterAccountNumber || filterTransporterAccountNumber === 'all' && transporterAccountNumber === '') && transporterBatchNumber === filterTransporterBatchNumber) {
            const packages = parseInt(result.getData('packages'));
            totalPackages = totalPackages + packages;
            subPackages = subPackages + packages;
            subCount = subCount + 1;
            totalCount = totalCount + 1;

            if (!pages[page]) {
              pages[page] = {
                data: []
              };
            }

            pages[page].data.pushObject(result);
            pages[page].subCount = subCount;
            pages[page].subPackages = subPackages;
            pages[page].page = page + 1;

            if (subCount >= pageLimit) {
              subPackages = 0;
              subCount = 0;
              page = page + 1;
            }
          }
        }

        return false;
      });

      if (totalCount > 0) {
        return {
          totals: {
            packages: totalPackages,
            count: totalCount
          },
          pages: pages
        };
      }

      return false;
    },

    isHCTList: Ember.computed('results.[]', 'allResults.[]', function () {
      let results = this.allResults || [];

      if (RA.isNilOrEmpty(results)) {
        results = this.results || [];
      }

      const filterTransporters = [this.get('constants.transporterExtensions.hct')];
      const filterDispatchStatus = this.get('constants.dispatchStatus.dispatched');
      return results.filter(result => {
        return this._filterByTransporters(filterTransporters, result._data.transporterExtension) && this._filterByDispatchStatus(filterDispatchStatus, result._data.dispatchStatus); // return this._filterByTransporters(filterTransporters, result._data.transporterExtension)
      });
    }),

    _filterByTransporters(filterTransporters, transporterExtension) {
      if (Ember.isEmpty(filterTransporters)) {
        return true;
      }

      if (filterTransporters.indexOf(transporterExtension) > -1) {
        return true;
      }

      return false;
    },

    _filterByDispatchStatus(filterDispatchStatus, dispatchStatus) {
      // no filter set, dont filter
      if (Ember.isEmpty(filterDispatchStatus)) {
        return true;
      } // match status


      if (dispatchStatus === filterDispatchStatus) {
        return true;
      }

      return false;
    },

    fetchHCTSummaryFileTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        date
      } = _ref;
      return function* () {
        if (!date) {
          const period = _this.period;
          date = period.date;
        }

        const results = yield _this.server.call('GET', `api/protected/extensions/apps/tw/transporters/hct/${date}`);
        const files = R.prop('data', results);

        _this.set('uploadedHCTSummaryFiles', files);
      }();
    }),
    submitHCTSummaryTask: (0, _emberConcurrency.task)(function* () {
      const isHCTList = this.isHCTList || [];
      const summaryData = isHCTList.map(model => {
        let contact = model.getData('person');

        if (!contact) {
          contact = model.getData('contact');
        }

        let amount = '';

        if (model.getData('paymentMethodKey') === this.get('constants.paymentMethods.cash')) {
          // need to get amt
          const cashCollectionInclTotal = Number(model.getData('cashCollectionInclTotal') || 0);
          amount = cashCollectionInclTotal;
        }

        const groups = model.getData('groups') || [];
        R.forEach(group => {
          const groupPaymentMethodKey = group.getData('paymentMethodKey');

          if (groupPaymentMethodKey === this.get('constants.paymentMethods.cash')) {
            const groupCashCollectionInclTotal = Number(group.getData('cashCollectionInclTotal') || 0);
            amount = amount + groupCashCollectionInclTotal;
          }
        })(groups);
        let arrivalDate = '';
        const transporterArrivalDateZ = model.getData('transporterArrivalDateZ');
        const tomorrow = this.getTomorrow();
        const tomorrowZ = this.getStartOfDayZ(tomorrow);

        if (transporterArrivalDateZ && (this.isEqualWeekday(transporterArrivalDateZ, 6) || transporterArrivalDateZ > tomorrowZ)) {
          arrivalDate = this.formatDate(transporterArrivalDateZ, 'YYYY-MM-DD');
        }

        const arrivalTime = '';
        const transporterWaybillRecord = model?._data?.transporterWaybillRecord || '';
        const externalId = model?._data?.externalId || '';
        return {
          externalId,
          transporterWaybillRecord,
          _key: model.getData('_key'),
          accountNumber: model.getData('transporterAccountNumber'),
          waybill: model.getData('waybill'),
          name: contact,
          address: model.getData('address'),
          telephone: model.getData('telephone'),
          packages: model.getData('packages'),
          arrivalDate: arrivalDate,
          arrivalTime: arrivalTime,
          notes: model.getData('transporterNotes', ''),
          amount: String(amount)
        };
      }); // if (summaryData.length > 0) {

      const period = this.period;
      const data = {
        batch: summaryData,
        date: period.date,
        transporterExtension: 'hct'
      };

      try {
        yield this.server.call('POST', 'api/protected/extensions/apps/tw/transporters/hct', data);
        this.set('hctSummarySubmitted', true);
        yield this.fetchHCTSummaryFileTask.perform({
          date: period.date
        });
        this.set('errors', '');
      } catch (err) {
        const errors = R.pipe(R.pathOr([{
          context: '',
          message: 'error occurred'
        }], ['data', 'batch', 'message']), R.values, R.map(errorItem => {
          errorItem.label = errorItem.context;
          return errorItem;
        }))(err);
        this.set('errors', errors);
      } // }

    }).drop()
  });

  _exports.default = _default;
});