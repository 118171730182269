define("client/pods/components/lists/list-selector/component", ["exports", "ramda", "client/utils/nventor"], function (_exports, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    intl: Ember.inject.service('intl'),
    tabsManager: Ember.inject.service(),
    classNames: ['list-selector'],
    allSelected: false,
    selected: null,
    // tracks values that are selected. will be an array if multiple values
    // rememberSelected: true, //keep track of selected. set to false if list shouldnt keep track of selected items
    selectedModels: null,
    selectedComponents: null,
    // tracks components selected in order to deselect
    showEditor: false,
    optionsComponents: null,
    // keeps track of all options components. used to update components.isSelected when model changes after init
    multiSelect: false,
    // allow multiple selections
    allowEmpty: true,
    // allow no selections, will not deselect of last option selected
    defaultSelected: null,
    optionLabelKey: '_data.label',
    // key that points to option's label
    optionValueKey: '_data.value',
    // key that points to option's value
    model: null,
    // used to bind selected values to model value
    valueKey: '',
    // specify which model key to bind to selected values
    labelKey: null,
    // specify which model key to bind to selected labels (joined into string)
    // attributeBindings: ['value'],
    translate: true,
    translateLegend: true,
    filterTerm: '',
    filterFromStart: false,

    init() {
      this._super(...arguments);

      this.set('allSelected', false);

      if (this.multiSelect) {
        this.set('selected', []);
      }

      this.set('selectedComponents', []);
      this.set('itemClassNames', ['list-selector__item']);
      this.set('legendClassNames', ['list-selector__legend']);
      this.set('optionsComponents', []);
      const valueKey = this.valueKey;

      if (valueKey === '') {
        throw new Error('list-selector: valueKey is empty.');
      }

      this.setup();
    },

    didInsertElement() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      // update selector's selected values based on model value
      this.updateSelected();
    },

    proxyList: Ember.computed('list.[]', 'filterTerm', 'filterFromStart', function () {
      // proxyList: computed('list.{synced,[]}', 'filterTerm', function () {
      const list = this.list || [];
      let filterTerm = this.filterTerm;

      if (filterTerm) {
        filterTerm = R.pipe(R.trim, R.toLower)(filterTerm);
        const optionValueKey = this.optionValueKey;
        const optionLabelKey = this.optionLabelKey;
        const filterFromStart = this.filterFromStart;

        if (filterFromStart) {
          return R.filter(option => {
            const optionValue = R.pipe(_nventor.default.dotPathOr('', optionValueKey), R.toLower)(option);

            const optionLabel = _nventor.default.dotPathOr('', optionLabelKey)(option);

            const optionLabelLowercase = R.toLower(optionLabel);
            const hasValue = R.startsWith(filterTerm)(optionValue);
            const hasLabel = R.startsWith(filterTerm)(optionLabelLowercase);
            let hasMatchingTranslatedLabel = false;

            if (this.translate || option._data.translate) {
              try {
                const translatedOptionLabel = this.intl.t(optionLabel);
                hasMatchingTranslatedLabel = R.startsWith(filterTerm)(translatedOptionLabel);
              } catch (error) {
                hasMatchingTranslatedLabel = false;
              }
            }

            if (hasValue || hasLabel || hasMatchingTranslatedLabel) {
              return true;
            }

            return false;
          })(list);
        }

        return R.filter(option => {
          const optionValue = R.pipe(_nventor.default.dotPathOr('', optionValueKey), R.toLower)(option);
          const optionLabel = R.pipe(_nventor.default.dotPathOr('', optionLabelKey), R.toLower)(option);
          const hasValue = R.includes(filterTerm)(optionValue);
          const hasLabel = R.includes(filterTerm)(optionLabel);

          if (hasValue || hasLabel) {
            return true;
          }

          return false;
        })(list);
      }

      return list;
    }),
    allowEdit: Ember.computed('addItemComponent', 'editItemComponent', function () {
      if (this.addItemComponent) {
        return true;
      }

      if (this.editItemComponent) {
        return true;
      }

      return false;
    }),

    /**
     * updateSelected:
     * update component's selected property
     * called on init to setup which options are selected.
     * also called when model's property is changed
     * function is also used as an observer created on init
     * this listens for changes on model and updates selector's value accordingly
     * @return {[type]} [description]
     */
    updateSelected() {
      // @TODO: will need to select/deselect option
      const valueKey = this.valueKey;
      const value = this.get('model.' + valueKey);

      if (this.selected !== value) {
        // only update when value has changed
        this.set('selected', value); // on init of component optionsComponents and selectedComponents will be empty
        // so in effect updateOptionsComponentsSelected does nothing on init

        this.updateOptionsComponentsSelected(value);
      }
    },

    /**
     * updateOptionsComponentsSelected:
     * sets isSelected for all optionsComponents based on model changes
     * when underlying model selected value changes, the optionsComponents.isSelected needs to be updated
     * @param  {[type]} value [description]
     * @return {[type]}       [description]
     */
    updateOptionsComponentsSelected(value) {
      const self = this;

      if (Ember.isEmpty(value)) {
        // model's value is null, clear all selected options
        self.clearAllSelectedOptionsComponents();
      } else {
        // loop through all options components
        const optionsComponents = self.get('optionsComponents');
        const optionValueKey = self.get('optionValueKey');
        const isMultiSelect = self.get('multiSelect'); // clear selected components
        // self.set('selectedComponents', [])

        optionsComponents.forEach(optionComponent => {
          const option = optionComponent.get('option');
          const optionValue = option.get(optionValueKey);
          let isSelected = false;

          if (isMultiSelect) {
            if (value.indexOf(optionValue) > -1) {
              isSelected = true;
            }
          } else {
            if (optionValue === value) {
              isSelected = true;
            }
          }

          if (isSelected) {
            const selectedComponents = self.get('selectedComponents') || [];
            selectedComponents.addObject(optionComponent);
            self.set('selectedComponents', selectedComponents);
          }

          if (!optionComponent.isDestroyed) {
            optionComponent.set('isSelected', isSelected);
          }
        });
      }
    },

    // set all selected options components is selected to false
    clearAllSelectedOptionsComponents() {
      const components = this.selectedComponents || [];
      components.forEach(function (optionComponent) {
        if (!optionComponent.isDestroyed) {
          optionComponent.set('isSelected', false);
        }
      });
      this.set('selectedComponents', []);
    },

    /**
     * toggle selected option
     * will determine select/deselect
     * @param {object} option - object to toggle
     * @param {object} optionComponent - the component sending the toggle action,
     *                                    used to keep track of selected state
     */
    toggleOption(option, optionComponent) {
      if (optionComponent.get('isSelected') === true) {
        // deselect option
        this.deselectOption(option, optionComponent);
      } else {
        // select option
        this.selectOption(option, optionComponent);
      }
    },

    /**
     * keep track of selected item
     * @param {object} option - selected object
     * @param {object} optionComponent - the component sending the toggle action,
     *                                    used to keep track of selected state
     */
    selectOption(option, optionComponent) {
      const multiSelect = this.multiSelect;

      if (!multiSelect) {
        // set previous option.isSelected = false
        const components = this.selectedComponents || [];
        components.forEach(function (component) {
          // set each component to isSelected=false
          if (!component.isDestroyed) {
            component.set('isSelected', false);
          }
        }); // clear selectedComponents array...

        this.set('selectedComponents', []);
        this.set('selected', this.getOptionValue(option));
      } else {
        // multiselect
        // keep track of selected
        if (!Ember.isArray(this.selected)) {
          // selected is always an array for multiselect
          this.set('selected', []);
        }

        const value = this.getOptionValue(option);
        const selected = this.selected || [];
        selected.addObject(value);
        this.set('selected', selected);
      }

      const selectedComponents = this.selectedComponents || [];
      selectedComponents.addObject(optionComponent);
      this.set('selectedComponents', selectedComponents); // this.get('selectedComponents').addObject(optionComponent)
      // select option

      if (!optionComponent.isDestroyed) {
        optionComponent.set('isSelected', true);
      }

      this.bindModelValue(); // send action to onSelect event..only used for when list is in a dropdown

      if (this.onSelect) {
        this.onSelect(option, this);
      }
    },

    /**
     * remove selected item
     * @param {object} option - object to deselect
     * @param {object} optionComponent - the component sending the toggle action,
     *                                    used to keep track of selected state
     */
    deselectOption(option, optionComponent) {
      let allowDeselect = true; // check to see if list allows no selections

      if (this.allowEmpty === false) {
        if (this.multiSelect) {
          // only disallow deselect if only last item is being deselected
          if (this.selected.length <= 1) {
            allowDeselect = false;
          }
        } else {
          // single select: dont allow deselecting of itself
          if (optionComponent.get('isSelected') === true) {
            allowDeselect = false;
          }
        }
      }

      if (allowDeselect) {
        if (!optionComponent.isDestroyed) {
          optionComponent.set('isSelected', false);
        }

        if (this.multiSelect) {
          if (!Ember.isArray(this.selected)) {
            // for multiselect, selected must be an array
            this.set('selected', []);
          }

          this.selected.removeObject(this.getOptionValue(option));
        } else {
          this.set('selected', null);
        }

        this.selectedComponents.removeObject(optionComponent);
        this.bindModelValue(); // send action to onSelect event..only used for when list is in a dropdown

        if (this.onDeselect) {
          this.onDeselect(option, this);
        }
      }
    },

    /**
     * binds selected value to that of model value.
     * this updates model's value when user makes a selection
     * will also bind label if labelKey is specified
     */
    bindModelValue() {
      const model = this.model;

      if (model.set) {
        model.set(this.valueKey, this.selected);
      } else {
        this.set(`model.${this.valueKey}`, this.selected);
      }

      this.bindModelLabel();
    },

    /**
     * binds selected labels to that of model property (specified by labelKey).
     */
    bindModelLabel() {
      // @TODO: test
      if (this.labelKey) {
        const model = this.model;
        model.set(this.labelKey, this.selectedOptionLabel);
      }
    },

    getOptionValue(option) {
      const value = option.get?.(this.optionValueKey);

      if (!R.isNil(value)) {
        return value;
      }

      return option?.[this.optionValueKey] || '';
    },

    editorComponent: Ember.computed('addItemComponent', 'editItemComponent', function () {
      return this.addItemComponent || this.editItemComponent;
    }),

    /**
     * returns selected options label,
     * for multiSelect labels are joined together into a string
     */
    selectedOptionLabel: Ember.computed('selectedComponents.[]', function () {
      const multiSelect = this.multiSelect;
      const selectedComponents = this.selectedComponents;

      if (selectedComponents.length > 0) {
        if (!multiSelect) {
          // single select
          const selectedOption = selectedComponents.get('firstObject');
          return selectedOption.get('optionLabel');
        } else {
          // multiselect..
          const labels = [];
          selectedComponents.forEach(function (component) {
            labels.pushObject(component.get('optionLabel'));
          });
          return labels.join(', ');
        }
      }

      return '';
    }),

    /**
     * return model's selected value
     */
    getModelValue() {
      return this.model.get(this.valueKey);
    },

    /**
     * set model's selected value
     */
    setModelValue(value) {
      return this.model.set(this.valueKey, value);
    },

    toggleShowEditor(bool) {
      if (typeof bool === 'boolean') {
        Ember.set(this, 'showEditor', bool);
      } else {
        Ember.set(this, 'showEditor', !this.showEditor);
      }
    },

    updateItem() {
      this.toggleShowEditor(false);
    },

    removeItem(item) {
      const self = this;
      const list = self.get('list');
      const itemValueKey = self.get('optionValueKey');
      const needle = item.get(itemValueKey);
      const found = list.findBy(itemValueKey, needle);
      list.removeObject(found);
      this.toggleShowEditor(false);
    },

    onAddComponent() {
      if (this.onAdd) {
        this.onAdd();
      }
    },

    actions: {
      /**
       * listen for toggle event sent from options component
       */
      toggleOption(option, optionComponent) {
        // handle toggle event
        this.toggleOption(option, optionComponent);
      },

      addItem() {// this.get('list').pushObject(item)
        // this.send('selectDropdownItem', item);
      },

      editItem(item) {
        this.set('editItem', item);
        this.set('showEditor', true);
      },

      selectAll(isSelected) {
        let selected = [];

        if (isSelected) {
          const list = this.proxyList || [];
          const optionValueKey = this.optionValueKey;
          selected = R.map(item => {
            const value = item.get(optionValueKey);
            return value;
          })(list);
        }

        this.set('selected', selected);
        this.bindModelValue();
        this.updateOptionsComponentsSelected(selected);
        this.set('allSelected', isSelected);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "toggleShowEditor", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleShowEditor"), _obj), _applyDecoratedDescriptor(_obj, "updateItem", [_dec2], Object.getOwnPropertyDescriptor(_obj, "updateItem"), _obj), _applyDecoratedDescriptor(_obj, "removeItem", [_dec3], Object.getOwnPropertyDescriptor(_obj, "removeItem"), _obj), _applyDecoratedDescriptor(_obj, "onAddComponent", [_dec4], Object.getOwnPropertyDescriptor(_obj, "onAddComponent"), _obj)), _obj)));

  _exports.default = _default;
});