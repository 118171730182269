define("client/pods/components/admin/payments/card/panels-refunds/component", ["exports", "client/config/environment", "client/mixins/crud", "client/mixins/payments-list-by", "ember-concurrency"], function (_exports, _environment, _crud, _paymentsListBy, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _paymentsListBy.default, {
    adapterName: 'admin/payments/card',
    filtersAdapterName: 'admin/payments/card/filters',

    init() {
      this.set('config', _environment.default);

      this._super(...arguments);

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      filters.setData('provider', this.selectedProvider);
      const refundRequested = this.get('constants.paymentCreditCardStatus.refundRequested');
      this.listByTask.perform({
        adapterName: 'admin/payments/card',
        filters,
        paymentCreditCardStatus: refundRequested
      });
    },

    markIsRefundedTask: (0, _emberConcurrency.task)(function* (doc) {
      const self = this;
      const key = doc._data._key;
      const payload = {
        _rev: doc._data._rev
      };
      yield self.get('server').call('PUT', `api/protected/admin/payments/card/refunded/${key}`, payload);
      doc.setData('paymentCreditCardStatus', this.get('constants.paymentCreditCardStatus.refunded'));
    }) // markIsUnsuccessfulTask: task(function * (tempDoc) {
    //   const self = this
    //   const key = tempDoc._data._key
    //   yield self.get('server').call('PUT', `api/protected/admin/payments/card/abandoned/unsuccessful/${key}`, {})
    //   tempDoc.setData('isUnsuccessful', true)
    //   tempDoc.setData('isSuccessful', false)
    // })

  });

  _exports.default = _default;
});