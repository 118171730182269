define("client/pods/tabs-manager/service", ["exports", "ramda", "ramda-adjunct", "client/pods/tabs-manager/model", "client/pods/options/model", "client/pods/form-container/model", "client/pods/form-container/form-container-sub/model", "client/pods/guide-menu/model", "client/utils/nventor"], function (_exports, R, RA, _model, _model2, _model3, _model4, _model5, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // @NOTE: if tab is not toggling between visible=true/false then make sure tagName is set on the component
  // const TabModel = EmberObject.extend({
  //   init () {
  //     this._super(...arguments)
  //
  //     this.resetTabs()
  //   },
  //   resetTabs () {
  //     const tabs = []
  //     tabs._history = []
  //     tabs._closed = []
  //     this.set('subTabs', tabs)
  //   },
  //
  //   subTabsArray: computed('subTabs.{[],@each.id}', 'tabsUpdated', function () {
  //     return this.subTabs
  //   })
  // })
  const self = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_obj = {
    tabsUpdated: 0,

    init() {
      this._super(...arguments);

      this.resetTabs();
    },

    /**
     * Get master tab
     * @param {String} tabPosition 'left', 'right' or 'sidebarRight'
     * @returns
     */
    getMasterTab() {
      let tabPosition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'left';
      const masterTab = this.getTabs(tabPosition);
      return this.getActiveTab(masterTab);
    },

    /**
     * Register the tabmenu on the tab
     * @param {Object} tab parent tab of the subtab
     * @param {String} menuId the menuId for registeration
     * @param {String} label the label of guide menu tab (type === 'container') / the label of guide menu item (type === 'sub')
     * @param {String} parentLabel the label of the tab of guide menu (required when type === 'sub')
     * @param {String} type 'container' / 'sub'
     * @param {String} className used for querySelector to get the target in guide-menu-content
     * @returns {Object|null} return containerModel
     */
    registerMenu() {
      let {
        tab,
        menuId = '',
        label = '',
        parentLabel = '',
        type = '',
        className
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (tab?.get) {
        let guideMenu = tab.get(menuId);

        if (RA.isNilOrEmpty(guideMenu)) {
          guideMenu = _model5.default.create({
            menu: []
          });
        }

        const menu = guideMenu?.menu || [];
        let containerModel = {};

        if (type === 'container') {
          containerModel = R.find(R.propEq('label', label))(menu) || {};

          if (RA.isNilOrEmpty(containerModel)) {
            containerModel = _model3.default.create({
              label,
              className,
              menuId,
              showActive: false,
              sub: []
            });
            menu.pushObject(containerModel);
          } else {
            if (!containerModel.className) {
              containerModel.set('className', className);
            } else if (containerModel.className !== className) {
              _nventor.default.throw(`Intend to overwrite existing className '${containerModel.className}' with '${className}'!!`);
            }
          }
        }

        if (type === 'sub') {
          containerModel = R.find(R.propEq('label', parentLabel))(menu) || {};

          if (RA.isNilOrEmpty(containerModel)) {
            const subModel = _model4.default.create({
              label,
              className,
              menuId,
              showActive: false
            });

            containerModel = _model3.default.create({
              label: parentLabel,
              showActive: false,
              menuId,
              sub: [subModel]
            });
            menu.pushObject(containerModel);
          } else {
            if (!containerModel?.sub) {
              containerModel.sub = [];
            }

            const existingSubModel = R.find(R.propEq('label', label))(containerModel.sub);

            if (RA.isNotNilOrEmpty(existingSubModel)) {
              if (!existingSubModel?.className) {
                Ember.set(existingSubModel, 'className', className);
              } else if (existingSubModel.className !== className) {
                _nventor.default.throw(`Intend to overwrite existing className '${existingSubModel.className}' with '${className}'!!`);
              }
            } else {
              const subModel = _model4.default.create({
                label,
                className,
                menuId,
                showActive: false
              });

              containerModel.sub.push(subModel);
            }
          }
        }

        Ember.set(guideMenu, 'menu', menu);
        Ember.set(tab, menuId, guideMenu);
        return containerModel;
      }
    },

    registerErrors() {
      let {
        dirty = null,
        parentTab = {},
        tab = {}
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const apiErrorsAll = dirty?.apiErrors || [];
      Ember.set(parentTab, 'apiErrorsAll', apiErrorsAll);
      const errorsTrackerAll = parentTab?.errorsTrackerAll || [];
      const errorsTrackers = errorsTrackerAll.filter(errorsTracker => errorsTracker?.instanceId === dirty?.instanceId);
      const subTabs = tab?.subTabs || [];

      this._setErrorsTabs({
        apiErrors: apiErrorsAll,
        dirty,
        errorsTrackers,
        subTabs,
        tab
      });
    },

    _setErrorsTabs() {
      let {
        apiErrors = [],
        dirty = null,
        errorsTrackers = [],
        subTabs = [],
        tab = {}
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const subTabsErrors = [];
      const instanceId = dirty?.instanceId;
      errorsTrackers.map?.(errorsTracker => {
        if (errorsTracker?.context && RA.isNotNilOrEmpty(apiErrors)) {
          const apiError = R.find(R.pathEq(['context', 'label'], errorsTracker.context))(apiErrors) || R.find(R.pathEq(['context', 'key'], errorsTracker.context))(apiErrors);

          if (apiError) {
            const errorData = R.find(R.propEq('tabId', errorsTracker.tabId))(subTabsErrors);

            if (!errorData) {
              subTabsErrors.push({
                errors: [apiError],
                instanceId,
                isModal: errorsTracker.isModal,
                tabId: errorsTracker.tabId,
                childTabIds: errorsTracker?.childTabIds || []
              });
            } else {
              const errors = errorData?.errors || [];
              errors?.push?.(apiError);
            }
          }
        }
      });

      if (instanceId) {
        const found = this._setErrorsAndErrorsCountOnTab({
          errors: subTabsErrors,
          instanceId,
          tab
        });

        if (!found) {
          subTabs.map?.(subTab => {
            this._setErrorsAndErrorsCountOnTab({
              errors: subTabsErrors,
              instanceId,
              tab: subTab
            });
          });
        }
      }
    },

    _setErrorsAndErrorsCountOnTab() {
      let {
        errors = [],
        instanceId = '',
        tab = {}
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      let _errors = R.filter(error => R.propEq('instanceId', instanceId)(error))(errors) || [];

      let found = false;

      if (_errors.length >= 1) {
        _errors = R.find(R.anyPass([R.propEq('tabId', tab?.id), error => R.includes(tab?.id, error.childTabIds)]))(errors);
        found = !!_errors;
      }

      Ember.set(tab, 'errors', _errors);
      return found;
    },

    clearErrors() {
      let tab = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const hasErrors = tab.errors;

      if (hasErrors) {
        Ember.set(tab, 'errors', null);
      }
    },

    resetTabs() {
      this._resetTabs('left');

      this._resetTabs('right');

      this._resetTabs('sidebarRight');
    },

    setHeader(tab) {
      let header = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      if (tab) {
        tab.set('header', R.take(30)(header));
      }
    },

    _createOptionsModel() {
      let tabOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      const allTabOptions = Object.assign(...tabOptions);

      if (!allTabOptions.isModel) {
        const TabsManagerModel = _model2.default.create();

        TabsManagerModel.populate(allTabOptions);
        return TabsManagerModel;
      }

      return allTabOptions;
    },

    _resetTabs(tabPosition) {
      const tabs = [];
      tabs._history = [];
      tabs._closed = [];
      this.set(tabPosition, tabs);
    },

    /**
     * Get the tab by tabPosition
     * @param {String} tabPosition
     * @returns {Array}
     */
    getTabs(tabPosition) {
      return this.get(tabPosition) || [];
    },

    openTab(tabPosition) {
      for (var _len = arguments.length, tabOptions = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        tabOptions[_key - 1] = arguments[_key];
      }

      const tabOptionsModel = this._createOptionsModel(tabOptions);

      tabPosition = tabOptionsModel.getData('tabPosition') || tabPosition;
      const tabs = this.get(tabPosition);
      const rememberTabScrollTop = true;

      const tab = this._createTab(tabs, tabOptionsModel, false, rememberTabScrollTop); // set active


      this.selectTab(tabs, tab); // if (tabPosition === 'sidebarRight') {
      //   const sidebarRight = window.$('.app-grid__sidebar-right')
      //   if (!sidebarRight.hasClass('bot-sidebar-right')) {
      //     sidebarRight.addClass('bot-sidebar-right')
      //   }
      // }
    },

    // createTabOnParent (parent, subTabsProperty, tabOptions) {
    //   const tabs = R.prop(subTabsProperty)(parent) || []
    //   const tabOptionsModel = this._createOptionsModel(tabOptions)
    //   const rememberTabScrollTop = true
    //   const tab = this._createTab(tabs, tabOptionsModel, false, rememberTabScrollTop)
    //   parent.set(subTabsProperty, tabs)
    //   this.selectTab(tabs, tab)
    // },
    _createTab(tabs, tabOptionsModel) {
      let tab = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let rememberTabScrollTop = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      const tabOptions = R.pathOr({}, ['_data'])(tabOptionsModel);
      tabOptions.rememberTabScrollTop = rememberTabScrollTop; // let tab

      if (tabOptions.tabType === 'singleton') {
        // check that tab doesnt already exist
        let idParam = 'component';
        let needle = tabOptions.component;

        if (tabOptions.id) {
          idParam = 'id';
          needle = tabOptions.id;
        }

        const isExists = tabs.findBy(idParam, needle);

        if (isExists) {
          if (tabOptions.id) {
            if (tabOptions.id === isExists.id) {
              return isExists;
            }
          } else {
            return isExists;
          }
        }
      }

      if (tabOptions.tabType === 'replace') {
        const isExists = tabs.findBy('component', tabOptions.component);
        tabs.removeObject(isExists);
        this.removeTabFromHistory(tabs, isExists);
      }

      const current = _model.default.create(tabOptions);

      let tabId = tabOptions.id;

      if (!tabId) {
        tabId = _nventor.default.random.alphaNum(8);
        current.set('id', tabId);
      } // @TODO: change id to position
      // no longer need id
      // id does not work when creating tabs within tabs.
      // eg. product tab is created. then new panel is created within product tab.
      // it will increment current product tab's id
      // const id = this.get('id') + 1;
      // this.set('id', id);
      // current.set('id', id);
      // if current model also implements tabs mixin, reset tabs and history so
      // that sub tabs will not be shared across other tab instances
      // if (current.resetTabs) {
      //   current.resetTabs();
      // }
      // tab = current


      tabs.pushObject(current);

      if (tab) {
        tab.set('tabsUpdated', tabId);
      }

      return current;
    },

    selectTab(tabs, tab) {
      let isModal = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (tab) {
        const scrollTop = window.pageYOffset;
        tabs.forEach(tab => {
          if (tab.get('isActive')) {
            tab.set('scrollTop', scrollTop);
          }

          tab.set('isActive', false);
        });
        tab.set('isActive', true);
        const newScrollTop = tab.get?.('scrollTop') || 0;

        if (!isModal) {
          Ember.run.scheduleOnce('afterRender', () => {
            if (tab?.rememberTabScrollTop) {
              window.scrollTo({
                top: newScrollTop,
                behavior: 'smooth'
              });
            } else {
              window.scrollTo({
                top: 0,
                behavior: 'auto'
              });
            }
          });
        }

        this.addTabToHistory(tabs, tab);
      }
    },

    getLastSelectedTab(tabs) {
      return tabs.get('_history').get('firstObject');
    },

    addTabToHistory(tabs, tab) {
      const history = tabs._history;
      history.removeObject(tab);
      history.unshiftObject(tab);
    },

    removeTabFromHistory(tabs, tab) {
      const history = tabs._history;
      history.removeObject(tab);
    },

    addToClosed(tabs, tab) {
      const closed = tabs._closed;
      closed.unshiftObject(tab);
    },

    /**
     * close all tabs (resetting unused tabs)
     * @param {Object[]} tabs
     */
    closeAllSubTabs(subTabs) {
      const subTabsClone = R.clone(subTabs);
      R.forEach(subTabClone => {
        const subTab = subTabs.findBy('id', subTabClone.id);
        self.closeTab(subTabs, subTab);
      })(subTabsClone);
    },

    /**
     * close tab
     * @param  {object} tabs - all tabs
     * @param  {object} tab - tab to close
     */
    closeTab() {
      let tabs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let tab = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // @TODO: set isActive=false
      // remove tab
      // set isClosing so that the view can destroy itself
      tab.set('isClosing', true);
      tabs.removeObject(tab); // remove tab from history

      this.removeTabFromHistory(tabs, tab);
      this.addToClosed(tabs, tab); // select next tab in history

      const lastSelectedTab = this.getLastSelectedTab(tabs);
      this.selectTab(tabs, lastSelectedTab); // const sidebarRight = this.get('sidebarRight') || []
      // if (sidebarRight.length === 0) {
      //   const sidebarRight = window.$('.app-grid__sidebar-right')
      //   if (!sidebarRight.hasClass('bot-sidebar-right')) {
      //     sidebarRight.removeClass('bot-sidebar-right')
      //   }
      // }
    },

    recoverLastTab() {
      const tabs = this.tabs;
      const deletedHistory = this.deletedHistory;
      const deleted = deletedHistory.shiftObject(); // add deleted tab back

      tabs.pushObject(deleted);
      this.selectTab(deleted);
    },

    getPath() {
      let tabsManagerModel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this;
      let path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      const tabs = R.pathOr([], ['left'])(tabsManagerModel);
      const index = R.pipe(R.pathOr([], [...path]), R.findIndex(R.propEq('isActive', true)))(tabs);

      if (index === -1) {
        return path;
      }

      path.push(index);
      path.push('subTabs');
      const hasSubTabs = R.pipe(R.pathOr([], path), R.isEmpty, R.not)(tabs);

      if (hasSubTabs) {
        return this.getPath(tabsManagerModel, path);
      }
    },

    getOuterMostTab() {
      const [index] = this.getPath();
      return R.pathOr({}, ['left', index])(this);
    },

    getActiveTab(tabsArray) {
      // hide all other tabs
      return tabsArray.findBy('isActive', true) || {};
    },

    getActiveSubTab(tab) {
      if (!tab) {
        return {};
      }

      const subTabs = tab.subTabs || []; // hide all other tabs

      return subTabs.findBy('isActive', true) || {};
    },

    isActiveSubTab(tab, data) {
      const active = this.getActiveSubTab(tab) || {};
      return R.pipe(R.keys, R.head, R.eqProps(R.__, active, data))(data);
    },

    reuseSubTab(tab) {
      for (var _len2 = arguments.length, tabOptions = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        tabOptions[_key2 - 1] = arguments[_key2];
      }

      const tabOptionsModel = this._createOptionsModel(tabOptions); // incorrect?


      tabOptionsModel.setData('tabType', 'singleton');
      const subTabs = tab.subTabs;

      const subTab = this._createTab(subTabs, tabOptionsModel, tab);

      this.selectTab(subTabs, subTab);
    },

    appendSubTab(tab) {
      for (var _len3 = arguments.length, tabOptions = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
        tabOptions[_key3 - 1] = arguments[_key3];
      }

      const tabOptionsModel = this._createOptionsModel(tabOptions);

      tabOptionsModel.setData('tabType', 'append');
      const subTabs = tab.subTabs;

      const subTab = this._createTab(subTabs, tabOptionsModel, tab);

      const isModal = tabOptionsModel.getData('isModal') || false;
      this.selectTab(subTabs, subTab, isModal);
      return subTabs;
    },

    replaceSubTab(tab) {
      for (var _len4 = arguments.length, tabOptions = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
        tabOptions[_key4 - 1] = arguments[_key4];
      }

      const tabOptionsModel = this._createOptionsModel(tabOptions);

      const activeTab = this.getActiveSubTab(tab);
      const activeTabData = R.pipe(R.pathOr([], ['subTabs']), R.find(R.pathEq(['isActive'], true)))(activeTab) || tab;
      const adapterName = R.propOr(tab.adapterName, 'adapterName')(activeTabData);
      const value = R.propOr('', 'value')(activeTabData);
      tabOptionsModel.setData('tabType', 'replace');
      tabOptionsModel.setData('adapterName', adapterName);
      tabOptionsModel.setData('value', value);
      const isModal = tabOptionsModel.getData('isModal') || false;
      const subTabs = tab.subTabs;

      const subTab = this._createTab(subTabs, tabOptionsModel, tab);

      this.selectTab(subTabs, subTab, isModal);
      return subTabs;
    },

    getLoadInstructions(tab) {
      if (!tab) {
        return false;
      }

      const loadInstructions = tab?.loadInstructions || false;

      if (!loadInstructions) {
        return false;
      } // already a model, dont re-create a model


      if (loadInstructions?._data) {
        return loadInstructions;
      }

      return _nventor.default.createModel(_model2.default, loadInstructions);
    },

    getLoadInstructionsAndClear(tab) {
      const loadInstructions = this.getLoadInstructions(tab);
      this.clearLoadInstructions(tab);
      return loadInstructions;
    },

    clearLoadInstructions(tab) {
      if (tab.loadInstructions) {
        tab.set('loadInstructions', null);
        return true;
      }

      return false;
    },

    triggerMenuAction(component, menu) {
      const action = R.path(['_data', 'action'])(menu);

      if (action) {
        return component[action](menu);
      }
    },

    displaySubPanel(component) {
      let resourceData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const tabType = resourceData.getData?.('tabType') || 'replaceSubTab';
      const tabOptions = resourceData?._data || {};
      const subTabOptions = R.mergeRight({}, tabOptions);
      subTabOptions.adapterName = resourceData?.adapterName;
      const tab = component.tab || component.args.tab;
      self[tabType](tab, subTabOptions);
    },

    getDefaultLoadInstructions(tab) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (tab) {
        const activeSubTab = this.getActiveSubTab(tab);

        if (!R.isEmpty(activeSubTab)) {
          if (options.onEdit) {
            return {
              loadInstructions: {
                component: activeSubTab.get('editingComponent'),
                value: activeSubTab.get('value'),
                isDisplay: activeSubTab.get('component')
              }
            };
          }

          return {
            loadInstructions: {
              component: activeSubTab.get('component'),
              value: activeSubTab.get('value')
            }
          };
        }
      }

      return {};
    },

    /**
     * Filtering out tabs base on missing component
     */
    filterOutTabMenu(tabsMenuSubPanels) {
      const filterSub = sub => {
        return sub?._data?.component;
      };

      const filteredTabMenu = R.filter(filterSub)(tabsMenuSubPanels);
      return filteredTabMenu;
    },

    setHeaderBottom() {
      let {
        element,
        headerBottom
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!headerBottom) {
        const domElement = element?.get?.('element'); // it only gets EmberComponent's element
        // need to handle the case for GlimmerComponent

        let value;
        const headerTitle = domElement?.querySelector?.('.panel-grid__header-title');

        if (headerTitle && !headerTitle.classList.contains('hidden')) {
          const titleRect = headerTitle?.getBoundingClientRect?.();
          const headerTitleDefaultOffset = 310; // unit: px

          value = titleRect?.bottom || headerTitleDefaultOffset;
        } else {
          const header = domElement?.querySelector?.('.panel-grid__header-with-guide-menu');
          const headerRect = header?.getBoundingClientRect?.();
          const tabHeaderDefaultOffset = 164; // unit: px

          value = headerRect?.bottom || tabHeaderDefaultOffset;
        }

        if (value) {
          Ember.set(element, 'headerBottom', value);
        }
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "registerErrors", [_dec], Object.getOwnPropertyDescriptor(_obj, "registerErrors"), _obj), _applyDecoratedDescriptor(_obj, "_setErrorsTabs", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_setErrorsTabs"), _obj), _applyDecoratedDescriptor(_obj, "_setErrorsAndErrorsCountOnTab", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_setErrorsAndErrorsCountOnTab"), _obj), _applyDecoratedDescriptor(_obj, "clearErrors", [_dec4], Object.getOwnPropertyDescriptor(_obj, "clearErrors"), _obj), _applyDecoratedDescriptor(_obj, "openTab", [_dec5], Object.getOwnPropertyDescriptor(_obj, "openTab"), _obj), _applyDecoratedDescriptor(_obj, "setHeaderBottom", [_dec6], Object.getOwnPropertyDescriptor(_obj, "setHeaderBottom"), _obj)), _obj));

  var _default = Ember.Service.extend(self);

  _exports.default = _default;
});