define("client/pods/channels/shop/campaigns/details/commission-base-cost/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        choices: [],
        variantChildren: [],
        setDetails: []
      };
    },

    populate(data) {
      const choices = data.choices || [];
      const variantChildren = data.variantChildren || [];
      const setDetails = data.setDetails || [];

      if (RA.isNotNilOrEmpty(choices)) {
        data = this.transformSetWithChoices(data);
      }

      if (RA.isNotNilOrEmpty(variantChildren)) {
        data = this.transformVariants(data);
      }

      if (RA.isNotNilOrEmpty(setDetails)) {
        data = this.transformSetDetails(data);
      }

      this._super(...arguments);
    },

    transformSetDetails(data) {
      if (data.setDetails) {
        const detailsRequiredAttrs = this.setDetailsRequiredAttrs;
        data.setDetails = R.pipe(R.propOr([], 'setDetails'), R.map(detailData => {
          const detailModel = this.dataManager.setAsRecord({
            adapterName: 'products/sets/details',
            data: detailData,
            attrs: {
              requiredAttrs: detailsRequiredAttrs
            }
          });
          return detailModel;
        }))(data);
      }

      return data;
    },

    transformSetWithChoices(data) {
      data.choices = R.pipe(R.propOr([], 'choices'), R.map(choiceData => {
        const details = choiceData.details || [];
        choiceData.details = R.map(detailData => {
          if (detailData.hasVariants) {
            detailData = this.transformVariants(detailData);
          } else if (detailData.isSet) {
            detailData = this.transformSetDetails(detailData);
          }

          return this.dataManager.setAsRecord({
            adapterName: 'products/choices/details',
            data: detailData
          });
        })(details);
        return this.dataManager.setAsRecord({
          adapterName: 'products/choices',
          data: choiceData
        });
      }))(data);
      return data;
    },

    transformVariants(data) {
      data.variants = R.pipe(R.propOr([], 'variants'), R.map(variantData => {
        variantData.options = R.pipe(R.propOr([], 'options'), R.map(optionData => {
          return this.dataManager.setAsRecord({
            adapterName: 'products/variants/options',
            data: optionData
          });
        }))(variantData);
        return this.dataManager.setAsRecord({
          adapterName: 'products/variants',
          data: variantData
        });
      }))(data);
      data.variantChildren = R.pipe(R.propOr([], 'variantChildren'), R.map(variantChild => {
        const variantChildModel = this.dataManager.setAsRecord({
          adapterName: 'products/variants/children',
          data: variantChild
        });
        return variantChildModel;
      }))(data);
      return data;
    }

  });

  _exports.default = _default;
});