define("client/pods/components/guests/campaign-main/campaign-commissions/component", ["exports", "ember-concurrency", "ramda"], function (_exports, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    guests: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.fetchTask.perform();
    },

    fetchTask: (0, _emberConcurrency.task)(function* () {
      const token = this.get('guests').getToken();
      const searchSettings = {
        adapterName: 'guests/shop/campaigns',
        token
      };
      let campaign;

      try {
        campaign = yield this.crud.findTask.perform(searchSettings);

        if (R.isNil(campaign)) {
          throw Error('null campaign');
        }
      } catch (error) {
        return this.guests.logout();
      }

      this.set('campaign', campaign); // const master = campaign.getData('_key')
      // const filters = this.search.setupFilters({
      //   adapterName: 'channels/shop/campaigns/details/filters',
      //   defaultFiltersData: {
      //     master,
      //     appendPath: `/master/${master}`
      //   }
      // })
      // this.set('filters', filters)
    })
  });

  _exports.default = _default;
});