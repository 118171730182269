define("client/helpers/get-error-by-index", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function getErrorByIndex(params
  /*, hash*/
  ) {
    const errors = R.path([0])(params);
    const index = R.path([1])(params);

    if (RA.isNotNilOrEmpty(errors)) {
      return R.find(R.propEq('index', index))(errors);
    }

    return params;
  });

  _exports.default = _default;
});