define("client/pods/components/documents/panel-dash/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      const docType = this.docType;
      const docName = this.docName;
      const tableViewOptions = {
        hasDispatchProcess: false,
        allowDispatching: false
      };

      if (docType === 'sales' && (docName === 'invoices' || docName === 'consignments')) {
        tableViewOptions.hasDispatchProcess = true;
        tableViewOptions.allowDispatching = true;
      }

      this.set('tableViewOptions', tableViewOptions);
      this.tabsManager.setHeader(this.tabParent, '');
    }

  });

  _exports.default = _default;
});