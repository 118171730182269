define("client/pods/components/contacts/panels-edit/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    adapterName: 'contacts',
    init: function () {
      this._super(...arguments);

      const dirty = this.setupDirty();
      this.set('dirty', dirty);
      this.tabsManager.setHeader(this.tabParent, dirty.getData('name'));
    }
  });

  _exports.default = _default;
});