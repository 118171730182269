define("client/pods/flows/steps/actions/games/prizes/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _model, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    defaults() {
      return {
        subStepId: _nventor.default.random.alphaNum(17),
        prizeType: 'generatePromoCode',
        isPrize: true,
        name: '',
        description: '',
        successfulMessage: '',
        defaults: {}
      };
    },

    populate(data, attrs) {
      if (RA.isNotNilOrEmpty(data?.defaults) && data.prizeType === 'generatePromoCode') {
        data.defaults = this._transformDefaults(data);
      }

      this._super(data, attrs);
    },

    setupPrizeType(_ref) {
      let {
        prizeType,
        shopKey,
        shop
      } = _ref;
      // @TODO: make this the same as action
      let hasSetupPrizeType = this?._data?.hasSetupPrizeType || '';

      if (!prizeType || prizeType !== hasSetupPrizeType) {
        if (prizeType === 'generatePromoCode') {
          const promoCodeModel = this.getPromoCodeModel({
            shopKey,
            shop
          });
          this.setData('defaults', promoCodeModel);
          this.setData('hasSetupPrizeType', prizeType);
        }
      }
    },

    getPromoCodeModel() {
      let {
        defaultsData = {},
        shopKey,
        shop
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (RA.isNilOrEmpty(defaultsData)) {
        defaultsData = {
          dateEndZ: '',
          dateStartZ: '',
          discountAmount: 0,
          discountRate: 0,
          discountRateOnSale: 0,
          status: 'active',
          type: 'rate',
          usageLimit: 1,
          allowedShopKey: shopKey,
          allowedShop: shop
        };
      }

      return this.dataManager.setAsRecord({
        adapterName: 'flows/steps/actions/promo-codes-default',
        data: defaultsData
      });
    },

    _transformDefaults(data) {
      let defaultsData = R.pathOr({}, ['defaults'])(data);
      return this.getPromoCodeModel({
        defaultsData
      });
    },

    actionsComponent: Ember.computed('_data.prizeType', function () {
      if (this?._data?.prizeType === 'generatePromoCode') {
        const prefix = 'flows/flows-editor/steps-editor/actions-editor';
        return `${prefix}/generate-promo-code`;
      }

      return '';
    })
  }, (_applyDecoratedDescriptor(_obj, "setupPrizeType", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupPrizeType"), _obj), _applyDecoratedDescriptor(_obj, "getPromoCodeModel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "getPromoCodeModel"), _obj), _applyDecoratedDescriptor(_obj, "_transformDefaults", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_transformDefaults"), _obj)), _obj)));

  _exports.default = _default;
});