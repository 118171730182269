define("client/pods/components/websites/websites-editor/website-wizard/quick-start/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XM9BufYP",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[4,\"forms/form-section\",null,[[\"context\",\"documentationResource\"],[\"accountAdmin\",\"admin\"]],{\"statements\":[[4,\"forms/form-input-row\",null,[[\"label\",\"isRequired\",\"errors\",\"context\",\"documentationResource\"],[\"name\",true,[24,[\"errors\"]],\"name\",\"websites\"]],{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-input\",null,[[\"autofocus\",\"readonly\",\"value\"],[true,[24,[\"isReadonly\"]],[23,1,[\"_data\",\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"isRequired\",\"errors\",\"context\",\"documentationResource\"],[\"website url (at least 6 characters)\",true,[24,[\"errors\"]],\"url\",\"websites\"]],{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"regex\",\"value\"],[[24,[\"isReadonly\"]],\"[^-\\\\d\\\\w]\",[23,1,[\"_data\",\"subdomain\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[23,1,[\"_data\",\"subdomain\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--mini u-margin-top--6\"],[8],[0,\"\\n        https://\"],[1,[23,1,[\"_data\",\"subdomain\"]],false],[0,\".bigordr.com\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/websites-editor/website-wizard/quick-start/template.hbs"
    }
  });

  _exports.default = _default;
});