define("client/pods/components/taxes-editor/component", ["exports", "ramda", "@glimmer/component"], function (_exports, _ramda, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TaxesEditor = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class TaxesEditor extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "big", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);

      _initializerDefineProperty(this, "model", _descriptor4, this);

      _defineProperty(this, "adapterName", this.args?.customAdapterName || this.args?.adapterName || 'taxes');

      this.crud.addLists(this, ['taxes']);
      const model = this.args?.model || {};
      const tab = this.args?.tab || {};
      this.tab = tab;
      const isEditing = this.args?.isEditing;

      if (isEditing) {
        const dirty = this.crud.setupDirty({
          adapterName: this.adapterName,
          model,
          tab
        });
        const rate = this.big?.getDataBig?.('value', dirty);
        const percentage = rate.times(100).toFixed();
        dirty.setData('percentage', percentage);
        this.model = dirty;
      } else {
        this.model = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          tab
        });
      }
    }

    willDestroy() {
      this.crud.cancelEditing({
        adapterName: this.adapterName,
        dirty: this.model,
        tab: this.tab
      });
    }

    get isMinRequiredMet() {
      const minRequired = ['label', 'value', 'percentage'];
      const model = this.model;
      let met = true;
      minRequired.forEach(required => {
        if (_ramda.default.isEmpty(model.getData(required))) {
          met = false;
        }
      });
      return met;
    }

    afterCreateRecordTask(model) {
      this.args?.onAddItem?.(model);
      this.args?.onCloseDialogAction?.();
    }

    afterReplaceRecordTask(model) {
      this.args?.onUpdateItem?.(model);
    }

    afterRemoveRecordTask() {
      this.args?.onRemoveItem?.(this.model);
    }

    autoSetDescription() {
      const model = this.model;
      const percentageBig = this.big?.getDataBig?.('percentage', model);
      const rate = percentageBig.div(100).toFixed(2);
      const percentage = model.getData('percentage') + '%';
      model.setData('label', percentage);
      model.setData('value', rate);
    }

    autoClearDescription() {
      const model = this.model;
      model.setData('label', '');
      model.setData('value', '');
    }

    cancel() {
      this.args?.onCloseDialogAction?.();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "big", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterCreateRecordTask", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "afterCreateRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterReplaceRecordTask", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "afterReplaceRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterRemoveRecordTask", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "afterRemoveRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "autoSetDescription", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "autoSetDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "autoClearDescription", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "autoClearDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  _exports.default = TaxesEditor;
});