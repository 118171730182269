define("client/pods/components/documents/document-totals/spend-rewards-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NBsIPjhX",
    "block": "{\"symbols\":[\"@onSumTotals\",\"@label\",\"@isReadonly\",\"@model\",\"@isRunning\",\"@onCloseDialogAction\",\"@disabled\"],\"statements\":[[5,\"forms/form-section\",[[3,\"did-insert\",[[23,1,[]]]]],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[[23,2,[]],[23,0,[\"errors\"]],\"rewardPointApplied\",\"documents\"]],{\"statements\":[[0,\"\\n    \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@onInput\",\"@value\"],[[23,3,[]],[23,1,[]],[23,4,[\"_data\",\"rewardPointApplied\"]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[7,\"table\",true],[10,\"class\",\"u-table\"],[8],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[28,\"tt\",[\"valid points\"],null],false],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\":\"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[28,\"tt\",[\"points\"],[[\"points\"],[[23,4,[\"_data\",\"fetchedContactRewardData\",\"validPoints\"]]]]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[5,\"layouts/footer-btns\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/btns-group\",[],[[\"@classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/btn-cancel\",[],[[\"@isRunning\",\"@onSubmit\"],[[23,5,[]],[23,6,[]]]]],[0,\"\\n\\n      \"],[5,\"elements/btn-save\",[],[[\"@icon\",\"@label\",\"@disabled\",\"@onSubmit\"],[false,\"done\",[23,7,[]],[23,6,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/document-totals/spend-rewards-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});