define("client/pods/payment-methods/installment-periods/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model", "ramda"], function (_exports, _adapter, _nventor, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let data = [{
        label: '3',
        value: 3
      }, {
        label: '6',
        value: 6
      }, {
        label: '9',
        value: 9
      }, {
        label: '12',
        value: 12
      }, {
        label: '18',
        value: 18
      }, {
        label: '24',
        value: 24
      }, {
        label: '30',
        value: 30
      }, {
        label: '36',
        value: 36
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});