define("client/pods/components/extensions/apps/tw/e-invoices/print-manager/invoice-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '' // isPending: computed('invoice._data.status', function () {
    //   const status = this.get('invoice._data.status')
    //   if (status === 'pending' || status === 'pendingCancellation') {
    //     return true
    //   }
    //   return false
    // }),
    // isSuccessful: computed('invoice._data.status', function () {
    //   const status = this.get('invoice._data.status')
    //   if (status === 'successful') {
    //     return true
    //   }
    //   return false
    // }),
    // isCancelledOrUnsuccessful: computed('invoice._data.status', function () {
    //   const status = this.get('invoice._data.status')
    //   if (status === 'unsuccessful' || status === 'cancelled') {
    //     return true
    //   }
    //   return false
    // }),
    // statusClassName: computed('invoice._data.status', function () {
    //   const status = this.get('invoice._data.status')
    //   if (status === 'successful') {
    //     return 'is-success'
    //   }
    //   if (status === 'cancelled') {
    //     return 'is-danger'
    //   }
    //   if (status === 'unsuccessful') {
    //     return 'is-danger'
    //   }
    //   return 'is-warning'
    // })

  });

  _exports.default = _default;
});