define("client/helpers/listlabel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params, hash) {
      // {{list-label itemLabelKey list needle itemValueKey}}
      // const key = params[0];
      let list = [];
      let itemValueKey = '_data.value';
      let itemLabelKey = '_data.label';
      let defaultValue = '';
      let needle = '';
      let label = '';
      let translate = true;

      if (hash.translate === false) {
        translate = false;
      }

      if (hash.list) {
        list = hash.list;
      }

      if (hash.itemValueKey) {
        itemValueKey = hash.itemValueKey;
      }

      if (hash.itemLabelKey) {
        itemLabelKey = hash.itemLabelKey;
      }

      if (hash.needle) {
        needle = hash.needle;
      }

      if (hash.defaultValue) {
        defaultValue = hash.defaultValue;
      }

      const found = list.findBy(itemValueKey, needle);

      if (found) {
        label = found.get(itemLabelKey);

        if (translate && label) {
          return this.intl.t(label);
        }

        if (label) {
          return label;
        }
      }

      if (defaultValue) {
        return defaultValue;
      }

      return '';
    }

  });

  _exports.default = _default;
});