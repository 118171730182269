define("client/pods/components/extensions/apps/tw/e-invoices/tracks-manager/blanks-uploader/component", ["exports", "@glimmer/component", "ramda-extension"], function (_exports, _component, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwEInvoicesTracksManagerBlanksUploaderComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service('extensions/apps/tw/e-invoices'), _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = class ExtensionsAppsTwEInvoicesTracksManagerBlanksUploaderComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dateService", _descriptor2, this);

      _initializerDefineProperty(this, "eInvoicesService", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "uploadUnusedBlanks", _descriptor5, this);

      _initializerDefineProperty(this, "uploadedResults", _descriptor6, this);

      _initializerDefineProperty(this, "wasUploaded", _descriptor7, this);
    }

    get blanksToUpload() {
      if (this.wasUploaded) {
        return R_.dotPath('uploadedResults.blanksStatus')(this) || [];
      }

      return R_.dotPath('args.track._data.blanksToUpload')(this) || [];
    } // @task
    // * uploadBlanksTask (selectedPeriod) {
    //   this.selectedPeriod = selectedPeriod
    //   const mm = selectedPeriod.mm
    //   const yyyy = this.args.yyyy
    //   try {
    //     const results = yield this.eInvoicesService.uploadBlanksTask.perform({ mm, yyyy, uploadUnusedBlanks: this.uploadUnusedBlanks })
    //     const data = R.propOr({}, 'data')(results)
    //     this.uploadedResults = {
    //       blanksStatus: data.blanksStatus || [],
    //       blanksToUpload: data.blanksToUpload || []
    //     }
    //     this.wasUploaded = true
    //   } catch (err) {
    //     this.wasUploaded = true
    //   }
    // }


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "eInvoicesService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uploadUnusedBlanks", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uploadedResults", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "wasUploaded", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = ExtensionsAppsTwEInvoicesTracksManagerBlanksUploaderComponent;
});