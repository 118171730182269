define("client/pods/components/channels/shop/campaigns/campaigns-editor/reward-editor/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsCampaignsEditorRewardEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ChannelsShopCampaignsCampaignsEditorRewardEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "search", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "filters", _descriptor3, this);

      _defineProperty(this, "adapterName", 'channels/shop/rewards');

      this.filters = this.search.setupFilters({
        adapterName: 'channels/shop/rewards/filters',
        defaultFiltersData: {
          query: '',
          status: ['active']
        }
      });
    } // @task
    // * fetchRewardTask ({ query }) {
    //   query = query.trim()
    //   if (query) {
    //     const filters = this.filters
    //     const adapterName = this.adapterName
    //     filters.setData('query', query)
    //     const results = yield this.crud.searchInputTask.perform({
    //       adapterName,
    //       filters,
    //       component: this,
    //       resultsProperty: 'rewardsList'
    //     })
    //     return results
    //   }
    // }


    onLoadItem(selectedItem) {
      this.args.model.set('_data.rewardKey', selectedItem._data._key);
      this.args.model.set('_data.isAllowSpendReward', true);
      this.args.model.set('_data.isAllowEarnReward', true);
    }

    onResetItem() {
      this.args.model.set('_data.rewardKey', '');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onLoadItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onLoadItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onResetItem", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onResetItem"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorRewardEditorComponent;
});