define("client/pods/components/payment-terms-editor/component", ["exports", "client/mixins/crud", "ramda-extension"], function (_exports, _crud, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'paymentTerms',

    init() {
      this.crud.addLists(this, ['paymentTerms', 'paymentTerms/from', 'paymentTerms/end']);

      this._super(...arguments);

      if (this.isEditing) {
        const dirty = this.setupDirty();
        this.set('model', dirty);
      } else {
        const model = this.setupNewRecord({
          data: {
            months: 0,
            days: 0,
            from: this.get('constants.paymentTermsFrom.invoiceMonth'),
            end: this.get('constants.paymentTermsEnd.exactDate')
          }
        });
        this.set('model', model);
      }
    },

    isExactDate: Ember.computed('model._data.end', function () {
      const model = this.model;

      if (model.getData('end') === this.get('constants.paymentTermsEnd.exactDate')) {
        return true;
      }

      return false;
    }),
    isMinRequiredMet: Ember.computed('model._data.{from,end}', function () {
      const minRequired = ['from', 'end'];
      const model = this.model;
      let met = true;
      minRequired.forEach(function (required) {
        if (Ember.isEmpty(model.getData(required))) {
          met = false;
        }
      });
      return met;
    }),

    _setDescription() {
      const model = this.model;
      const intl = this.intl;
      let description = '';
      const months = model.getDataInt('months');

      if (months > 0) {
        description = intl.t('months') + ': ' + months;
      }

      const days = model.getDataInt('days');

      if (days > 0) {
        if (months > 0) {
          description = `${description}, `;
        }

        description = description + intl.t('days') + ': ' + days;
      }

      const fromList = R_.dotPath('crud.lists.paymentTerms-from')(this) || [];
      const from = fromList.findBy('_data.value', model.getData('from'));
      const endList = R_.dotPath('crud.lists.paymentTerms-end')(this) || [];
      const end = endList.findBy('_data.value', model.getData('end'));

      if (description) {
        description = description + '. ';
      }

      description = description + intl.t('from') + ': ' + intl.t(from.getData('label')) + '. ';
      description = description + intl.t('end') + ': ' + intl.t(end.getData('label')) + '.';
      model.setData('label', description);
    },

    // autoSetDescription: Ember.observer('model._data.months', 'model._data.days', 'model._data.from', function () {
    //
    //   this._setDescription();
    // }),
    beforeCreateRecord(model) {
      const months = model.getDataInt('months');
      model.setData('months', months);
      const days = model.getDataInt('days');
      model.setData('days', days);

      if (!model.getData('label')) {
        this._setDescription();
      }

      return model;
    },

    afterCreateRecordTask(model) {
      this.onAddItem(model);
      this.onCloseDialogAction();
    },

    beforeReplaceRecord(model) {
      const months = model.getDataInt('months');
      model.setData('months', months);
      const days = model.getDataInt('days');
      model.setData('days', days);

      if (!model.getData('label')) {
        this._setDescription();
      }

      return model;
    },

    afterReplaceRecordTask(model) {
      this.onUpdateItem(model);
    },

    afterRemoveRecordTask() {
      this.onRemoveItem(this.model);
    },

    actions: {
      cancel(model) {
        if (this.isEditing) {
          this.cancelEditing({
            dirty: model
          });
          this.set('showEditor', false);
        } else {
          this.onCloseDialogAction();
        }
      }

    }
  });

  _exports.default = _default;
});