define("client/pods/contacts/batch/statuses/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new"], function (_exports, R, _adapter, _adaptersNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/contacts',
    idParam: false,

    serialize(contacts) {
      const batch = R.map(contact => {
        return {
          _key: contact?._data._key
        };
      })(contacts);
      return {
        batch
      };
    }

  });

  _exports.default = _default;
});