define("client/pods/components/extensions/apps/tw/invoices/tab-container/component", ["exports", "client/mixins/date", "client/mixins/big", "client/pods/components/extensions/apps/tw/invoices/mixins/invoices", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _big, _invoices, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _invoices.default, _big.default, _date.default, {
    model: null,
    errors: null,
    customDateZ: '',
    invoiceRollSize: 250,
    adapterName: 'extensions/apps/tw/invoices',

    init() {
      this._super(...arguments);

      const pastDays = this.getPastDays(14);
      this.set('pastDays', pastDays);
      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
    },

    displayDatePanelTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        dateEnd,
        dateStart
      } = _ref;
      return function* () {
        const filters = _this.search.setupFilters({
          adapterName: 'filters',
          defaultFiltersData: {
            dateStart,
            dateEnd
          }
        });

        const results = yield _this.searchRecordsTask.perform({
          filters
        });
        const subTabOptions = {
          component: 'extensions/apps/tw/invoices/panels-display',
          results: results,
          dateEnd: dateEnd,
          dateStart: dateStart
        };
        const tab = _this.tab;

        _this.tabsManager.replaceSubTab(tab, subTabOptions);
      }();
    }),
    displaySummaryPanelTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        monthEnd,
        monthStart
      } = _ref2;
      return function* () {
        const data = {
          monthEnd,
          monthStart
        };
        const report = yield _this2.getAdapter().call({
          method: 'GET',
          data
        });
        const subTabOptions = {
          component: 'extensions/apps/tw/invoices/panels-summary',
          report: report
        };
        const tab = _this2.tab;

        _this2.tabsManager.replaceSubTab(tab, subTabOptions);
      }();
    }),
    actions: {
      displayByCustomDate(dateZ) {
        const self = this;
        const dateEnd = self.getMoment(dateZ).format('YYYY-MM-DD');
        this.displayDatePanelTask.perform({
          dateEnd
        });
      },

      /**
       * display dispatched list for given date
       * @param  {string} date (YYYY-MM-DD)
       */
      displayByDate(period) {
        const dateEnd = period.date;
        this.displayDatePanelTask.perform({
          dateEnd
        });
      },

      /**
       * display dispatched list for given date
       * @param  {string} date (YYYY-MM-DD)
       */
      displayByMonth(period) {
        const monthStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
        const monthEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
        this.displaySummaryPanelTask.perform({
          monthEnd,
          monthStart
        });
      }

    }
  });

  _exports.default = _default;
});