define("client/pods/components/workflows/workflow-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GoTmZ559",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@model\",\"@btnClassNames\",\"@dispatchProcessCount\",\"@onSubmit\",\"@isRunning\"],\"statements\":[[4,\"unless\",[[23,2,[\"isDeleted\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"elements/element-btn\",[],[[\"@label\",\"@translate\",\"@classNames\",\"@hasBadge\",\"@badge\",\"@onSubmit\",\"@isRunning\"],[[23,2,[\"_data\",\"name\"]],[28,\"if\",[[23,2,[\"_data\",\"translate\"]],true,false],null],[23,3,[]],[28,\"isGreaterThan\",[[24,[\"dispatchProcessCount\"]],0],null],[23,4,[]],[23,5,[]],[23,6,[]]]]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isEditable\"]]],null,{\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"icon\",\"closeOnOverlayClick\"],[\"fas fa-pen fa-xs\",false]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"dispatch/processes-editor\",null,[[\"adapterName\",\"model\",\"hasProcessCount\",\"onCloseDialogAction\",\"isEditing\"],[\"dispatch/lists/processes\",[24,[\"dispatchProcess\"]],[24,[\"hasProcessCount\"]],[23,1,[]],true]]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/workflows/workflow-btn/template.hbs"
    }
  });

  _exports.default = _default;
});