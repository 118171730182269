define("client/pods/components/channels/shop/campaigns/panels-dash/component", ["exports", "ember-concurrency", "client/mixins/date", "client/pods/components/channels/shop/campaigns/mixins/campaigns-list-by", "ramda-adjunct"], function (_exports, _emberConcurrency, _date, _campaignsListBy, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_campaignsListBy.default, _date.default, {
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    adapterName: 'channels/shop/campaigns',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['periods/months', 'periods/years', 'channels/shop/campaigns/reports/filters', 'taxMethods/filters']);
      const customDateStartZ = this.dateService.getMonthStart().toISOString();
      const customDateEndZ = this.dateService.getMonthEnd().toISOString();
      this.customDateStartZ = customDateStartZ;
      this.customDateEndZ = customDateEndZ;
      this.set('showCampaignReportBy', Ember.Object.create({
        revenueBy: 'ordered',
        taxMethods: 'inclusive'
      }));
      const period = this.getPeriod();
      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
      this.displayByMonth(period);
      this.tabsManager.setHeader(this.tabParent, this.intl.t('overview'));
    },

    displayByMonth(period) {
      const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm));
      this.display({
        dateStart,
        dateEnd
      });
    },

    display(period) {
      const {
        dateStart,
        dateEnd
      } = period;
      this.set('period', period);
      this.set('dateStart', dateStart);
      this.set('dateEnd', dateEnd);
      this.fetchReportsTask.perform({
        dateStart,
        dateEnd
      });
    },

    dispatchStatusFilter: Ember.computed('showCampaignReportBy.revenueBy', function () {
      const revenueBy = this.get('showCampaignReportBy.revenueBy');

      if (revenueBy === 'ordered') {
        return 'all';
      }

      return this.get('constants.dispatchStatus.dispatched');
    }),
    fetchReportsTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        dateStart,
        dateEnd
      } = _ref;
      return function* () {
        const monthlyReport = yield _this._fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['dateZ']
        });

        _this.set('monthlyReport', monthlyReport);

        _this.set('itemReport', []);

        _this.set('sourceReport', []);

        _this.set('paymentMethodKeyReport', []);

        _this.set('contactReport', []); // this.fetchYearTask.perform({ dateStart, dateEnd })

      }();
    }),
    displayItemReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';

      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const itemReport = yield this._fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['item']
        });
        this.set('itemReport', itemReport);
      }
    }),
    displaySourceReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';

      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const sourceReport = yield this._fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['source']
        });
        this.set('sourceReport', sourceReport);
      }
    }),
    displayPaymentMethodKeyReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';

      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const paymentMethodKeyReport = yield this._fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['paymentMethodKey']
        });
        this.set('paymentMethodKeyReport', paymentMethodKeyReport);
      }
    }),
    displayContactReportTask: (0, _emberConcurrency.task)(function* () {
      const dateStart = this.dateStart || '';
      const dateEnd = this.dateEnd || '';

      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        const contactReport = yield this._fetchReportTask.perform({
          dateStart,
          dateEnd,
          segments: ['contact', 'contactTags', 'customerType', 'memberType', 'gender', 'age']
        });
        this.set('contactReport', contactReport);
      }
    }),
    _fetchReportTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        dateStart,
        dateEnd,
        segments = []
      } = _ref2;
      return function* () {
        const monthParams = {
          docType: 'sales',
          dateStart,
          dateEnd,
          match: ['channel'],
          status: _this2.get('constants.documentsStatus.final'),
          dispatchStatus: _this2.dispatchStatusFilter,
          query: 'shop',
          // segments: [
          //   'dateZ',
          //   'contact',
          //   'item',
          //   'channel',
          //   'source',
          //   'paymentMethodKey',
          //   'promoCode',
          //   'customerType',
          //   'memberType',
          //   'gender',
          //   'age'
          // ]
          segments // segments: ['dateZ', 'contact', 'item', 'itemWithGroupedSetWithChoices', 'channel', 'source', 'paymentMethodKey', 'promoCode']

        };
        const results = yield _this2.server.call('GET', 'api/protected/reports/dashboard/', monthParams);
        return results.data; // this.set('monthlyReport', month.data)
      }();
    }),
    fetchYearTask: (0, _emberConcurrency.task)(function (_ref3) {
      let {
        dateStart,
        dateEnd
      } = _ref3;
      return function* () {// @NOTE: this is taking too long
        // const yearParams = {
        //   docType: 'sales',
        //   dateStart: this.formatDateForUrl(this.getYearStart(dateStart)),
        //   dateEnd: this.formatDateForUrl(this.getYearEnd(dateEnd)),
        //   match: ['channel'],
        //   status: this.get('constants.documentsStatus.final'),
        //   dispatchStatus: this.dispatchStatusFilter,
        //   query: 'shop',
        //   segments: ['month']
        // }
        // const year = yield this.server.call('GET', 'api/protected/reports/dashboard/', yearParams)
        // this.set('yearlyReport', year.data)
      }();
    }),
    isTaxExclusive: Ember.computed('showCampaignReportBy.taxMethods', function () {
      if (this.get('showCampaignReportBy.taxMethods') === 'exclusive') {
        return true;
      }

      return false;
    }),
    actions: {
      display(model) {
        this.display(model);
      },

      displayByMonth(period) {
        this.displayByMonth(period);
      },

      displayByCustom() {
        if (!this.customDateEndZ || !this.customDateStartZ) {
          return;
        }

        const period = this.dateService.getCustomPeriod(this.customDateEndZ, this.customDateStartZ);
        this.display(period);
      },

      fetchReports(_ref4) {
        let {
          dateStart,
          dateEnd
        } = _ref4;
        dateStart = dateStart || this.dateStart;
        dateEnd = dateEnd || this.dateEnd;
        this.fetchReportsTask.perform({
          dateStart,
          dateEnd
        });
      }

    }
  });

  _exports.default = _default;
});