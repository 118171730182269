define("client/pods/application/service", ["exports", "ramda", "client/utils/nventor", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, R, _nventor, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class ApplicationService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dataManager", _descriptor, this);

      _initializerDefineProperty(this, "isMobile", _descriptor2, this);

      _defineProperty(this, "_splitBy", R.curry((char, data) => {
        return R.pipe(R.split(char), R.map(R.trim), R.reject(_nventor.default.isNilOrEmpty))(data);
      }));

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }

    stubFunction() {
      return;
    }

    resizeTask(_ref) {
      var _this = this;

      let {
        contentRect: {
          width,
          height
        }
      } = _ref;
      return function* () {
        const DEBOUNCE_MS = 500;
        yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);

        if (width <= 900) {
          _this.isMobile = true;
        } else {
          _this.isMobile = false;
        }
      }();
    }

    splitToArray() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      if (data == null) {
        return '';
      }

      return R.pipe(R.trim, R.replace(/,/g, ' '), R.replace(/\n/g, ' '), this._splitBy(' '))(data);
    }

    joinAsString() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return R.pipe(_nventor.default.convertToArray, R.join('\n'))(data);
    }

    copyText() {
      let text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let onAfter = arguments.length > 1 ? arguments[1] : undefined;
      const el = document.createElement('textarea');
      el.value = text;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      if (onAfter) {
        onAfter();
      }
    }

    processMenu(_ref2) {
      let {
        allowed,
        allPinned,
        onlyPinned = false
      } = _ref2;

      const setIsPinned = _ref3 => {
        let {
          allPinned,
          menu
        } = _ref3;
        let isPinned = false;
        let pinnedKey = false;
        const menuLabel = R.pathOr('', ['_data', 'pinLabel'])(menu);
        const pinnedItem = R.find(R.pathEq(['_data', 'pinLabel'], menuLabel))(allPinned);
        const pinnedItemIndex = R.findIndex(R.pathEq(['_data', 'pinLabel'], menuLabel))(allPinned);

        if (pinnedItem) {
          isPinned = true;
          pinnedKey = pinnedItem.getData('_key');

          if (onlyPinned) {
            const id = pinnedItem.getData('_id');
            const copy = this.dataManager.copyRecord('users/personal-menu', menu);
            copy.setData('_id', id);
            copy.setData('_key', pinnedKey);
            copy.setData('isPinned', true);
            copy.setData('pinnedItem', null);
            copy.setData('isExpanded', false);
            copy.setData('isFavoritesItem', true);
            allPinned.replace(pinnedItemIndex, 1, [copy]);
          } else {
            menu.set('pinnedItem', pinnedItem);
          }
        }

        menu.setData('_key', pinnedKey);
        menu.setData('isPinned', isPinned);
        return menu;
      };

      const allowedMenu = R.map(menu => {
        setIsPinned({
          menu,
          allPinned
        });
        const groups = R.pipe(R.pathOr([], ['_data', 'groups']), R.map(group => {
          return R.map(groupMenu => {
            const sub = R.pipe(R.pathOr([], ['_data', 'sub']), R.map(subMenu => {
              return setIsPinned({
                menu: subMenu,
                allPinned
              });
            }))(groupMenu);
            groupMenu.setData('sub', sub);
            return setIsPinned({
              menu: groupMenu,
              allPinned
            });
          })(group);
        }))(menu);
        menu.setData('groups', groups);
        const sub = R.pipe(R.pathOr([], ['_data', 'sub']), R.map(subMenu => {
          return setIsPinned({
            menu: subMenu,
            allPinned
          });
        }))(menu);
        menu.setData('sub', sub);
        return menu;
      })(allowed);

      if (onlyPinned) {
        return allPinned;
      }

      return allowedMenu;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isMobile", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "stubFunction", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "stubFunction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resizeTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "resizeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "splitToArray", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "splitToArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "joinAsString", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "joinAsString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyText", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "copyText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "processMenu", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "processMenu"), _class.prototype)), _class));
  _exports.default = ApplicationService;
});