define("client/pods/ledgers/journals/cashbook/lists/menu/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        label: 'overview',
        component: 'ledgers/journals/cashbook/panels-display',
        allowNewDetail: false,
        txType: '',
        docType: '',
        tabType: 'replaceSubTab'
      }, {
        label: 'receipts',
        component: 'ledgers/journals/cashbook/panels-display',
        allowNewDetail: true,
        txType: 'cashIn',
        docType: 'sales',
        tabType: 'replaceSubTab'
      }, {
        label: 'payments',
        component: 'ledgers/journals/cashbook/panels-display',
        allowNewDetail: true,
        txType: 'cashOut',
        docType: 'purchases',
        tabType: 'replaceSubTab'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});