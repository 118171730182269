define("client/pods/components/documents/batch/status/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentsBatchStatusComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class DocumentsBatchStatusComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "showModal", _descriptor, this);
    }

    get isModalVisible() {
      const modalDialogIsVisible = R.pathEq(['args', 'modalDialogIsVisible'], true)(this);

      if (modalDialogIsVisible) {
        return true;
      }

      const hasUnsuccessfulBatch = R.pipe(R.pathOr([], ['args', 'batch', 'unsuccessful']), R.propSatisfies(R.gte(R.__, 1), 'length'))(this);

      if (hasUnsuccessfulBatch) {
        return true;
      }

      return false;
    }

    onClose(onCloseDialogAction) {
      const resetStatus = this.args.resetStatus;

      if (resetStatus) {
        resetStatus();
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showModal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype)), _class));
  _exports.default = DocumentsBatchStatusComponent;
});