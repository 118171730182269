define("client/pods/components/extensions/apps/tw/momo/orders/table-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rz2767Mf",
    "block": "{\"symbols\":[\"@fetchOrdersTask\",\"@errors\",\"@dateRange\",\"@tab\",\"@currentOrders\",\"@ordersFilters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onEdit\",\"@onDisplay\",\"@refreshOrders\"],\"statements\":[[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,1,[\"isRunning\"]]]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"date range\",[23,2,[]],\"momoOrders\",\"momoOrdersDateRange\"]],{\"statements\":[[0,\"\\n\\n    \"],[5,\"lists/list-dropdown\",[],[[\"@isBtn\",\"@translate\",\"@readonly\",\"@list\",\"@value\",\"@onSelect\"],[true,true,true,[23,0,[\"crud\",\"lists\",\"extensions-apps-tw-momo-lists-orders\"]],[23,3,[]],[28,\"perform\",[[23,0,[\"fetchOrdersTask\"]]],null]]]],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"layouts/table-view\",[],[[\"@tab\",\"@detailsRowComponent\",\"@results\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onEdit\",\"@onDisplay\",\"@tableViewOptions\"],[[23,4,[]],\"extensions/apps/tw/momo/orders/table-view/detail-row\",[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,1,[]],[23,9,[]],[23,10,[]],[28,\"hash\",null,[[\"refreshOrders\"],[[23,11,[]]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/momo/orders/table-view/template.hbs"
    }
  });

  _exports.default = _default;
});