define("client/pods/components/elements/btn-save/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ElementsBtnSave = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ElementsBtnSave extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "showErrorsModal", _descriptor, this);

      _initializerDefineProperty(this, "rawErrors", _descriptor2, this);
    }

    get translate() {
      return R.pathOr(true, ['args', 'translate'])(this);
    }

    get btnClassNames() {
      return R.pathOr('is-primary', ['args', 'btnClassNames'])(this);
    }

    get tooltipLabel() {
      return R.pathOr('save', ['args', 'tooltipLabel'])(this);
    }

    get defaultIcon() {
      const icon = this.args.icon;

      if (icon === false) {
        return '';
      }

      if (icon) {
        return icon;
      }

      return 'fas fa-save';
    }

    get hasErrors() {
      if (this.rawErrors?.length > 0) {
        return true;
      }

      return false;
    }

    toggleShowErrorsModal() {
      this.showErrorsModal = !this.showErrorsModal;
    }

    async submit(onSaveTask) {
      try {
        const response = await onSaveTask();
        this.rawErrors = [];
        return response;
      } catch (error) {
        if (error?.data?.errors?.length) {
          this.rawErrors = error?.data?.errors;
        } else if (error?.cause?.errors?.length) {
          this.rawErrors = error?.cause?.errors;
        } else {
          this.rawErrors = error;
        }

        return null;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showErrorsModal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rawErrors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleShowErrorsModal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowErrorsModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class));
  _exports.default = ElementsBtnSave;
});