define("client/pods/components/files/import-btn/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../../../../utils/nventor'
  var _default = Ember.Component.extend({
    excel: Ember.inject.service(),
    tagName: 'div',
    label: 'import',
    generalError: 'error occurred please try again later',
    showProgress: false,
    // set to true to show progress bar
    onUploaded: null,
    // external action to handle uploading
    percentage: 0,
    subResourcePath: '',

    init() {
      this._super(...arguments);

      const formats = {
        csv: ['.csv', 'text/csv'],
        xls: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
      };
      this.set('formats', formats);
    },

    /**
     * keep track of upload progress percentage
     */
    progress: Ember.computed('percentage', function () {
      const percentage = this.percentage;
      return percentage + '%';
    }),
    acceptedFormats: Ember.computed('accept', 'formats.[]', 'filetypes.[]', function () {
      let accept = this.accept || [];
      const formats = this.formats || {};

      if (!R.is(Array, accept)) {
        accept = [accept];
      }

      return R.pipe(R.map(filetype => {
        if (formats[filetype]) {
          return formats[filetype];
        }

        if (formats[`.${filetype}`]) {
          return formats[filetype];
        }

        return filetype;
      }), R.flatten, R.join(', '))(accept);
    }),
    actions: {
      /**
       * handle file changes, when user finished selecting files
       */
      async uploadsChanged(e, uploads) {
        try {
          if (uploads) {
            const file = uploads[0];
            const results = await this.excel.readFile(file);
            this.set('selectedFiles', '');

            if (this.onUploadsChanged) {
              this.set('results', results);
              this.set('hasResults', RA.isNotNilOrEmpty(results));
              return this.onUploadsChanged(results);
            }
          }
        } catch (e) {
          this.set('errors', e);
        }
      },

      /**
       * triggers hidden images uploader input when clicking on upload btn
       */
      selectUploads() {
        if (this.onPreSubmit) {
          this.onPreSubmit();
        } // trigger hidden upload input


        if (!this.multiple) {
          this.set('selectedFiles', '');
        }

        this.$('.files-uploader__input').click();
      }

    }
  });

  _exports.default = _default;
});