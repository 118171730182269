define("client/pods/components/contacts/results/contact-persons-results/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['list-selector'],
    itemClassNames: ['list-selector__item'],
    legendClassNames: ['list-selector__legend'],
    optionLabelKey: 'label',
    // key that points to option's label
    optionValueKey: 'value',
    // key that points to option's value
    value: '',

    /**
     * trigger onSelect action
     * @param {object} option - selected object
     * @param {object} optionComponent - the component sending the toggle action,
     *                                    used to keep track of selected state
     */
    selectOption(option
    /* ,  optionComponent */
    ) {
      // send action to onSelect event..only used for when list is in a dropdown
      if (this.onSelect) {
        this.onSelect(option, this);
      }
    },

    actions: {
      /**
       * listen for toggle event sent from options component
       */
      toggleOption(option, optionComponent) {
        // list results only allows select (no deselect)
        // handle select event
        this.selectOption(option, optionComponent);
      }

    }
  });

  _exports.default = _default;
});