define("client/pods/components/alerts/confirm-msg/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    msg: 'confirm',
    actions: {
      confirmAction() {
        const msg = this.intl.t(this.msg);

        if (window.confirm(msg)) {
          this.onConfirmed(...arguments);
        } else {
          if (this.onDeclined) {
            this.onDeclined(...arguments);
          }
        }
      }

    }
  });

  _exports.default = _default;
});