define("client/pods/components/helpers/info-tooltip-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5kYd6tuX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"isViewable\"]],[24,[\"hasContent\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"modals/modal-container-new\",[],[[\"@closeOnOverlayClick\",\"@hasModalOverlay\",\"@hasCustomBlock\",\"@modalContentClassNames\",\"@onToggleModalDialog\"],[true,[22,\"hasModalOverlay\"],[22,\"hasCustomModalBlock\"],\"is-tooltip info-tooltip-modal\",[28,\"fn\",[[23,0,[\"onToggleModalDialog\"]]],null]]],{\"statements\":[[0,\"\\n    \"],[14,1],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/info-tooltip-content/template.hbs"
    }
  });

  _exports.default = _default;
});