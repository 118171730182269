define("client/pods/store-location/model", ["exports", "client/pods/base/model", "client/mixins/uploads", "client/mixins/statuses", "ramda", "client/utils/nventor", "client/pods/open-hours/model"], function (_exports, _model, _uploads, _statuses, R, _nventor, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_uploads.default, _statuses.default, {
    defaults() {
      return {
        index: 0,
        isShopLocation: false,
        openHours: {
          hasBreakTime: false,
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
          sunday: {}
        }
      };
    },

    populate(data, attrs) {
      data.openHours = this._transformOpenHours(data);

      this._super(data, attrs);
    },

    _transformOpenHours(data) {
      const defaults = this.defaults() || {};
      const defaultsOpenHours = defaults.openHours || {};
      const openHours = R.propOr(defaultsOpenHours, 'openHours')(data);
      return R.mapObjIndexed((value, key) => {
        if (R.type(value) === 'Boolean') {
          return value;
        }

        value.name = key;
        return _nventor.default.createModel(_model2.default, value);
      })(openHours);
    },

    index: Ember.computed('_data.index', function () {
      return this._data.index;
    }),
    displayIndex: Ember.computed('_data.index', function () {
      return this._data.index + 1;
    }),
    sortedOpenHours: Ember.computed('_data.openHours.monday.{open,close,hasOpeningHours}', '_data.openHours.tuesday.{open,close,hasOpeningHours}', '_data.openHours.wednesday.{open,close,hasOpeningHours}', '_data.openHours.thursday.{open,close,hasOpeningHours}', '_data.openHours.friday.{open,close,hasOpeningHours}', '_data.openHours.saturday.{open,close,hasOpeningHours}', '_data.openHours.sunday.{open,close,hasOpeningHours}', function () {
      const openHours = this._data.openHours;
      const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      const sortDays = R.pipe(R.toPairs, R.sortBy(R.pipe(R.head, R.indexOf(R.__, weekdays))), R.fromPairs, R.values, R.reject(day => R.type(day) === 'Boolean'));
      return sortDays(openHours);
    }),
    openDays: Ember.computed('_data.openHours.monday.{open,close}', '_data.openHours.tuesday.{open,close}', '_data.openHours.wednesday.{open,close}', '_data.openHours.thursday.{open,close}', '_data.openHours.friday.{open,close}', '_data.openHours.saturday.{open,close}', '_data.openHours.sunday.{open,close}', function () {
      const openHours = this._data.openHours;

      if (openHours) {
        return R.map(R.ifElse(R.propEq('open', 'none'), R.always({
          hasOpeningTime: true
        }), R.always({
          hasOpeningTime: false
        })))(openHours);
      }
    })
  });

  _exports.default = _default;
});