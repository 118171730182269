define("client/pods/components/elements/accordian-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sCaBO4MZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"isViewable\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"isModal\"]]],null,{\"statements\":[[0,\"    \"],[5,\"modals/modal-container-new\",[],[[\"@onToggleModalDialog\"],[[28,\"fn\",[[23,0,[\"onToggleModalDialog\"]]],null]]],{\"statements\":[[0,\"\\n      \"],[14,1],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/accordian-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});