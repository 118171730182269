define("client/pods/components/extensions/sub/panels-display/display-item/component", ["exports", "client/mixins/date", "client/mixins/search-contacts", "client/mixins/crud"], function (_exports, _date, _searchContacts, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, _searchContacts.default, {
    tagName: 'div',
    classNames: ['u-grid__row'],

    init() {
      this._super(...arguments);

      this.set('fetchHCTRegionDataOnInit', true);
    },

    paymentMethodClassName: Ember.computed('model._data.paymentMethodKey', function () {
      // @TODO: remove. move to model
      const paymentMethodKey = this.get('model._data.paymentMethodKey');
      let className = 'u-bg--white';

      switch (paymentMethodKey) {
        // cash on delivery
        case 'cash':
          className = 'u-bg--orange';
          break;
        // credit card

        case 'creditCard':
          className = 'u-bg--green';
          break;
        // electronic transfer

        case 'cashBeforeDelivery':
          className = 'u-bg--pink';
          break;
        // free

        case '9':
          // className = '';
          break;
      }

      return className;
    }),
    hasDiscount: Ember.computed('model._data.discount', function () {
      if (this.get('model._data.discountAmount') === '0' || this.get('model._data.discountAmount') === '-0' || this.get('model._data.discountAmount') === '') {
        return false;
      }

      return true;
    }),
    actions: {
      selectItem() {
        const model = this.model;

        if (model.getData('hasValidProducts')) {
          model.toggleProperty('isSelected');
        }
      },

      edit() {
        const model = this.model;
        model.set('isEditing', true);
      },

      cancel() {
        const self = this;
        const msg = this.intl.t('are you sure you want to cancel?');

        if (window.confirm(msg)) {
          const model = self.get('model');
          const extName = self.get('extName'); // skip data store and create record

          const appendPath = `/${extName}/orders/` + model.getData('ref'); // await this.get('removeRecordTask').perform({
          //   appendPath,
          //   model
          // })
          // this.get('orders').removeObject(model)

          self.get('dataManager').getAdapter('channels/shop/web').remove({}, appendPath).then(result => {
            if (!self.get('isDestroyed')) {
              if (result) {
                self.get('orders').removeObject(model);
              }
            }
          }); // @TODO: handel where cancel was unsuccessful
        }
      }

    }
  });

  _exports.default = _default;
});