define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SWTv14k9",
    "block": "{\"symbols\":[\"@automationDetails\",\"@automationModel\",\"@master\",\"@productsList\",\"@searchProductsTask\",\"@tab\",\"@isDisabled\",\"@isEditing\",\"@isNew\",\"@isReadonly\",\"@onEditAutomationItem\",\"@onLoadItem\",\"@onResetItem\",\"@onSetAsDefaultImageTask\",\"@selectedAutomation\"],\"statements\":[[4,\"unless\",[[23,2,[\"isDeleted\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"channels/shop/campaigns/campaigns-editor/automations-editor/when-editor\",[],[[\"@automationDetails\",\"@automationModel\",\"@isEditing\",\"@isNew\",\"@isReadonly\",\"@master\",\"@productsList\",\"@searchProductsTask\",\"@tab\"],[[23,1,[]],[23,2,[]],false,false,true,[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]]]]],[0,\"\\n\\n\\t\"],[5,\"channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details\",[],[[\"@automationDetails\",\"@automationModel\",\"@isDisabled\",\"@isEditing\",\"@isNew\",\"@isReadonly\",\"@master\",\"@onEditAutomationItem\",\"@onLoadItem\",\"@onResetItem\",\"@onSetAsDefaultImageTask\",\"@productsList\",\"@searchProductsTask\",\"@selectedAutomation\",\"@tab\"],[[23,1,[]],[23,2,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,3,[]],[23,11,[]],[23,12,[]],[23,13,[]],[23,14,[]],[23,4,[]],[23,5,[]],[23,15,[]],[23,6,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});