define("client/pods/channels/shop/shipping-zones/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct", "client/constants", "client/utils/nventor"], function (_exports, _model, R, RA, _constants, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        zoneId: '',
        zoneType: _constants.default.shippingFeeZoneType.single,
        name: '',
        isSplit: false,
        isDefault: false,
        isLabelOnly: false,
        priorityZone: '',
        productTags: [],
        denyProductTags: [],
        shopPaymentMethods: [],
        shopPaymentMethodsDefault: '',
        supplierKey: '',
        supplierLabel: '',
        transporterKey: '',
        transporterLabel: '',
        transporterAccount: '',
        transporterAccountNumber: '',
        transporterExtension: '',
        additionalFreeShippingRule: {},
        countries: [],
        rates: [],
        surcharges: [],
        hasSurchargeWhenFreeShipping: false,
        transporterDefaultPickupLocationPersonId: '',
        transporterUseDefaultPickupLocation: true
      };
    },

    populate(data, attrs) {
      data.countries = this._transformCountries(data);
      data.rates = this._transformRates(data);
      data.surcharges = this._transformSurcharges(data);

      if (RA.isNotNilOrEmpty(data.campaigns)) {
        this._transformCampaigns(data);
      }

      this._super(data, attrs);
    },

    _transformCampaigns(data) {
      const campaigns = R.propOr([], 'campaigns')(data);
      const campaignModels = R.map(campaign => {
        return this.dataManager.setPartialRecord({
          adapterName: 'channels/shop/campaigns',
          data: campaign
        });
      })(campaigns);
      this.set('campaigns', campaignModels);
      delete data.campaigns;
    },

    _transformCountries(data) {
      const countries = R.propOr([], 'countries')(data);
      return R.map(country => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/countries',
          data: country
        });
      })(countries);
    },

    _transformRates(data) {
      const rates = R.propOr([], 'rates')(data);
      return R.map(rate => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/rates',
          data: rate
        });
      })(rates);
    },

    _transformSurcharges(data) {
      const surcharges = R.propOr([], 'surcharges')(data);
      return R.map(surcharge => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/surcharges',
          data: surcharge
        });
      })(surcharges);
    },

    addSurcharge() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const surcharges = this.getData('surcharges') || [];
      const surchargeModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/surcharges',
        data
      });
      surcharges.pushObject(surchargeModel);
      this.setData('surcharges', surcharges);
    },

    clearIncomplete() {
      this._clearIncomplete('rates');

      this.set('updated', Math.random());
    },

    isLastRateIndex(rate) {
      const rates = this.getData('rates') || [];
      const rateIndex = rates.indexOf(rate);

      if (rateIndex === rates.length - 1) {
        return true;
      }

      return false;
    },

    updateMinRates() {
      const rates = this.getData('rates') || [];
      let previousRate;
      const newRates = RA.mapIndexed((rate, index) => {
        let previousMax = 0;

        if (previousRate) {
          previousMax = previousRate.getData('max');
        }

        let currentMin = previousMax;
        const initialMin = rate.getData('min') || 0;

        if (index === 0 && RA.isNotNilOrEmpty(initialMin)) {
          currentMin = initialMin;
        }

        if (index > 0) {
          currentMin = previousMax + 1;
        }

        rate.setData('min', currentMin);
        let currentMax = parseFloat(rate.getData('max')) || 0;

        if (currentMax > 0) {
          if (currentMax < currentMin) {
            currentMax = 0;
          }

          rate.setData('max', currentMax);
        }

        previousRate = rate;
        return rate;
      })(rates);
      this.setData('rates', newRates);
    },

    newBlankRate() {
      let rate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let rateData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      // check if current rate is the last one
      if (this.isLastRateIndex(rate)) {
        const hasRequiredData = rate.hasRequiredData();

        if (hasRequiredData) {
          this.newRate(rateData);
        }
      }
    },

    newRate() {
      let rateData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const rateModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/rates',
        data: rateData
      });
      this.addRate(rateModel);
    },

    addRate(rate) {
      const rates = this.getData('rates') || [];
      rates.pushObject(rate);
      this.setData('rates', rates);
    },

    removeRate(rate) {
      const rates = this.getData('rates') || [];
      const currentRateIndex = rates.indexOf(rate);
      const isLast = this.isLastRateIndex(rate);
      rates.removeObject(rate);
      const previousRate = rates[currentRateIndex - 1] || {};
      const isZeroPrevMax = parseInt(previousRate?._data?.max || 0) === 0;
      const isZeroOrOneCurrentMin = parseInt(rates?._data?.min || 0) === 0 || parseInt(rates?._data?.max || 0) === 1;
      const isRateRemoveable = isZeroPrevMax && isZeroOrOneCurrentMin;
      const isOnlyOne = rates.length === 0;

      if (isOnlyOne) {
        this.newRate();
      } else if (isLast && !isRateRemoveable) {
        this.newRate();
      } else {
        this.setData('rates', rates);
      }

      return rate;
    },

    newCountry() {
      let countryData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/countries',
        data: countryData
      });
    },

    addCountry(country) {
      const countries = this.getData('countries') || [];
      countries.pushObject(country);
      this.setData('countries', countries);
    },

    removeCountry(country) {
      const countries = this.getData('countries') || [];
      countries.removeObject(country);
      this.setData('countries', countries);
      return country;
    },

    hasManyCampaigns: Ember.computed('campaigns.[]', function () {
      return R.pipe(R.propOr([], 'campaigns'), R.propSatisfies(R.gte(R.__, 3), 'length'))(this);
    }),
    hasNotZeroMinimumRate: Ember.computed('_data.rates.@each.synced', function () {
      const rates = this?._data?.rates || [];

      const firstRate = _nventor.default.safeHeadOr({}, rates);

      if (RA.isNilOrEmpty(firstRate)) {
        return false;
      }

      const minRate = parseInt(firstRate?._data?.min) || 0;

      if (minRate === 0) {
        return false;
      }

      return true;
    }),
    minimumRate: Ember.computed('_data.rates.@each.synced', function () {
      const rates = this?._data?.rates || [];

      const firstRate = _nventor.default.safeHeadOr({}, rates);

      return parseInt(firstRate?._data?.min) || 0;
    }),
    hasIncorrectRates: Ember.computed('_data.rates.@each.synced', function () {
      const rates = this?._data?.rates || [];
      return R.pipe(RA.mapIndexed((rate, index) => {
        const nextRate = rates[index + 1];

        if (RA.isNotNilOrEmpty(nextRate)) {
          const currentMin = parseInt(rate?._data?.min || 0);
          const nextMin = nextRate?._data?.min; // dont set default value

          if (nextMin && parseInt(nextMin) < currentMin) {
            return true;
          }
        }

        return false;
      }), R.includes(true, R.__))(rates);
    })
  });

  _exports.default = _default;
});