define("client/pods/components/documents/sales/consignments/overview-container/component", ["exports", "client/pods/components/documents/mixins/tab"], function (_exports, _tab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tab.default, {
    crud: Ember.inject.service(),
    docType: 'sales',
    docName: 'consignments',

    init() {
      this._super(...arguments); //fetch list of all consignments


      this.setup();
    },

    setup() {// const self = this;
      // const docType = self.get('docType');
      // const docName = self.get('docName');
      // const data = {};
      // self.get('server').GET(`api/protected/documents/${docType}/${docName}/overview`, data).then(function (results) {
      //   self.set('pendingList', results.data);
      // });
    }

  });

  _exports.default = _default;
});