define("client/helpers/multiply", ["exports", "client/mixins/big"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_big.default, {
    compute(params, hash) {
      const rounding = hash.rounding || 0;
      const n1 = parseInt(params[0]) || 0;
      const n2 = parseInt(params[1]) || 0;
      return this.newBig(n1).times(n2).toFixed(rounding);
    }

  });

  _exports.default = _default;
});