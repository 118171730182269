define("client/pods/grapesjs/schedules/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        status: 'active',
        schedules: [],
        applytoall: false
      };
    },

    populate(data, attrs) {
      data = this._transform(data);
      data.schedules = this._transformSchedules(data);

      this._super(data, attrs);
    },

    _transform(_ref) {
      let {
        status,
        schedules = [],
        applytoall
      } = _ref;

      if (status) {
        this.setData('status', status);
      }

      if (_ramdaAdjunct.default.isNotNilOrEmpty(schedules)) {
        this.setData('schedules', schedules);
      }

      if (_ramdaAdjunct.default.isNotNilOrEmpty(applytoall)) {
        this.setData('applytoall', applytoall);
      }

      return this._data;
    },

    _transformSchedules(data) {
      const schedules = R.propOr([], 'schedules')(data);
      return R.map(schedule => {
        return this.dataManager.setAsRecord({
          adapterName: 'grapesjs/schedules/schedules',
          data: schedule
        });
      })(schedules);
    },

    addSchedule() {
      let schedule = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const schedules = this.getData('schedules') || [];
      const scheduleModel = this.dataManager.setAsRecord({
        adapterName: 'grapesjs/schedules/schedules',
        data: schedule
      });
      schedules.pushObject(scheduleModel);
      this.setData('schedules', schedules);
    },

    statusClass: Ember.computed('_data.status', function () {
      const status = this.getData('status');

      if (status === this.constants.status.active) {
        return 'is-primary';
      }

      return 'is-danger';
    }),

    checkOverlapDate(_ref2) {
      let {
        model
      } = _ref2;
      const schedules = model.getData('schedules') || [];
      const emptyStartDateZSchedules = R.filter(R.pathEq(['_data', 'dateStartZ'], ''))(schedules);

      if (R.length(emptyStartDateZSchedules) > 1) {
        return {
          hasOverlap: true,
          ids: R.pipe(R.pluck('_data'), R.pluck('scheduleId'))(emptyStartDateZSchedules)
        };
      }

      const emptyDateEndZSchedules = R.filter(R.pathEq(['_data', 'dateEndZ'], ''))(schedules);

      if (R.length(emptyDateEndZSchedules) > 1) {
        return {
          hasOverlap: true,
          ids: R.pipe(R.pluck('_data'), R.pluck('scheduleId'))(emptyDateEndZSchedules)
        };
      }

      const emptyStartDateZSchedule = R.head(emptyStartDateZSchedules) || {};
      const emptyDateEndZSchedule = R.head(emptyDateEndZSchedules) || {};
      const scheduleWithoutEmptyDate = R.reject(R.anyPass([R.pathEq(['_data', 'startDateZ'], ''), R.pathEq(['_data', 'dateEndZ'], '')]))(schedules);
      const overlappedIds = [];
      const hasOverlap = R.pipe(R.sortBy(R.path(['_data', 'dateEndZ'])), R.sortBy(R.path(['_data', 'dateStartZ'])), R.prepend(emptyStartDateZSchedule), R.append(emptyDateEndZSchedule), R.reject(_ramdaAdjunct.default.isNilOrEmpty), _ramdaAdjunct.default.mapIndexed((data, i, sortedDatas) => {
        const dateEndZ = data.getData('dateEndZ') || '';
        let nextDateStartZ = '';
        const nextData = sortedDatas[i + 1];

        if (_ramdaAdjunct.default.isNotNilOrEmpty(nextData)) {
          nextDateStartZ = nextData.getData('dateStartZ');

          if (nextDateStartZ <= dateEndZ) {
            overlappedIds.push(data.getData('scheduleId'));
            overlappedIds.push(nextData.getData('scheduleId'));
            return true;
          }
        }

        return false;
      }), R.includes(true))(scheduleWithoutEmptyDate);
      return {
        hasOverlap,
        ids: R.uniq(overlappedIds)
      };
    }

  });

  _exports.default = _default;
});