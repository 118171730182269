define("client/helpers/dateRange", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_date.default, {
    intl: Ember.inject.service(),

    compute(params
    /* ,  hash */
    ) {
      const startDateZ = params[0];
      const endDateZ = params[1];
      const format = params[2];
      const range = this.formatDate(startDateZ, format);

      if (range && endDateZ) {
        return range + ' ~ ' + this.formatDate(endDateZ, format);
      }

      if (endDateZ) {
        return ' < ' + this.formatDate(endDateZ, format);
      }

      if (range) {
        return range + ' > ';
      }

      return '';
    }

  });

  _exports.default = _default;
});