define("client/pods/components/countdown/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CountdownComponent = (_dec = Ember.inject.service('date'), _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class CountdownComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dateService", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "countdownTime", _descriptor3, this);

      _initializerDefineProperty(this, "endDateTime", _descriptor4, this);

      _initializerDefineProperty(this, "isReady", _descriptor5, this);

      this.setup();
    }

    get classNames() {
      const classNamesList = ['tag'];

      if (this.isReady) {
        classNamesList.push('is-success');
      } else {
        classNamesList.push('is-warning');
      }

      return classNamesList.join(' ');
    }

    setup() {
      this.endDateTime = moment(this.args.dateZ).add(this.args.timeLimitSeconds, 'seconds');
      this.countdownAction();
    }

    countdownAction() {
      const endTime = moment().add(1, 'hour'); // Calculate the end time by adding one hour to the current time

      const timer = setInterval(() => {
        const currentTime = moment(); // Get the current time

        const remainingTime = moment.duration(this.endDateTime.diff(currentTime)); // Calculate the remaining time

        if (remainingTime.asSeconds() <= 0) {
          clearInterval(timer); // Stop the timer if the remaining time is zero or negative
          // console.log('Countdown has ended.');

          this.isReady = true;
          this.countdownTime = this.intl.t('ready to dispatch');
        } else {
          const hours = Math.floor(remainingTime.asHours());
          const minutes = remainingTime.minutes();
          const seconds = remainingTime.seconds();

          const prependZeros = function () {
            let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
            return str.toString().padStart(2, '0');
          }; // HH:mm:ss


          this.countdownTime = `${prependZeros(hours)}:${prependZeros(minutes)}:${prependZeros(seconds)}`; // console.log(`Time remaining: ${hours} hours, ${minutes} minutes, ${seconds} seconds.`);
        }
      }, 1000); // Update the countdown every second
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "countdownTime", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "endDateTime", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isReady", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "countdownAction", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "countdownAction"), _class.prototype)), _class));
  _exports.default = CountdownComponent;
});