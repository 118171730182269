define("client/pods/components/messages/tab-container/messages-window/message-bubble/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    messageTypeComponentName: Ember.computed('messageBubble.type', function () {
      let messageType = this.get('messageBubble.type') || 'text';
      messageType = R.toLower(messageType);

      if (messageType.includes('video')) {
        messageType = 'video';
      } else if (messageType.includes('image')) {
        messageType = 'image';
      }

      const commonComponentPath = 'messages/tab-container/messages-window/message-bubble';
      const components = {
        text: `${commonComponentPath}/text-type`,
        image: `${commonComponentPath}/image-type`,
        sticker: `${commonComponentPath}/sticker-type`,
        video: `${commonComponentPath}/video-type`,
        audio: `${commonComponentPath}/audio-type`,
        file: `${commonComponentPath}/file-type`,
        link: `${commonComponentPath}/link-type`,
        options: `${commonComponentPath}/options-type`
      };
      return components[messageType];
    })
  });

  _exports.default = _default;
});