define("client/pods/components/documents/editor-main/editor-inventory-notification/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DocumentsEditorMainEditorInventoryNotification = (_dec = Ember._action, (_class = class DocumentsEditorMainEditorInventoryNotification extends _component.default {
    get modalDialogIsVisible() {
      const model = this.args.model;

      if (model) {
        const detailsInventoryData = model.get('detailsInventoryData') || [];

        if (_ramdaAdjunct.default.isNotNilOrEmpty(detailsInventoryData)) {
          const docType = model?._data?.docType;
          const docName = model?._data?.docName;

          if (docType !== 'purchases') {
            return false;
          }

          if (docName === 'consignments') {
            return true;
          }

          const paymentMethod = model?._data?.paymentMethodKey;

          const hasNotModified = _ramdaAdjunct.default.isNilOrEmpty(model?._data?.modified);

          if (hasNotModified && docName === 'invoices' && paymentMethod !== 'consignment') {
            // only show when no key. ie when saving for the first time only
            return true;
          }

          return false;
        }
      }

      return false;
    }

    onCloseDetailsInventoryDataDialog(onCloseDialogAction) {
      const model = this.args.model || {};

      if (_ramdaAdjunct.default.isNotNilOrEmpty(model)) {
        const detailsInventoryData = model.get('detailsInventoryData') || [];

        if (_ramdaAdjunct.default.isNotNilOrEmpty(detailsInventoryData)) {
          model.set('detailsInventoryData', []);
        }
      }

      if (this.args.onDone) {
        this.args.onDone();
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onCloseDetailsInventoryDataDialog", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseDetailsInventoryDataDialog"), _class.prototype)), _class));
  _exports.default = DocumentsEditorMainEditorInventoryNotification;
});