define("client/pods/components/store-location/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EPacw6Hb",
    "block": "{\"symbols\":[\"@errors\",\"@onCancel\",\"@onDash\",\"@onEdit\",\"@tab\"],\"statements\":[[5,\"store-location/store-location-editor\",[],[[\"@errors\",\"@isDisabled\",\"@isEditing\",\"@isNew\",\"@isReadonly\",\"@model\",\"@onCancel\",\"@onDash\",\"@onEdit\",\"@tab\"],[[23,1,[]],true,false,false,true,[23,0,[\"model\"]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/store-location/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});