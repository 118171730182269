define("client/pods/components/extensions/apps/tw/hct/sender-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EVbPvdFJ",
    "block": "{\"symbols\":[\"@errors\",\"@isReadonly\",\"@model\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"sender name\",[23,1,[]],\"twHct\",\"senderName\"]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"elements/element-input\",[],[[\"@translatedPlaceholder\",\"@readonly\",\"@value\"],[[23,0,[\"settingsService\",\"settingsModel\",\"_data\",\"company\"]],[23,2,[]],[23,3,[\"_data\",\"senderName\"]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"sender telephone\",[23,1,[]],\"twHct\",\"senderTelephone\"]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"elements/element-input\",[],[[\"@translatedPlaceholder\",\"@readonly\",\"@value\"],[[23,0,[\"settingsService\",\"settingsModel\",\"_data\",\"companyTel\"]],[23,2,[]],[23,3,[\"_data\",\"senderTelephone\"]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"sender address\",[23,1,[]],\"twHct\",\"senderAddress\"]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"elements/element-input\",[],[[\"@translatedPlaceholder\",\"@readonly\",\"@value\"],[[23,0,[\"settingsService\",\"settingsModel\",\"_data\",\"address\"]],[23,2,[]],[23,3,[\"_data\",\"senderAddress\"]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/hct/sender-details/template.hbs"
    }
  });

  _exports.default = _default;
});