define("client/pods/workflows/related/model", ["exports", "client/pods/base/model", "client/utils/nventor", "ramda"], function (_exports, _model, _nventor, _ramda) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        stageId: _nventor.default.random.alphaNum(8)
      };
    },

    isDoc: Ember.computed('_data.resource', function () {
      const resource = this.getData('resource');

      if (_ramda.default.includes('sales-')(resource)) {
        return true;
      }

      if (_ramda.default.includes('purchases-')(resource)) {
        return true;
      }

      return false;
    }),

    _getDocTypeData(resource) {
      if (resource) {
        // eg resource = purchases-consignments
        const docTypeData = _ramda.default.pipe(_ramda.default.split('-'), _ramda.default.zipObj(['docType', 'docName']))(resource);

        return docTypeData;
      }

      return {};
    },

    docType: Ember.computed('_data.resource', function () {
      const resource = this.getData('resource');

      const docTypeData = this._getDocTypeData(resource);

      return docTypeData.docType || '';
    }),
    docName: Ember.computed('_data.resource', function () {
      const resource = this.getData('resource');

      const docTypeData = this._getDocTypeData(resource);

      return docTypeData.docName || '';
    })
  });

  _exports.default = _default;
});