define("client/mixins/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // getDefaultTaxRate (taxes) {
    //   taxes = taxes || []
    //   const defaultTaxRateKey = this.get('taxRateKey')
    //   const defaultTax = taxes.findBy('_data._key', defaultTaxRateKey)
    //   if (defaultTax) {
    //     return defaultTax.getData('value')
    //   }
    //   const firstObject = taxes.get('firstObject')
    //   if (firstObject) {
    //     return firstObject.getData('value')
    //   }
    //   return '0'
    // },
    storeSettings: function (settings) {
      /* jshint unused: false */
      // @TODO: redo
      // locker.remove('settings');
      // locker.set('settings', settings);
    },

    /**
     * gets a specific setting.
     * @param {string} prop - settings property
     * @param {class} preferred - (optional) first check preferred, return settings set in preferred otherwise return settings
     * @param {string} preferredProp - (optional) settings property for preferred
     */
    getSetting: function (prop, preferred, preferredProp) {
      /* jshint unused: false */
      return ''; // var setting;
      // if (preferred) {
      //   preferredProp = preferredProp || prop;
      //   setting = preferred.getData(preferredProp);
      // }
      // if (!setting) {
      //   var settings = App.Factory.model('BaseModel', locker.get('settings'));
      //   setting = settings.getData(prop);
      // }
      //
      // return setting;
    },
    // getToday: function () {
    //   return ''
    //   // return moment().format('YYYY-MM-DD');
    // },

    /**
    * checks if pod settings allow tax and disables elements
    * used for both setting element to disabled when processing and
    * for setting disabled/enabled depending on taxAllowed setting
    */
    isTaxDisabled: Ember.computed('model.taxAllowed', 'disabled', function () {
      // set to true if disabled=true (eg. when processing)
      if (this.disabled) {
        return true;
      }

      if (this.getSetting('taxAllowed') === 0 || this.getSetting('taxAllowed') === '0') {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});