define("client/pods/components/documents/links-conflict/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rSl+2hxQ",
    "block": "{\"symbols\":[\"valueGroup\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\"],[[24,[\"conflictCategory\",\"label\"]]]],{\"statements\":[[0,\"    \"],[7,\"table\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"conflictCategory\",\"values\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"component\",[[24,[\"conflictCategory\",\"component\"]]],[[\"conflictCategory\",\"valueGroup\",\"onSelectConflictResolution\",\"model\"],[[24,[\"conflictCategory\"]],[23,1,[]],[24,[\"onSelectConflictResolution\"]],[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/links-conflict/template.hbs"
    }
  });

  _exports.default = _default;
});