define("client/pods/components/admin/payments/card/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HVFiO/fD",
    "block": "{\"symbols\":[\"subTab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__header-title\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"tab-header flex-row flex-container--justify-end\"],[8],[0,\"\\n\\n\\t\\t\\t\"],[1,[28,\"lists/list-dropdown\",null,[[\"isBtn\",\"translate\",\"disabled\",\"list\",\"itemLabelKey\",\"itemValueKey\",\"value\"],[true,false,true,[23,0,[\"crud\",\"lists\",\"payments-card-lists-providers\"]],\"_data.label\",\"_data.value\",[24,[\"selectedProvider\"]]]]],false],[0,\"\\n\\n\\t\\t\\t\"],[5,\"elements/element-btn\",[],[[\"@translate\",\"@label\",\"@onSubmit\"],[false,\"re-apply atm payments\",[28,\"perform\",[[23,0,[\"reApplyVirtualAccountsTask\"]]],null]]]],[0,\"\\n\\n\\t\\t\\t\"],[1,[22,\"layouts/btns-seperator\"],false],[0,\"\\n\\n\\t\\t\\t\"],[1,[28,\"menus/nav-menu\",null,[[\"menu\",\"classNames\",\"onSelect\"],[[23,0,[\"crud\",\"lists\",\"admin-payments-card-lists-panels\"]],\"is-pulled-right\",[28,\"action\",[[23,0,[]],\"displayPanel\"],null]]]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__content u-section\"],[8],[0,\"\\n\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[24,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tabParent\",\"lists\",\"selectedProvider\",\"tab\"],[[24,[\"tab\"]],[24,[\"lists\"]],[24,[\"selectedProvider\"]],[23,1,[]]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/admin/payments/card/tab-container/template.hbs"
    }
  });

  _exports.default = _default;
});