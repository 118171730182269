define("client/pods/components/dispatch/panels-display/component", ["exports", "ramda", "client/mixins/date", "client/mixins/emails-dispatched", "client/mixins/doc-status", "ember-concurrency", "ramda-extension", "client/utils/nventor", "client/pods/dispatch/model"], function (_exports, R, _date, _emailsDispatched, _docStatus, _emberConcurrency, R_, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_date.default, _docStatus.default, _emailsDispatched.default, (_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    print: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    showPrintDiaolog: false,
    showMoreBtns: false,
    allSelected: false,
    isDispatchedBeforeToday: false,
    customEmailMessage: '',

    init() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      const dispatchProcess = this.get('tab.dispatchProcess');
      this.set('dispatchProcess', dispatchProcess);
      this.set('sortBy', this.get('tab.sortBy'));
      const period = this.get('tab.period');
      this.set('period', period); // const results = this.get('tab.results')
      // this.set('results', results)

      if (period) {
        const isDispatched = this.get('tab.isDispatched');
        this.set('isDispatched', isDispatched);

        if (this.isBeforeToday(period.dateZ) && isDispatched) {
          this.set('isDispatchedBeforeToday', true);
        }
      }

      this.tabsManager.setHeader(this.tabParent, this.tabHeader);
      this.dispatch.tCatGetModel.perform();
      const showImagesOnDocuments = this?.settings?.settingsModel?._data?.showImagesOnDocuments || false;

      if (showImagesOnDocuments) {
        this.set('dispatch.showDocumentDetailsThumbnailImage', true);
      }
    },

    tabHeader: Ember.computed('dispatchProcess._data.{name,translate}', 'period.{weekday,dateZ}', function () {
      if (this.period) {
        return `${this.intl.t('dispatched')} ${this.intl.t(this.period.weekday)} ${this.dateService.formatDate(this.period.dateZ)}`;
      }

      const name = this.dispatchProcess?._data?.name;

      if (!name) {
        return '';
      }

      if (this.dispatchProcess?._data?.translate) {
        return this.intl.t(name);
      }

      return name;
    }),
    dispatchProcessCode: Ember.computed('dispatchProcess', function () {
      return this.get('dispatchProcess._data.code');
    }),
    // dispatchSortByListName: computed('dispatchProcess', function () {
    //   return 'settings__' + this.get('dispatchProcess._data.code')
    // }),
    shopeeResults: Ember.computed('results.@each.{isSelected,showDetails}', 'sortBy.@each.{direction,param}', function () {
      return R.pipe(R.propOr([], 'results'), R.filter(R.pathEq(['model', '_data', 'transporterExtension'], this.constants.transporterExtensions.shopee)))(this);
    }),
    readyToPrintResults: Ember.computed('shopeeResults', 'results.@each.isReadyToPrint', 'sortBy.@each.{direction,param}', function () {
      const shopeeResults = this.shopeeResults;
      return R.filter(R.pathEq(['model', '_data', 'transporterWaybillRecords', 0, 'isReadyToPrint'], true))(shopeeResults);
    }),
    sortedResults: Ember.computed('results.@each.{isSelected,showDetails}', 'sortBy.@each.{direction,param}', function () {
      let sortBy = this.sortBy || [];
      const results = this.results || [];

      if (!R.isEmpty(results)) {
        sortBy = R.map(sortByObject => {
          if (sortByObject.direction === 'asc') {
            return R.ascend(R.path(['model', '_data', sortByObject.param]));
          }

          return R.descend(R.path(['model', '_data', sortByObject.param]));
        }, sortBy);
        return R.sortWith(sortBy)(results);
      }

      return [];
    }),
    hasSelected: Ember.computed('results.@each.isSelected', 'dispatchProcess._data.code', function () {
      const currentDispatchProcess = this.get('dispatchProcess._data.code');
      const results = this.results || [];
      return R.filter(proxyModel => {
        const dispatchProcess = R.path(['model', '_data', 'dispatchProcess'], proxyModel) || 'unprepared';

        if (R.propEq('isSelected', true, proxyModel) && (R.equals(dispatchProcess, currentDispatchProcess) || currentDispatchProcess === 'all')) {
          return true;
        }

        return false;
      })(results);
    }),
    hasSelectedModels: Ember.computed('hasSelected.[]', function () {
      const hasSelected = this.hasSelected || [];
      return R.map(R.prop('model'), hasSelected);
    }),
    allowProcesses: Ember.computed('isDispatched', 'period.date', function () {
      const isDispatched = this.isDispatched;
      const dispatchDateZ = this.get('period.date');

      if (isDispatched && this.isBeforeToday(dispatchDateZ)) {
        return false;
      }

      return true;
    }),
    // printBadge: computed('searchResults.@each.isSelected', 'filterByValue', 'hasSelected.@each.isSelected', function () {
    //   const selected = this.get('hasSelected') || []
    //   if (selected.length > 0) {
    //     return selected.length
    //   }
    //   const searched = this.get('searchResults') || []
    //   const filterByValue = this.get('filterByValue')
    //   if (searched.length > 0 && filterByValue) {
    //     return searched.length
    //   }
    //   return ''
    // }),
    // @TODO: remove
    dispatchProcessesList: Ember.computed('crud.lists.dispatch-lists-processes-menu.[]', function () {
      const dispatchProcessesList = R_.dotPath('crud.lists.dispatch-lists-processes-menu')(this) || [];
      const isDispatchedProcessModel = this.isDispatchedProcessModel;
      return R.concat(dispatchProcessesList, [isDispatchedProcessModel]);
    }),
    duplicateWaybills: Ember.computed('dashData.duplicates.waybills', function () {
      return this.get('dashData.duplicates.waybills') || {};
    }),
    duplicateWaybillsDocNos: Ember.computed('duplicateWaybills', function () {
      const duplicateData = this.duplicateWaybills || {};
      return this._getDuplicateDocNos(duplicateData);
    }),
    duplicateAddresses: Ember.computed('dashData.duplicates.addresses.synced', function () {
      return this.get('dashData.duplicates.addresses') || {};
    }),
    duplicateAddressesDocNos: Ember.computed('duplicateAddresses.synced', function () {
      const duplicateData = this.duplicateAddresses || {};
      return this._getDuplicateDocNos(duplicateData);
    }),

    _getDuplicateDocNos(duplicateData) {
      return R.pipe(R.propOr([], 'values'), R.pluck('docs'), R.flatten)(duplicateData);
    },

    // exportTransporterExtensionDataTask: task(function * (transporterExtension) {
    //   const allHctData = this.get('hctExportData')
    //   const today = this.formatDate(this.getToday(), 'YYYYMMDD')
    //   const specs = this.get('settings').getProp('tw_hct_export_instructions')
    //   const columns = R.pluck('column', specs)
    //   const props = R.pluck('prop', specs)
    //   const data = R.pipe(
    //     R.map(doc => {
    //       return R.map(prop => {
    //         if (prop === '_today_') {
    //           return today
    //         }
    //         return R.propOr('', prop, doc)
    //       }, props)
    //     }),
    //     R.values,
    //     R.concat([columns])
    //   )(allHctData)
    //   yield this.get('excel').xlsx({
    // worksheets: [{
    //   sheetName: '1',
    //   data
    // }],
    //     title: `新竹物流匯出範例-${today}`
    //   })
    // }).drop(),
    _filterByTransporters(filterTransporters, transporterExtension) {
      if (Ember.isEmpty(filterTransporters)) {
        return true;
      }

      if (filterTransporters.indexOf(transporterExtension) > -1) {
        return true;
      }

      return false;
    },

    _filterByDispatchStatus(filterDispatchStatus, dispatchStatus) {
      // no filter set, dont filter
      if (Ember.isEmpty(filterDispatchStatus)) {
        return true;
      } // match status


      if (dispatchStatus === filterDispatchStatus) {
        return true;
      }

      return false;
    },

    fetchAllUnpaginatedTask: (0, _emberConcurrency.task)(function* () {
      const dispatchProcess = this.dispatchProcess;
      const period = this.period;
      const allResults = yield this.onFetchUnpaginatedDispatchDataTask.perform({
        dispatchProcess,
        period,
        setResults: false,
        hidePrinted: ''
      });
      let allUnpaginated = R.prop('docs')(allResults) || [];
      allUnpaginated = R.map(result => _nventor.default.createModel(_model.default, {}, {
        model: result,
        isSelected: false,
        showDetails: true // @TODO: get this from settings

      }))(allUnpaginated); // if (this.isDispatched) {
      //   allUnpaginated = R.filter(R.pathEq(['model', '_data', 'dispatchProcess'], this.get('constants.dispatchProcess.dispatched')), allUnpaginated)
      // }

      this.set('allUnpaginated', allUnpaginated);
    }),
    fetchWaybillRecordTask: (0, _emberConcurrency.task)(function* (documentArray) {
      const documents = R.pipe(R.pluck('model'), R.flatten)(documentArray);
      const transporterExtension = R.pathOr('', [0, '_data', 'transporterExtension'])(documents);
      yield this.dispatch.fetchWaybillRecordTask.perform({
        transporterExtension,
        documents,
        component: this
      });
    }),
    modelsList: Ember.computed('sortedResults.[]', function () {
      const results = this.sortedResults || [];
      return R.map(R.prop('model'), results);
    }),
    readyToPrintBadge: Ember.computed('shopeeResults.length', 'readyToPrintResults.length', function () {
      return `${this.readyToPrintResults.length}/${this.shopeeResults.length}`;
    }),
    searchResults: Ember.computed('sortedResults.[]', 'filterByValue', function () {
      const results = this.sortedResults;
      let filterByValue = this.filterByValue;

      if (filterByValue && R.is(String, filterByValue)) {
        filterByValue = filterByValue.toLowerCase();
        return R.filter(result => {
          const doc = result.model;
          const data = R.prop('_data', doc);
          const details = R.pipe(R.propOr([], 'details'), R.pluck('_data'), R.map(R.pipe(R.pick(['item', 'itemCode']), R.values)), R.flatten, R.map(value => R.is(String, value) ? value.toLowerCase() : value))(data);
          const values = R.pipe(R.pick(['telephone', 'email', 'contact', 'person', 'waybill', 'address', 'docNo', 'ref', 'transporterLabel', 'source']), R.values, R.concat(details))(data);
          const matches = R.filter(value => {
            if (R.is(String, value)) {
              value = value.toLowerCase();
              filterByValue.toLowerCase();
            }

            if (value.indexOf(filterByValue) > -1) {
              return true;
            }

            return false;
          })(values);

          if (matches.length > 0) {
            return true;
          }

          return false;
        }, results);
      }

      return results;
    }),
    allUnpaginatedSelected: Ember.computed('hasSelected.[]', 'allUnpaginated.[]', function () {
      const hasSelected = this.hasSelected || [];
      let results = hasSelected;

      if (hasSelected.length === 0) {
        results = this.allUnpaginated || [];
      }

      return results;
    }),
    // print list
    toPrintList: Ember.computed('hasSelected.[]', 'unpaginatedResults.[]', function () {
      const hasSelected = this.hasSelected || [];
      let results = hasSelected;

      if (hasSelected.length === 0) {
        results = this.unpaginatedResults || [];
      }

      if (this.isDispatched) {
        results = R.filter(R.pathEq(['model', '_data', 'dispatchProcess'], this.get('constants.dispatchProcess.dispatched')), results);
      } // let lastSetId = ''
      // results = R.map(detail => {
      //   if (detail._data.setId && detail._data.setId !== lastSetId) {
      //     detail.set('isFirstSetChoiceItem', true)
      //     lastSetId = detail._data.setId
      //   }
      //   return detail
      // })(results)


      return results;
    }),
    toPrintListTCat: Ember.computed('dispatch.tCatModel._data._key', 'hasSelected.[]', 'unpaginatedResults.[]', function () {
      return R.pipe(R.propOr([], 'toPrintList'), R.filter(R.pathEq(['model', '_data', 'transporterKey'], this?.dispatch?.tCatModel?._data?._key)))(this);
    }),
    toPrintModelsList: Ember.computed('toPrintList.[]', function () {
      const toPrintList = this.toPrintList || [];
      return R.map(R.prop('model'))(toPrintList);
    }),

    deselectAll() {
      this.set('allSelected', false);
      const allSelected = this.allSelected;
      const results = this.searchResults;
      let isSelected = false;

      if (allSelected) {
        isSelected = true;
      }

      results.forEach(result => {
        if (result.model._data.status === this.get('constants.documentsStatus.final')) {
          result.set('isSelected', isSelected);
        }
      });
    },

    actions: {
      filterByValue() {
        const value = this.searchValue;
        this.deselectAll();
        this.set('filterByValue', value);
        this.set('reFilter', Math.random());
      },

      selectAll(allSelected) {
        this.set('allSelected', allSelected);
        const results = this.searchResults;
        results.forEach(result => {
          if (result.model._data.status === this.get('constants.documentsStatus.final')) {
            result.set('isSelected', allSelected);
          }
        });
      },

      deselectAll() {
        this.set('allSelected', false);
        const results = this.searchResults;
        results.forEach(result => {
          if (result.model._data.status === this.get('constants.documentsStatus.final')) {
            result.set('isSelected', false);
          }
        });
      },

      clearSearch() {
        this.set('filterByValue', '');
        this.set('searchValue', '');
      },

      fetchUnpaginatedData(_ref) {
        let {
          setResults = true,
          hidePrinted
        } = _ref;
        const dispatchProcess = this.dispatchProcess;
        const period = this.period;
        return this.onFetchUnpaginatedDispatchDataTask.perform({
          dispatchProcess,
          period,
          setResults,
          hidePrinted
        });
      }

    },
    isShowBulkSplitBtn: Ember.computed('isDispatched', 'dispatchProcess.{name,isSystemProcess}', function () {
      const isDispatched = this?.isDispatched || false;

      if (isDispatched) {
        return false;
      }

      const dispatchProcess = this?.dispatchProcess || {};
      const dispatchProcessName = dispatchProcess?._data?.name || '';
      const isSystemProcess = dispatchProcess?._data?.isSystemProcess || false;

      if (isSystemProcess && dispatchProcessName === 'all') {
        return false;
      }

      return true;
    }),

    onToggleModalDialog() {
      // @TODO: remove?
      this.set('showVerticalDispatchProcessBar', false);
    }

  }, (_applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj)), _obj)));

  _exports.default = _default;
});