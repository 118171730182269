define("client/pods/grapesjs/schedules/schedules/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        scheduleId: '',
        dateStartZ: '',
        dateEndZ: '',
        status: 'active'
      };
    },

    populate(data, attrs) {
      attrs.startHours = '00';
      attrs.startMinutes = '00';
      attrs.endHours = '00';
      attrs.endMinutes = '00';

      this._super(data, attrs);
    },

    isActive: Ember.computed('_data.{dateStartZ,dateEndZ}', function () {
      const nowZ = this.getNowZ();
      const dateStartZ = this.getData('dateStartZ');
      const dateEndZ = this.getData('dateEndZ');

      if (dateStartZ && !dateEndZ && dateStartZ <= nowZ) {
        return true;
      }

      if (dateEndZ && !dateStartZ && dateEndZ >= nowZ) {
        return true;
      }

      if (dateStartZ <= nowZ && dateEndZ >= nowZ) {
        return true;
      }

      return false;
    }),
    isActiveNext: Ember.computed('_data.{dateStartZ,dateEndZ}', function () {
      const todayZ = this.getNowZ();
      const dateStartZ = this.getData('dateStartZ');

      if (dateStartZ > todayZ) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});