define("client/pods/components/extensions/apps/tw/e-invoices/panels-display/panels-statement/captured-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w2feFs3k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"has-text-centered\"],[8],[1,[28,\"tt\",[\"tw_eInvoices_captured\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"u-container\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"table\"],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[8],[1,[28,\"tt\",[\"tw_eInvoices_captured_invoices\"],null],false],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[1,[28,\"currency\",[[24,[\"capturedTotals\",\"capturedInvoicesInclTotal\"]]],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[1,[28,\"tt\",[\"tw_eInvoices_captured_returns\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[1,[28,\"currency\",[[24,[\"capturedTotals\",\"capturedReturnsInclTotal\"]]],[[\"negative\"],[true]]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[1,[28,\"tt\",[\"tw_eInvoices_uncaptured_total\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"td\",true],[10,\"class\",\"has-text-weight-bold\"],[8],[0,\"\\n        \"],[1,[28,\"currency\",[[24,[\"capturedTotals\",\"uncapturedInclTotal\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/e-invoices/panels-display/panels-statement/captured-details/template.hbs"
    }
  });

  _exports.default = _default;
});