define("client/pods/components/documents/pos/product/card/component", ["exports", "@glimmer/component", "ramda", "ember-concurrency-decorators"], function (_exports, _component, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentsPosProductCardComponent = (_dec = Ember.inject.service('uploadcare'), _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class DocumentsPosProductCardComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "uploadcareService", _descriptor, this);

      _initializerDefineProperty(this, "dateService", _descriptor2, this);

      _initializerDefineProperty(this, "crud", _descriptor3, this);
    }

    get productKey() {
      const productData = R.pathOr({}, ['args', 'product', '_data'])(this);
      return productData.itemKey || productData._key || '';
    }

    get productName() {
      const productData = R.pathOr({}, ['args', 'product', '_data'])(this);
      const name = R.pathOr(R.propOr('', 'name')(productData), ['item'])(productData);
      const shouldTruncate = name.length > 20;

      if (shouldTruncate) {
        return R.pipe(R.take(20), R.splitEvery(1), R.append('...'), R.join(''))(name);
      }

      return name;
    }

    get productPrice() {
      const productData = R.pathOr({}, ['args', 'product', '_data'])(this);
      return productData.price || productData.salesInclPrice || '0';
    }

    get productLastUpdate() {
      const lastUpdate = R.pathOr('', ['args', 'product', '_data', 'timestampZ_update'])(this);
      return this.dateService.formatDate(lastUpdate, 'YYYY-MM-DD HH:mm:ss');
    }

    get qty() {
      const detail = this.detail;
      const qty = R.pathOr(0, ['_data', 'qty'])(detail);
      return qty;
    }

    get detail() {
      const details = this?.args?.details || [];
      const productData = R.pathOr({}, ['args', 'product', '_data'])(this);
      const itemKey = productData.itemKey || productData._key || '';
      return R.find(R.anyPass([R.pathEq(['_data', '_key'], itemKey), R.pathEq(['_data', 'itemKey'], itemKey)]))(details);
    }

    get isRunning() {
      const isOnAddItemToCartRunning = this?.args?.onAddItemToCart?.isRunning;
      const isOnRemoveItemFromCartRunning = this?.args?.onRemoveItemFromCart?.isRunning;

      if (isOnAddItemToCartRunning || isOnRemoveItemFromCartRunning) {
        return true;
      }

      return false;
    }

    addProduct(product) {
      const productName = product?._data?.name || product?._data?.item || 'P';
      alert(productName);
    }

    *updateDocDetailsTask(updateTask, detail) {
      const dirtyProduct = this.crud.setupDirty({
        adapterName: 'channels/shop/campaigns/details',
        model: detail
      });
      return yield updateTask.perform(dirtyProduct);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "uploadcareService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addProduct", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "addProduct"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDocDetailsTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "updateDocDetailsTask"), _class.prototype)), _class));
  _exports.default = DocumentsPosProductCardComponent;
});