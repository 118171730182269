define("client/pods/components/channels/shop/campaigns/campaigns-editor/component", ["exports", "client/config/environment", "client/mixins/transporter", "ramda-extension", "client/utils/nventor"], function (_exports, _environment, _transporter, R_, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transporter.default, {
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    translate: true,
    headerBottom: null,

    // Only used for guide menu nav
    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['users', 'statuses', 'availability', 'toggles/auto', 'toggles/onOff', 'paymentMethods/shop', 'channels/shop/campaigns/statuses', 'channels/shop/campaigns/overSalesLimitedQtyStatuses', 'channels/shop/campaigns/lists/subPanels', 'channels/shop/campaigns/lists/editorSubPanels', 'channels/shop/campaigns/lists/noOfLines', 'channels/shop/campaigns/lists/statuses', 'channels/shop/campaigns/deliveryDateSettings', 'channels/shop/campaigns/deliveryDateSettingsItems', 'extensions/apps/tw/invoicingAuto', 'channels/shop/campaigns/lists/locations', 'channels/shop/campaigns/lists/productsOverview', 'channels/shop/campaigns/lists/productsImagesRatio', 'channels/shop/campaigns/lists/productsImagesSize', 'channels/shop/campaigns/lists/productsNameFontSize', 'settings/imagesHoverFilter', 'settings/imagesHoverTransform', 'shipping/feeType', 'commissions/methods']);
      this.set('productsList', []);
      this.set('config', _environment.default);
      this.set('editingPanels', []);
    },

    afterLoadLists() {
      this._super(...arguments);

      const editorSubPanels = R_.dotPath('crud.lists.channels-shop-campaigns-lists-editorSubPanels')(this) || [];
      editorSubPanels.forEach(panel => {
        this.editingPanels.pushObject(panel);
      });
      const tab = this.tab;
      const subPanels = R_.dotPath('crud.lists.channels-shop-campaigns-lists-editorSubPanels')(this) || [];

      const initialPanel = this.tabsManager.getLoadInstructions(tab) || _nventor.default.safeHeadOr({}, subPanels);

      this.tabsManager.displaySubPanel(this, initialPanel);
    },

    isReadonly: Ember.computed('isEditing', 'saveTask.isRunning', function () {
      if (this.get('saveTask.isRunning')) {
        return true;
      }

      if (this.isEditing === true) {
        return false;
      }

      return true;
    }),
    hasSalesPersons: Ember.computed('model._data.salesPersons.[]', function () {
      // @TODO TEST
      const salesPersons = this.get('model._data.salesPersons');

      if (Ember.isEmpty(salesPersons)) {
        return false;
      }

      return salesPersons;
    }),
    actions: {
      assignSalesPerson(person) {
        const model = this.model;
        model.addSalesPerson(person.serialize()); // set commission default to

        model.setData('commissionMethod', this.get('constants.commissionMethod.percentagePrice'));
      },

      unassignSalesPerson(person) {
        const model = this.model;
        model.removeSalesPerson(person);
      },

      toggleCommissionMethod() {
        const model = this.model;
        model.setData('commissionFlat', '');
        model.setData('commissionPercentage', '');
      }

    }
  });

  _exports.default = _default;
});