define("client/pods/components/elements/element-toggle-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jQkV1US9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"iconOn\"]],[24,[\"iconOff\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"toggleIsOn\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[[24,[\"iconOn\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[[24,[\"iconOff\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"prefix\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"prefix\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-label\",null,[[\"label\",\"classNames\",\"defaultLabel\",\"translate\"],[[24,[\"label\"]],[24,[\"labelClassNames\"]],[24,[\"defaultLabel\"]],[24,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"badge\"]],[24,[\"hasBadge\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"element-btn__badge \",[22,\"badgeClassNames\"]]]],[8],[0,\"\\n    \"],[1,[22,\"badge\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-toggle-btn/template.hbs"
    }
  });

  _exports.default = _default;
});