define("client/pods/components/workflows/status-tags/status-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sZup1Y5B",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"event\"],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"tag \",[22,\"statusClass\"]]]],[8],[0,\"\\n\\t\"],[1,[28,\"tt\",[[28,\"concat\",[\"email_event_\",[24,[\"data\",\"last\",\"event\"]]],null]],null],false],[0,\" @ \"],[1,[28,\"dateTime\",[[24,[\"data\",\"last\",\"timestampZ\"]]],null],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"data\",\"count\"]],1],null]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"helpers/info-tooltip\",[],[[\"@customBlock\"],[true]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"info-badge\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[24,[\"data\",\"count\"]],false],[0,\" \"],[1,[28,\"tt\",[\"no_of_times\"],null],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"],[4,\"helpers/info-tooltip-content\",null,[[\"isViewable\"],[[23,1,[]]]],{\"statements\":[[4,\"each\",[[24,[\"data\",\"all\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[28,\"tt\",[[28,\"concat\",[\"email_event_\",[23,2,[\"event\"]]],null]],null],false],[0,\" @ \"],[1,[28,\"dateTime\",[[23,2,[\"timestampZ\"]]],null],false],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\t\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/workflows/status-tags/status-tag/template.hbs"
    }
  });

  _exports.default = _default;
});