define("client/pods/components/helpers/drag-container/component", ["exports", "client/mixins/draggable"], function (_exports, _draggable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_draggable.default, {
    tagName: 'span',
    classNames: ['drag-container']
  });

  _exports.default = _default;
});