define("client/pods/extensions/apps/tw/kerry/accounts/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/extensions/model"], function (_exports, R, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/contacts/transporters/extension/kerry',
    modelObj: _model.default,
    idParam: 'account',

    async findAll() {
      const data = {};
      const results = await this._super(data);
      const convert = R.pipe(this._getAndSetDefault);
      return convert(results);
    },

    _getAndSetDefault(_ref) {
      let {
        defaultTransporterAccount,
        transporterAccounts
      } = _ref;
      return R.map(account => {
        account.isDefault = account.account === defaultTransporterAccount;
        return account;
      }, transporterAccounts);
    }

  });

  _exports.default = _default;
});