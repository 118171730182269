define("client/pods/components/comments/panels-display/component", ["exports", "ramda", "ramda-extension", "client/mixins/crud", "client/mixins/date", "client/utils/nventor", "ember-concurrency"], function (_exports, R, R_, _crud, _date, _nventor, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, {
    crud: Ember.inject.service(),
    commentsService: Ember.inject.service('comments'),
    settingsService: Ember.inject.service('settings'),
    tagName: 'span',
    classNames: ['comments__display-container'],
    tooltipLabel: 'internal comments',
    label: '',
    adapterName: 'comments',
    showEditor: false,
    isEditing: false,
    icon: 'far fa-comment',

    init() {
      this._super(...arguments); // this.crud.addLists(this, [
      //   {
      //     name: 'taxes',
      //     appendPath: 'products'
      //   },
      //   'products/lists/displaySubPanels',
      //   'products/lists/editorSubPanels',
      //   'products/lists/tags',
      //   'products/lists/types',
      //   'products/lists/setTypes',
      //   'products/lists/setTypes/variants',
      //   'products/lists/tracking',
      //   'products/lists/invoiceDescriptions',
      //   'statuses'
      // ])


      this.set('isEditing', false);
    },

    didInsertElement() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      const model = this.get('tab.model'); // images is required to be an array
      // if (!isArray(model.getData('images'))) {
      //   model.setData('images', [])
      // }

      this.set('model', model);
      this.setPropertiesFromModel(model);
      this.tabsManager.setHeader(this.tabParent, model.getData('name'));
      this.setupNewCommentRecord();
      const master = model.getData('master');

      if (master) {
        this.fetchCommentsTask.perform(master);
      }
    },

    setPropertiesFromModel(model) {
      this.set('type', model.getData('type'));
      this.set('subType', model.getData('subType'));
      this.set('metaData', {
        type: 'documents-public',
        subType: model?._data?.subType || '',
        contact: model?._data?.contact || '',
        contactKey: model?._data?.contactKey || '',
        emailToAddress: model?._data?.emailToAddress || '',
        emailToName: model?._data?.contact || '',
        locale: model?._data?.locale || '',
        ref: model?._data?.ref || '',
        threadId: model?._data?.threadId || '',
        isPublic: true,
        emailType: 'fromMerchantToCustomer',
        shopKey: model?._data?.shopKey || '',
        sourceHost: model?._data?.sourceHost || '',
        sourceHostName: model?._data?.sourceHostName || '',
        name: model?._data?.docNo || ''
      });
    },

    setupNewCommentRecord() {
      if (this?.isDestroyed === false) {
        const oldModel = this.model;
        const master = oldModel.getData('master') || '';
        const tokenData = this.storage.get('tokenData');
        const data = {
          username: tokenData.username,
          master: master
        };
        const model = this.setupNewRecord({
          data
        });
        this.set('newModel', model);
      }
    },

    comments: Ember.computed('commentsCollection._data.comments.[]', 'commentsCollection.synced', function () {
      return this.get('commentsCollection._data.comments') || [];
    }),
    commentsCount: Ember.computed('comments.[]', 'commentsCollection.synced', function () {
      const comments = this.comments || [];
      return comments.length;
    }),
    errorMsg: Ember.computed(function () {
      return this.intl.t('error occurred please try again later');
    }),
    trackingTagLabel: Ember.computed(function () {
      return this.intl.t('tracking') + ': ';
    }),
    isAllowed: Ember.computed('isPublic', function () {
      if (!this.isPublic) {
        return true;
      }

      return this.settingsService?.settingsModel?._data?.allowOrderMessages;
    }),
    fetchCommentsTask: (0, _emberConcurrency.task)(function* (master) {
      var _this = this;

      let data = {};

      if (master) {
        data.master = master;
      }

      if (this.type) {
        data.type = this.type;
      }

      if (this.subType) {
        data.subType = this.subType;
      }

      const metaData = this.metaData;

      if (metaData) {
        data = R.mergeRight(data, metaData);
      }

      const excludeFromFilters = this.excludeFromFilters || [];
      data = R.omit(excludeFromFilters)(data);
      data.related = this.related || [];
      this.commentsService.qFetch({
        data
      }, function () {
        let batchCommentsCollection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        if (_this?.isDestroyed === false) {
          if (!R.is(Array, batchCommentsCollection)) {
            return false;
          }

          const type = _this.type;
          let criteria = R_.dotPathEq('_data.master', master);

          if (type) {
            criteria = R.allPass([R_.dotPathEq('_data.master', master), R_.dotPathEq('_data.type', type)]);
          }

          const commentsCollection = R.find(criteria)(batchCommentsCollection) || [];

          _this.set('commentsCollection', commentsCollection);
        }
      });
    }),
    createCommentTask: (0, _emberConcurrency.task)(function* (onUpdateContent) {
      try {
        const nowZ = this.getNowZ();
        const type = this.type;
        const subType = this.subType;
        const model = this.newModel; // const message = model.getData('message.string')
        // model.setData('message', message)

        if (!model.getData('message')) {
          return false;
        }

        let confirmed = true;

        if (this.isPublic) {
          const msg = this.intl.t('are you sure you want to comment and notify customer');
          confirmed = window.confirm(msg);
        }

        if (confirmed) {
          model.setData('dateZ', nowZ);
          model.setData('type', type);

          if (subType) {
            model.setData('subType', subType);
          }

          const metaData = this.metaData;

          if (metaData) {
            Object.keys(metaData).map(key => {
              const value = metaData[key];
              model.setData(key, value);
            });
          }

          if (this.onBeforeCreateComment) {
            this.onBeforeCreateComment(model);
          }

          const msg = yield this.createRecordTask.perform({
            model
          });
          this.setupNewCommentRecord();

          if (onUpdateContent) {
            onUpdateContent(msg);
          }
        }
      } catch (err) {
        this.set('errors', err.data);

        _nventor.default.throw('createCommentTask failed', err);
      }
    }).drop(),

    afterRemoveRecordTask(model) {
      const dirty = this.dirty;
      const comments = this.comments;
      comments.removeObject(model);

      if (dirty && model) {
        if (dirty.getData('_id') === model.getData('_id')) {
          this.cancelEdit();
        }
      }
    },

    willDestroyElement() {
      if (this?.isDestroyed === false) {
        this.cancelEdit();
      }
    },

    cancelEdit() {
      this.set('dirty', '');
      this.set('isEditing', false);
    },

    actions: {
      toggleEditor() {
        this.toggleProperty('showEditor');
      },

      edit(model) {
        const dirty = this.setupDirty({
          adapterName: this.adapterName,
          model
        });
        this.set('dirty', dirty);
        this.set('isEditing', true);
      },

      cancelEdit() {
        this.cancelEdit();
      }

    }
  });

  _exports.default = _default;
});