define("client/pods/components/files/files-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3P57rieR",
    "block": "{\"symbols\":[\"widget\"],\"statements\":[[4,\"if\",[[24,[\"isUpdatingServer\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[28,\"tt\",[\"uploading please wait\"],null],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"widgets\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"cdnBase\",\"clearable\",\"crop\",\"icon\",\"imageShrink\",\"imagesOnly\",\"label\",\"multiple\",\"multipleMax\",\"multipleMin\",\"name\",\"onUpdateServer\",\"previewStep\",\"publicKey\",\"translate\",\"resource\",\"resourceKey\",\"secureExpire\",\"secureSignature\",\"tabs\"],[[24,[\"cdnBase\"]],[24,[\"clearable\"]],[24,[\"crop\"]],\"fas fa-upload\",[24,[\"imageShrink\"]],[24,[\"imagesOnly\"]],[24,[\"label\"]],[24,[\"multiple\"]],[24,[\"multipleMax\"]],[24,[\"multipleMin\"]],[24,[\"name\"]],[28,\"perform\",[[24,[\"updateServerTask\"]]],null],[24,[\"previewStep\"]],[24,[\"publicKey\"]],[24,[\"translate\"]],[24,[\"resource\"]],[24,[\"resourceKey\"]],[23,0,[\"uploadcare\",\"secureExpire\"]],[23,0,[\"uploadcare\",\"secureSignature\"]],[24,[\"tabs\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[1,[28,\"msgs/error-msg\",null,[[\"hasError\",\"msg\"],[[24,[\"error\"]],[24,[\"error\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/files-uploader/template.hbs"
    }
  });

  _exports.default = _default;
});