define("client/mixins/has-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    //@TODO: redo this:
    //all inputs will perform this show/hide dropdown
    //even tho its not used in a dropdown
    focusIn(e) {
      this._super(e);

      this.showDropdown();
    },

    showDropdown() {
      if (this.disabled !== true) {
        this.set('isDropdownVisible', true);
      }
    },

    hideDropdown() {
      this.set('isDropdownVisible', false);
    } // toggleDropdown() {
    //
    // }


  });

  _exports.default = _default;
});