define("client/pods/contacts/model", ["exports", "ramda", "ramda-adjunct", "client/pods/base/model", "client/mixins/sales-persons", "client/mixins/contacts-is-parent", "client/mixins/statuses", "client/mixins/contacts-departments"], function (_exports, R, RA, _model, _salesPersons, _contactsIsParent, _statuses, _contactsDepartments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend(_salesPersons.default, _contactsIsParent.default, _contactsDepartments.default, _statuses.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_obj = {
    crud: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['transporter', 'extensions/apps/tw/t-cat/lists/thermosphere', 'extensions/apps/tw/t-cat/lists/package-size', 'extensions/apps/tw/t-cat/lists/product-type', 'extensions/apps/tw/t-cat/lists/waybill-print-type', 'extensions/apps/tw/t-cat/lists/delivery-time']);
    },

    defaults() {
      return {
        isAllowedLinkMember: this.settings.getProp('isAllowedLinkMember', false),
        salesPaymentMethodKey: this.get('constants.paymentMethods.cash'),
        salesTaxMethodKey: this.get('constants.taxMethods.auto'),
        purchasesPaymentMethodKey: this.get('constants.paymentMethods.cash'),
        purchasesTaxMethodKey: this.get('constants.taxMethods.auto'),
        status: this.get('constants.status.active'),
        invoicing: this.get('constants.invoicing.auto'),
        isTransporter: this.get('constants.isTransporter.off'),
        sevenEleven: {
          returnStore: {
            storeId: '',
            storeName: '',
            storeAddress: ''
          },
          checkout: {},
          pickup: {},
          sftp: {},
          waybillsPerPage: 4
        }
      };
    },

    /**
     * custom populate function. (overrides data mixin default)
     * will create child detail models
     * @param {object/json} data - model data
     */
    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let attrs = arguments.length > 1 ? arguments[1] : undefined;
      const self = this;
      data = data || {};
      self.transformSalesPersons(data);
      self.transformDetails(data);
      self.transformPersons(data);
      self.transformDepartments(data);
      self.transformTransporterAccounts(data); // populate docName info
      // self.overwriteData(data)

      this._super(data, attrs);
    },

    birthday: Ember.computed('_data.birthday,_data.member.birthday', function () {
      let birthday = this.getData('birthday');

      if (RA.isNilOrEmpty(birthday)) {
        birthday = this.getData('member.birthday');
      }

      return birthday || '';
    }),
    age: Ember.computed('birthday', function () {
      const birthday = this.birthday || '';

      if (birthday) {
        const todayZ = this.dateService.getStartOfDayZ();
        return this.dateService.getDateDiff(birthday, todayZ, 'years');
      }

      return '';
    }),
    hasRate: Ember.computed('_data.transporterExtension', function () {
      return R.pipe(R.pathEq(['_data', 'transporterExtension'], 't-cat'), R.not)(this);
    }),
    translateAccountName: Ember.computed('_data.transporterExtension', function () {
      return R.pathEq(['_data', 'transporterExtension'], 't-cat')(this);
    }),
    gender: Ember.computed('_data.gender,_data.member.gender', function () {
      let gender = this.getData('gender');

      if (RA.isNilOrEmpty(gender)) {
        gender = this.getData('member.gender');
      }

      return gender || '';
    }),
    otherGender: Ember.computed('_data.otherGender,_data.member.otherGender', function () {
      let otherGender = this.getData('otherGender');

      if (RA.isNilOrEmpty(otherGender)) {
        otherGender = this.getData('member.otherGender');
      }

      return otherGender || '';
    }),
    defaultTelephone: Ember.computed('details.[]', 'persons.[]', 'departments.[]', 'synced', function () {
      const type = 'phone';
      let defaultPhone = this.getDefaultValue({
        type,
        subType: 'telephone'
      });

      if (!defaultPhone) {
        defaultPhone = this.getDefaultValue({
          type,
          subType: 'mobile'
        });
      }

      return defaultPhone;
    }),
    defaultFax: Ember.computed('details.[]', 'persons.[]', 'departments.[]', 'synced', function () {
      const type = 'phone';
      return this.getDefaultValue({
        type,
        subType: 'fax'
      });
    }),
    defaultEmail: Ember.computed('details.[]', 'persons.[]', 'departments.[]', 'synced', function () {
      const type = 'email';
      return this.getDefaultValue({
        type
      });
    }),
    defaultAddress: Ember.computed('details.[]', 'persons.[]', 'departments.[]', 'synced', function () {
      const type = 'address';
      return this.getDefaultValue({
        type
      });
    }),
    defaultTransporterAccount: Ember.computed('_data.transporterAccounts.[]', '_data.transporterAccounts.@each.synced', function () {
      let defaultAccount = this?._data?.transporterAccounts.find(transporterAccount => transporterAccount?._data?.isDefault);

      if (!defaultAccount) {
        defaultAccount = this?._data?.transporterAccounts[0];
      }

      return defaultAccount?._data?.account || '';
    }),
    defaultSender: Ember.computed('_data.defaultPickupLocationPersonId', function () {
      const personId = this._data?.defaultPickupLocationPersonId;

      if (!personId) {
        return;
      }

      const findDetailByType = (details, type) => {
        const detailType = details.find(detail => detail?._data?.type === type) || {};
        return detailType?._data?.value || '';
      };

      const person = R.pipe(R.pathOr([], ['_data', 'persons']), R.find(R.pathEq(['_data', 'personId'], personId)))(this);

      if (!person) {
        return;
      }

      const name = person?._data?.name || '';
      const details = person?._data?.details || [];
      const telephone = findDetailByType(details, 'telephone');
      const address = findDetailByType(details, 'address');
      const transporterDeliveryTime = findDetailByType(details, 'deliveryTime');
      const transporterPackageTemperature = findDetailByType(details, 'transporterAccount');
      const transporterPackageSize = findDetailByType(details, 'packageSize');
      const transporterPackageType = findDetailByType(details, 'packageType');
      const transporterNotes = findDetailByType(details, 'note');
      return {
        transporterDeliveryTime,
        transporterPackageTemperature,
        transporterPackageSize,
        transporterPackageType,
        transporterNotes,
        personId,
        name,
        telephone,
        address,
        model: person
      };
    }),

    getDefaultValue(_ref) {
      let {
        type,
        subType
      } = _ref;

      const detailsData = this._getDetailsList({
        type,
        subType
      });

      return detailsData.defaultValue || '';
    },

    getDetailsList(_ref2) {
      let {
        type,
        subType,
        defaultProp = false
      } = _ref2;

      const detailsData = this._getDetailsList({
        type,
        subType
      });

      if (defaultProp && detailsData.defaultValue != null) {
        this.set(defaultProp, detailsData.defaultValue);
      }

      return detailsData.detailList;
    },

    _getDetailsList(_ref3) {
      let {
        type,
        subType
      } = _ref3;
      const detailList = [];
      const defaultList = []; // search for default address in contact.details, contacts.details.departments, etc...
      // store all addresses in addresses list

      const details = this.getData('details', []);

      this._addDetailsToList({
        type,
        subType,
        details,
        detailList,
        defaultList
      });

      const persons = this.getData('persons', []);
      persons.forEach(person => {
        const context = person.getData('name');
        const details = person.getData('details', []);

        this._addDetailsToList({
          type,
          subType,
          details,
          detailList,
          defaultList,
          context
        });
      });
      const departments = this.getData('departments', []);
      departments.forEach(department => {
        const departmentName = department.getData('name');
        const context = departmentName;
        const details = department.getData('details', []);

        this._addDetailsToList({
          type,
          subType,
          details,
          detailList,
          defaultList,
          context
        });

        const deptPersons = department.getData('persons', []);
        deptPersons.forEach(person => {
          const context = departmentName + ': ' + person.getData('name');
          const details = person.getData('details', []);

          this._addDetailsToList({
            type,
            subType,
            details,
            detailList,
            defaultList,
            context
          });
        });
      });
      const defaultValue = this.getDefaultDetailValue({
        detailList,
        defaultList
      });
      return {
        defaultValue,
        defaultList,
        detailList
      };
    },

    getDefaultDetailValue(_ref4) {
      let {
        detailList,
        defaultList
      } = _ref4;
      let defaultDetail = detailList.get('firstObject');

      if (!Ember.isEmpty(defaultList)) {
        defaultDetail = defaultList.get('firstObject');
      }

      if (defaultDetail) {
        return defaultDetail.getData('value');
      }

      return '';
    },

    hasSales: Ember.computed('_data.relationships.[]', function () {
      const relationships = this.getData('relationships') || [];

      if (relationships.indexOf('customer') > -1) {
        return true;
      }

      return false;
    }),
    hasPurchases: Ember.computed('_data.relationships.[]', function () {
      const relationships = this.getData('relationships') || [];

      if (relationships.indexOf('supplier') > -1) {
        return true;
      }

      return false;
    }),
    hasPickupLocation: Ember.computed('_data.persons.[]', function () {
      return R.pipe(R.pathOr([], ['_data', 'persons']), R.filter(R.pathEq(['_data', 'type'], 'pickupLocation')), RA.isNotNilOrEmpty)(this);
    }),
    isTransporterLocked: Ember.computed('_data.{_key,transporterExtension}', function () {
      const hasKey = R.pipe(R.path(['_data', '_key']), RA.isNotNilOrEmpty)(this);

      if (!hasKey) {
        return false;
      }

      return R.pathEq(['_data', 'isTransporterLocked'], true)(this);
    }),

    _addDetailsToList(_ref5) {
      let {
        type,
        subType = false,
        details,
        detailList,
        defaultList,
        context
      } = _ref5;
      details.forEach(detail => {
        // context does not belong to _data
        detail.set('context', context);
        let matchSubType = true;

        if (subType) {
          if (detail.getData('subType') !== subType) {
            matchSubType = false;
          }
        }

        if (detail.getData('type') === type && matchSubType) {
          detailList.push(detail);

          if (detail.getData('isDefault')) {
            defaultList.push(detail);
          }
        }
      });
    },

    transformTransporterAccounts(rawData) {
      if (Ember.isArray(rawData.transporterAccounts)) {
        rawData.transporterAccounts = rawData.transporterAccounts.map(data => {
          const isTCat = R.propEq('transporterExtension', 't-cat')(rawData);

          if (isTCat) {
            delete data.rate;
          }

          data.isDefault = rawData.defaultTransporterAccount === data.account;
          return this.dataManager.setAsRecord({
            adapterName: 'base',
            data: data
          });
        });
      }
    },

    setTransporter() {
      let transporterExtension = this._data.transporterExtension;
      let isAllowedLinkMember = this._data.isAllowedLinkMember;
      const isTransporter = this._data.isTransporter === 'on';

      if (isTransporter) {
        if (!transporterExtension) {
          transporterExtension = this.crud.lists.transporter[0]._data.value;
        }

        isAllowedLinkMember = false;
        this.setTransporterDefaults(transporterExtension);
      } else {
        transporterExtension = '';

        this._removeAllTransporterDefaults();
      }

      this.set('_data.transporterExtension', transporterExtension);
      this.set('_data.isAllowedLinkMember', isAllowedLinkMember);
    },

    setTransporterDefaults(transporterExtension) {
      transporterExtension = transporterExtension || this._data.transporterExtension;
      const isNew = R.pipe(R.pathOr('', ['_data', '_key']), RA.isNilOrEmpty)(this);

      if (!isNew) {
        return;
      }

      this._removeAllTransporterDefaults();

      switch (transporterExtension) {
        case this.constants.transporterExtensions.sevenEleven:
          return this._setTransporterDefaultsSevenEleven();

        case this.constants.transporterExtensions.hct:
          return this._setTransporterDefaultsHct();

        case this.constants.transporterExtensions.tCat:
          return this._setTransporterDefaultsTCat();

        default:
          return;
      }
    },

    _removeAllTransporterDefaults() {
      const options = {
        reset: true
      };

      this._setTransporterDefaultsSevenEleven(options);

      this._setTransporterDefaultsHct(options);

      this._setTransporterDefaultsTCat(options);
    },

    _setTransporterDefaultsSevenEleven() {
      return;
    },

    _setTransporterDefaultsHct() {},

    _setTransporterDefaultsTCat() {
      let {
        reset = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const transporterDeliveryTimes = this.crud.lists['extensions-apps-tw-t-cat-lists-delivery-time'];
      const transporterDeliveryTimeDefault = R.pathOr('anytime', [1, '_data', 'value'])(transporterDeliveryTimes);
      const transporterAccounts = this.crud.lists['extensions-apps-tw-t-cat-lists-thermosphere'];
      const transporterAccountDefault = R.pathOr('anytime', [0, '_data', 'value'])(transporterAccounts);
      const transporterPackageSize = this.crud.lists['extensions-apps-tw-t-cat-lists-package-size'];
      const transporterPackageSizeDefault = R.pathOr('anytime', [0, '_data', 'value'])(transporterPackageSize);
      const transporterPackageType = this.crud.lists['extensions-apps-tw-t-cat-lists-product-type'];
      const transporterPackageTypeDefault = R.pathOr('anytime', [0, '_data', 'value'])(transporterPackageType);
      const updateData = {
        defaultDeliveryTime: transporterDeliveryTimeDefault,
        defaultTransporterAccount: transporterAccountDefault,
        packageSizeDefault: transporterPackageSizeDefault,
        productTypeDefault: transporterPackageTypeDefault
      };

      for (const updateDataKey in updateData) {
        const key = `_data.${updateDataKey}`;
        let value = updateData[updateDataKey];

        if (reset) {
          value = '';
        }

        this.set(key, value);
      }
    },

    resetSevenElevenReturnStore() {
      const returnStore = {
        storeId: '',
        storeName: '',
        storeAddress: ''
      };
      this.set('_data.sevenEleven.returnStore', returnStore);
    }

  }, (_applyDecoratedDescriptor(_obj, "setTransporter", [_dec], Object.getOwnPropertyDescriptor(_obj, "setTransporter"), _obj), _applyDecoratedDescriptor(_obj, "setTransporterDefaults", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setTransporterDefaults"), _obj), _applyDecoratedDescriptor(_obj, "_removeAllTransporterDefaults", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_removeAllTransporterDefaults"), _obj), _applyDecoratedDescriptor(_obj, "_setTransporterDefaultsSevenEleven", [_dec4], Object.getOwnPropertyDescriptor(_obj, "_setTransporterDefaultsSevenEleven"), _obj), _applyDecoratedDescriptor(_obj, "_setTransporterDefaultsHct", [_dec5], Object.getOwnPropertyDescriptor(_obj, "_setTransporterDefaultsHct"), _obj), _applyDecoratedDescriptor(_obj, "_setTransporterDefaultsTCat", [_dec6], Object.getOwnPropertyDescriptor(_obj, "_setTransporterDefaultsTCat"), _obj), _applyDecoratedDescriptor(_obj, "resetSevenElevenReturnStore", [_dec7], Object.getOwnPropertyDescriptor(_obj, "resetSevenElevenReturnStore"), _obj)), _obj)));

  _exports.default = _default;
});