define("client/pods/components/contacts/details/editor-details/editor-phone/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: 'span',
    classNames: ['contacts-detail'],
    autofocus: true,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['contacts/lists/subTypesPhone']);
    }

  });

  _exports.default = _default;
});