define("client/pods/components/forms/form-section/component", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @deprecated
  // Should use FormEntity for new features
  var _default = Ember.Component.extend({
    inViewport: Ember.inject.service(),
    tagName: 'div',
    classNameBindings: ['getClassNames', 'classNames'],
    getClassNames: Ember.computed('betaPath', 'isBetaAllowed', 'isInView', 'isNarrowOuterContainer', function () {
      if (this.isMenu && !this.isInView) {
        return 'form-section__outer-container--no-border';
      }

      if (this.betaPath && !this.isBetaAllowed) {
        return 'form-section__outer-container--no-border';
      }

      if (this.isNarrowOuterContainer) {
        return 'form-section__outer-container--no-padding';
      }

      return 'form-section__outer-container';
    }),
    translate: true,
    toggle: true,
    isModal: false,
    toggleOnIconClassName: 'fas fa-caret-up',
    toggleOffIconClassName: 'fas fa-caret-down',
    isViewable: true,
    isAccordian: false,
    isInView: false,

    /**
     * Register guide menu and the viewport
     * @void
     */
    didInsertElement: function () {
      this._super(...arguments);

      if (this.isMenu) {
        const menuId = this.menuId;
        const containerModel = this.tabsManager.registerMenu({
          menuId,
          tab: this.tab,
          label: this.title,
          parentLabel: this.parent,
          type: 'sub',
          className: this.elementId
        });

        if (RA.isNotNilOrEmpty(containerModel)) {
          this.set('containerModel', containerModel);
          this.element?.classList?.add(this.elementId);
        }

        const loader = this.element;
        const tabHeader = document.querySelector('.panel-grid__tab-header') || {};
        const tabFooter = document.querySelector('.panel-grid__footer') || {};
        const tolerenceTop = -tabHeader.getBoundingClientRect?.()?.bottom || 0;
        const tolerenceBottom = -tabFooter.getBoundingClientRect?.()?.height || 0;
        const viewportTolerance = {
          top: tolerenceTop,
          bottom: tolerenceBottom
        };
        const {
          onEnter,
          onExit
        } = this.inViewport.watchElement(loader, {
          viewportTolerance
        });
        onEnter(this.didEnterViewport.bind(this));
        onExit(this.didExitViewport.bind(this));
      }
    },

    /**
     * Hooks of ember-in-viewport
     * Check if the FormSection has entered the viewport and set isActive=true for the corresponding guide menu item
     * @void
     */
    didEnterViewport() {
      const containerModel = this.get('containerModel');
      const subs = containerModel?.sub;
      this.set('isInView', true);

      if (RA.isNotNilOrEmpty(subs)) {
        for (const s in subs) {
          const sub = subs[s];

          if (sub.className === this.elementId) {
            this.tab.setMenuItemAsActive(sub);
            break;
          }
        }
      }
    },

    /**
     * Hooks of ember-in-viewport
     * Check if the FormSection has exit the viewport and set isActive=false for the corresponding guide menu item
     * @void
     */
    didExitViewport() {
      const containerModel = this.get('containerModel');
      const subs = containerModel?.sub;

      if (RA.isNotNilOrEmpty(subs)) {
        for (const s in subs) {
          const sub = subs[s];

          if (sub.className === this.elementId) {
            this.tab.setMenuItemAsInactive(sub);
            break;
          }
        }
      }
    },

    /**
     * Hooks of ember-in-viewport
     * Unregiter the viewport
     * @void
     */
    willDestroy() {
      if (this?.isDestroyed === false) {
        if (this.isMenu) {
          const loader = this.element;
          this?.inViewport?.stopWatching?.(loader);

          this._super(...arguments);
        }
      }
    },

    isAccordianCollapsed: Ember.computed('toggle', 'isAccordian', function () {
      if (this.isAccordian && this.toggle === false) {
        return true;
      }

      return false;
    }),
    hasErrorClassNames: Ember.computed('error', function () {
      const error = this.error;

      if (error) {
        if (R.is(Array, error) && R.isEmpty(error)) {
          return '';
        }

        return 'has-error';
      }

      return '';
    }),
    isBetaAllowed: Ember.computed('betaPath', function () {
      if (!this.betaPath) {
        return true;
      }

      return this.settings.getProp(this.betaPath);
    }),
    loadControl: Ember.computed('isLazyLoad', 'isViewable', 'isInView', function () {
      let isLazyLoad = this.isLazyLoad;

      if (R.isNil(isLazyLoad)) {
        isLazyLoad = true;
      }

      if (isLazyLoad && this.isMenu) {
        return this.isViewable && this.isInView;
      }

      return true;
    }),
    actions: {
      preToggleAction(toggleAction) {
        if (this.isAccordian) {
          toggleAction();
        }
      }

    }
  });

  _exports.default = _default;
});