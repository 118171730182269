define("client/pods/messages/message/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*globals R */
  var _default = _model.default.extend({
    defaults() {
      return {
        sender: '',
        recipient: '',
        message: {
          type: '',
          payload: {}
        },
        timestampZ: ''
      };
    },

    getQuickReplies() {
      const model = this.getData('message');
      return R.pathOr({}, ['payload', 'options'])(model);
    }

  });

  _exports.default = _default;
});