define("client/mixins/doc-contacts", ["exports", "client/mixins/transporter", "ramda-extension"], function (_exports, _transporter, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * helper methods for setting up lists for tabs and panels
   */
  var _default = Ember.Mixin.create(_transporter.default, {
    /**
       * load settings from contact
       */
    loadContact(docType, contact) {
      // @TODO TEST
      const model = this.model;

      this._loadContact(model, docType, contact);
    },

    _loadContact(model, docType, contact) {
      this.set('linksList', []);
      this.set('selectedContact', contact);
      model.loadContact({
        docType,
        contact,
        paymentMethodsList: R_.dotPath('crud.lists.paymentMethods')(this),
        paymentTermsList: R_.dotPath('crud.lists.paymentTerms')(this)
      });
      this.fetchAndLoadTransporterForContactTask.perform({
        contact,
        model
      });
    },

    loadContactAndUpdateDoInfo(model, contact) {
      const docType = this.docType;
      const adapterName = this.adapterName;
      const dirty = this.onSetupDirty({
        adapterName,
        model: this.model
      });
      dirty.setData('contact', contact.getData('name'));

      this._loadContact(dirty, docType, contact);

      return this.onUpdateDocInfoTask.linked().perform(dirty);
    },

    /**
     * unload contact
     */
    unloadContact(model) {
      // @TODO: test
      const empty = '';
      const emptyArray = [];
      this.set('selectedContact', empty);
      this.set('linksList', emptyArray);
      model.setData('exclTotal', '0');
      model.setData('inclTotal', '0');
      model.setData('taxTotal', '0');
      model.unloadContact();
      this.unloadTransporter(model);
      this.removeAllDetails();
    },

    loadContactPerson(model, personOrDepartment) {
      return model.loadContactPerson(personOrDepartment);
    },

    actions: {
      loadContact(docType, contact) {
        this.loadContact(docType, contact);
      },

      loadContactAndUpdateDoInfo() {
        return this.loadContactAndUpdateDoInfo(...arguments);
      },

      unloadContact(model) {
        this.unloadContact(model);
      },

      loadContactPerson(model, personOrDepartment) {
        this.loadContactPerson(model, personOrDepartment);
      },

      loadContactDetail(model, prop, detail) {
        const value = detail.getData('value');
        model.setData(prop, value);
      },

      addContactDetail(model, prop, detail) {
        const value = detail.getData('value');
        let existing = model.getData(prop);

        if (existing) {
          existing = `${existing} / ${value}`;
        } else {
          existing = value;
        }

        model.setData(prop, existing);
      }

    }
  });

  _exports.default = _default;
});