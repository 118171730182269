define("client/pods/bsp/virtual-accounts/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        merchantId: '',
        hash: {
          A1: '',
          A2: '',
          B1: '',
          B2: ''
        }
      };
    }

  });

  _exports.default = _default;
});