define("client/pods/components/store-location/table-view/detail-row/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StoreLocationTableViewDetailRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class StoreLocationTableViewDetailRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dragAndDrop", _descriptor2, this);

      _initializerDefineProperty(this, "date", _descriptor3, this);

      _initializerDefineProperty(this, "dropPosition", _descriptor4, this);

      _initializerDefineProperty(this, "showUploadsRow", _descriptor5, this);

      _defineProperty(this, "isDraggable", true);

      _defineProperty(this, "isDroppable", true);

      this.crud.addLists(this, ['store-location/lists/menu', 'store-location/lists/tags', 'statuses']);
    }

    get rowClassName() {
      return `table-view__detail-row ${this.dropPosition}`;
    }

    get description() {
      const div = document.createElement('div');
      div.innerHTML = R.pathOr('', ['args', 'model', '_data', 'description'])(this);
      return div.innerText;
    }

    get showOpenHours() {
      const openHours = R.pathOr({}, ['_data', 'openHours'])(this.args.model);
      const hasOpenHours = R.pipe(R.isEmpty, R.not)(openHours);

      if (hasOpenHours) {
        return R.reduce((acc, day) => {
          const time = R.propOr('', day)(openHours);
          const hasOpeningTime = R.pipe(R.pathEq(['open'], 'none'), R.not)(time);

          if (hasOpeningTime) {
            acc.push({
              day,
              time
            });
          }

          return acc;
        }, [])(this.date.getWeekdays());
      }

      return [];
    }

    drop(indexStart, indexEnd, results) {
      let reIndexStoreLocation;

      if (indexStart > indexEnd) {
        reIndexStoreLocation = R.slice(indexEnd, R.inc(indexStart))(results);
      } else {
        reIndexStoreLocation = R.slice(indexStart, R.inc(indexEnd))(results);
      }

      const master = R.view(R.lensPath([0, '_data', 'master']))(results);
      this.crud.adapterCallTask.perform('updateIndexes', {
        adapterName: 'store-location/details/batch',
        appendPath: `/${master}/indexes`,
        model: reIndexStoreLocation
      });
    }

    dragOver() {
      if (this.args.tableViewOptions.onDragOver) {
        this.dropPosition = this.args.tableViewOptions.onDragOver(...arguments);
      }
    }

    dragStart() {
      if (this.args.tableViewOptions.onDragStart) {
        this.args.tableViewOptions.onDragStart(...arguments);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dragAndDrop", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dropPosition", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showUploadsRow", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "drop", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOver", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragStart", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "dragStart"), _class.prototype)), _class));
  _exports.default = StoreLocationTableViewDetailRowComponent;
});