define("client/pods/contacts/transporters/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/contacts/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TransportersModel extends _model.default {
    get transporterData() {
      const isTCat = this._data?.transporterExtension === 't-cat';
      const transporter = {
        transporterSenderName: '',
        transporterSenderTelephone: '',
        transporterSenderAddress: '',
        transporterDeliveryTime: '',
        transporterPackageTemperature: '',
        transporterPackageSize: '',
        transporterPackageType: '',
        transporterNotes: ''
      };

      if (isTCat) {
        transporter.transporterSenderName = this?._data?.senderName;
        transporter.transporterSenderTelephone = this?._data?.senderTelephone;
        transporter.transporterSenderAddress = this?._data?.senderAddress;
        transporter.transporterSenderPostNumber = this?._data?.senderPostNumber;
        transporter.transporterDeliveryTime = this?._data?.defaultDeliveryTime;
        transporter.transporterPackageSize = this?._data?.packageSizeDefault;
        transporter.transporterPackageType = this?._data?.productTypeDefault;
        transporter.transporterPackageTemperature = this?._data?.defaultTransporterAccount || this?._data?.thermosphereDefault;

        if (!transporter.transporterPackageTemperature) {
          transporter.transporterPackageTemperature = R.pathOr('', ['_data', 'transporterAccounts', 0, '_data', 'account'])(this);
        }
      }

      return transporter;
    }

    get isTCat() {
      return this._data?.transporterExtension === 't-cat';
    }

    get isHct() {
      return this._data?.transporterExtension === 'hct';
    }

  }

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/contacts/transporters',
    modelObj: TransportersModel,
    idParam: '_id'
  });

  _exports.default = _default;
});