define("client/pods/extensions/apps/tw/e-invoices/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/extensions/apps/tw/e-invoices/model"], function (_exports, R, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/extensions/apps/tw/e-invoices',
    modelObj: _model.default,
    idParam: '_id',

    posIssue(_ref) {
      let {
        payload,
        appendPath = ''
      } = _ref;
      appendPath = '/pos' + appendPath;
      return this.call({
        method: 'POST',
        appendPath,
        data: payload
      }).then(R.propOr([], 'data'));
    },

    issue(_ref2) {
      let {
        payload,
        appendPath = ''
      } = _ref2;
      return this.call({
        method: 'POST',
        appendPath,
        data: payload
      }).then(R.propOr([], 'data'));
    },

    issueBatch(_ref3) {
      let {
        payload,
        appendPath = ''
      } = _ref3;
      const path = `/batch${appendPath}`;
      return this.call({
        method: 'POST',
        appendPath: path,
        data: payload
      }).then(R.propOr([], 'data'));
    },

    rePrint(_ref4) {
      let {
        appendPath,
        payload
      } = _ref4;
      const path = `/printer/reprint${appendPath}`;
      return this.call({
        method: 'POST',
        appendPath: path,
        data: payload
      }).then(R.propOr([], 'data'));
    }

  });

  _exports.default = _default;
});