define("client/pods/grapesjs/plugins/tabs-with-header", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @project: bigordr-app
   * @file: grid
   * @author Nafis Rubio
   * @since 19/09/2020
   * @copyright (C) 2020 BigOrdr
   * @description: Grid component
   */

  /**
   * @typedef {import("../categories").Categories}
   */
  var _default = editor => {
    const {
      DomComponents,
      BlockManager
    } = editor;
    const pluginName = 'bigordr-tabs-with-header';
    const textBlock = 'bigordr-tabs-with-header-block';
    const options = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const {
      intl,

      /** @type {Categories} */
      categories
    } = options;
    const textBlockLabel = intl.t(textBlock);
    const textBlockLabelId = `${textBlockLabel}-id`;
    DomComponents.addType(pluginName, {
      model: {
        defaults: {
          classes: [pluginName],
          components: [{
            type: 'text',
            tagName: 'h2',
            content: 'Title Anchor Tag ⚓️',
            droppable: false,
            draggable: false,
            removable: false,
            copyable: false,
            attributes: {
              [`data-${pluginName}-anchor`]: true
            },
            traits: [{
              type: 'bigordr-animation-trait',
              name: 'animationSelector',
              label: 'Select Animation'
            }, {
              type: 'bigordr-animation-iteration-trait',
              name: 'animationIterationSelector',
              label: intl.t('select iteration count')
            }, {
              type: 'bigordr-animation-duration-trait',
              name: 'animationDuration',
              label: intl.t('enter duration (sec)')
            }]
          }, {
            type: 'tabs',
            draggable: false,
            removable: false,
            copyable: false
          }],
          traits: []
        }
      }
    });
    BlockManager.add(textBlockLabelId, {
      label: textBlockLabel,
      category: categories.complex,
      content: {
        type: pluginName
      },
      render: () => `
<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg width="100%" height="100%" viewBox="0 0 4267 4267" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-miterlimit:1.5;">
    <g transform="matrix(4.16667,0,0,4.16667,0,0)">
        <g transform="matrix(2.28635,0,0,2.40366,-172.973,-970.666)">
            <rect x="87.22" y="585.137" width="424.78" height="201.562" style="fill:rgb(235,235,235);stroke:black;stroke-width:19.37px;"/>
        </g>
        <g transform="matrix(48.2112,0,0,48.2112,-63.9628,50.1959)">
            <path d="M7,5.013C7,5.006 6.994,5 6.987,5L3.013,5C3.006,5 3,5.006 3,5.013L3,6.987C3,6.994 3.006,7 3.013,7L6.987,7C6.994,7 7,6.994 7,6.987L7,5.013Z"/>
        </g>
        <g transform="matrix(48.2112,0,0,48.2112,-63.9628,50.1959)">
            <path d="M12,5.013C12,5.006 11.994,5 11.987,5L8.013,5C8.006,5 8,5.006 8,5.013L8,6.987C8,6.994 8.006,7 8.013,7L11.987,7C11.994,7 12,6.994 12,6.987L12,5.013Z"/>
        </g>
        <g transform="matrix(48.2112,0,0,48.2112,-63.9628,50.1959)">
            <path d="M17,5.013C17,5.006 16.994,5 16.987,5L13.013,5C13.006,5 13,5.006 13,5.013L13,6.987C13,6.994 13.006,7 13.013,7L16.987,7C16.994,7 17,6.994 17,6.987L17,5.013Z"/>
        </g>
        <g transform="matrix(0.431255,0,0,0.431255,-375.802,-44.7949)">
            <text x="926.12px" y="672.779px" style="font-family:'Arial-Black', 'Arial Black', sans-serif;font-weight:900;font-size:288px;">TITLE</text>
        </g>
        <g transform="matrix(0.515727,0,0,0.24,-643.139,0)">
            <rect x="1211.46" y="573.284" width="2050.17" height="540.011" style="fill:none;stroke:black;stroke-width:59.67px;stroke-linejoin:round;"/>
        </g>
    </g>
</svg>

`
    });
  };

  _exports.default = _default;
});