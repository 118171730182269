define("client/pods/components/documents/pos/view/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentsPosViewComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class DocumentsPosViewComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "pin", _descriptor, this);

      _initializerDefineProperty(this, "originalPin", _descriptor2, this);

      _initializerDefineProperty(this, "pinModalVisible", _descriptor3, this);

      _initializerDefineProperty(this, "showCheckoutDetailsPanel", _descriptor4, this);

      this.eventListeners('onLoad');
    }

    willDestroy() {
      this.eventListeners('onUnLoad');
      super.willDestroy();
    }

    eventListeners(action) {
      const onConfirmRefresh = function (event) {
        event.preventDefault();
        return event.returnValue = 'Are you sure you want to leave the page?';
      };

      if (action === 'onLoad') {
        window.addEventListener('beforeunload', onConfirmRefresh, {
          capture: true
        });
      } else if (action === 'onUnLoad') {
        window.removeEventListener('beforeunload', onConfirmRefresh, {
          capture: true
        });
      }
    }

    get tabIcon() {
      const areProductsFullScreen = R.pathEq(['args', 'areProductsFullScreen'], true)(this);

      if (areProductsFullScreen) {
        return 'fas fa-angle-right';
      }

      return 'fas fa-angle-left';
    }

    get tabLabel() {
      const areProductsFullScreen = R.pathEq(['args', 'areProductsFullScreen'], true)(this);

      if (areProductsFullScreen) {
        return 'show more';
      }

      return 'show less';
    }

    get isFullscreenLock() {
      if (this.originalPin) {
        return this.originalPin?.length > 0;
      }

      return false;
    }

    togglePinModalVisible() {
      this.pinModalVisible = !this.pinModalVisible;
    }

    onLockUnlockWithPin(onCloseDialogAction, onTogglePosFullscreenMode) {
      if (this.originalPin) {
        const isValidPin = this.pin === this.originalPin;

        if (isValidPin) {
          this.originalPin = '';

          if (onTogglePosFullscreenMode) {
            onTogglePosFullscreenMode();
          }

          if (onCloseDialogAction) {
            onCloseDialogAction();
          }
        }

        if (onCloseDialogAction) {
          onCloseDialogAction();
        }

        this.pin = '';
        this.togglePinModalVisible();
        return;
      }

      this.originalPin = this.pin;
      this.pin = '';

      if (onTogglePosFullscreenMode) {
        onTogglePosFullscreenMode();
      }

      this.togglePinModalVisible();

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    togglePosFullscreenMode(onTogglePosFullscreenMode) {
      if (this.isFullscreenLock) {
        this.togglePinModalVisible();
        this.onLockUnlockWithPin(null, onTogglePosFullscreenMode);
        return;
      }

      if (onTogglePosFullscreenMode) {
        onTogglePosFullscreenMode();
      }
    }

    toggleShowCheckoutDetailsPanel() {
      this.showCheckoutDetailsPanel = !this.showCheckoutDetailsPanel;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pin", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "originalPin", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pinModalVisible", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showCheckoutDetailsPanel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "togglePinModalVisible", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "togglePinModalVisible"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onLockUnlockWithPin", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onLockUnlockWithPin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePosFullscreenMode", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "togglePosFullscreenMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleShowCheckoutDetailsPanel", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowCheckoutDetailsPanel"), _class.prototype)), _class));
  _exports.default = DocumentsPosViewComponent;
});