define("client/pods/components/dispatch/tab-container/component", ["exports", "ramda", "client/mixins/crud", "client/mixins/date", "ember-concurrency", "client/pods/dispatch/model", "client/utils/nventor", "ramda-extension", "ramda-adjunct"], function (_exports, R, _crud, _date, _emberConcurrency, _model, _nventor, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, {
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    model: null,
    errors: null,
    unprepared: null,
    adapterName: 'dispatch',
    results: null,
    count: 20,
    page: 1,
    dispatchSortByListName: 'dispatchSortBy',
    showDetails: true,
    showSideBar: true,
    filtersAdapterName: 'documents/dispatch/filters',
    resultsUpdated: '',

    init() {
      this._super(...arguments);

      this.set('results', []);
      this.set('filters', []);
      this.crud.addLists(this, ['dispatch/lists/menu', 'dispatch/lists/print-all-sub-panels', 'dispatch/lists/processes', 'dispatch/lists/processes/menu', 'dispatch/lists/filters/dispatchstatuses', 'dispatch/lists/filters/sort', 'paymentMethods', 'paymentTerms', 'contacts/lists/tags', 'contacts/transporters/filters', 'contacts/transporters', 'filters/count', 'filters/sortDirection']);
      const pastDays = this.getPastDays(7);
      this.set('pastDays', pastDays);
      this.set('today', this.getPeriod(this.getToday()));
      this.loadTabTask.perform();
    },

    didInsertElement() {
      this._super(...arguments);

      this.fetchByProcessesCountTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const dateZ = loadInstructions.getData('find.dateZ');
        yield this.displayDispatched({
          dateZ
        });
        return true;
      }

      return false;
    }),
    todayLabel: Ember.computed('today.dateZ', function () {
      const today = this.today;
      const dateZ = today.dateZ;
      const intl = this.intl;
      return intl.t('dispatched') + ': ' + this.formatDate(dateZ, 'MM/DD') + ' (' + intl.t('today') + ')';
    }),
    todayDispatchCount: Ember.computed('dispatchProcessesByCount.synced', function () {
      const todayZ = this.dateService.getStartOfDayZ();
      const count = R.path(['dispatchProcessesByCount', '_data', todayZ, 'count'])(this) || 0;
      return count;
    }),
    currentDispatchProcessLabel: Ember.computed('currentDispatchProcess', function () {
      const intl = this.intl;
      const currentDispatchProcess = this.currentDispatchProcess;

      if (currentDispatchProcess) {
        const name = currentDispatchProcess.getData('name');

        if (currentDispatchProcess.getData('translate') === true) {
          return intl.t(name);
        }

        return name;
      }

      return intl.t('undispatched');
    }),
    hasMinimizedDispatchProcesses: Ember.computed('crud.lists.dispatch-lists-processes.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];

      if (dispatchProcesses.length > 5) {
        return true;
      }

      return false;
    }),
    dispatchProcessesMenu: Ember.computed('crud.lists.dispatch-lists-processes-menu.@each.isDeleted', 'dispatchProcessesByCount.synced', function () {
      const list = R_.dotPath('crud.lists.dispatch-lists-processes-menu')(this) || [];
      const existingDispatchProcesses = R.pipe(R.reject(R.propEq('isDeleted', true)), R.sortWith([R.ascend(R.path(['_data', 'index']))]))(list);

      if (RA.isNilOrEmpty(existingDispatchProcesses)) {
        return existingDispatchProcesses;
      }

      const dispatchProcessesByCount = this.dispatchProcessesByCount || {};
      const danglingDisapatchProcesses = R.pipe(R.pathOr([], ['_data', 'processes']), R.reject(dispatchProcessByCountData => {
        if (dispatchProcessByCountData?.dispatchProcess === 'dispatched') {
          return true;
        }

        const code = dispatchProcessByCountData.process;
        return R.find(R.pathEq(['_data', 'code'], code))(existingDispatchProcesses);
      }), R.map(dispatchProcessByCountData => {
        const code = dispatchProcessByCountData.process;
        const hideIfEmpty = R.propEq('hideIfEmpty', true)(dispatchProcessByCountData);
        return this.crud.setupNewRecord({
          adapterName: 'dispatch/lists/processes/menu',
          data: {
            code: code,
            name: code,
            hideIfEmpty,
            isDangling: true,
            isEditable: false,
            isLocked: true,
            translate: false
          }
        });
      }))(dispatchProcessesByCount);
      return R.concat(existingDispatchProcesses, danglingDisapatchProcesses);
    }),
    fetchByProcessesCountTask: (0, _emberConcurrency.task)(function* () {
      const adapterName = 'dispatch/processes/count';
      const dispatchProcessesByCount = yield this.findTask.perform({
        adapterName
      });
      this.set('dispatchProcessesByCount', dispatchProcessesByCount);
    }),
    fetchDispatchDataTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        dispatchProcess,
        period,
        filters
      } = _ref;
      return function* () {
        _this.set('unpaginatedResults', []);

        filters.setData('dispatchProcessesCriterias', dispatchProcess.getData('criterias'));
        filters.setData('dispatchProcess', dispatchProcess.getData('code'));

        if (period) {
          filters.setData('dispatchDateZ', period.date);
        } else {
          filters.setData('dispatchDateZ', '');
        } // Hide isPos on dispatch


        filters.setData('isPos', false);
        const searchSettings = {
          adapterName: 'dispatch',
          resultsProperty: '',
          resultsToggle: '',
          query: '',
          filters,
          component: _this
        };
        const results = yield _this.crud.searchRecordsTask.perform(searchSettings);

        _this.setResultsAndSummaryData(results);

        return results;
      }();
    }),
    fetchUnpaginatedDispatchDataTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        dispatchProcess,
        period,
        setResults = true,
        hidePrinted = ''
      } = _ref2;
      return function* () {
        const filters = _this2.filters;
        const filtersData = filters.serialize();

        if (!hidePrinted) {
          hidePrinted = '';
        }

        const defaultFiltersData = R.mergeRight(filtersData, {
          matches: [],
          count: 1000,
          dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
          dispatchProcess: dispatchProcess.getData('code'),
          hidePrinted,
          sort: filters.getData('sort')
        }); // Only filter by date when on dispatch process

        const isDispatchProcess = defaultFiltersData.dispatchProcess === _this2.get('constants.dispatchProcess.dispatched');

        if (period && isDispatchProcess) {
          defaultFiltersData.dispatchDateZ = period.date;
        } else {
          defaultFiltersData.dispatchDateZ = '';
        }

        const unpaginatedFilters = _this2.search.setupFilters({
          adapterName: _this2.filtersAdapterName,
          defaultFiltersData
        });

        const searchSettings = {
          adapterName: 'dispatch',
          resultsProperty: '',
          resultsToggle: '',
          query: '',
          filters: unpaginatedFilters,
          component: _this2
        };
        const results = yield _this2.crud.searchRecordsTask.perform(searchSettings);

        if (setResults !== false) {
          _this2.setResultsAndSummaryData(results, 'unpaginatedResults');
        }

        return results;
      }();
    }),
    customSearchTask: (0, _emberConcurrency.task)(function () {
      var _this3 = this;

      let {
        adapterName,
        filters
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let query = arguments.length > 1 ? arguments[1] : undefined;
      return function* () {
        adapterName = adapterName || _this3.adapterName;
        filters.setData('query', query);
        const results = yield _this3.searchRecordsTask.perform({
          adapterName,
          filters,
          query
        });

        _this3.setResultsAndSummaryData(results);

        return _this3.results;
      }();
    }),
    customAdvancedSearchTask: (0, _emberConcurrency.task)(function (_ref3) {
      var _this4 = this;

      let {
        filters
      } = _ref3;
      return function* () {
        filters.setData('query', '');
        const adapterName = _this4.adapterName;
        const results = yield _this4.searchRecordsTask.perform({
          adapterName,
          filters
        });

        _this4.setResultsAndSummaryData(results);

        return _this4.results;
      }();
    }),
    customReloadSearchTask: (0, _emberConcurrency.task)(function (_ref4) {
      var _this5 = this;

      let {
        filters,
        lastSearchSettingsProperty = 'lastSearchSettings',
        searchSettings,
        reset = false
      } = _ref4;
      return function* () {
        if (reset) {
          filters.reset();
        }

        yield _this5.customAdvancedSearchTask.perform({
          filters,
          lastSearchSettingsProperty,
          searchSettings,
          reset
        });
      }();
    }),
    paginateDispatchDataTask: (0, _emberConcurrency.task)(function* () {
      const results = yield this.paginateSearchRecordsTask.perform(...arguments);
      this.setResultsAndSummaryData(results);
    }),
    isDispatchedProcessModel: Ember.computed('crud.lists.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const isDispatched = this.get('constants.dispatchProcess.dispatched');
      return R.find(R.pathEq(['_data', 'code'], isDispatched), dispatchProcesses);
    }),
    isAwaitingPaymentProcessModel: Ember.computed('crud.lists.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const awaitingPaymentCode = this.get('constants.dispatchProcess.awaitingPayment');
      return R.find(R.pathEq(['_data', 'code'], awaitingPaymentCode), dispatchProcesses);
    }),
    // undispatchedPanels: computed('crud.lists.dispatch-lists-processes.[]', function () {
    //   const processes = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || []
    //   return R.reject(R.path(['_data', 'noPanel']), processes)
    // }),
    resultsProxy: Ember.computed('results.[]', function () {
      const results = this.results || [];
      return R.map(result => {
        return _nventor.default.createModel(_model.default, {}, {
          model: result,
          isSelected: false,
          showDetails: true // @TODO: get this from settings

        });
      })(results);
    }),
    unpaginatedResultsProxy: Ember.computed('unpaginatedResults.[]', function () {
      const results = this.unpaginatedResults || [];
      return R.map(result => _nventor.default.createModel(_model.default, {}, {
        model: result,
        isSelected: false,
        showDetails: true // @TODO: get this from settings

      }), results);
    }),
    displayTask: (0, _emberConcurrency.task)(function (_ref5) {
      var _this6 = this;

      let {
        dispatchProcess,
        period,
        isDispatched = false,
        onCloseDialogAction
      } = _ref5;
      return function* () {
        if (!dispatchProcess) {
          console.log('MISSING::dispatchProcess', {
            dispatchProcess,
            period,
            isDispatched,
            onCloseDialogAction
          });
        }

        _this6.set('currentDispatchProcess', dispatchProcess);

        const dispatchProcessCode = dispatchProcess.getData('code');

        if (!period && dispatchProcessCode === _this6.get('constants.dispatchStatus.dispatched')) {
          period = _this6.getPeriod();
        }

        const filters = _this6.search.setupFilters({
          adapterName: _this6.filtersAdapterName,
          altStorageProp: _this6.filtersAdapterName + `__${dispatchProcessCode}`,
          rememberProps: ['sortBy'],
          rememberMe: true
        });

        filters.setData('dateHeaderValue', 'timestampZ_latest');
        filters.setData('dateHeaderLabel', 'updated date');

        _this6.set('filters', filters);

        const subTabOptions = {
          component: 'dispatch/panels-display',
          dispatchProcess: dispatchProcess,
          period: period,
          isDispatched,
          filters
        };
        yield _this6.fetchDispatchDataTask.perform({
          dispatchProcess,
          period,
          filters
        });

        if (onCloseDialogAction) {
          onCloseDialogAction();
        }

        const tab = _this6.tab;

        _this6.tabsManager.replaceSubTab(tab, subTabOptions);
      }();
    }),

    displayDispatched(_ref6) {
      let {
        period,
        dateZ
      } = _ref6;

      if (!period && dateZ) {
        period = this.getPeriod(dateZ);
      }

      const dispatchProcess = this.isDispatchedProcessModel;
      this.displayTask.perform({
        dispatchProcess,
        period,
        isDispatched: true
      });
    },

    setResultsAndSummaryData(results) {
      let prop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'results';
      const summaryData = R.prop('summaryData', results);
      const dashData = R.prop('dashData', results);
      const docs = R.prop('docs', results);
      this.set('summaryData', summaryData);
      this.set('dashData', dashData);
      this.set(prop, docs);
    },

    actions: {
      refresh(dispatchProcess, period, isDispatched, models, nextDispatchProcess) {
        // nextDispatchProcess = nextDispatchProcess || dispatchProcess
        nextDispatchProcess = dispatchProcess;
        this.displayTask.perform({
          dispatchProcess: nextDispatchProcess,
          period,
          isDispatched
        });
      },

      display(dispatchProcess, onCloseDialogAction) {
        this.displayTask.perform({
          dispatchProcess,
          onCloseDialogAction
        });
      },

      displayDispatched(period) {
        this.displayDispatched({
          period
        });
      },

      displayCustomDate(dateZ) {
        this.displayDispatched({
          dateZ
        });
      },

      displaySummaryPanel(resourceData) {
        const subTabOptions = {
          component: resourceData.getData('component'),
          value: resourceData.getData('value')
        };
        const tab = this.tab;
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      },

      afterSplit(dispatchProcess) {
        this.displayTask.perform({
          dispatchProcess
        });
      },

      afterMerge(dispatchProcess) {
        this.displayTask.perform({
          dispatchProcess
        });
      }

    }
  });

  _exports.default = _default;
});