define("client/helpers/substringFrom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params, hash) {
      // returns substring using start index and length of characters to return
      // @NOTE: don't confuse with substring.
      const str = params[0] + '';
      const from = hash.from;
      const fromOffset = hash.fromOffset;
      const end = hash.end;
      let fromIndex = from;

      if (typeof from === 'string') {
        fromIndex = str.indexOf(from);
      }

      if (fromOffset) {
        fromIndex = fromIndex + fromOffset;
      }

      let endIndex = end;

      if (end == null) {
        endIndex = str.length;
      }

      if (typeof end === 'string') {
        endIndex = str.indexOf(end);
      }

      if (str) {
        return str.substring(fromIndex, endIndex);
      }

      return '';
    }

  });

  _exports.default = _default;
});