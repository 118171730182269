define("client/pods/specs/model", ["exports", "client/pods/base/model", "ramda", "ramda-extension", "ramda-adjunct", "client/utils/nventor"], function (_exports, _model, R, R_, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    init() {
      this._super(...arguments); // @TODO:
      // const specsPerMethod = {
      //   byOrders: ['', 'index', ]
      // }


      const specs = [{
        // empty block for spaces and first default
        value: '',
        defaultLabel: '',
        prop: '',
        translate: false,
        allowedTransforms: [],
        disallowedTransforms: []
      }, {
        value: 'index',
        defaultLabel: 'index',
        prop: null,
        allowedTransforms: ['index'],
        disallowedTransforms: []
      }, {
        value: '#',
        defaultLabel: '#',
        prop: 'docNo',
        translate: false,
        allowedTransforms: [],
        disallowedTransforms: []
      }, {
        value: 'date',
        defaultLabel: 'date',
        prop: 'dateZ',
        type: 'date',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index'] // allowCustomOptions: true/false

      }, {
        value: 'dispatch date',
        defaultLabel: 'dispatch date',
        prop: 'dispatchDateZ',
        type: 'date',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index'] // allowCustomOptions: true/false

      }, {
        value: 'person',
        defaultLabel: 'person_export',
        prop: 'person',
        label: this.intl.t('person_export'),
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'contact',
        defaultLabel: 'customer_export',
        prop: 'contact',
        label: this.intl.t('customer_export'),
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'person + contact',
        defaultLabel: 'person + contact',
        prop: ['person', 'contact'],
        allowedTransforms: ['concat'],
        disallowedTransforms: ['index']
      }, {
        value: 'address',
        defaultLabel: 'address',
        prop: 'address',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'convenienceStoreBrand',
        defaultLabel: 'convenience store brand',
        prop: 'convenienceStoreBrand',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'storeId',
        defaultLabel: 'convenience store id',
        prop: 'storeId',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'storeName',
        defaultLabel: 'convenience store name',
        prop: 'storeName',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'telephone',
        defaultLabel: 'telephone',
        prop: 'telephone',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'payment method',
        defaultLabel: 'payment method',
        prop: 'paymentMethodKey',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'total',
        defaultLabel: 'inclusive total',
        type: 'number',
        prop: 'inclTotal',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'unpaid total',
        defaultLabel: 'unpaid total',
        type: 'number',
        prop: 'unpaidInclTotal',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'exclTotal',
        defaultLabel: 'exclusive total',
        type: 'number',
        prop: 'exclTotal',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'taxTotal',
        defaultLabel: 'tax total',
        type: 'number',
        prop: 'taxTotal',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'packages',
        defaultLabel: 'packages',
        prop: 'packages',
        type: 'number',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'notes',
        defaultLabel: 'notes',
        prop: 'notes',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'ref',
        defaultLabel: 'ref',
        prop: 'ref',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'waybill',
        defaultLabel: 'waybill',
        prop: 'waybill',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'shop',
        defaultLabel: 'shop',
        prop: 'source',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'rewardInclTotal',
        defaultLabel: 'rewards inclusive total',
        prop: 'rewardInclTotal',
        type: 'number',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'discInclTotal',
        defaultLabel: 'discount amount',
        prop: 'discInclTotal',
        type: 'number',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'email',
        defaultLabel: 'email',
        prop: 'email',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'taxNumber',
        defaultLabel: 'tax number',
        prop: 'taxNumber',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'companyName',
        defaultLabel: 'tax_companyName',
        prop: 'companySettings.companyName',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index'] // }, {
        //   value: 'senderName',
        //   defaultLabel: 'sender name',
        //   prop: 'companyName',
        //   allowedTransforms: ['all'],
        //   disallowedTransforms: ['index']

      }, {
        value: 'senderTelephone',
        defaultLabel: 'sender telephone',
        prop: 'companySettings.companyTelephone',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'senderAddress',
        defaultLabel: 'sender address',
        prop: 'companySettings.companyAddress',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, // {
      //   value: 'senderFax',
      //   defaultLabel: 'senderFax',
      //   prop: 'companyFax',
      //   allowedTransforms: ['all'],
      //   disallowedTransforms: ['index']
      // },
      {
        value: 'transporterName',
        defaultLabel: 'transporter',
        prop: 'transporterLabel',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'transporterAccountName',
        defaultLabel: 'transporter account',
        prop: 'transporterAccount',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'transporterAccountNumber',
        defaultLabel: 'transporter account number',
        prop: 'transporterAccountNumber',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index']
      }, {
        value: 'rewardPointApplied',
        defaultLabel: 'reward_spent_points',
        prop: 'rewardPointApplied',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }, {
        value: 'rewardPointEarned',
        defaultLabel: 'reward_earned_points',
        prop: 'rewardPointEarned',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }, {
        value: 'promoCode',
        defaultLabel: 'promo code',
        prop: 'promoCode',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }, {
        value: 'status',
        defaultLabel: 'status',
        prop: 'status',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }, {
        value: 'dispatchStatus',
        defaultLabel: 'dispatch status',
        prop: 'dispatchStatus',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }, {
        value: 'invoice number',
        defaultLabel: 'invoice number',
        prop: 'eInvoices.numbers',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }, {
        value: 'item',
        defaultLabel: 'item',
        item: '',
        // specific item
        isDetail: true,
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }, {
        value: 'shopDotComCommission',
        defaultLabel: 'shopDotComCommission',
        prop: 'shopDotComCommission',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }, {
        value: 'custom',
        defaultLabel: 'custom',
        prop: 'custom',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'tableLookup']
      }, {
        value: 'country',
        defaultLabel: 'country',
        prop: 'addressData.country',
        checkProp: 'hasAdvancedAddress',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'tableLookup']
      }, // {
      //   value: 'countryCode',
      //   defaultLabel: 'countryCode',
      //   prop: 'addressData.countryCode',
      //   checkProp: 'hasAdvancedAddress',
      //   allowedTransforms: ['all'],
      //   disallowedTransforms: ['index', 'tableLookup']
      // },
      {
        value: 'district',
        defaultLabel: 'district',
        prop: 'addressData.district',
        checkProp: 'hasAdvancedAddress',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'tableLookup']
      }, {
        value: 'postCode',
        defaultLabel: 'postCode',
        prop: 'addressData.postCode',
        checkProp: 'hasAdvancedAddress',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'tableLookup']
      }, {
        value: 'region',
        defaultLabel: 'region',
        prop: 'addressData.region',
        checkProp: 'hasAdvancedAddress',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'tableLookup']
      }, {
        value: 'street',
        defaultLabel: 'street',
        prop: 'addressData.street',
        checkProp: 'hasAdvancedAddress',
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'tableLookup']
      }, {
        value: 'itemName',
        defaultLabel: 'item',
        prop: 'item',
        isDetail: true,
        isAutoDetail: true,
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }, {
        value: 'product code',
        defaultLabel: 'product code',
        prop: 'itemCode',
        isDetail: true,
        isAutoDetail: true,
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }, {
        value: 'qty',
        defaultLabel: 'qty',
        prop: 'qty',
        type: 'number',
        isDetail: true,
        isAutoDetail: true,
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }, {
        value: 'inclusive amount',
        defaultLabel: 'inclusive amount',
        prop: 'postDiscInclAmt',
        type: 'number',
        isDetail: true,
        isAutoDetail: true,
        allowedTransforms: ['all'],
        disallowedTransforms: ['index', 'concat']
      }];
      const availableNormalSpecs = R.map(spec => {
        const hasLabel = spec.label;

        if (!hasLabel) {
          let label = spec.defaultLabel;

          if (spec?.translate !== false) {
            label = this.intl.t(spec.defaultLabel);
          }

          spec.label = label;
        }

        return Ember.Object.create(spec);
      })(specs);
      this.set('availableNormalSpecs', availableNormalSpecs);
    },

    availableCombineProps: Ember.computed('availableNormalSpecs.[]', function () {
      return R.pipe(R.propOr([], 'availableNormalSpecs'), R.reject(_nventor.default.isNilOrEmptyDotPath('prop')), R.filter(data => {
        const dataProp = R_.dotPath('prop', data);
        return R.is(String, dataProp);
      }))(this);
    }),

    populate(data, attrs) {
      const availableNormalSpecs = this.get('availableNormalSpecs');
      const dataSpecs = R.pipe(R.propOr([], 'specs'), R.map(R.map(spec => {
        return this.dataManager.setAsRecord({
          adapterName: 'specs/child',
          data: spec,
          attrs: {
            availableNormalSpecs
          }
        });
      })))(data);
      data.specs = dataSpecs;
      data.originalSpecsStructure = dataSpecs;

      this._super(...arguments);
    },

    columnNames: Ember.computed('_data.specs.[]', 'synced', function () {
      return R.pipe(R.pathOr([], ['_data', 'specs']), _nventor.default.safeHeadOr([]))(this);
    }),
    availableMethods: Ember.computed('_data.{}', function () {
      return [Ember.Object.create({
        label: 'byDetails',
        value: 'byDetails'
      }), Ember.Object.create({
        label: 'byAllOrders',
        value: 'byAllOrders'
      })];
    }),

    addNewRow() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let insertAfterIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      let insertAtIndex = 0;

      if (insertAfterIndex >= 0) {
        insertAtIndex = parseInt(insertAfterIndex) + 1;
      }

      const specs = this.get('_data.specs') || [];

      if (insertAfterIndex > specs.length) {
        insertAfterIndex = specs.length;
      }

      if (RA.isNilOrEmpty(data)) {
        data = {
          value: '',
          prop: '',
          label: '',
          translate: false
        };
      }

      const availableNormalSpecs = this.get('availableNormalSpecs');
      const newCell = this.dataManager.setAsRecord({
        adapterName: 'specs/child',
        data: data,
        attrs: {
          availableNormalSpecs
        }
      });
      specs.insertAt(insertAtIndex, RA.list(newCell));
    },

    sortedSpecs: Ember.computed('synced', function () {
      const sortedSpecs = R_.dotPath('_data.specs')(this);
      return sortedSpecs;
    })
  });

  _exports.default = _default;
});