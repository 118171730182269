define("client/pods/components/msgs/deleted-error/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    msg: Ember.computed('model.{isDeleted,isOutOfDate,synced}', function () {
      const isDeleted = this.get('model.isDeleted');

      if (isDeleted) {
        return 'deleted';
      }

      const isOutOfDate = this.get('model.isOutOfDate');

      if (isOutOfDate) {
        return 'data out of date';
      }
    })
  });

  _exports.default = _default;
});