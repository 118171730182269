define("client/pods/components/dispatch/processes-item-dispatched/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BubpfnbA",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"elements/element-btn\",null,[[\"badgeClassNames\",\"label\",\"translate\",\"classNames\",\"hasBadge\",\"badge\",\"onSubmit\",\"isRunning\"],[[28,\"if\",[[28,\"gt\",[[24,[\"index\"]],0],null],\"u-badge--gray\",\"\"],null],[28,\"concat\",[[24,[\"result\",\"mm\"]],\"/\",[24,[\"result\",\"dd\"]],\"(\",[28,\"tt\",[[24,[\"result\",\"weekday\"]]],null],\")\"],null],false,[24,[\"btnClassNames\"]],[24,[\"dispatchProcessCountComputed\"]],[24,[\"dispatchProcessCountComputed\"]],[24,[\"onSubmit\"]],[24,[\"isRunning\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dispatch/processes-item-dispatched/template.hbs"
    }
  });

  _exports.default = _default;
});