define("client/pods/components/guests/campaign-login/component", ["exports", "ramda", "ember-concurrency", "client/config/environment"], function (_exports, R, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    url: '',
    password: '',
    guests: Ember.inject.service(),
    router: Ember.inject.service(),
    auth: Ember.inject.service(),

    init() {
      this.crud.addLists(this, ['languages']);

      this._super(...arguments);
    },

    redirectToNewWay() {
      this.guests.redirectToNewWay();
    },

    loginAsGuestTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('errorMsg', '');
        const guestResource = this.guestResource;
        const credentials = {
          resource: guestResource,
          password: this.password
        };
        const data = yield this.guests.call('POST', 'api/guests/login', credentials);
        const redirectUrl = R.path(['data', 'redirect'], data);

        if (redirectUrl) {
          window.open(redirectUrl, '_self');
        }
      } catch (err) {
        this.set('report', false);

        if (err.data === 'not connected') {
          window.location.reload();
        }

        this.set('errorMsg', 'invalid password');
      }
    }).drop(),
    version: Ember.computed('env.version', function () {
      return _environment.default.version || '';
    }),
    actions: {
      setLanguage(item) {
        this.auth.setLanguage(item.getData('value'));
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "redirectToNewWay", [_dec], Object.getOwnPropertyDescriptor(_obj, "redirectToNewWay"), _obj)), _obj)));

  _exports.default = _default;
});