define("client/pods/extensions/apps/tw/e-invoices/to-issue/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/extensions/apps/tw/e-invoices/to-issue/model", "ramda", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/extensions/apps/tw/e-invoices/to-issue',
    modelObj: _model.default,
    idParam: false,

    afterFindAll(responseData) {
      // overrides what happens after findAll in data-manager
      // this is required because findAll should return an array but an object is returned
      // cannot use find because require the use of filters/pagination
      responseData.toIssue = R.pipe(R.propOr([], 'toIssue'), R.map(docData => {
        return _nventor.default.createModel(_model.default, docData);
      }))(responseData);
      responseData.invalid = R.pipe(R.propOr([], 'invalid'), R.map(docData => {
        return _nventor.default.createModel(_model.default, docData);
      }))(responseData);
      return responseData;
    }

  });

  _exports.default = _default;
});