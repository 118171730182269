define("client/pods/components/extensions/apps/tw/seven-eleven/report-status-days/additional-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rkURdGHu",
    "block": "{\"symbols\":[\"byDaysOldData\",\"doc\",\"index\",\"@groupData\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"td\",true],[8],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,4,[\"groupedByDaysOld\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[\"docs\"]]],null,{\"statements\":[[0,\"        \"],[5,\"elements/info-card\",[],[[\"@index\"],[[28,\"sum\",[[23,3,[]],1],[[\"rounding\"],[0]]]]],{\"statements\":[[0,\"\\n          \"],[5,\"helpers/tab-link\",[[12,\"classNames\",\"u-link--text\"]],[[\"@hasLink\",\"@tooltipContent\",\"@tabOptions\"],[[23,2,[\"_key\"]],[28,\"tt\",[[28,\"concat\",[[23,2,[\"docType\"]],\"-\",[23,2,[\"docName\"]]],null]],null],[28,\"hash\",null,[[\"component\",\"title\",\"docType\",\"docName\",\"loadInstructions\"],[\"documents/tab-container\",[28,\"concat\",[[23,2,[\"docType\"]],\"-\",[23,2,[\"docName\"]]],null],[23,2,[\"docType\"]],[23,2,[\"docName\"]],[28,\"hash\",null,[[\"find\"],[[28,\"hash\",null,[[\"id\"],[[23,2,[\"_key\"]]]]]]]]]]]]],{\"statements\":[[0,\"\\n            \"],[7,\"p\",true],[10,\"class\",\"u-desc\"],[8],[0,\"\\n              \"],[1,[23,2,[\"waybill\"]],false],[0,\" - \"],[1,[23,2,[\"contact\"]],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"p\",true],[10,\"class\",\"u-desc u-desc--italics u-desc--mini\"],[8],[0,\"\\n              #\"],[1,[23,2,[\"docNo\"]],false],[0,\" - \"],[1,[23,2,[\"ref\"]],false],[0,\"\\n            \"],[9],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/seven-eleven/report-status-days/additional-info/template.hbs"
    }
  });

  _exports.default = _default;
});