define("client/pods/extensions/apps/tw/shopee/lists/orders/date-range/adapter", ["exports", "@ember-data/adapter/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtensionsAppsTwShopeeListsOrdersDateRangeAdapter extends _jsonApi.default {}

  _exports.default = ExtensionsAppsTwShopeeListsOrdersDateRangeAdapter;
});