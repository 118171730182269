define("client/pods/components/admin/pods/search-results/component", ["exports", "client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',

    init() {
      this._super(...arguments);

      this.set('config', _environment.default);
    }

  });

  _exports.default = _default;
});