define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/component", ["exports", "client/mixins/crud", "ramda", "ramda-extension"], function (_exports, _crud, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    constants: Ember.inject.service(),
    isNew: false,
    isEditing: false,
    hasThen: true,
    adapterName: 'channels/shop/campaigns/automations',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['automations/criterias', 'automations/when', 'automations/when/itemQty']);
    },

    selectedAutomation: Ember.computed('crud.lists.automations-when.[]', 'automationModel._data.when', function () {
      const when = this.get('automationModel._data.when');
      const list = R_.dotPath('crud.lists.automations-when')(this) || [];
      return list.findBy('_data.value', when);
    }),
    conditionsComponent: Ember.computed('selectedAutomation.[]', function () {
      return this.selectedAutomation.getData('component');
    }),

    willDestroyElement() {
      if (this?.isDestroyed === false) {
        if (this.isNew) {
          this.cancelEditing({
            adapterName: this.adapterName,
            dirty: this.automationModel
          });
          this.set('automationModel', '');
        }

        this._super(...arguments);
      }
    },

    actions: {
      loadItem(product) {
        const automationModel = this.automationModel;
        automationModel.setData('itemCode', product.getData('code'));
      },

      resetItem() {
        const automationModel = this.automationModel;
        automationModel.setData('itemCode', '');
      },

      toggleAdvanced() {
        this.toggleProperty('showAdvanced');
      },

      async editAutomation(automationModel) {
        const dirty = this.setupDirty({
          adapterName: this.adapterName,
          model: automationModel
        });
        this.set('dirty', dirty);
        this.set('errors', null);
      },

      // async updateAutomation (dirty, onCloseDialogAction) {
      //   return this.get('replaceRecordTask').perform({
      //     adapterName: this.adapterName,
      //     model: automationModel,
      //     errorsProp: 'automationErrors'
      //   })
      //   if (updated) {
      //     this.set('dirty', '')
      //     onCloseDialogAction()
      //   }
      // },
      async removeAutomation(automationModel, onCloseDialogAction) {
        const removed = await this.onRemove(automationModel);

        if (removed) {
          this.set('dirty', '');
          onCloseDialogAction();
        }
      },

      cancel(dirty, onCloseDialogAction) {
        this.cancelEditing({
          adapterName: this.adapterName,
          dirty
        });
        this.set('dirty', '');
        onCloseDialogAction();
      }

    }
  });

  _exports.default = _default;
});