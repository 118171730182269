define("client/pods/components/files-manager-row/finder/component", ["exports", "@glimmer/component", "ramda-adjunct", "ramda", "ember-concurrency-decorators"], function (_exports, _component, RA, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FilesManagerFinderComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class FilesManagerFinderComponent extends _component.default {
    // individual model in uploadsCollection._data.uploads
    // collection model that holds _data.uploads
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);

      _initializerDefineProperty(this, "loading", _descriptor4, this);

      _initializerDefineProperty(this, "selectedResource", _descriptor5, this);

      _initializerDefineProperty(this, "selectedTag", _descriptor6, this);

      _initializerDefineProperty(this, "tab", _descriptor7, this);

      _initializerDefineProperty(this, "filters", _descriptor8, this);

      _initializerDefineProperty(this, "selectedItem", _descriptor9, this);

      _initializerDefineProperty(this, "selectedItems", _descriptor10, this);

      _initializerDefineProperty(this, "selectedItemPrevious", _descriptor11, this);

      _defineProperty(this, "adapterName", 'uploads');

      _defineProperty(this, "collectionAdapterName", 'uploads/collection');

      _defineProperty(this, "filtersAdapterName", 'uploads/filters');

      _defineProperty(this, "finderLabel", this.args.finderLabel || 'files_manager_tags');

      const tab = R.path(['args', 'tab'])(this);

      if (tab) {
        this.tab = this.tabsManager.getActiveSubTab(tab);

        if (RA.isNilOrEmpty(this.tab)) {
          this.tab = this.args.tab;
        }
      }

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          excludeCopies: true,
          count: 80
        }
      });
      this.filters = filters;
    }

    get options() {
      const externalOptions = this?.args?.options || {};
      return R.mergeRight({
        isDefault: true
      }, externalOptions);
    }

    get filesBrowserFilter() {}

    get allowMultipleItems() {
      return R.pathOr(true, ['args', 'allowMultipleItems'])(this);
    }

    willDestroy() {
      if (this?.isDestroyed === false) {
        const subTabs = this?.tab?.subTabs || [];
        const firstSubTab = R.head(subTabs);
        const hasSubTab = RA.isNotNilOrEmpty(firstSubTab);
        const hasSubTabs = RA.isNotNilOrEmpty(subTabs);

        if (hasSubTabs && hasSubTab && firstSubTab.set) {
          this.tabsManager.closeTab(subTabs, firstSubTab);
        }
      }
    }

    *onSubmitTask(onCloseDialogAction, options, selectedItems) {
      const results = yield this.args.onSubmitTask.perform(onCloseDialogAction, options, selectedItems);
      this.resetSelectedItems();
      return results;
    }

    resetSelectedItems() {
      this.selectedItems = [];
    }

    onRemoveTask(files) {
      var _this = this;

      let {
        onAfter
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return function* () {
        const options = _this.options;
        const onCloseDialogAction = _this.args.onCloseDialogAction;
        const isDeleted = yield _this.args.onRemoveTask.perform(onCloseDialogAction, options, files);

        if (!isDeleted) {
          return;
        }

        const fileKeys = R.map(R.path(['_data', '_key']))(files);
        R.forEach(file => {
          const fileKey = R.path(['_data', '_key'])(file);

          if (R.includes(fileKey, fileKeys)) {
            file.set('isSelected', false);

            _this.selectedItems.removeObject(file);
          }
        })(files);

        if (onAfter) {
          try {
            yield onAfter.perform();
          } catch (error) {
            console.error('ERROR WHILE REMOVING ITEM', error);

            try {
              yield onAfter();
            } catch (error) {}
          }
        }

        return isDeleted;
      }();
    }

    setResource(resourceName) {
      this.display = false;
      this.selectedResource = resourceName;
      this.selectedTag = '';
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          excludeCopies: true,
          count: 80
        }
      });
      this.filters = filters;
      this.filters.setData('resource', resourceName);
      this.filters.setData('tagKey', '');
      this.filters.setData('query', '');
      const panelOptions = {
        component: 'files/files-browser',
        fetchUploadsOnInit: true,
        isModal: true
      };
      this.tabsManager.replaceSubTab(this.tab, panelOptions);
    }

    filterByTagKey(tag) {
      this.display = false;
      const panelOptions = {
        component: 'files/files-browser',
        fetchUploadsOnInit: true
      };
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          excludeCopies: true,
          count: 80
        }
      });
      this.filters = filters;
      this.filters.setData('tagKey', tag?._data?._key);
      this.filters.setData('query', ''); // this.selectedResource = ''

      this.selectedTag = tag?._data?._key;
      this.tabsManager.replaceSubTab(this.tab, panelOptions);
    }

    showResultsAfterSearch() {
      this.display = false;
      const panelOptions = {
        component: 'files/files-browser',
        fetchUploadsOnInit: false
      };
      this.filters.setData('tagKey', '');
      this.filters.setData('resource', '');
      this.selectedResource = '';
      this.selectedTag = '';
      this.tabsManager.replaceSubTab(this.tab, panelOptions);
    }

    onSelectItem(selected) {
      if (this.allowMultipleItems) {
        this.selectedItems.addObject(selected);
      } else {
        this.selectedItems.clear();
        this.selectedItems.addObject(selected);
      }
    }

    onDeselectItem(selected) {
      this.selectedItems.removeObject(selected);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedResource", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedTag", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tab", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedItem", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedItems", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectedItemPrevious", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmitTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetSelectedItems", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "resetSelectedItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRemoveTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onRemoveTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setResource", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setResource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterByTagKey", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "filterByTagKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showResultsAfterSearch", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "showResultsAfterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectItem", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDeselectItem", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onDeselectItem"), _class.prototype)), _class));
  _exports.default = FilesManagerFinderComponent;
});