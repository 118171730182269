define("client/pods/components/forms/grid-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    classNames: [],
    labelClassNames: [],
    label: '',
    input: '',
    error: '',
    // classNameBindings: ['error:has-error'],
    translate: true,
    customBlock: false
  });

  _exports.default = _default;
});