define("client/pods/components/elements/tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K/+imlhi",
    "block": "{\"symbols\":[\"@isRunning\",\"@onRemove\",\"@onSubmit\",\"@model\",\"@label\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"tag\"],[8],[0,\"\\n  \"],[7,\"span\",false],[12,\"class\",[29,[\"tag__label \",[28,\"if\",[[23,3,[]],\"tag__label--pointer\",\"\"],null]]]],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"submit\"]],[23,4,[]]],null]]],[8],[0,\"\\n    \"],[1,[23,5,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[5,\"elements/element-pushbtn\",[],[[\"@classNames\",\"@isRunning\",\"@onSubmit\"],[\"tag__remove-btn u-close-btn\",[23,1,[]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/tag/template.hbs"
    }
  });

  _exports.default = _default;
});