define("client/pods/components/elements/element-pushbtn/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    attributeBindings: ['tabindex', 'disabled'],
    tabindex: 0,
    classNames: ['element-pushbtn__component', 'u-btn'],
    classNameBindings: ['disabled'],
    submit: function (e) {
      if (this.disabled !== true) {
        if (this.onPreSubmit) {
          this.onPreSubmit(e, this);
        }

        var isToggler = this.isToggler;

        if (isToggler) {
          this.toggleProperty('toggleIsOn');
        }

        if (this.onSubmit) {
          this.onSubmit(e, this);
        }
      }
    },
    click: function (e) {
      this.submit(e);
    },
    keyUp: function (e) {
      if (e.which === 13) {
        this.submit(e);
      }
    } // click: function (e) {
    //
    //   /* jshint unused: false */
    //
    //   if (this.get('disabled') !== true) {
    //     //@TODO: convert all to the new action handler
    //     if (this.get('action')) {
    //       this.sendAction('action', this);
    //     } else {
    //       this.attrs.onPush(this);
    //     }
    //   }
    // },
    // keyUp: function (e) {
    //   if (this.get('disabled') !== true) {
    //     if (e.which === 13) {
    //       //@TODO: convert all to the new action handler
    //       if (this.get('action')) {
    //         this.sendAction('action', this);
    //       } else {
    //         this.attrs.onPush(this);
    //       }
    //     }
    //   }
    // }

  });

  _exports.default = _default;
});