define("client/pods/components/froala-editor/component", ["exports", "ember-froala-editor/components/froala-editor", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _froalaEditor, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FroalaEditor = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class FroalaEditor extends _froalaEditor.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stringifyContent", _descriptor2, this);

      _initializerDefineProperty(this, "editor", _descriptor3, this);

      _defineProperty(this, "locale", R.pipe(R.pathOr(['en_us'], ['intl', 'locale']), _nventor.default.safeHeadOr('en_us'), R.replace('-', '_'))(this));

      _defineProperty(this, "defaultOptions", {
        // width: '800',
        heightMin: 100,
        height: this.args.basicToolbar ? 100 : 600,
        attribution: false,
        // fontSizeDefaultSelection: '14', // doesnt work
        toolbarBottom: false,
        // toolbarInline: true,
        // toolbarVisibleWithoutSelection: true,
        fontFamilyDefaultSelection: 'Arial',
        fontSizeDefaultSelection: '14',
        fontSize: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '36', '48', '60', '72', '96'],
        // Enable or disable file upload.
        fileUpload: false,
        // Enable or disable image upload.
        imageUpload: false,
        // Enable or disable video upload.
        videoUpload: false,
        quickInsertEnabled: false,
        // iconsTemplate: 'font_awesome_5',
        language: this.locale,
        toolbarSticky: false,
        toolbarStickyOffset: 0,
        // when toolbarBottom is true, should be 40
        charCounterCount: false,
        toolbarButtons: this.setToolbarButtons(),
        imageDefaultWidth: 600,
        imageEditButtons: ['imageReplace', 'imageAlign', 'imageCaption', 'imageRemove', 'imageLink', '|', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'] // iframe: true,
        // iframeStyle: 'body{height:300px;overflow:auto;}'

      });

      _defineProperty(this, "externalOptions", this.args.options || {});

      _defineProperty(this, "options", R.mergeRight(this.defaultOptions, this.externalOptions));
    }

    /**
     * Event on Initialized
     * @link https://froala.com/wysiwyg-editor/docs/events#initialized
     * @param {FroalaEditor} editor - Editor Instance
     */
    'on-initialized'(editor) {
      this.editor = editor; // passing autofocus into options currently does not set autofocus.
      // temp fix

      this.toggleScrollListenerOnModal(editor, 'initialized');

      if (this.args.onInit) {
        this.args.onInit(this);
      }

      if (this.args?.options?.autofocus) {
        editor.el.focus();
      }
    }

    setToolbarButtons() {
      let toolbarButtons;

      if (this.args.basicToolbar) {
        toolbarButtons = {
          moreText: {
            // List of buttons used in the  group.
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
            // Alignment of the group in the toolbar.
            align: 'left',
            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more
            // button.
            buttonsVisible: 6
          },
          moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
            align: 'left',
            buttonsVisible: 4
          },
          moreRich: {
            buttons: ['insertLink'],
            align: 'left',
            buttonsVisible: 1
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'selectAll', 'html', 'help'],
            align: 'right',
            buttonsVisible: 2
          }
        };
      } else {
        toolbarButtons = {
          moreText: {
            // List of buttons used in the  group.
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
            // Alignment of the group in the toolbar.
            align: 'left',
            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more
            // button.
            buttonsVisible: 3
          },
          moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
            align: 'left',
            buttonsVisible: 3
          },
          moreRich: {
            buttons: ['insertLink', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly'],
            align: 'left',
            buttonsVisible: 3
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'print', 'selectAll', 'html', 'help'],
            align: 'right',
            buttonsVisible: 2
          }
        };
      }

      const hasFileBrowser = R.pathOr(false, ['hasFileBrowser'])(this.args);
      const hasVideoBrowser = R.pathOr(false, ['hasVideoBrowser'])(this.args);

      if (hasFileBrowser && !this.args.basicToolbar) {
        toolbarButtons = R.over(R.lensPath(['moreRich', 'buttons']), R.append('imageManager'))(toolbarButtons);
      }

      if (hasVideoBrowser && !this.args.basicToolbar) {
        toolbarButtons = R.over(R.lensPath(['moreRich', 'buttons']), R.append('insertVideo'))(toolbarButtons);
      }

      return toolbarButtons;
    }
    /**
     * Add or Remove event listener scroll, only when Froala is on a Modal
     * @param {FroalaEditor} editor - Editor Instance
     * @param {string} lifeCycle
     */


    toggleScrollListenerOnModal(editor, lifeCycle) {// let fnName = ''
      // const outerModal = editor?.$box?.closest('.modal-dialog__content')?.[0]
      // if (lifeCycle === 'initialized' && outerModal) {
      //   fnName = 'addEventListener'
      // } else if (lifeCycle === 'destroy' && outerModal) {
      //   fnName = 'removeEventListener'
      // } else {
      //   return null
      // }
      // outerModal[fnName]('scroll', () => editor.position.refresh())
    }

    showFileUploader() {
      // SAVE CURRENT CURSOR POSITION
      this.editor.selection.save();
      this.editor.popups.hideAll();
      let currentImage = this.editor.image?.get();

      if (RA.isNotNilOrEmpty(currentImage)) {
        currentImage = R.pipe(cImage => cImage.toArray(), R.head, cImage => {
          cImage.setAttribute('data-replace-id', _nventor.default.random.alpha(40));
          return cImage;
        })(currentImage);
      }

      this.args.showFileUploader(this, currentImage);
    }
    /**
     * Event on Destroy
     * @link https://froala.com/wysiwyg-editor/docs/events#destroy
     * @param {FroalaEditor} editor - Editor Instance
     */


    'on-destroy'(editor) {
      if (this?.isDestroyed === false) {
        this.toggleScrollListenerOnModal(editor, 'destroy');
      }
    }
    /**
     * Triggered after image was loaded in the Froala Rich Text Editor.
     * @link https://froala.com/wysiwyg-editor/docs/events#image.inserted
     * @param {FroalaEditor} editor - Editor Instance
     * @param {any} filesArray - Images array
     */


    'on-image-loaded'(editor, filesArray) {
      if (!editor) {
        // this is when images are loading and modal is closed too quickly
        return;
      }

      const file = R.path([0])(filesArray);

      if (file) {
        editor.selection.setAfter(file);
        const uuid = R.pathOr('', ['dataset', 'uuid'])(file);

        editor._uploaded.push(uuid);
      }
    }

    'on-file-inserted'(editor, filesArray) {
      if (!editor) {
        // this is when images are loading and modal is closed too quickly
        return;
      }

      const file = R.path([0])(filesArray);

      if (file) {
        editor.selection.setAfter(file);
        const uuid = R.pipe(R.propOr('', 'href'), R.replace('https://ucarecdn.com/', ''), R.replace('/', ''))(file);

        editor._uploaded.push(uuid);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stringifyContent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "editor", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, 'on-initialized', [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, 'on-initialized'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showFileUploader", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "showFileUploader"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'on-destroy', [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, 'on-destroy'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'on-image-loaded', [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, 'on-image-loaded'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'on-file-inserted', [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, 'on-file-inserted'), _class.prototype)), _class));
  _exports.default = FroalaEditor;
});