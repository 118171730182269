define("client/pods/components/grapesjs/preview-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lbmH7wZt",
    "block": "{\"symbols\":[\"&default\",\"&attrs\",\"@model\",\"@page\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[[28,\"if\",[[25,1],\"gjs-preview-grid\",\"\"],null]]]],[13,2],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"updateStyle\"]],[23,3,[]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"gjs-preview-palettes-list\"],[8],[0,\"\\n      \"],[14,1,[[28,\"hash\",null,[[\"updateStyle\"],[[23,0,[\"updateStyle\"]]]]]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"gjs-preview-container\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",[29,[\"panel__top panel__top-\",[23,0,[\"id\"]],\" gjs__center-devices \",[28,\"if\",[[23,0,[\"displayTopPanel\"]],\"editor-top-panel-display-initial\",\"editor-top-panel-display-none\"],null]]]],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"setup\"]],[23,4,[]]],null]]],[3,\"did-update\",[[28,\"fn\",[[23,0,[\"updateContent\"]],[23,4,[]]],null]]],[8],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"panel__devices panel__devices-\",[23,0,[\"id\"]]]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"gjs-preview gjs-preview-\",[23,0,[\"id\"]]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/grapesjs/preview-container/template.hbs"
    }
  });

  _exports.default = _default;
});