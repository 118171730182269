define("client/pods/extensions/apps/tw/shopee/lists/orders/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/utils/nventor", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtensionsAppsTwShopeeListsOrdersAdapter extends _model.default {}

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: ExtensionsAppsTwShopeeListsOrdersAdapter,
    idParam: false,

    findAll() {
      const data = [{
        value: '7',
        label: 'last 7 days'
      }, {
        value: '14',
        label: 'last 14 days'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});