define("client/pods/components/elements/print-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R+ZTic1G",
    "block": "{\"symbols\":[\"onPrintDialogAction\",\"&default\",\"@modalDialogTranslate\"],\"statements\":[[1,[28,\"elements/element-btn\",null,[[\"classNames\",\"iconClassName\",\"tabindex\",\"disabled\",\"icon\",\"prefix\",\"label\",\"tooltipLabel\",\"defaultLabel\",\"translate\",\"isToggler\",\"toggleOnIconClassName\",\"toggleOffIconClassName\",\"hasModalDialog\",\"modalDialogIsVisible\",\"onSubmit\"],[[24,[\"btnClassNames\"]],[24,[\"iconClassName\"]],[24,[\"tabindex\"]],[24,[\"disabled\"]],[24,[\"icon\"]],[24,[\"prefix\"]],[24,[\"label\"]],[24,[\"tooltipLabel\"]],[24,[\"defaultLabel\"]],[24,[\"translate\"]],[24,[\"isToggler\"]],[24,[\"toggleOnIconClassName\"]],[24,[\"toggleOffIconClassName\"]],true,[24,[\"modalDialogIsVisible\"]],[28,\"action\",[[23,0,[]],\"submit\"],null]]]],false],[0,\"\\n\\n\"],[5,\"modals/modal-dialog\",[],[[\"@title\",\"@translate\",\"@onClose\",\"@modalDialogIsVisible\"],[[23,0,[\"_modalDialogTitle\"]],[23,3,[]],[28,\"action\",[[23,0,[]],\"closeDialog\"],null],[22,\"modalDialogIsVisible\"]]],{\"statements\":[[0,\"\\n\"],[4,\"elements/print-content-container\",null,[[\"hasPrintBtn\",\"hasTotalDue\",\"showTotalDue\",\"onPrintDialog\",\"model\",\"onClose\",\"showCancelBtn\"],[[24,[\"hasPrintBtn\"]],[24,[\"hasTotalDue\"]],[24,[\"showTotalDue\"]],[28,\"action\",[[23,0,[]],\"onPrintDialog\"],null],[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"closeDialog\"],null],[24,[\"showCancelBtn\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"elements/print-content-container/print-content\",null,null,{\"statements\":[[0,\"      \"],[14,2,[[28,\"action\",[[23,0,[]],\"closeDialog\"],null],[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/print-btn/template.hbs"
    }
  });

  _exports.default = _default;
});