define("client/pods/components/contacts/panels-display/sub-panels-member-levels/adjust-reward-dialog/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactsPanelsDisplaySubPanelsMemberLevelsAdjustMemberLevelDialogComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._action, _dec21 = Ember._action, (_class = class ContactsPanelsDisplaySubPanelsMemberLevelsAdjustMemberLevelDialogComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dataManager", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "server", _descriptor3, this);

      _initializerDefineProperty(this, "constants", _descriptor4, this);

      _initializerDefineProperty(this, "date", _descriptor5, this);

      _initializerDefineProperty(this, "intl", _descriptor6, this);

      _initializerDefineProperty(this, "selectedMemberLevel", _descriptor7, this);

      _initializerDefineProperty(this, "adjustedMemberLevelPoints", _descriptor8, this);

      _initializerDefineProperty(this, "memberLevelExpiryDateZ", _descriptor9, this);

      _initializerDefineProperty(this, "points", _descriptor10, this);

      _initializerDefineProperty(this, "pointsExpiryTime", _descriptor11, this);

      _initializerDefineProperty(this, "pointsExpiryPeriod", _descriptor12, this);

      _initializerDefineProperty(this, "sendEmail", _descriptor13, this);

      _initializerDefineProperty(this, "rewardEvent", _descriptor14, this);

      _initializerDefineProperty(this, "rewardKey", _descriptor15, this);

      _initializerDefineProperty(this, "selectedRewardData", _descriptor16, this);

      _initializerDefineProperty(this, "memberLevelPointsToAdd", _descriptor17, this);

      _initializerDefineProperty(this, "useDefaultMessage", _descriptor18, this);

      _initializerDefineProperty(this, "isSetByMemberLevelPoints", _descriptor19, this);
    }

    get isDisabled() {
      const type = this.args.type;
      const adjustedMemberLevelPoints = this.adjustedMemberLevelPoints;
      const memberLevelExpiryDateZ = this.memberLevelExpiryDateZ;
      const currentMemberLevel = this.currentMemberLevel;
      const selectedMemberLevelId = this.selectedMemberLevelId;
      const isSetByMemberLevelPoints = this.isSetByMemberLevelPoints;
      const contactReward = this?.args?.contactReward || {};
      const rewardKey = this.rewardKey || '';
      const points = this.points || 0;
      const pointsExpiryTime = this.pointsExpiryTime;
      const pointsExpiryPeriod = this.pointsExpiryPeriod;

      if (type === 'member level') {
        if (!selectedMemberLevelId || !memberLevelExpiryDateZ) {
          return true;
        }

        if (!isSetByMemberLevelPoints && currentMemberLevel.memberLevelId === selectedMemberLevelId) {
          return true;
        }
      }

      if (type === 'points') {
        if (RA.isNilOrEmpty(contactReward) && !rewardKey) {
          return true;
        }

        if (Number(points) === 0 || Number(pointsExpiryTime) === 0) {
          return true;
        }
      }

      return false;
    }

    get memberLevelsList() {
      let memberLevels = this?.args?.contactReward?.rewardData?.memberLevels || []; // used when contact doesnt have contact reward data

      const selectedRewardData = this.selectedRewardData;

      if (RA.isNilOrEmpty(memberLevels) && RA.isNotNilOrEmpty(selectedRewardData)) {
        memberLevels = selectedRewardData?._data?.memberLevels || [];
      } // currently remove memberLevels with productTags


      memberLevels = R.reject(memberLevel => {
        const productTags = memberLevel?._data?.productTags || [];

        if (RA.isNotNilOrEmpty(productTags)) {
          return true;
        }

        return false;
      })(memberLevels);
      const memberLevelsModel = R.map(memberLevel => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/rewards/member-levels',
          data: memberLevel
        });
      })(memberLevels);
      return memberLevelsModel;
    }

    get rewardTypes() {
      const rewardTypesList = this.crud.lists['channels-shop-rewards-types'] || [];
      const rewardTypesListClone = R.concat(rewardTypesList, []);

      if (RA.isNotNilOrEmpty(rewardTypesListClone)) {
        return R.pipe(R.filter(rewardType => {
          const type = R.pathOr('', ['_data', 'value'])(rewardType);
          return R.includes(type, [this.constants.rewardEvents.birthday, // this.constants.rewardEvents.promotion,
          // this.constants.rewardEvents.opening,
          this.constants.rewardEvents.pointsAdjustment]);
        }), R.sortWith([R.descend(R.path(['_data', 'value']))]))(rewardTypesListClone);
      }

      return [];
    }

    get currentMemberLevel() {
      let currentMemberLevel = this?.args?.contactReward?.level || {};
      const selectedRewardData = this.selectedRewardData;

      if (RA.isNilOrEmpty(currentMemberLevel) && RA.isNotNilOrEmpty(selectedRewardData)) {
        const rewardData = selectedRewardData.serialize();
        currentMemberLevel = R.pipe(R.propOr([], 'memberLevels'), R.find(memberLevel => {
          if (Number(memberLevel.requiredPoints) === 0) {
            return true;
          }
        }))(rewardData);
      }

      return currentMemberLevel;
    }

    get selectedMemberLevelRequiredPoints() {
      const selectedMemberLevel = this.selectedMemberLevel || {};

      if (RA.isNotNilOrEmpty(selectedMemberLevel)) {
        return selectedMemberLevel.requiredPoints || 0;
      }

      return 0;
    }

    get addedMemberLevelPoints() {
      const currentMemberLevel = this.currentMemberLevel || {};
      const currentMemberLevelPoints = currentMemberLevel.points || 0;
      const memberLevelPointsToAdd = this.memberLevelPointsToAdd || 0;
      return R.add(currentMemberLevelPoints, memberLevelPointsToAdd);
    }

    get activeRewardsList() {
      const rewards = this.crud.lists['channels-shop-rewards'] || [];
      return R.filter(R.pathEq(['_data', 'status'], 'active'))(rewards) || [];
    }

    *sendRewardTask(onCloseDialogAction) {
      const type = this.args.type;
      const contactReward = this?.args?.contactReward || {};
      const contactModel = this?.args?.contactModel || {};
      const rewardData = contactReward.rewardData || this.selectedRewardData.serialize() || {};
      const level = this.currentMemberLevel;
      const contactKey = contactModel.getData('_key');
      const memberId = contactModel.getData('memberId');
      const rewardKey = R.propOr('', '_key')(rewardData);
      const rewardName = R.propOr('', 'name')(rewardData);
      const sendEmail = this.sendEmail;
      const useDefaultMessage = this.useDefaultMessage;
      const adjustmentEmailSubject = this.adjustmentEmailSubject || '';
      const adjustmentEmailMessage = this.adjustmentEmailMessage || '';

      if (type === 'member level') {
        const selectedMemberLevelId = this.selectedMemberLevelId;
        const adjustedMemberLevelPoints = this.adjustedMemberLevelPoints || 0;
        const memberLevelExpiryDateZ = this.memberLevelExpiryDateZ;
        const startDateZ = this.date.getToday().subtract(1, 'days').toISOString();
        const expireDateZ = this.date.getEndOfDay().subtract(1, 'days').toISOString();
        let memberLevelPoints = this.selectedMemberLevelRequiredPoints;

        if (this.isSetByMemberLevelPoints) {
          memberLevelPoints = this.addedMemberLevelPoints;
        }

        const payload = {
          memberLevelData: {
            contactKey,
            rewardKey,
            memberLevelId: selectedMemberLevelId,
            levelExpiryDateZ: memberLevelExpiryDateZ,
            timestampZ_active: this.date.getStartOfDayZ(),
            points: memberLevelPoints
          },
          contactRewardData: {
            contactKey,
            memberId,
            rewardKey,
            method: 'earn',
            name: rewardName,
            type: 'memberLevelAdjustment',
            points: adjustedMemberLevelPoints,
            startDateZ,
            expireDateZ,
            dateZ: startDateZ,
            isCalculatedForMemberLevel: true,
            isNeverExpire: false
          },
          sendEmail,
          useDefaultMessage,
          adjustmentEmailSubject,
          adjustmentEmailMessage
        };
        const response = yield this.server.call('POST', 'api/protected/contacts/rewards/adjust/member-level', payload);

        if (response.data) {
          onCloseDialogAction();
          window.alert(this.intl.t('sent_member_level_adjustment'));
        }
      }

      if (type === 'points') {
        const rewardEvent = this.rewardEvent;
        const points = this.points || 0;
        const pointsExpiryTime = this.pointsExpiryTime || 1;
        const pointsExpiryPeriod = this.pointsExpiryPeriod || 'days';
        const payload = {
          contactRewardData: {
            contactKey,
            memberId,
            rewardKey,
            method: 'earn',
            name: rewardName,
            type: rewardEvent,
            points,
            startDateZ: this.date.getStartOfDayZ(),
            expireDateZ: this.date.getEndOfDay().add(pointsExpiryTime, pointsExpiryPeriod).toISOString(),
            dateZ: this.date.getStartOfDayZ(),
            isCalculatedForMemberLevel: false,
            isNeverExpire: false
          },
          sendEmail,
          useDefaultMessage,
          adjustmentEmailSubject,
          adjustmentEmailMessage
        }; // when contact doenst have contact reward data, need to set member level

        if (RA.isNilOrEmpty(contactReward)) {
          payload.memberLevelData = {
            contactKey,
            rewardKey,
            memberLevelId: level.memberLevelId,
            levelExpiryDateZ: this.memberLevelExpiryDateZ,
            points: 0
          };
        }

        const response = yield this.server.call('POST', 'api/protected/contacts/rewards/adjust/points', payload);

        if (response.data) {
          onCloseDialogAction();
          window.alert(this.intl.t('sent_valid_points_adjustment'));
        }
      }
    }

    onChangeMemberLevel(selectedItem) {
      let selectedMemberLevel = {};
      let adjustedMemberLevelPoints = 0;
      const currentMemberLevel = this.currentMemberLevel;
      const addedMemberLevelPoints = this.addedMemberLevelPoints;

      if (this.isSetByMemberLevelPoints) {
        const contactReward = this?.args?.contactReward || {};
        const rewardData = contactReward.rewardData || this.selectedRewardData.serialize() || {};
        selectedMemberLevel = R.pipe(R.propOr([], 'memberLevels'), R.sortWith([R.descend(R.prop('requiredPoints'))]), R.find(memberLevel => {
          return R.lte(memberLevel.requiredPoints)(addedMemberLevelPoints);
        }))(rewardData) || {};
        adjustedMemberLevelPoints = Number(selectedItem); // memberLevelPoints before added with current points

        Ember.set(this, 'selectedMemberLevelId', selectedMemberLevel.memberLevelId);
      } else {
        selectedMemberLevel = selectedItem?._data || {};
        const currentMemberLevelPoints = currentMemberLevel.points || 0;
        const selectedMemberLevelRequiredPoints = selectedMemberLevel.requiredPoints || 0;
        const memberLevelPointsDifference = Number(selectedMemberLevelRequiredPoints) - Number(currentMemberLevelPoints) || 0;
        adjustedMemberLevelPoints = memberLevelPointsDifference;
      }

      Ember.set(this, 'selectedMemberLevel', selectedMemberLevel);
      const expiryTime = R.propOr(1, 'expiryTime')(selectedMemberLevel);
      const expiryPeriod = R.propOr('years', 'expiryPeriod')(selectedMemberLevel);
      const memberLevelExpiryDateZ = this.date.getEndOfDay().add(expiryTime, expiryPeriod).toISOString();
      Ember.set(this, 'memberLevelExpiryDateZ', memberLevelExpiryDateZ);
      Ember.set(this, 'memberLevelExpiryDate', this.date.formatDate(memberLevelExpiryDateZ, 'YYYY-MM-DD'));
      Ember.set(this, 'adjustedMemberLevelPoints', adjustedMemberLevelPoints);
    }

    onSelectRewards(selectedItem) {
      this.selectedRewardData = selectedItem;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedMemberLevel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "adjustedMemberLevelPoints", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "memberLevelExpiryDateZ", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "points", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "pointsExpiryTime", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "pointsExpiryPeriod", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.constants.timePeriods.months;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "sendEmail", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "rewardEvent", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'pointsAdjustment';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "rewardKey", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "selectedRewardData", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "memberLevelPointsToAdd", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "useDefaultMessage", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "isSetByMemberLevelPoints", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sendRewardTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "sendRewardTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeMemberLevel", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeMemberLevel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectRewards", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectRewards"), _class.prototype)), _class));
  _exports.default = ContactsPanelsDisplaySubPanelsMemberLevelsAdjustMemberLevelDialogComponent;
});