define("client/mixins/statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    unknownStatusClass: 'is-danger',

    addStatusClasses(param, key) {
      this.set(`statusClasses.${param}`, key);
    },

    statusClass: Ember.computed('_data.status', function () {
      const status = this.get('_data.status');

      if (this.productsService) {
        return this.productsService.getStatusClass(status);
      }

      return '';
    }),
    calculatedStatusClass: Ember.computed('_data.calculatedStatusBeforeCart', function () {
      const calculatedStatusBeforeCart = this?._data?.calculatedStatusBeforeCart || '';

      if (this.productsService) {
        return this.productsService.getStatusClass(calculatedStatusBeforeCart);
      }

      return '';
    }),
    isActive: Ember.computed('_data.status', function () {
      const status = this.get('_data.status');

      if (status === this.get('constants.status.active')) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});