define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditor = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditor extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "date", _descriptor2, this);

      _initializerDefineProperty(this, "search", _descriptor3, this);

      _initializerDefineProperty(this, "server", _descriptor4, this);

      _initializerDefineProperty(this, "guests", _descriptor5, this);

      _initializerDefineProperty(this, "dataManager", _descriptor6, this);

      _initializerDefineProperty(this, "big", _descriptor7, this);

      _initializerDefineProperty(this, "commissionBaseCostModel", _descriptor8, this);

      _initializerDefineProperty(this, "original", _descriptor9, this);

      _initializerDefineProperty(this, "campaignsDetails", _descriptor10, this);

      _initializerDefineProperty(this, "isMethodSelectorDisabled", _descriptor11, this);

      _initializerDefineProperty(this, "commissionBaseCostMethodModel", _descriptor12, this);

      _initializerDefineProperty(this, "isNew", _descriptor13, this);

      _initializerDefineProperty(this, "isEditing", _descriptor14, this);

      _initializerDefineProperty(this, "salesPersonKey", _descriptor15, this);

      _initializerDefineProperty(this, "showResults", _descriptor16, this);

      this.crud.addLists(this, ['channels/shop/campaigns/commissions-date-ranges', 'channels/shop/campaigns/lists/commission-types']);
      const model = this.args.model;
      const salesPersons = model.getData('salesPersons') || [];

      const salesPerson = _nventor.default.safeHead(salesPersons);

      const salesPersonKey = R.pathOr('', ['_data', '_key'], salesPerson);
      this.salesPersonKey = salesPersonKey;
    }

    *setupCommissionBaseCostMethodTask() {
      const model = this.args.model;
      const campaignKey = model.getData('_key');
      const campaignName = model.getData('name');
      const salesPersons = model.getData('salesPersons') || [];

      const salesPerson = _nventor.default.safeHead(salesPersons);

      const salesPersonKey = R.pathOr(true, ['_data', '_key'], salesPerson);
      const salesPersonName = R.pathOr(true, ['_data', 'name'], salesPerson);
      const result = yield this.crud.findTask.perform({
        adapterName: 'channels/shop/campaigns/commission-base-cost-method',
        appendPath: `/${campaignKey}/${salesPersonKey}`
      });
      const resultDataCampaignKey = result?._data?.campaignKey || '';

      if (RA.isNilOrEmpty(resultDataCampaignKey)) {
        this.isNew = true;
        this.isEditing = false;
        const data = {
          campaignKey,
          campaignName,
          salesPersonKey,
          salesPersonName,
          selectedCommissionBaseCostMethod: R.pathOr('normalPercent', ['_data', 'selectedCommissionBaseCostMethod'], model),
          isUsingAverage: R.pathOr(true, ['_data', 'isCommissionsUsingAverage'], model),
          isCalculatedByExclAmt: R.pathOr(true, ['_data', 'isCommissionsCalculatedByExclAmt'], model),
          isUsingSetChild: R.pathOr(false, ['_data', 'isCommissionsUsingSetChild'], model),
          hasAdjustedShippingFee: R.pathOr(false, ['_data', 'isCommissionsHasAdjustedShippingFee'], model)
        };
        const dirty = this.dataManager.newRecord({
          adapterName: 'channels/shop/campaigns/commission-base-cost-method',
          data
        });
        this.commissionBaseCostMethodModel = dirty;
      } else {
        this.isEditing = true;
        this.isNew = false;
        const dirty = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/commission-base-cost-method',
          model: result
        });
        this.isMethodSelectorDisabled = true;
        this.commissionBaseCostMethodModel = dirty;
        yield this.fetchPresetCommissionsTask.perform();
      }
    }

    *fetchPresetCommissionsTask() {
      const commissionBaseCostMethodModel = this.commissionBaseCostMethodModel;
      const isUsingSetChild = commissionBaseCostMethodModel.getData('isUsingSetChild');
      const isUsingAverage = commissionBaseCostMethodModel.getData('isUsingAverage');
      this.args.commissionBaseCostFilters.setData('isUsingSetChild', isUsingSetChild);
      let results = [];

      if (isUsingAverage) {
        const model = this.args.model;
        const master = model.getData('_key');
        results = yield this.crud.searchRecordsTask.perform({
          adapterName: 'channels/shop/campaigns/details/preset-commissions',
          appendPath: `/master/${master}`,
          filters: this.args.commissionBaseCostFilters,
          resultsProperty: 'results'
        });
      } else {
        results = yield this.crud.searchRecordsTask.perform({
          adapterName: 'channels/shop/campaigns/details',
          filters: this.args.commissionBaseCostFilters,
          resultsProperty: 'results'
        });
      }

      this.createDirtyDetails({
        filters: this.args.commissionBaseCostFilters
      });
    }

    *saveSelectedCommissionBaseCostMethodTask() {
      const commissionBaseCostMethodModel = this.commissionBaseCostMethodModel;
      const isNew = this.isNew;

      if (isNew) {
        yield this.crud.createRecordTask.perform({
          adapterName: 'channels/shop/campaigns/commission-base-cost-method',
          model: commissionBaseCostMethodModel
        });
      } else {
        yield this.crud.updateRecordTask.perform({
          adapterName: 'channels/shop/campaigns/commission-base-cost-method',
          model: commissionBaseCostMethodModel
        });
      }

      yield this.setupCommissionBaseCostMethodTask.perform();
    }

    createDirtyDetails(_ref) {
      let {
        filters
      } = _ref;
      const results = filters.get('results') || [];
      const dirtyResults = R.map(model => {
        const commissionBaseCostNew = R.propOr({}, 'commissionBaseCostNew', model);

        const _key = R.pathOr('', ['_data', '_key'], commissionBaseCostNew);

        let isNew = false;

        if (RA.isNilOrEmpty(_key)) {
          isNew = true;
        }

        const dirty = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/details/commission-base-cost',
          model: commissionBaseCostNew,
          isNew
        });
        const campaignModel = this.args.model;
        const campaignName = campaignModel.getData('name');
        const salesPersons = campaignModel.getData('salesPersons') || [];

        const salesPerson = _nventor.default.safeHead(salesPersons);

        const salesPersonKey = R.pathOr(true, ['_data', '_key'], salesPerson);
        const salesPersonName = R.pathOr(true, ['_data', 'name'], salesPerson);
        dirty.setData('campaignName', campaignName);
        dirty.setData('salesPersonKey', salesPersonKey);
        dirty.setData('salesPersonName', salesPersonName);
        model.set('commissionBaseCostNew', dirty);
        return model;
      })(results);
      filters.set('results', dirtyResults);
      this.showResults = true;
    }

    *resetPresetCommissionsTask() {
      const commissionBaseCostMethodModel = this.commissionBaseCostMethodModel;
      const model = this.args.model;
      const campaignKey = model.getData('_key');
      const salesPersons = model.getData('salesPersons') || [];

      const salesPerson = _nventor.default.safeHead(salesPersons);

      const salesPersonKey = R.pathOr('', ['_data', '_key'], salesPerson);
      const data = {
        campaignKey,
        salesPersonKey,
        isForCommission: true,
        isUsingAverage: model.getData('isCommissionsUsingAverage'),
        isCalculatedByExclAmt: model.getData('isCommissionsCalculatedByExclAmt'),
        isUsingSetChild: model.getData('isCommissionsUsingSetChild'),
        hasAdjustedShippingFee: model.getData('isCommissionsHasAdjustedShippingFee'),
        isReset: true,
        page: 1,
        count: 1000
      };
      yield this.server.call('PATCH', 'api/protected/channels/shop/campaigns/commission-base-cost/reset', data);
      this.isMethodSelectorDisabled = false;
      this.showResults = false;
      const commissionBaseCostFilters = this?.args?.commissionBaseCostFilters || {};

      if (RA.isNotNilOrEmpty(commissionBaseCostFilters)) {
        commissionBaseCostFilters.set('results', []);
        commissionBaseCostFilters.setData('page', 1);
      }
    }

    cancelAllModels(onCloseDialogAction) {
      if (this?.isDestroyed === false) {
        const commissionBaseCostMethodModel = this.commissionBaseCostMethodModel || {};
        const campaignsDetails = this.campaignsDetails || [];
        this.crud.cancelEditing({
          adapterName: 'channels/shop/campaigns/commission-base-cost-method',
          dirty: commissionBaseCostMethodModel
        });
        R.forEach(model => {
          this.crud.cancelEditing({
            adapterName: 'channels/shop/campaigns/details/commission-base-cost',
            dirty: model.commissionBaseCostNew
          });
        })(campaignsDetails);

        if (onCloseDialogAction) {
          onCloseDialogAction();
        }
      }
    }

    saveCommissionBaseCostNewModelTask(_ref2) {
      var _this = this;

      let {
        model,
        campaignsDetailsModel,
        selectedCommissionBaseCostMethod,
        isUsingAverage
      } = _ref2;
      return function* () {
        const results = _this?.args?.commissionBaseCostFilters?.results || [];
        const dirtyIndex = results.indexOf(campaignsDetailsModel);
        const adapterName = 'channels/shop/campaigns/details/commission-base-cost';
        model.setData('selectedCommissionBaseCostMethod', selectedCommissionBaseCostMethod);

        if (R.equals(selectedCommissionBaseCostMethod, 'normalPercent')) {
          model.setData('twPercent', '');
          model.setData('byBaseCost', '');
          model.setData('byAmount', '');
        }

        if (R.equals(selectedCommissionBaseCostMethod, 'twPercent')) {
          model.setData('normalPercent', '');
          model.setData('byBaseCost', '');
          model.setData('byAmount', '');
        }

        if (R.equals(selectedCommissionBaseCostMethod, 'byBaseCost')) {
          model.setData('normalPercent', '');
          model.setData('twPercent', '');
          model.setData('byAmount', '');
        }

        if (R.equals(selectedCommissionBaseCostMethod, 'byAmount')) {
          model.setData('normalPercent', '');
          model.setData('twPercent', '');
          model.setData('byBaseCost', '');
        }

        if (isUsingAverage) {
          model.setData('isUsingAverage', true);
        }

        const _key = R.pathOr('', ['_data', '_key'], model);

        let savedModel;

        if (RA.isNilOrEmpty(_key)) {
          savedModel = yield _this.crud.queueCreateRecordTask.perform({
            adapterName,
            model,
            component: _this
          });
        } else {
          savedModel = yield _this.crud.queueUpdateRecordTask.perform({
            adapterName,
            model,
            component: _this
          });
        }

        const dirty = _this.createDirtyDetail({
          savedModel,
          campaignsDetailsModel,
          isUsingAverage
        });

        dirty.set('wasSaved', true);
        setTimeout(() => {
          if (dirty && !dirty.isDestroyed) {
            Ember.set(dirty, 'wasSaved', false);
          }
        }, 5000);
        results.replace(dirtyIndex, 1, RA.list(dirty));
      }();
    }

    createDirtyDetail(_ref3) {
      let {
        savedModel,
        campaignsDetailsModel,
        isUsingAverage
      } = _ref3;
      Ember.set(campaignsDetailsModel, '_data.commissionBaseCostNew', R.propOr({}, '_data', savedModel));
      let newModel;

      if (isUsingAverage) {
        newModel = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/details/preset-commissions',
          model: campaignsDetailsModel
        });
      } else {
        newModel = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns/details',
          model: campaignsDetailsModel
        });
      }

      const commissionBaseCostNew = R.propOr({}, 'commissionBaseCostNew', newModel);
      const dirty = this.crud.setupDirty({
        adapterName: 'channels/shop/campaigns/details/commission-base-cost',
        model: commissionBaseCostNew,
        isNew: true
      });
      newModel.commissionBaseCostNew = dirty;
      return newModel;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "guests", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "big", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "commissionBaseCostModel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "original", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "campaignsDetails", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isMethodSelectorDisabled", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "commissionBaseCostMethodModel", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isNew", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isEditing", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "salesPersonKey", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "showResults", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupCommissionBaseCostMethodTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupCommissionBaseCostMethodTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchPresetCommissionsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchPresetCommissionsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSelectedCommissionBaseCostMethodTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveSelectedCommissionBaseCostMethodTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createDirtyDetails", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "createDirtyDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetPresetCommissionsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "resetPresetCommissionsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelAllModels", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "cancelAllModels"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveCommissionBaseCostNewModelTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveCommissionBaseCostNewModelTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createDirtyDetail", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "createDirtyDetail"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionsAdjustmentEditor;
});