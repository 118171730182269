define("client/pods/flows/service", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),

    getFlowButtonData(_ref) {
      let {
        shop,
        eventType
      } = _ref;
      return R.pipe(R.pathOr([], ['_data', 'flows']), R.find(R.propEq('eventType', eventType)))(shop);
    }

  });

  _exports.default = _default;
});