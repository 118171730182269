define("client/pods/components/menus/nav-tree/nav-item/component", ["exports", "ramda-adjunct", "ember-concurrency"], function (_exports, RA, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    dragAndDrop: Ember.inject.service(),
    // tagName: 'div',
    // classNames: ['nav-tree__item'],
    translate: true,
    item: null,
    labelKey: 'label',
    label: Ember.computed('item', 'labelKey', 'isPinnedMenu', function () {
      const isPinnedMenu = this.isPinnedMenu || '';

      if (isPinnedMenu) {
        return this.item?._data?.pinLabel;
      }

      return this.item?._data[this.labelKey];
    }),
    draggable: Ember.computed('item', function () {
      const _key = this.item.getData('_key') || '';

      if (RA.isNilOrEmpty(_key)) {
        return false;
      }

      return true;
    }),
    _translate: Ember.computed('item._data.translate', function () {
      if (this?.item?._data?.translate === false) {
        return false;
      }

      return this.translate;
    }),

    onClick() {
      const item = this.item;
      const pinLabel = item.getData('pinLabel');

      if (!R.equals(pinLabel, 'favorites')) {
        this.onSelect(item);
      }
    },

    onDrop(droppedAt, results) {
      let indexDotPath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '_data.index';
      let outerDrop = arguments.length > 3 ? arguments[3] : undefined;
      let draggedFrom = arguments.length > 4 ? arguments[4] : undefined;
      this.dragAndDrop.onDropWithIndexOld(droppedAt, results, indexDotPath = '_data.index', outerDrop, draggedFrom);
      this.onUpdateMenuIndexTask.perform({
        resetIndex: false
      });
    },

    pinTask: (0, _emberConcurrency.task)(function* (pinItem) {
      yield this.onPin(pinItem);
    })
  }, (_applyDecoratedDescriptor(_obj, "onClick", [_dec], Object.getOwnPropertyDescriptor(_obj, "onClick"), _obj), _applyDecoratedDescriptor(_obj, "onDrop", [_dec2], Object.getOwnPropertyDescriptor(_obj, "onDrop"), _obj)), _obj)));

  _exports.default = _default;
});