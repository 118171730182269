define("client/helpers/ifZero", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params
    /* , hash */
    ) {
      const value = params[0];
      const defaultValue = params[1];

      if (R.isEmpty(value) || value === 0 || value === '0') {
        return defaultValue;
      }

      return value;
    }

  });

  _exports.default = _default;
});