define("client/pods/components/reports/commissions/panels-all/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension"], function (_exports, _component, _emberConcurrencyDecorators, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ReportsCommissionsPanelsAll = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class ReportsCommissionsPanelsAll extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "date", _descriptor2, this);

      _initializerDefineProperty(this, "server", _descriptor3, this);

      _initializerDefineProperty(this, "search", _descriptor4, this);

      _initializerDefineProperty(this, "dataManager", _descriptor5, this);

      _initializerDefineProperty(this, "reportsSummary", _descriptor6, this);

      _initializerDefineProperty(this, "bySalesPersonsFilters", _descriptor7, this);

      _initializerDefineProperty(this, "reloadSummaryResource", _descriptor8, this);

      _initializerDefineProperty(this, "yyyy", _descriptor9, this);

      _initializerDefineProperty(this, "mm", _descriptor10, this);

      _defineProperty(this, "adapterName", 'reports/commissions/data');

      _defineProperty(this, "filtersAdapterName", 'reports/commissions/data/filters');

      this.crud.addLists(this, ['periods/months', 'periods/years', 'commissions/lists/panels']);
      const bySalesPersonsFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      }); // const salesPersons = R.pipe(
      //   R_.dotPathOr([], 'args.salesPersons'),
      //   R.map(user => {
      //     return {
      //       name: user?._data?.name || '',
      //       key: user?._data?._key || '',
      //       username: user?._data?.username || ''
      //     }
      //   })
      // )(this)
      // bySalesPersonsFilters.setData('salesPersons', salesPersons)

      this.bySalesPersonsFilters = bySalesPersonsFilters;
    }

    afterLoadLists() {
      this.getMonthAndYear();
      this.setup();
      this.reloadSummaryResource = R.pipe(R.pathOr([], ['crud', 'lists', 'commissions-lists-panels']), R.find(R.pathEq(['_data', 'value'], 'show all')))(this);
    }

    setup() {
      this.fetchBySalesPersonsSummary.perform();
      this.fetchReportSummaryTask.perform();
    }

    getMonthAndYear() {
      const yyyy = this?.args?.yyyy || {};
      const mm = this?.args?.mm || [];
      let startOfMonth = '';

      if (yyyy.value && mm.value) {
        startOfMonth = `${yyyy.value}-${mm.value}-01`;
      }

      const period = this.date.getPeriod(startOfMonth);
      this.yyyy = {
        value: period.yyyy,
        label: period.yyyy
      };
      this.mm = {
        value: period.mm,
        label: R.pipe(R_.dotPathOr([], 'crud.lists.periods-months'), R.find(lists => R_.dotPathEq('_data.value', period.mm, lists)), R_.dotPathOr('', '_data.label'))(this)
      };
    }

    *fetchBySalesPersonsSummary() {
      const bySalesPersonsFilters = this.bySalesPersonsFilters;
      bySalesPersonsFilters.setData('query', '');
      bySalesPersonsFilters.setData('mm', this.mm.value);
      bySalesPersonsFilters.setData('yyyy', this.yyyy.value);
      bySalesPersonsFilters.setData('isDispatched', true);
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        appendPath: '/by-sales-persons',
        filters: bySalesPersonsFilters
      });
      this.bySalesPersonsFilters = bySalesPersonsFilters;
    }

    *fetchReportSummaryTask() {
      const yyyy = this.yyyy;
      const mm = this.mm;
      const data = {
        yyyy: yyyy.value,
        mm: mm.value,
        salesPersons: [],
        segments: [],
        sort: [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]
      };
      const summaryFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: data,
        resultsProperty: 'results'
      });
      const summary = yield this.crud.findTask.perform({
        adapterName: this.adapterName,
        appendPath: '/summary',
        filters: summaryFilters
      });
      this.reportsSummary = summary;
    }

    generateSummaryAndTable() {
      this.setup();
    }

    displaySalesPerson(summaryModel) {
      const salesPersons = this.args.salesPersons || [];
      const summaryModelSalesPersonKey = R_.dotPathOr('', '_data.salesPersonKey', summaryModel);
      const model = R.find(data => R_.dotPathEq('_data._key', summaryModelSalesPersonKey, data))(salesPersons);
      this.args.onDisplayWithDate(model, this.yyyy, this.mm);
    }

    reloadAfterPayout() {
      const reloadSummaryResource = this.reloadSummaryResource;
      this.args.onDisplayPanelWithDate(reloadSummaryResource, this.yyyy, this.mm);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "reportsSummary", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "bySalesPersonsFilters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "reloadSummaryResource", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "yyyy", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "mm", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchBySalesPersonsSummary", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchBySalesPersonsSummary"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchReportSummaryTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchReportSummaryTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateSummaryAndTable", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "generateSummaryAndTable"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displaySalesPerson", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "displaySalesPerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadAfterPayout", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "reloadAfterPayout"), _class.prototype)), _class));
  _exports.default = ReportsCommissionsPanelsAll;
});