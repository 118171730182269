define("client/pods/ajax/service", ["exports", "client/utils/nventor", "client/config/environment", "jquery"], function (_exports, _nventor, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { isArray } from '@ember/array'
  var _default = Ember.Service.extend({
    guests: Ember.inject.service(),
    auth: Ember.inject.service(),
    storage: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    server: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    settings: Ember.inject.service(),
    requestId: '',

    GET(url, data, token) {
      let hasToken = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      return this._ajax('GET', url, {
        data,
        token,
        hasToken
      });
    },

    POST(url, data, token) {
      let hasToken = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      data = JSON.stringify(data);
      const config = {
        // contentType: 'application/vnd.bigordr.v1+json; charset=utf-8'
        contentType: 'application/json; charset=utf-8'
      };
      return this._ajax('POST', url, {
        data,
        config,
        token,
        hasToken
      });
    },

    PUT(url, data, token) {
      let hasToken = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      data = JSON.stringify(data);
      const config = {
        contentType: 'application/json; charset=utf-8'
      };
      return this._ajax('PUT', url, {
        data,
        config,
        token,
        hasToken
      });
    },

    PATCH(url, data, token) {
      let hasToken = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      data = JSON.stringify(data);
      const config = {
        contentType: 'application/json; charset=utf-8'
      };
      return this._ajax('PATCH', url, {
        data,
        config,
        token,
        hasToken
      });
    },

    DELETE(url, data, token) {
      let hasToken = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      const key = _nventor.default.confirm.get(data, '_key');

      if (key) {
        url = url + '/' + key;
      } // always stringify data when sending to Server


      data = JSON.stringify(data);
      const config = {
        contentType: 'application/json; charset=utf-8'
      };
      return this._ajax('DELETE', url, {
        data,
        config,
        token,
        hasToken
      });
    },

    _ajax(method, url) {
      let {
        data,
        config,
        token = false,
        hasToken = true
      } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      const requestId = _nventor.default.random.alphaNum(32);

      this.set('requestId', requestId); // jshint unused: false

      const self = this;
      token = token || self.get('storage').get('token');
      token = token || self.get('guests').getToken();
      const locale = this.intl.get('locale')[0] || 'zh_TW';

      if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {
        console.log('>>>>>>>>AJAX CALL>>>>>>>');
        console.log('path: ' + url);
        console.log('method:' + method);
        console.log('data:');
        console.log(typeof data);
        console.log(data);
        console.log('token');
        console.log(token);
        console.log('----------');
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        config = config || {};
        config.timeout = 0;
        config.async = true;
        config.type = method;
        config.url = url;
        config.data = data;

        config.beforeSend = function (request, options) {
          // @NOTE: cannot test
          // console.log('check for token');
          // console.log(self.attr.token);
          // console.log('---SENDING TOKEN---');
          // console.log(token);
          request.setRequestHeader('Accept-Language', locale);
          request.setRequestHeader('x-request-id', requestId);

          if (token && hasToken) {
            request.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        };

        return _jquery.default.ajax(config).done((response, textStatus, res) => {
          // @TODO: start process and other things here
          // console.log('*******Response*******')
          // console.log(response)
          // console.log('......................')
          if (_environment.default.environment === 'development' || _environment.default.environment === 'development-online') {
            console.log('====response====');
            console.log(response);
          } // if (response.autoExtendedToken) {
          //   const autoExtendedToken = response.autoExtendedToken
          //   this.auth.setAutoExtendedToken(autoExtendedToken)
          //   delete response.autoExtendedToken
          // }


          return resolve(response); // return response;
        }).fail((jqXHR, textStatus, errorThrown) => {
          const httpStatus = jqXHR.status;

          if (httpStatus === 0) {
            const error = new Error();
            error.message = 'not connected';
            error.data = 'not connected';
            return reject(error);
          }

          const err = jqXHR.responseJSON; // console.log('*******ERROR Response*******');
          // console.log(err);
          // // console.log(err.message);
          // // console.log(err.data);
          // console.log('......................');

          const msg = _nventor.default.confirm.get(err, 'message', 'error occurred');

          const errData = _nventor.default.confirm.get(err, 'data', 'error occurred');

          const errorMessage = err?.data?.error;

          if (errorMessage) {
            window.alert(errorMessage);
          } // console.log('========error details=======');
          // console.log(details);
          // console.log('>>>>>>>>>>>>>>>>>>>>>>');
          // reject(nventor.error(msg, details));
          // reject(details);


          const error = new Error();
          error.message = msg || 'error occurred';
          error.data = errData || 'error occurred';
          console.log('>>>>>>>>>>>>>ERRRRRR>>>>>>>>>>');
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown); // if (httpStatus === 401) {
          //   // window.alert(this.intl.t('session expired due to inactivity'))
          //   this.auth.logout(true)
          // }

          reject(error); // throw new Error();
        });
      });
    }

  });

  _exports.default = _default;
});