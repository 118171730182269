define("client/pods/components/settings/defaults/3rd-party-editor/facebook-plugins/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+54u6KUJ",
    "block": "{\"symbols\":[\"@documentationResource\",\"@isReadonly\",\"@model\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@label\"],[\"facebookPage\",[23,1,[]],\"facebook page\"]],{\"statements\":[[0,\"\\n  \"],[5,\"lists/list-dropdown\",[],[[\"@addItemComponent\",\"@allowDeselect\",\"@disabled\",\"@isBtn\",\"@itemValueKey\",\"@itemLabelKey\",\"@list\",\"@translate\",\"@value\"],[\"facebook/facebook-pages-id\",true,[23,2,[]],true,\"_data.value\",\"_data.label\",[23,0,[\"crud\",\"lists\",\"facebook-pages\"]],false,[23,3,[\"_data\",\"facebookPage\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/defaults/3rd-party-editor/facebook-plugins/template.hbs"
    }
  });

  _exports.default = _default;
});