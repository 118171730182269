define("client/pods/components/extensions/apps/tw/e-invoices/table-view/detail-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    defaultPaymentMethods: Ember.computed('crud.lists.paymentMethods', function () {
      const paymentMethods = this?.crud?.lists?.paymentMethods || this?.crud?.lists['payment-methods'] || [];
      return R.filter(R.hasPath(['_data', 'isNotEditable']))(paymentMethods);
    })
  });

  _exports.default = _default;
});