define("client/pods/extensions/apps/tw/momo/lists/products/sync-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/utils/nventor", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtensionsAppsTwShopeeListsOrdersImportSubPanelsAdapter extends _model.default {}

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: ExtensionsAppsTwShopeeListsOrdersImportSubPanelsAdapter,
    idParam: false,

    findAll() {
      const data = [{
        value: 'unSyncProducts',
        label: 'unsynchronized products',
        loadInstructions: {
          component: 'extensions/apps/tw/momo/products/sync/table-view',
          action: 'new',
          tabType: 'replaceSubTab'
        },
        component: 'extensions/apps/tw/momo/products/sync/table-view',
        action: 'new',
        tabType: 'replaceSubTab'
      }, {
        value: 'products',
        label: 'products',
        loadInstructions: {
          component: 'extensions/apps/tw/momo/products/sync/table-view',
          action: 'new',
          tabType: 'replaceSubTab'
        },
        component: 'extensions/apps/tw/momo/products/sync/table-view',
        action: 'new',
        tabType: 'replaceSubTab'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});