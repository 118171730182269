define("client/pods/products/sets/details/model", ["exports", "client/pods/base/model", "client/mixins/products-children", "client/mixins/uploads"], function (_exports, _model, _productsChildren, _uploads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_productsChildren.default, _uploads.default, {
    init() {
      this._super(...arguments);

      this.set('childAdapters', {
        'choices': 'products/choices',
        'choices-details': 'products/choices/details',
        'sets-details': 'products/sets/details',
        'variants': 'products/variants',
        'variants-options': 'products/variants/options',
        'variants-children': 'products/variants/children'
      });
    },

    defaults() {
      return {
        isSet: true,
        status: this.get('constants.status.active')
      };
    } // defaultImage: computed('productsUploadsCollection._data.uploads.[]', 'productsCampaignsUploadsCollection._data.uploads.[]', '_data.defaultImage', function () {
    //   if (this.get('_data.defaultImage')) {
    //     return this.get('_data.defaultImage')
    //   }
    //   const productsUploadsCollection = this.get('productsUploadsCollection._data.uploads') || []
    //   const productsCampaignsUploadsCollection = this.get('productsCampaignsUploadsCollection._data.uploads') || []
    //   const allUploads = productsUploadsCollection.concat(productsCampaignsUploadsCollection)
    //   const firstImage = allUploads.get('firstObject')
    //   if (firstImage) {
    //     return firstImage.uuid
    //   }
    //   return false
    // })


  });

  _exports.default = _default;
});