define("client/pods/components/documents/links-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showSearchBar: true,
    tagName: '',
    linksListProxy: Ember.computed('linksList.[]', function () {
      return this?.linksList || [];
    }),
    hasMultipleSelected: Ember.computed('linksListProxy.@each.isSelected', function () {
      const selected = this.linksListProxy.filterBy('isSelected', true) || [];

      if (selected.length > 0) {
        return selected;
      }

      return false;
    }),
    actions: {
      // loadLinkedDetail (linkFromDetail) {
      //   this.get('onCloseDialogAction')()
      //   this.get('onPreLoadLinkedDetails')([linkFromDetail], this.get('detail'))
      // },
      done() {
        const selected = this.hasMultipleSelected;

        if (this.onCloseDialogAction) {
          this.onCloseDialogAction();
        }

        this.onPreLoadLinkedDetails(selected, this.detail);
      }

    }
  });

  _exports.default = _default;
});