define("client/pods/components/menus/nav-menu/nav-item/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['tab-item'],
    classNameBindings: ['isActive:is-active'],
    translate: true,
    isDisabled: Ember.computed('item._data.{enable}', function () {
      const enable = R.pathOr(true, ['item', '_data', 'enable'])(this);
      return !enable;
    }),

    click() {
      const item = this.item;
      this.onSelect(item);
    },

    isActive: Ember.computed('selected', function () {
      return this.selected === this.item;
    })
  });

  _exports.default = _default;
});