define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/price-selector-dialog/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsCampaignsItemEditorPriceSelectorDialogComponent = (_dec = Ember.inject.service('products'), _dec2 = Ember.inject.service('campaignsProducts'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class ChannelsShopCampaignsCampaignsItemEditorPriceSelectorDialogComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "productsService", _descriptor, this);

      _initializerDefineProperty(this, "campaignsProductsService", _descriptor2, this);

      _initializerDefineProperty(this, "priceHistoryData", _descriptor3, this);

      _initializerDefineProperty(this, "preferredPriceData", _descriptor4, this);

      _initializerDefineProperty(this, "hasNoPriceHistory", _descriptor5, this);
    }

    *fetchHistoryOnInitTask() {
      const {
        preferredHistoryData,
        priceHistoryData
      } = yield this.args.onFetchPriceHistoryTask();
      this.hasNoPriceHistory = _ramdaAdjunct.default.isNilOrEmpty(preferredHistoryData);
      this.priceHistoryData = priceHistoryData;
      this.preferredPriceData = preferredHistoryData;
    }

    get sortedData() {
      const priceHistoryData = this?.priceHistoryData || [];
      const forCampaigns = this.productsService.getFor('campaigns', priceHistoryData);
      const forAutomations = this.productsService.getFor('automations', priceHistoryData);
      const forDocuments = this.productsService.getFor('documents', priceHistoryData);
      const nonePrice = [{
        type: 'none',
        price: '',
        discPrice: '',
        priceFinal: '',
        inclPrice: '',
        exclPrice: ''
      }];

      if (_ramdaAdjunct.default.isNilOrEmpty(forCampaigns) && _ramdaAdjunct.default.isNilOrEmpty(forDocuments) && _ramdaAdjunct.default.isNilOrEmpty(forAutomations)) {
        return [];
      }

      return _ramdaAdjunct.default.concatAll([nonePrice, forCampaigns, forAutomations, forDocuments]);
    }

    get preferredPrice() {
      const preferredPriceData = this.preferredPriceData || {}; // from doc (inclPrice), from campaign (priceFinal)

      const preferredPrice = _ramdaAdjunct.default.defaultWhen(_ramdaAdjunct.default.isNilOrEmpty, preferredPriceData?.priceFinal, preferredPriceData?.inclPrice);

      return preferredPrice;
    }

    get selectedPrice() {
      const preferredPriceData = this?.preferredPriceData || {};

      if (preferredPriceData.type === 'none') {
        return 'none';
      }

      if (preferredPriceData?.doc?.contact) {
        return preferredPriceData.inclPrice;
      }

      return preferredPriceData.price;
    }

    select(history, onHideDropdown) {
      if (this.selectedPrice === 'none') {
        if (onHideDropdown) {
          return onHideDropdown();
        }

        return;
      }

      this.args.onSelect(history, onHideDropdown);
    }

    selectPreferredPriceData(history) {
      this.preferredPriceData = history;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "productsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "campaignsProductsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "priceHistoryData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "preferredPriceData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasNoPriceHistory", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchHistoryOnInitTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchHistoryOnInitTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectPreferredPriceData", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "selectPreferredPriceData"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsItemEditorPriceSelectorDialogComponent;
});