define("client/pods/news/details/batch/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new"], function (_exports, R, _adapter, _adaptersNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/news/details/batch',
    idParam: false,

    serialize(details) {
      return R.map(detail => {
        return this._serialize(detail._data);
      })(details);
    },

    updateIndexes(data, appendPath) {
      const path = this.getFullPath('updatePath', appendPath);
      data = R.map(R.pick(['_key', 'index']))(data);
      data = this._addAdapterInfo(data, appendPath);
      const payload = {
        batch: data
      };

      try {
        return this.ajax.PATCH(path, payload);
      } catch (e) {
        console.error(e);
      }
    }

  });

  _exports.default = _default;
});