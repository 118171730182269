define("client/pods/components/dispatch/panels-display/list-summary/component", ["exports", "ramda", "client/mixins/big", "ramda-extension"], function (_exports, R, _big, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_big.default, {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    tagName: '',
    displaySummary: false,

    didInsertElement() {
      this._super(...arguments);

      setTimeout(() => {
        if (this?.isDestroyed === false) {
          this.set('displaySummary', true);
        }
      }, 700);
    },

    hasMultipleProcesses: Ember.computed('summaryData.processes.[]', function () {
      const processes = this.get('summaryData.processes') || [];

      if (processes.length > 1) {
        return true;
      }

      return false;
    }),
    dispatchProcessesList: Ember.computed('crud.lists.dispatch-lists-processes-menu.[]', function () {
      const dispatchProcessesList = R_.dotPath('crud.lists.dispatch-lists-processes-menu')(this) || [];
      const isDispatchedProcessModel = this.isDispatchedProcessModel;
      return R.concat(dispatchProcessesList, [isDispatchedProcessModel]);
    }),
    summaryProcessesFilters: Ember.computed('filteredSummaryData.[]', function () {
      const dispatchProcessCodes = this.get('summaryData.processes') || [];
      const dispatchProcesses = this.dispatchProcessesList;
      const unpreparedProcess = this.dispatch.findDispatchProcess(dispatchProcesses, 'unprepared');
      return R.pipe(R.map(dispatchProcessCode => {
        const dispatchProcess = this.dispatch.findDispatchProcess(dispatchProcesses, dispatchProcessCode) || unpreparedProcess;
        return Ember.Object.create({
          dispatchProcess,
          index: R.path(['_data', 'index'])(dispatchProcess),
          selected: true
        });
      }), R.uniq, R.sortWith([R.ascend(R.prop('index'))]))(dispatchProcessCodes);
    }),
    allowedDispatchProcessesCodes: Ember.computed('summaryProcessesFilters.@each.selected', function () {
      const summaryProcessesFilters = this.summaryProcessesFilters || [];
      return R.pipe(R.filter(R.propEq('selected', true)), R.pluck('dispatchProcess'), R.pluck('_data'), R.pluck('code'))(summaryProcessesFilters);
    }),
    filteredSummaryData: Ember.computed('dispatchProcessesList.[]', 'summaryData.{processes.[],items.[]}', function () {
      const summaryData = Ember.Object.create(this.summaryData);
      const dispatchProcessesList = this.dispatchProcessesList || [];
      const processesModels = R.pipe(R.propOr([], 'processes'), R.map(dispatchProcess => {
        return this.dispatch.findDispatchProcess(dispatchProcessesList, dispatchProcess);
      }), R.reject(R.isNil), R.sortWith([R.ascend(R.path(['_data', 'index']))]))(summaryData);
      summaryData.set('processes', processesModels);
      return summaryData;
    }),
    summaryTotalPackages: Ember.computed('filteredSummaryData', 'allowedDispatchProcessesCodes.[]', function () {
      const summaryData = this.filteredSummaryData || {};
      const allowedDispatchProcessesCodes = this.allowedDispatchProcessesCodes || [];
      const allowedProcessesData = R.pick(allowedDispatchProcessesCodes, summaryData);
      const totals = R.pipe(R.pluck('packages'), R.values)(allowedProcessesData);
      return this.sumArray(totals).toFixed(0);
    }),
    summaryTotal: Ember.computed('filteredSummaryData', 'allowedDispatchProcessesCodes.[]', function () {
      const summaryData = this.filteredSummaryData || {};
      const allowedDispatchProcessesCodes = this.allowedDispatchProcessesCodes || [];
      const allowedProcessesData = R.pick(allowedDispatchProcessesCodes, summaryData);
      const totals = R.pipe(R.pluck('total'), R.values)(allowedProcessesData);
      return this.sumArray(totals).toFixed(0);
    })
  });

  _exports.default = _default;
});