define("client/pods/websites/lists/new-website-wizard/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        component: 'websites/websites-editor/website-wizard/quick-start',
        icon: 'fas fa-tasks',
        label: 'website info',
        tabType: 'reuseSubTab',
        value: 'websiteInfo'
      }, {
        component: 'websites/websites-editor/website-wizard/quick-templates',
        icon: 'fas fa-file-alt',
        label: 'public templates',
        tabType: 'reuseSubTab',
        value: 'publicTemplates'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});