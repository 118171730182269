define("client/pods/components/products/products-editor/sets-with-choices-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E13YwRKZ",
    "block": "{\"symbols\":[\"choice\",\"index\",\"@model\",\"@isEditing\",\"@isNew\",\"@tab\"],\"statements\":[[4,\"if\",[[24,[\"isEditing\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"model\",\"_data\",\"_rev\"]]],null,{\"statements\":[[0,\"    \"],[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"has-text-danger\"],[8],[1,[28,\"tt\",[\"product_sync_warning\"],null],false],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[23,3,[\"_data\",\"choices\"]]],null,{\"statements\":[[0,\"  \"],[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"products/products-editor/sets-with-choices-editor/choices-editor\",[],[[\"@choice\",\"@errorIndex\",\"@errors\",\"@model\",\"@onRemoveChoice\",\"@isEditing\",\"@isNew\",\"@isReadonly\",\"@tab\"],[[23,1,[]],[23,2,[]],[22,\"errors\"],[23,3,[]],[28,\"action\",[[23,0,[]],\"removeChoice\",[23,3,[]],[23,1,[]]],null],[23,4,[]],[23,5,[]],[23,0,[\"isReadonlyAndAvailable\"]],[23,6,[]]]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n  \"],[5,\"elements/element-btn\",[],[[\"@icon\",\"@tooltipLabel\",\"@disabled\",\"@onSubmit\"],[\"fas fa-plus\",\"add new choices\",[23,0,[\"isReadonlyAndAvailable\"]],[28,\"action\",[[23,0,[]],\"addChoice\",[23,3,[]]],null]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/products-editor/sets-with-choices-editor/template.hbs"
    }
  });

  _exports.default = _default;
});