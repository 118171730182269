define("client/pods/users/personal-filters/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({// populate (data, attrs) {
    //   data.groups = this._transformGroups(data.groups)
    //   data.sub = this._transformSub(data.sub)
    //   this._super(...arguments)
    // },
    // _transformGroups (groups = []) {
    //   return groups.map(group => {
    //     return group.map(data => {
    //       data.sub = this._transformSubNested(data.sub)
    //       return this.dataManager.setPartialRecord({
    //         adapterName: 'users/personal-menu/groups-sub',
    //         data
    //       })
    //     })
    //   })
    // },
    // _transformSub (sub = []) {
    //   return sub.map(data => {
    //     data.groups = this._transformGroups(data.groups)
    //     data.sub = this._transformSubNested(data.sub)
    //     return this.dataManager.setPartialRecord({
    //       adapterName: 'users/personal-menu/groups-sub',
    //       data
    //     })
    //   })
    // },
    // _transformSubNested (sub = []) {
    //   return sub.map(data => {
    //     return this.dataManager.setPartialRecord({
    //       adapterName: 'users/personal-menu/groups-sub',
    //       data
    //     })
    //   })
    // }
  });

  _exports.default = _default;
});