define("client/pods/commissions/lists/panels/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../../../../../../utils/nventor'
  // import AdapterMixin from '../../../../../../mixins/adapters-new'
  // import OptionsModel from '../../../../../options/model'
  var _default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,

    findAll() {
      const data = [{
        value: 'show all',
        label: 'show all',
        component: 'reports/commissions/panels-all'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});