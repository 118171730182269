define("client/pods/contacts/aging/purchases/model", ["exports", "client/pods/base/model", "ramda-adjunct"], function (_exports, _model, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate(data, attrs) {
      if (RA.isNotNilOrEmpty(data.contactData)) {
        data.contactData = this._transformContact(data.contactData);
      }

      this._super(...arguments);
    },

    _transformContact() {
      let contactData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.dataManager.setPartialRecord({
        adapterName: 'contacts',
        data: contactData
      });
    }

  });

  _exports.default = _default;
});