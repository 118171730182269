define("client/pods/updates/service", ["exports", "ramda", "client/config/environment", "ember-concurrency"], function (_exports, R, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    hasNewUpdate: false,
    server: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('env', _environment.default);
      this.set('forceLogout', false);
    },

    isUpdateRequired(data) {
      if (data.debug) {
        debugger; //eslint-disable-line
      }

      if (data) {
        const currentVersionNumber = parseInt(this.versionNumber) || 0;
        const latestVersionNumber = parseInt(data.versionNumber) || 0;

        if (currentVersionNumber < latestVersionNumber) {
          this.set('hasNewUpdate', true);
          return true;
        }
      }

      return false;
    },

    checkForReSyncData(socketId, wasDisconnected) {
      this.updateCacheDataTask.perform(socketId, wasDisconnected);
    },

    updateCacheDataTask: (0, _emberConcurrency.task)(function* (socketId, wasDisconnected) {
      this.set('isReSyncing', true);
      let isStale = true;

      try {
        const response = yield this.server.call('GET', 'api/protected/app/resync', {
          id: socketId,
          timestampZ: wasDisconnected
        });
        const currentState = R.propOr({}, 'data', response);
        isStale = currentState.isStale;
      } catch (e) {
        isStale = true;
      }

      if (isStale) {
        this.set('forceLogout', true);
      } else {
        this.set('forceLogout', false);
      }

      this.set('isReSyncing', false);
      return isStale;
    }).drop(),
    version: Ember.computed('env.version', function () {
      return _environment.default.version || '';
    }),
    versionNumber: Ember.computed('env.versionNumber', function () {
      return R.pipe(R.split('.'), R.map(number => {
        let leadingZeros = [0, 0, 0];
        const numberOfZeros = leadingZeros.length - number.length;
        leadingZeros = R.take(numberOfZeros)(leadingZeros);
        leadingZeros.push(number);
        return R.join('', leadingZeros);
      }), R.join(''))(_environment.default.version);
    })
  });

  _exports.default = _default;
});