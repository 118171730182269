define("client/pods/components/news/panels-edit/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'news',

    init() {
      this.setup();

      this._super(...arguments);
    },

    setup() {
      const dirty = this.setupDirty({
        model: this.get('tab.model')
      });
      this.set('dirty', dirty);
    }

  });

  _exports.default = _default;
});