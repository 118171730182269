define("client/pods/components/extensions/apps/tw/shopDotCom/info-summary/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtensionsAppsTwShopDotComInfoSummaryComponent extends _component.default {
    get shopDotComData() {
      if (this.args.model._data.docType === 'sales' && this.args.model._data.docName === 'invoices') {
        const shopDotComSettings = R.pathOr({}, ['args', 'model', '_data', 'thirdParty', 'shopDotCom'])(this);
        const thirdPartyCommissions = R.pathOr([], ['args', 'model', '_data', 'thirdPartyCommissions'])(this);
        const shopDotCom = R.find(R.propEq('provider', 'shopDotCom'))(thirdPartyCommissions) || {};
        const data = {
          externalId: this.args.model._data?.externalId,
          ...shopDotComSettings,
          ...shopDotCom
        } || {};
        return data;
      }

      return {};
    }

  }

  _exports.default = ExtensionsAppsTwShopDotComInfoSummaryComponent;
});