define("client/pods/components/menus/tab-menu-new/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, _ramda, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MenusTabMenuNewComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class MenusTabMenuNewComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "selected", _descriptor, this);

      _defineProperty(this, "menuLabelKey", this.args?.menuLabelKey || '_data.label');

      _defineProperty(this, "classNames", this.args?.classNames || 'tabs u-tab-menu');
    }

    get isCurrentActive() {
      const tabs = this.args?.tabs || [];

      if (RA.isNotNilOrEmpty(tabs)) {
        const activeSubTab = _ramda.default.find(_ramda.default.propEq('isActive', true))(tabs) || tabs[0]; // @DO NOT DELETE
        // use tracked variable to update get

        const selected = this.selected;

        if (selected) {
          if (selected?.label === activeSubTab?.label || selected?.value === activeSubTab?.value || selected?.component === activeSubTab?.component) {
            return {
              component: activeSubTab?.component,
              value: activeSubTab?.value,
              label: activeSubTab?.label
            };
          }
        }

        return {
          component: activeSubTab?.component,
          value: activeSubTab?.value,
          label: activeSubTab?.label
        };
      }

      return {};
    }

    get translate() {
      return _ramda.default.pathOr(true, ['args', 'translate'])(this);
    }
    /**
     * Select tab item
     * @param {Object} item
     * @void
     */


    select(item) {
      if (this.args?.onSelect) {
        this.args.onSelect(item);
        this.selected = item?._data;
      }
    }
    /**
     * Set css prop
     * @param {HTMLElement} element
     * @void
     */


    setCss(element) {
      if (RA.isNilOrEmpty(element)) {
        return;
      }

      const tabGridContent = element.closest('.tab-grid__content');
      const panelGrid = element.closest('.panel-grid');
      const tabMenuContainer = panelGrid.querySelector('.panel-grid__header-with-guide-menu');

      if (!tabMenuContainer) {
        return;
      }

      const outerPanelContent = panelGrid.closest('.panel-grid__content');
      const headerTitle = panelGrid.querySelector('.panel-grid__header-title');

      this._setHeaderTitleCss({
        tabGridContent,
        tabMenuContainer,
        headerTitle,
        outerPanelContent
      });
    }
    /**
     * Set css prop of tab header-title
     * @param {Object} params
     * @param {HTMLElement} params.tabMenuContainer
     * @param {HTMLElement|Null} params.tabGridContent
     * @param {HTMLElement|Null} params.headerTitle
     * @param {HTMLElement|Null} params.outerPanelContent if not null, that means it is a panel grid inside another one
     * @void
     */


    _setHeaderTitleCss() {
      let {
        tabMenuContainer,
        tabGridContent,
        headerTitle,
        outerPanelContent
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (tabGridContent) {
        const tabContentOnly = this.args?.tabContentOnly || false;

        if (tabContentOnly) {
          tabMenuContainer.style.top = 'auto';
        } else {
          const offsetTop = tabGridContent.offsetTop || 128;
          tabMenuContainer.style.top = `${offsetTop}px`;
        }
      }

      if (outerPanelContent) {
        if (headerTitle) {
          headerTitle.style.zIndex = 'unset';
        }

        tabMenuContainer.style.zIndex = 'unset';
      } else {// if (headerTitle && !headerTitle.classList.contains('hidden')) {
        //   const tabMenuTop = tabMenuContainer?.offsetTop
        //   const tabMenuHeight = tabMenuContainer?.offsetHeight
        //   if (!tabMenuTop || !tabMenuHeight) {
        //     return
        //   }
        //   const tabMenuBottom = tabMenuTop + tabMenuHeight
        //   headerTitle.style.top = `${tabMenuBottom}px`
        //   headerTitle.style.position = 'sticky'
        // }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "select", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCss", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setCss"), _class.prototype)), _class));
  _exports.default = MenusTabMenuNewComponent;
});