define("client/pods/components/menus/tab-menu-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "urxRhAkk",
    "block": "{\"symbols\":[\"item\",\"index\",\"&default\",\"@itemClassNames\",\"@tabs\",\"@menu\"],\"statements\":[[7,\"div\",true],[11,\"class\",[23,0,[\"classNames\"]]],[8],[0,\"\\n  \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[23,6,[]]],null,{\"statements\":[[0,\"      \"],[5,\"menus/tab-menu-new/tab-item\",[],[[\"@classNames\",\"@isCurrentActive\",\"@item\",\"@menuLabelKey\",\"@menuIndex\",\"@onSelect\",\"@onSetCss\",\"@subTabs\",\"@translate\"],[[23,4,[]],[23,0,[\"isCurrentActive\"]],[23,1,[]],[23,0,[\"menuLabelKey\"]],[23,2,[]],[28,\"fn\",[[23,0,[\"select\"]],[23,1,[]]],null],[23,0,[\"setCss\"]],[23,5,[]],[23,0,[\"translate\"]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"      \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/tab-menu-new/template.hbs"
    }
  });

  _exports.default = _default;
});