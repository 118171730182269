define("client/pods/components/comments/comments-manager/component", ["exports", "ramda", "ramda-extension", "ramda-adjunct", "client/utils/nventor", "ember-concurrency"], function (_exports, R, R_, RA, _nventor, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    commentsService: Ember.inject.service('comments'),
    crud: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    settingsService: Ember.inject.service('settings'),
    tagName: 'span',
    classNames: ['comments__display-container'],
    tooltipLabel: 'internal comments',
    label: '',
    adapterName: 'comments',
    showEditor: false,
    isEditing: false,
    // icon: 'far fa-comment',
    isAllowed: Ember.computed('isPublic', function () {
      if (!this.isPublic) {
        return true;
      }

      if (!R.equals(this.subType, 'sales-invoices')) {
        return false;
      }

      return this.settingsService?.settingsModel?._data?.allowOrderMessages;
    }),

    init() {
      this._super(...arguments);

      this.set('isEditing', false);
      const firstCommentFilters = this.search.setupFilters({
        adapterName: 'comments/contacts/filters',
        defaultFiltersData: {
          fetchWithActiveCampaigns: true
        }
      });
      this.set('firstCommentFilters', firstCommentFilters);
    },

    didInsertElement() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      if (!this.isAllowed) {
        return false;
      }

      this.setupNewCommentRecord();
      const master = this.master;

      if (master) {
        this.fetchCommentsTask.perform(master);
        const filters = this.firstCommentFilters;
        this.fetchFirstCommentDataTask.perform({
          adapterName: 'comments',
          filters
        });
      }
    },

    setupNewCommentRecord() {
      if (this?.isDestroyed === false) {
        const master = this.master;
        const tokenData = this.storage.get('tokenData');
        const data = {
          username: tokenData.username,
          master: master
        };
        const model = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          data
        });
        this.set('newModel', model);
      }
    },

    comments: Ember.computed('commentsCollection._data.comments.[]', 'commentsCollection.synced', function () {
      return this.get('commentsCollection._data.comments') || [];
    }),
    commentsCount: Ember.computed('comments.[]', 'commentsCollection.synced', function () {
      const comments = this.comments || [];
      return comments.length;
    }),
    fetchFirstCommentDataTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        adapterName,
        filters
      } = _ref;
      return function* () {
        const master = _this.master;
        filters.setData('master', master);
        const results = yield _this.crud.searchRecordsConcurrentlyTask.perform({
          adapterName: `${adapterName}/contacts`,
          filters
        });

        const firstCommentModel = _nventor.default.safeHeadOr({}, results);

        if (RA.isNotNilOrEmpty(firstCommentModel)) {
          _this.set('firstCommentModel', firstCommentModel);
        }

        if (_this.isPublic) {
          _this.metaData.name = firstCommentModel?._data?.docNo || '';
        }
      }();
    }),
    fetchCommentsTask: (0, _emberConcurrency.task)(function* (master) {
      var _this2 = this;

      if (!this.isAllowed) {
        return false;
      }

      const data = {};
      const options = {
        data,
        excludeFromFilters: this.excludeFromFilters || [],
        master,
        metaData: this.metaData,
        related: this.related,
        type: this.type
      };
      yield this.commentsService.qFetch(options, function () {
        let batchCommentsCollection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        if (_this2?.isDestroyed === false) {
          if (!R.is(Array, batchCommentsCollection)) {
            return false;
          }

          const type = _this2.type;
          let criteria = R_.dotPathEq('_data.master', master);

          if (type) {
            criteria = R.allPass([R_.dotPathEq('_data.master', master), R_.dotPathEq('_data.type', type)]);
          }

          const commentsCollection = R.find(criteria)(batchCommentsCollection) || [];

          _this2.set('commentsCollection', commentsCollection);
        }
      });
    }),
    createCommentTask: (0, _emberConcurrency.task)(function* (onUpdateContent) {
      try {
        const nowZ = this.dateService.getNowZ();
        const type = this.type;
        const subType = this.subType;
        const model = this.newModel; // const message = model.getData('message.string')
        // model.setData('message', message)

        if (!model.getData('message')) {
          return false;
        }

        let confirmed = true;

        if (this.isPublic) {
          const msg = this.intl.t('are you sure you want to comment and notify customer');
          confirmed = window.confirm(msg);
        }

        if (confirmed) {
          model.setData('dateZ', nowZ);
          model.setData('type', type);

          if (subType) {
            model.setData('subType', subType);
          }

          const metaData = this.metaData;

          if (metaData) {
            Object.keys(metaData).map(key => {
              const value = metaData[key];
              model.setData(key, value);
            });
          }

          if (this.onBeforeCreateComment) {
            this.onBeforeCreateComment(model);
          }

          let firstCommentModel = this.firstCommentModel;

          if (RA.isNilOrEmpty(firstCommentModel)) {
            // check if there's first comment model one more time
            // first comment will not be there if somehow APP user is already inside comment modal, and the broadcast updated customer message from SHOP
            yield this.fetchFirstCommentDataTask.perform({
              adapterName: this.adapterName,
              filters: this.firstCommentFilters
            });
            firstCommentModel = this.firstCommentModel;
          } // set as isFirst if there's still no firstCommentModel


          if (RA.isNilOrEmpty(firstCommentModel)) {
            model.setData('isFirst', true);
          }

          const msg = yield this.crud.createRecordTask.perform({
            adapterName: this.adapterName,
            model
          });

          if (RA.isNilOrEmpty(firstCommentModel)) {
            yield this.fetchFirstCommentDataTask.perform({
              adapterName: this.adapterName,
              filters: this.firstCommentFilters
            });
          }

          this.setupNewCommentRecord();

          if (onUpdateContent) {
            onUpdateContent(msg);
          }
        }
      } catch (err) {
        this.set('errors', err.data);

        _nventor.default.throw('createCommentTask failed', err);
      }
    }).drop(),

    afterRemoveRecordTask(model) {
      const dirty = this.dirty;
      const comments = this.comments;
      comments.removeObject(model);

      if (dirty && model) {
        if (dirty.getData('_id') === model.getData('_id')) {
          this.cancelEdit();
        }
      }
    },

    willDestroyElement() {
      if (this?.isDestroyed === false) {
        this.cancelEdit();
      }
    },

    cancelEdit() {
      this.set('dirty', '');
      this.set('isEditing', false);
    },

    edit(model) {
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model
      });
      this.set('dirty', dirty);
      this.set('isEditing', true);
    }

  }, (_applyDecoratedDescriptor(_obj, "cancelEdit", [_dec], Object.getOwnPropertyDescriptor(_obj, "cancelEdit"), _obj), _applyDecoratedDescriptor(_obj, "edit", [_dec2], Object.getOwnPropertyDescriptor(_obj, "edit"), _obj)), _obj)));

  _exports.default = _default;
});