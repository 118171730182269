define("client/pods/blogs/model", ["exports", "client/pods/base/model", "client/mixins/uploads", "client/mixins/statuses"], function (_exports, _model, _uploads, _statuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_uploads.default, _statuses.default, {
    defaults() {
      return {
        index: 0
      };
    },

    index: Ember.computed('_data.index', function () {
      return this._data.index;
    }),
    displayIndex: Ember.computed('_data.index', function () {
      return this._data.index + 1;
    })
  });

  _exports.default = _default;
});