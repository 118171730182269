define("client/pods/date/lists/durations/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/base/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OpenHours extends _model.default {}

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: OpenHours,
    idParam: false,

    findAll() {
      const data = [{
        value: 'none',
        label: '',
        translate: true
      }, {
        value: 0.25,
        label: '15 minutes'
      }, {
        value: 0.5,
        label: '30 minutes'
      }, {
        value: 1,
        label: '1 hour'
      }, {
        value: 2,
        label: '2 hours'
      }, {
        value: 3,
        label: '3 hours'
      }, {
        value: 4,
        label: '4 hours'
      }, {
        value: 5,
        label: '5 hours'
      }, {
        value: 6,
        label: '6 hours'
      }, {
        value: 24,
        label: '1 day'
      }, {
        value: 48,
        label: '2 days'
      }, {
        value: 72,
        label: '3 days'
      }, {
        value: 96,
        label: '4 days'
      }, {
        value: 168,
        label: '1 week'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});