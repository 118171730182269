define("client/pods/components/helpers/pre-line/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['u-pre-line'],
    trimmedValue: Ember.computed('value', function () {
      const value = this.value;

      if (typeof value === 'string') {
        return value.trim();
      }

      return value;
    })
  });

  _exports.default = _default;
});