define("client/pods/components/products/products-editor/sets-with-choices-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isReadonlyAndAvailable: Ember.computed('model._data.isSetWithChoices', 'isReadonly', function () {
      if (!this.get('model._data.isSetWithChoices')) {
        return true;
      }

      return this.isReadonly;
    }),
    actions: {
      addChoice(model) {
        model.addChoice();
      },

      removeChoice(model, choice) {
        const msg = this.intl.t('are you sure you want to remove');

        if (window.confirm(msg)) {
          model.removeChoice(choice);
        }
      }

    }
  });

  _exports.default = _default;
});