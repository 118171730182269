define("client/pods/components/contacts/details/editor-details/editor-shopee-code/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V7InGLpb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-input-row\",null,[[\"label\",\"customBlock\",\"errors\",\"context\"],[\"shopee Code\",true,[24,[\"errors\"]],\"value\"]],{\"statements\":[[0,\"\\n\"],[4,\"forms/form-label\",null,[[\"context\",\"documentationResource\"],[[24,[\"detail\",\"_data\",\"type\"]],\"contacts\"]],{\"statements\":[[0,\"    \"],[1,[28,\"tt\",[\"shopee code\"],null],false],[0,\"\\n    \"],[1,[28,\"contacts/icons/detail-icon\",null,[[\"detail\"],[[24,[\"detail\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input\",null,null,{\"statements\":[[0,\"    \"],[1,[28,\"elements/element-input\",null,[[\"autofocus\",\"value\",\"onSelect\"],[[24,[\"autofocus\"]],[24,[\"detail\",\"_data\",\"value\"]],[24,[\"onCheckForDefaults\"]]]]],false],[0,\"\\n\\n    \"],[1,[28,\"contacts/icons/default-icon\",null,[[\"detail\"],[[24,[\"detail\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/details/editor-details/editor-shopee-code/template.hbs"
    }
  });

  _exports.default = _default;
});