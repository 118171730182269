define("client/pods/components/documents/panel-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2nyaUt53",
    "block": "{\"symbols\":[\"@filters\",\"@onDisplay\",\"@onEdit\",\"@onPaginationTask\",\"@onReloadSearchRecordsTask\",\"@onSearchTask\",\"@results\",\"@tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[5,\"layouts/table-view\",[],[[\"@detailsRowComponent\",\"@filters\",\"@listAdapters\",\"@onDisplay\",\"@onEdit\",\"@onMainAction\",\"@onPaginationTask\",\"@onReloadSearchRecordsTask\",\"@onSearchTask\",\"@results\",\"@tab\",\"@tableViewOptions\"],[\"documents/table-view/doc-row\",[23,1,[]],[28,\"array\",[\"documents/lists/filters/statuses\",\"filters/count\",\"toggles/yesNo\",\"paymentMethods\",\"paymentTerms\",\"units\",[28,\"hash\",null,[[\"name\",\"appendPath\"],[\"users\",\"internal\"]]],\"documents/lists/filters/statuses\",\"documents/lists/filters/productsStatus\",\"dispatch/lists/processes\",\"contacts/lists/tags\",\"extensions/apps/tw/t-cat/lists/waybillPrintType\",\"dispatch/lists/processes\",\"channels/shop/campaigns/lists/locations\"],null],[23,2,[]],[23,3,[]],[23,2,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,0,[\"tableViewOptions\"]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documents/panel-dash/template.hbs"
    }
  });

  _exports.default = _default;
});