define("client/pods/components/documents/editor-main/editor-btns-display/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    tagName: '',
    server: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    showVerticalDispatchProcessBar: false,
    isReQueued: false,
    showReQueueBtn: Ember.computed('model._data.isTempDocNo', function () {
      const twoMinLater = this.dateService.getMoment(this.model?._data?.timestampZ).add(30, 'seconds');
      const nowZ = this.dateService.getNowZ();

      if (twoMinLater.isAfter(nowZ)) {
        return false;
      }

      if (this.model?._data?.isTempDocNo) {
        return true;
      }

      return false;
    }),
    reQueueToGenerateDocumentNumber: (0, _emberConcurrency.task)(function* (model) {
      const results = yield this.server.call('PATCH', `api/protected/documents/${model?._data?.docType}/${model?._data?.docName}/number/${model._data._key}`, {
        tempDocNo: model?._data?.docNo
      });
      this.set('isReQueued', true);
    }).drop(),

    setupDirty() {
      // @TODO: Move to load instructions
      const model = this.model;
      const dirty = this.crud.setupDirty({
        adapterName: model.get('adapterName'),
        model
      });
      this.set('dirtyModel', dirty);
    }

  }, (_applyDecoratedDescriptor(_obj, "setupDirty", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupDirty"), _obj)), _obj)));

  _exports.default = _default;
});