define("client/pods/websites/lists/types/links/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: '',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: this.constants.websitePageLinkTypes.url,
        label: 'website_type_link_url'
      }, {
        value: this.constants.websitePageLinkTypes.pageAndCategory,
        label: 'website_type_link_pageAndCategory'
      }, // {
      //   value: this.constants.websitePageLinkTypes.page,
      //   label: 'website_type_link_page'
      // },
      // {
      //   value: this.constants.websitePageLinkTypes.category,
      //   label: 'website_type_category'
      // },
      {
        value: this.constants.websitePageLinkTypes.product,
        label: 'website_type_link_product'
      }, {
        value: this.constants.websitePageLinkTypes.blog,
        label: 'website_type_link_blogs'
      }, {
        value: this.constants.websitePageLinkTypes.news,
        label: 'website_type_link_news'
      }, {
        value: this.constants.websitePageLinkTypes.storeLocation,
        label: 'website_type_link_store-location'
      }, {
        value: this.constants.websitePageLinkTypes.flows,
        label: 'website_type_link_flows'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});