define("client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1W6F8oEg",
    "block": "{\"symbols\":[\"@model\",\"@tab\",\"@onUpdateBulkDiscountTask\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"panel-grid__content\"],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"setupDirty\"]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"dirty\"]]],null,{\"statements\":[[0,\"      \"],[5,\"channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/bulk-discount-editor\",[],[[\"@bulkDiscount\",\"@model\",\"@isEditing\",\"@tab\"],[[23,0,[\"dirty\"]],[23,1,[]],true,[23,2,[]]]]],[0,\"\\n\\n      \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[5,\"layouts/footer-btns\",[],[[],[]],{\"statements\":[[0,\"\\n          \"],[5,\"layouts/btns-group\",[],[[\"@classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\n            \"],[5,\"elements/btn-delete\",[],[[\"@icon\",\"@typeToDelete\",\"@label\",\"@onSubmit\",\"@isRunning\"],[\"fas fa-trash\",true,\"\",[28,\"perform\",[[23,0,[\"removeBulkDiscountTask\"]]],null],[23,3,[\"isRunning\"]]]]],[0,\"\\n\\n            \"],[5,\"layouts/btns-seperator\",[],[[],[]]],[0,\"\\n\\n            \"],[5,\"elements/btn-cancel\",[],[[\"@onSubmit\",\"@isRunning\"],[[28,\"fn\",[[23,0,[\"cancelEdit\"]]],null],[23,3,[\"isRunning\"]]]]],[0,\"\\n\\n            \"],[5,\"elements/btn-save\",[],[[\"@disabled\",\"@onSubmit\",\"@isRunning\"],[[23,0,[\"dirty\",\"hasBulkDiscountOverlap\"]],[28,\"perform\",[[23,0,[\"editBulkDiscount\"]]],null],[23,3,[\"isRunning\"]]]]],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/bulk-discounts-editor/panels-edit/template.hbs"
    }
  });

  _exports.default = _default;
});