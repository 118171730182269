define("client/pods/components/channels/shop/campaigns/items/table-view/detail-row/item-set-with-choices-details/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChannelsShopCampaignsItemsTableViewDetailRowItemSetWithChoicesDetailsComponent extends _component.default {
    get isLastChoice() {
      const model = this.args.model;
      const choices = model._data.choices || [];
      const choice = this.args.choice;
      const index = choice?._data?.index; // no need to length - 1, because index start from 1

      if (index === R.length(choices)) {
        return true;
      }

      return false;
    }

  }

  _exports.default = ChannelsShopCampaignsItemsTableViewDetailRowItemSetWithChoicesDetailsComponent;
});