define("client/pods/reset/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ResetRoute extends Ember.Route {
    model() {
      window.localStorage.clear();
    } // redirect () {
    //   this.transitionTo('index', {
    //     queryParams: {
    //       reload: true
    //     }
    //   })
    // }


  }

  _exports.default = ResetRoute;
});