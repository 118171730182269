define("client/pods/grapesjs/plugins/panels", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = editor => {
    const {
      Commands,
      Panels
    } = editor;
    Commands.add('set-device-desktop', function (e) {
      e.setDevice('Desktop');
    });
    Commands.add('set-device-tablet', function (e) {
      e.setDevice('Tablet');
    });
    Commands.add('set-device-mobile', function (e) {
      e.setDevice('Mobile portrait');
    });
    const panels = Panels.getPanels();
    R.pipe(R.forEach(singlePanel => {
      const el = singlePanel.get('el');

      if (el.includes('style')) {
        singlePanel.set('id', 'style');
      } else if (el.includes('devices')) {
        singlePanel.set('id', 'devices');
      } else if (el.includes('basic')) {
        singlePanel.set('id', 'basic');
      }
    }))(panels.models);
  };

  _exports.default = _default;
});