define("client/pods/components/payments/card/panels-display/component", ["exports", "client/mixins/crud", "client/mixins/payments-list-by", "ember-concurrency", "ramda", "ramda-extension"], function (_exports, _crud, _paymentsListBy, _emberConcurrency, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, _paymentsListBy.default, (_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    intl: Ember.inject.service(),
    exportService: Ember.inject.service('export'),
    excel: Ember.inject.service(),
    intl: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    adapterName: 'payments/card',
    isExporting: false,

    init() {
      var _this = this;

      this._super(...arguments);

      const period = this.get('tab.period');
      this.set('period', period);
      const nowZ = this.dateService.getMoment();
      const monthNow = nowZ.month() + 1;
      const yearNow = nowZ.year();
      this.set('mm', monthNow);
      this.set('yyyy', yearNow);
      const filters = this.filters;
      const {
        dateStart,
        dateEnd
      } = period;
      const paymentCreditCardStatus = [this.get('constants.paymentCreditCardStatus.approved'), this.get('constants.paymentCreditCardStatus.refundRequested'), this.get('constants.paymentCreditCardStatus.refunded'), this.get('constants.paymentCreditCardStatus.paidOut')];
      this.listByTask.perform({
        adapterName: 'payments/card',
        filters,
        dateStart,
        dateEnd,
        paymentCreditCardStatus
      });

      if (!this.query) {
        this.fetchStatementTask.perform(period);
      }

      const self = this;
      const paymentSpecs = {
        description: 'cc_payment',
        labelValue: 'CreditCardPayment',
        method: 'byDetails',
        index: 0,
        subMethod: '',
        paymentMethod: 'all',
        specs: [[{
          value: 'creator.md',
          prop: 'creator.md',
          label: this.intl.t('date'),
          transforms: [{
            label: 'getPropOr',
            fn: 'getPropOr',
            options: {
              propOr: 'paymentCreditCardTransactionTimestampZ'
            },
            type: ''
          }, {
            type: 'date',
            label: 'Date',
            fn: '',
            options: ''
          }],
          hideDefault: false,
          type: "date",
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'contact',
          prop: 'contact',
          label: this.intl.t('contact'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'email',
          prop: 'email',
          label: this.intl.t('email'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'docNo',
          prop: 'docNo',
          label: '#',
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'ref',
          prop: 'ref',
          label: this.intl.t('ref'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'externalId',
          prop: 'externalId',
          label: this.intl.t('cc_ref'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'inclTotal',
          prop: 'inclTotal',
          label: this.intl.t('subtotal'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'refundRequestedInclTotal',
          prop: 'refundRequestedInclTotal',
          label: this.intl.t('cc_refundRequested'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'refundedInclTotal',
          prop: 'refundedInclTotal',
          label: this.intl.t('cc_refunded'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'netInclTotal',
          prop: 'netInclTotal',
          label: this.intl.t('inclusive total'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'paymentCreditCardProcessingFee',
          prop: 'paymentCreditCardProcessingFee',
          label: this.intl.t('fees'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'campaign.name',
          prop: 'campaign.name',
          label: this.intl.t('shop'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'paymentCreditCardProvider',
          prop: 'paymentCreditCardProvider',
          label: '',
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'paymentCreditCard.partial_card_number',
          prop: 'paymentCreditCard.partial_card_number',
          label: this.intl.t('cc_partial_card_number'),
          transforms: [],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }, {
          value: 'dispatchProcessLabel',
          prop: 'dispatchProcessLabel',
          label: this.intl.t('dispatch status'),
          transforms: [],
          hideDefault: false,

          translateFn(propValue, eachDocData) {
            const status = R_.dotPath('status')(eachDocData);

            if (status !== 'final') {
              return self.intl.t(status);
            }

            let translate = true;
            const translateProp = 'dispatchProcessLabelTranslate';

            if (translateProp) {
              translate = R_.dotPath(translateProp)(eachDocData);
            }

            if (translate) {
              return self.intl.t(propValue);
            }
          },

          type: '',
          item: '',
          itemKey: '',
          isDetail: '' // }, {
          //   value: 'paymentCreditCardStatus',
          //   prop: 'paymentCreditCardStatus',
          //   label: this.intl.t('credit card status'),
          //   transforms: [],
          //   hideDefault: false,
          //   translateFn (propValue) {
          //     return self.intl.t(`cc_${propValue}`)
          //   },
          //   type: '',
          //   item: '',
          //   itemKey: '',
          //   isDetail: ''

        }, {
          value: 'invoices',
          prop: 'invoices',
          label: 'invoice number',
          transforms: [{
            fn: function () {
              let {
                spec,
                eachDocData,
                value,
                specOptions,
                options,
                index
              } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
              const values = R.pipe(R.propOr([], 'invoices'), R.map(invoice => {
                const data = invoice?._data || {};
                let invoiceDate = data?.dateLocal || data?.timestampZ || '';
                invoiceDate = _this.dateService.formatDate(invoiceDate, 'YYYY-MM-DD');
                return [{
                  label: _this.intl.t('invoice number'),
                  value: data.number || ''
                }, {
                  label: _this.intl.t('tax'),
                  value: data.taxTotal || ''
                }, {
                  label: _this.intl.t('inclTotal'),
                  value: data.inclTotal || ''
                }, {
                  label: _this.intl.t('invoice date'),
                  value: invoiceDate
                }];
              }))(eachDocData);
              return values;
            }
          }],
          hideDefault: false,
          type: '',
          item: '',
          itemKey: '',
          isDetail: ''
        }]]
      };
      const specsModel = this.crud.setupNewRecord({
        adapterName: 'payments/card/specs',
        data: paymentSpecs
      });
      this.set('specs', specsModel);
    },

    fetchStatementTask: (0, _emberConcurrency.task)(function* (period) {
      const appendPath = this.selectedProvider;
      const response = yield this.server.call('GET', `api/protected/payments/card/statement/${appendPath}`, period);
      response.data.podKey = this?.settings?.settingsModel?._data?.podKey;
      this.set('statement', response.data);
      return response.data;
    }),
    requestCancellationTask: (0, _emberConcurrency.task)(function* (model) {
      const msg = this.intl.t('are you sure you want to cancel?');

      if (window.confirm(msg)) {
        const updated = yield this.updateRecordTask.perform({
          adapterName: this.adapterName,
          appendPath: '/refund/request',
          model
        });
        const period = this.period;
        this.fetchStatementTask.perform(period);
        return updated;
      }
    }),
    undoCancellationTask: (0, _emberConcurrency.task)(function* (model) {
      const msg = this.intl.t('are you sure you want to undo cancel?');

      if (window.confirm(msg)) {
        const updated = yield this.updateRecordTask.perform({
          adapterName: this.adapterName,
          appendPath: '/refund/undo',
          model
        });
        const period = this.period;
        this.fetchStatementTask.perform(period);
        return updated;
      }
    }),
    exportTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction
    /* filter */
    ) {
      this.set('isExporting', true);
      let filename = this.filename;

      if (!filename) {
        filename = 'untitled';
      }

      const newSerializedCustomSpecs = [this?.specs?._data] || [];
      const filters = this.exportFilters;
      const {
        dateStart,
        dateEnd
      } = this.period;
      const paymentCreditCardStatus = [this.get('constants.paymentCreditCardStatus.approved'), this.get('constants.paymentCreditCardStatus.refundRequested'), this.get('constants.paymentCreditCardStatus.refunded'), this.get('constants.paymentCreditCardStatus.paidOut')];
      const results = yield this.listByTask.perform({
        adapterName: 'payments/card',
        appendPath: '/export',
        filters,
        dateStart,
        dateEnd,
        paymentCreditCardStatus
      });
      const serialized = R.map(doc => {
        const data = doc._data;
        data.invoices = doc?.eInvoicesCollection?._data?.invoices || [];
        return data;
      })(results);
      let maxColumns = 0;
      const worksheets = R.pipe(R.map(worksheet => {
        const method = R.prop('method')(worksheet);

        if (method) {
          const customSheetToPush = this.exportService.exportByMethod({
            specs: worksheet,
            docData: serialized
          }); // loop through all invoices...flatten and add extra columns
          // @TODO: not all rows needs to be same length. but use the same logic to get maxColumnns,
          // add header label for extra columns (and also work out for extra position)

          const headers = R.pathOr([], ['data', 0])(customSheetToPush);
          customSheetToPush.data = R.pipe(R.propOr([], 'data'), R.map(row => {
            row = R.flatten(row);

            if (row.length > maxColumns) {
              maxColumns = row.length;
            }

            return row;
          }), R.map(row => {
            let index = -1;
            row = R.map(column => {
              index = index + 1;

              if (R.is(Object)(column)) {
                headers[index] = column?.label || '';
                return column?.value || '';
              }

              return column;
            })(row);

            if (row.length === maxColumns) {
              return row;
            }

            const toAdd = maxColumns - row.length;
            const toAddColumns = R.times(() => {
              return '';
            })(toAdd);
            return R.concat(row)(toAddColumns);
          }))(customSheetToPush);

          if (customSheetToPush?.data) {
            customSheetToPush.data[0] = headers;
          }

          return customSheetToPush;
        }
      }))(newSerializedCustomSpecs);

      if (!R.isEmpty(worksheets)) {
        yield this.excel.exportFile({
          worksheets,
          title: filename
        });
        this.set('isExporting', false);
      } else {
        this.set('isExporting', false);
        const msg = this.intl.t('no data to export');
        window.alert(msg);
      }

      onCloseDialogAction();
    }).drop(),

    setupFilename() {
      const period = this.period;
      const {
        dateStart,
        dateEnd
      } = period;
      const filename = `${this.intl.t('credit card transactions')} ${dateStart}-${dateEnd}`;
      this.set('filename', filename);
    },

    displayByMonth(period) {
      period.dateStart = this.dateService.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      period.dateEnd = this.dateService.formatDateForUrl(this.dateService.getMonthEnd(period.yyyy, period.mm));
      this.filters._data.dateEnd = period.dateEnd;
      this.filters._data.dateStart = period.dateStart;
      const updatePeriod = R.mergeRight(this.period, period);
      this.set('period', updatePeriod);
      const paymentCreditCardStatus = [this.get('constants.paymentCreditCardStatus.approved'), this.get('constants.paymentCreditCardStatus.refundRequested'), this.get('constants.paymentCreditCardStatus.refunded'), this.get('constants.paymentCreditCardStatus.paidOut')];
      this.listByTask.perform({
        adapterName: 'payments/card',
        filters: this.filters,
        dateStart: period.dateStart,
        dateEnd: period.dateEnd,
        paymentCreditCardStatus
      });
      this.fetchStatementTask.perform({
        dateStart: period.dateStart,
        dateEnd: period.dateEnd
      });
      this.set('mm', period.mm);
      this.set('yyyy', period.yyyy);
    }

  }, (_applyDecoratedDescriptor(_obj, "setupFilename", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupFilename"), _obj), _applyDecoratedDescriptor(_obj, "displayByMonth", [_dec2], Object.getOwnPropertyDescriptor(_obj, "displayByMonth"), _obj)), _obj)));

  _exports.default = _default;
});