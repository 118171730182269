define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-history-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e2iyQ1o2",
    "block": "{\"symbols\":[\"onHideDropdown\",\"history\",\"@type\",\"@selectedCommissionBaseCostMethod\",\"@campaignsDetailsModel\",\"@model\"],\"statements\":[[5,\"lists/list-btn\",[],[[\"@disabled\",\"@isRunning\",\"@onSubmit\",\"@list\"],[[22,\"isDisabled\"],[23,0,[\"fetchHistoryOnInitTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"fetchHistoryOnInitTask\"]]],null],[28,\"array\",[1],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"commissionBaseCostHistoryData\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"commissionBaseCostHistoryData\"]]],null,{\"statements\":[[0,\"      \"],[5,\"channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-history-selector/commission-base-cost-history-prices\",[],[[\"@history\",\"@onSelect\",\"@selectedCommissionBaseCostMethod\",\"@type\"],[[23,2,[]],[28,\"fn\",[[23,0,[\"select\"]],[23,6,[]],[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[]],[23,1,[]]],null],[23,4,[]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini has-text-centered\"],[8],[1,[28,\"tt\",[\"empty\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-history-selector/template.hbs"
    }
  });

  _exports.default = _default;
});