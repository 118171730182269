define("client/pods/components/channels/shop/campaigns/tab-container/component", ["exports", "ramda", "client/mixins/crud", "client/pods/components/channels/shop/campaigns/mixins/campaigns-list-by", "ramda-extension", "ramda-adjunct", "ember-concurrency", "ember-concurrency-decorators", "client/utils/nventor"], function (_exports, R, _crud, _campaignsListBy, R_, RA, _emberConcurrency, _emberConcurrencyDecorators, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_campaignsListBy.default, _crud.default, (_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    users: Ember.inject.service(),
    results: null,
    allowDuplicating: true,
    query: '',
    adapterName: 'channels/shop/campaigns',
    filtersAdapterName: 'channels/shop/campaigns/filters',
    showResults: true,
    showResultsWithStatus: false,
    menuBarList: Ember.A(['showResults', 'showResultsWithStatus']),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['automations/when', 'automations/criterias', 'channels/shop/campaigns/lists/menu', 'channels/shop/shipping-zones/all', 'contacts/lists/tags', 'discounts/types', 'products/lists/tags', 'products/lists/display-tags', 'products/lists/product-display-position', 'channels/shop/campaigns/lists/itemEditorSubPanels', 'statuses', 'availability', {
        name: 'workflows/lists/items/activities/templates',
        appendPath: 'authorization'
      }]);

      this._setupFilters();

      const upcomingCampaignStatusFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          campaignStatus: 'upcoming',
          count: 10
        }
      });
      this.set('upcomingCampaignStatusFilter', upcomingCampaignStatusFilter);
      const inProgressCampaignStatusFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          campaignStatus: 'inProgress',
          count: 10
        }
      });
      this.set('inProgressCampaignStatusFilter', inProgressCampaignStatusFilter);
      const expiredNoCommissionDataCampaignStatusFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          campaignStatus: 'expiredNoCommissionData',
          count: 10
        }
      });
      this.set('expiredNoCommissionDataCampaignStatusFilter', expiredNoCommissionDataCampaignStatusFilter);
      const expiredHaveCommissionDataCampaignStatusFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          campaignStatus: 'expiredHaveCommissionData',
          count: 10
        }
      });
      this.set('expiredHaveCommissionDataCampaignStatusFilter', expiredHaveCommissionDataCampaignStatusFilter);
      const noDateCampaignStatusFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          campaignStatus: 'noDate',
          count: 10
        }
      });
      this.set('noDateCampaignStatusFilter', noDateCampaignStatusFilter);
      const userKey = this.users.getUserKey();
      const campaignsBookmarkFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/members-campaign-menu/filters',
        defaultFiltersData: {
          sort: [{
            by: 'timestampZ_latest',
            direction: 'DESC'
          }],
          userKey,
          menuId: 'channels/shop/campaigns/panels-display'
        }
      });
      this.set('campaignsBookmarkFilters', campaignsBookmarkFilters);
      const pinnedCampaignFilter = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('pinnedCampaignFilter', pinnedCampaignFilter);
    },

    _setupFilters() {
      const hasThirdParty = R.pathEq(['tab', 'hasThirdParty'], true)(this);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          hasThirdParty
        }
      });
      this.set('filters', filters);
    },

    afterLoadLists() {
      this._super(...arguments);

      this.loadTabTask.perform();
    },

    /**
     * @param {HTMLDivElement} tabHeader
     * @void
     */
    updateStickyTop(tabHeader) {
      const top = tabHeader?.offsetTop;

      if (top) {
        tabHeader.style.top = `${top}px`;
      }
    },

    getCustomizedCampaignsBookmarkTask: (0, _emberConcurrency.task)(function* () {
      const campaignsBookmarkMemberMenu = yield this.crud.findTask.perform({
        adapterName: 'channels/shop/campaigns/members-campaign-menu/collection',
        filters: this.campaignsBookmarkFilters
      });
      this.set('campaignsBookmarkMemberMenu', campaignsBookmarkMemberMenu);
    }),
    resultsProxy: Ember.computed('synced', 'results', function () {
      const results = this.results || [];
      const userCampaignMenu = this?.campaignsBookmarkMemberMenu?.userCampaignMenu || [];
      const userCampaignMenuKey = R.pipe(R.pluck('_data'), R.reject(RA.isNilOrEmpty), R.pluck('_key'), R.reject(RA.isNilOrEmpty))(userCampaignMenu);
      return R.map(result => {
        const campaignKey = result.getData('_key') || '';

        if (R.includes(campaignKey, userCampaignMenuKey)) {
          result.setData('isPinned', true);
        }

        return result;
      })(results);
    }),

    *pinMenuTask(menu) {
      const campaignsBookmark = this.campaignsBookmark || [];
      const lastIndex = campaignsBookmark.length || 0;
      const isPinned = menu.getData('isPinned');
      const campaignKey = menu.getData('_key');
      const campaignName = menu.getData('name');

      if (isPinned) {
        const campaignsBookmarkMemberMenuCollection = this?.campaignsBookmarkMemberMenu?._data?.collection || [];
        const toRemove = R.find(R.pathEq(['_data', 'campaignKey'], campaignKey))(campaignsBookmarkMemberMenuCollection);
        menu.setData('isPinned', false);

        if (toRemove) {
          yield this.crud.removeRecordTask.perform({
            adapterName: 'channels/shop/campaigns/members-campaign-menu',
            model: toRemove,
            noConfirm: true
          });
        }
      } else {
        const userKey = this.users.getUserKey();
        menu.setData('userKey', userKey);
        menu.setData('menuId', 'channels/shop/campaigns/panels-display');
        menu.setData('index', lastIndex);
        menu.setData('campaignKey', campaignKey);
        menu.setData('campaignName', campaignName);
        yield this.crud.createRecordTask.perform({
          adapterName: 'channels/shop/campaigns/members-campaign-menu',
          model: menu
        });
      } // yield this.getCustomizedCampaignsBookmarkTask.perform()

    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      if (this.preloadedModel) {
        this.display(this.preloadedModel);
        return true;
      }

      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const model = loadInstructions.getData('model');

        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.findTask.perform({
            appendPath: id
          });
          this.display(found);
        }
      } else {
        const adapterName = this.adapterName;
        const filters = this.filters;
        yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters,
          resultsProperty: 'results'
        }); // @NOTE: going to dash takes too long
        // this.dash(results)

        yield this.getCustomizedCampaignsBookmarkTask.perform();
      }
    }),

    dash(results) {
      const menuActionsList = R_.dotPath('crud.lists.channels-shop-campaigns-lists-menu')(this) || [];
      const found = menuActionsList.findBy('_data.component', 'channels/shop/campaigns/panels-dash');

      if (!found) {
        return this.schedule();
      }

      if (!results) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        this.fetchDefaultDataTask.perform({
          adapterName,
          filters,
          resultsProperty: 'results'
        });
      }

      const tab = this.tab;
      const panelOptions = {
        component: 'channels/shop/campaigns/panels-dash'
      };
      return this.tabsManager.replaceSubTab(tab, panelOptions);
    },

    schedule() {
      this.set('showResults', false);
      const tab = this.tab;
      const panelOptions = {
        component: 'channels/shop/campaigns/panels-schedule'
      };
      this.tabsManager.replaceSubTab(tab, panelOptions);
    },

    display(model) {
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const tab = this.tab;
      const panelOptions = {
        component: 'channels/shop/campaigns/panels-display',
        model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
    },

    new(panelOptions) {
      this.set('showResults', false); // add new contact sub tab

      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, panelOptions);
    },

    edit(model) {
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.set('showResults', false);
      const tab = this.tab;
      const panelOptions = {
        component: 'channels/shop/campaigns/panels-edit',
        model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
    },

    actions: {
      dash() {
        this.set('showResults', false); // should deselect results if any..
        // this.deselectResult();

        this.dash();
      },

      schedule() {
        // should deselect results if any..
        // this.deselectResult();
        this.schedule();
      },

      display(model) {
        let {
          tabOptions
        } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        this.set('showResults', false);

        if (model) {
          this.display(...arguments);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },

      new(panelOptions) {
        this.new(...arguments);
      },

      editAutomationItem(automation, campaign, item) {
        this.set('showResults', false);
        const tab = this.tab;
        const panelOptions = {
          component: 'channels/shop/campaigns/campaigns-editor/automations-editor/then-editor-details/item-editor',
          campaign,
          automation,
          item
        };
        this.tabsManager.replaceSubTab(tab, panelOptions);
      }

    },
    displayPinnedCampaignTask: (0, _emberConcurrency.task)(function* (campaignKey) {
      const pinnedCampaignFilter = this?.pinnedCampaignFilter || {};

      if (RA.isNotNilOrEmpty(pinnedCampaignFilter)) {
        pinnedCampaignFilter.setData('campaignKey', campaignKey);
        const results = yield this.searchInputTask.perform({
          adapterName: this.adapterName,
          filters: pinnedCampaignFilter
        });

        const campaignModel = _nventor.default.safeHeadOr({}, results);

        if (RA.isNilOrEmpty(campaignModel)) {
          return;
        }

        this.display(campaignModel);
      }
    }),

    resetShowResult(type) {
      R.pipe(R.reject(menuBar => menuBar === type), R.forEach(menuBar => {
        this.set(menuBar, false);
      }))(this.menuBarList);
    },

    toggleSidebarMenuTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        type
      } = _ref;
      return function* () {
        const toggleValue = R.pipe(R.propOr('', type), R.clone)(_this);

        if (RA.isNotNilOrEmpty(toggleValue) && toggleValue == true) {
          if (type === 'showResultsWithStatus') {
            const upcomingCampaignStatusFilter = _this.upcomingCampaignStatusFilter;
            const inProgressCampaignStatusFilter = _this.inProgressCampaignStatusFilter;
            const expiredNoCommissionDataCampaignStatusFilter = _this.expiredNoCommissionDataCampaignStatusFilter;
            const expiredHaveCommissionDataCampaignStatusFilter = _this.expiredHaveCommissionDataCampaignStatusFilter;
            const noDateCampaignStatusFilter = _this.noDateCampaignStatusFilter;

            if (RA.isNilOrEmpty(upcomingCampaignStatusFilter?.results)) {
              yield _this.listByTask.perform({
                adapterName: _this.adapterName,
                filters: upcomingCampaignStatusFilter,
                resultsProperty: 'results'
              });
            }

            if (RA.isNilOrEmpty(inProgressCampaignStatusFilter?.results)) {
              yield _this.listByTask.perform({
                adapterName: _this.adapterName,
                filters: inProgressCampaignStatusFilter,
                resultsProperty: 'results'
              });
            }

            if (RA.isNilOrEmpty(expiredNoCommissionDataCampaignStatusFilter?.results)) {
              yield _this.listByTask.perform({
                adapterName: _this.adapterName,
                filters: expiredNoCommissionDataCampaignStatusFilter,
                resultsProperty: 'results'
              });
            }

            if (RA.isNilOrEmpty(expiredHaveCommissionDataCampaignStatusFilter?.results)) {
              yield _this.listByTask.perform({
                adapterName: _this.adapterName,
                filters: expiredHaveCommissionDataCampaignStatusFilter,
                resultsProperty: 'results'
              });
            }

            if (RA.isNilOrEmpty(noDateCampaignStatusFilter?.results)) {
              yield _this.listByTask.perform({
                adapterName: _this.adapterName,
                filters: noDateCampaignStatusFilter,
                resultsProperty: 'results'
              });
            }
          }

          if (type === 'showResults') {
            const filters = _this.filters;
            yield _this.fetchDefaultDataTask.perform({
              adapterName: _this.adapterName,
              filters,
              resultsProperty: 'results'
            });
          }

          _this.resetShowResult(type);
        }
      }();
    }),
    toggleCampaignStatusAndListByTask: (0, _emberConcurrency.task)(function* (campaignStatusToggled) {
      let newCampaignStatus = R.clone(campaignStatusToggled);
      const filters = this.filters;
      const currentCampaignStatus = filters.getData('campaignStatus') || '';

      if (currentCampaignStatus === campaignStatusToggled) {
        newCampaignStatus = '';
        filters.setData('campaignStatus', '');
      }

      yield this.listByTask.perform({
        campaignStatus: newCampaignStatus,
        filters,
        resultsProperty: 'results',
        component: this
      });
      this.resetShowResult('showResults');
    })
  }, (_applyDecoratedDescriptor(_obj, "pinMenuTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_obj, "pinMenuTask"), _obj), _applyDecoratedDescriptor(_obj, "edit", [_dec], Object.getOwnPropertyDescriptor(_obj, "edit"), _obj), _applyDecoratedDescriptor(_obj, "resetShowResult", [_dec2], Object.getOwnPropertyDescriptor(_obj, "resetShowResult"), _obj)), _obj)));

  _exports.default = _default;
});