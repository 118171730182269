define("client/pods/components/users/panels-new/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    adapterName: 'users',
    init: function () {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      const data = {
        type: this.get('constants.userTypes.employee'),
        status: this.get('constants.status.active'),
        changePassword: true,
        role: 'general' // @TODO: setup default role somewhere else

      };
      const attrs = {
        isNew: true
      };
      const model = this.setupNewRecord({
        data,
        attrs
      });
      this.set('model', model);
    }

  });

  _exports.default = _default;
});