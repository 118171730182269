define("client/pods/extensions/apps/tw/momo/logistics/service", ["exports", "ember-concurrency-decorators", "ramda"], function (_exports, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwShopeeLogisticsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ExtensionsAppsTwShopeeLogisticsService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _defineProperty(this, "adapterExtensionsAppsTwShopeeLogistics", 'extensions/apps/tw/momo/logistics');
    }

    *fetchShopeeOrdersLogistics(documents) {
      const refs = R.map(R.pathOr('', ['_data', 'ref']))(documents);
      const filters = this.search.setupFilters({
        adapterName: 'extensions/apps/tw/momo/logistics/filters',
        defaultFiltersData: {
          refs
        }
      });
      const shopeeLogistics = yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterExtensionsAppsTwShopeeLogistics,
        filters
      });
      return shopeeLogistics;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchShopeeOrdersLogistics", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchShopeeOrdersLogistics"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwShopeeLogisticsService;
});