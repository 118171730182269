define("client/initializers/froala-editor-elements", ["exports", "froala-editor"], function (_exports, _froalaEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* application */
  initialize() {
    _froalaEditor.default.DefineIcon('imageManager', {
      NAME: 'info',
      SVG_KEY: 'imageManager'
    });

    _froalaEditor.default.RegisterCommand('imageManager', {
      title: 'Image Uploader',
      focus: false,
      undo: false,
      refreshAfterCallback: false,

      callback() {
        this.component.showFileUploader();
      }

    });

    _froalaEditor.default.DefineIconTemplate('file_import', '<i class="fas fa-file-import" aria-hidden="true"></i>');

    _froalaEditor.default.DefineIcon('fileImportIcon', {
      NAME: 'fileImport',
      template: 'file_import'
    });

    _froalaEditor.default.RegisterCommand('fileImportButton', {
      title: 'file import',
      icon: 'fileImportIcon',
      // Save the button action into undo stack.
      undo: true,
      // Focus inside the editor before the callback.
      focus: true,
      // Show the button on mobile or not.
      showOnMobile: true,
      // Refresh the buttons state after the callback.
      refreshAfterCallback: false,
      // Called when the button is hit.
      callback: function () {// The current context is the editor instance.
        // console.log(this.html.get())
      },
      // Called when the button state might have changed.
      refresh: function ($btn) {// The current context is the editor instance.
        // console.log(this.selection.element())
      }
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});