define("client/pods/payments/card/transactions/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/payments/card/transactions/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const PaymentsCardTransactionsAdapterBase = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/payments/card/ccTemp',
    modelObj: _model.default,
    idParam: false
  });

  class PaymentsCardTransactionsAdapter extends PaymentsCardTransactionsAdapterBase {}

  _exports.default = PaymentsCardTransactionsAdapter;
});