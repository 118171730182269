define("client/pods/components/lists/list-dropdown-container/component", ["exports", "ramda-adjunct"], function (_exports, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['list-dropdown-container'],
    attributeBindings: ['disabled', 'tabindex'],
    autoPosition: false,
    hasModalOverlay: true,

    didRender() {
      this._super(...arguments);

      const dropdownContainer = this.$('.list-dropdown-container__content');

      if (_ramdaAdjunct.default.lengthGt(0, dropdownContainer) && this.autoPosition) {
        const height = dropdownContainer.outerHeight();
        const width = dropdownContainer.outerWidth();
        const top = dropdownContainer.offset().top;
        const left = dropdownContainer.offset().left;
        const right = left + width;
        const bottom = top + height;
        const viewportTop = this.$(window).scrollTop();
        const viewportRight = this.$(window).width();
        const viewportBottom = viewportTop + this.$(window).height() - 35;

        if (bottom > viewportBottom) {
          const topDifference = bottom - viewportBottom + 40;
          const maxTop = viewportTop + 130;
          const newTop = top - topDifference;

          if (newTop > maxTop) {
            dropdownContainer.css('marginTop', `-=${topDifference}px`);
          }
        }

        if (right > viewportRight) {
          const rightDifference = right - viewportRight + 40;
          const maxLeft = 5;
          const newLeft = left - rightDifference;

          if (newLeft > maxLeft) {
            dropdownContainer.css('marginLeft', `-=${rightDifference}px`);
          }
        }
      }
    }

  });

  _exports.default = _default;
});