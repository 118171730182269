define("client/pods/components/contacts/statement-details/statement-transactions/tx-commission/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UKRcflR3",
    "block": "{\"symbols\":[\"@data\",\"@txDateZ\",\"@cumulativeTotal\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"td\",true],[8],[1,[28,\"date\",[[23,2,[]]],null],false],[9],[0,\"\\n\\n  \"],[7,\"td\",true],[8],[9],[0,\"\\n\\n  \"],[7,\"td\",true],[8],[0,\"\\n    \"],[1,[28,\"tt\",[\"commissions\"],null],false],[0,\" \\n\"],[4,\"if\",[[23,1,[\"_data\",\"campaignName\"]]],null,{\"statements\":[[0,\"      (\\n        \"],[1,[23,1,[\"_data\",\"campaignName\"]],false],[0,\"\\n\"],[4,\"if\",[[23,1,[\"_data\",\"invoiceNumber\"]]],null,{\"statements\":[[0,\"          - \"],[1,[23,1,[\"_data\",\"invoiceNumber\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      )\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"isLessThan\",[[23,1,[\"_data\",\"grandCommissionTotal\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[1,[28,\"currency\",[[23,1,[\"_data\",\"grandCommissionTotal\"]]],null],false],[9],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[9],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[1,[28,\"currency\",[[23,1,[\"_data\",\"grandCommissionTotal\"]]],null],false],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"td\",true],[10,\"class\",\"has-text-right\"],[8],[0,\"\\n    \"],[1,[28,\"currency\",[[23,3,[]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/statement-details/statement-transactions/tx-commission/template.hbs"
    }
  });

  _exports.default = _default;
});