define("client/pods/modals/service", ["exports", "client/config/environment", "ramda", "ramda-adjunct"], function (_exports, _environment, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ModalsService = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class ModalsService extends Ember.Service {
    setupModal(_ref) {
      let {
        id,
        headerHeight = 46,
        footerHeight = 61,
        modalContentClassNames = '',
        modalDialogInitMinHeight = 100,
        modalDialogZIndex,
        modalOverLayZIndex,
        modalDialogZIndexOnFullScreen,
        modalOverLayZIndexOnFullscreen,
        isSidebar,
        hasExtraSidebar,
        sidebarModalMaxWidth,
        isMobile
      } = _ref;

      if (window.debug) {
        debugger; //eslint-disable-line
      }

      let modalDialog = document.getElementById(id);
      const overlay = document.getElementById(`overlay-${id}`);

      if (RA.isNilOrEmpty(modalDialog)) {
        return;
      }

      modalDialog.style.visibility = 'hidden';

      const parentElement = this._getParentElement({
        modalDialog,
        modalContentClassNames
      }); // prevent tooltip showing on list-btn (jumping modal dialog)


      const isTooltip = R.includes('is-tooltip', modalContentClassNames);

      if (isTooltip) {
        const hasOtherModalDialog = parentElement.closest('.modal-container__content.is-list-btn');

        if (hasOtherModalDialog) {
          return;
        }
      }

      const parentElementBoundingClientRect = parentElement.getBoundingClientRect(); // need to initialize in the beginning

      const positionY = this._getPositionY({
        parentElementBoundingClientRect,
        modalDialog,
        modalDialogInitMinHeight
      });

      const positionX = this._getPositionX({
        parentElementBoundingClientRect
      });

      modalDialog = this._setMaxHeight({
        modalDialog,
        parentElementBoundingClientRect,
        positionY,
        positionX,
        headerHeight,
        footerHeight,
        isSidebar,
        hasExtraSidebar,
        sidebarModalMaxWidth,
        isMobile
      });
      modalDialog = this._setPosition({
        modalDialog,
        parentElement,
        parentElementBoundingClientRect,
        positionY,
        positionX,
        headerHeight,
        isSidebar,
        modalContentClassNames
      });

      const modalSection = this._getModalContainerSection(modalDialog);

      if (overlay) {
        modalSection.appendChild(overlay);
      }

      modalSection.appendChild(modalDialog);
      modalDialog.style.visibility = '';
    }

    _getCurrentTab(element) {
      return element.closest('.tab-parent');
    }

    _getModalContainerSection(element) {
      const currentTab = this._getCurrentTab(element);

      let modalSection = document.querySelector('.global-modals');

      if (!currentTab) {
        return modalSection;
      }

      let modalContainerSelector = '.tab-modals-container';
      const isFullscreen = currentTab.querySelector('.is-fullscreen');

      if (isFullscreen) {
        modalContainerSelector = '.tab-fullscreen-modals-container';
        const fullScreenModalContainerSection = currentTab.querySelector(modalContainerSelector);

        if (!fullScreenModalContainerSection) {
          const newDiv = document.createElement('div');
          newDiv.classList.add('tab-fullscreen-modals-container');
          isFullscreen.append(newDiv);
          return newDiv;
        }
      }

      modalSection = currentTab.querySelector(modalContainerSelector);

      if (!modalSection) {
        const newDiv = document.createElement('div');
        newDiv.classList.add('tab-modals-container');
        currentTab.append(newDiv);
        modalSection = newDiv;
      }

      return modalSection;
    } // @action
    // setupOverlay (
    //   {
    //     modalOverLayZIndex,
    //     modalOverLayZIndexOnFullscreen
    //   },
    //   element
    // ) {
    //   element.classList.add('tab-grid__modal-overlay', 'modal-overlay')
    //   // const currentTab = this._getCurrentTab(element)
    //   const modalSection = this._getModalContainerSection(element)
    //   modalSection.appendChild(element)
    //   // if (currentTab) {
    //   //   const modalSection = currentTab.lastElementChild
    //   //   const hasModalFullscreen = currentTab.querySelector('.tab-grid__modal--fullscreen')
    //   //   const hasPosFullscreen = currentTab.querySelector('.pos-view--fullscreen')
    //   //   const hasModalSidebar = currentTab.lastElementChild.querySelector('.modal-sidebar')
    //   //   const onSidebar = element.closest('.app-grid__sidebar')
    //   //   // if (hasModalFullscreen || hasPosFullscreen || onSidebar || hasModalSidebar) {
    //   //   //   element.style.zIndex = modalOverLayZIndexOnFullscreen
    //   //   // } else {
    //   //   //   element.style.zIndex = modalOverLayZIndex
    //   //   // }
    //   //   modalSection.appendChild(element)
    //   // } else {
    //   //   element.style.zIndex = modalOverLayZIndexOnFullscreen
    //   //   const modalSection = document.querySelector('.global-modals.no-tab')
    //   //   if (modalSection) {
    //   //     modalSection.appendChild(element)
    //   //   }
    //   // }
    //   // app-grid__content
    //   document.querySelector('.modal-overlay').addEventListener('wheel', function (e) {
    //     e.preventDefault()
    //   }, { passive: false })
    // }


    closeDialog(_ref2) {
      let {
        component,
        prop
      } = _ref2;
      Ember.set(component, prop, false);
    } // this should be deprecated
    // @deprecated


    closeOverlay(_ref3) {
      let {
        component,
        isShowOverlay,
        onToggleModalDialog,
        onToggle
      } = _ref3;

      if (isShowOverlay) {
        if (onToggleModalDialog) {
          onToggleModalDialog();
        }

        if (onToggle) {
          onToggle();
        }
      }
    }

    _getParentElement(_ref4) {
      let {
        modalContentClassNames,
        modalDialog
      } = _ref4;
      const isListDropdown = R.includes('is-list-dropdown', modalContentClassNames);
      const isListBtn = R.includes('is-list-btn', modalContentClassNames);
      const isListSearcher = R.includes('is-list-searcher', modalContentClassNames);
      const isTooltip = R.includes('is-tooltip', modalContentClassNames);
      let parentElement = modalDialog.parentElement;

      if (isListBtn || isListSearcher) {
        let parentElementBtn = parentElement;

        while (parentElementBtn) {
          if (parentElementBtn.classList.contains('button')) {
            break;
          }

          parentElementBtn = parentElementBtn.previousElementSibling;
        }

        parentElement = parentElementBtn;

        if (RA.isNilOrEmpty(parentElement)) {
          parentElement = modalDialog.parentElement;
        }
      }

      if (isListDropdown) {
        let hasListDropdown = false;

        if (RA.isNotNilOrEmpty(parentElement)) {
          hasListDropdown = parentElement?.classList?.contains('list-dropdown');
        }

        if (!hasListDropdown) {
          parentElement = modalDialog.closest('.list-dropdown').querySelector('button');
        }

        if (RA.isNilOrEmpty(parentElement)) {
          parentElement = modalDialog.closest('.list-dropdown').querySelector('span');
        }

        if (RA.isNilOrEmpty(parentElement)) {
          parentElement = modalDialog.closest('.list-dropdown');
        }

        if (RA.isNilOrEmpty(parentElement)) {
          parentElement = modalDialog.previousElementSibling;
        }
      }

      if (isTooltip) {
        parentElement = modalDialog.closest('.info-tooltip');

        if (RA.isNilOrEmpty(parentElement)) {
          parentElement = modalDialog.parentElement.parentElement;
        }
      } // prevent modalDialog without parentElement


      if (RA.isNilOrEmpty(parentElement)) {
        parentElement = modalDialog.parentElement;
      }

      return parentElement;
    }

    _getPositionY(_ref5) {
      let {
        parentElementBoundingClientRect,
        modalDialog,
        modalDialogInitMinHeight = 100
      } = _ref5;
      const parentElementTop = parentElementBoundingClientRect.top;
      const parentElementBottom = parentElementBoundingClientRect.bottom;
      const modalDialogBoundingClientRect = modalDialog.getBoundingClientRect();
      const modalDialogHeight = modalDialogBoundingClientRect.height;
      const screenHeight = document.documentElement.clientHeight;
      const screenHeightWithoutFooter = screenHeight - 60;
      const topSpace = parentElementTop - 75; // 75px navbar

      const bottomSpace = screenHeightWithoutFooter - parentElementBottom;
      let minHeight = modalDialogHeight - 10;

      if (minHeight >= modalDialogInitMinHeight) {
        minHeight = modalDialogInitMinHeight;
      }

      if (bottomSpace >= minHeight) {
        return 'bottom';
      }

      if (topSpace >= minHeight) {
        return 'top';
      }

      return 'center';
    }

    _getPositionX(_ref6) {
      let {
        parentElementBoundingClientRect
      } = _ref6;
      const parentElementLeft = parentElementBoundingClientRect.left;
      const parentElementRight = parentElementBoundingClientRect.right;
      const screenWidth = document.documentElement.clientWidth;
      const leftSpace = parentElementLeft;
      const rightSpace = screenWidth - parentElementRight;

      if (leftSpace > rightSpace) {
        return 'left';
      }

      return 'right';
    }

    _setMaxHeight(_ref7) {
      let {
        modalDialog,
        parentElementBoundingClientRect,
        positionY,
        positionX,
        headerHeight = 46,
        footerHeight = 61,
        isSidebar,
        hasExtraSidebar,
        sidebarModalMaxWidth,
        isMobile
      } = _ref7;
      const parentElementTop = parentElementBoundingClientRect.top;
      const parentElementBottom = parentElementBoundingClientRect.bottom;
      const parentElementLeft = parentElementBoundingClientRect.left;
      const parentElementRight = parentElementBoundingClientRect.right;
      const screenWidth = document.documentElement.clientWidth;
      const screenHeight = document.documentElement.clientHeight;
      const modalContainerContent = modalDialog.querySelector('.modal-container__content');

      if (!modalContainerContent) {
        return modalDialog;
      }

      let maxHeight;
      let maxWidth;

      if (positionY === 'bottom') {
        maxHeight = screenHeight - parentElementBottom - footerHeight - 5 - 5; // 5px each for margin between parent and footer
      }

      if (positionY === 'top') {
        maxHeight = parentElementTop - headerHeight - 5 - 5; // 5px each for margin between parent and footer
      }

      if (positionX === 'right') {
        maxWidth = screenWidth - parentElementLeft - 15; // 15 for right margin
      }

      if (positionX === 'left') {
        maxWidth = parentElementRight - 15; // 15 for right margin
      }

      let isOversized = false;

      if (positionY === 'center') {
        isOversized = true;
        maxHeight = screenHeight - 30 - headerHeight;
        maxWidth = screenWidth - 30;
        modalContainerContent.style.width = 'max-content';
      }

      modalDialog.isOversized = isOversized; // @TODO: this does not belong here? should side bar be a general or specific solution?
      // ie. should size be set on each side bar? then modal does not need to adjust width. only check if it exceeds max width
      // the contents should be what determines width

      if (isSidebar) {
        maxHeight = screenHeight - headerHeight;
        const defaultsidebarModalMaxWidth = screenWidth * 0.98;
        sidebarModalMaxWidth = sidebarModalMaxWidth || defaultsidebarModalMaxWidth;
        const sidebarModalFullSize = screenWidth; // - (screenWidth * 0.01)

        maxWidth = sidebarModalFullSize;

        if (maxWidth > sidebarModalMaxWidth) {
          maxWidth = sidebarModalMaxWidth;
        }

        if (isMobile) {
          maxWidth = sidebarModalFullSize;
        } // modalContainerContent.style.width = `${maxWidth}px`


        modalContainerContent.style.height = `${maxHeight}px`;
      }

      modalContainerContent.style.maxHeight = `${maxHeight}px`;
      modalContainerContent.style.maxWidth = `${maxWidth}px`;
      return modalDialog;
    }

    _setPosition(_ref8) {
      let {
        modalDialog,
        parentElement,
        parentElementBoundingClientRect,
        positionY,
        positionX,
        headerHeight,
        isSidebar,
        modalContentClassNames = ''
      } = _ref8;
      modalDialog.style.position = 'fixed';
      const parentElementTop = parentElementBoundingClientRect.top;
      const parentElementBottom = parentElementBoundingClientRect.bottom;
      const parentElementLeft = parentElementBoundingClientRect.left;
      const parentElementRight = parentElementBoundingClientRect.right;
      const screenWidth = document.documentElement.clientWidth;
      const screenHeight = document.documentElement.clientHeight;
      isSidebar = isSidebar || false;
      const isOversized = modalDialog?.isOversized || false;

      if (isSidebar) {
        modalDialog.style.top = `${headerHeight}px`;
        modalDialog.style.right = '0px';
        setTimeout(() => {
          // remove slide-in-right after animation
          modalDialog.classList.remove('slide-in-right');
        }, 1000);
      } else if (isOversized) {
        modalDialog.style.top = `calc(50% + ${headerHeight}px)`;
        modalDialog.style.left = '50%'; // modalDialog.style.transform = `translate(-50%, calc(-50% - ${headerHeight / 2}px))`
      } else {
        if (positionY === 'bottom') {
          modalDialog.style.top = `${parentElementBottom + 5}px`;
        }

        if (positionY === 'top') {
          modalDialog.style.bottom = `${screenHeight - (parentElementTop - 15)}px`;
        }

        if (positionX === 'right') {
          // if parent is partially outside screen
          // if (parentElementLeft < 0) {
          //   modalDialog.style.left = `15px`
          // } else {
          modalDialog.style.left = `${parentElementLeft}px`; // }
        }

        if (positionX === 'left') {
          let rightMargin = 0;
          const isListDropdown = R.includes('is-list-dropdown', modalContentClassNames);

          if (isListDropdown) {
            const firstChild = parentElement.firstElementChild;

            if (firstChild) {
              const style = window.getComputedStyle(firstChild);
              rightMargin = parseFloat(style.marginRight || 0);
            }
          }

          const isListBtn = R.includes('is-list-btn', modalContentClassNames);

          if (isListBtn) {
            const style = window.getComputedStyle(parentElement);
            rightMargin = -parseFloat(style.marginRight || 0);
          } // if parent is partially outside screen
          // if (parentElementRight > screenWidth) {
          //   modalDialog.style.right = `15px`
          // } else {


          modalDialog.style.right = `${screenWidth - parentElementRight + rightMargin}px`; // }
        }
      }

      return modalDialog;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setupModal", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setupModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeDialog", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "closeDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeOverlay", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "closeOverlay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_getParentElement", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_getParentElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_getPositionY", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_getPositionY"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_getPositionX", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "_getPositionX"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_setMaxHeight", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "_setMaxHeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_setPosition", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "_setPosition"), _class.prototype)), _class));
  _exports.default = ModalsService;
});