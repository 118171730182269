define("client/pods/pikaday/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * stores to localstorage using the store.js library.
   * will use other methods in localstorage is not available
   */
  //@TODO: test
  var _default = Ember.Service.extend({
    setup(options) {
      options = options || {};
      return new Pikaday(options);
    }

  });

  _exports.default = _default;
});