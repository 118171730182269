define("client/helpers/notEmpty", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params) {
      const obj = params[0];
      const prop = params[1];
      const value = obj.get(prop);

      if (R.is(Array, value) || R.is(Object)) {
        return !R.isEmpty(value);
      }

      if (value) {
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});