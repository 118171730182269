define("client/pods/components/workflows/create-dynamic/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "client/utils/nventor", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WorkflowsCreateDynamicComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('workflows'), _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('users'), _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class WorkflowsCreateDynamicComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dataManager", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "workflowsService", _descriptor4, this);

      _initializerDefineProperty(this, "constants", _descriptor5, this);

      _initializerDefineProperty(this, "usersService", _descriptor6, this);

      _initializerDefineProperty(this, "storage", _descriptor7, this);

      _initializerDefineProperty(this, "workflow", _descriptor8, this);

      _initializerDefineProperty(this, "workflowStage", _descriptor9, this);

      _initializerDefineProperty(this, "workflowStageId", _descriptor10, this);

      _initializerDefineProperty(this, "workflowItem", _descriptor11, this);

      _initializerDefineProperty(this, "workflowItemActivity", _descriptor12, this);

      _initializerDefineProperty(this, "selectedWorkflowKey", _descriptor13, this);

      _initializerDefineProperty(this, "selectedAction", _descriptor14, this);

      _defineProperty(this, "workflowDefaultName", this.intl.t('select workflow'));

      _defineProperty(this, "workflowStageDefaultName", this.intl.t('select stage'));

      this.crud.addLists(this, ['users/related', 'workflows/lists/create-dynamic']);
    }

    get workflowStageName() {
      // return 'no stage selected'
      return R.pipe(R.pathOr([{}], ['workflow', '_data', 'stages']), R.find(R.pathEq(['_data', 'stageId'], this.workflowStageId)), R.pathOr(this.workflowStageDefaultName, ['_data', 'name']))(this);
    }

    get workflowName() {
      return R.pipe(R.pathOr([{}], ['workflowsService', 'workflows']), R.find(R.pathEq(['_data', '_key'], this.selectedWorkflowKey)), R.pathOr(this.workflowDefaultName, ['_data', 'name']))(this);
    }

    compactModeSetup() {
      const commentModel = R.path(['args', 'model'])(this);

      if (!commentModel) {
        return null;
      }

      this.workflowItem = this.workflowsService.newWorkflowItem({
        workflow: this.workflow
      });
      this.setDefaultsWorkflowItem(commentModel);
    }

    setDefaultsWorkflowItem(comment) {
      const description = R.pathOr('', ['_data', 'message'])(comment);
      const username = R.pathOr('', ['_data', 'username'])(comment); // chryspii requested a todo from this.args.type

      const translatedSection = this.intl.t('requested a todo from');
      const translatedType = this.intl.t(this.args.type);
      const title = `@${username} ${translatedSection} ${translatedType}`;
      this.workflowItem.setData('title', title);
      this.workflowItem.setData('description', description);
      this.workflowItem.setData('stageId', this.workflowStageId);
      let resource = this.args.type;
      let docType = this.args.metaData?.docType || '';
      let docName = this.args.metaData?.docName || '';

      if (this.args.type === 'documents-public') {
        resource = this.args.subType;
        const subTypeArray = R.split('-', resource);
        docType = _nventor.default.safeHeadOr('', subTypeArray);
        docName = _nventor.default.safeLastOr('', subTypeArray);
      } else if (this.args.type === 'documents') {
        resource = `${docType}-${docName}`;
      }

      let resourceValue = this.args.metaData?.name || '';

      if (this.args.type === 'documents') {
        resourceValue = this.args.metaData?.docNo || '';
      }

      const related = this.dataManager.setAsRecord({
        adapterName: 'workflows/related',
        data: {
          resource,
          resourceValue,
          resourceKey: this.args.master,
          docType,
          docName
        }
      });
      this?.workflowItem?._data?.related?.pushObject(related);
    }

    truncate() {
      let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let truncateBy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;
      let append = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '...';
      const p = document.createElement('p');
      p.innerHTML = str;
      const strWithoutHtmlTags = p.innerText;
      return R.when(R.propSatisfies(R.gt(R.__, truncateBy), 'length'), R.pipe(R.take(truncateBy), R.append(append), R.join('')))(strWithoutHtmlTags);
    }

    setDefaultsWorkflowItemActivity(comment) {
      let title = R.pathOr(R.pathOr('', ['_data', 'message'])(comment), ['_data', 'description'])(this?.workflowItem || {});
      const description = R.pathOr('', ['_data', 'message'])(comment);
      const key = R.pathOr('', ['_data', '_key'])(comment);
      title = this.truncate(title);
      this.workflowItemActivity.setData('title', title);
      this.workflowItemActivity.setData('description', description);
      let resource = this.args.type;
      let docType = this.args.metaData?.docType || '';
      let docName = this.args.metaData?.docName || '';

      if (this.args.type === 'documents-public') {
        resource = this.args.subType;
        const subTypeArray = R.split('-', resource);
        docType = _nventor.default.safeHeadOr('', subTypeArray);
        docName = _nventor.default.safeLastOr('', subTypeArray);
      } else if (this.args.type === 'documents') {
        resource = `${docType}-${docName}`;
      }

      let resourceValue = this.args.metaData?.name || '';

      if (this.args.type === 'documents') {
        resourceValue = this.args.metaData?.docNo || '';
      }

      const related = this.dataManager.setAsRecord({
        adapterName: 'workflows/related',
        data: {
          resource,
          resourceValue,
          resourceKey: this.args.master,
          docType,
          docName
        }
      });
      this?.workflowItemActivity?._data?.related?.pushObject(related); // ADD COMMENT

      const commentRelated = {
        resource: 'comment',
        resourceValue: description,
        resourceKey: key
      };
      this?.workflowItemActivity?._data?.related?.pushObject(commentRelated);
    }

    setWorkflow(selected, workflow) {
      if (!selected) {
        this.workflow = null;
        return null;
      }

      this.workflow = workflow; // Auto select first stage from workflow

      const firstStage = R.path(['_data', 'stages', 0])(workflow);

      if (firstStage) {
        this.setWorkflowStage(firstStage);
      }

      if (this?.args?.model) {
        const workflowKey = R.path(['_data', '_key'])(workflow);
        this.args.model.setData('workflowKey', workflowKey);
      }
    }

    setWorkflowStage(stage) {
      this.workflowStage = stage;
      this.workflowStageId = R.path(['_data', 'stageId'])(stage);

      if (this.args.compactMode) {
        this.compactModeSetup();
      }
    }

    onCreateContact(comment) {
      if (this.workflow) {
        this.createWorkflowItemsAndActivities.perform(comment);
      }
    }

    *createWorkflowItemsAndActivities(comment, onCloseDialogAction) {
      if (!this.args.compactMode) {
        this.workflowItem = this.workflowsService.newWorkflowItem({
          workflow: this.workflow
        });
        this.setDefaultsWorkflowItem(comment);
      } // TODO: CREATE WORKFLOW ITEM


      this.workflowItem = yield this.workflowsService.createWorkflowItem.perform({
        model: this.workflowItem
      });
      this.workflowItemActivity = this.workflowsService.newWorkflowItemActivity({
        workflow: this.workflow,
        item: this.workflowItem,
        type: this.constants.workflowsItemsActivitiesTypes.todo
      });
      this.setDefaultsWorkflowItemActivity(comment); // TODO: CREATE WORKFLOW ITEM ACTIVITY

      this.workflowItemActivity = yield this.workflowsService.createWorkflowItemActivity.perform({
        model: this.workflowItemActivity
      }); // TODO: UPDATE COMMENT WITH WORKFLOW DATA
      // TODO: PERHAPS ADD THIS LOGIC ON THE API

      if (this.args.compactMode) {
        const dirtyComment = this.crud.setupDirty({
          adapterName: 'comments',
          model: comment
        });
        yield this.crud.replaceRecordTask.perform({
          adapterName: 'comments',
          model: dirtyComment
        });
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    *searchWorkflowsDropTask() {
      yield this.workflowsService.searchWorkflows.perform();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "workflowsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "usersService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "workflow", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "workflowStage", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "workflowStageId", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "workflowItem", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "workflowItemActivity", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "selectedWorkflowKey", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'workflows';
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "selectedAction", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setWorkflow", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setWorkflow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setWorkflowStage", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setWorkflowStage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCreateContact", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onCreateContact"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createWorkflowItemsAndActivities", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createWorkflowItemsAndActivities"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchWorkflowsDropTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchWorkflowsDropTask"), _class.prototype)), _class));
  _exports.default = WorkflowsCreateDynamicComponent;
});