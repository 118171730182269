define("client/pods/components/transporters/sender-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rqwkrKVo",
    "block": "{\"symbols\":[\"@model\",\"@isReadonly\",\"@onSave\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"_data\",\"transporterExtension\"]],[23,0,[\"constantsService\",\"transporterExtensions\",\"sevenEleven\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"extensions/apps/tw/seven-eleven/sender-details\",[],[[\"@model\",\"@isReadonly\",\"@onSave\"],[[23,1,[]],[23,2,[]],[23,3,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"_data\",\"transporterExtension\"]],[23,0,[\"constantsService\",\"transporterExtensions\",\"hct\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[5,\"extensions/apps/tw/hct/sender-details\",[],[[\"@model\",\"@isReadonly\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"_data\",\"transporterExtension\"]],[23,0,[\"constantsService\",\"transporterExtensions\",\"tCat\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"extensions/apps/tw/t-cat/sender-details\",[],[[\"@model\",\"@isReadonly\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n\\n  \"],[5,\"extensions/apps/tw/t-cat/sender-details/extra\",[],[[\"@model\",\"@isReadonly\"],[[23,1,[]],[23,2,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/sender-details/template.hbs"
    }
  });

  _exports.default = _default;
});