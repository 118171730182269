define("client/pods/components/extensions/apps/tw/shopee/orders/table-view/order-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LEgq/Vg3",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"th\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"th\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"th\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n    \"],[1,[23,1,[\"_data\",\"qty\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"th\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n    \"],[5,\"extensions/apps/tw/shopee/products/link\",[],[[\"@model\",\"@readonlyInput\"],[[23,1,[]],true]]],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"th\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"th\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"th\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n    \"],[1,[23,1,[\"_data\",\"price\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"th\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n    \"],[1,[23,1,[\"_data\",\"inclPrice\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"th\",true],[10,\"class\",\"has-text-centered\"],[8],[0,\"\\n    \"],[1,[23,1,[\"_data\",\"shopeeCode\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/shopee/orders/table-view/order-detail/template.hbs"
    }
  });

  _exports.default = _default;
});