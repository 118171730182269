define("client/pods/components/products/table-view/detail-row/sets-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    didInsertElement() {
      this._super(...arguments);

      if (this.isEditing || this.isNew) {
        const model = this.model;
        model.newBlankSetDetail();
      }
    },

    isReadonlyAndAvailable: Ember.computed('model._data.isSet', 'isReadonly', function () {
      if (!this.get('model._data.isSet')) {
        return true;
      }

      return this.isReadonly;
    }),
    actions: {
      loadProduct(model, detail, product) {
        if (model.hasDuplicateSetDetailsProduct(product)) {
          const msg = this.intl.t('duplicate product');
          window.alert(msg);
          model.resetSetDetail(detail);
        } else {
          model.loadChildProduct(detail, product);
          model.newBlankSetDetail();
        }
      },

      resetSetDetail(model, detail) {
        model.resetSetDetail(detail);
      },

      removeSetDetail(model, detail) {
        const msg = this.intl.t('are you sure you want to remove');

        if (window.confirm(msg)) {
          const model = this.model;
          model.removeSetDetail(detail);
        }
      }

    }
  });

  _exports.default = _default;
});