define("client/pods/guests/shop/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    guests: Ember.inject.service(),

    model(params) {
      const guestResource = params.guest_resource;
      this.guests.setup(guestResource);
      this.guests.redirectToNewWay();
      return;
    }

  });

  _exports.default = _default;
});