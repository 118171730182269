define("client/pods/components/documents/tab-container/component", ["exports", "ramda", "ramda-extension", "ramda-adjunct", "client/mixins/crud", "client/mixins/doc-list-by", "client/utils/nventor", "client/pods/components/documents/docConfig", "ember-concurrency", "client/pods/documents/proxy/model"], function (_exports, R, R_, RA, _crud, _docListBy, _nventor, _docConfig, _emberConcurrency, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, _docListBy.default, (_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    uploadcareService: Ember.inject.service('uploadcare'),
    subTabsCopy: null,
    filtersAdapterName: 'documents/filters',
    posShopKey: null,
    products: null,
    shop: null,
    enableAutoIssueEInvoice: true,
    isReadyToImportMomoOrders: false,

    init() {
      this._super(...arguments);

      const tab = this.tab;

      const docConfigData = _docConfig.default.getFor(tab);

      const {
        docType,
        docName
      } = docConfigData;
      this.crud.addLists(this, ['dispatch/lists/processes', 'units', 'taxes', 'taxMethods', 'contacts/lists/tags', 'discounts/types', 'dispatch/lists/processes', 'documents/lists/discountmethods', 'documents/lists/filters/statuses', 'documents/lists/filters/dispatch-status', 'documents/lists/print', 'documents/lists/print/unit', 'documents/lists/print/product-code', 'paymentMethods', 'paymentTerms', 'paymentTerms/from', 'paymentTerms/end', 'documents/lists/deliverydatesoptions', 'dispatch/lists/processes', 'extensions/apps/tw/invoicingAuto', 'toggles/onOff', 'shipping/deliveryTimes', {
        adapterName: 'documents/lists/menu',
        appendPath: `${docType}/${docName}`,
        options: {
          docType,
          docName
        }
      }, {
        adapterName: 'documents/lists/menu/pos',
        appendPath: `${docType}/${docName}`,
        options: {
          docType,
          docName
        }
      }, {
        adapterName: 'users',
        appendPath: 'internal'
      }]);
      this.set('config', docConfigData);
      this.set('tab.tabs', []);
      const title = tab.get('docType') + '-' + tab.get('docName');
      this.set('docTitle', title);
      this.set('adapterName', this.get('config.adapterName'));
      this.set('docType', docType);
      this.set('docName', docName);
      const period = this.getPeriod();
      this.set('period', period);
      const isPos = R.pathEq(['tab', 'isPos'], true)(this);
      const defaultFiltersData = {
        isPos
      };
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData
      });
      this.set('filters', filters);
    },

    loadTabTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        tab
      } = _ref;
      return function* () {
        _this.set('isReadyToImportMomoOrders', true); // move to panel display


        const loadInstructions = _this.tabsManager.getLoadInstructions(tab);

        if (loadInstructions) {
          // const model = loadInstructions.getData('model')
          // if (model) {
          //   this.display(loadInstructions.getData('model'))
          // } else {
          //   const id = loadInstructions.getData('find.id')
          //   if (id) {
          //     const found = yield this.findTask.perform({
          //       appendPath: id
          //     })
          //     this.display(found)
          //   }
          //   const query = loadInstructions.getData('search.query')
          //   if (query) {
          //     filters.setData('query', query)
          //     const results = yield this.searchRecordsTask.perform({ filters })
          //     const found = results.get('firstObject')
          //     this.display(found)
          //   }
          // }
          _this.display();
        } else {
          const adapterName = _this.adapterName;
          const filters = _this.filters;
          const results = yield _this.fetchDefaultDataTask.perform({
            adapterName,
            filters
          });

          _this.set('results', results);

          _this.dash({}, results);

          _this.fetchAndSetDefaultsTask.perform();
        }

        _this.setupPos.perform();
      }();
    }),
    fetchAndSetDefaultsTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      const adapterName = this.adapterName;
      const filters = this.filters;
      const results = yield this.fetchDefaultDataTask.perform({
        adapterName,
        filters
      });
      this.set('results', results);
      this.dash({}, results);

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }),
    setupPos: (0, _emberConcurrency.task)(function () {
      var _this2 = this;

      let {
        setup = true
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        if (!setup) {
          return;
        }

        const isPos = R.pathEq(['tab', 'isPos'], true)(_this2);

        if (isPos) {
          _this2.setProductsFilters();

          _this2.fetchPosCampaigns.perform();

          const newDocSales = R.pipe(R.propOr([], 'documentsListsMenu'), R.find(R.pathEq(['_data', 'label'], 'new')))(_this2);

          if (newDocSales) {
            _this2.tabsManager.triggerMenuAction(_this2, newDocSales);
          }
        }
      }();
    }).drop(),
    fetchPosCampaigns: (0, _emberConcurrency.task)(function* () {
      // @TODO: this is a temporary fix for the fact that the campaign list is not available in the menu
      const campaignFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/filters',
        defaultFiltersData: {
          hasPos: true
        }
      });
      yield this.crud.searchInputTask.perform({
        filters: campaignFilters,
        adapterName: 'channels/shop/campaigns',
        resultsProperty: 'shopsList',
        component: this,
        allowEmptyQuery: true
      });
      const shop = R.path(['shopsList', 0])(this);
      this.set('shop', shop);
      const defaultPosShop = R.path(['_data', '_key'])(shop);
      this.set('posShopKey', defaultPosShop);
      this.fetchProductsByShopTask.perform({
        shop
      });
    }).drop(),
    fetchProductsByShopNavTask: (0, _emberConcurrency.task)(function* (shop) {
      return yield this.fetchProductsByShopTask.perform({
        shop
      });
    }),

    /**
     * Fetch campaigns details
     */
    fetchProductsByShopTask: (0, _emberConcurrency.task)(function () {
      var _this3 = this;

      let {
        filters,
        shop
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let shopKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return function* () {
        if (shop) {
          _this3.set('shop', shop);
        } else {
          shop = _this3.shop;
        }

        shopKey = shopKey || shop?._data?._key;
        filters = filters || _this3.search.setupFilters({
          adapterName: 'channels/shop/campaigns/details/filters',
          defaultFiltersData: {
            master: shopKey,
            resultsProperty: 'results',
            resultsAdapterName: 'channels/shop/campaigns/details',
            appendPath: `/master/${shopKey}`,
            statuses: ['active', 'inactive', 'preOrder', 'soldOut', 'draft'],
            sort: [{
              by: 'index',
              direction: 'ASC'
            }],
            excludes: [{
              key: 'setType',
              value: 'isSetWithChoices'
            }, {
              key: 'setType',
              value: 'hasVariants'
            }],
            count: 50
          }
        });

        _this3.set('posOptionsFilters', filters);

        let appendPath = `/master/${shopKey}`;

        if (!shopKey) {
          appendPath = filters?._data?.appendPath;
        }

        const campaignDetails = yield _this3.crud.searchRecordsTask.perform({
          adapterName: 'channels/shop/campaigns/details',
          appendPath,
          filters
        });

        _this3.set('campaignDetails', campaignDetails);
      }();
    }),

    setProductsFilters() {
      const filters = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          excludes: [{
            key: 'setType',
            value: 'isSetWithChoices'
          }, {
            key: 'setType',
            value: 'hasVariants'
          }]
        }
      });
      this.set('productFilters', filters);
    },

    /**
     * Fetch products
     */
    fetchProductsTask: (0, _emberConcurrency.task)(function () {
      var _this4 = this;

      let {
        filters
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        filters = filters || _this4.setProductsFilters();

        _this4.set('productFilters', filters);

        const products = yield _this4.crud.searchRecordsTask.perform({
          adapterName: 'products',
          filters
        });

        _this4.set('products', products);
      }();
    }),

    afterLoadLists() {
      this._super(...arguments);

      const tab = this.tab;
      this.set('listsIsLoaded', Math.random());
      this.loadTabTask.perform({
        tab
      });
    },

    isDispatchedProcessModel: Ember.computed('crud.lists.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const isDispatched = this.get('constants.dispatchProcess.dispatched');
      return R.find(R.pathEq(['_data', 'code'], isDispatched), dispatchProcesses);
    }),
    updateDocInfoTask: (0, _emberConcurrency.task)(function* (dirty) {
      const adapterName = this.adapterName;
      return yield this.updateRecordTask.perform({
        adapterName,
        appendPath: '/info',
        model: dirty
      });
    }).drop(),
    documentsListsMenu: Ember.computed('listsIsLoaded', 'docType', 'docName', function () {
      const docType = this.docType;
      const docName = this.docName;
      const isPos = R.pathEq(['tab', 'isPos'], true)(this);
      let listMenu = this.get(`crud.lists.documents-lists-menu--${docType}-${docName}`) || [];

      if (isPos) {
        listMenu = this.get(`crud.lists.documents-lists-menu-pos--${docType}-${docName}`) || [];
      }

      return listMenu;
    }),
    resultsProxy: Ember.computed('results.[]', 'filters.results.[]', function () {
      const results = this?.filters?.results || []; // let results = this.results || []
      // if (RA.isNotNilOrEmpty(filterResults)) {
      //   results = filterResults
      // }

      return R.map(result => _nventor.default.createModel(_model.default, {}, {
        model: result,
        isSelected: false,
        showDetails: false // @TODO: get this from settings

      }), results);
    }),

    dash(model, results) {
      const tab = this.tab;
      this.set('showResults', false);

      if (_nventor.default.isNilOrEmpty(results)) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        filters.setData('query', '');
        results = this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
        this.set('results', results);
      }

      const subTabOptions = {
        component: 'documents/panel-dash'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    /**
     * display document
     * @param  {object} document model
     */
    display(model) {
      this.set('showResults', false);
      const component = this.get('config.displayComponent');
      const tab = this.tab;
      const isNew = tab.isNew;
      const isFromShortcut = tab.isFromShortcut || false;
      const isFromPendingShortcut = tab.isFromPendingShortcut || false;
      const isFromContactShortcut = tab.isFromContactShortcut || false;
      const isFromDocument = tab.isFromDocument || false;
      const isDuplicateDocumentShortcut = tab.isDuplicateDocumentShortcut || false;
      const isFromOutstandingQty = tab.isFromOutstandingQty || false;
      const isFromToInvoiceQty = tab.isFromToInvoiceQty || false;
      const isLinkingNeeded = RA.isNilOrEmpty(tab.isLinkingNeeded) ? true : tab.isLinkingNeeded;
      let subTabOptions;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (tab?.isNew) {
        tab.set('isNew', false);
        return this.new(); // subTabOptions = {
        //   component: this.get('config.newComponent'),
        //   loadInstructions,
        //   pendingDoc: model,
        //   isFromShortcut,
        //   isFromPendingShortcut,
        //   isFromContactShortcut,
        //   isFromDocument,
        //   isDuplicateDocumentShortcut,
        //   isFromOutstandingQty,
        //   isFromToInvoiceQty,
        //   isLinkingNeeded,
        //   config: this.config
        // }
        // tab.set('isFromShortcut', false)
      } else {
        subTabOptions = {
          component: component,
          model: model,
          loadInstructions,
          config: this.config
        };
      }

      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    new() {
      this.set('showResults', false);
      const self = this;
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructionsAndClear(tab);
      const component = self.get('config.newComponent');
      const subTabOptions = {
        component: component,
        config: self.get('config'),
        loadInstructions
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
      this.setupPos.perform({
        setup: false
      });
    },

    actions: {
      dash() {
        this.dash();
      },

      display(model) {
        this.set('showResults', false);

        if (model) {
          this.display(model);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },

      new() {
        this.new(...arguments);
      },

      close(panel) {
        this.tab.closePanel(panel);
      },

      edit(model) {
        this.set('showResults', false);
        const self = this;
        const component = self.get('config.editComponent');
        const subTabOptions = {
          component: component,
          model: model,
          config: self.get('config')
        };
        const tab = this.tab;
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "setProductsFilters", [_dec], Object.getOwnPropertyDescriptor(_obj, "setProductsFilters"), _obj)), _obj)));

  _exports.default = _default;
});