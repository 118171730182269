define("client/pods/guests/service", ["exports", "client/config/environment", "client/utils/nventor", "ramda"], function (_exports, _environment, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),
    server: Ember.inject.service(),
    storage: Ember.inject.service(),
    router: Ember.inject.service(),
    host: _environment.default.APP.serverUrl,
    shop: _environment.default.APP.shopUrl,
    resource: '',

    setup(resource) {
      this.set('resource', resource);
    },

    call(method, path) {
      let data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const token = this.getToken();
      return this.server.call(method, path, data, token);
    },

    setToken(token) {
      return this.storage.setWithPrefix(this.resource, 'guestToken', token);
    },

    /**
     * Set to local storage
     * @param {{key: string, value: any}} args
     */
    setToLocalStorage(_ref) {
      let {
        key,
        value
      } = _ref;
      return this.storage.setWithPrefix(this.resource, key, value);
    },

    /**
     * Get to local storage
     * @param {{key: string}} args
     */
    getToLocalStorage(_ref2) {
      let {
        key
      } = _ref2;
      return this.storage.getWithPrefix(this.resource, key);
    },

    isNewWayLogin() {
      return this.getToLocalStorage({
        key: 'isNewWay'
      });
    },

    redirectToNewWay() {
      const url = `${this.shop}/${this.resource}/influencer`;
      window.open(url, '_self');
    },

    getToken() {
      const params = _nventor.default.parseParams(window.location);

      const tokenParams = R.prop('token')(params);
      const isNewWay = R.propEq('isNewWay', 'true')(params);

      if (tokenParams) {
        this.setToLocalStorage({
          key: 'isNewWay',
          value: isNewWay
        });
        this.setToken(tokenParams); // Remove token from url

        window.history.pushState({}, document.title, window.location.pathname);
        return tokenParams;
      }

      return this.storage.getWithPrefix(this.resource, 'guestToken');
    },

    getTokenData() {
      const token = this.getToken();
      const tokenData = this.auth.parseToken(token);
      return tokenData;
    },

    logout() {
      this.setToken('');
      const resource = this?.router?.currentRoute?.params?.guest_resource;

      if (resource) {
        return this.router.transitionTo('guests.shop.login', resource);
      }

      return this.router.transitionTo('/');
    }

  });

  _exports.default = _default;
});