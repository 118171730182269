define("client/pods/components/extensions/apps/tw/shopee/batch/status/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwShopeeBatchStatusComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class ExtensionsAppsTwShopeeBatchStatusComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "modalDialogIsVisible", _descriptor, this);
    }

    toggleModalDialogIsVisible(onCloseDialogAction, value) {
      if (value === false) {
        this.modalDialogIsVisible = false;
      } else if (value === true) {
        this.modalDialogIsVisible = true;
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    handleBatchStatusUpdate(batch) {
      const hasUnsuccessful = R.pipe(R.propOr([], 'unsuccessful'), RA.isNotNilOrEmpty)(batch);

      if (hasUnsuccessful) {
        this.toggleModalDialogIsVisible(null, true);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modalDialogIsVisible", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleModalDialogIsVisible", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleModalDialogIsVisible"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleBatchStatusUpdate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleBatchStatusUpdate"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwShopeeBatchStatusComponent;
});