define("client/pods/components/modals/modal-dialog/modal-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xubMf9Sn",
    "block": "{\"symbols\":[\"@titleSuffix\",\"@title\",\"&default\",\"@onClose\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"modal-dialog__header u-no-print\"],[8],[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"u-full-width u-no-print\"],[8],[0,\"\\n\"],[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[14,3],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"tt\",[[23,2,[]]],[[\"translate\"],[[23,0,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"        \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"      \"],[7,\"td\",true],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"delete is-small is-pulled-right\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"close\"]]],null]]],[8],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/modals/modal-dialog/modal-header/template.hbs"
    }
  });

  _exports.default = _default;
});