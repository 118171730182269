define("client/pods/components/extensions/apps/tw/e-invoices/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "APfjKuhd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"hasStatement\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"panel-grid__header-title u-header-1\"],[8],[0,\"\\n        \"],[1,[24,[\"period\",\"dateStart\"]],false],[0,\" ~ \"],[1,[24,[\"period\",\"dateEnd\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasStatement\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[8],[0,\"\\n          \"],[1,[28,\"extensions/apps/tw/e-invoices/panels-display/panels-statement\",null,[[\"statement\",\"onFetchStatementTask\",\"period\"],[[24,[\"statement\"]],[24,[\"fetchStatementTask\"]],[24,[\"period\"]]]]],false],[0,\"\\n        \"],[9],[0,\"        \\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"extensions/apps/tw/e-invoices/panels-display/panels-activity\",null,[[\"hasManagementBtns\",\"period\",\"model\",\"filters\",\"onListByTask\",\"results\",\"exportResults\",\"count\",\"page\",\"onFetchUnpaginatedDataTask\",\"onPaginateSearchRecordsTask\",\"onRequestCancellationTask\",\"onUndoCancellationTask\"],[[24,[\"hasManagementBtns\"]],[24,[\"period\"]],[24,[\"model\"]],[24,[\"filters\"]],[24,[\"listByTask\"]],[24,[\"results\"]],[24,[\"exportResults\"]],[24,[\"count\"]],[24,[\"page\"]],[24,[\"fetchUnpaginatedDataTask\"]],[24,[\"paginateSearchRecordsTask\"]],[24,[\"requestCancellationTask\"]],[24,[\"undoCancellationTask\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/e-invoices/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});