define("client/pods/components/products/sync-modal/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductsSyncModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = (0, _emberConcurrencyDecorators.task)({
    maxConcurrency: 20
  }), _dec8 = (0, _emberConcurrencyDecorators.dropTask)(), (_class = class ProductsSyncModalComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "filters", _descriptor3, this);

      _initializerDefineProperty(this, "errors", _descriptor4, this);

      _initializerDefineProperty(this, "campaignsDetailsFilters", _descriptor5, this);

      _defineProperty(this, "campaignsAdapter", 'channels/shop/campaigns/sync/campaigns');

      _defineProperty(this, "campaignsFilterAdapter", 'channels/shop/campaigns/sync/products/filters');

      this.crud.addLists(this, ['channels/shop/campaigns/statuses', 'channels/shop/campaigns/lists/filters/status', 'products/lists/sync-search']);
    }

    get preFetch() {
      return R.pathOr(false, ['args', 'preFetch'])(this);
    }

    get results() {
      const product = this.args.model;
      return [product];
    }

    get isRunning() {
      return this.syncModalSearchRecordsTask.isRunning || this.syncProductOnCampaigns.isRunning;
    }

    setup() {
      const itemKey = R.pathOr('', ['args', 'model', '_data', '_key'])(this);
      const price = R.pathOr('', ['args', 'model', '_data', 'salesInclPrice'])(this);
      this.filters = this.search.setupFilters({
        adapterName: this.campaignsFilterAdapter,
        defaultFiltersData: {
          resultsProperty: 'results',
          itemKey,
          campaignSyncAll: {
            price
          }
        }
      });
      this.campaignsDetailsFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/filters',
        defaultFiltersData: {
          itemKey,
          resultsProperty: 'results',
          resultsAdapterName: 'channels/shop/campaigns/details',
          appendPath: `/advanced`
        }
      });

      if (this.preFetch) {
        this.syncModalSearchRecordsTask.perform();
      }
    } //@TODO: ADD HORIZONTAL SCROLL BINDING


    setScrollBinding() {
      // products__sync-modal--product-detail-row
      // products__sync-modal--campaign-detail-row
      document.querySelector('.products__sync-modal--product-detail-row').onscroll = _ref => {
        let {
          target
        } = _ref;
        return document.querySelector('.products__sync-modal--campaign-detail-row').scrollLeft = target.scrollLeft;
      };
    }

    resetErrors() {
      this.errors = null;
    }

    *syncModalSearchRecordsTask() {
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.campaignsAdapter,
        filters: this.filters
      });
      this.filters.setDefaultCampaignSync();
    }

    *syncProductOnCampaigns(onCloseDialogAction) {
      this.resetErrors();
      const syncCampaignModel = this.filters.getSyncCampaignModel();
      yield this.crud.updateRecordTask.perform({
        adapterName: this.campaignsAdapter,
        model: syncCampaignModel,
        component: this
      });

      if (this.errors) {
        return;
      }

      this.filters.resetCampaignSync();

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "campaignsDetailsFilters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "syncModalSearchRecordsTask", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "syncModalSearchRecordsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "syncProductOnCampaigns", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "syncProductOnCampaigns"), _class.prototype)), _class));
  _exports.default = ProductsSyncModalComponent;
});