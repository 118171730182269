define("client/pods/channels/shop/campaigns/bulk-discounts/rules/model", ["exports", "ramda", "client/pods/base/model", "client/mixins/sortable-details"], function (_exports, R, _model, _sortableDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_sortableDetails.default, {
    toSortProp: '_data.value',

    defaults() {
      return {
        discountType: this.constants.discountTypes.rate,
        discountAmount: '',
        discountRate: '',
        when: this.constants.automationsWhen.itemQty,
        criteria: this.constants.automationsCriterias.greaterThanOrEqualTo,
        value: '',
        description: '',
        isShowLineDescription: true,
        isDefaultDescription: true,
        hasMemberLevels: false,
        memberLevelId: '',
        status: this.constants.status.active
      };
    },

    populate(data, attrs) {
      const discountType = R.propOr(this.constants.discountTypes.rate, 'discountType')(data);

      if (discountType === this.constants.discountTypes.rate) {
        data.discountAmount = '';
      }

      if (discountType === this.constants.discountTypes.amount) {
        data.discountRate = '';
      }

      const isDefaultDescription = R.propOr(false, 'isDefaultDescription')(data);
      attrs.description = R.propOr('', 'description')(data);

      if (isDefaultDescription) {
        attrs.description = this.getBulkDiscountDescription({
          data
        });
      }

      this._super(data, attrs);
    },

    // should be the same as the shop getBulkDiscountDescription
    getBulkDiscountDescription(_ref) {
      let {
        data
      } = _ref;
      const when = data.when;
      const criteria = data.criteria;
      const criteriaValue = data.value;
      const discountType = data.discountType;
      let discountValue = data.discountAmount;
      let discountDesc = `$ ${discountValue}`;

      if (discountType === 'rate') {
        discountValue = data.discountRate;
        discountDesc = `${discountValue}%`;
      }

      if (criteria && criteriaValue && discountType && discountValue) {
        // 10% discount when buy items greater than or equal to 4
        let whenDescription = '';

        if (when === 'itemQty') {
          whenDescription = this.intl.t('discount when buy items');
        } // 10% discount when items value greater than or equal to 4


        if (when === 'itemValue') {
          whenDescription = this.intl.t('discount when items value');
        }

        return `${discountDesc} ${whenDescription} ${this.intl.t(criteria)} ${criteriaValue}`;
      }

      return '';
    },

    statusClass: Ember.computed('_data.status', function () {
      const status = this.getData('status');

      if (status === this.constants.status.active) {
        return 'is-primary';
      }

      return 'is-danger';
    })
  });

  _exports.default = _default;
});