define("client/pods/components/elements/date-input/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ElementsDateInputComponent = (_dec = Ember.inject.service('date'), _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ElementsDateInputComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dateService", _descriptor, this);

      _initializerDefineProperty(this, "dateValue", _descriptor2, this);

      _defineProperty(this, "DEBOUNCE_MS", 450);

      const dateConstraint = this.args?.dateConstraint;
      const todayZ = this.dateService.getNowZ();

      if (dateConstraint) {
        const todayStartZ = this.dateService?.getStartOfDayZ(todayZ);
        const min = parseInt(dateConstraint?.min); // unit: day(s)

        if (min >= 0) {
          const daysAfterZ = this.dateService.getDaysAfterZ?.(todayStartZ, min);
          this.dateMin = this.dateService.formatDateForUrl?.(daysAfterZ);
        }

        const max = parseInt(dateConstraint?.max);

        if (max > 0) {
          const daysAfterZ = this.dateService.getDaysAfterZ?.(todayStartZ, min);
          this.dateMax = this.dateService.formatDateForUrl?.(daysAfterZ);
        }
      }
    }

    get dateZ() {
      const dateValue = this.dateValue;

      if (dateValue) {
        const dateZ = this.dateService.getMoment?.(dateValue)?.toISOString();
        return dateZ;
      }

      return '';
    }

    get initDate() {
      const dateInputType = this.dateInputType || 'date';
      const date = this?.args?.date;

      if (dateInputType === 'date' && date) {
        return this.dateService.formatDate(date, 'YYYY-MM-DD');
      }

      if (dateInputType === 'datetime-local' && date) {
        return this.dateService.formatDate(date, 'YYYY-MM-DDTHH:mm');
      }

      return '';
    } // @DO NOT REMOVE THIS SETTER
    // Ember built-in Input tag would try to set 'value', which is this.initDate
    // if there's only getter, it cannot be set by ```obj[key] = ...```, which would throw an error


    set initDate(value) {
      return value;
    }

    selectDate(element) {
      this.value = element?.target?.value || '';
      const value = this.value; // should also perform when value = '' (clear date)

      this.debounceTask.perform(value);
      const model = this.args?.model;
      const target = this.args?.target;

      if (model && target) {
        let dateZ = '';

        if (value) {
          dateZ = this.dateService.getMoment(value).toISOString();
        } // should use target without _data


        Ember.set(model, target, dateZ);

        if (this.args.onSetDate) {
          this.args.onSetDate(dateZ);
        }
      }
    }

    focusOut(element) {
      if (R.is(Function)(this.args.onFocusOut)) {
        this.value = element?.target?.value || '';
        const value = this.value;
        const target = this.args?.target;

        if (target) {
          let dateZ = '';

          if (value) {
            dateZ = this.dateService.getMoment(value).toISOString();
          } // should use target without _data


          this.args.onFocusOut(dateZ);
        }
      }
    }

    *debounceTask(value) {
      yield (0, _emberConcurrency.timeout)(this.DEBOUNCE_MS);
      const instruct = this.args?.instruct;
      const onSelectDate = this.args?.onSelectDate; // do not assign dateZ to a variable here
      // since it is a getter and will vary with dateValue

      if (instruct === 'start' && onSelectDate) {
        this.dateValue = value;
        onSelectDate({
          dateStartString: this.dateZ
        });
      } else if (instruct === 'end' && onSelectDate) {
        this.dateValue = value;
        onSelectDate({
          dateEndString: this.dateZ
        });
      } else if (onSelectDate) {
        const filters = this.args?.filters;
        this.dateValue = value;
        onSelectDate(this.dateZ, {
          filters
        });
      }
    }

    get dateInputType() {
      return this.args?.dateInputType || 'date';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateValue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectDate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusOut", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "focusOut"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "debounceTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "debounceTask"), _class.prototype)), _class));
  _exports.default = ElementsDateInputComponent;
});