define("client/pods/components/files/images-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ndp0tk60",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"elements/element-btn\",null,[[\"classNames\",\"label\",\"onSubmit\"],[\"images-uploader__btn\",[24,[\"label\"]],[28,\"action\",[[23,0,[]],\"selectUploads\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"elements/element-inputfile\",null,[[\"classNames\",\"onChange\"],[\"files-uploader__input\",[28,\"action\",[[23,0,[]],\"uploadsChanged\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showProgress\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"images-uploader__progress-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"images-uploader__progress-bar\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"msgs/error-msg\",null,[[\"hasError\",\"msg\"],[[24,[\"hasErrors\"]],[24,[\"error\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/images-uploader/template.hbs"
    }
  });

  _exports.default = _default;
});