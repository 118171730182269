define("client/pods/channels/shop/campaigns/lists/page-import-btn-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'campaigns',
        label: 'campaigns',
        component: 'channels/shop/campaigns/page-import-btn/campaigns-results'
      }, {
        value: 'campaignsDetails',
        label: 'campaign products',
        component: 'channels/shop/campaigns/page-import-btn/campaigns-details-results'
      }, {
        value: 'products',
        label: 'products',
        component: 'products/page-import-btn/products-results'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});