define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/is-set-with-choices/is-set-with-choices-detail/component", ["exports", "client/mixins/crud", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _crud, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    campaignsProducts: Ember.inject.service(),
    big: Ember.inject.service(),
    tagName: '',

    init() {
      this._super(...arguments);

      this.set('showSetDetails', false);
      const isSet = R.pathEq(['child', '_data', 'isSet'], true, this);

      if (isSet) {
        this.set('showSetDetails', true);
      }
    },

    showSetSetDetails: Ember.computed('showSetDetails', 'choice._data.isSetPriceOnBundle', 'isSetNew', function () {
      const showSetDetails = this.showSetDetails;
      const isSetPriceOnBundle = this.get('choice._data.isSetPriceOnBundle');
      const isSetNew = this.isSetNew;

      if (showSetDetails) {
        if (isSetPriceOnBundle && isSetNew) {
          return false;
        }

        if (!isSetNew) {
          return false;
        }

        return true;
      }

      return false;
    }),
    setDetailsData: Ember.computed('model._data.setDetails.@each._data', function () {
      const detailsData = R.pipe(R_.dotPathOr([], 'model._data.setDetails'), R.map(detail => {
        const price = R_.dotPathOr('', '_data')(detail);
        return price;
      }))(this);
      return detailsData;
    }),
    setPriceEqualsDetailsPrice: Ember.computed('model._data.{price,priceDiscounted}', 'setDetailsData.@each.{price,priceDiscounted}', function () {
      const setPrice = Number(this.model._data.price) || 0;
      const setPriceDiscounted = Number(this.model._data.priceDiscounted) || 0; // const setCommissionBaseCost = Number(this.model._data.commissionBaseCost) || 0

      let detailsPrice = this.big.newBig(0);
      let detailsPriceDiscounted = this.big.newBig(0); // const detailsCommissionBaseCost = this.big.newBig(0)

      R.forEach(detail => {
        const price = R_.dotPathOr('', 'price')(detail);
        const priceDiscounted = R_.dotPathOr('', 'priceDiscounted')(detail); // const commissionBaseCost = R_.dotPathOr('', 'commissionBaseCost')(detail)

        const qty = R_.dotPathOr('', 'qty')(detail);
        detailsPrice = detailsPrice.plus(this.big.newBig(price).times(qty));
        detailsPriceDiscounted = detailsPriceDiscounted.plus(this.big.newBig(priceDiscounted).times(qty)); // detailsCommissionBaseCost = detailsCommissionBaseCost.plus(this.big.newBig(commissionBaseCost).times(qty))
      })(this.setDetailsData);
      const priceIsEqual = R.equals(RA.floor(setPrice), RA.floor(detailsPrice.toFixed(0)));
      const priceDiscountedIsEqual = R.equals(RA.floor(setPriceDiscounted), RA.floor(detailsPriceDiscounted.toFixed(0))); // const commissionBaseCostIsEqual = R.equals(RA.floor(setCommissionBaseCost), RA.floor(detailsCommissionBaseCost.toFixed(0)))

      return priceIsEqual && priceDiscountedIsEqual;
    }),
    toggleBtnLabel: Ember.computed('child.{_data.isSet,hasVariants}', function () {
      if (this.get('child._data.isSet')) {
        return 'product_sets_contents';
      }

      return 'variants';
    }),
    isDisabled: Ember.computed('choice._data.isSetPriceOnBundle', function () {
      return this.get('choice._data.isSetPriceOnBundle');
    }),
    isSetNew: Ember.computed('child._data.isSetNew', function () {
      if (this.get('child._data.isSetNew')) {
        return this.get('child._data.isSetNew');
      }

      return false;
    }),
    isSetWithChoicesPriceRequired: Ember.computed('isSetNew', 'choice._data.isSetPriceOnBundle', 'child._data.isSet', function () {
      const isSetPriceOnBundle = this.get('choice._data.isSetPriceOnBundle');
      const isSetNew = this.isSetNew;
      const isSet = this.get('child._data.isSet');

      if (isSetPriceOnBundle) {
        return false;
      }

      if (isSet) {
        if (isSetNew) {
          return false;
        }

        return true;
      }

      return true;
    }),
    isSetWithChoicesPriceDiscountedRequired: Ember.computed('item._data.{onSale}', 'isSetNew', 'choice._data.isSetPriceOnBundle', function () {
      const isSetPriceOnBundle = this.get('choice._data.isSetPriceOnBundle');
      const isSetNew = this.isSetNew;
      const isSet = this.get('child._data.isSet');
      const itemOnSale = this.get('item._data.onSale');

      if (isSetPriceOnBundle) {
        return false;
      }

      if (isSet && isSetNew) {
        return false;
      } else {
        if (itemOnSale === 'on') {
          return true;
        }

        return false;
      }
    })
  });

  _exports.default = _default;
});