define("client/pods/components/social-login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lF4sN8+u",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@model\",\"@link\",\"@isReadonly\"],\"statements\":[[5,\"lists/list-btn\",[],[[\"@disabled\",\"@icon\",\"@label\"],[[23,4,[]],\"fas fa-share\",\"report\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"field has-addons\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"_data\",\"hash\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[10,\"class\",\"control\"],[8],[0,\"\\n          \"],[5,\"helpers/new-browser-tab-link\",[],[[\"@link\"],[[28,\"concat\",[[23,3,[]],\"?token=\",[23,2,[\"_data\",\"hash\"]]],null]]],{\"statements\":[[0,\"\\n            \"],[5,\"elements/element-btn\",[],[[\"@icon\",\"@label\"],[\"fas fa-share\",\"report\"]]],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"control\"],[8],[0,\"\\n          \"],[5,\"elements/element-btn\",[],[[\"@isRunning\",\"@icon\",\"@tabindex\",\"@translate\",\"@label\",\"@onSubmit\"],[[23,0,[\"isRunning\"]],\"far fa-copy\",-1,false,[28,\"concat\",[[28,\"tt\",[\"report link\"],null],\": \",[23,2,[\"_data\",\"hash\"]]],null],[28,\"fn\",[[23,0,[\"applicationService\",\"copyText\"]],[28,\"concat\",[[23,3,[]],\"?token=\",[23,2,[\"_data\",\"hash\"]]],null],false],null]]]],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"p\",true],[10,\"class\",\"control\"],[8],[0,\"\\n        \"],[5,\"elements/element-btn\",[],[[\"@label\",\"@isRunning\",\"@icon\",\"@onSubmit\"],[\"regenerate access code\",[23,0,[\"isRunning\"]],\"fas fa-sync-alt\",[28,\"perform\",[[23,0,[\"updateRecordTask\"]]],null]]]],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/social-login/template.hbs"
    }
  });

  _exports.default = _default;
});