define("client/pods/components/forms/form-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9dnVKQib",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"customBlock\"]]],null,{\"statements\":[[0,\"\\n  \"],[14,1],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"tt\",[[24,[\"label\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,1],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isRequired\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"u-input-required\"],[8],[0,\"*\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"documentationResource\"]]],null,{\"statements\":[[0,\"    \"],[5,\"documentations/documentation\",[],[[\"@options\"],[[28,\"hash\",null,[[\"documentationId\",\"documentationResource\",\"context\"],[[24,[\"documentationId\"]],[24,[\"documentationResource\"]],[24,[\"context\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[1,[28,\"tt\",[[24,[\"label\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isRequired\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"u-input-required\"],[8],[0,\"*\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"documentationResource\"]]],null,{\"statements\":[[0,\"    \"],[5,\"documentations/documentation\",[],[[\"@options\"],[[28,\"hash\",null,[[\"documentationId\",\"documentationResource\",\"context\"],[[24,[\"documentationId\"]],[24,[\"documentationResource\"]],[24,[\"context\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/forms/form-label/template.hbs"
    }
  });

  _exports.default = _default;
});