define("client/pods/components/extensions/apps/tw/seven-eleven/accounts-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwSevenElevenAccountsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class ExtensionsAppsTwSevenElevenAccountsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "model", _descriptor2, this);

      _initializerDefineProperty(this, "isMinRequiredMet", _descriptor3, this);

      _defineProperty(this, "adapterName", 'base');

      this.setup();
    }

    setup() {
      const isEditing = this.args.isEditing === true;

      if (isEditing) {
        this.model = this.crud.setupDirty({
          adapterName: this.adapterName,
          model: this.args.model
        });
      } else {
        this.model = this.crud.setupNewRecord({
          adapterName: this.adapterName
        });
      }
    }

    submit() {
      if (this.model.isDirty) {
        const originalModel = this.args.model;
        const data = this.model._data;
        originalModel.set('_data', data);
        this.args.onUpdateItem(originalModel);
      } else {
        this.args.onAddItem(this.model);
      }
    }

    validateMinRequiredMet() {
      const minRequired = ['name', 'shopId', 'subShopId', 'printPassword'];
      const allPass = minRequired.map(requireField => {
        return R.pipe(R.path(['model', '_data', requireField]), RA.isNotNilOrEmpty)(this);
      });
      this.isMinRequiredMet = R.all(R.equals(true))(allPass);
    }

    delete() {}

    cancel(model) {
      this.args.onCloseDialogAction();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isMinRequiredMet", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateMinRequiredMet", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "validateMinRequiredMet"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwSevenElevenAccountsEditorComponent;
});