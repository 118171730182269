define("client/pods/components/news/search-bar/search-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TTPx/z5F",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[4,\"if\",[[24,[\"results\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\",true],[10,\"class\",\"table-form u-full-width\"],[8],[0,\"\\n    \"],[1,[28,\"products/search-bar/search-results/search-row\",null,[[\"isHeaders\"],[true]]],false],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"results\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"products/search-bar/search-results/search-row\",null,[[\"isDetailRow\",\"tab\",\"item\",\"onSelect\"],[true,[24,[\"tab\"]],[23,1,[]],[24,[\"onSelect\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/news/search-bar/search-results/template.hbs"
    }
  });

  _exports.default = _default;
});