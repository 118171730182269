define("client/pods/components/extensions/apps/tw/seven-eleven/report-status-days/additional-info/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtensionsAppsTwSevenElevenReportStatusDaysAdditionalInfoComponent extends _component.default {}

  _exports.default = ExtensionsAppsTwSevenElevenReportStatusDaysAdditionalInfoComponent;
});