define("client/pods/components/comments/tab-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopRewardsTabContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, (_class = class ChannelsShopRewardsTabContainerComponent extends _component.default {
    constructor() {
      super(...arguments); // this.crud.addLists(this, [
      //   {
      //     name: 'taxes',
      //     appendPath: 'products'
      //   },
      //   'filters/count',
      //   'filters/criterias',
      //   'products/lists/menu',
      //   'products/inventory/lists/qtyTypes',
      //   'products/lists/invoiceDescriptions',
      //   'products/lists/tags',
      //   'products/lists/tracking',
      //   'products/lists/types',
      //   'products/lists/setTypes',
      //   'products/lists/setTypesFilters',
      //   'settings/roundingAuto',
      //   'statuses',
      //   'toggles/yesNo',
      //   'units',
      //   'units/dimensions',
      //   'units/weight'
      // ])

      _initializerDefineProperty(this, "constants", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "search", _descriptor3, this);

      _initializerDefineProperty(this, "dataManager", _descriptor4, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor5, this);

      _initializerDefineProperty(this, "filters", _descriptor6, this);

      _initializerDefineProperty(this, "resultsToggle", _descriptor7, this);

      _defineProperty(this, "adapterName", 'comments/contacts');

      _defineProperty(this, "filtersAdapterName", 'comments/contacts/filters');

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          fetchWithActiveCampaigns: true
        }
      });
      this.filters = filters;
      this.loadTabTask.perform();
    } // afterLoadLists () {
    //   this.super(...arguments)
    //   this.loadTabTask.perform()
    // }


    *loadTabTask() {
      const tab = this.args.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const model = loadInstructions.getData('model');

        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.crud.findTask.perform({
            adapterName: this.adapterName,
            appendPath: id
          });
          const filters = this.filters;
          filters.set('results', RA.list(found));
          filters.setData('query', found.getData('docNo'));
          this.display(found);
        }
      } else {
        const adapterName = this.adapterName;
        const filters = this.filters;
        yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
      }
    }

    filterDefaultDataTask(_ref) {
      var _this = this;

      let {
        status
      } = _ref;
      return function* () {
        const adapterName = _this.adapterName;
        const filters = _this.filters || {};

        if (RA.isNotNilOrEmpty(filters)) {
          const originalStatus = filters.getData('status') || '';

          if (R.equals(status, originalStatus)) {
            filters.setData('status', '');
          } else {
            filters.setData('status', status);
          }
        }

        yield _this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
        const subTabs = _this?.args?.tab?.subTabs || [];

        if (RA.isNotNilOrEmpty(subTabs) && RA.isNotNilOrEmpty(subTabs[0])) {
          _this.tabsManager.closeTab(subTabs, subTabs[0]);
        }
      }();
    }

    fetchDefaultDataTask(_ref2) {
      var _this2 = this;

      let {
        adapterName,
        filters
      } = _ref2;
      return function* () {
        yield _this2.crud.searchRecordsTask.perform({
          adapterName: `${_this2.adapterName}`,
          filters: _this2.filters
        });
      }();
    }
    /**
     * display the product
     * @param  {object} product model
     */


    display(model) {
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const tab = this.args.tab;
      const panelOptions = {
        component: 'comments/panels-display',
        model: model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "resultsToggle", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadTabTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadTabTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterDefaultDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "filterDefaultDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchDefaultDataTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDefaultDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "display", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype)), _class));
  _exports.default = ChannelsShopRewardsTabContainerComponent;
});