define("client/pods/products/variants/model", ["exports", "client/pods/base/model", "client/mixins/products-children", "client/mixins/sortable-details"], function (_exports, _model, _productsChildren, _sortableDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_productsChildren.default, _sortableDetails.default, {
    toSortProp: '_data.options',
    indexProp: '_data.index',
    toSortArray: Ember.computed('_data.options.[]', 'synced', function () {
      return this.get('_data.options');
    }),

    defaults() {
      return {};
    }

  });

  _exports.default = _default;
});