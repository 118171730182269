define("client/pods/extensions/apps/tw/e-invoices/tracks/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate(data, attrs) {
      data = this.transformTrackNumbers(data);

      this._super(data, attrs);

      return data;
    },

    transformTrackNumbers(data) {
      data.trackNumbers = R.pipe(R.propOr([], 'trackNumbers'), R.map(trackNumber => {
        trackNumber = this.transformAllocatedNumbers(trackNumber);
        return this.dataManager.setAsRecord({
          adapterName: 'extensions/apps/tw/eInvoices/tracks/numbers',
          data: trackNumber
        });
      }))(data);
      return data;
    },

    transformAllocatedNumbers(data) {
      data.allocatedNumbers = R.pipe(R.propOr([], 'allocatedNumbers'), R.map(allocatedNumber => {
        return this.dataManager.setAsRecord({
          adapterName: 'extensions/apps/tw/eInvoices/tracks/numbers/allocated',
          data: allocatedNumber
        });
      }))(data);
      return data;
    },

    current: Ember.computed('_data.current', function () {
      return this._data.current;
    }),
    index: Ember.computed('_data.index', function () {
      return this._data.index;
    })
  });

  _exports.default = _default;
});