define("client/pods/components/documents/panel-refund/component", ["exports", "client/mixins/date", "client/mixins/settings", "client/mixins/crud", "client/pods/components/documents/mixins/pre-submit", "client/pods/components/documents/mixins/details"], function (_exports, _date, _settings, _crud, _preSubmit, _details) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, _details.default, _settings.default, _crud.default, _preSubmit.default, {
    crud: Ember.inject.service(),
    adapterName: 'documents/sales/returns',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['taxes']);
    },

    actions: {
      addNewDetail() {// dont add new detail
      }

    }
  });

  _exports.default = _default;
});