define("client/pods/components/websites/panels-display/sub-panels-pages/pages-new/component", ["exports", "client/mixins/crud", "ember-concurrency", "ramda"], function (_exports, _crud, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    saveTask: (0, _emberConcurrency.task)(function (_ref, model, options) {
      var _this = this;

      let {
        adapterName,
        onAfter
      } = _ref;
      return function* () {
        return yield _this.createRecordTask.perform({
          adapterName,
          onAfter,
          model,
          ...options
        });
      }();
    }).drop(),
    actions: {
      onAfter(model) {
        model.set('preSaved', true);
        const isWebsitePage = R.pathEq(['_data', 'type'], 'page')(model);

        if (isWebsitePage) {
          this.onEdit(model);
        } else {
          this.onDisplay(model);
        }

        return model;
      }

    }
  });

  _exports.default = _default;
});