define("client/pods/color-picker/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        defaultSwatches: ['#F6F5F5', '#145374', '#00334E', '#EE6F57', '#206A5D', '#81B214', '#BFDCAE', '#F1F1E8', '#1B262C', '#0F4C75', '#00B7C2', '#FDCB9E', '#B0CAC7', '#F7D6BF'],
        userSwatches: []
      };
    }

  });

  _exports.default = _default;
});