define("client/pods/grapesjs/plugins/animation-trait", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @project: bigordr-app
   * @file: animation-trait
   * @author Nafis Rubio
   * @since 01/07/2021
   * @copyright (C) 2021 BigOrdr
   * @description: $DESCRIPTION$
   */
  const ANIMATION_NAMES = Object.freeze({
    none: 'none',
    fadeIn: 'fadeIn',
    fadeInDown: 'fadeInDown',
    zoomIn: 'zoomIn',
    slideInDown: 'slideInDown',
    rotate: 'rotate',
    rotateIn: 'rotateIn',
    backInDown: 'backInDown',
    backInUp: 'backInUp',
    flip: 'flip',
    flipInX: 'flipInX',
    flipInY: 'flipInY',
    flash: 'flash',
    pulse: 'pulse',
    rubberBand: 'rubberBand',
    bounce: 'bounce',
    shakeX: 'shakeX',
    shakeY: 'shakeY',
    swing: 'swing',
    tada: 'tada',
    wobble: 'wobble',
    jello: 'jello',
    heartbeat: 'heartbeat'
  });
  const ANIMATION_TEXT_NAMES = Object.freeze({ ...ANIMATION_NAMES,
    typing: 'typing'
  });
  const ANIMATION_ITERATIONS_COUNT = Object.freeze({
    once: 'once',
    infinite: 'infinite'
  });

  var _default = editor => {
    const {
      TraitManager
    } = editor;

    const returnedElement = (options, preselected) => {
      const el = document.createElement('div');
      el.innerHTML = `
      <select class='bigordr-animation__type'>
        ${options.map(opt => `<option value='${opt.id}' ${preselected === opt.id ? 'selected="selected"' : ''}>${opt.name}</option>`).join('')}
      </select>
    `;
      return el;
    };

    const traitName = 'bigordr-animation-trait';
    const options = R.pathOr({}, ['Config', 'pluginsOpts', traitName])(editor);
    const {
      intl
    } = options;
    TraitManager.addType(traitName, {
      createInput(_ref) {
        let {
          /* trait, */
          component
        } = _ref;
        const componentAttributes = component.getAttributes();
        const preselectedAnimation = R.propOr(ANIMATION_NAMES.none, 'data-gjs-animation')(componentAttributes);
        const options = R.pipe(R.map(animationName => ({
          id: animationName,
          name: intl.t(animationName)
        })), R.values)(ANIMATION_NAMES);
        const el = returnedElement(options, preselectedAnimation);
        return el;
      },

      onEvent(_ref2) {
        let {
          component,
          event
        } = _ref2;
        const selectedAnimation = R.pathOr(ANIMATION_NAMES.none, ['target', 'value'])(event);
        component.addAttributes({
          'data-gjs-animation': selectedAnimation
        });
      }

    });
    const titleTraitName = 'bigordr-animation-text-trait';
    TraitManager.addType(titleTraitName, {
      createInput(_ref3) {
        let {
          component
        } = _ref3;
        const componentAttributes = component.getAttributes();
        const preselectedAnimation = R.propOr(ANIMATION_TEXT_NAMES.none, 'data-gjs-text-animation')(componentAttributes);
        const options = R.pipe(R.map(animationName => ({
          id: animationName,
          name: intl.t(animationName)
        })), R.values)(ANIMATION_TEXT_NAMES);
        const el = returnedElement(options, preselectedAnimation);
        return el;
      },

      onEvent(_ref4) {
        let {
          component,
          event
        } = _ref4;
        const selectedAnimation = R.pathOr(ANIMATION_NAMES.none, ['target', 'value'])(event);
        component.addAttributes({
          'data-gjs-text-animation': selectedAnimation
        });
      }

    });
    const iterationTraitName = 'bigordr-animation-iteration-trait';
    TraitManager.addType(iterationTraitName, {
      createInput(_ref5) {
        let {
          component
        } = _ref5;
        const componentAttributes = component.getAttributes();
        const preselectedCount = R.propOr(ANIMATION_ITERATIONS_COUNT.once, 'data-gjs-animation__iteration-count')(componentAttributes);
        const options = R.pipe(R.map(animationCount => ({
          id: animationCount,
          name: intl.t(animationCount)
        })), R.values)(ANIMATION_ITERATIONS_COUNT);
        const el = returnedElement(options, preselectedCount);
        return el;
      },

      onEvent(_ref6) {
        let {
          component,
          event
        } = _ref6;
        const selectedCount = R.pathOr(ANIMATION_ITERATIONS_COUNT.once, ['target', 'value'])(event);
        component.addAttributes({
          'data-gjs-animation__iteration-count': selectedCount
        });
      }

    });
    const durationTraitName = 'bigordr-animation-duration-trait';
    TraitManager.addType(durationTraitName, {
      createInput(_ref7) {
        let {
          component
        } = _ref7;
        const componentAttributes = component.getAttributes();
        const presetDuration = R.pipe(R.propOr(null, 'data-gjs-animation__duration'), parseFloat)(componentAttributes);
        const el = document.createElement('div');

        if (typeof presetDuration === 'number' && !isNaN(presetDuration)) {
          el.innerHTML = `
          <input type='text' value=${presetDuration}>
        `;
        } else {
          el.innerHTML = `
          <input type='text'>
        `;
        }

        return el;
      },

      onEvent(_ref8) {
        let {
          component,
          event
        } = _ref8;
        const customizedDuration = R.pipe(R.pathOr(null, ['target', 'value']), parseFloat)(event);

        if (typeof customizedDuration === 'number' && !isNaN(customizedDuration)) {
          component.addAttributes({
            'data-gjs-animation__duration': customizedDuration
          });
        }
      }

    });
  };

  _exports.default = _default;
});