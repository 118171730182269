define("client/pods/components/extensions/apps/tw/momo/products/sync/table-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hB8g5dDe",
    "block": "{\"symbols\":[\"@tab\",\"@results\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onEdit\",\"@onDisplay\"],\"statements\":[[5,\"layouts/table-view\",[],[[\"@tab\",\"@detailsRowComponent\",\"@results\",\"@filters\",\"@onSearchTask\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onEdit\",\"@onDisplay\",\"@tableViewOptions\"],[[23,1,[]],\"extensions/apps/tw/momo/products/sync/table-view/detail-row\",[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[28,\"hash\",null,[[\"tab\"],[[23,1,[]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/momo/products/sync/table-view/template.hbs"
    }
  });

  _exports.default = _default;
});