define("client/pods/doc-list-by/service", ["exports", "ember-concurrency-decorators"], function (_exports, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CrudService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class CrudService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "date", _descriptor2, this);
    }

    listByTask() {
      var _this = this;

      let {
        adapterName,
        appendPath,
        contact,
        contactKey,
        date,
        dateEnd,
        dateStart,
        dispatchStatus,
        documentStatus,
        filters,
        isVoid,
        match,
        notDispatchStatus,
        query,
        resultsProperty,
        resultsToggle,
        sourceKey
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let onHideDropdown = arguments.length > 1 ? arguments[1] : undefined;
      return function* () {
        if (match) {
          filters.setData('match', match);
        }

        if (sourceKey) {
          filters.setData('sourceKey', sourceKey);
        }

        if (contactKey) {
          filters.setData('contactKey', contactKey);
        } else if (contact) {
          filters.setData('contact', contact);
        }

        if (isVoid) {
          filters.setData('isVoid', true);
        }

        if (documentStatus) {
          if (!Ember.isArray(documentStatus)) {
            documentStatus = [documentStatus];
          }

          filters.setData('documentStatus', documentStatus);
        }

        if (dispatchStatus) {
          if (!Ember.isArray(dispatchStatus)) {
            dispatchStatus = [dispatchStatus];
          }

          filters.setData('dispatchStatus', dispatchStatus);
        }

        if (notDispatchStatus) {
          if (!Ember.isArray(notDispatchStatus)) {
            notDispatchStatus = [notDispatchStatus];
          }

          filters.setData('notDispatchStatus', notDispatchStatus);
        }

        if (date) {
          filters.setData('match', ['dateZ']);
          filters.setData('query', date);
        }

        if (dateStart) {
          filters.setData('dateStart', dateStart);
        }

        if (dateEnd) {
          filters.setData('dateEnd', dateEnd);
        }

        const searchSettings = {
          adapterName,
          appendPath,
          filters,
          query,
          resultsProperty,
          resultsToggle
        };
        const results = yield _this.crud.searchRecordsTask.perform(searchSettings);

        if (onHideDropdown) {
          onHideDropdown();
        }

        return results;
      }();
    }

    listByDetailsTask() {
      var _this2 = this;

      let {
        adapterName,
        contact,
        contactKey,
        date,
        dateEnd,
        dateStart,
        documentStatus,
        dispatchStatus,
        filters,
        itemKey,
        match,
        notDispatchStatus,
        query = '',
        resultsProperty,
        resultsToggle,
        sourceKey
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let onHideDropdown = arguments.length > 1 ? arguments[1] : undefined;
      return function* () {
        filters.setData('page', 1);

        if (match) {
          filters.setData('match', match);
        }

        if (sourceKey) {
          filters.setData('sourceKey', sourceKey);
        }

        if (itemKey) {
          filters.setData('itemKey', itemKey);
        }

        if (contactKey) {
          filters.setData('contactKey', contactKey);
        } else if (contact) {
          filters.setData('contact', contact);
        }

        if (documentStatus) {
          if (!Ember.isArray(documentStatus)) {
            documentStatus = [documentStatus];
          }

          filters.setData('documentStatus', documentStatus);
        }

        if (dispatchStatus) {
          if (!Ember.isArray(dispatchStatus)) {
            dispatchStatus = [dispatchStatus];
          }

          filters.setData('dispatchStatus', dispatchStatus);
        }

        if (notDispatchStatus) {
          if (!Ember.isArray(notDispatchStatus)) {
            notDispatchStatus = [notDispatchStatus];
          }

          filters.setData('notDispatchStatus', notDispatchStatus);
        }

        if (date) {
          filters.setData('match', ['dateZ']);
          query = date;
        }

        if (dateStart) {
          filters.setData('dateStart', dateStart);
        }

        if (dateEnd) {
          filters.setData('dateEnd', dateEnd);
        }

        const searchSettings = {
          adapterName,
          appendPath: '/details',
          filters,
          query,
          resultsProperty,
          resultsToggle
        };
        const results = yield _this2.crud.searchRecordsTask.perform(searchSettings);

        if (onHideDropdown) {
          onHideDropdown();
        }

        return results;
      }();
    }

    listByDate(onHideDropdown, dateZ, date) {
      return this.listByTask.perform({
        date,
        dateZ,
        resultsToggle: 'resultsToggle'
      }, onHideDropdown);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "listByTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "listByTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "listByDetailsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "listByDetailsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "listByDate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "listByDate"), _class.prototype)), _class));
  _exports.default = CrudService;
});