define("client/pods/components/extensions/apps/tw/shopee/orders/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwShopeeOrdersComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('search'), _dec4 = Ember.inject.service('date'), _dec5 = Ember.inject.service('server'), _dec6 = Ember.inject.service('tabsManager'), _dec7 = Ember.inject.service('extensions/apps/tw/shopee/products'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember.computed('shopeeButtonStatusModel._data.{ready,isProcessing}'), _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, (_class = class ExtensionsAppsTwShopeeOrdersComponent extends _component.default {
    /** @type {A|Array} */
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dataManager", _descriptor2, this);

      _initializerDefineProperty(this, "searchService", _descriptor3, this);

      _initializerDefineProperty(this, "dateService", _descriptor4, this);

      _initializerDefineProperty(this, "serverService", _descriptor5, this);

      _initializerDefineProperty(this, "tabsManagerService", _descriptor6, this);

      _initializerDefineProperty(this, "shopeeProductsService", _descriptor7, this);

      _initializerDefineProperty(this, "orders", _descriptor8, this);

      _initializerDefineProperty(this, "currentOrders", _descriptor9, this);

      _initializerDefineProperty(this, "ordersFilters", _descriptor10, this);

      _initializerDefineProperty(this, "dateStartZ", _descriptor11, this);

      _initializerDefineProperty(this, "dateEndZ", _descriptor12, this);

      _initializerDefineProperty(this, "dateRange", _descriptor13, this);

      _initializerDefineProperty(this, "hasMore", _descriptor14, this);

      _initializerDefineProperty(this, "tab", _descriptor15, this);

      _initializerDefineProperty(this, "selectedDispatchProcess", _descriptor16, this);

      _initializerDefineProperty(this, "shopeeButtonStatusModel", _descriptor17, this);

      _defineProperty(this, "adapterName", 'extensions/apps/tw/shopee/orders');

      _defineProperty(this, "filterAdapterName", 'extensions/apps/tw/shopee/orders/filters');

      this.crud.addLists(this, ['extensions/apps/tw/shopee/lists/orders', 'extensions/apps/tw/shopee/lists/orders/import-sub-panels']);
      this.setDefaults();
      this.shopeeButtonStatusModel = this.dataManager.setAsRecord({
        adapterName: 'extensions/apps/tw/shopee/sync/orders',
        data: {
          _id: 'extensionsAppsTwShopeeSyncOrdersId',
          ready: false,
          isProcessing: false
        }
      }); // fas fa-arrow-down
    }

    get icon() {
      if (this.shopeeButtonStatusModel._data.ready) {
        return 'fas fa-arrow-down';
      }

      if (this.shopeeButtonStatusModel._data.isProcessing) {
        return 'fas fa-sync-alt fa-spin';
      }

      return 'fas fa-arrow-down';
    }
    /**
     * Check if all orders contains link products
     */


    get validOrders() {
      const validateAllOrders = this.orders.map(orderModel => orderModel.validOrder);
      return R.all(R.equals(true))(validateAllOrders);
    }

    get activeTab() {
      return this.tabsManagerService.getActiveSubTab(this.args.tab);
    }

    get isRunning() {
      if (this.fetchOrdersTask.isRunning) {
        return true;
      }

      if (this.crud.searchRecordsConcurrentlyTask.isRunning) {
        return true;
      }

      if (this.crud.createRecordTask.isRunning) {
        return true;
      }

      if (this.crud.searchInputTask.isRunning) {
        return true;
      }

      if (this.importOrdersTask.isRunning) {
        return true;
      }

      if (this.shopeeProductsService.syncProducts.isRunning) {
        return true;
      }

      return false;
    }

    get disableImport() {
      if (!this.validOrders) {
        return true;
      }

      if (this.orders.length === 0) {
        return true;
      }

      if (this.hasMore) {
        return true;
      }

      return false;
    }

    get unknownProducts() {
      const unknownProducts = this.orders.map(orderModel => orderModel.unknownProducts);
      return R.pipe(R.reject(RA.isNilOrEmpty), R.flatten, R.groupBy(R.path(['_data', 'shopeeCode'])), R.mapObjIndexed(docSalesDetails => {
        const refs = R.map(R.pathOr('', ['_data', 'ref']))(docSalesDetails);
        docSalesDetails[0].set('refs', refs);
        return docSalesDetails[0];
      }), R.values)(unknownProducts);
    }

    get productsBadge() {
      return this.unknownProducts.length > 0 ? this.unknownProducts.length : '';
    }

    get importOrdersSubPanels() {
      const importOrdersSubPanels = this.crud.lists['extensions-apps-tw-shopee-lists-orders-import-sub-panels'] || [];
      return importOrdersSubPanels.map(subPanelModel => {
        if (subPanelModel._data.value === 'products') {
          subPanelModel.set('_data.badge', this.productsBadge);
        }

        return subPanelModel;
      });
    }

    get activeSubTab() {
      const subTabs = this?.tab?.subTabs || [];
      return subTabs.find(subTab => subTab?.isActive);
    }

    selectDefaultTab() {
      this.tab = this.tabsManagerService.getActiveSubTab(this.args.tab);

      const initialPanel = _nventor.default.safeHeadOr({}, this.crud.lists['extensions-apps-tw-shopee-lists-orders-import-sub-panels']);

      this.tabsManagerService.displaySubPanel(this, initialPanel);
    }

    refreshOrders() {
      this.orders = this.orders.map(orderModel => orderModel);
    }

    updateOrderDetailsProductKeys(product, detail) {
      debugger;
      this.orders = this.orders.map(orderModel => {
        const updateOrderDetail = R.includes(orderModel._data.ref)(detail.refs);

        if (updateOrderDetail) {
          orderModel.updateDetails(product);
        }

        return orderModel;
      });
    }

    setDefaults() {
      this.orders = [];
      this.currentOrders = [];
      this.dateRange = '7';
      this.dateStartZ = this.dateService.getMoment().subtract(this.dateRange, 'days').toISOString();
      this.dateEndZ = this.dateService.getMoment().toISOString();
      this.ordersFilters = this.searchService.setupFilters({
        adapterName: this.filterAdapterName,
        defaultFiltersData: {
          resultsProperty: 'ordersData',
          count: 20,
          page: 1,
          dateStartZ: this.dateStartZ,
          dateEndZ: this.dateEndZ
        }
      });
    }

    *fetchOrdersTask() {
      this.shopeeButtonStatusModel.set('_data.ready', false);
      this.selectDefaultTab();
      this.dateStartZ = this.dateService.getMoment().subtract(this.dateRange, 'days').toISOString();
      this.ordersFilters.set('_data.dateStartZ', this.dateStartZ);
      const ordersData = yield this.crud.findTask.perform({
        adapterName: this.adapterName,
        filters: this.ordersFilters
      });
      this.hasMore = ordersData._data.hasMore;
      this.currentOrders = ordersData._data.orders;
      this.orders = this.orders.concat(ordersData._data.orders);
      console.log('ExtensionsAppsTwShopeeOrdersComponent::fetchOrdersTask:', {
        ordersData
      });
    }

    *syncProductsTask(preSaveProducts, path) {
      const saveProducts = yield this.shopeeProductsService.syncProducts.perform(preSaveProducts, path);
      saveProducts.forEach(product => {
        const shopeeCode = R.pathOr('', ['_data', 'shopeeCode'])(product);
        const preSaveProduct = R.find(R.pathEq(['_data', 'shopeeCode'], shopeeCode))(preSaveProducts);
        return this.updateOrderDetailsProductKeys(product, preSaveProduct);
      });
      return saveProducts;
    }

    *importOrdersTask(onCloseDialogAction) {
      const path = 'api/protected/documents/sales/invoices/final/import/shopee';
      const sOrders = this.orders.map(order => order.serialize());
      const payload = {
        dispatchProcess: 'unprepared',
        batch: sOrders
      };

      try {
        // @TODO: DISPLAY SUCCESSFUL AND UNSUCCESSFUL IMPORTED ORDERS
        const response = yield this.serverService.call('POST', path, payload);
        const successful = R.pathOr([], ['data', 'successful'], response);
        const unsuccessful = R.pipe(R.pathOr([], ['data', 'unsuccessful']), R.values)(response);
        console.log('ExtensionsAppsTwShopeeOrdersComponent::importOrdersTask:', {
          response,
          successful,
          unsuccessful
        });
        this.shopeeButtonStatusModel.set('_data.isProcessing', true);
        this.shopeeButtonStatusModel = R.prop('shopeeButtonStatusModel')(this);

        if (onCloseDialogAction) {
          onCloseDialogAction();
        }
      } catch (err) {
        console.log('ExtensionsAppsTwShopeeOrdersComponent::importOrdersTask:', {
          err
        });
      }
    }

    onClose(onCloseDialogAction) {
      this.setDefaults();

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    setDispatchProcess(dispatchModel) {
      this.orders = this.orders.map(order => {
        const dispatchProcess = dispatchModel?._data?.code;
        const dispatchProcessLabel = dispatchModel?._data?.name;
        order.set(`_data.dispatchProcess`, dispatchProcess);
        order.set(`_data.dispatchProcessLabel`, dispatchProcessLabel);
        return order;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "searchService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "serverService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tabsManagerService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "shopeeProductsService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "orders", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "currentOrders", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "ordersFilters", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "dateStartZ", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "dateEndZ", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "dateRange", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "hasMore", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "tab", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "selectedDispatchProcess", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "shopeeButtonStatusModel", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "icon", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "icon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectDefaultTab", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "selectDefaultTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshOrders", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "refreshOrders"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateOrderDetailsProductKeys", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "updateOrderDetailsProductKeys"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchOrdersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchOrdersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "syncProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "syncProductsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "importOrdersTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "importOrdersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDispatchProcess", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "setDispatchProcess"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwShopeeOrdersComponent;
});