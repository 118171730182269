define("client/pods/components/products/products-editor/pages-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    tabsManager: Ember.inject.service(),
    activeTemplateIndex: null,

    didInsertElement() {
      this._super(...arguments);

      const model = this.model;

      if (!model) {
        return false;
      }

      const templateModel = model.getData('templates').get('firstObject');
      templateModel.instanceId = model?.instanceId;
      this.selectTemplate(this, templateModel);
      Ember.set(this, 'showAdditionalDescription', model?._data?.additionalDescription ? true : false);
    },

    updateContent(template) {
      this.model.setData('template', template);
    },

    newTemplate(model) {
      const templateModel = model.addNewTemplate();
      this.selectTemplate(templateModel);
    },

    selectTemplate() {
      let templateModel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const templates = this.model?._data?.templates || [];
      let index = templates?.indexOf(templateModel) || 0;

      if (index < 0) {
        index = 0;
      }

      this.set('activeTemplateIndex', index);
    },

    clearAdditionalDescription(model) {
      model.setData('additionalDescription', '');
    }

  }, (_applyDecoratedDescriptor(_obj, "updateContent", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateContent"), _obj), _applyDecoratedDescriptor(_obj, "newTemplate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "newTemplate"), _obj), _applyDecoratedDescriptor(_obj, "selectTemplate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "selectTemplate"), _obj), _applyDecoratedDescriptor(_obj, "clearAdditionalDescription", [_dec4], Object.getOwnPropertyDescriptor(_obj, "clearAdditionalDescription"), _obj)), _obj)));

  _exports.default = _default;
});