define("client/pods/components/websites/links-editor/component", ["exports", "ramda", "ramda-extension", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, R, R_, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GrapesjsLinksModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('websites'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class GrapesjsLinksModalComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "constants", _descriptor2, this);

      _initializerDefineProperty(this, "search", _descriptor3, this);

      _initializerDefineProperty(this, "websitesService", _descriptor4, this);

      _initializerDefineProperty(this, "model", _descriptor5, this);

      _initializerDefineProperty(this, "selectedFilter", _descriptor6, this);

      _initializerDefineProperty(this, "selectedAdapter", _descriptor7, this);

      _initializerDefineProperty(this, "allFilters", _descriptor8, this);

      _initializerDefineProperty(this, "allSetupData", _descriptor9, this);

      _initializerDefineProperty(this, "query", _descriptor10, this);

      this.crud.addLists(this, ['websites/lists/types/links', 'websites/lists/types/links/targets']);

      this._setup.perform();
    }

    _setupFilters(linkType, setupData) {
      const allFilters = this.allFilters;
      const filter = this.search.setupFilters(setupData.filterData);
      allFilters[linkType] = filter;
      this.allFilters = allFilters;
    }

    *_setup() {
      const website = this.args.website;
      const websiteKey = website.getData('_key');
      const shopKey = website.getData('shopKey');
      const allSetupData = {
        pageAndCategory: {
          adapterName: 'websites/pages',
          filterData: {
            adapterName: 'websites/pages/filters',
            defaultFiltersData: {
              master: websiteKey,
              itemLabelKey: '_data.name',
              itemValueKey: '_data._key',
              resultsItemComponent: 'websites/search-results/links'
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: 'pageName'
            },
            linkResourceName: {
              dotPath: 'pageName'
            },
            linkResourceType: {
              dotPath: '_data.type'
            },
            linkResourceKey: {
              dotPath: '_data._key'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        },
        product: {
          adapterName: 'channels/shop/campaigns/details',
          filterData: {
            adapterName: 'channels/shop/campaigns/details/filters',
            defaultFiltersData: {
              master: shopKey,
              itemLabelKey: '_data.item',
              itemValueKey: '_data._itemKey',
              resultsItemComponent: 'channels/shop/items/search-results/links'
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: '_data.item'
            },
            linkResourceName: {
              dotPath: '_data.item'
            },
            linkResourceType: {
              value: 'product'
            },
            linkResourceKey: {
              dotPath: '_data.itemKey'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        },
        blog: {
          adapterName: 'blogs',
          filterData: {
            adapterName: 'blogs/filters',
            defaultFiltersData: {
              master: websiteKey,
              resultsItemComponent: 'blogs/search-results'
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: '_data.name'
            },
            linkResourceName: {
              dotPath: '_data.name'
            },
            linkResourceType: {
              value: 'blog'
            },
            linkResourceKey: {
              dotPath: '_data._key'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        },
        news: {
          adapterName: 'news',
          filterData: {
            adapterName: 'news/filters',
            defaultFiltersData: {
              master: websiteKey,
              resultsItemComponent: 'news/search-results'
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: '_data.name'
            },
            linkResourceName: {
              dotPath: '_data.name'
            },
            linkResourceType: {
              value: 'news'
            },
            linkResourceKey: {
              dotPath: '_data._key'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        },
        storeLocation: {
          adapterName: 'store-location',
          filterData: {
            adapterName: 'store-location/filters',
            defaultFiltersData: {
              master: websiteKey,
              resultsItemComponent: 'store-location/search-results'
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: '_data.name'
            },
            linkResourceName: {
              dotPath: '_data.name'
            },
            linkResourceType: {
              value: 'store-location'
            },
            linkResourceKey: {
              dotPath: '_data._key'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        },
        flows: {
          adapterName: 'flows',
          filterData: {
            adapterName: 'flows/filters',
            defaultFiltersData: {
              itemLabelKey: '_data.name',
              itemValueKey: '_data._key',
              websiteKey,
              shopKey
            }
          },
          toLoadDataFromModel: {
            linkTitle: {
              dotPath: '_data.name'
            },
            linkResourceName: {
              dotPath: '_data.name'
            },
            linkResourceType: {
              value: 'flows'
            },
            linkResourceKey: {
              dotPath: '_data._key'
            },
            linkUrl: {
              dotPath: '_data.url'
            },
            linkTarget: {
              value: '_self'
            }
          }
        }
      };
      this.allSetupData = allSetupData;
      R.mapObjIndexed((setupData, linkType) => {
        this._setupFilters(linkType, setupData);
      })(allSetupData);
      yield this._selectTypeTask.perform();
    }

    *_selectTypeTask() {
      const model = this.args.model;
      const linkType = model?._data?.linkType;
      this.selectedFilter = this.allFilters[linkType];

      if (!this.selectedFilter) {
        this.selectedFilter = {};
        this.selectedAdapter = {};
        return;
      }

      const adapterData = this.allSetupData[linkType];
      this.selectedAdapter = adapterData;
      yield this.crud.searchRecordsTask.perform({
        adapterName: adapterData.adapterName,
        appendPath: adapterData.appendPath,
        filters: this.selectedFilter,
        component: this
      });
    }

    *onChangeTypeTask() {
      this.unloadResource();
      yield this._selectTypeTask.perform();
    }

    loadResource(selected) {
      const model = this.args.model;
      const linkType = model?._data?.linkType;
      const toLoadDataFromModel = R.pathOr({}, ['allSetupData', linkType, 'toLoadDataFromModel'])(this);
      R.mapObjIndexed((getValueInstructions, toSaveKey) => {
        if (getValueInstructions.value) {
          model.setData(toSaveKey, getValueInstructions.value);
          return;
        }

        const value = R_.dotPathOr('', getValueInstructions.dotPath)(selected);
        console.log('-----toSaveKey ' + toSaveKey + ' : ' + getValueInstructions.dotPath + ' = ' + value);
        model.setData(toSaveKey, value);
      })(toLoadDataFromModel);
    }

    unloadResource() {
      const model = this.args.model;
      model.setData('linkTitle', '');
      model.setData('linkResourceName', '');
      model.setData('linkResourceKey', '');
      model.setData('linkResourceType', '');
      model.setData('linkUrl', '');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "websitesService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedFilter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedAdapter", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "allFilters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "allSetupData", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_setup", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "_setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_selectTypeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "_selectTypeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeTypeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeTypeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadResource", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "loadResource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadResource", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "unloadResource"), _class.prototype)), _class));
  _exports.default = GrapesjsLinksModalComponent;
});