define("client/pods/components/payment-methods-editor/component", ["exports", "client/mixins/crud", "ramda"], function (_exports, _crud, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    adapterName: 'paymentMethods',

    init() {
      this._super(...arguments);

      const isEditing = this.isEditing;

      if (isEditing) {
        const dirty = this.setupDirty();
        this.set('model', dirty);
      } else {
        const model = this.setupNewRecord();
        this.set('model', model);
      }
    },

    defaultPaymentMethods: Ember.computed('crud.lists.paymentMethods.[]', 'crud.lists.paymentMethods.@each.synced', 'addItemOptions.docName', function () {
      const docName = this?.addItemOptions?.docName || '';
      const paymentMethods = this?.crud?.lists?.paymentMethods || this?.crud?.lists['payment-methods'] || [];
      const defaultPaymentMethods = R.filter(R.hasPath(['_data', 'isNotEditable']))(paymentMethods);

      if (!R.equals(docName, 'consignments') && !R.equals(docName, 'consignments-returns')) {
        return defaultPaymentMethods;
      }

      return R.filter(paymentMethod => {
        const value = paymentMethod?._data?.value || '';

        if (R.equals(value, 'consignment')) {
          return true;
        }

        return false;
      })(defaultPaymentMethods);
    }),

    setHasPaymentTerms(paymentMethod) {
      const hasPaymentTerms = R.pathOr(false, ['_data', 'hasPaymentTerms'])(paymentMethod);
      const model = this.model;
      model.setData('hasPaymentTerms', hasPaymentTerms);
      model.setData('translate', false);
    },

    afterCreateRecordTask(model) {
      this.onAddItem(model);
      this.onCloseDialogAction();
    },

    afterReplaceRecordTask(model) {
      this.onUpdateItem(model);
    },

    afterRemoveRecordTask() {
      this.onRemoveItem(this.model);
    },

    actions: {
      cancel(model) {
        if (this.isEditing) {
          this.cancelEditing({
            dirty: model
          });
          this.set('showEditor', false);
        } else {
          this.onCloseDialogAction();
        }
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "setHasPaymentTerms", [_dec], Object.getOwnPropertyDescriptor(_obj, "setHasPaymentTerms"), _obj), _applyDecoratedDescriptor(_obj, "afterCreateRecordTask", [_dec2], Object.getOwnPropertyDescriptor(_obj, "afterCreateRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "afterReplaceRecordTask", [_dec3], Object.getOwnPropertyDescriptor(_obj, "afterReplaceRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "afterRemoveRecordTask", [_dec4], Object.getOwnPropertyDescriptor(_obj, "afterRemoveRecordTask"), _obj)), _obj)));

  _exports.default = _default;
});