define("client/pods/components/helpers/content-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0ipBAO34",
    "block": "{\"symbols\":[\"@content\",\"@className\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"has-block\"]]],null,{\"statements\":[[0,\"  \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"hasWrapper\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"content-display \",[23,2,[]]]]],[8],[0,\"\\n    \"],[1,[23,1,[]],true],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,1,[]],true],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/content-display/template.hbs"
    }
  });

  _exports.default = _default;
});