define("client/pods/components/lists/list-dropdown/component", ["exports", "client/pods/components/lists/mixins/dropdown", "client/mixins/translate", "ramda", "ramda-adjunct"], function (_exports, _dropdown, _translate, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * NOTES:
   * - Dropdown will find label based on value set on init (if set)
   *
   * - Dropdown will automatically update label when model.value changes dynamically (which is bound to selected.value)
   *
   * - Will always convert value to string when comparing for match.
   *   eg. 0 will equal '0' when matching selected
   *
   * WARNING:
   *  do not set label property when setting up component
   */
  var _default = Ember.Component.extend(_dropdown.default, _translate.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    toggleOnIconClassName: 'fas fa-caret-up',
    toggleOffIconClassName: 'fas fa-caret-down',
    tabsManager: Ember.inject.service(),
    itemValueKey: '_data.value',
    itemLabelKey: '_data.label',
    tagName: 'span',
    modalClassNames: '',
    selected: null,
    // tracks the value that is selected
    // selectedComponents: null,
    // autoSelectFirst: false, //automatically select first list item
    allowEmpty: false,
    allowDeselect: false,
    multiSelect: false,
    hasDropdown: true,
    showEditor: false,
    isBtn: false,
    label: '',
    isPersistentLabel: false,
    value: null,
    readonly: false,
    // input element's readonly
    disabled: false,
    // determine whether clicking on the input will toggle dropdown
    rememberSelected: true,
    onDeselect: null,
    translate: false,
    onSelect: null,
    // action for onSelect
    onBindHelper: null,
    // helper to bind property to model
    allowDynamicLabelChanges: true,
    // allow changes dynamic changes in value to update label automatically
    triggerOnSelectActionOnChange: true,
    // will trigger onSelect when model value changes
    dropdownWasSelected: false,
    // used to determine if user selected dropdown or value was changed from external source
    // classNames: ['list-dropdown', 'u-tappable'],
    inputIsFullW: true,
    autoPosition: true,
    onAfterAddItem: '',
    closeOnOverlayClick: true,

    init() {
      this._super(...arguments);

      this.setSelected();
      let classNames = this.classNames || [];

      if (RA.isNilOrEmpty(classNames)) {
        classNames = classNames.concat(['list-dropdown', 'u-tappable']);
      } else {
        classNames = classNames.concat(['list-dropdown']);
      }

      const containerClassNames = this.containerClassNames || [];
      classNames = classNames.concat(containerClassNames);
      this.set('classNames', classNames);
    },

    imageStyle: Ember.computed('imageHeight', 'imageWidth', function () {
      let style = '';

      if (this.imageHeight) {
        style = `height:${this.imageHeight};`;
      }

      if (this.imageWidth) {
        style = `${style} width:${this.imageWidth};`;
      }

      return style;
    }),

    // didRender () {
    //   console.log('===isDropdownVisible')
    //   console.log(this.get('isDropdownVisible'))
    //   const isDropdownVisible = this.get('isDropdownVisible')
    //   if (isDropdownVisible) {
    //     const dropdownContainer = this.$('.list-dropdown__container')
    //     const height = dropdownContainer.outerHeight()
    //     const top = dropdownContainer.offset().top
    //     const bottom = top + height
    //     // const viewportTop = this.$(window).scrollTop()
    //     // const viewportBottom = viewportTop + this.$(window).height() - 35
    //     const viewportTop = this.$(document).scrollTop()
    //     const viewportBottom = viewportTop + this.$(document).height() - 35
    //     if (bottom > viewportBottom) {
    //       console.log('is not fully visible')
    //     } else {
    //       console.log('is fully visible')
    //     }
    //     console.log('===dropdown---')
    //     console.log(top)
    //     console.log(height)
    //     console.log(bottom)
    //     console.log('===viewport---')
    //     console.log(viewportTop)
    //     console.log(viewportBottom)
    //   }
    // },
    setSelected(item) {
      if (!item) {
        const value = this.value;
        item = this.getSelectedItem(value);
      }

      if (this.get('rememberSelected')) {
        this.set('selected', item);
      }

      return item;
    },

    // setup () {
    //   let value = this.get('value')
    //   const label = this.get('label')
    //   const list = this.get('list')
    //   console.log('===setup dropdown list')
    //   console.log(label)
    //   console.log(list)
    //   if ((value !== '' && value != null) && isEmpty(label) && !isEmpty(list)) {
    //     this.changeLabel()
    //   } extensions/apps/tw/t-cat/lists/product-type
    // },
    allowEdit: Ember.computed('addItemComponent', function () {
      if (this.addItemComponent) {
        return true;
      }

      return false;
    }),
    //
    // dropdownList: computed('list.[]', function () {
    //
    //   console.log('--dropdown list changed--');
    //   return this.get('list');
    // }),

    /**
     * value was changed on the model elsewhere (not by selecting dropdown item)
     */
    // valueChangedDynamically: Ember.observer('value', function () {
    // // @TODO: test
    // // @TODO: what happens when value is available, but list has not loaded yet?
    //   if (this.get('allowDynamicLabelChanges')) {
    //     const value = this.get('value')
    //     const list = this.get('list')
    //     const dropdownWasSelected = this.get('dropdownWasSelected')
    //     // only trigger label change if value change was from external to component
    //     // (ie. list dropdown was not selected).
    //     if ((value !== '' && value != null) && !isEmpty(list) && dropdownWasSelected === false) {
    //       const item = list.findBy(this.get('itemValueKey'), value)
    //       this.changeLabel(item)
    //       const onSelectAction = this.get('onSelect')
    //       const triggerOnSelectActionOnChange = this.get('triggerOnSelectActionOnChange')
    //       if (onSelectAction && triggerOnSelectActionOnChange) {
    //         onSelectAction(item)
    //       }
    //     }
    //   }
    //   this.set('dropdownWasSelected', false)
    // }),

    /**
     * get selected dropdown value
     * @param {object} item model
     * @return {string} selected item value
     */
    getSelectedValue: function (item) {
      const itemValueKeyArrayPath = R.pipe(R.propOr('', 'itemValueKey'), R.split('.'))(this);
      return R.pathOr('', itemValueKeyArrayPath)(item);
    },

    /**
     * get selected dropdown value
     * @param {object} item model
     * @return {string} selected item value
     */
    getSelectedLabel: function (item) {
      if (this.isPersistentLabel) {
        return this.label;
      }

      if (!item) {
        const value = this.value;
        const list = this.list;

        if (value != null && !Ember.isEmpty(list)) {
          if (!item) {
            item = this.getSelectedItem(value);
          }
        }
      }

      if (item && this.get('rememberSelected')) {
        const itemLabelKey = this.itemLabelKey;
        return item.get?.(itemLabelKey) || item?.[itemLabelKey];
      }
    },

    /**
     * observes when value changes, will update label accordingly
     * will run on init
     */
    // hasValueChanged: Ember.on('init', Ember.observer('value', function () {
    //
    //   //@TODO: also update when list changes?
    //
    //   const value = this.get('value');
    //   if (value) {
    //
    //     //find item that matches value
    //     const list = this.get('list');
    //     const itemValueKey = this.get('itemValueKey');
    //     const item = list.findBy(itemValueKey, value);
    //
    //     this.set('label', this.getSelectedLabel(item));
    //   }
    // })),
    // computedLabel: computed('value', function () {
    //   const item = this.getSelectedItem(this.value)
    //   return this.getSelectedLabel(item)
    //   // if (this.list) {
    //   //   return R.pipe(
    //   //     R.find(R.propEq(this.itemValueKey, this.value)),
    //   //     R.prop(this.itemLabelKey)
    //   //   )(this.list) || this.label
    //   // }
    //   // return this.label
    // }),
    getSelectedItem(value) {
      const list = this.list;
      const itemValueKey = this.itemValueKey;
      let i = 0;
      let item;

      if (Ember.isArray(list)) {
        while (list[i]) {
          // if (String(list[i].get(itemValueKey)) === String(value)) {
          if (R.equals(list.get(`${i}.${itemValueKey}`), value)) {
            item = list[i];
            i = list.length + 1;
          }

          i++;
        }
      }

      return item;
    },

    selectedLabel: Ember.computed('translate', 'defaultLabel', 'value', 'list.[]', 'selected', 'selected.{_data.translate,synced}', 'updateLabel', function () {
      if (!this.get('rememberSelected')) {
        return this.defaultLabel || '';
      }

      const value = this.value;
      const label = this.getSelectedLabel() || this.label;
      const selected = this.getSelectedItem(value);

      if (!selected) {
        const defaultLabel = this.defaultLabel;

        if (defaultLabel) {
          if (this.translate) {
            return this.intl.t(defaultLabel);
          }

          return defaultLabel;
        }
      }

      let itemTranslate;

      if (selected) {
        itemTranslate = R.path(['_data', 'translate'])(selected);
        const shouldTranslateLabel = this.shouldTranslateLabel(value, itemTranslate);

        if (shouldTranslateLabel) {
          return this.intl.t(label);
        }
      }

      if (label && this.translate && this.list && value != null && value !== '') {
        const shouldTranslateLabel = this.shouldTranslateLabel(value, itemTranslate);

        if (shouldTranslateLabel) {
          return this.intl.t(label);
        }
      } // if (value != null && value != '' && this.get('list')) {
      // }


      return label;
    }),

    toggleShowEditor(bool) {
      if (typeof bool === 'boolean') {
        Ember.set(this, 'showEditor', bool);
      } else {
        Ember.set(this, 'showEditor', !this.showEditor);
      }
    },

    updateItem(item) {
      this.toggleShowEditor(false);
      this.send('selectDropdownItem', item);
    },

    removeItem(item) {
      const self = this;
      const list = self.get('list');
      const itemValueKey = self.get('itemValueKey');
      const needle = item.get(itemValueKey);
      const found = list.findBy(itemValueKey, needle);
      list.removeObject(found);
      this.toggleShowEditor(false);
    },

    actions: {
      hideDropdown() {
        this.set('isDropdownVisible', false);
      },

      selectDropdownItem(item) {
        let confirmed = true;

        if (this.confirm && this.confirmMessage) {
          confirmed = window.confirm(this.confirmMessage);
        }

        if (confirmed) {
          this.set('dropdownWasSelected', true);
          const previousValue = this.value;
          const newValue = this.getSelectedValue(item); // select dropdown item, set component value

          this.set('isDropdownVisible', false); // if (previousValue !== newValue) { //@TODO: test
          // const originalValue = this.get('originalValue')
          // if (newValue !== originalValue) {

          this.set('value', newValue);
          this.set('originalValue', newValue);
          this.set('label', this.getSelectedLabel(item));
          this.setSelected(item);
          this.set('updateLabel', Math.random()); // @TODO: semi focused, ie. focus but not showing dropdown
          // this.$('.element-inputpvt').focus();
          // trigger outer onSelect action

          if (this.onSelect) {
            this.onSelect(item, previousValue);
          }
        }
      },

      deselectDropdownItem() {
        this.set('value', '');
        this.set('originalValue', '');
        this.set('label', '');
        this.set('selected', '');
        this.set('updateLabel', Math.random());
        const onDeselectAction = this.onDeselect;

        if (onDeselectAction) {
          onDeselectAction();
        }
      },

      addItem(item) {
        if (!this.list) {
          this.set('list', []);
        }

        const list = this.list;

        if (!list.findBy(this.itemValueKey, this.getSelectedValue(item))) {
          this.list.pushObject(item);
        }

        this.send('selectDropdownItem', item);
      },

      editItem(item) {
        this.set('editItem', item);
        this.set('showEditor', true);
      }

    },

    onToggleModalDialog() {
      const isDropdownVisible = this.get('isDropdownVisible');
      this.set('isDropdownVisible', !isDropdownVisible);
    }

  }, (_applyDecoratedDescriptor(_obj, "toggleShowEditor", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleShowEditor"), _obj), _applyDecoratedDescriptor(_obj, "updateItem", [_dec2], Object.getOwnPropertyDescriptor(_obj, "updateItem"), _obj), _applyDecoratedDescriptor(_obj, "removeItem", [_dec3], Object.getOwnPropertyDescriptor(_obj, "removeItem"), _obj), _applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec4], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj)), _obj)));

  _exports.default = _default;
});