define("client/pods/components/contacts/panels-display/sub-panels-statements/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _component, _emberConcurrencyDecorators, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactsPanelsDisplaySubPanelsStatements = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._action, (_class = class ContactsPanelsDisplaySubPanelsStatements extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "date", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);

      _initializerDefineProperty(this, "server", _descriptor4, this);

      _initializerDefineProperty(this, "dataManager", _descriptor5, this);

      _initializerDefineProperty(this, "settings", _descriptor6, this);

      _initializerDefineProperty(this, "statement", _descriptor7, this);

      _initializerDefineProperty(this, "selectedBankAccountKey", _descriptor8, this);

      _initializerDefineProperty(this, "resourceData", _descriptor9, this);

      _initializerDefineProperty(this, "paymentDueDateZ", _descriptor10, this);

      _initializerDefineProperty(this, "currentPeriod", _descriptor11, this);

      _initializerDefineProperty(this, "showTotalDuePrintStatement", _descriptor12, this);

      _initializerDefineProperty(this, "showTotalDuePrintAll", _descriptor13, this);

      _defineProperty(this, "adapterName", 'contacts/statements');

      this.crud.addLists(this, ['ledgers/accounts/lists/banks', 'ledgers/journals/cashbook/lists/menu']);
      this.docType = this?.args.tab?.docType || ''; // get list for 12 month

      const pastMonths = this.date.getPastMonths(12);
      this.pastMonths = pastMonths;
      const settings = this.settings?.settingsModel || {};

      if (RA.isNotNilOrEmpty(settings)) {
        const defaultShowTotalDue = R.pathOr(false, ['_data', 'showTotalDue'])(settings);
        this.showTotalDuePrintStatement = defaultShowTotalDue;
        this.showTotalDuePrintAll = defaultShowTotalDue;
      }
    }

    afterLoadLists() {
      // when tab component is created, it will automatically fetch txData for default bank account.
      // this can only happen once list of bank accounts has been fetched.
      const defaultBankAccountKey = this.getDefaultBankAccountKey();

      if (RA.isNotNilOrEmpty(defaultBankAccountKey)) {
        this.selectedBankAccountKey = defaultBankAccountKey;
      } // show current month statement


      if (this?.args?.isFromLedgersAccountAging) {
        this.docType = this?.args?.tab?.docType || '';
        const period = this?.args?.period || {};
        const dateStart = this.date.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
        const dateEnd = this.date.formatDateForUrl(this.date.getMonthEnd(period.yyyy, period.mm));
        this.paymentDueDateZ = this?.args?.paymentDueDateZ || '';
        this.currentPeriod = {
          dateStart,
          dateEnd
        };
        this.fetchStatementTask.perform(dateStart, dateEnd);
      } else {
        const thisMonth = this.date.getPeriod();
        this.displayByMonth.perform(thisMonth);
      } // get resourceData for quick links to cashbook


      this.getResourceData();
    }

    getResourceData() {
      const resourceData = R.pipe(R_.dotPathOr([], 'crud.lists.ledgers-journals-cashbook-lists-menu'), R.find(R.pathEq(['_data', 'docType'], this.docType)))(this);
      this.resourceData = resourceData;
    }

    getDefaultBankAccountKey() {
      const bankAccounts = R_.dotPath('crud.lists.ledgers-accounts-lists-banks')(this) || [];
      let defaultAccount = bankAccounts.findBy('_data.isDefault', true);
      const bankAccountsLength = R.length(bankAccounts);

      if (!defaultAccount && R.equals(bankAccountsLength, 1)) {
        defaultAccount = bankAccounts.get('firstObject');
      }

      if (defaultAccount) {
        return defaultAccount?._data?._key || '';
      }

      return '';
    }

    *fetchStatementTask(dateStart, dateEnd) {
      const model = this?.args?.model || {};
      const key = model?._data?._key || '';
      const docType = this?.docType || '';
      const allowedPaymentMethodForStatement = R.pipe(R_.dotPathOr([], 'crud.lists.contacts-lists-allowed-payment-method'), R.pluck('_data'))(this);
      const params = {
        key,
        docType,
        dateStart,
        dateEnd,
        paymentDueDateZ: this.paymentDueDateZ,
        allowedPaymentMethodForStatement
      };
      const result = yield this.crud.findTask.perform({
        adapterName: this.adapterName,
        appendPath: `/${docType}`,
        params
      });
      this.statement = result;
    }

    *displayByMonth(period) {
      const dateStart = this.date.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      const dateEnd = this.date.formatDateForUrl(this.date.getMonthEnd(period.yyyy, period.mm));
      this.paymentDueDateZ = this.date.getMoment(dateEnd).toISOString();
      this.currentPeriod = {
        dateStart,
        dateEnd
      };
      yield this.fetchStatementTask.perform(dateStart, dateEnd);
    }

    *displayByCustomMonth() {
      const dateEndString = this.customDateEndZ;
      const dateStartString = this.customDateStartZ;
      let dateEnd;
      let dateStart;

      if (dateEndString) {
        if (!dateStartString) {
          const dateEndObj = this.date.getMonthEnd(dateEndString);
          dateStart = this.date.formatDateForUrl(dateEndObj.startOf('month'));
          dateStart = this.date.getStartOfDayZ(dateStart);
          dateEnd = dateEndString;
        } else {
          if (dateStartString > dateEndString) {
            dateEnd = dateStartString;
            dateStart = dateEndString;
          } else {
            dateEnd = dateEndString;
            dateStart = dateStartString;
          }
        }

        Ember.set(this, 'paymentDueDateZ', dateEnd);
        this.currentPeriod = {
          dateStart,
          dateEnd
        };
        yield this.fetchStatementTask.perform(dateStart, dateEnd);
      }
    }

    openCashbookTab() {
      const model = this?.args?.model || {};
      const resourceData = this.resourceData;
      const contactKey = model?._data?._key || '';
      const contra = model?._data?.name || '';
      const docType = this?.docType || '';
      let relationship = 'customer';

      if (R.equals(docType, 'purchases')) {
        relationship = 'supplier';
      }

      resourceData.setData('contactKey', contactKey);
      resourceData.setData('contra', contra);
      resourceData.setData('relationship', relationship);
      resourceData.setData('isFromContactsStatements', true);
      const tabOptions = {
        component: 'ledgers/journals/cashbook/tab-container',
        title: 'cash book',
        resourceData,
        loadInstructions: {
          find: {
            id: this.selectedBankAccountKey
          }
        }
      };
      this.tabsManager.openTab('left', tabOptions); // reset selectedBankAccountKey

      this.selectedBankAccountKey = '';
      const defaultBankAccountKey = this.getDefaultBankAccountKey();

      if (RA.isNotNilOrEmpty(defaultBankAccountKey)) {
        this.selectedBankAccountKey = defaultBankAccountKey;
      }
    }

    *reloadStatementOnDueDateChangeTask() {
      const currentPeriod = this.currentPeriod;
      const dateStart = currentPeriod?.dateStart || '';
      const dateEnd = currentPeriod?.dateEnd || '';

      if (RA.isNotNilOrEmpty(dateStart) && RA.isNotNilOrEmpty(dateEnd)) {
        yield this.fetchStatementTask.perform(dateStart, dateEnd);
      }
    } // printStatement() {
    //
    //   window.open('https://localhost:9000/api/protected/contacts/statements/print/sales/2017-11-01/2017-11-30/3040087962211', '_blank');
    // }


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "date", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "statement", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedBankAccountKey", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "resourceData", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "paymentDueDateZ", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "currentPeriod", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showTotalDuePrintStatement", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showTotalDuePrintAll", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchStatementTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchStatementTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayByMonth", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "displayByMonth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayByCustomMonth", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "displayByCustomMonth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openCashbookTab", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "openCashbookTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadStatementOnDueDateChangeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "reloadStatementOnDueDateChangeTask"), _class.prototype)), _class));
  _exports.default = ContactsPanelsDisplaySubPanelsStatements;
});