define("client/pods/store-location/lists/filters/search/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,

    findAll() {
      const data = [{
        value: 'all',
        label: 'all'
      }, {
        value: 'name',
        label: 'name'
      }, {
        value: 'description',
        label: 'description'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});