define("client/pods/components/forms/table-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c1xmuO/J",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"table-row__label\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"tt\",[[24,[\"label\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"table-row__detail\"],[8],[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"table-row__detail\"],[8],[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/forms/table-row/template.hbs"
    }
  });

  _exports.default = _default;
});