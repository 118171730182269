define("client/pods/components/menus/tab-menu/tab-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4EyJQQZx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\",true],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"\\t\\t\"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"item\",\"_data\",\"icon\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[[24,[\"item\",\"_data\",\"icon\"]]]]],false],[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"u-icon-spacer--right\"],[8],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[1,[28,\"tt\",[[24,[\"label\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"item\",\"_data\",\"badge\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"element-btn__badge\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[24,[\"item\",\"_data\",\"badge\"]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\\t\"],[1,[28,\"msgs/error-badge\",null,[[\"errors\",\"errorsTracker\"],[[24,[\"errors\"]],[24,[\"item\",\"_data\",\"errorsTracker\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/tab-menu/tab-item/template.hbs"
    }
  });

  _exports.default = _default;
});