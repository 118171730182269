define("client/pods/components/extensions/apps/tw/momo/products/component", ["exports", "@glimmer/component", "client/utils/nventor", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _nventor, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwMomoProductsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('search'), _dec3 = Ember.inject.service('date'), _dec4 = Ember.inject.service('server'), _dec5 = Ember.inject.service('tabsManager'), _dec6 = Ember.inject.service('extensions/apps/tw/momo/products'), _dec7 = Ember.inject.service('extensions/apps/tw/e-invoices'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, (_class = class ExtensionsAppsTwMomoProductsComponent extends _component.default {
    // @service('extensions/apps/tw/e-invoices') eInvoicesService
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "searchService", _descriptor2, this);

      _initializerDefineProperty(this, "dateService", _descriptor3, this);

      _initializerDefineProperty(this, "serverService", _descriptor4, this);

      _initializerDefineProperty(this, "tabsManagerService", _descriptor5, this);

      _initializerDefineProperty(this, "shopeeProductsService", _descriptor6, this);

      _initializerDefineProperty(this, "eInvoicesService", _descriptor7, this);

      _initializerDefineProperty(this, "tab", _descriptor8, this);

      _initializerDefineProperty(this, "results", _descriptor9, this);

      _initializerDefineProperty(this, "shopeeProductsFilters", _descriptor10, this);

      _defineProperty(this, "extensionsAppsTwMomoListsProductsSyncSubPanels", 'extensions-apps-tw-momo-lists-products-sync-sub-panels');

      this.crud.addLists(this, ['statuses', 'extensions/apps/tw/momo/sync/status', 'extensions/apps/tw/momo/lists/products/sync-sub-panels']);
      this.shopeeProductsFilters = this.searchService.setupFilters({
        adapterName: 'extensions/apps/tw/momo/products/filters',
        defaultFiltersData: {
          campaignKey: this.args?.model?._data?._key
        }
      });
    }

    get importOrdersSubPanels() {
      const importOrdersSubPanels = this.crud.lists[this.extensionsAppsTwMomoListsProductsSyncSubPanels] || [];
      return importOrdersSubPanels.map(subPanelModel => {
        if (subPanelModel._data.value === 'products') {
          subPanelModel.set('_data.badge', this.productsBadge);
        }

        return subPanelModel;
      });
    }

    get selectedProducts() {
      return R.pipe(R.propOr([], 'results'), R.filter(R.pathEq(['isSelected'], true)))(this);
    }

    afterLoadLists() {
      this.tab = this.tabsManagerService.getActiveSubTab(this.args.parentTab);

      const initialPanel = _nventor.default.safeHeadOr({}, this.crud.lists[this.extensionsAppsTwMomoListsProductsSyncSubPanels]);

      this.tabsManagerService.displaySubPanel(this, initialPanel);
    }

    *onClickMomoSyncTask() {
      this.afterLoadLists();
      yield this.reloadSearchRecordsTask.perform();
    }

    syncProducts() {
      var _this = this;

      let products = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let appendPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return function* () {
        appendPath = `${appendPath}/batch`;
        const selectedProducts = R.filter(R.propEq('isSelected', true))(products);
        const hasSelectedProducts = RA.isNotNilOrEmpty(selectedProducts);
        const toSyncProducts = hasSelectedProducts ? selectedProducts : products;
        _this.results = yield _this.shopeeProductsService.syncProducts.perform(toSyncProducts, appendPath);
      }();
    }

    reloadSearchRecordsTask() {
      var _this2 = this;

      let {
        filters
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        filters = filters || _this2.shopeeProductsFilters;
        _this2.results = yield _this2.shopeeProductsService.fetchMomoProducts.perform({
          filters
        });
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "searchService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "serverService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tabsManagerService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "shopeeProductsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "eInvoicesService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tab", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "results", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "shopeeProductsFilters", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterLoadLists", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "afterLoadLists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClickMomoSyncTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onClickMomoSyncTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "syncProducts", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "syncProducts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reloadSearchRecordsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "reloadSearchRecordsTask"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwMomoProductsComponent;
});