define("client/pods/components/settings/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+7zsi+y1",
    "block": "{\"symbols\":[\"@model\",\"@onEdit\",\"@tab\"],\"statements\":[[5,\"settings/settings-editor\",[],[[\"@isEditing\",\"@model\",\"@onEdit\",\"@tab\"],[false,[23,1,[]],[23,2,[]],[23,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});