define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/rate-editor/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChannelsShopShippingZonesShippingZonesEditorDetailsEditorRateEditorComponent extends _component.default {
    get rates() {
      const rates = this.args.rates || [];
      return R.pluck('_data')(rates);
    }

    get minRate() {
      const rates = this.rates;
      const index = this.args.index;

      if (index > 0) {
        const previousRate = rates[index - 1];
        const previousMax = parseFloat(previousRate.max) || 0;
        return previousMax + 1;
      }

      return '0';
    }

  }

  _exports.default = ChannelsShopShippingZonesShippingZonesEditorDetailsEditorRateEditorComponent;
});