define("client/pods/components/layouts/section-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SkruIq/t",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"title\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h3\",true],[10,\"class\",\"section-title\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"section-row__container\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/section-row/template.hbs"
    }
  });

  _exports.default = _default;
});