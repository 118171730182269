define("client/pods/components/users/panels-edit/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'users',

    init() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      const model = this.model;
      const dirty = this.setupDirty({
        adapterName: this.adapterName,
        model
      });
      this.set('dirty', dirty);
    }

  });

  _exports.default = _default;
});