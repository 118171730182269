define("client/helpers/date", ["exports", "client/mixins/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_date.default, {
    intl: Ember.inject.service(),

    compute(params
    /* ,  hash */
    ) {
      const dateZ = params[0];
      const format = params[1];
      return this.formatDate(dateZ, format);
    }

  });

  _exports.default = _default;
});