define("client/pods/components/contacts/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A03J8FkR",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"contacts/contacts-editor\",null,[[\"model\",\"errors\",\"isEditing\",\"onDash\",\"saveTask\",\"onDisplay\",\"onCancel\",\"tab\"],[[24,[\"dirty\"]],[24,[\"errors\"]],true,[24,[\"onDash\"]],[24,[\"updateRecordTask\"]],[24,[\"onDisplay\"]],[28,\"action\",[[23,0,[]],\"cancelEditing\",[28,\"hash\",null,[[\"dirty\"],[[24,[\"dirty\"]]]]]],null],[24,[\"tab\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/panels-edit/template.hbs"
    }
  });

  _exports.default = _default;
});