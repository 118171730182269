define("client/pods/components/workflows/items/activities/note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nxVz3GdH",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@activity\",\"@item\",\"@workflow\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"workflows-item-activity__container\"],[8],[0,\"\\n\\t\"],[7,\"p\",true],[10,\"class\",\"u-desc\"],[8],[1,[23,2,[\"_data\",\"description\"]],false],[9],[0,\"\\n\\n\\t\"],[7,\"p\",true],[10,\"class\",\"u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\"],[7,\"span\",true],[10,\"class\",\"tag\"],[8],[1,[23,2,[\"_data\",\"creator\",\"username\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[5,\"elements/element-btn-with-modal-dialog\",[],[[\"@title\",\"@modalDialogTitleTranslate\",\"@icon\",\"@closeOnOverlayClick\",\"@onSubmit\"],[[23,3,[\"_data\",\"name\"]],false,\"fas fa-pen\",false,[28,\"fn\",[[23,0,[\"crud\",\"setupDirty\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"component\",\"resultsProperty\"],[\"workflows/items/activities\",[23,2,[]],[23,0,[]],\"dirty\"]]]],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[5,\"workflows/items/activities/editor\",[],[[\"@onCloseDialogAction\",\"@isNew\",\"@isEditing\",\"@workflow\",\"@model\",\"@onCancel\"],[[23,1,[]],false,true,[23,4,[]],[23,2,[]],[23,1,[]]]]],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/workflows/items/activities/note/template.hbs"
    }
  });

  _exports.default = _default;
});