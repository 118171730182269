define("client/pods/components/channels/shop/campaigns/search-filters/component", ["exports", "client/mixins/crud", "client/pods/components/channels/shop/campaigns/mixins/campaigns-list-by"], function (_exports, _crud, _campaignsListBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _campaignsListBy.default, {
    crud: Ember.inject.service(),
    tagName: '',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['channels/shop/campaigns/lists/filters/search', 'channels/shop/campaigns/lists/filters/status', 'channels/shop/campaigns/lists/filters/sort', 'filters/count', 'filters/sortDirection']);
    } // actions: {
    //   listByDate (onHideDropdown, dateZ, date) {
    //     return this.get('listByTask').perform({ date, dateZ, resultsToggle: 'resultsToggle' }, onHideDropdown)
    //   }
    // }


  });

  _exports.default = _default;
});