define("client/pods/websites/lists/layout/menu-logo-height-separate-line/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    idParam: false,
    modelObj: _model.default,

    findAll() {
      const data = [{
        value: '10px',
        label: '10px'
      }, {
        value: '20px',
        label: '20px'
      }, {
        value: '30px',
        label: '30px'
      }, {
        value: '40px',
        label: '40px'
      }, {
        value: '50px',
        label: '50px'
      }, {
        value: '60px',
        label: '60px'
      }, {
        value: '70px',
        label: '70px'
      }, {
        value: '80px',
        label: '80px'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});