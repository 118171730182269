define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/is-set-with-choices/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    campaignsProducts: Ember.inject.service(),
    tagName: '',
    actions: {
      toggleOldSetAndRecalculatePrice(model, choice) {
        R.pipe(R.pathOr([], ['_data', 'details']), R.map(detail => {
          const isSet = detail.get('_data.isSet');

          if (isSet) {
            detail.setData('isSetNew', true);
          }
        }))(choice);
        model.recalculatePrice(model);
      }

    }
  });

  _exports.default = _default;
});