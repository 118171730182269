define("client/pods/components/products/products-editor/sets-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pfibz7Kr",
    "block": "{\"symbols\":[\"detail\",\"index\",\"@isEditing\",\"@isNew\",\"@model\",\"@tab\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[4,\"if\",[[23,5,[\"_data\",\"_rev\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"span\",true],[10,\"class\",\"has-text-danger\"],[8],[1,[28,\"tt\",[\"product_sync_warning\"],null],false],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"forms/form-section\",[],[[\"@title\"],[\"product_sets_contents\"]],{\"statements\":[[0,\"\\n\\t\"],[7,\"table\",true],[10,\"class\",\"table-form u-full-width\"],[8],[0,\"\\n\\n\\t\\t\"],[5,\"products/products-editor/sets-editor/details-editor\",[],[[\"@isEditing\",\"@isHeaders\",\"@isNew\"],[[23,3,[]],true,[23,4,[]]]]],[0,\"\\n\\n\"],[4,\"each\",[[23,5,[\"_data\",\"setDetails\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"products/products-editor/sets-editor/details-editor\",[],[[\"@detail\",\"@errors\",\"@errorIndex\",\"@isEditing\",\"@isNew\",\"@isReadonly\",\"@model\",\"@onLoadProduct\",\"@onRemoveSetDetail\",\"@onResetSetDetail\",\"@tab\"],[[23,1,[]],[22,\"errors\"],[23,2,[]],[23,3,[]],[23,4,[]],[23,0,[\"isReadonlyAndAvailable\"]],[23,5,[]],[28,\"action\",[[23,0,[]],\"loadProduct\"],null],[28,\"action\",[[23,0,[]],\"removeSetDetail\"],null],[28,\"action\",[[23,0,[]],\"resetSetDetail\"],null],[23,6,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/products-editor/sets-editor/template.hbs"
    }
  });

  _exports.default = _default;
});