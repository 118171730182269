define("client/pods/components/contacts/panels-duplicates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jHv734FJ",
    "block": "{\"symbols\":[\"duplicateData\",\"@tab\"],\"statements\":[[7,\"table\",false],[12,\"class\",\"table-form u-full-width\"],[3,\"did-insert\",[[23,0,[\"onDidInsert\"]]]],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"duplicatesByEmails\"]]],null,{\"statements\":[[0,\"    \"],[5,\"contacts/panels-duplicates/row\",[],[[\"@tab\",\"@duplicateData\"],[[23,2,[]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/panels-duplicates/template.hbs"
    }
  });

  _exports.default = _default;
});