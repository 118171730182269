define("client/pods/components/calendar/calendar-day/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nXzvSmMx",
    "block": "{\"symbols\":[\"model\"],\"statements\":[[7,\"td\",true],[10,\"class\",\"u-calendar__td\"],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"span\",true],[11,\"class\",[29,[\"u-calendar__day \",[28,\"if\",[[24,[\"isToday\"]],\" u-calendar__day--is-today \"],null]]]],[8],[0,\"\\n      \"],[1,[24,[\"day\",\"dd\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"schedule\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"has-text-left u-desc u-desc--mini\"],[8],[0,\"\\n        \"],[1,[28,\"component\",[[24,[\"scheduleComponent\"]]],[[\"isToday\",\"model\"],[[24,[\"isToday\"]],[23,1,[]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/calendar/calendar-day/template.hbs"
    }
  });

  _exports.default = _default;
});