define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/is-set/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7mYqr+B2",
    "block": "{\"symbols\":[\"setDetail\",\"childIndex\",\"@isPresetTableView\",\"@isDetailRow\",\"@index\",\"@whiteSpace\",\"@tableViewOptions\",\"@results\",\"@filters\",\"@hasHeadersSort\",\"@commissionBaseCostNewModel\",\"@campaignsDetailsModel\",\"@wasSaved\",\"@setCommissionBaseCostFromHistoryTask\",\"@commissionBaseCostNew\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/details\",[],[[\"@isPresetTableView\",\"@isDetailRow\",\"@model\",\"@index\",\"@whiteSpace\",\"@tableViewOptions\",\"@results\",\"@filters\",\"@hasHeadersSort\",\"@commissionBaseCostNewModel\",\"@campaignsDetailsModel\",\"@wasSaved\",\"@setCommissionBaseCostFromHistoryTask\"],[[23,3,[]],[23,4,[]],[23,15,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]],[23,14,[]]]]],[0,\"\\n\"],[4,\"each\",[[23,15,[\"_data\",\"setDetails\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/details\",[],[[\"@isPresetTableView\",\"@isDetailRow\",\"@model\",\"@index\",\"@whiteSpace\",\"@tableViewOptions\",\"@results\",\"@filters\",\"@hasHeadersSort\",\"@commissionBaseCostNewModel\",\"@campaignsDetailsModel\",\"@wasSaved\",\"@setCommissionBaseCostFromHistoryTask\"],[[23,3,[]],[23,4,[]],[23,1,[]],[28,\"concat\",[[23,5,[]],\".\",[28,\"calculate\",[[23,2,[]],\"+\",\"1\"],null]],null],[28,\"concat\",[\"\\t\",[23,6,[]]],null],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]],[23,14,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/is-set/template.hbs"
    }
  });

  _exports.default = _default;
});