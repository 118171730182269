define("client/pods/flows/lists/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../../../../utils/nventor'
  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let appendPath = arguments.length > 1 ? arguments[1] : undefined;
      const list = [{
        value: 'details',
        label: 'details',
        resource: 'api/protected/flows/sub-panels-edit/main',
        component: 'flows/flows-editor/main-editor',
        tabType: 'reuseSubTab'
      }, {
        value: 'data',
        label: 'data',
        resource: 'api/protected/flows/sub-panels-edit/data',
        component: 'flows/flows-editor/data-display',
        isDisplayOnly: true,
        tabType: 'replaceSubTab'
      }];
      const customData = {
        list
      };
      const path = this.getFullPath('findAllPath', appendPath);
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});