define("client/pods/export/service", ["exports", "client/mixins/big", "ramda", "ramda-adjunct", "ramda-extension", "client/utils/nventor"], function (_exports, _big, R, RA, R_, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_big.default, {
    dateService: Ember.inject.service('date'),
    crud: Ember.inject.service(),
    intl: Ember.inject.service(),

    calculateDocsNetReturns(_ref) {
      let {
        serialized,
        useOriginalCashCollectionInclTotal = false,
        options
      } = _ref;
      const detailsProp = R.propOr('details', 'detailsProp')(options);
      return R.pipe(R.map(doc => {
        doc.inclTotal = doc.netInclTotal;
        doc[detailsProp] = R.pipe(R.propOr([], detailsProp), R.map(detail => {
          detail.qty = detail.netReturnsQty;
          detail.postDiscInclAmt = detail.netReturnsPostDiscInclAmt;
          detail.postDiscExclAmt = detail.netReturnsPostDiscExclAmt;
          detail.postDiscTaxAmt = detail.netReturnsPostDiscTaxAmt;

          if (useOriginalCashCollectionInclTotal && R.equals(doc.paymentMethodKey, 'cash') && doc.isDispatched) {
            detail.qty = detail.cashCollectionQty;
            detail.postDiscInclAmt = detail.cashCollectionInclAmt;
            detail.postDiscExclAmt = detail.cashCollectionExclAmt;
            detail.postDiscTaxAmt = detail.cashCollectionTaxAmt;
          }

          if (detail.qty === 0 || detail.qty === '0') {
            return false;
          }

          return detail;
        }), R.reject(R.equals(false)))(doc);

        if (RA.isNilOrEmpty(doc[detailsProp])) {
          return false;
        }

        if (useOriginalCashCollectionInclTotal && R.equals(doc.paymentMethodKey, 'cash')) {
          doc.inclTotal = doc.cashCollectionInclTotal;
        }

        const thirdPartyCommissions = doc?.thirdPartyCommissions;
        const shopDotCom = R.find(R.propEq('provider', 'shopDotCom'))(thirdPartyCommissions);

        if (shopDotCom?.commission) {
          doc.shopDotComCommission = shopDotCom.commission;
        }

        if (doc.paymentMethodkey === 'cash') {
          doc.unpaidInclTotal = doc.cashCollectionInclTotal;
        }

        if (doc.isConvenienceStore) {
          const cvsData = doc.addressData;
          doc.address = `${cvsData.postCode}${cvsData.region}${cvsData.district}${cvsData.street}`;
          doc.convenienceStoreBrand = this.intl.t(cvsData.brand);
          doc.storeId = cvsData.storeId;
          doc.storeName = cvsData.storeName;
        }

        return doc;
      }), R.reject(R.equals(false)))(serialized);
    },

    exportByMethod(_ref2) {
      let {
        specs,
        docData,
        options = {}
      } = _ref2;
      const today = this.dateService.formatDate(this.dateService.getToday(), 'YYYYMMDD') | '';
      const method = specs.method;
      const subMethod = specs.subMethod;
      const paymentMethodKey = specs.paymentMethod;
      const description = specs.description;
      let translatedDescription = this.intl.t(description);

      if (R.includes('Missing translation', translatedDescription)) {
        translatedDescription = description;
      }

      if (R.equals('export_orders_total', description)) {
        translatedDescription = R.join('-', [translatedDescription, today]);
      }

      if (method === 'byPaymentMethod') {
        return {
          sheetName: translatedDescription,
          data: this[method]({
            specs,
            docData,
            subMethod,
            paymentMethodKey,
            options
          })
        };
      }

      return {
        sheetName: translatedDescription,
        data: this[method]({
          specs,
          docData,
          options
        })
      };
    },

    byDetails(_ref3) {
      let {
        specs,
        docData,
        options
      } = _ref3;
      const madeByDetail = this.makeByDetail({
        specs,
        docData,
        options
      });
      return R.propOr([], 'byDetailsWorksheet')(madeByDetail);
    },

    byDetailsWithGrandTotals(_ref4) {
      let {
        specs,
        docData,
        options
      } = _ref4;
      const madeByDetail = this.makeByDetail({
        specs,
        docData,
        options
      });
      const byDetailsWorksheet = R.propOr([], 'byDetailsWorksheet')(madeByDetail);
      const grandTotalRow = R.propOr([], 'grandTotalRow')(madeByDetail);
      return R.append(grandTotalRow)(byDetailsWorksheet);
    },

    byAllOrders(_ref5) {
      let {
        specs,
        docData,
        options
      } = _ref5;
      let index = 0;
      const separatedSpecs = this.separateInfoAndDetailSpecs({
        specs
      }); // only need first specs for column title

      const specsForColumnTitle = R.pipe(R.propOr([], 'infoSpecs'), R.take(1))(separatedSpecs);
      const columnTitle = this.getInfoColumnTitle({
        specs: specsForColumnTitle
      });
      return R.compose(R.prepend(columnTitle), R.unnest, R.map(eachDocData => {
        index = index + 1; // separate the info and detail specs

        const separatedSpecs = this.separateInfoAndDetailSpecs({
          specs
        });
        const infoSpecs = R.propOr({}, 'infoSpecs')(separatedSpecs);
        const detailSpecs = R.propOr({}, 'detailSpecs')(separatedSpecs); // get data for the info row.

        const infoRowObject = this.applySpec({
          specs: infoSpecs,
          eachDocData,
          index,
          type: 'multiple',
          options
        });
        const infoRow = infoRowObject?.infoRows || []; // get data for the detail row

        const detailRows = this.createDetailRows({
          detailSpecs,
          eachDocData,
          options
        });
        return R.concat(infoRow)(detailRows);
      }))(docData);
    },

    byAllOrdersWithOneColumnRow(_ref6) {
      let {
        specs,
        docData,
        options
      } = _ref6;
      let index = 0;
      const separatedSpecs = this.separateInfoAndDetailSpecs({
        specs
      }); // only need first specs for column title

      const specsForColumnTitleInfo = R.pipe(R.propOr([], 'infoSpecs'), R.take(1))(separatedSpecs);
      const specsForColumnTitleDetail = R.pipe(R.propOr([], 'detailSpecs'), R.take(1))(separatedSpecs);
      const combinedSpecsForColumnTitle = R.pipe(R.concat(specsForColumnTitleInfo), R.unnest, R.sortBy(R.prop('index')), RA.list)(specsForColumnTitleDetail);
      const columnTitle = this.getInfoColumnTitle({
        specs: combinedSpecsForColumnTitle,
        ignoreDetail: false
      });
      return R.compose(R.prepend(columnTitle), R.unnest, R.map(eachDocData => {
        index = index + 1; // separate the info and detail specs

        const separatedSpecsObject = this.separateInfoAndDetailSpecs({
          specs
        });
        const infoSpecs = R.propOr({}, 'infoSpecs')(separatedSpecsObject);
        const detailSpecs = R.propOr({}, 'detailSpecs')(separatedSpecsObject); // get data for the info row.

        const infoRowObject = this.applySpec({
          specs: infoSpecs,
          eachDocData,
          index,
          type: 'multiple',
          options
        });
        const infoRow = infoRowObject?.infoRowsAsObjects || []; // get data for the detail row

        const detailRows = this.createDetailRowsAsObject({
          detailSpecs,
          eachDocData,
          options
        });

        if (RA.isNilOrEmpty(detailSpecs)) {
          return R.pipe(R.head, R.sortBy(R.prop('index')), R.pluck('value'), RA.list)(infoRow);
        }

        const returnList = RA.mapIndexed((detailRow, index2) => {
          const infoRowHead = R.head(infoRow);

          if (R.equals(index2, 0)) {
            return R.pipe(R.concat(detailRow), R.sortBy(R.prop('index')), R.pluck('value'))(infoRowHead);
          }

          let emptyInfoRowHead = R.clone(infoRowHead);

          if (!specs.fillInfoRow) {
            emptyInfoRowHead = R.map(infoRow => {
              infoRow.value = '';
              return infoRow;
            })(infoRowHead);
          }

          return R.pipe(R.concat(detailRow), R.sortBy(R.prop('index')), R.pluck('value'))(emptyInfoRowHead);
        })(detailRows);
        return returnList;
      }))(docData);
    },

    byAllOrdersPerRow(_ref7) {
      let {
        specs,
        docData,
        options
      } = _ref7;
      let index = 0; // const separatedSpecs = this.separateInfoAndDetailSpecs({ specs })
      // only need first specs for column title

      const specsForColumnTitle = R.pipe(R.propOr([], 'specs'), R.take(1))(specs);
      const columnTitle = this.getInfoColumnTitle({
        specs: specsForColumnTitle,
        ignoreDetail: false
      });
      return R.compose(R.prepend(columnTitle), R.unnest, R.map(eachDocData => {
        index = index + 1;
        const specsArray = R_.dotPathOr([], 'specs', specs);
        const row = this.createMultipleRows({
          specsArray,
          eachDocData,
          index,
          options
        });
        return R.pipe(R.unnest, R.transpose)(row);
      }))(docData);
    },

    createMultipleRows(_ref8) {
      let {
        specsArray,
        eachDocData,
        index,
        options
      } = _ref8;
      return R.map(specs => {
        return R.map(spec => {
          const specNested = R.pipe(RA.list, RA.list)(spec);

          if (R.propEq('isDetail', true, spec)) {
            return R.unnest(this.createDetailRows({
              detailSpecs: specNested,
              eachDocData,
              options
            }));
          }

          const detailsProp = R.propOr('details', 'detailsProp')(options);
          let usedEachDocDataDetails = R.propOr([], detailsProp)(eachDocData);

          if (R.propEq('showSetDetails', false, options)) {
            usedEachDocDataDetails = R.reject(R.propEq('isSetChild', true))(usedEachDocDataDetails);
          }

          if (R.propEq('hideSetParents', true, options) && R.propEq('showSetDetails', true, options)) {
            usedEachDocDataDetails = R.reject(R.propEq('isSet', true))(usedEachDocDataDetails);
          }

          const detailsLength = R_.dotPathOr(1, 'length')(usedEachDocDataDetails);
          const infoRowObject = this.applySpec({
            specs: specNested,
            eachDocData,
            index,
            type: 'multiple',
            options
          });
          const infoRow = infoRowObject?.infoRows || [];
          return R.pipe(_nventor.default.safeHeadOr(''), R.repeat(R.__, detailsLength), R.unnest)(infoRow);
        })(specs);
      })(specsArray);
    },

    // @DEPRECATED
    byAllOrdersWithSummary(_ref9) {
      let {
        specs,
        docData,
        options
      } = _ref9;
      let summaryRows = this.createSummary({
        specs,
        docData,
        options
      });
      summaryRows = R.prepend([])(summaryRows);
      return R.concat(this.byAllOrders({
        specs,
        docData,
        options
      }))(summaryRows);
    },

    byAllOrdersWithOneColumnRowWithSummary(_ref10) {
      let {
        specs,
        docData,
        options
      } = _ref10;
      let summaryRows = this.createSummary({
        specs,
        docData,
        options
      });
      summaryRows = R.prepend([])(summaryRows);
      return R.concat(this.byAllOrdersWithOneColumnRow({
        specs,
        docData,
        options
      }))(summaryRows);
    },

    byAllOrdersPerRowWithSummary(_ref11) {
      let {
        specs,
        docData,
        options
      } = _ref11;
      let summaryRows = this.createSummary({
        specs,
        docData,
        options
      });
      summaryRows = R.prepend([])(summaryRows);
      return R.concat(this.byAllOrdersPerRow({
        specs,
        docData
      }))(summaryRows);
    },

    byPaymentMethod(_ref12) {
      let {
        specs,
        docData,
        subMethod = 'byAllOrdersWithSummary',
        paymentMethodKey = 'all',
        options
      } = _ref12;

      const paidStatus = docData => {
        const isGroupDocs = options.isGroupDocs || false;

        if (docData.paymentMethodKey === 'cash' || isGroupDocs && R.includes('cash', docData.paymentMethodKey)) {
          return 'unpaid';
        }

        return 'paid';
      };

      const batchGroupByPayment = R.groupBy(paidStatus)(docData);
      const applicablePaymentMethods = R.pipe(R.mapObjIndexed((groupData, groupPaymentMethodKey) => {
        if (paymentMethodKey === 'all') {
          return groupData;
        }

        if (groupPaymentMethodKey === paymentMethodKey) {
          return groupData;
        }

        return false;
      }), R.reject(R.equals(false)))(batchGroupByPayment);
      const paymentWorksheets = R.map(eachGroupByPayment => {
        return this[subMethod]({
          specs,
          docData: eachGroupByPayment,
          options
        });
      })(applicablePaymentMethods);
      return R.unnest(R.values(paymentWorksheets));
    },

    // EXPORT HELPER

    /**
     * get only column title for info. use this if the detail column title is not needed.
     * @param {Array} specsData  contains array of array from customer's specs
     * @return {Array}  array of column title in one row
     */
    getInfoColumnTitle(_ref13) {
      let {
        specs,
        ignoreDetail = true
      } = _ref13;
      return R.pipe(_nventor.default.safeHeadOr([]), R.map(spec => {
        const isDetail = R.prop('isDetail')(spec);

        if (isDetail && ignoreDetail) {
          return false;
        }

        const label = R.prop('label')(spec);
        let title = R.prop('column', spec);

        if (title) {
          title = this.intl.t(title);
        }

        if (label) {
          title = label;
        }

        return title || '';
      }), R.reject(R.equals(false)))(specs); // let columnTitle = []
      // R.forEach(specsForRow => {
      //   let columnTitleRow = []
      //   R.forEach(spec => {
      //     if (RA.isNilOrEmpty(R.prop('isDetail', spec))) {
      //       const label = R.prop('label')(spec)
      //       let title = R.prop('column', spec)
      //       if (title) {
      //         title = this.intl.t(title)
      //       }
      //       if (label) {
      //         title = label
      //       }
      //       columnTitleRow = R.append(title, columnTitleRow)
      //     }
      //   })(specsForRow)
      //   columnTitle = R.append(columnTitleRow, columnTitle)
      // })(specs)
      // return R.unnest(columnTitle)
    },

    getColumnsPrep(_ref14) {
      let {
        specs,
        docData,
        options
      } = _ref14;
      const infoColumnNameList = this.getInfoColumnTitle({
        specs: specs.specs
      });
      const [detailColumnNameList, detailItemKeyList, detailItemCodeList] = this.getColumnNameList({
        specs,
        docData,
        options
      });
      return {
        infoColumnNameList,
        detailColumnNameList,
        detailItemKeyList,
        detailItemCodeList
      };
    },

    /**
     * get all column title (info and detail in 1 row)
     * @param {Object} specsData
     * @param {Object} specsData.specs    specs from customer
     * @param {Object} specsData.docData  serialized dataset
     * @return {Array}  array of column title in one row
     */
    getAllColumnTitle(_ref15) {
      let {
        specs,
        docData,
        options
      } = _ref15;
      const columnsPrep = this.getColumnsPrep({
        specs,
        docData,
        options
      });
      const infoColumnNameList = R.propOr([], 'infoColumnNameList')(columnsPrep);
      const detailColumnNameList = R.propOr([], 'detailColumnNameList')(columnsPrep);
      return R.concat(infoColumnNameList, detailColumnNameList);
    },

    makeByDetail(_ref16) {
      let {
        specs,
        docData,
        options
      } = _ref16;
      let index = 0; // get all column title in one row

      const columnNameList = this.getAllColumnTitle({
        specs,
        docData,
        options
      }); // get item key list for validating unique item (even if it have different name)

      const columnsPrep = this.getColumnsPrep({
        specs,
        docData,
        options
      });
      const detailItemKeyList = R.propOr({}, 'detailItemKeyList')(columnsPrep);
      const detailItemCodeList = R.propOr({}, 'detailItemCodeList')(columnsPrep);
      let combinedRows = [];
      let grandTotal = R.repeat(0, detailItemKeyList.length);
      const separatedSpecs = this.separateInfoAndDetailSpecs({
        specs
      });
      R.forEach(eachDocData => {
        index = index + 1; // get only infospecs with separate function

        const infoSpecs = R.propOr({}, 'infoSpecs')(separatedSpecs); // get info row from each data

        const infoRowObject = this.applySpec({
          specs: infoSpecs,
          eachDocData,
          index,
          type: 'single',
          options
        });
        const infoRow = infoRowObject?.infoRows || []; // get detail row from each data

        const detailRow = this.getDetailQty({
          eachDocData,
          detailItemKeyList,
          options
        }); // make a combined row of info and detail

        const combinedRow = R.concat(infoRow, detailRow); // append all combined row into one combined rows

        combinedRows = R.append(combinedRow, combinedRows); // calculate grand total for each item while at it

        grandTotal = R_.mapIndexed((value, index) => R.add(value, detailRow[index]))(grandTotal);
      })(docData); // setting place for grand total below all of combined rows.

      let toRepeat = R.pipe(R.propOr([], 'infoColumnNameList'), R.length)(columnsPrep);
      const toRepeatForItemCode = R.clone(toRepeat);

      if (toRepeat) {
        toRepeat = toRepeat - 1;
      }

      let blankSpace = R.repeat('', toRepeat);
      blankSpace = R.append('Total', blankSpace);
      let byDetailsWorksheet = RA.list(columnNameList);

      if (RA.isNotNilOrEmpty(detailItemCodeList)) {
        let itemCodeRow = R.repeat('', toRepeatForItemCode);
        itemCodeRow = R.append(detailItemCodeList, itemCodeRow);
        itemCodeRow = R.flatten(itemCodeRow);
        byDetailsWorksheet = R.concat(byDetailsWorksheet, RA.list(itemCodeRow));
      }

      byDetailsWorksheet = R.concat(byDetailsWorksheet, combinedRows);
      const grandTotalRow = R.concat(blankSpace, grandTotal);
      return {
        byDetailsWorksheet,
        grandTotalRow
      };
    },

    getDetailQty(_ref17) {
      let {
        eachDocData,
        detailItemKeyList,
        options
      } = _ref17;
      const detailsProp = R.propOr('details', 'detailsProp')(options);
      const toDetails = R.propOr([], detailsProp)(eachDocData);
      let detailRow = [];
      R.forEach(detailItemKey => {
        let detailValue = 0;
        R.forEach(eachDocDataDetails => {
          const itemKeyVal = R.prop('itemKey')(eachDocDataDetails);

          if (detailItemKey === 'total' && itemKeyVal) {
            detailValue += parseFloat(this.newBig(R.prop('qty')(eachDocDataDetails)).toFixed(0) || 0);
          } else {
            if (RA.isNilOrEmpty(itemKeyVal)) {
              const item = R.prop('item')(eachDocDataDetails);

              if (detailItemKey === item) {
                detailValue += parseFloat(this.newBig(R.prop('qty')(eachDocDataDetails)).toFixed(0) || 0);
              }
            } else {
              if (detailItemKey === itemKeyVal) {
                detailValue += parseFloat(this.newBig(R.prop('qty')(eachDocDataDetails)).toFixed(0) || 0);
              }
            }
          }
        })(toDetails);
        detailRow = R.append(detailValue)(detailRow);
      })(detailItemKeyList);
      return detailRow;
    },

    createSummary(_ref18) {
      let {
        specs,
        docData,
        options
      } = _ref18;
      // use default details summary for now since customer is complaining
      const detailSpecs = [{
        value: '',
        prop: '',
        isDetail: true,
        label: '',
        transforms: []
      }, {
        value: 'qty',
        prop: 'qty',
        type: 'number',
        isDetail: true,
        label: 'Qty',
        transforms: []
      }, {
        value: 'item',
        prop: 'item',
        isDetail: true,
        label: 'Item',
        transforms: []
      }, {
        value: 'code',
        prop: 'itemCode',
        isDetail: true,
        label: 'Code',
        transforms: []
      }, {
        value: 'inclusive amount',
        prop: 'postDiscInclAmt',
        type: 'number',
        isDetail: true,
        label: 'Incl. Amount',
        transforms: []
      }]; // const detailSpecs = R.pipe(
      //   R.propOr([], 'specs'),
      //   R.flatten,
      //   R.filter(R.propEq('isDetail', true)),
      //   R.reject(R.propEq('prop', 'discInclPrice'))
      // )(specs)

      const columnName = R.map(detailSpec => {
        const name = R.prop('value')(detailSpec);

        if (name) {
          return this.intl.t(name);
        }

        return '';
      })(detailSpecs);
      const propNames = R.map(detailSpec => {
        return R.prop('prop')(detailSpec);
      })(detailSpecs);

      const groupByItem = eachDocDataDetails => {
        const itemKey = R.prop('itemKey')(eachDocDataDetails);

        if (RA.isNotNilOrEmpty(itemKey)) {
          return itemKey;
        }

        return R.prop('item')(eachDocDataDetails);
      };

      const detailsProp = R.propOr('details', 'detailsProp')(options);
      let grandTotal = 0;
      let data = [];
      data = R.pipe(R.pluck(detailsProp), R.flatten, data => {
        if (R.propEq('hideSetParents', false, options || R.propEq('showSetDetails', false, options))) {
          return R.reject(R.propEq('isSetChild', true))(data);
        }

        if (R.propEq('hideSetParents', true, options) && R.propEq('showSetDetails', true, options)) {
          return R.reject(R.propEq('isSet', true))(data);
        }

        return data;
      }, R.groupBy(groupByItem), R.mapObjIndexed(groupData => {
        let propValues = [];
        R.forEach(prop => {
          if (R.isEmpty(prop)) {
            // blank space
            propValues = R.append('', propValues);
          }

          if (prop === 'qty') {
            // qty
            const groupTotalQty = parseInt(this.sumPropAndAutoRound(0, 'qty', groupData)) || 0;
            propValues = R.append(groupTotalQty, propValues);
          } // if (prop === 'discInclPrice') {
          //   // discInclPrice
          //   const groupTotalInclAmt = parseInt(this.sumPropAndAutoRound(0, 'discInclPrice', groupData)) || 0
          //   grandTotal = grandTotal + groupTotalInclAmt
          //   propValues = R.append('', propValues)
          // }


          if (prop === 'postDiscInclAmt') {
            // postDiscInclAmt
            // need to filter isSetChild again when show set details is on (don't include the amt in summary bcs it's already represented by isSet)
            const filteredGroupData = R.reject(data => {
              const hideSetParents = R.propEq('hideSetParents', true, options);
              const isSetChild = R.propEq('isSetChild', true)(data);

              if (!hideSetParents && isSetChild) {
                return true;
              }

              return false;
            })(groupData);
            const groupTotalInclAmt = parseInt(this.sumPropAndAutoRound(0, 'postDiscInclAmt', filteredGroupData)) || 0;
            grandTotal = grandTotal + groupTotalInclAmt;
            propValues = R.append(groupTotalInclAmt, propValues);
          }

          if (prop === 'item') {
            // item
            const item = R.pipe(R.head, R.propOr('', 'item'))(groupData);
            propValues = R.append(item, propValues);
          }

          if (prop === 'itemCode') {
            // itemCode
            const itemCode = R.pipe(R.head, R.propOr('', 'itemCode'))(groupData);
            propValues = R.append(itemCode, propValues);
          } // add more for summary

        })(propNames);
        return propValues;
      }), R.values, R.concat([[], columnName]), R.append([]))(docData);
      let blankSpace = [];

      if (columnName.length >= 2) {
        blankSpace = R.repeat('', columnName.length - 2);
      }

      data = R.append(R.concat(blankSpace, ['total', grandTotal]), data);
      return data;
    },

    fillItemKeyAndColumnNameList(_ref19) {
      let {
        itemKeyVal = '',
        itemKeyList,
        itemCodeVal = '',
        itemCodeList,
        itemVal = '',
        columnNameList,
        customColumnTitle = itemVal
      } = _ref19;

      if (RA.isNotNilOrEmpty(itemKeyVal)) {
        if (!R.includes(itemKeyVal, itemKeyList)) {
          itemKeyList = R.append(itemKeyVal, itemKeyList);
          itemCodeList = R.append(itemCodeVal, itemCodeList);
          columnNameList = R.append(customColumnTitle, columnNameList);
        }
      } else {
        if (!R.includes(itemVal, itemKeyList)) {
          itemKeyList = R.append(itemVal, itemKeyList);
          itemCodeList = R.append(itemCodeVal, itemCodeList);
          columnNameList = R.append(customColumnTitle, columnNameList);
        }
      }

      return [itemKeyList, itemCodeList, columnNameList];
    },

    insertTotalColumn(_ref20) {
      let {
        columnNameList,
        itemKeyList,
        itemCodeList
      } = _ref20;
      itemKeyList = R.prepend('total', itemKeyList);
      itemCodeList = R.prepend('', itemCodeList);
      const totalColumnName = this.intl.t('total qtys');
      columnNameList = R.prepend(totalColumnName, columnNameList);
      return [itemKeyList, itemCodeList, columnNameList];
    },

    getColumnNameList(_ref21) {
      let {
        specs,
        docData,
        options
      } = _ref21;
      let itemKeyList = [];
      let columnNameList = [];
      let itemCodeList = [];

      if (!specs.autoGenerateByDetailsSummary) {
        // check if there's custom detail
        R.forEach(array => {
          R.forEach(spec => {
            if (spec.isDetail) {
              const itemKeyVal = R.prop('itemKey')(spec);
              const itemVal = R.prop('item')(spec);
              const itemCodeVal = R.prop('itemCode')(spec);
              let customColumnTitle = R.prop('label')(spec); // || itemVal;

              if (customColumnTitle === 'item') {
                customColumnTitle = itemVal;
              }

              [itemKeyList, itemCodeList, columnNameList] = this.fillItemKeyAndColumnNameList({
                itemKeyVal,
                itemKeyList,
                itemCodeVal,
                itemCodeList,
                itemVal,
                columnNameList,
                customColumnTitle
              });
            }
          })(array);
        })(specs.specs);
      } else {
        const detailsProp = R.propOr('details', 'detailsProp')(options);
        let allDocDetails = R.pipe(R.pluck(detailsProp), R.flatten, R.reject(RA.isNilOrEmpty), R.filter(R.prop('itemKey')))(docData);

        if (specs.shopSortOrderDetailsByIndex) {
          allDocDetails = R.sortWith([R.ascend(R.path(['index']))])(allDocDetails);
        }

        R.forEach(eachDocDataDetails => {
          const itemKeyVal = R.prop('itemKey')(eachDocDataDetails);
          const itemVal = R.prop('item')(eachDocDataDetails);
          const itemCodeVal = R.prop('itemCode')(eachDocDataDetails);
          [itemKeyList, itemCodeList, columnNameList] = this.fillItemKeyAndColumnNameList({
            itemKeyVal,
            itemKeyList,
            itemCodeVal,
            itemCodeList,
            itemVal,
            columnNameList
          });
        })(allDocDetails);
      }

      if (specs.generateTotalQtyPerRow) {
        [itemKeyList, itemCodeList, columnNameList] = this.insertTotalColumn({
          columnNameList,
          itemKeyList,
          itemCodeList
        });
      }

      return [columnNameList, itemKeyList, itemCodeList];
    },

    createDetailRows(_ref22) {
      let {
        detailSpecs = [],
        eachDocData,
        options
      } = _ref22;
      detailSpecs = R.unnest(detailSpecs);
      let detailRows = [];
      const detailsProp = R.propOr('details', 'detailsProp')(options);
      const toDetails = R.propOr([], detailsProp)(eachDocData);
      R.pipe(docData => {
        if (R.propEq('showSetDetails', false, options)) {
          return R.reject(R.propEq('isSetChild', true))(docData);
        }

        if (R.propEq('hideSetParents', true, options) && R.propEq('showSetDetails', true, options)) {
          return R.reject(R.propEq('isSet', true))(docData);
        }

        return docData;
      }, R.forEach(eachDocDataDetails => {
        // make detail for each item bought
        let detailRow = [];
        R.forEach(detailSpec => {
          const propValue = R.prop('prop')(detailSpec); // add blank

          if (RA.isNilOrEmpty(propValue)) {
            detailRow = R.append('', detailRow);
          } // qty


          if (propValue === 'qty') {
            const query = parseInt(this.newBig(R.prop('qty')(eachDocDataDetails)).toFixed(0) || 0);
            detailRow = R.append(query, detailRow);
          } // item


          if (propValue === 'item') {
            let query = R.prop('item')(eachDocDataDetails);

            if (eachDocDataDetails.isSet) {
              query = `${this.intl.t('isSet')}: ${query}`;
            } // if (eachDocDataDetails.isSetChild) {
            //   const hideSetParents = R.propEq('hideSetParents', true, options)
            //   if (!hideSetParents) {
            //     query = `${this.intl.t('product_sets_contents')}: ${query}`
            //   }
            // }


            detailRow = R.append(query, detailRow);
          } // itemCode


          if (propValue === 'itemCode') {
            const query = R.prop('itemCode')(eachDocDataDetails);
            detailRow = R.append(query, detailRow);
          } // postDiscInclAmt


          if (propValue === 'discInclPrice') {
            let query = R.prop('discInclPrice')(eachDocDataDetails);
            query = parseFloat(query) || 0;

            if (eachDocDataDetails.isSetChild && !options.hideSetParents) {
              query = '';
            }

            detailRow = R.append(query, detailRow);
          } // postDiscInclAmt


          if (propValue === 'postDiscInclAmt') {
            let query = R.prop('postDiscInclAmt')(eachDocDataDetails);
            query = parseFloat(query) || 0;

            if (eachDocDataDetails.isSetChild && !options.hideSetParents) {
              query = '';
            }

            detailRow = R.append(query, detailRow);
          } // total
          // if (propValue === 'total') {
          //   let postDiscInclAmtQuery = R.prop('postDiscInclAmt')(eachDocDataDetails)
          //   postDiscInclAmtQuery = parseFloat(postDiscInclAmtQuery) || 0
          //   const qtyQuery = parseInt(this.newBig(R.prop('qty')(eachDocDataDetails)).toFixed(0) || 0)
          //   let query = parseFloat(this.newBig(postDiscInclAmtQuery).times(qtyQuery).toFixed(0))
          //   if (eachDocDataDetails.isSetChild) {
          //     query = ''
          //   }
          //   detailRow = R.append(query, detailRow)
          // }
          // add more below for custom detailRow
          // intentionally blank
          // intentionally blank
          // intentionally blank
          // intentionally blank

        })(detailSpecs); // append each item detail to make multiple item detail (if there are more than one item bought)

        detailRows = R.append(detailRow, detailRows);
      }))(toDetails);
      return detailRows;
    },

    createDetailRowsAsObject(_ref23) {
      let {
        detailSpecs = [],
        eachDocData,
        options
      } = _ref23;
      detailSpecs = R.unnest(detailSpecs);
      let detailRows = [];
      const detailsProp = R.propOr('details', 'detailsProp')(options);
      const toDetails = R.propOr([], detailsProp)(eachDocData);
      R.pipe(docData => {
        if (R.propEq('showSetDetails', false, options)) {
          return R.reject(R.propEq('isSetChild', true))(docData);
        }

        if (R.propEq('hideSetParents', true, options) && R.propEq('showSetDetails', true, options)) {
          return R.reject(R.propEq('isSet', true))(docData);
        }

        return docData;
      }, R.forEach(eachDocDataDetails => {
        // make detail for each item bought
        let detailRow = [];
        R.forEach(detailSpec => {
          const propValue = R.prop('prop')(detailSpec);
          const toAppend = {
            index: detailSpec.index
          }; // add blank

          if (RA.isNilOrEmpty(propValue)) {
            const query = '';
            toAppend.value = query;
            detailRow = R.append(toAppend, detailRow);
          } // qty


          if (propValue === 'qty') {
            const query = parseInt(this.newBig(R.prop('qty')(eachDocDataDetails)).toFixed(0) || 0);
            toAppend.value = query;
            detailRow = R.append(toAppend, detailRow);
          } // item


          if (propValue === 'item') {
            let query = R.prop('item')(eachDocDataDetails);

            if (eachDocDataDetails.isSet) {
              query = `${this.intl.t('isSet')}: ${query}`;
            } // if (eachDocDataDetails.isSetChild) {
            //   const hideSetParents = R.propEq('hideSetParents', true, options)
            //   if (!hideSetParents) {
            //     query = `${this.intl.t('product_sets_contents')}: ${query}`
            //   }
            // }


            toAppend.value = query;
            detailRow = R.append(toAppend, detailRow);
          } // itemCode


          if (propValue === 'itemCode') {
            const query = R.prop('itemCode')(eachDocDataDetails);
            toAppend.value = query;
            detailRow = R.append(toAppend, detailRow);
          } // postDiscInclAmt


          if (propValue === 'discInclPrice') {
            let query = R.prop('discInclPrice')(eachDocDataDetails);
            query = parseFloat(query) || 0;

            if (eachDocDataDetails.isSetChild && !options.hideSetParents) {
              query = '';
            }

            toAppend.value = query;
            detailRow = R.append(toAppend, detailRow);
          } // postDiscInclAmt


          if (propValue === 'postDiscInclAmt') {
            let query = R.prop('postDiscInclAmt')(eachDocDataDetails);
            query = parseFloat(query) || 0;

            if (eachDocDataDetails.isSetChild && !options.hideSetParents) {
              query = '';
            }

            toAppend.value = query;
            detailRow = R.append(toAppend, detailRow);
          } // add more below for custom detailRow
          // intentionally blank
          // intentionally blank
          // intentionally blank
          // intentionally blank

        })(detailSpecs); // append each item detail to make multiple item detail (if there are more than one item bought)

        detailRows = R.append(detailRow, detailRows);
      }))(toDetails);
      return detailRows;
    },

    /**
     * separate info and detail specs to be used separately
     * @param  {Object} specsData contains specs from customer
     * @return {Object} separatedSpecs object containing info and detail specs
     */
    separateInfoAndDetailSpecs(_ref24) {
      let {
        specs
      } = _ref24;
      let infoSpecs = [];
      let detailSpecs = [];
      R.pipe(R.propOr([], 'specs'), R.forEach(array => {
        let infoSpec = [];
        let detailSpec = [];
        RA.mapIndexed((spec, idx) => {
          const isDetail = R.prop('isDetail')(spec);
          spec.index = idx;

          if (isDetail) {
            detailSpec = R.append(spec, detailSpec);
          } else {
            infoSpec = R.append(spec, infoSpec);
          }
        })(array);

        if (RA.isNotNilOrEmpty(infoSpec)) {
          infoSpecs = R.append(infoSpec, infoSpecs);
        }

        if (RA.isNotNilOrEmpty(detailSpec)) {
          detailSpecs = R.append(detailSpec, detailSpecs);
        }
      }))(specs);
      return {
        infoSpecs,
        detailSpecs
      };
    },

    applySpec(_ref25) {
      let {
        specs,
        eachDocData,
        index,
        type = 'single',
        options
      } = _ref25;
      let infoRows = [];
      let infoRowsAsObjects = [];
      R.forEach(array => {
        let infoRow = [];
        let infoRowsAsObject = [];
        R.forEach(spec => {
          const value = this.transform_value({
            spec,
            eachDocData,
            index,
            specOptions: options
          });
          const objValue = {
            value,
            index: spec.index
          };

          if (R.is(Array, value)) {
            infoRow = R.pipe(R.concat(infoRow), R.flatten)(value);
            infoRowsAsObject = R.pipe(RA.ensureArray, R.concat(infoRowsAsObject), R.flatten)(objValue);
          } else {
            infoRow = R.append(value, infoRow);
            infoRowsAsObject = R.append(objValue, infoRowsAsObject);
          }
        })(array);
        infoRows = R.append(infoRow, infoRows);
        infoRowsAsObjects = R.append(infoRowsAsObject, infoRowsAsObjects);
      })(specs);

      if (type === 'single') {
        return {
          infoRows: R.unnest(infoRows),
          infoRowsAsObjects: R.unnest(infoRowsAsObjects)
        };
      }

      return {
        infoRows,
        infoRowsAsObjects
      };
    },

    transform_value(_ref26) {
      let {
        spec,
        eachDocData,
        index,
        specOptions
      } = _ref26;
      let value = ''; // getprop

      value = this.transform_getProp({
        spec,
        eachDocData,
        value
      }); // transform Fn

      const transforms = R.prop('transforms')(spec);

      if (RA.isNotNilOrEmpty(transforms)) {
        if (R.is(String, transforms)) {
          value = this[`transform_${transforms}`]({
            spec,
            eachDocData,
            value,
            options: {},
            index
          });
        } else {
          R.map(eachFn => {
            const theFn = R.prop('fn')(eachFn);
            const options = R.prop('options')(eachFn);

            if (R.is(Function, theFn)) {
              value = theFn({
                spec,
                eachDocData,
                value,
                specOptions,
                options,
                index
              });
            } else if (theFn) {
              value = this[`transform_${theFn}`]({
                spec,
                eachDocData,
                value,
                specOptions,
                options,
                index
              });
            }
          })(R.flatten(RA.list(transforms)));
        }
      } // type


      const type = R.prop('type')(spec);

      if (type && RA.isNotNilOrEmpty(type)) {
        value = this[`transform_${type}`]({
          spec,
          eachDocData,
          value
        });
      }

      return value;
    },

    transform_concat(_ref27) {
      let {
        eachDocData,
        spec,
        specOptions,
        options,
        index
      } = _ref27;
      const joinWith = R.propOr(' ', 'joinWith')(options);
      const joinUniqueOnly = R.propOr(true, 'joinUniqueOnly')(options);
      const availableNormalSpecs = specOptions?.availableNormalSpecs || [];
      return R.pipe(R.propOr([], 'prop'), RA.ensureArray, props => {
        return R.map(prop => {
          const propSpec = R.find(R.propEq('prop', prop))(availableNormalSpecs); // exclude custom since new model wouldn't have customText

          if (RA.isNotNilOrEmpty(propSpec) && !R.equals(prop, 'custom')) {
            return this.transform_value({
              spec: propSpec,
              eachDocData,
              index
            });
          }

          return this.transform_getProp({
            prop,
            spec,
            eachDocData
          });
        })(props);
      }, R.reject(RA.isNilOrEmpty), values => {
        if (joinUniqueOnly) {
          return R.uniq(values);
        }

        return values;
      }, R.join(joinWith))(options);
    },

    transform_joinArray(_ref28) {
      let {
        eachDocData,
        spec,
        specOptions,
        options,
        index
      } = _ref28;
      const joinWith = R.propOr(' ', 'joinWith')(options);
      const availableNormalSpecs = specOptions?.availableNormalSpecs || [];
      return R.pipe(R.propOr([], 'prop'), RA.ensureArray, props => {
        return R.map(prop => {
          const propSpec = R.find(R.propEq('prop', prop))(availableNormalSpecs); // exclude custom since new model wouldn't have customText

          if (RA.isNotNilOrEmpty(propSpec) && !R.equals(prop, 'custom')) {
            return this.transform_value({
              spec: propSpec,
              eachDocData,
              index
            });
          }

          return this.transform_getProp({
            prop,
            spec,
            eachDocData
          });
        })(props);
      }, R.reject(RA.isNilOrEmpty), values => {
        return R.uniq(values);
      }, R.join(joinWith))(options);
    },

    transform_tableLookup(_ref29) {
      let {
        eachDocData,
        spec,
        options,
        value
      } = _ref29;
      const tableLookupArray = R.propOr([], 'tableLookupArray')(options);
      const prop = R.propOr([], 'prop')(spec);

      if (RA.isNilOrEmpty(value)) {
        value = R_.dotPath(prop)(eachDocData);
      }

      const indexofValueInInputArray = R.findIndex(R.propEq('input', value))(tableLookupArray);

      if (!R.equals(indexofValueInInputArray, -1)) {
        value = R.propOr('', 'output')(tableLookupArray[indexofValueInInputArray]);
      }

      return value;
    },

    transform_date(_ref30) {
      let {
        spec,
        value
      } = _ref30;
      const format = R.prop('dateFormat')(spec) || 'YYYY-MM-DD';
      const fillEmpty = R.propOr(false, 'fillEmpty')(spec);

      if (RA.isNilOrEmpty(value)) {
        if (fillEmpty) {
          value = this.dateService.getMoment();
          return this.dateService.formatDate(value, format);
        }

        return '';
      } else {
        return this.dateService.formatDate(value, format);
      }
    },

    transform_index(_ref31) {
      let {
        index
      } = _ref31;
      return index;
    },

    transform_number(_ref32) {
      let {
        value
      } = _ref32;
      return parseFloat(value) || 0;
    },

    transform_getPropOr(_ref33) {
      let {
        eachDocData,
        spec,
        options
      } = _ref33;
      let transformResult = this.transform_getProp({
        spec,
        eachDocData
      });

      if (RA.isNilOrEmpty(transformResult)) {
        const propOr = options?.propOr || '';
        transformResult = this.transform_getProp({
          prop: propOr,
          spec,
          eachDocData
        });
      }

      return transformResult;
    },

    transform_getProp(_ref34) {
      let {
        prop,
        spec,
        eachDocData
      } = _ref34;

      if (RA.isNilOrEmpty(prop)) {
        prop = R.prop('prop')(spec);
      }

      if (!R.is(String, prop)) {
        return '';
      } // used if you need some prop to be checked before returning getProp
      // (e.g. you need to see if docData.hasAdvancedAddress === true before getting countryCode prop, etc.)
      // only get prop if checkProp is true


      const checkProp = R.pipe(R.propOr('', 'checkProp'), checkProp => {
        if (RA.isNotNilOrEmpty(checkProp)) {
          return R.propOr(true, checkProp)(eachDocData);
        }

        return true;
      })(spec);

      if (!checkProp) {
        return '';
      }

      let propValue = '';

      if (RA.isNotNilOrEmpty(prop)) {
        propValue = R_.dotPath(prop)(eachDocData);

        if (R.equals(prop, 'custom')) {
          propValue = R.prop('customText')(spec);
        }
      } // select spec child, select prop=paymentMethodKey, availableNormalSpecs { prop: 'paymentMethodKey', translate: true } => becomes the spec child


      if (spec.translateFn) {
        return spec.translateFn(propValue, eachDocData);
      }

      let translate = spec.translate;
      const translateProp = spec.translateProp;

      if (translateProp) {
        translate = R_.dotPath(translateProp)(eachDocData);
      }

      if (translate) {
        return this.intl.t(propValue);
      } // @TODO: remove this and use the above method


      if (prop === 'paymentMethodKey') {
        return this.getPaymentMethodProp(eachDocData, propValue);
      }

      if (prop === 'address') {
        return this.getAddressProp(eachDocData, propValue);
      }

      return propValue;
    },

    transform_round(_ref35) {
      let {
        value,
        options
      } = _ref35;
      const rounding = R.prop('rounding')(options);
      value = parseFloat(this.newBig(value).toFixed(rounding));
      return value;
    },

    transform_today(_ref36) {
      let {
        spec
      } = _ref36;
      const format = R.prop('dateFormat')(spec) || 'YYYY-MM-DD';
      const today = this.dateService.getMoment();
      return this.dateService.formatDate(today, format);
    },

    transform_getItemData(_ref37) {
      let {
        spec,
        eachDocData,
        value,
        typeOfTransformsArgs
      } = _ref37;
      let itemData = '';
      const options = R.propOr('', 'options')(spec);
      const hasAnyItemData = R.pipe(R.pick(['itemKey', 'itemCode', 'item']), R.reject(RA.isNilOrEmpty))(spec);

      if (RA.isNotNilOrEmpty(options)) {
        typeOfTransformsArgs = R.prop('prop')(options);
      }

      if (RA.isNotNilOrEmpty(hasAnyItemData)) {
        const hasItemPropValue = R.includes(R.__, R.values(hasAnyItemData));
        const detailsProp = R.propOr('details', 'detailsProp')(options);
        const toTransformData = R.propOr([], detailsProp)(eachDocData);
        R.map(eachDocDataDetails => {
          const docHasItemKey = R.prop('itemKey')(eachDocDataDetails);
          const docHasItemCode = R.prop('itemCode')(eachDocDataDetails);
          const docHasItem = R.prop('item')(eachDocDataDetails);
          const hasAnyItemProps = R.any(hasItemPropValue);

          if (hasAnyItemProps([docHasItemKey, docHasItemCode, docHasItem])) {
            const productQty = this.newBig(R.prop(typeOfTransformsArgs)(eachDocDataDetails));
            value = parseFloat(this.newBig(value).plus(productQty));
            itemData = R.props(['itemCode', 'itemKey'])(eachDocDataDetails);
          }
        })(toTransformData);
        itemData = R.append(value)(itemData);
      }

      return itemData;
    },

    transform_getItemCode(_ref38) {
      let {
        spec,
        eachDocData,
        value = '',
        typeOfTransformsArgs = 'qty'
      } = _ref38;
      const itemData = this.transform_getItemData({
        spec,
        eachDocData,
        value,
        typeOfTransformsArgs
      });
      return R.dropLast(1, itemData);
    },

    transform_getSum(_ref39) {
      let {
        spec,
        eachDocData,
        value = '',
        typeOfTransformsArgs = 'qty'
      } = _ref39;
      const itemData = this.transform_getItemData({
        spec,
        eachDocData,
        value,
        typeOfTransformsArgs
      });
      return R.last(itemData);
    },

    getPaymentMethodProp(eachDocData, propValue) {
      const hasPaymentMethodCustomLabel = R.path(['paymentMethodCustomLabel'])(eachDocData);

      if (hasPaymentMethodCustomLabel) {
        const paymentMethodCustomLabel = R_.dotPath('paymentMethodCustomLabel')(eachDocData);

        if (RA.isArray(propValue)) {
          // for grouped docs, payment method is array ['cash', 'credicCard']
          const propVal = R.head(propValue) || '';

          if (propVal) {
            return `${paymentMethodCustomLabel} (${this.intl.t(propVal)})`;
          } else {
            `${paymentMethodCustomLabel}`;
          }
        } else {
          return `${paymentMethodCustomLabel} (${this.intl.t(propValue)})`;
        }
      }

      if (RA.isArray(propValue)) {
        // for grouped docs, payment method is array ['cash', 'credicCard']
        const propVal = R.head(propValue) || '';

        if (propVal) {
          return this.intl.t(propVal);
        } else {
          return '';
        }
      } else {
        return this.intl.t(propValue);
      }
    },

    getAddressProp(eachDocData, propValue) {
      const isConvenienceStore = R.pathOr(false, ['isConvenienceStore'])(eachDocData);

      if (isConvenienceStore) {
        const convenienceStoreData = R.pathOr({}, ['convenienceStoreData'])(eachDocData);
        const brand = convenienceStoreData?.brand || '';
        const storeName = convenienceStoreData?.storeName || '';
        const storeAddress = convenienceStoreData?.storeAddress || '';
        const storeId = convenienceStoreData?.storeId || '';
        return `${this.intl.t(brand)} - ${storeName} - ${storeAddress} - (${this.intl.t('storeId')}: ${storeId})`;
      }

      return propValue;
    }

  });

  _exports.default = _default;
});