define("client/pods/guests/shop/report/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    guests: Ember.inject.service(),

    model(params) {
      const guestResource = params.guest_resource;
      this.guests.setup(guestResource);
      return {
        guestResource
      };
    },

    redirect(model) {
      const token = this.guests.getToken();

      if (!token) {
        const resource = model.guestResource;
        return this.transitionTo('guests.shop.login', resource);
      }
    }

  });

  _exports.default = _default;
});