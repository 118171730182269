define("client/pods/components/contacts/statement-details/statement-transactions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G8BM2crd",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"component\",[[24,[\"txComponent\"]]],[[\"data\",\"showStatementDetails\",\"isStatementPrint\",\"txDateZ\",\"cumulativeTotal\"],[[24,[\"data\"]],[23,0,[\"showStatementDetails\"]],[23,0,[\"isStatementPrint\"]],[23,0,[\"txDateZ\"]],[23,0,[\"cumulativeTotal\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/statement-details/statement-transactions/template.hbs"
    }
  });

  _exports.default = _default;
});