define("client/pods/components/extensions/apps/tw/shopee/products/sync/table-view/detail-row/component", ["exports", "@glimmer/component", "ramda", "ember-concurrency-decorators"], function (_exports, _component, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwShopeeProductsSyncTableViewDetailRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('extensions/apps/tw/shopee/products'), _dec3 = Ember._tracked, (_class = class ExtensionsAppsTwShopeeProductsSyncTableViewDetailRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "shopeeProductsService", _descriptor2, this);

      _initializerDefineProperty(this, "showUploadsRow", _descriptor3, this);
    }

    get shouldSync() {
      const isErpSync = R.pathEq(['args', 'model', '_data', 'sync', 'erp'], true)(this);
      const isShopeeSync = R.pathEq(['args', 'model', '_data', 'sync', 'shopee'], true)(this);

      if (isErpSync && isShopeeSync) {
        return false;
      }

      return true;
    }

    get isExistInProductsStyle() {
      const isUpToDate = this.args.model?._data?.sync?.isUpToDate === true;
      const isExistInProducts = this.args.model?._data?.sync?.isExistInProducts === true;
      const isExistInShopee = this.args.model?._data?.sync?.isExistInShopee === true;

      if (isExistInProducts) {
        if (isExistInShopee) {
          if (isUpToDate) {
            return {
              icon: 'fas fa-check-circle',
              classNames: 'has-text-success'
            };
          }

          return {
            icon: 'fas fa-exclamation-triangle',
            classNames: 'has-text-warning'
          };
        }

        return {
          icon: 'fas fa-check-circle',
          classNames: 'has-text-success'
        };
      }

      return {
        icon: 'fas fa-times-circle',
        classNames: 'has-text-danger'
      };
    }

    *setupTask() {
      yield this.args.onReloadSearchRecordsTask.perform();
    }

    syncProduct(model) {
      var _this = this;

      let appendPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return function* () {
        return yield _this.shopeeProductsService.syncProduct.perform({
          model,
          appendPath
        });
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "shopeeProductsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showUploadsRow", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "syncProduct", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "syncProduct"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwShopeeProductsSyncTableViewDetailRowComponent;
});