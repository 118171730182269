define("client/pods/components/contacts/statement-details/statement-transactions/component", ["exports", "ramda", "ramda-adjunct", "client/constants/index"], function (_exports, R, RA, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bigService: Ember.inject.service('big'),
    tagName: '',
    txComponent: Ember.computed('data._data.{docName,txType}', function () {
      const data = this?.data || {};

      if (RA.isNotNilOrEmpty(data)) {
        let txComponent = data.getData('docName') || '';

        if (!txComponent) {
          txComponent = data.getData('txType') || '';
        }

        if (!txComponent) {
          txComponent = 'commission';
        }

        if (txComponent && R.is(String, txComponent)) {
          txComponent = txComponent.toLowerCase();
        }

        return `contacts/statement-details/statement-transactions/tx-${txComponent}`;
      }

      return '';
    }),
    txDateZ: Ember.computed('data._data.{docName,txType}', function () {
      let txDateZ = '';
      const data = this?.data || {};

      if (RA.isNotNilOrEmpty(data)) {
        let txComponent = data.getData('docName') || '';

        if (!txComponent) {
          txComponent = data.getData('txType') || '';
        }

        if (!txComponent) {
          txComponent = 'commission';
        }

        if (txComponent && R.is(String, txComponent)) {
          txComponent = txComponent.toLowerCase();
        }

        if (R.equals(txComponent, 'invoices')) {
          txDateZ = data?._data?.dispatchDateZ || data?._data?.dateZ || '';
        } else {
          txDateZ = data?._data?.dateZ || '';
        }
      }

      return txDateZ;
    }),
    cumulativeTotal: Ember.computed('openingBalance', 'transactionIndex', 'transactions', function () {
      const cumulativeTotalBig = this.bigService.newBig(0);
      const openingBalanceBig = this.bigService.newBig(this?.openingBalance || 0);
      const transactions = this?.transactions || [];
      const transactionIndex = parseInt(this.bigService.newBig(this?.transactionIndex || 0).plus(1).toFixed(0));
      const transactionsToAccumulate = R.pipe(R.take(transactionIndex), R.map(transaction => {
        const docType = transaction?._data?.docType || '';
        let txValue = this.bigService.newBig(0);
        let txComponent = transaction?._data?.docName || '';

        if (RA.isNilOrEmpty(txComponent)) {
          txComponent = transaction?._data?.txType || '';
        }

        if (RA.isNilOrEmpty(txComponent)) {
          txComponent = 'commission';
        }

        if (R.includes(txComponent, ['cashIn', 'cashOut'])) {
          txValue = this.bigService.newBig(transaction?._data?.total);
          const isValueNegative = R.equals(txComponent, 'cashIn') && R.equals(docType, 'sales') || R.equals(txComponent, 'cashOut') && !R.equals(docType, 'sales');

          if (isValueNegative) {
            txValue = txValue.times(-1);
          }
        }

        if (R.includes(txComponent, ['invoices', 'returns'])) {
          if (!R.equals(transaction?._data?.paymentMethodKey || '', 'creditCard')) {
            txValue = this.bigService.newBig(transaction?._data?.inclTotal);
            let isValueNegative = R.equals(txComponent, 'returns') && R.equals(docType, 'sales') || R.equals(txComponent, 'invoices') && !R.equals(docType, 'sales');
            const relationship = transaction?._data?.contactRelationship || _index.default.contactsRelationship.customer;

            if (R.equals(relationship, 'supplier')) {
              isValueNegative = R.not(isValueNegative);
            }

            if (isValueNegative) {
              txValue = txValue.times(-1);
            }
          }
        }

        if (R.equals(txComponent, 'commission')) {
          txValue = this.bigService.newBig(transaction?._data?.grandCommissionTotal);
        }

        return txValue.toFixed();
      }), R.sum)(transactions);
      return cumulativeTotalBig.plus(openingBalanceBig).plus(transactionsToAccumulate).toFixed();
    })
  });

  _exports.default = _default;
});