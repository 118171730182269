define("client/pods/components/files/files-browser-modal/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    uploadsService: Ember.inject.service('uploads'),
    tagName: '',
    resource: 'files',
    resourceKey: 'files',
    onSubmitTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, options, uploadsModel, data) {
      if (this.onSubmit) {
        if (this.onSubmit.perform) {
          yield this.onSubmit.perform(onCloseDialogAction, options, uploadsModel, data);
        } else {
          yield this.onSubmit(onCloseDialogAction, options, uploadsModel, data);
        }
      }

      onCloseDialogAction();
    }),
    actions: {
      cancel(onCloseDialogAction) {
        const onCancel = this.onCancel;

        if (onCancel) {
          return onCancel(onCloseDialogAction);
        }

        return onCloseDialogAction();
      }

    }
  });

  _exports.default = _default;
});