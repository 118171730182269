define("client/pods/extensions/apps/tw/e-invoices/service", ["exports", "client/config/environment", "ember-concurrency", "ramda"], function (_exports, _environment, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import AjaxMixin from '../../mixins/ajax'

  /**
   * performs and manages connections to servers
   */
  var _default = Ember.Service.extend({
    crud: Ember.inject.service(),
    server: Ember.inject.service(),
    printer: Ember.inject.service(),
    adapterName: 'extensions/apps/tw/e-invoices/to-issue',
    filtersAdapterName: 'extensions/apps/tw/e-invoices/filters',
    host: _environment.default.APP.serverUrl,
    fetchToIssueEInvoicesTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        component,
        adapterName = 'extensions/apps/tw/e-invoices/to-issue',
        appendPath,
        filters,
        period = {},
        dispatchProcess,
        includeBeforeStartDate,
        onlyRemaining = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const data = {
          isByDispatchProcess: !period.date,
          dateEnd: period.date || ''
        };

        if (!includeBeforeStartDate) {
          data.dateStart = period.monthStart || period.date;
          data.dateEnd = period.monthEnd;
        }

        if (dispatchProcess && !period.date) {
          data.dispatchProcess = dispatchProcess.serialize();
        }

        if (onlyRemaining) {
          data.onlyRemaining = onlyRemaining;
        }

        try {
          const results = yield _this.crud.searchRecordsTask.perform({
            adapterName,
            appendPath,
            filters,
            data
          });
          filters.set('fullCount', results?.fullCount);
          component.set('toIssue', R.pathOr([], ['toIssue'])(results));
          component.set('invalid', R.pathOr([], ['invalid'])(results));
          component.set('invalidCount', R.pathOr([], ['invalidCount'])(results));
          component.set('toIssueCount', R.pathOr([], ['toIssueCount'])(results));
          return results;
        } catch (err) {// this.setValidationErrors('notifyErrors', err)
        }
      }();
    }),
    fetchTrackNumbers: (0, _emberConcurrency.task)(function () {
      var _this2 = this;

      let {
        component,
        resultProp = 'track',
        mm = '',
        yyyy = '',
        period
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        if (period) {
          mm = period.mm;
          yyyy = period.yyyy;
        }

        const trackModel = yield _this2.crud.findTask.perform({
          adapterName: 'extensions/apps/tw/e-invoices/tracks',
          params: {
            mm,
            yyyy
          }
        });

        if (component && resultProp) {
          component[resultProp] = trackModel;
        }

        return trackModel;
      }();
    }),
    uploadBlanksTask: (0, _emberConcurrency.task)(function () {
      var _this3 = this;

      let {
        mm = '',
        yyyy = '',
        period,
        uploadUnusedBlanks
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        if (period) {
          mm = period.mm;
          yyyy = period.yyyy;
        }

        return yield _this3.server.call('POST', 'api/protected/extensions/apps/tw/e-invoices/upload-blanks', {
          mm,
          yyyy,
          uploadUnusedBlanks
        });
      }();
    }),
    customPaginateSearchRecordsTask: (0, _emberConcurrency.task)(function* (args) {
      const component = R.prop('component')(args);
      const filters = R.prop('filters')(args);
      const results = yield this.crud.paginateSearchRecordsTask.perform({
        filters
      });

      if (component) {
        component.set('toIssue', R.pathOr([], ['toIssue'])(results));
        component.set('invalid', R.pathOr([], ['invalid'])(results));
        component.set('invalidCount', R.pathOr([], ['invalidCount'])(results));
        component.set('toIssueCount', R.pathOr([], ['toIssueCount'])(results));
      }
    }),
    issueAllEInvoicesForPeriodTask: (0, _emberConcurrency.task)(function () {
      var _this4 = this;

      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const {
          component,
          onCloseDialogAction,
          period = {},
          dispatchProcess,
          includeBeforeStartDate,
          twEInvoicePrint = false,
          twEInvoicePrinterId = null
        } = args;
        const payload = {
          isByDispatchProcess: !period.date,
          dateEnd: period.date || '',
          twEInvoicePrint,
          twEInvoicePrinterId
        };

        if (!includeBeforeStartDate) {
          payload.dateStart = period.monthStart || period.date;
          payload.dateEnd = period.monthEnd;
        }

        if (dispatchProcess && !period.date) {
          payload.dispatchProcess = dispatchProcess.serialize();
        }

        component.set('printErrors', []);

        try {
          component.set('hasResults', false);
          const results = yield _this4.server.getAdapter('extensions/apps/tw/e-invoices').issueBatch({
            payload
          });
          onCloseDialogAction();
          return results;
        } catch (err) {
          _this4.crud.setValidationErrors('printErrors', err, component);

          return false;
        }
      }();
    }).drop(),
    issueTask: (0, _emberConcurrency.task)(function* (component, onCloseDialogAction, document, custom) {
      const payload = {
        _key: document.getData('_key'),
        custom
      };
      component.set('issueErrors', []);

      try {
        const results = yield this.server.getAdapter('extensions/apps/tw/e-invoices').issue({
          payload
        });
        onCloseDialogAction();
        return results;
      } catch (err) {
        this.crud.setValidationErrors('issueErrors', err, component);
        return false;
      }
    }).drop()
  });

  _exports.default = _default;
});