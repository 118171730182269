define("client/pods/grapesjs/plugins/slider-native/components/Slides", ["exports", "client/pods/grapesjs/plugins/slider-native/constants", "client/pods/grapesjs/plugins/slider-native/utils"], function (_exports, _constants, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(dc) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const defaultType = dc.getType('default');
    const defaultModel = defaultType.model;
    const {
      slidesName,
      slidesId,
      slideSelector,
      frameSelector
    } = _constants.default;
    dc.addType(slidesName, {
      model: defaultModel.extend({
        defaults: { ...defaultModel.prototype.defaults,
          name: 'Slides',
          droppable: slideSelector,
          draggable: frameSelector,
          selectable: false,
          style: {
            // display: 'inline-block',
            'transition-delay': '1ms'
          },
          ...config.slidesProps
        },

        init() {
          const cls = config.classSlides;
          this.get('classes').pluck('name').indexOf(cls) < 0 && this.addClass(cls); // add defaults

          this.addDefaults();
        },

        addDefaults() {
          this.addClass('lory-slides');
        }

      }, {
        isComponent(el) {
          if ((0, _utils.elHasClass)(el, config.classSlides)) return {
            type: slidesName
          };
        }

      }),
      view: defaultType.view.extend({
        init() {
          this.listenTo(this.model.components(), 'add remove', this.renderSlider);
        },

        renderSlider() {
          const slider = this.model.parent().parent();
          slider && slider.view.render();
        }

      })
    });
  };

  _exports.default = _default;
});