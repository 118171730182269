define("client/pods/components/msgs/error-badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P1HkgOH4",
    "block": "{\"symbols\":[\"isTooltipVisible\"],\"statements\":[[4,\"if\",[[23,0,[\"hasErrors\"]]],null,{\"statements\":[[0,\"  \"],[5,\"helpers/info-tooltip\",[],[[\"@customBlock\"],[true]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"elements/element-icon\",null,[[\"classNames\",\"icon\"],[\"has-text-danger u-icon-spacer--left\",[24,[\"icon\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"showError\"]]],null,{\"statements\":[[4,\"helpers/info-tooltip-content\",null,[[\"isViewable\"],[[23,1,[]]]],{\"statements\":[[0,\"        \"],[1,[28,\"msgs/error-msg\",null,[[\"errors\",\"showError\",\"showErrorLabel\",\"showMultipleErrors\"],[[23,0,[\"hasErrors\"]],true,[23,0,[\"showErrorLabel\"]],[24,[\"showMultipleErrors\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/msgs/error-badge/template.hbs"
    }
  });

  _exports.default = _default;
});