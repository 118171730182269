define("client/pods/components/documents/document-print/footer-sales-signature/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    totalItemsWithGroup: Ember.computed('hasGroups', 'model.{totalItems,_data.groups.@each.synced}', function () {
      const model = this.model;
      const hasGroups = this.get('hasGroups') || false;
      const groups = model.getData('groups') || [];
      let totalItems = model.get('totalItems') || 0;

      if (hasGroups) {
        R.forEach(group => {
          const groupTotalItems = group.get('totalItems') || 0;
          totalItems = totalItems + groupTotalItems;
        })(groups);
      }

      return totalItems;
    })
  });

  _exports.default = _default;
});