define("client/pods/components/helpers/tab-link/component", ["exports", "@glimmer/component", "ramda-extension", "ramda"], function (_exports, _component, R_, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let HelpersTabLinkComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class HelpersTabLinkComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "tabsManager", _descriptor, this);
    }

    get hasLink() {
      return R_.dotPathOr(false, 'args.hasLink')(this);
    }

    get isHoverLink() {
      return R_.dotPathOr(true, 'args.isHoverLink')(this);
    }

    get hideOpenTabBtnComputed() {
      return R.path(['args', 'hideOpenTabBtn'])(this) || false;
    }

    get linkLabel() {
      return R_.dotPathOr('open_tab', 'args.linkLabel')(this);
    }

    get tooltipContainer() {
      const title = this.args.tabOptions?.title || '';

      if (title === 'contacts') {
        return 'helpers/tab-link/contacts';
      }

      return '';
    }

    open() {
      if (this.hasLink) {
        this.tabsManager.openTab('left', this.args.tabOptions);
        return false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "open", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype)), _class));
  _exports.default = HelpersTabLinkComponent;
});