define("client/pods/components/extensions/apps/tw/seven-eleven/settings-editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwSevenElevenSettingsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class ExtensionsAppsTwSevenElevenSettingsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "transporterModel", _descriptor3, this);

      _initializerDefineProperty(this, "renderCreateContact", _descriptor4, this);

      _defineProperty(this, "unlinkMessage", 'are you sure you want to remove');

      _defineProperty(this, "adapterNameContactsTransporters", 'contacts/transporters');

      _defineProperty(this, "transporterExtension", 'seven-eleven');

      this.crud.addLists(this, [this.adapterNameContactsTransporters]);
    }

    afterLoadLists() {
      const transporters = R.pathOr([], ['crud', 'lists', 'contacts-transporters'])(this);
      const transporterModel = transporters.findBy('_data.transporterExtension', this.transporterExtension);

      if (transporterModel) {
        this.transporterModel = transporterModel;
      }

      this.renderCreateContact = true;
    }

    *unlinkTransporterTask() {
      const msg = this.intl.t(this.unlinkMessage);

      if (window.confirm(msg) && this.transporterModel) {
        const dirty = this.crud.setupDirty({
          adapterName: this.adapterNameContactsTransporters,
          model: this.transporterModel
        });
        dirty.setData('transporterExtension', '');
        yield this.crud.updateRecordTask.perform({
          adapterName: this.adapterNameContactsTransporters,
          model: dirty
        });
        this.transporterModel = null;
      }
    }

    loadTransporter(model) {
      this.transporterModel = model;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "renderCreateContact", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "unlinkTransporterTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "unlinkTransporterTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadTransporter", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "loadTransporter"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwSevenElevenSettingsEditorComponent;
});