define("client/pods/components/emails/campaigns/table-view/detail-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    contactsService: Ember.inject.service('contacts'),
    tagName: '',
    showEmail: false,

    init() {
      this.crud.addLists(this, ['emails/campaigns/editStatuses']);

      this._super(...arguments);

      if (this.isFilters) {
        const campaignFilters = this.search.setupFilters({
          adapterName: 'channels/shop/campaigns/filters'
        });
        this.set('campaignFilters', campaignFilters);
      }
    },

    actions: {
      loadShopSource(shop) {
        const filters = this.filters;
        filters.setData('sourceKey', shop.getData('_key'));
      },

      unloadShopSource() {
        const filters = this.filters;
        filters.setData('sourceKey', '');
      },

      setupResendEmail(email) {
        const dirty = this.crud.setupDirty({
          adapterName: 'emails',
          model: email
        });
        this.set('dirty', dirty);
      },

      clearResendEmail() {
        this.set('dirty', null);
      },

      async resendEmail(dirty, onCloseDialogAction) {
        await this.crud.createRecordTask.perform({
          adapterName: 'emails',
          appendPath: '/resend',
          model: dirty
        });
        onCloseDialogAction();
        const filters = this.filters;
        this.onReloadSearchRecordsTask.perform({
          filters
        });
      },

      cancelResendEmail(onCloseDialogAction) {
        onCloseDialogAction();
        this.set('dirty', null);
      },

      showEmail() {
        this.set('showEmail', true);
      },

      closeEmail() {
        this.set('showEmail', false);
      }

    }
  });

  _exports.default = _default;
});