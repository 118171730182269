define("client/pods/comments/contacts/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        keys: [],
        tags: [],
        page: 1,
        count: 10,
        query: '',
        master: '',
        docType: '',
        docname: '',
        status: '',
        match: ['all'],
        statuses: [],
        sort: [{
          by: 'timestampZ_latest',
          direction: 'DESC'
        }]
      };
    }

  });

  _exports.default = _default;
});