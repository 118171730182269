define("client/pods/components/extensions/apps/tw/invoices/print-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    customInvoiceNumber: '',
    customInvoiceDateZ: '',
    updateFollowingInvoices: false
  });

  _exports.default = _default;
});