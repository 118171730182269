define("client/pods/components/line/line-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pCPJNxuS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\",\"required\",\"documentationResource\"],[\"channel id\",[24,[\"errors\"]],\"lineChannelId\",true,\"line\"]],{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"model\",\"_data\",\"lineChannelId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\",\"required\",\"documentationResource\"],[\"channel secret\",[24,[\"errors\"]],\"lineChannelSecret\",true,\"line\"]],{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-input\",null,[[\"readonly\",\"value\"],[[24,[\"isReadonly\"]],[24,[\"model\",\"_data\",\"lineChannelSecret\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input-row\",null,[[\"label\",\"errors\",\"context\",\"required\",\"documentationResource\"],[\"callback url\",[24,[\"errors\"]],\"lineCallbackUrl\",true,\"line\"]],{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-label\",null,[[\"translate\",\"label\"],[false,[24,[\"model\",\"_data\",\"lineCallbackUrl\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/line/line-profile/template.hbs"
    }
  });

  _exports.default = _default;
});