define("client/helpers/docStatus", ["exports", "client/mixins/big", "client/constants/index"], function (_exports, _big, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_big.default, {
    intl: Ember.inject.service(),

    compute(params, hash) {
      const status = params[0];
      const dispatchStatus = params[1];

      if (status === _index.default.documentsStatus.void || status === _index.default.documentsStatus.draft) {
        return status;
      }

      if (!dispatchStatus) {
        return status;
      }

      return dispatchStatus;
    }

  });

  _exports.default = _default;
});