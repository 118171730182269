define("client/pods/users/lists/ip-addresses/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "ember-concurrency-decorators"], function (_exports, _adapter, _adaptersNew, _model, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _adapter.default.extend(_adaptersNew.default, (_obj = {
    server: Ember.inject.service(),
    modelObj: _model.default,
    resourcePath: 'api/protected/users/ip-addresses',
    idParam: '_id',

    *getIpUsersTable(ipKey) {
      const path = `${this.resourcePath}/${ipKey}/users`;
      const response = yield this.server.call('GET', path);
      return response?.data || [];
    }

  }, (_applyDecoratedDescriptor(_obj, "getIpUsersTable", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "getIpUsersTable"), _obj)), _obj));

  _exports.default = _default;
});