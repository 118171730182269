define("client/pods/grapesjs/plugins/grid", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @project: bigordr-app
   * @file: grid
   * @author Nafis Rubio
   * @since 19/09/2020
   * @copyright (C) 2020 BigOrdr
   * @description: Grid component
   */

  /**
   * @typedef {import("../categories").Categories}
   */
  var _default = editor => {
    const {
      DomComponents,
      BlockManager
    } = editor;
    const pluginName = 'bigordr-grid';
    const bigordrGridImages = 'bigordr-grid-images';
    const bigordrGridComponent = 'bigordr-grid-component';
    const pluginBlockId = `${pluginName}-block`;
    const bigordrGridImagesBlockId = `${bigordrGridImages}-block`;
    const options = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const margin0 = 'margin-0';
    const {
      intl,

      /** @type {Categories} */
      categories
    } = options;
    const blockLabel = intl.t(pluginName);
    const bigordrGridImagesBlockLabel = intl.t(bigordrGridImages);
    const componentName = intl.t(pluginName);
    DomComponents.addType(bigordrGridComponent, {
      extend: 'cell',
      model: {
        defaults: {
          style: {
            padding: '10px'
          },
          components: [{
            imgType: 'full-width-image',
            type: 'full-width-image',
            src: 'https://picsum.photos/512.webp',
            traits: [{
              type: 'bigordr-animation-trait',
              name: 'animationSelector',
              label: intl.t('select animation')
            }, {
              type: 'bigordr-animation-iteration-trait',
              name: 'animationIterationSelector',
              label: intl.t('select iteration count')
            }, {
              type: 'bigordr-animation-duration-trait',
              name: 'animationDuration',
              label: intl.t('enter duration (sec)')
            }]
          }, {
            type: 'text',
            tagName: 'h1',
            classes: ['bigordr-title', margin0],
            content: 'Insert title here',
            traits: [{
              type: 'bigordr-animation-text-trait',
              name: 'animationSelector',
              label: intl.t('select animation')
            }, {
              type: 'bigordr-animation-iteration-trait',
              name: 'animationIterationSelector',
              label: intl.t('select iteration count')
            }, {
              type: 'bigordr-animation-duration-trait',
              name: 'animationDuration',
              label: intl.t('enter duration (sec)')
            }]
          }, {
            type: 'text',
            style: {
              padding: '10px'
            },
            tagName: 'p',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lobortis ullamcorper lorem. Donec tempor lectus sapien, eu consequat dolor vehicula eu. Vestibulum non felis blandit, condimentum mi ac, pulvinar dolor.',
            traits: [{
              type: 'bigordr-animation-trait',
              name: 'animationSelector',
              label: intl.t('select animation')
            }, {
              type: 'bigordr-animation-iteration-trait',
              name: 'animationIterationSelector',
              label: intl.t('select iteration count')
            }, {
              type: 'bigordr-animation-duration-trait',
              name: 'animationDuration',
              label: intl.t('enter duration (sec)')
            }],
            classes: [margin0]
          }]
        },
        traits: []
      }
    });
    DomComponents.addType(pluginName, {
      model: {
        defaults: {
          name: componentName,
          classes: [pluginName],
          components: [{
            type: 'row',
            selectable: false,
            style: {
              padding: '0'
            },
            components: [{
              type: bigordrGridComponent,
              src: 'https://picsum.photos/512.webp?random=1',
              traits: []
            }, {
              type: bigordrGridComponent,
              src: 'https://picsum.photos/512.webp?random=2',
              traits: []
            }, {
              type: bigordrGridComponent,
              src: 'https://picsum.photos/512.webp?random=3',
              traits: []
            }]
          }],
          traits: []
        }
      }
    });
    DomComponents.addType(bigordrGridImages, {
      model: {
        defaults: {
          name: componentName,
          classes: [bigordrGridImages],
          components: [{
            type: 'row',
            style: {
              padding: '0'
            },
            components: [{
              type: 'cell',
              style: {
                padding: '10px'
              },
              components: [{
                imgType: 'full-width-image',
                type: 'full-width-image',
                src: 'https://picsum.photos/512.webp?random=1'
              }]
            }, {
              type: 'cell',
              style: {
                padding: '10px'
              },
              components: [{
                imgType: 'full-width-image',
                type: 'full-width-image',
                src: 'https://picsum.photos/512.webp?random=2'
              }]
            }, {
              type: 'cell',
              style: {
                padding: '10px'
              },
              components: [{
                imgType: 'full-width-image',
                type: 'full-width-image',
                src: 'https://picsum.photos/512.webp?random=3'
              }]
            }]
          }, {
            type: 'row',
            style: {
              padding: '0'
            },
            components: [{
              type: 'cell',
              style: {
                padding: '10px'
              },
              components: [{
                imgType: 'full-width-image',
                type: 'full-width-image',
                src: 'https://picsum.photos/512.webp?random=4'
              }]
            }, {
              type: 'cell',
              style: {
                padding: '10px'
              },
              components: [{
                imgType: 'full-width-image',
                type: 'full-width-image',
                src: 'https://picsum.photos/512.webp?random=5'
              }]
            }, {
              type: 'cell',
              style: {
                padding: '10px'
              },
              components: [{
                imgType: 'full-width-image',
                type: 'full-width-image',
                src: 'https://picsum.photos/512.webp?random=6'
              }]
            }]
          }],
          traits: []
        }
      }
    });
    BlockManager.add(pluginBlockId, {
      label: blockLabel,
      category: categories.complex,
      content: {
        type: pluginName
      },
      render: () => `<svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;'>
    <g transform='matrix(1,0,0,1,-1.46126,-85.0551)'>
        <g>
            <g>
                <g transform='matrix(1.00794,0,0,0.792537,12.4421,44.5258)'>
                    <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:38.6px;'/>
                </g>
                <g transform='matrix(1.08069,0,0,1.08069,-48.6111,-30.4861)'>
                    <g transform='matrix(1.51835,-6.97105e-19,-6.97105e-19,1.52404,-502.354,-212.761)'>
                        <circle cx='567.803' cy='388.754' r='27.858' style='stroke:black;stroke-width:18.25px;stroke-linecap:round;stroke-linejoin:round;'/>
                    </g>
                    <g transform='matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)'>
                        <g transform='matrix(-0.93623,0,0,0.939727,1014.78,130.716)'>
                            <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                        </g>
                        <g transform='matrix(-1.01027,0,0,0.756475,916.709,228.489)'>
                            <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
    <g transform='matrix(0.697024,0,0,0.646383,-7.75042,441.721)'>
        <rect x='301.902' y='349.059' width='385.98' height='98.525'/>
    </g>
    <g transform='matrix(1.62999,0,0,1,-323.453,211.159)'>
        <rect x='322.785' y='550.771' width='382.132' height='25.78'/>
    </g>
    <g transform='matrix(1.62999,0,0,1,-323.453,262.69)'>
        <rect x='322.785' y='550.771' width='382.132' height='25.78'/>
    </g>
    <g transform='matrix(1.62999,0,0,1,-323.453,308.821)'>
        <rect x='322.785' y='550.771' width='382.132' height='25.78'/>
    </g>
</svg>`
    });
    BlockManager.add(bigordrGridImagesBlockId, {
      label: bigordrGridImagesBlockLabel,
      category: categories.complex,
      content: {
        type: bigordrGridImages
      },
      render: () => `<svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;'>
    <g transform='matrix(1,0,0,1,10.3804,-27.0402)'>
        <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:15px;'/>
    </g>
    <g transform='matrix(0.387407,0,0,0.387407,171.456,210.719)'>
        <g transform='matrix(1.07796,0,0,0.792537,-23.8264,44.5258)'>
            <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:40.93px;'/>
        </g>
        <g transform='matrix(1,0,0,1,-7.30471,-8.68871)'>
            <g transform='matrix(1.02774,-4.71854e-19,-4.71854e-19,1.03159,-262.217,-3.18345)'>
                <circle cx='567.803' cy='388.754' r='27.858' style='stroke:black;stroke-width:75.21px;stroke-linecap:round;stroke-linejoin:round;'/>
            </g>
            <g transform='matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)'>
                <g transform='matrix(-1.13872,0,0,0.939727,1136.32,130.716)'>
                    <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                </g>
                <g transform='matrix(-1.2181,0,0,0.756475,980.282,228.489)'>
                    <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                </g>
            </g>
        </g>
    </g>
    <g transform='matrix(0.387407,0,0,0.387407,171.456,454.781)'>
        <g transform='matrix(1.07796,0,0,0.792537,-23.8264,44.5258)'>
            <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:40.93px;'/>
        </g>
        <g transform='matrix(1,0,0,1,-7.30471,-8.68871)'>
            <g transform='matrix(1.02774,-4.71854e-19,-4.71854e-19,1.03159,-262.217,-3.18345)'>
                <circle cx='567.803' cy='388.754' r='27.858' style='stroke:black;stroke-width:75.21px;stroke-linecap:round;stroke-linejoin:round;'/>
            </g>
            <g transform='matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)'>
                <g transform='matrix(-1.13872,0,0,0.939727,1136.32,130.716)'>
                    <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                </g>
                <g transform='matrix(-1.2181,0,0,0.756475,980.282,228.489)'>
                    <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                </g>
            </g>
        </g>
    </g>
    <g transform='matrix(0.387407,0,0,0.387407,449.404,210.719)'>
        <g transform='matrix(1.07796,0,0,0.792537,-23.8264,44.5258)'>
            <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:40.93px;'/>
        </g>
        <g transform='matrix(1,0,0,1,-7.30471,-8.68871)'>
            <g transform='matrix(1.02774,-4.71854e-19,-4.71854e-19,1.03159,-262.217,-3.18345)'>
                <circle cx='567.803' cy='388.754' r='27.858' style='stroke:black;stroke-width:75.21px;stroke-linecap:round;stroke-linejoin:round;'/>
            </g>
            <g transform='matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)'>
                <g transform='matrix(-1.13872,0,0,0.939727,1136.32,130.716)'>
                    <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                </g>
                <g transform='matrix(-1.2181,0,0,0.756475,980.282,228.489)'>
                    <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                </g>
            </g>
        </g>
    </g>
    <g transform='matrix(0.387407,0,0,0.387407,449.404,454.781)'>
        <g transform='matrix(1.07796,0,0,0.792537,-23.8264,44.5258)'>
            <rect x='203.744' y='249.501' width='586.657' height='586.657' style='fill:white;stroke:black;stroke-width:40.93px;'/>
        </g>
        <g transform='matrix(1,0,0,1,-7.30471,-8.68871)'>
            <g transform='matrix(1.02774,-4.71854e-19,-4.71854e-19,1.03159,-262.217,-3.18345)'>
                <circle cx='567.803' cy='388.754' r='27.858' style='stroke:black;stroke-width:75.21px;stroke-linecap:round;stroke-linejoin:round;'/>
            </g>
            <g transform='matrix(1.01538,0,0,1.01538,-7.2449,-44.0848)'>
                <g transform='matrix(-1.13872,0,0,0.939727,1136.32,130.716)'>
                    <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                </g>
                <g transform='matrix(-1.2181,0,0,0.756475,980.282,228.489)'>
                    <path d='M453.058,338.375L600.233,533.547L305.882,533.547L453.058,338.375Z'/>
                </g>
            </g>
        </g>
    </g>
</svg>`
    });
  };

  _exports.default = _default;
});