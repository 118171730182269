define("client/pods/components/channels/shop/rewards/table-view/detail-row/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopRewardsTableViewDetailRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = class ChannelsShopRewardsTableViewDetailRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "server", _descriptor2, this);

      _initializerDefineProperty(this, "hasExpiringDate", _descriptor3, this);

      _initializerDefineProperty(this, "expiringDateStartZ", _descriptor4, this);

      _initializerDefineProperty(this, "expiringDateEndZ", _descriptor5, this);
    }

    get isDisabled() {
      const hasExpiringDate = this.hasExpiringDate;
      const expiringDateStartZ = this.expiringDateStartZ || '';
      const expiringDateEndZ = this.expiringDateEndZ || '';

      if (hasExpiringDate && (!expiringDateStartZ || !expiringDateEndZ)) {
        return true;
      }

      return false;
    }

    *sendExpiringRewardNotification(model, onCloseDialogAction) {
      const hasExpiringDate = this.hasExpiringDate;
      const rewardKey = model.getData('_key');
      let dateStartZ = '';
      let dateEndZ = '';

      if (hasExpiringDate) {
        dateStartZ = this.expiringDateStartZ;
        dateEndZ = this.expiringDateEndZ;
      }

      const payload = {
        rewardKey,
        dateStartZ,
        dateEndZ
      };
      const response = yield this.server.call('PUT', 'api/protected/contacts/rewards/send/expire-notification', payload);
      let sent = 0;

      if (response.data) {
        sent = R.length(response.data);
      }

      onCloseDialogAction();
      window.alert('sent: ' + sent + ' emails.');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasExpiringDate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "expiringDateStartZ", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "expiringDateEndZ", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sendExpiringRewardNotification", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "sendExpiringRewardNotification"), _class.prototype)), _class));
  _exports.default = ChannelsShopRewardsTableViewDetailRowComponent;
});