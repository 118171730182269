define("client/pods/components/products/table-view/detail-row/component", ["exports", "ember-concurrency", "ramda", "ramda-extension"], function (_exports, _emberConcurrency, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    productsSerivce: Ember.inject.service('products'),
    shortcuts: Ember.inject.service(),
    tagName: '',
    showUploadsRow: false,
    hasShortcuts: true,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['products/lists/tags']);
      this.set('shortcutsMap', [{
        keys: [['Meta', 'Enter'], ['Control', 'Enter']],
        handler: this.quickSaveTask
      }, {
        keys: ['Alt', 'Enter'],
        handler: this.showWizardModal
      }]);
    },

    isSelected: Ember.computed('tableViewOptions.selectedItems.[]', function () {
      const selectedItems = this?.tableViewOptions?.selectedItems || [];

      if (selectedItems.indexOf(this.model) > -1) {
        return true;
      }

      return false;
    }),
    isSingleOrBulkEditing: Ember.computed('isSingleEditing', 'tableViewOptions.isBulkEditing', function () {
      return this.isSingleEditing || this.tableViewOptions.isBulkEditing;
    }),
    isReadonly: Ember.computed('isEditing', 'isNew', 'isSingleOrBulkEditing', function () {
      if (this.isEditing || this.isNew || this.isSingleOrBulkEditing) {
        return false;
      }

      return true;
    }),
    isFiltersReadonly: Ember.computed('isEditing', 'isNew', function () {
      if (this.isEditing || this.isNew) {
        return true;
      }

      return false;
    }),
    isProductSetsReadonly: Ember.computed('isNew', 'isEditing', 'isReadonly', function () {
      if (this.isNew) {
        return false;
      }

      let isProductSetsReadonly = false; // option cannot be edited after saving

      if (this.isEditing) {
        isProductSetsReadonly = true;
      }

      if (!isProductSetsReadonly) {
        isProductSetsReadonly = this.isReadonly;
      }

      return isProductSetsReadonly;
    }),
    quickSaveTask: (0, _emberConcurrency.task)(function* () {
      return yield this.crud.createRecordTask.perform({
        adapterName: 'products',
        model: this.model,
        onAfter: this.tableViewOptions?.onAfterQuickAddNew,
        component: this
      });
    }),
    updateBulkEditingTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        appendPath = '',
        model
      } = _ref;
      return function* () {
        if (RA.isNilOrEmpty(appendPath)) {
          appendPath = '';
        }

        if (_this.tableViewOptions?.isBulkEditing) {
          yield _this.updateTask.perform({
            appendPath,
            model
          });
        }
      }();
    }),
    updateTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        appendPath,
        model
      } = _ref2;
      return function* () {
        model = model || _this2.model;
        let dirty = model;

        if (!model.isDirty) {
          dirty = _this2.crud.setupDirty({
            adapterName: 'products',
            model
          });
        }

        const product = model.product;
        const updated = yield _this2.crud.replaceRecordTask.perform({
          adapterName: 'products',
          appendPath,
          model: dirty,
          component: _this2
        });

        if (updated && !_this2.tableViewOptions?.isBulkEditing) {
          _this2.set('model', updated);

          _this2.set('original', false);

          _this2.set('isSingleEditing', false);
        }

        if (updated) {
          _this2.set('errors', false);
        }
      }();
    }),

    showWizardModal() {
      let currentStep = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      const model = this.model;
      this.set('currentStep', 0);
      const setType = model.getData('setType');

      if (!setType) {
        return this.showStepTemplate();
      } // if (this.currentStep === 0) {


      return this.showStepSet(); // }
      // return this.showStepTemplate()
    },

    showStepSet() {
      this.set('currentStep', 0);
      const model = this.model;
      const setType = model.getData('setType');
      const panels = R_.dotPath('crud.lists.products-lists-editorSubPanels')(this) || [];
      const panel = R.find(R.pathEq(['_data', 'value'], setType))(panels);

      if (panel) {
        this.tabsManager.displaySubPanel(this, panel);
        this.set('isSetTypeModalVisible', true);
      }
    },

    showStepTemplate() {
      this.set('currentStep', 1);
      const panels = R_.dotPath('crud.lists.products-lists-editorSubPanels')(this) || [];
      const panel = R.find(R.pathEq(['_data', 'value'], 'webpages'))(panels);

      if (panel) {
        this.tabsManager.displaySubPanel(this, panel);
        this.set('isSetTypeModalVisible', true);
      }
    },

    actions: {
      clickRow() {
        if (this.onMainAction) {
          this.onMainAction(...arguments);
        }
      },

      /**
       * sales tax selected. if salesTaxRate selected, auto set purchasesTaxRate (and vice versa)
       */
      salesTaxRateSelected(taxModel) {
        const model = this.model;
        const taxRate = taxModel.getData('value');
        const purchasesTaxKey = model.getData('purchasesTaxRateKey'); // manually set tax rate

        model.setData('salesTaxRate', taxRate);

        if (purchasesTaxKey == null || purchasesTaxKey === 'auto') {
          // auto setData values for purchases
          // model.setData('purchasesTaxRateLabel', taxModel.getData('label'));
          model.setData('purchasesTaxRateKey', taxModel.getData('_key')); // model.setData('purchasesTaxRate', taxRate);
        }
      },

      /**
       * purchases tax selected. if salesTaxRate selected, auto set purchasesTaxRate (and vice versa)
       */
      purchasesTaxRateSelected(taxModel) {
        const model = this.model;
        const taxRate = taxModel.getData('value');
        const salesTaxKey = model.getData('salesTaxRateKey'); // manually set tax rate

        model.setData('purchasesTaxRate', taxRate);

        if (salesTaxKey == null || salesTaxKey === 'auto') {
          // auto setData values for purchases
          // model.setData('salesTaxRateLabel', taxModel.getData('label'));
          model.setData('salesTaxRateKey', taxModel.getData('_key')); // model.setData('salesTaxRate', taxRate);
        }
      },

      setSetType(setType) {
        const model = this.model;
        model.setData('isSet', false);
        model.setData('isSetWithChoices', false);
        model.setData('hasVariants', false);
        const selectedSetType = setType.getData('value');

        if (selectedSetType) {
          model.setData(selectedSetType, true);
        } // this.setEditorSubPanels()

      },

      deselectSetType() {
        const model = this.model;
        model.setData('isSet', false);
        model.setData('isSetWithChoices', false);
        model.setData('hasVariants', false);
      },

      updateVariantChildrenList(_ref3) {
        let {
          originalModel
        } = _ref3;
        const model = this.model;
        return model.updateVariantChildrenList({
          originalModel,
          setInitialValue: this.isEditing
        });
      },

      showWizardModal() {
        this.showWizardModal();
      },

      closeWizardModal() {
        this.set('isSetTypeModalVisible', false);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "showWizardModal", [_dec], Object.getOwnPropertyDescriptor(_obj, "showWizardModal"), _obj), _applyDecoratedDescriptor(_obj, "showStepSet", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showStepSet"), _obj), _applyDecoratedDescriptor(_obj, "showStepTemplate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showStepTemplate"), _obj)), _obj)));

  _exports.default = _default;
});