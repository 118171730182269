define("client/pods/documents/data/links/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/documents/data/links/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/documents/data/links',
    idParam: false,
    modelObj: _model.default
  });

  _exports.default = _default;
});