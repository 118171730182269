define("client/pods/components/documents/search-filters/component", ["exports", "client/mixins/doc-list-by", "client/mixins/crud"], function (_exports, _docListBy, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, _docListBy.default, (_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    tagName: '',
    dateZ: '',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['documents/lists/filters/search', 'documents/lists/filters/statuses', 'documents/lists/filters/dispatchStatus', 'documents/lists/filters/sort', 'filters/count', 'filters/sortDirection']);

      if (!this.onListByTask) {
        this.set('onListByTask', this.listByTask);
      }

      if (!this.onListByDate) {
        this.set('onListByDate', this.listByDate);
      }
    },

    afterListByTask(onHideDropdown) {
      this.set('dateZ', '');

      if (onHideDropdown) {
        onHideDropdown();
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "afterListByTask", [_dec], Object.getOwnPropertyDescriptor(_obj, "afterListByTask"), _obj)), _obj)));

  _exports.default = _default;
});