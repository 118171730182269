define("client/pods/components/products/products-editor/variants-editor/variant-editor/component", ["exports", "client/mixins/errors", "client/mixins/sortable-actions"], function (_exports, _errors, _sortableActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_errors.default, _sortableActions.default, (_dec = Ember._action, (_obj = {
    tagName: '',
    modelProp: 'variant',
    indexProp: '_data.index',

    didInsertElement() {
      this._super(...arguments);

      if (this.isEditing || this.isNew) {
        const model = this.model;
        const variant = this.variant;
        model.addVariantOption(variant);
      }
    },

    reorderAndUpdateVariantChildrenList(option) {
      const variantModel = this.getModel();
      const dragItem = this.dragDetail;
      variantModel.reorderDetails(option, dragItem);
    },

    actions: {
      addVariantOption(model, variant, option) {
        model.addVariantOption(variant, option);
      },

      removeVariantOption(model, variant, option) {
        const msg = this.intl.t('are you sure you want to delete variant option');
        const confirm = window.confirm(msg);

        if (confirm) {
          model.removeVariantOption(variant, option);
        }
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "reorderAndUpdateVariantChildrenList", [_dec], Object.getOwnPropertyDescriptor(_obj, "reorderAndUpdateVariantChildrenList"), _obj)), _obj)));

  _exports.default = _default;
});