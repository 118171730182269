define("client/pods/components/messages/tab-container/users-window/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MW3GUGP/",
    "block": "{\"symbols\":[\"users\",\"index\"],\"statements\":[[4,\"lists/list-results\",null,[[\"list\",\"itemValueKey\",\"itemLabelKey\",\"classNames\",\"itemClassNames\",\"filters\",\"count\",\"onPaginationTask\",\"onSelect\"],[[24,[\"chatUsers\"]],[24,[\"_data\",\"id\"]],[24,[\"_data\",\"name\"]],\"u-results__list\",\"u-results__item\",[24,[\"filters\"]],[24,[\"filters\",\"_data\",\"count\"]],[24,[\"paginateSearchRecordsTask\"]],[24,[\"onSelect\"]]]],{\"statements\":[[0,\"\\n     \"],[2,\"TODO: OPTIMIZE IMAGE VIEW \"],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"panel-icon image is-48x48\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"class\",\"is-rounded\"],[11,\"src\",[29,[[23,1,[\"_data\",\"profilePic\"]]]]],[10,\"alt\",\"Placeholder image\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"newMessagesCount\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"tag is-primary is-medium\"],[8],[0,\"\\n      \"],[1,[23,1,[\"newMessagesCount\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[28,\"if\",[[28,\"eq\",[[23,1,[\"memberOf\"]],\"line\"],null],\"tag is-success\",\"tag is-info\"],null]],[8],[1,[23,1,[\"memberOf\"]],false],[9],[0,\"\\n\\n  \"],[7,\"span\",true],[8],[1,[23,1,[\"_data\",\"pageName\"]],false],[9],[0,\"\\n\\n  \"],[1,[28,\"products/search-results-item\",null,[[\"query\",\"filters\",\"result\"],[[24,[\"filters\",\"_data\",\"query\"]],[24,[\"filters\"]],[23,1,[]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/messages/tab-container/users-window/template.hbs"
    }
  });

  _exports.default = _default;
});