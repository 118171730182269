define("client/pods/components/channels/shop/shipping-zones/tab-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopShippingZonesTabContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class ChannelsShopShippingZonesTabContainerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "constants", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "search", _descriptor3, this);

      _initializerDefineProperty(this, "dataManager", _descriptor4, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor5, this);

      _initializerDefineProperty(this, "hasLoadedInstructions", _descriptor6, this);

      _defineProperty(this, "adapterName", 'channels/shop/shipping-zones');

      _defineProperty(this, "filtersAdapterName", 'channels/shop/shipping-zones/filters');

      this.crud.addLists(this, ['statuses', 'channels/shop/shipping-zones/lists/menu', 'channels/shop/shipping-zones/lists/sub-panels', 'channels/shop/shipping-zones/lists/sub-panels-editor', 'channels/shop/shipping-zones/lists/zone-types', 'channels/shop/shipping-zones/lists/additional-free-shipping-when', 'contacts/transporters', 'extensions/apps/tw/convenience-stores/lists/brands', 'paymentMethods/shop', 'products/lists/tags', 'settings/countries', 'settings/countries/regions', 'websites']);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          fetchWithCampaigns: true
        }
      });
      Ember.set(this, 'filters', filters);
    }

    afterLoadLists() {
      this.fetchAndDisplayTask.perform();
    }

    *fetchAndDisplayTask() {
      const tab = this.args?.tab || {};
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions && !this.hasLoadedInstructions) {
        this.hasLoadedInstructions = true;
        const model = loadInstructions?._data?.model;

        if (model) {
          this.display(loadInstructions?._data?.model);
        } else {
          const id = loadInstructions?._data?.find?.id || '';
          const found = yield this.crud.findTask.perform({
            adapterName: this.adapterName,
            appendPath: id
          });
          this.display(found);
        }
      } else {
        const filters = this.filters;
        const model = yield this.crud.searchRecordsTask.perform({
          adapterName: this.adapterName,
          query: '',
          filters
        }); // PREVENT INFINITE LOOP OF SEARCHING

        if (model) {
          this.dashFn(model);
        }
      }
    }

    dashFn(model, additionalOptions) {
      const subTabOptions = {
        component: 'channels/shop/shipping-zones/panels-dash',
        model: model
      };

      if (!model) {
        const filters = this.filters;
        filters.setData('query', '');
        this.fetchAndDisplayTask.perform();
      }

      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions, additionalOptions);
    }

    displayFn(model, additionalOptions) {
      const subTabOptions = {
        component: 'channels/shop/shipping-zones/panels-display',
        model
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions, additionalOptions);
    }

    editFn(model, additionalOptions) {
      const subTabOptions = {
        component: 'channels/shop/shipping-zones/panels-edit',
        model
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions, additionalOptions);
    }

    dash() {
      this.dashFn();
    }

    display(model) {
      if (model) {
        this.displayFn(model);
      } else {
        this.dashFn();
      }
    }

    edit(model, additionalOptions) {
      this.editFn(model, additionalOptions);
    }

    new() {
      const subTabOptions = {
        component: 'channels/shop/shipping-zones/panels-new'
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasLoadedInstructions", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchAndDisplayTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchAndDisplayTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dash", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "dash"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "display", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "new", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "new"), _class.prototype)), _class));
  _exports.default = ChannelsShopShippingZonesTabContainerComponent;
});