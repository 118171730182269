define("client/pods/components/extensions/sub/tab-container/component", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, {
    crud: Ember.inject.service(),
    model: null,
    errors: null,
    adapterName: 'channels/shop/web',

    init() {
      this.crud.addLists(this, ['extensions/lists/submenu']);

      this._super(...arguments);

      const extName = this.get('tab.options._data.extName');
      this.set('extName', extName);
    },

    fetchOrdersTask: (0, _emberConcurrency.task)(function* () {
      const options = {
        cache: false
      };
      const extName = this.extName;
      const appendPath = `/${extName}/orders`;
      const filters = this.search.setupFilters({
        adapterName: 'filters'
      });
      const results = yield this.searchRecordsTask.perform({
        appendPath,
        options,
        filters
      }); // when searching extension, fetch list of data but
      // treat entire list as 1 single model

      const adapterName = this.adapterName;
      const adapter = this.getAdapter(adapterName);
      const model = adapter.createModel(adapterName, adapter, {
        orders: results
      });
      const tab = this.tab;
      const subTabOptions = {
        component: 'extensions/sub/panels-display',
        model: model,
        extName: extName
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }),

    fetch() {
      this.fetchOrdersTask.perform();
    },

    actions: {
      fetch() {
        this.fetch(...arguments);
      }

    }
  });

  _exports.default = _default;
});