define("client/pods/components/products/inventory/report/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "ramda-extension", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, R, RA, R_, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductsInventoryReport = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, (_class = class ProductsInventoryReport extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "settings", _descriptor, this);

      _initializerDefineProperty(this, "date", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "big", _descriptor4, this);

      _initializerDefineProperty(this, "excel", _descriptor5, this);

      _initializerDefineProperty(this, "search", _descriptor6, this);

      _initializerDefineProperty(this, "crud", _descriptor7, this);

      _initializerDefineProperty(this, "monthEnd", _descriptor8, this);

      _initializerDefineProperty(this, "monthEndZ", _descriptor9, this);

      _initializerDefineProperty(this, "monthStart", _descriptor10, this);

      _initializerDefineProperty(this, "monthStartZ", _descriptor11, this);

      _initializerDefineProperty(this, "isExporting", _descriptor12, this);

      _initializerDefineProperty(this, "isUsingDateRange", _descriptor13, this);

      _initializerDefineProperty(this, "productsFilters", _descriptor14, this);

      _initializerDefineProperty(this, "productsList", _descriptor15, this);

      _initializerDefineProperty(this, "inventoryReportMMStart", _descriptor16, this);

      _initializerDefineProperty(this, "inventoryReportMMEnd", _descriptor17, this);

      _initializerDefineProperty(this, "inventoryReportYYYYStart", _descriptor18, this);

      _initializerDefineProperty(this, "inventoryReportYYYYEnd", _descriptor19, this);

      this.crud.addLists(this, ['periods/months', 'periods/years']);
      const specs = {
        info: [{
          column: '',
          prop: '_index_'
        }, {
          column: this.intl.t('product'),
          prop: 'name'
        }, {
          column: this.intl.t('product code'),
          prop: 'code'
        }, {
          column: this.intl.t('type'),
          prop: 'setType'
        }, {
          column: this.intl.t('unit'),
          prop: 'unit'
        }, {
          column: this.intl.t('barcode'),
          prop: 'barcode'
        }, {
          column: '',
          prop: 'inventoryReport.openingBalance.netQty',
          transform: value => parseFloat(value || 0)
        }, {
          column: this.intl.t('opening balance'),
          prop: 'inventoryReport.openingBalance.netExclPrice',
          transform: value => parseFloat(value || 0)
        }, {
          column: '',
          prop: 'inventoryReport.openingBalance.netValue',
          transform: value => parseFloat(value || 0)
        }, {
          column: '',
          prop: 'inventoryReport.incomingBalance.netQty',
          transform: value => parseFloat(value || 0)
        }, {
          column: this.intl.t('incoming transactions summary'),
          prop: 'inventoryReport.incomingBalance.netExclPrice',
          transform: value => parseFloat(value || 0)
        }, {
          column: '',
          prop: 'inventoryReport.incomingBalance.netValue',
          transform: value => parseFloat(value || 0)
        }, {
          column: '',
          prop: 'inventoryReport.outgoingBalance.netQty',
          transform: value => parseFloat(value || 0)
        }, {
          column: this.intl.t('outgoing transactions summary'),
          prop: 'inventoryReport.outgoingBalance.netExclPrice',
          transform: value => parseFloat(value || 0)
        }, {
          column: '',
          prop: 'inventoryReport.outgoingBalance.netValue',
          transform: value => parseFloat(value || 0)
        }, {
          column: '',
          prop: 'inventoryReport.closingBalance.netQty',
          transform: value => parseFloat(value || 0)
        }, {
          column: this.intl.t('closing balance'),
          prop: 'inventoryReport.closingBalance.netExclPrice',
          transform: value => parseFloat(value || 0)
        }, {
          column: '',
          prop: 'inventoryReport.closingBalance.netValue',
          transform: value => parseFloat(value || 0)
        }, {
          column: this.intl.t('gross profit'),
          prop: 'inventoryReport.grossProfit',
          transform: value => parseFloat(value || 0)
        }, {
          column: this.intl.t('margin'),
          prop: 'inventoryReport.marginValue',
          transform: value => parseFloat(value || 0)
        }, {
          column: this.intl.t('inventory book qty'),
          prop: 'inventory.onBookQty',
          transform: value => parseFloat(value || 0)
        }, {
          column: this.intl.t('on hand qty'),
          prop: 'inventory.onHandQty',
          transform: value => parseFloat(value || 0)
        }, {
          column: this.intl.t('warnings'),
          prop: 'inventoryReport.hasWarning',
          transform: value => value ? this.intl.t('probably missing data') : ''
        }]
      };
      this.specs = specs;
      this.isUsingDateRange = false;
      this.setProductFilters();
    }

    setProductFilters() {
      const productsFilters = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes: [{
            key: 'setType',
            value: 'isSetWithChoices'
          }, {
            key: 'setType',
            value: 'hasVariants'
          }, {
            key: 'setType',
            value: 'isSet'
          }]
        }
      });
      this.productsFilters = productsFilters;
    }

    getDefaultFilename() {
      const filters = this.args.filters || {};
      let mmStart = '';
      let yyyyStart = '';
      let mmEnd = '';
      let yyyyEnd = '';

      if (filters) {
        mmStart = filters.getData('inventoryReportMMStart');
        yyyyStart = filters.getData('inventoryReportYYYYStart');
        mmEnd = filters.getData('inventoryReportMMEnd');
        yyyyEnd = filters.getData('inventoryReportYYYYEnd');
      }

      return `export-${mmStart}-${yyyyStart}_${mmEnd}-${yyyyEnd}`;
    }

    *exportTask() {
      this.isExporting = true;
      let filename = this.filename;

      if (!filename) {
        filename = this.getDefaultFilename();
      }

      const unpaginatedProductsFilters = this.search.copyFilters({
        filters: this.args.filters,
        adapterName: 'products/filters',
        defaultFiltersData: {
          fetchMax: true
        }
      }); // used searchAdvancedTask to follow filter correctly
      // rever to searchRecordsTask if don't want to

      const serialized = yield this.crud.searchAdvancedTask.perform({
        adapterName: 'products',
        filters: unpaginatedProductsFilters,
        rawResponse: true
      });
      const specs = this.specs;
      const worksheets = [];
      worksheets.push({
        sheetName: this.intl.t('inventory reports'),
        data: this._exportInventoryReport(specs.info, [], serialized, false)
      });

      if (!R.isEmpty(worksheets)) {
        yield this.excel.exportFile({
          worksheets,
          title: filename
        });
        this.isExporting = false;
      } else {
        this.isExporting = false;
        const msg = this.intl.t('no data to export');
        window.alert(msg);
      }
    }

    *previewExportTask() {
      const onLoadPrintDataTask = this.args.onLoadPrintDataTask;

      if (onLoadPrintDataTask) {
        onLoadPrintDataTask.perform();
      }
    }

    _exportInventoryReport(specs) {
      let detailsSpecs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      let results = arguments.length > 2 ? arguments[2] : undefined;
      let hasSummary = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      const columns = R.pluck('column', specs);
      const qtyTranslation = this.intl.t('qty');
      const exclPriceTranslation = this.intl.t('exclusive price');
      const valueTranslation = this.intl.t('amount');
      const secondInfoRow = ['', '', '', '', '', '', qtyTranslation, exclPriceTranslation, valueTranslation, qtyTranslation, exclPriceTranslation, valueTranslation, qtyTranslation, exclPriceTranslation, valueTranslation, qtyTranslation, exclPriceTranslation, valueTranslation, '', '', '', ''];
      const doubleRowColumns = [columns, secondInfoRow];
      let detailProps = false;

      if (!R.isEmpty(detailsSpecs)) {
        detailProps = R.pluck('prop', detailsSpecs);
      }

      let index = 0; // let totalQty = 0

      const excelData = R.pipe(R.map(doc => {
        index = index + 1;
        let detailsRows = [];
        detailsRows = R.pipe(R.propOr([], 'details'))(doc);

        const docInfoRow = this._getDataBySpecs({
          data: doc,
          index
        }, specs);

        if (!detailProps) {
          return [docInfoRow];
        }

        detailsRows = R.map(detailData => {
          return this._getDataBySpecs({
            data: detailData
          }, detailsSpecs);
        })(detailsRows);
        return R.concat([docInfoRow, secondInfoRow], detailsRows);
      }), R.unnest, R.concat(doubleRowColumns))(results); // const grandTotal = parseInt(this.big.sumPropAndAutoRound(0, 'exclTotal', results)) || 0
      // excelData = R.append(['', '', '', '', '', '', intl.t('total'), grandTotal], excelData)

      return excelData;
    }

    _getDataBySpecs(_ref, specs) {
      let {
        data,
        inclTotalBig = false,
        index,
        today
      } = _ref;
      return R.map(spec => {
        let props = R.prop('prop', spec);

        if (!R.is(Array, props)) {
          props = [props];
        }

        const values = R.map(prop => {
          return this._getPropData({
            today,
            index,
            data,
            spec,
            prop,
            inclTotalBig
          });
        })(props);

        if (props.length > 1) {
          const joinWith = R.prop('joinWith', spec) || '-';
          return R.pipe(R.reject(RA.isNilOrEmpty), R.join(joinWith))(values);
        }

        return _nventor.default.safeHeadOr('', values);
      }, specs);
    }
    /**
     * search product
     */


    searchProductsTask(_ref2) {
      var _this = this;

      let {
        adapterName = 'products',
        query
      } = _ref2;
      return function* () {
        if (query.trim() !== '') {
          const filters = _this.productsFilters; // filters.setData('query', query)

          const results = yield _this.crud.searchInputTask.perform({
            adapterName,
            filters
          }, query);
          return results;
        }
      }();
    }

    setInventoryReportDate(_ref3) {
      let {
        onAfter
      } = _ref3;
      const isUsingDateRange = this.isUsingDateRange;

      if (R.equals(isUsingDateRange, false)) {
        const filters = this?.args?.filters || {};
        const inventoryReportMMStart = filters.getData('inventoryReportMMStart') || '';
        const inventoryReportYYYYStart = filters.getData('inventoryReportYYYYStart') || '';
        filters.setData('inventoryReportMMEnd', inventoryReportMMStart);
        filters.setData('inventoryReportYYYYEnd', inventoryReportYYYYStart);
      }

      if (onAfter) {
        onAfter();
      }
    }

    toggleIsUsingDateRange() {
      const isUsingDateRange = this.isUsingDateRange;
      this.isUsingDateRange = R.not(isUsingDateRange); // reset inventoryReportDate

      this.setInventoryReportDate({
        onAfter: false
      });
    }

    _getPropData(_ref4) {
      let {
        today,
        index,
        data,
        spec,
        prop,
        inclTotalBig
      } = _ref4;
      const intl = this.intl;

      if (prop == null) {
        return '';
      }

      if (spec.returnProp) {
        return R.propOr('', spec.returnProp)(data);
      }

      const transform = R.prop('transform')(spec);
      let value = R_.dotPathOr('', prop, data);

      if (prop === '_index_') {
        value = index;
      }

      if (prop === '_today_') {
        return today;
      }

      if (prop === 'paymentMethodKey') {
        const v = R_.dotPathOr('', prop, data);

        if (v) {
          value = intl.t(v);
        }
      }

      if (prop === '_inclTotal_' && inclTotalBig) {
        value = inclTotalBig.toFixed(0);
      }

      if (transform) {
        value = transform(value, data);
      }

      return value;
    }

    get isExportAllowed() {
      const thisMonthPeriod = this.date.getPeriod();
      const filters = this?.args?.filters || {};
      const inventoryReportMMStart = this.inventoryReportMMStart || filters.getData('inventoryReportMMStart');
      const inventoryReportMMEnd = this.inventoryReportMMEnd || filters.getData('inventoryReportMMEnd');
      const inventoryReportYYYYStart = this.inventoryReportYYYYStart || filters.getData('inventoryReportYYYYStart');
      const inventoryReportYYYYEnd = this.inventoryReportYYYYEnd || filters.getData('inventoryReportYYYYEnd');
      const isDateStartSafe = R.lt(inventoryReportYYYYStart, thisMonthPeriod?.yyyy) || R.equals(inventoryReportYYYYStart, thisMonthPeriod?.yyyy) && R.lte(inventoryReportMMStart, thisMonthPeriod?.mm);
      const isDateEndSafe = R.lt(inventoryReportYYYYEnd, thisMonthPeriod?.yyyy) || R.equals(inventoryReportYYYYEnd, thisMonthPeriod?.yyyy) && R.lte(inventoryReportMMEnd, thisMonthPeriod?.mm);

      if (isDateStartSafe && isDateEndSafe) {
        return true;
      }

      return false;
    }

    setInventoryReportTracked() {
      const filters = this?.args?.filters || {};
      this.inventoryReportMMStart = filters.getData('inventoryReportMMStart');
      this.inventoryReportMMEnd = filters.getData('inventoryReportMMEnd');
      this.inventoryReportYYYYStart = filters.getData('inventoryReportYYYYStart');
      this.inventoryReportYYYYEnd = filters.getData('inventoryReportYYYYEnd');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "big", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "excel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "monthEnd", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "monthEndZ", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "monthStart", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "monthStartZ", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isExporting", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isUsingDateRange", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "productsFilters", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "productsList", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "inventoryReportMMStart", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "inventoryReportMMEnd", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "inventoryReportYYYYStart", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "inventoryReportYYYYEnd", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "exportTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "exportTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previewExportTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "previewExportTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchProductsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setInventoryReportDate", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "setInventoryReportDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleIsUsingDateRange", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsUsingDateRange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setInventoryReportTracked", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "setInventoryReportTracked"), _class.prototype)), _class));
  _exports.default = ProductsInventoryReport;
});