define("client/pods/components/contacts/details/editor-details/editor-phone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GvY6zp2j",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-input-row\",null,[[\"customBlock\",\"errors\",\"context\"],[true,[24,[\"errors\"]],\"value\"]],{\"statements\":[[0,\"\\n\"],[4,\"forms/form-label\",null,[[\"isRequired\",\"context\",\"documentationResource\"],[[24,[\"isRequired\"]],[24,[\"detail\",\"_data\",\"type\"]],\"contacts\"]],{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"lists/list-dropdown\",null,[[\"isLabel\",\"readonly\",\"list\",\"itemLabelKey\",\"itemValueKey\",\"translate\",\"value\",\"onSelect\"],[true,true,[23,0,[\"crud\",\"lists\",\"contacts-lists-subTypesPhone\"]],\"_data.label\",\"_data.value\",true,[24,[\"detail\",\"_data\",\"subType\"]],[24,[\"onCheckForDefaults\"]]]]],false],[0,\"\\n\\n\\t\\t\"],[1,[28,\"contacts/icons/detail-icon\",null,[[\"detail\"],[[24,[\"detail\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"forms/form-input\",null,null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"elements/element-input\",null,[[\"autofocus\",\"value\"],[[24,[\"autofocus\"]],[24,[\"detail\",\"_data\",\"value\"]]]]],false],[0,\"\\n\\n\\t\\t\"],[1,[28,\"contacts/icons/default-icon\",null,[[\"detail\"],[[24,[\"detail\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/details/editor-details/editor-phone/template.hbs"
    }
  });

  _exports.default = _default;
});