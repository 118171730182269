define("client/pods/components/emails/panels-scheduled/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LmvMdTMV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid panel-grid--has-header\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__tab-header\"],[8],[0,\"\\n    \\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content u-container\"],[8],[0,\"\\n\"],[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"fetchScheduledTask\",\"isRunning\"]]]],{\"statements\":[[0,\"      \"],[1,[28,\"documents/activity-list\",null,[[\"hideExcl\",\"results\",\"onPaginationTask\",\"lastSearchSettings\",\"page\",\"count\",\"onToggleOnHold\",\"onToggleDispatched\"],[true,[24,[\"results\"]],[24,[\"paginateSearchRecordsTask\"]],[24,[\"lastSearchSettings\"]],[24,[\"page\"]],[24,[\"count\"]],[28,\"action\",[[23,0,[]],\"toggleOnHold\"],null],[28,\"action\",[[23,0,[]],\"toggleDispatched\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/emails/panels-scheduled/template.hbs"
    }
  });

  _exports.default = _default;
});