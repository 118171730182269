define("client/pods/extensions/apps/tw/e-invoices/printer/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtensionsAppsTwEInvoicesPrinterModel extends _model.default {
    get printerIdLastSix() {
      const lastSixCharacters = R.pipe(R.pathOr('', ['_data', 'printerId']), R.takeLast(6))(this);
      return `ID: ${lastSixCharacters}`;
    }

  } // export default BaseModel.extend({})


  _exports.default = ExtensionsAppsTwEInvoicesPrinterModel;
});