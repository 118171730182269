define("client/pods/components/websites/panels-display/sub-panels-pages/pages-edit/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    adapterName: 'websites/pages',

    init() {
      this._super(...arguments);

      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model: this.model
      });
      this.set('dirty', dirty);
    },

    updateTask: (0, _emberConcurrency.task)(function (_ref, model, options) {
      var _this = this;

      let {
        adapterName
      } = _ref;
      return function* () {
        yield _this.updateRecordTask.perform({
          adapterName,
          model,
          ...options
        });
      }();
    }).drop(),
    actions: {
      cancel() {
        const original = this.crud.cancelEditing({
          adapterName: this.adapterName,
          dirty: this.dirty
        });
        this.onDisplay(original);
      }

    }
  });

  _exports.default = _default;
});