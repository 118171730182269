define("client/pods/components/grapesjs/schedules-modal/schedules-dialog/schedules-row/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GrapesjsSchedulesModalSchedulesDialogSchedulesRowComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class GrapesjsSchedulesModalSchedulesDialogSchedulesRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "date", _descriptor2, this);

      _initializerDefineProperty(this, "constants", _descriptor3, this);

      const schedule = this.args.schedule;
      const dateStartZ = schedule.getData('dateStartZ');

      if (_ramdaAdjunct.default.isNotNilOrEmpty(dateStartZ)) {
        let startHours = this.date.getMoment(dateStartZ).hours();

        if (parseInt(startHours) < 10) {
          startHours = `0${startHours}`;
        }

        let startMinutes = this.date.getMoment(dateStartZ).minutes();

        if (parseInt(startMinutes) < 10) {
          startMinutes = `0${startMinutes}`;
        }

        Ember.set(schedule, 'startHours', startHours);
        Ember.set(schedule, 'startMinutes', startMinutes);
      }

      const dateEndZ = schedule.getData('dateEndZ');

      if (_ramdaAdjunct.default.isNotNilOrEmpty(dateEndZ)) {
        let endHours = this.date.getMoment(dateEndZ).hours();

        if (parseInt(endHours) < 10) {
          endHours = `0${endHours}`;
        }

        let endMinutes = this.date.getMoment(dateEndZ).minutes();

        if (parseInt(endMinutes) < 10) {
          endMinutes = `0${endMinutes}`;
        }

        Ember.set(schedule, 'endHours', endHours);
        Ember.set(schedule, 'endMinutes', endMinutes);
      }
    }

    get statusClass() {
      const schedule = this.args.schedule;
      const status = schedule.getData('status');

      if (status === this.constants.status.active) {
        return 'is-primary';
      }

      return 'is-danger';
    }

    setDateTime(args) {
      const {
        schedule,
        type
      } = args;
      let hoursProperty;
      let minutesProperty;
      let dateProperty;

      if (type === 'dateStartZ') {
        hoursProperty = 'startHours';
        minutesProperty = 'startMinutes';
        dateProperty = 'dateStart';
      }

      if (type === 'dateEndZ') {
        hoursProperty = 'endHours';
        minutesProperty = 'endMinutes';
        dateProperty = 'dateEnd';
      }

      let dateZ = schedule.getData(type);

      if (_ramdaAdjunct.default.isNotNilOrEmpty(dateZ)) {
        let hours = schedule[hoursProperty] || 0;
        let minutes = schedule[minutesProperty] || 0;

        if (parseInt(hours) > 23) {
          hours = 23;
          Ember.set(schedule, hoursProperty, '23');
        }

        if (parseInt(hours) < 0) {
          hours = 0;
          Ember.set(schedule, hoursProperty, '00');
        }

        if (parseInt(minutes) > 59) {
          minutes = 59;
          Ember.set(schedule, minutesProperty, '59');
        }

        if (parseInt(minutes) < 0) {
          minutes = 0;
          Ember.set(schedule, minutesProperty, '00');
        }

        dateZ = this.date.getMoment(dateZ).hours(hours).minutes(minutes).toISOString();
        schedule.setData(type, dateZ);
        const date = this.date.formatDate(dateZ, 'YYYY-MM-DD');
        Ember.set(schedule, dateProperty, date);
      }
    }

    onSubmitRemoveSchedule(schedule) {
      const model = this.args.model;
      const schedules = model.getData('schedules');
      schedules.removeObject(schedule);
      this.args.checkOverlapDate(model, schedules);
    }

    onChangeSetActivation() {
      const schedule = this.args.schedule;
      this.setDateTime({
        schedule,
        type: 'dateStartZ'
      });
      this.setDateTime({
        schedule,
        type: 'dateEndZ'
      });
      const model = this.args.model;
      const schedules = model.getData('schedules');
      this.args.checkOverlapDate(model, schedules);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSubmitRemoveSchedule", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onSubmitRemoveSchedule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeSetActivation", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeSetActivation"), _class.prototype)), _class));
  _exports.default = GrapesjsSchedulesModalSchedulesDialogSchedulesRowComponent;
});