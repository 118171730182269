define("client/pods/components/products/table-view/detail-row/sets-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CM3ps72L",
    "block": "{\"symbols\":[\"detail\",\"index\"],\"statements\":[[0,\"\\t\"],[7,\"table\",true],[10,\"class\",\"table-form u-full-width\"],[8],[0,\"\\n\\n\\t\\t\"],[1,[28,\"products/products-editor/sets-editor/details-editor\",null,[[\"isEditing\",\"isNew\",\"isHeaders\"],[[24,[\"isEditing\"]],[24,[\"isNew\"]],true]]],false],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\",\"_data\",\"setDetails\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"products/products-editor/sets-editor/details-editor\",null,[[\"tab\",\"isEditing\",\"isNew\",\"isReadonly\",\"errorIndex\",\"errors\",\"model\",\"detail\",\"onLoadProduct\",\"onResetSetDetail\",\"onRemoveSetDetail\"],[[24,[\"tab\"]],[24,[\"isEditing\"]],[24,[\"isNew\"]],[24,[\"isReadonlyAndAvailable\"]],[23,2,[]],[24,[\"errors\"]],[24,[\"model\"]],[23,1,[]],[28,\"action\",[[23,0,[]],\"loadProduct\"],null],[28,\"action\",[[23,0,[]],\"resetSetDetail\"],null],[28,\"action\",[[23,0,[]],\"removeSetDetail\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\t\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/table-view/detail-row/sets-editor/template.hbs"
    }
  });

  _exports.default = _default;
});