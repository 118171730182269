define("client/pods/components/websites/panels-display/sub-panels-pages/pages-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XnhQH0Dd",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"websites/websites-editor/pages-editor\",null,[[\"tab\",\"isEditing\",\"isNew\",\"isReadonly\",\"model\",\"website\",\"fullscreenOnInit\",\"pagesCollection\",\"errors\",\"onCancel\",\"onSaveTask\"],[[24,[\"tab\"]],false,true,false,[24,[\"model\"]],[24,[\"website\"]],true,[24,[\"pagesCollection\"]],[24,[\"errors\"]],[24,[\"onDisplay\"]],[28,\"perform\",[[24,[\"saveTask\"]],[28,\"hash\",null,[[\"adapterName\",\"onAfter\"],[\"websites/pages\",[28,\"action\",[[23,0,[]],\"onAfter\"],null]]]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-pages/pages-new/template.hbs"
    }
  });

  _exports.default = _default;
});