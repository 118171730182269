define("client/pods/settings/editor-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  const filterTabs = (list, filterByLabel) => R.map(R.pipe(R.unless(R.propSatisfies(R.includes(R.__, filterByLabel), 'label'), R.set(R.lensProp('component'), null))))(list);

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let appendPath = arguments.length > 1 ? arguments[1] : undefined;
      const customData = {
        list: [{
          component: 'settings/settings-editor/company-editor',
          label: 'company',
          value: 'company',
          resource: 'api/protected/settings/sub-panels-edit/settings',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/company-editor',
            isEditing: 'settings/settings-editor/company-editor',
            isNew: 'settings/settings-editor/company-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          component: 'settings/settings-editor/taxes-editor',
          label: 'taxes',
          value: 'taxes',
          resource: 'api/protected/settings/sub-panels-edit/taxes',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/company-editor',
            isEditing: 'settings/settings-editor/company-editor',
            isNew: 'settings/settings-editor/company-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          component: 'settings/settings-editor/products-editor',
          label: 'products',
          value: 'products',
          resource: 'api/protected/settings/sub-panels-edit/products',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/company-editor',
            isEditing: 'settings/settings-editor/company-editor',
            isNew: 'settings/settings-editor/company-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          label: 'contacts',
          value: 'contacts',
          resource: 'api/protected/settings/sub-panels-edit/contacts',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/company-editor',
            isEditing: 'settings/settings-editor/company-editor',
            isNew: 'settings/settings-editor/company-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          component: 'settings/settings-editor/documents-editor',
          label: 'documents',
          value: 'documents',
          resource: 'api/protected/settings/sub-panels-edit/documents',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/company-editor',
            isEditing: 'settings/settings-editor/company-editor',
            isNew: 'settings/settings-editor/company-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          component: 'settings/settings-editor/emails-editor',
          label: 'emails',
          value: 'emails',
          resource: 'api/protected/settings/sub-panels-edit/emails',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/company-editor',
            isEditing: 'settings/settings-editor/company-editor',
            isNew: 'settings/settings-editor/company-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          component: 'settings/settings-editor/shipping-editor',
          label: 'shipping',
          value: 'shipping',
          resource: 'api/protected/settings/sub-panels-edit/shipping',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/shipping-editor',
            isEditing: 'settings/settings-editor/shipping-editor',
            isNew: 'settings/settings-editor/shipping-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          component: 'settings/settings-editor/shop-editor',
          label: 'shop',
          value: 'shop',
          resource: 'api/protected/settings/sub-panels-edit/shop',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/shop-editor',
            isEditing: 'settings/settings-editor/shop-editor',
            isNew: 'settings/settings-editor/shop-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          component: 'settings/settings-editor/3rd-party-editor',
          label: '3rd-party',
          value: '3rd-party',
          resource: 'api/protected/settings/sub-panels-edit/3rd-party',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/3rd-party-editor',
            isEditing: 'settings/settings-editor/3rd-party-editor',
            isNew: 'settings/settings-editor/3rd-party-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }, {
          component: 'settings/settings-editor/beta-editor',
          label: 'beta',
          value: 'beta',
          resource: 'api/protected/settings/sub-panels-edit/beta',
          loadInstructions: {
            isDisplay: 'settings/settings-editor/beta-editor',
            isEditing: 'settings/settings-editor/beta-editor',
            isNew: 'settings/settings-editor/beta-editor'
          },
          tabType: 'reuseSubTab',
          _isNewTab: true
        }]
      };
      customData.list = filterTabs(customData.list, ['company', 'shipping', 'shop', '3rd-party', 'beta']);
      const path = this.getFullPath('findAllPath', appendPath);
      return this.ajax.POST(path, customData).then(res => res?.data || []);
    }

  });

  _exports.default = _default;
});