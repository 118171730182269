define("client/pods/components/emails/campaigns/campaigns-editor/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    contactsService: Ember.inject.service('contacts'),
    tagName: 'div',
    classNames: ['col-container'],
    disabled: false,
    translate: true,
    defaultProductType: null,
    defaultProductStatus: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['emails/campaigns/editStatuses']);
      const groupsFilters = this.search.setupFilters({
        adapterName: 'contacts/groups/filters',
        defaultFiltersData: {
          sort: [{
            by: 'name',
            direction: 'ASC'
          }],
          status: [this.constants.status.active]
        }
      });
      this.set('groupsFilters', groupsFilters);
      const websitesFilters = this.search.setupFilters({
        adapterName: 'websites/filters',
        defaultFiltersData: {
          sort: [{
            by: 'name',
            direction: 'ASC'
          }],
          status: [this.constants.status.active]
        }
      });
      this.set('websitesFilters', websitesFilters);
    },

    loadContactsGroup(model, selected) {
      model.setData('contactsGroupKey', selected?._data?._key);
    },

    unloadContactsGroup(model) {
      model.setData('contactsGroupKey', '');
    },

    loadWebsite(model, selected) {
      model.setData('websiteKey', selected?._data?._key);
    },

    unloadWebsite(model) {
      model.setData('websiteKey', '');
    },

    actions: {
      autoSetUrl(value) {
        const model = this.model;
        const url = R.pipe(R.trim, R.toLower, R.replace(/\\/g, ''), R.replace(/\s/g, '-'))(value);
        model.setData('url', url);
      } // replaceTemplate (onCloseDialogAction, template) {
      //   const model = this.get('model')
      //   model.setData('template', template)
      //   onCloseDialogAction()
      // },
      // insertTemplate (onCloseDialogAction, template) {
      //   const model = this.get('model')
      //   const currentTemplate = model.getData('template')
      //   let newTemplate = ''
      //   if (currentTemplate) {
      //     newTemplate = `${currentTemplate}<br>`
      //   }
      //   newTemplate = `${newTemplate} ${template}`
      //   model.setData('template', newTemplate)
      //   onCloseDialogAction()
      // }


    }
  }, (_applyDecoratedDescriptor(_obj, "loadContactsGroup", [_dec], Object.getOwnPropertyDescriptor(_obj, "loadContactsGroup"), _obj), _applyDecoratedDescriptor(_obj, "unloadContactsGroup", [_dec2], Object.getOwnPropertyDescriptor(_obj, "unloadContactsGroup"), _obj), _applyDecoratedDescriptor(_obj, "loadWebsite", [_dec3], Object.getOwnPropertyDescriptor(_obj, "loadWebsite"), _obj), _applyDecoratedDescriptor(_obj, "unloadWebsite", [_dec4], Object.getOwnPropertyDescriptor(_obj, "unloadWebsite"), _obj)), _obj)));

  _exports.default = _default;
});