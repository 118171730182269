define("client/pods/components/elements/element-textarea/component", ["exports", "client/mixins/translate"], function (_exports, _translate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_translate.default, {
    // export default Ember.Component.extend(
    //   translateMixin,
    //   isDirtyMixin, {
    tagName: 'span',
    classNames: ['element-textarea'],
    autofocus: false,
    translate: true,
    // set default rows to 2 lines
    rows: 1,
    maxRows: 5,
    autoResize: true,
    onInput: null,

    init() {
      // @NOTE: ember textarea becomes two lines if value=undefined
      this._super(...arguments);

      if (this.value == null) {
        this.set('value', '');
      }
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.autofocus && !this.readonly && !this.disabled) {
        setTimeout(() => {
          try {
            this.element.firstChild.focus();
          } catch (e) {
            console.error('cannot autofocus text area', e);
          }
        }, 400);
      }
    },

    inputPlaceHolder: Ember.computed('isRequired', function () {
      if (this.isRequired) {
        return this.intl.t('is required');
      }

      return this.translatedPlaceholder || '';
    }),
    actions: {
      submit: function () {
        // send onSubmit action
        if (this.onSubmit) {
          this.onSubmit();
        }
      }
    }
  });

  _exports.default = _default;
});