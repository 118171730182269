define("client/pods/admin/payments/card/server-logs/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdminPaymentsCardServerLogsFiltersModel extends _model.default {
    defaults() {
      return {
        resultsProperty: 'results',
        page: 1,
        count: 5,
        podKey: '',
        query: '',
        match: ['all'],
        statuses: [],
        dateStart: '',
        dateEnd: '',
        abandoned: '',
        contact: '',
        contactKey: '',
        paymentCreditCardStatus: [],
        sort: [{
          by: 'timestampZ',
          direction: 'DESC'
        }]
      };
    }

  }

  _exports.default = AdminPaymentsCardServerLogsFiltersModel;
});