define("client/pods/components/extensions/apps/tw/seven-eleven/report-status-days/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwSevenElevenReportStatusDaysComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, (_class = class ExtensionsAppsTwSevenElevenReportStatusDaysComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "server", _descriptor3, this);

      _initializerDefineProperty(this, "report", _descriptor4, this);

      _initializerDefineProperty(this, "transporterModel", _descriptor5, this);

      _initializerDefineProperty(this, "transportersList", _descriptor6, this);

      _initializerDefineProperty(this, "fetchedReportDetails", _descriptor7, this);

      _initializerDefineProperty(this, "isRowVisible", _descriptor8, this);

      _defineProperty(this, "daysOldArray", [0, 1, 2, 3, 4, 5, 6, 7]);

      _defineProperty(this, "provider", 'seven-eleven');

      this.crud.addLists(this, ['contacts/transporters', 'dispatch/lists/processes']);
    }

    afterLoadLists() {
      const transportersList = R.pathOr([], ['crud', 'lists', 'contacts-transporters'])(this);
      this.transportersList = transportersList;
      const transporterModel = transportersList.findBy('_data.transporterExtension', this.provider);

      if (transporterModel) {
        this.transporterModel = transporterModel;
      }
    }

    get hasExtension() {
      const transportersList = this.transportersList || [];
      const transporterModel = transportersList.findBy('_data.transporterExtension', this.provider);

      if (RA.isNilOrEmpty(transporterModel)) {
        return false;
      }

      return true;
    }

    fetchOverviewTask(_ref) {
      var _this = this;

      let {
        type = overview
      } = _ref;
      return function* () {
        const reportData = yield _this.fetchReportTask.perform({
          type
        });
        _this.report = reportData;
        return reportData;
      }();
    }

    fetchReportTask(_ref2) {
      var _this2 = this;

      let {
        type = 'overview',
        segment
      } = _ref2;
      return function* () {
        // segment is not used yet by the api.
        const results = yield _this2.server.call('GET', `api/protected/extensions/apps/tw/transporters/report/status-days/seven-eleven/${type}`);
        const reportData = R.propOr([], 'data')(results);
        return reportData;
      }();
    }

    fetchReportDetailsTask(_ref3) {
      var _this3 = this;

      let {
        type,
        segment
      } = _ref3;
      return function* () {
        const fetchedReportDetails = _this3.fetchedReportDetails || {};

        if (RA.isNilOrEmpty(fetchedReportDetails[type])) {
          const segmentReport = yield _this3.fetchReportTask.perform({
            type,
            segment
          });

          if (RA.isNotNilOrEmpty(segmentReport[type])) {
            const report = _this3.report || {};
            const segmentReportValue = segmentReport[type];
            Ember.set(report, type, segmentReportValue);
            _this3.report = report;
          }
        }

        _this3.fetchedReportDetails[type] = true; // @NOTE: this is required to refresh object in template

        _this3.fetchedReportDetails = _this3.fetchedReportDetails;
        const isVisible = !_this3.isRowVisible[`${type}-${segment}`];
        _this3.isRowVisible[`${type}-${segment}`] = isVisible; // @NOTE: this is required to refresh object in template

        _this3.isRowVisible = _this3.isRowVisible;
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "report", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "transportersList", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fetchedReportDetails", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isRowVisible", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchOverviewTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchOverviewTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchReportTask", [_emberConcurrencyDecorators.enqueueTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchReportTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchReportDetailsTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "fetchReportDetailsTask"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwSevenElevenReportStatusDaysComponent;
});