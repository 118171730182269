define("client/pods/uploads/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isImageFormat = R.anyPass([R.equals('AVIF'), R.equals('BMP'), R.equals('GIF'), R.equals('HEIC'), R.equals('JPEG'), R.equals('PCX'), R.equals('PNG'), R.equals('TGA'), R.equals('TIFF'), R.equals('SVG'), R.equals('WEBP')]);

  const Uploads = _model.default.extend({
    defaults() {
      return {
        tags: []
      };
    },

    populate(data, attrs) {
      data.mimeType = this._transformMimeType(data);

      this._super(data, attrs);
    },

    _transformMimeType(data) {
      const format = R.pipe(R.pathOr('', ['imageInfo', 'format']), R.toLower)(data);
      const isImage = R.pipe(R.toUpper, isImageFormat)(format);

      if (isImage) {
        return `image/${format}`;
      }

      return data.mimeType;
    },

    getUrl() {
      let transforms = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      const cdnBase = this.uploadcare.get('cdnBase');
      const uuid = this.get('_data.uuid');
      return `${cdnBase}${uuid}${transforms}/`;
    },

    url: Ember.computed('_data.uuid', function () {
      const cdnBase = this.uploadcare.get('cdnBase');
      const uuid = this.get('_data.uuid');

      if (uuid) {
        return `${cdnBase}${uuid}/`;
      }

      return '';
    })
  });

  class UploadsModel extends Uploads {
    get fileFormat() {
      return R.pipe(R.pathOr('', ['_data', 'imageInfo', 'format']), R.toLower)(this);
    }

    get isImage() {
      return R.pipe(R.pathOr('', ['fileFormat']), R.toUpper, isImageFormat)(this);
    }

    get isGif() {
      return this.fileFormat === 'gif';
    }
    /**
     * Return value form _data
     * @param {string} key
     * @returns {any}
     */


    getData(key) {
      return this.get(`_data.${key}`);
    }

  }

  _exports.default = UploadsModel;
});