define("client/pods/components/msgs/sync-updated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UFc8JvnM",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"modifier\"],\"statements\":[[4,\"if\",[[24,[\"syncUpdated\"]]],null,{\"statements\":[[0,\"  \"],[5,\"helpers/info-tooltip\",[],[[\"@customBlock\"],[true]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"elements/element-icon\",null,[[\"classNames\",\"icon\"],[\"u-is-cursor-default u-icon-spacer--left\",[24,[\"icon\"]]]]],false],[0,\"\\n\\n\"],[4,\"helpers/info-tooltip-content\",null,[[\"isViewable\"],[[23,1,[]]]],{\"statements\":[[4,\"each\",[[24,[\"syncUpdated\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"tt\",[\"updated\"],null],false],[0,\": \"],[1,[23,2,[\"username\"]],false],[0,\" \"],[4,\"if\",[[23,2,[\"md\"]]],null,{\"statements\":[[0,\" @ \"],[1,[28,\"dateTime\",[[23,2,[\"md\"]]],null],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/msgs/sync-updated/template.hbs"
    }
  });

  _exports.default = _default;
});