define("client/pods/components/channels/shop/campaigns/items/table-view/detail-row/component", ["exports", "ember-concurrency", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _emberConcurrency, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    date: Ember.inject.service(),
    settings: Ember.inject.service(),
    tagName: '',
    adapterName: 'channels/shop/campaigns/details',
    showDetails: false,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['filters/count', 'products/lists/menu', 'products/lists/tags', 'products/lists/types', 'products/lists/setTypesFilters', 'statuses', 'channels/shop/campaigns/statuses', 'toggles/yesNo']);
      let limitAdjustType = 'adjustTotalQty';
      const adjustCampaignsDetailsLimitQtyBy = this.settings.getProp('adjustCampaignsDetailsLimitQtyBy');

      if (RA.isNotNilOrEmpty(adjustCampaignsDetailsLimitQtyBy)) {
        limitAdjustType = adjustCampaignsDetailsLimitQtyBy;
      }

      this.set('limitAdjustType', limitAdjustType);
    },

    bulkDiscountSchedule: Ember.computed('model._data.bulkDiscountId', 'tableViewOptions.campaign._data.bulkDiscounts.@each.synced', function () {
      const model = this.model;
      const campaign = this?.tableViewOptions?.campaign || {};
      const bulkDiscountSchedule = model.getBulkDiscountSchedule({
        campaign
      });
      return {
        hasAnyActive: bulkDiscountSchedule.hasAnyActive,
        hasIsActive: bulkDiscountSchedule.hasIsActive,
        hasIsActiveNext: bulkDiscountSchedule.hasIsActiveNext,
        isActive: bulkDiscountSchedule.isActive,
        isActiveNext: bulkDiscountSchedule.isActiveNext
      };
    }),
    isSelected: Ember.computed('tableViewOptions.selectedItems.[]', function () {
      const selectedItems = this?.tableViewOptions?.selectedItems || [];

      if (selectedItems.indexOf(this.model) > -1) {
        return true;
      }

      return false;
    }),
    isAllSelected: Ember.computed('tableViewOptions.{selectedItems.[],campaignDetails.[]}', function () {
      const tableViewOptions = this?.tableViewOptions || {};
      const selectedItems = tableViewOptions?.selectedItems || [];
      const campaignDetails = tableViewOptions?.campaignDetails || [];

      if (R.length(selectedItems) === R.length(campaignDetails)) {
        return true;
      }

      return false;
    }),
    isSingleOrBulkEditing: Ember.computed('isSingleEditing', 'tableViewOptions.isBulkEditing', function () {
      return this.isSingleEditing || this.tableViewOptions.isBulkEditing;
    }),
    isReadonly: Ember.computed('isSingleOrBulkEditing', function () {
      return !this.isSingleOrBulkEditing;
    }),
    isShowProductSchedules: Ember.computed('tableViewOptions.{isAutomations,isProductsSelector}', function () {
      const isAutomations = this?.tableViewOptions?.isAutomation || false;

      if (isAutomations) {
        return false;
      }

      const isProductsSelector = this?.tableViewOptions?.isProductsSelector || false;

      if (isProductsSelector) {
        return false;
      }

      return true;
    }),
    // showResetFilter: computed('filters._data.{statuses.[],tags.[],setTypes.[],hasImages,item,itemCode,price}', function () {
    //   const filtersData = this?.filters?._data
    //   if (RA.isNilOrEmpty(filtersData)) {
    //     return false
    //   }
    //   const statuses = filtersData.statuses
    //   const hasImages = filtersData.hasImages
    //   const item = filtersData.item
    //   const itemCode = filtersData.itemCode
    //   const price = filtersData.price
    //   const setTypes = filtersData.setTypes
    //   const tags = filtersData.tags
    //   if (
    //     statuses.length !== 5 ||
    //     tags.length !== 0 ||
    //     setTypes.length !== 0 ||
    //     RA.isNilOrEmpty(hasImages) !== true ||
    //     item !== '' ||
    //     itemCode !== '' ||
    //     price !== ''
    //   ) {
    //     return true
    //   }
    //   return false
    // }),
    updateBulkEditingTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        appendPath = '',
        model,
        isSkipBulkDiscountConflict = false
      } = _ref;
      return function* () {
        if (RA.isNilOrEmpty(appendPath)) {
          appendPath = '';
        }

        if (_this.tableViewOptions?.isBulkEditing) {
          yield _this.updateTask.perform({
            appendPath,
            model
          });
        }

        if (isSkipBulkDiscountConflict) {
          if (RA.isNilOrEmpty(model)) {
            model = _this.model;
          }

          model.set('isShowBulkDiscountConflictDialogInDetailRow', false);
        }
      }();
    }),
    refetchCampaignDetail: (0, _emberConcurrency.task)(function* (model) {
      const updated = yield this.crud.findTask.perform({
        adapterName: 'channels/shop/campaigns/details',
        appendPath: `/${model?._data?._key}`
      });
      this.set('model', model);
    }),
    updateTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        appendPath,
        model
      } = _ref2;
      return function* () {
        model = model || _this2.model;
        let dirty = model;

        if (!model.isDirty) {
          dirty = _this2.crud.setupDirty({
            adapterName: 'channels/shop/campaigns/details',
            model
          });
        }

        const product = model.product;
        const updated = yield _this2.crud.replaceRecordTask.perform({
          adapterName: 'channels/shop/campaigns/details',
          appendPath,
          model: dirty,
          component: _this2
        });

        if (updated && !_this2.tableViewOptions?.isBulkEditing) {
          _this2.set('model', updated);

          _this2.set('original', false);

          _this2.set('isSingleEditing', false); // } else if (updated && this.tableViewOptions?.isBulkEditing) {
          // const adapterName = this.adapterName
          // const dirty = this.crud.setupDirty({
          //   adapterName,
          //   model: updated,
          //   attrs: {
          //     product
          //   }
          // })
          // this.set('model', dirty)

        }

        if (updated) {
          _this2.set('errors', false);
        }
      }();
    }),

    cancel() {
      const original = this.original;
      const dirty = this.model;
      this.crud.cancelEditing({
        adapterName: this.adapterName,
        dirty
      });
      this.set('model', original);
      this.set('original', false);
      this.set('isSingleEditing', false);
      this.set('errors', false);
    },

    isEvenOrOddClassName: Ember.computed('model._data.index', function () {
      const index = this?.model?._data?.index || '';

      if (R.is(Number)(index)) {
        if (_nventor.default.isEven(index)) {
          return 'even';
        }

        return 'odd';
      }

      return '';
    }),
    rowClassName: Ember.computed('dropPosition', 'isEvenOrOddClassName', function () {
      const dropPosition = this.get('dropPosition') || '';
      const isEvenOrOddClassName = this.isEvenOrOddClassName || '';
      return `table-view__detail-row new-table-view__tr--detail-row ${dropPosition}`;
    }),

    mainActionWithModel(onCloseDialogAction, model) {
      if (this.onMainAction) {
        return this.onMainAction(model);
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    },

    // @action
    // clearSelectedItems () {
    //   if (this?.crud?.reloadSearchRecordsTask) {
    //     this?.crud?.reloadSearchRecordsTask.perform({ filters: this.filters, reset: true })
    //   }
    // },
    actions: {
      toggleDetails() {
        let showDetails = this.showDetails;
        this.toggleProperty('showDetails');
      },

      onCloseUpdateProductTags() {
        this.updateBulkEditingTask.perform({
          appendPath: '/bulk-discount-conflict'
        });
        const model = this.model || {};
        model.set('isShowBulkDiscountConflictDialogInDetailRow', true);
      },

      drop() {
        if (this.get('tableViewOptions.onDrop')) {
          this.get('tableViewOptions.onDrop')(...arguments);
        }
      },

      dragOver() {
        if (this.get('tableViewOptions.onDragOver')) {
          const dropPosition = this.get('tableViewOptions.onDragOver')(...arguments);
          this.set('dropPosition', dropPosition);
        }
      },

      dragStart() {
        if (this.get('tableViewOptions.onDragStart')) {
          this.get('tableViewOptions.onDragStart')(...arguments);
        }
      },

      edit() {
        const adapterName = this.adapterName;
        const model = this.model;
        const dirty = this.crud.setupDirty({
          adapterName,
          model
        });
        this.set('original', model); // dirty.set('product', model.get('product'))

        this.set('model', dirty);
        this.set('isSingleEditing', true);
        this.set('errors', false);
      },

      cancel() {
        this.cancel();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "mainActionWithModel", [_dec], Object.getOwnPropertyDescriptor(_obj, "mainActionWithModel"), _obj)), _obj)));

  _exports.default = _default;
});