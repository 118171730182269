define("client/pods/components/websites/panels-display/sub-panels-overview/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),

    init() {
      // is this used? can't find any component calling websites/panels-display/sub-panels-overview.
      this.crud.addLists(this, ['websites/reports/filters', 'taxMethods/filters']);
      this.set('showCampaignReportBy', Ember.Object.create({
        revenueBy: 'ordered',
        taxMethods: 'inclusive'
      }));

      this._super(...arguments);
    },

    dispatchStatusFilter: Ember.computed('showCampaignReportBy.revenueBy', function () {
      const revenueBy = this.get('showCampaignReportBy.revenueBy');

      if (revenueBy === 'ordered') {
        return 'all';
      }

      return this.get('constants.dispatchStatus.dispatched');
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const model = this.model;
      const sourceKey = model.getData('_key');
      this.fetchReportTask.perform({
        sourceKey
      });
    },

    fetchReportTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        sourceKey
      } = _ref;
      return function* () {
        const report = yield _this._fetchReportTask.perform({
          sourceKey,
          segments: ['orderDateZ', 'expectedDispatchDateZ', 'dispatchDateZ', 'status']
        });

        _this.set('report', report);

        _this.set('itemReport', []);

        _this.set('paymentMethodKeyReport', []);

        _this.set('contactReport', []);
      }();
    }),
    displayItemReportTask: (0, _emberConcurrency.task)(function (_ref2) {
      var _this2 = this;

      let {
        sourceKey
      } = _ref2;
      return function* () {
        const itemReport = yield _this2._fetchReportTask.perform({
          sourceKey,
          segments: ['item']
        });

        _this2.set('itemReport', itemReport);
      }();
    }),
    displayPaymentMethodKeyReportTask: (0, _emberConcurrency.task)(function (_ref3) {
      var _this3 = this;

      let {
        sourceKey
      } = _ref3;
      return function* () {
        const paymentMethodKeyReport = yield _this3._fetchReportTask.perform({
          sourceKey,
          segments: ['paymentMethodKey']
        });

        _this3.set('paymentMethodKeyReport', paymentMethodKeyReport);
      }();
    }),
    displayContactReportTask: (0, _emberConcurrency.task)(function (_ref4) {
      var _this4 = this;

      let {
        sourceKey
      } = _ref4;
      return function* () {
        const contactReport = yield _this4._fetchReportTask.perform({
          sourceKey,
          segments: ['contact', 'contactTags', 'customerType', 'memberType', 'gender', 'age']
        });

        _this4.set('contactReport', contactReport);
      }();
    }),
    _fetchReportTask: (0, _emberConcurrency.task)(function (_ref5) {
      var _this5 = this;

      let {
        sourceKey,
        segments = []
      } = _ref5;
      return function* () {
        const params = {
          sourceKey,
          status: _this5.get('constants.documentsStatus.final'),
          dispatchStatus: _this5.dispatchStatusFilter,
          // segments: [
          //   'orderDateZ',
          //   'expectedDispatchDateZ',
          //   'dispatchDateZ',
          //   'contact',
          //   'item',
          //   'paymentMethodKey',
          //   'status',
          //   'customerType',
          //   'memberType',
          //   'gender',
          //   'age'
          // ]
          segments
        };
        const results = yield _this5.server.call('GET', 'api/protected/reports/campaigns/', params);
        return results.data;
      }();
    }),
    isTaxExclusive: Ember.computed('showCampaignReportBy.taxMethods', function () {
      if (this.get('showCampaignReportBy.taxMethods') === 'exclusive') {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});