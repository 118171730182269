define("client/pods/flows/model", ["exports", "ramda", "ramda-adjunct", "client/pods/base/model", "client/utils/nventor"], function (_exports, R, RA, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_obj = {
    defaults() {
      return {
        name: '',
        dateStartZ: '',
        dateEndZ: '',
        eventHasCriterias: false,
        type: 'bot',
        eventType: 'view',
        eventCategory: 'page',
        eventDisplay: 'message',
        eventCriterias: [],
        eventHasLimitsTotal: false,
        eventHasLimitsTotalQty: 0,
        eventHasLimitsPerOrder: true,
        eventHasLimitsPerOrderQty: 1,
        eventHasLimitsPerPerson: false,
        eventHasLimitsPerPersonQty: 0,
        steps: [],
        status: 'active',
        emailsSendOnlyIfHasResults: true,
        emailsAlsoSendToCustomer: false,
        emailsSendWhenSuccessful: false,
        flowsCreatedEmailSuccessfulTitle: '',
        flowsCreatedEmailSuccessfulMessage: '',
        flowsCreatedEmailCopyTo: '',
        flowKeyType: 'website'
      };
    },

    populate(data, attrs) {
      data.steps = this._transformSteps(data);
      data.eventCriterias = this._transformCriterias(data);
      data.flowsCreatedEmailCopyTo = this.emails.joinAsString(data.flowsCreatedEmailCopyTo);

      this._super(data, attrs);
    },

    _transformSteps(data) {
      return R.pipe(R.propOr([], 'steps'), R.map(step => {
        return this._getStepModel(step);
      }))(data);
    },

    _transformCriterias(data) {
      return R.pipe(R.propOr([], 'eventCriterias'), RA.mapIndexed((criteria, index) => {
        criteria.index = index;
        return this.dataManager.setAsRecord({
          adapterName: 'flows/criterias',
          data: criteria
        });
      }))(data);
    },

    isNotification: Ember.computed('_data.type', function () {
      const type = this?._data?.type || 'bot';

      if (type === 'notification') {
        return true;
      }

      return false;
    }),
    isSignUpEvent: Ember.computed('_data.eventType', function () {
      const eventType = this?._data?.eventType || '';

      if (eventType === 'signup') {
        return true;
      }

      return false;
    }),

    setupCriterias(eventHasCriterias) {
      this.getData('eventCriterias', []);

      if (eventHasCriterias) {
        this.addCriteria();
      }

      return this.getData('eventCriterias');
    },

    addCriteria() {
      let criteria = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const eventCriterias = this.setData('eventCriterias') || [];
      criteria.index = eventCriterias.length;
      const eventCriteria = this.dataManager.setAsRecord({
        adapterName: 'flows/criterias',
        data: criteria
      });
      eventCriterias.pushObject(eventCriteria);
      this.setData('eventCriterias', eventCriterias);
      return criteria;
    },

    selectType(type) {
      if (type?._data?.value === 'notification') {
        this.setData('steps', []);
      } // const category = eventModel.getData('category')
      // this.setData('eventCategory', category)
      // if (!eventModel.getData('hasCriterias')) {
      //   this.setData('eventHasCriterias', false)
      //   this.setData('eventCriterias', [])
      // }

    },

    selectEventType(eventModel) {
      const category = eventModel.getData('category');
      this.setData('eventCategory', category);
      const eventType = this.getData('eventType');
      this.setData('eventHasLimitsPerPerson', false);
      this.setData('eventHasLimitsPerPersonQty', '');

      if (R.equals(eventType, 'view')) {
        this.setData('eventHasLimitsPerOrder', false);
        this.setData('eventHasLimitsPerOrderQty', '');
      }

      if (R.equals(eventType, 'signup')) {
        this.setData('eventHasLimitsTotal', false);
        this.setData('eventHasLimitsTotalQty', '');
        this.setData('eventHasLimitsPerOrder', false);
        this.setData('eventHasLimitsPerOrderQty', '');
        this.setData('eventHasLimitsPerPerson', true);
        this.setData('eventHasLimitsPerPersonQty', 1);
        this.setData('eventHasLimitsTotal', false);
        this.setData('eventHasLimitsTotalQty', '');
      }

      if (!eventModel.getData('hasCriterias')) {
        this.setData('eventHasCriterias', false);
        this.setData('eventCriterias', []);
      }
    },

    setupNewSteps(typeModel, steps) {
      steps = R.map(step => {
        return R.clone(step);
      })(steps);
      const parentStepLabel = typeModel.getData('label');
      let currentIndex = this.getStepLastIndex();
      const parentIndex = this.getStepLastParentIndex() + 1;
      return R.map(step => {
        currentIndex = currentIndex + 1;
        step.index = currentIndex;
        step.stepId = _nventor.default.random.alphaNum(17);
        step.parentIndex = parentIndex;
        step.parentStepLabel = parentStepLabel;
        step.isValidated = false;

        const stepModel = this._getStepModel(step);

        if (step.actionType === 'games') {
          const actionModel = stepModel?._data?.actionData;
          actionModel.newPrize();
        }

        return stepModel;
      })(steps);
    },

    removeStep(step) {
      const parentIndex = step?._data?.parentIndex;

      if (!parentIndex) {
        _nventor.default.throw('removeStep: parentIndex is not defined');

        return;
      }

      let steps = this.getData('steps') || [];
      steps = R.pipe(R.reject(R.pathEq(['_data', 'parentIndex'], parentIndex)), R.forEachObjIndexed((m, index) => {
        m.set('_data.index', parseInt(index) + 1);
      }))(steps);
      this.setData('steps', steps);
    },

    addSteps() {
      let steps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let onCloseDialogAction = arguments.length > 1 ? arguments[1] : undefined;
      const currentSteps = this.getData('steps') || [];
      const newSteps = R.pipe(R.concat(currentSteps), R.forEachObjIndexed((m, index) => {
        m.set('_data.index', parseInt(index) + 1);
      }))(steps);
      this.setData('steps', newSteps);

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    },

    _getStepModel(data) {
      let stepData = data;

      if (data.serialize) {
        stepData = data.serialize();
      }

      return this.dataManager.setAsRecord({
        adapterName: 'flows/steps',
        data: stepData
      });
    },

    getStepLastIndex() {
      const lastIndex = R.pipe(R.pathOr([], ['_data', 'steps']), R.reduce((acc, step) => {
        const index = step.getData('index');

        if (index > acc) {
          return index;
        }

        return acc;
      }, 0))(this);
      return lastIndex;
    },

    getStepLastParentIndex() {
      const lastIndex = R.pipe(R.pathOr([], ['_data', 'steps']), R.reduce((acc, step) => {
        const index = step.getData('parentIndex');

        if (index > acc) {
          return index;
        }

        return acc;
      }, 0))(this);
      return lastIndex;
    }

  }, (_applyDecoratedDescriptor(_obj, "setupCriterias", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupCriterias"), _obj), _applyDecoratedDescriptor(_obj, "addCriteria", [_dec2], Object.getOwnPropertyDescriptor(_obj, "addCriteria"), _obj), _applyDecoratedDescriptor(_obj, "selectType", [_dec3], Object.getOwnPropertyDescriptor(_obj, "selectType"), _obj), _applyDecoratedDescriptor(_obj, "selectEventType", [_dec4], Object.getOwnPropertyDescriptor(_obj, "selectEventType"), _obj), _applyDecoratedDescriptor(_obj, "setupNewSteps", [_dec5], Object.getOwnPropertyDescriptor(_obj, "setupNewSteps"), _obj), _applyDecoratedDescriptor(_obj, "removeStep", [_dec6], Object.getOwnPropertyDescriptor(_obj, "removeStep"), _obj), _applyDecoratedDescriptor(_obj, "addSteps", [_dec7], Object.getOwnPropertyDescriptor(_obj, "addSteps"), _obj), _applyDecoratedDescriptor(_obj, "getStepLastIndex", [_dec8], Object.getOwnPropertyDescriptor(_obj, "getStepLastIndex"), _obj), _applyDecoratedDescriptor(_obj, "getStepLastParentIndex", [_dec9], Object.getOwnPropertyDescriptor(_obj, "getStepLastParentIndex"), _obj)), _obj)));

  _exports.default = _default;
});