define("client/helpers/strip-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var tmp = document.implementation.createHTMLDocument('New').body;
      tmp.innerHTML = params;
      return tmp.textContent || tmp.innerText || '';
    }

  });

  _exports.default = _default;
});