define("client/pods/components/documents/btn-refund/workflow/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DocumentsBtnRefundWorkflowComponent extends _component.default {}

  _exports.default = DocumentsBtnRefundWorkflowComponent;
});